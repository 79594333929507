import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import styled from 'styled-components';
import OverviewHeader from './OverviewHeader';
import PerformanceCards from './performance-cards/PerformanceCards';
import TableTabs from './spaces-table/TableTabs';

type OverviewProps = {
  siteClimateControl?: SiteClimateControlDto;
  refreshSiteClimateControl: () => void;
}

const Overview = ({ siteClimateControl, refreshSiteClimateControl }: OverviewProps) => {
  return (
    <>
      <HeaderContainer>
        <OverviewHeader />
        {siteClimateControl &&
          <PerformanceCards siteClimateControl={siteClimateControl} />
        }
      </HeaderContainer >

      {siteClimateControl &&
        <TableTabs
          siteClimateControl={siteClimateControl}
          refreshSiteClimateControl={refreshSiteClimateControl}
        />
      }
    </>
  )
}

export default Overview;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 40px;
  background-color: ${p => p.theme.palette.backgrounds.climateControl};

  @media (max-width: 900px) {
    padding: 30px;
  }

  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  @media print {
    padding: 0;
  }
`;