import SiteMetadataGetBySiteIdQuery from '@api/queries/site-metadata/SiteMetadataGetBySiteIdQuery';
import SiteMetadataUpdateCommand from '@api/queries/site-metadata/SiteMetadataUpdateCommand';
import { MetricType } from '@api/enums/MetricType';
import { MonthlyWeighting } from '@api/models/BuildingMetadata';
import { SiteMetadata } from '@api/models/SiteMetadata';
import { Button } from '@components/core/Button';
import { Form } from '@components/Form';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { RouterPrompt } from '@src/navigation/RouterPrompt/RouterPrompt';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { isEmpty, isMatch } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import styled, { useTheme } from 'styled-components';
import CertificationsAndRatingsEdit from './components/CertificationsAndRatingsEdit';
import EmissionsEdit from './components/EmissionsEdit';
import GeneralEdit from './components/GeneralEdit';
import UtilitiesEdit from './components/UtilitiesEdit';
import YearlyConsumptionTargetsEdit from './components/YearlyConsumptionTargetsEdit';
import { useSiteContext } from '@pages/site/SiteProvider';

const masonryBreakpointColumns = {
  default: 2,
  1600: 1
};

export type FormValues = {
  currency?: string,
  electricityPricePerKwh?: number,
  heatingPricePerKwh?: number,
  waterPricePerM3?: number,
  assetArea?: number,
  targetCarbon?: number,
  baselineCarbon?: number,
  electricityCarbonIntensity?: number
  heatingCarbonIntensity?: number,
  coolingCarbonIntensity?: number,
  electricityConsumptionTarget?: number,
  heatingConsumptionTarget?: number,
  coolingConsumptionTarget?: number,
  waterConsumptionTarget?: number,
  gasConsumptionTarget?: number,
  electricityConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  heatingConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  coolingConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  waterConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  gasConsumptionTargetMonthlyWeighting?: MonthlyWeighting,
  waterCarbonIntensity?: number,
  wasteWaterCarbonIntensity?: number,
  wasteWaterProportion?: number,
  wasteWaterPricePerM3?: number,
  gasPricePerM3?: number,
  gasCarbonIntensity?: number,
  gasCalorificValue?: number,
  epc?: string,
  gresb?: number,
  fitwel?: number,
  breeam?: number
}

const SiteMetadataEdit = () => {
  const { site } = useSiteContext();
  const { t } = useTranslation(['settingsAsset', 'currencies']);
  const theme = useTheme();
  const { toLocale, fromLocale } = useLocalisation();
  const formMethods = useForm<FormValues>();
  const { reset, handleSubmit, formState: { errors } } = formMethods;

  const { data: siteMetadata } = useApiState({
    query: new SiteMetadataGetBySiteIdQuery(site.id.toString())
  }, [site]);

  useEffect(() => {
    const defaultValues: FormValues = {
      currency: siteMetadata?.currency,
      electricityConsumptionTarget: siteMetadata?.electricityConsumptionTarget,
      heatingConsumptionTarget: siteMetadata?.heatingConsumptionTarget,
      coolingConsumptionTarget: siteMetadata?.coolingConsumptionTarget,
      waterConsumptionTarget: siteMetadata?.waterConsumptionTarget ? toLocale(MetricType.WaterVolume, siteMetadata?.waterConsumptionTarget, { round: 4 }) : undefined,
      gasConsumptionTarget: siteMetadata?.gasConsumptionTarget ? toLocale(MetricType.GasVolume, siteMetadata?.gasConsumptionTarget, { round: 4 }) : undefined,
      electricityConsumptionTargetMonthlyWeighting: siteMetadata?.electricityConsumptionTargetMonthlyWeighting,
      heatingConsumptionTargetMonthlyWeighting: siteMetadata?.heatingConsumptionTargetMonthlyWeighting,
      coolingConsumptionTargetMonthlyWeighting: siteMetadata?.coolingConsumptionTargetMonthlyWeighting,
      waterConsumptionTargetMonthlyWeighting: siteMetadata?.waterConsumptionTargetMonthlyWeighting,
      gasConsumptionTargetMonthlyWeighting: siteMetadata?.gasConsumptionTargetMonthlyWeighting,
      electricityPricePerKwh: siteMetadata?.electricityPricePerKwh,
      heatingPricePerKwh: siteMetadata?.heatingPricePerKwh,
      waterPricePerM3: siteMetadata?.waterPricePerM3,
      targetCarbon: siteMetadata?.targetCarbon ? toLocale(AltUnits.CO2WeightArea, siteMetadata?.targetCarbon, { round: 4 }) : undefined,
      baselineCarbon: siteMetadata?.baselineCarbon ? toLocale(AltUnits.CO2WeightArea, siteMetadata?.baselineCarbon, { round: 4 }) : undefined,
      electricityCarbonIntensity: siteMetadata?.electricityCarbonIntensity ? toLocale(AltUnits.CO2WeightIntensity, siteMetadata?.electricityCarbonIntensity, { round: 4 }) : undefined,
      heatingCarbonIntensity: siteMetadata?.heatingCarbonIntensity ? toLocale(AltUnits.CO2WeightIntensity, siteMetadata?.heatingCarbonIntensity, { round: 4 }) : undefined,
      coolingCarbonIntensity: siteMetadata?.coolingCarbonIntensity ? toLocale(AltUnits.CO2WeightIntensity, siteMetadata?.coolingCarbonIntensity, { round: 4 }) : undefined,
      waterCarbonIntensity: siteMetadata?.waterCarbonIntensity ? toLocale(AltUnits.CO2SubWeightArea, siteMetadata?.waterCarbonIntensity, { round: 4 }) : undefined,
      wasteWaterCarbonIntensity: siteMetadata?.wasteWaterCarbonIntensity ? toLocale(AltUnits.CO2SubWeightArea, siteMetadata?.wasteWaterCarbonIntensity, { round: 4 }) : undefined,
      wasteWaterProportion: siteMetadata?.wasteWaterProportion,
      wasteWaterPricePerM3: siteMetadata?.wasteWaterPricePerM3,
      gasPricePerM3: siteMetadata?.gasPricePerM3,
      gasCarbonIntensity: siteMetadata?.gasCarbonIntensity ? toLocale(AltUnits.CO2SubWeightArea, siteMetadata?.gasCarbonIntensity, { round: 4 }) : undefined,
      gasCalorificValue: siteMetadata?.gasCalorificValue,
      epc: siteMetadata?.epc,
      gresb: siteMetadata?.gresb,
      fitwel: siteMetadata?.fitwel,
      breeam: siteMetadata?.breeam
    };
    reset({ ...defaultValues });
  }, [siteMetadata, reset, toLocale])


  const [editMode, setEditMode] = useState(false);
  const { execute } = useApi();

  const exitEditMode = () => {
    setEditMode(false)
    reset();
  };

  const onSave: SubmitHandler<FormValues> = async data => {
    setEditMode(false);
    let isMounted = true;

    if (!siteMetadata) {
      return;
    }

    const modifiedSiteMetadata: SiteMetadata = {
      ...siteMetadata,
      currency: data.currency,
      electricityConsumptionTarget: data.electricityConsumptionTarget,
      heatingConsumptionTarget: data.heatingConsumptionTarget,
      coolingConsumptionTarget: data.coolingConsumptionTarget,
      waterConsumptionTarget: data.waterConsumptionTarget ? fromLocale(MetricType.WaterVolume, data.waterConsumptionTarget, { round: 4 }) : undefined,
      gasConsumptionTarget: data.gasConsumptionTarget ? fromLocale(MetricType.GasVolume, data.gasConsumptionTarget, { round: 4 }) : undefined,
      electricityConsumptionTargetMonthlyWeighting: data.electricityConsumptionTargetMonthlyWeighting,
      heatingConsumptionTargetMonthlyWeighting: data.heatingConsumptionTargetMonthlyWeighting,
      coolingConsumptionTargetMonthlyWeighting: data.coolingConsumptionTargetMonthlyWeighting,
      waterConsumptionTargetMonthlyWeighting: data.waterConsumptionTargetMonthlyWeighting,
      gasConsumptionTargetMonthlyWeighting: data.gasConsumptionTargetMonthlyWeighting,
      electricityPricePerKwh: data.electricityPricePerKwh,
      heatingPricePerKwh: data.heatingPricePerKwh,
      waterPricePerM3: data.waterPricePerM3,
      targetCarbon: data.targetCarbon ? fromLocale(AltUnits.CO2WeightArea, data.targetCarbon, { round: 4 }) : undefined,
      baselineCarbon: data.baselineCarbon ? fromLocale(AltUnits.CO2WeightArea, data.baselineCarbon, { round: 4 }) : undefined,
      heatingCarbonIntensity: data.heatingCarbonIntensity ? fromLocale(AltUnits.CO2WeightIntensity, data.heatingCarbonIntensity, { round: 4 }) : undefined,
      coolingCarbonIntensity: data.coolingCarbonIntensity ? fromLocale(AltUnits.CO2WeightIntensity, data.coolingCarbonIntensity, { round: 4 }) : undefined,
      waterCarbonIntensity: data.waterCarbonIntensity ? fromLocale(AltUnits.CO2SubWeightArea, data.waterCarbonIntensity, { round: 4 }) : undefined,
      wasteWaterCarbonIntensity: data.wasteWaterCarbonIntensity ? fromLocale(AltUnits.CO2SubWeightArea, data.wasteWaterCarbonIntensity, { round: 4 }) : undefined,
      wasteWaterProportion: data.wasteWaterProportion,
      wasteWaterPricePerM3: data.wasteWaterPricePerM3,
      electricityCarbonIntensity: data.electricityCarbonIntensity ? fromLocale(AltUnits.CO2WeightIntensity, data.electricityCarbonIntensity, { round: 4 }) : undefined,
      gasPricePerM3: data.gasPricePerM3,
      gasCarbonIntensity: data.gasCarbonIntensity ? fromLocale(AltUnits.CO2SubWeightArea, data.gasCarbonIntensity, { round: 4 }) : undefined,
      gasCalorificValue: data.gasCalorificValue,
      breeam: data.breeam,
      epc: data.epc,
      fitwel: data.fitwel,
      gresb: data.gresb
    }

    if (!isMatch(siteMetadata, modifiedSiteMetadata)) {
      const siteMetadataDto = await execute({
        query: new SiteMetadataUpdateCommand(modifiedSiteMetadata),
        pendingMessage: t('Sites.SiteMetadataEditing.Saving', { ns: 'settingsAsset' }),
        successMessage: t('Sites.SiteMetadataEditing.ChangesSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Sites.SiteMetadataEditing.UpdateFailed', { ns: 'settingsAsset' })
      });

      isMounted = siteMetadataDto !== undefined;
    }

    if (!isMounted) {
      return;
    }
  }

  return (
    <>
      <RouterPrompt when={editMode} />

      <PaddedContainer>
        <FlexRow>
          {editMode &&
            <>
              <Button
                tertiary
                label={t('Cancel', { ns: 'common' })}
                onClick={() => exitEditMode()}
                color={theme.palette.red}
              />

              <Button
                label={t('Sites.SiteMetadataEditing.Save', { ns: 'settingsAsset' })}
                onClick={handleSubmit(onSave)}
                disabled={!isEmpty(errors)}
              />
            </>
          }

          {!editMode &&
            <>
              <Button
                label={t('Sites.SiteMetadataEditing.Edit', { ns: 'settingsAsset' })}
                onClick={() => setEditMode(true)}
              />
            </>
          }
        </FlexRow>

        <Form>
          <FormProvider {...formMethods}>
            <Masonry
              breakpointCols={masonryBreakpointColumns}
              className="building-edit-masonry-grid"
              columnClassName="building-edit-masonry-grid_column">
              <GeneralEdit editMode={editMode} />
              <UtilitiesEdit siteMetadata={siteMetadata} editMode={editMode} />
              <CertificationsAndRatingsEdit editMode={editMode} />
              <EmissionsEdit editMode={editMode} />
              <YearlyConsumptionTargetsEdit editMode={editMode} />
            </Masonry>
          </FormProvider>
        </Form>
      </PaddedContainer>
    </>
  );
}

export default SiteMetadataEdit;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-bottom: 20px;
`;