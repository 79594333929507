import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { PostQuery } from '../../common/PostQuery';

export default class SiteClimateControlCreateCommand extends PostQuery<SiteClimateControlDto> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return '/ClimateControl/site';
  }
}
