import { TimeGranularity } from '@api/enums/TimeGranularity';
import styled, { css } from 'styled-components';

type PropTypes = {
  timeGranularities: TimeGranularity[] | undefined;
  onChange: (index: TimeGranularity) => void,
  selectedTimeGranularity: TimeGranularity
}

const ChartTimeGranularityToggle = ({ timeGranularities, onChange, selectedTimeGranularity }: PropTypes) => {

  return (
    <Wrapper>
      {timeGranularities?.map((timeGranularity, i) => (
        <ChartTimeGranularityToggleOption
          key={`chart-select-${i}`}
          selected={timeGranularity === selectedTimeGranularity}
          onClick={() => onChange(timeGranularity)}
        >
          <div>
            {timeGranularity}
          </div>
        </ChartTimeGranularityToggleOption>
      ))}
    </Wrapper>
  );
};

export default ChartTimeGranularityToggle;

export const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  margin-left: auto;
`;

export const ChartTimeGranularityToggleOption = styled.div<{ selected?: boolean }>`
  padding: 3px 11px;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.weak};
  border: 1px solid ${p => p.theme.palette.borders.medium};
  cursor: pointer;
  border-radius: 3px;

  :first-child {
    border-width: 1px 0 1px 1px;
    border-radius: 3px 0 0 3px;
  }
  :nth-child(2) {
    border-width: 1px 1px 1px 0;
    border-radius: 0 3px 3px 0;
  }

  ${p => p.selected && css`
    color: ${p.theme.palette.text.onPrimary};
    background-color: ${p.theme.palette.primary};
    border-color: ${p.theme.palette.primary};
  `}
`;
