import { MetricType } from '@api/enums/MetricType';
import { TimeGranularity } from '@api/enums/TimeGranularity';
import { Metric } from '@api/models/Metric';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class ExternalAggregateDevicesQuery extends PostQuery<Metric[]> {
  externalAggregateDeviceId: number;
  metricTypes?: MetricType[];
  fromDateTime?: string;
  timeGranularity?: TimeGranularity;

  constructor(externalAggregateDeviceId: number, metricTypes?: MetricType[], fromDateTime?: string, toDateTime?: string, timeGranularity?: TimeGranularity) {
    super();
    this.externalAggregateDeviceId = externalAggregateDeviceId;
    this.metricTypes = metricTypes;
    this.fromDateTime = fromDateTime;
    this.timeGranularity = timeGranularity;
  }

  public targetUrl(): string {
    return '/externalaggregatedevices/ExternalAggregatedDeviceMetrics';
  }
}
