import { OperatorGetQuery } from '@api/queries/resident-app/common/OperatorGetQuery';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';
import { BlobGetSasUriDto } from '@api/models/BlobGetSasUriDto';

export class BlobResidentAppGetUriQuery extends OperatorGetQuery<BlobGetSasUriDto> {
  blobName: string;
  blobStorageContainerType: BlobStorageContainerType;

  constructor(blobName: string, blobStorageContainerType: BlobStorageContainerType) {
    super();
    this.blobName = blobName;
    this.blobStorageContainerType = blobStorageContainerType;
  }

  public targetUrl(): string {
    return `/Blob/GetSasUri/${this.blobStorageContainerType}/${this.blobName}`;
  }
}
