import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import AlertingRuleActions from './AlertListActions';
import { AlertRule } from '@api/models/AlertRule';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import NoData from '@components/core/NoData';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

interface IAlertList {
  rules: AlertRule[];
  activeRule?: AlertRule;
  refreshRules: () => void;
}

const AlertList = ({ rules, activeRule, refreshRules }: IAlertList) => {
  const { t } = useTranslation('settingsAlerting');

  return (
    <AlertListWrapper>
      <AlertListHeader>
        <span>
          {t('AlertingPage.AlertingRules', { ns: 'settingsAlerting' })}
        </span>
        <ProtectedLink link={{ path: '../new', analytics: { action: 'new_alert_rule', category: 'alerts' } }}>
          <Circle>
            +
          </Circle>
        </ProtectedLink>
      </AlertListHeader>

      <AlertListItems>
        {rules.length === 0 &&
          <NoData
            subLabel={t('NoAlertingRulesFound', { ns: 'status' })}
            icon={regular('bell-slash')}
            styles={{ margin: '80px 0 100px' }}
          />
        }

        {rules.map((rule) => (
          <ListItemLink
            key={rule.id}
            to={`../${rule.id}`}
            selected={rule.id === activeRule?.id}
          >
            {rule.name}

            <AlertingRuleActions
              rule={rule}
              refreshRules={refreshRules}
            />
          </ListItemLink>
        ))}
      </AlertListItems>
    </AlertListWrapper>
  );
};

export default AlertList;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  color: ${p => p.theme.palette.primary};
  border: 1px solid ${p => p.theme.palette.primary};
  border-radius: 50%;
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.primary};
    color: ${p => p.theme.palette.text.onPrimary};
  }
`;

const AlertListHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AlertListWrapper = styled.div`
  width: 250px;
  height: max-content;

  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 4px;
  box-shadow: 0 3px 8px ${p => p.theme.palette.shadows.medium};
  border: 1px solid ${p => p.theme.action.divider};

  display: flex;
  flex-direction: column;
`;

const AlertListItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItemLink = styled(Link) <{ selected: boolean }>`
  font-size: 14px;
  color: ${p => p.theme.text.primary};
  padding: 10px 15px;
  cursor: pointer;
  border-left: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${p => p.theme.action.divider};

  &:hover {
    background-color:  ${p => p.theme.primary.hover};
  }

  ${p => p.selected && css`
    border-left-color: ${p => p.theme.primary.main};
    background-color:  ${p => p.theme.primary.hover};
  `}
`;