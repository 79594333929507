import { EnergyMeterTopologyDto } from '@api/models/EnergyMeterTopologyDto';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class EnergyMetersBulkUpdateCommand extends PatchQuery<boolean> {
  energyMeterTopologyDtos: EnergyMeterTopologyDto[];

  constructor(dtos: EnergyMeterTopologyDto[]) {
    super();
    this.energyMeterTopologyDtos = dtos;
  }

  public targetUrl(): string {
    return '/energyMeters/UpdateMany';
  }
}
