import { User } from '@api/models/User';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class UserUpdateCommand extends PatchQuery<User> {
  fullName: string;
  displayName?: string;
  phoneNumber: string;
  mfaEnabled: boolean;
  mfaMethod: string | null;

  constructor(id: number, fullName: string, phoneNumber: string, mfaEnabled: boolean, mfaMethod: string | null, displayName?: string) {
    super(id);
    this.fullName = fullName;
    this.displayName = displayName;
    this.phoneNumber = phoneNumber;
    this.mfaEnabled = mfaEnabled;
    this.mfaMethod = mfaMethod;
  }

  public targetUrl(): string {
    return '/users';
  }
}
