import { EnergyMeter } from '@api/models/EnergyMeter';
import { GetQuery } from '@api/queries/common/GetQuery';

export class EnergyMeterGetByDeviceIdQuery extends GetQuery<EnergyMeter[]> {
  deviceId: number;

  constructor(deviceId: number) {
    super();
    this.deviceId = deviceId;
  }

  public targetUrl(): string {
    return `/energymeters/${this.deviceId}`;
  }
}
