import InactiveDeviceDetails from '@api/models/InactiveDeviceDetails';
import { GetQuery } from '@api/queries/common/GetQuery';

export class DevicesGetAllInactiveQuery extends GetQuery<InactiveDeviceDetails[]> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/devices/DevicesGetAllInactive/${this.id}`;
  }
}
