import { stringToFloat } from '@utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: Data Rate (Spread Factor)
 */
const HeatmapConfigDataRate: IHeatmapConfig = {
  accessor: (x: string) => {
    const regex = RegExp('[0-9]{1,2}')
    const result = regex.exec(x);
    return result ? stringToFloat(result[0], 0) : ''; // only return first matching group
  },

  scale: {
    points: [
      {
        threshold: 7,
        label: 'SF7',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 8,
        label: 'SF8',
        color: DefaultScaleColors.LIGHTGREEN,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 9,
        label: 'SF9',
        color: DefaultScaleColors.YELLOW,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 10,
        label: 'SF10',
        color: DefaultScaleColors.ORANGE,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 11,
        label: 'SF11',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 12,
        label: 'SF12',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      }
    ]
  }
};

export default Object.freeze(HeatmapConfigDataRate);