import { Square } from '@components/core/Square';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { useTheme } from 'styled-components';
import { SquareWithValue } from '@components/core/SquareWithValue';
import { OutOfDateCircle } from '@components/core/OutOfDateCircle';
import { Tooltip } from '@components/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { useTranslation } from 'react-i18next';
import { HeatmapData } from '@pages/site/heatmap/HeatmapPage';

type PropTypes = {
  data?: HeatmapData;
  placement: TooltipPlacement;
}

const HeatmapLegend = ({ data, placement }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const deviceOfflineThreshold = data && data.spaces.length > 0 ? data.spaces[0].deviceOfflineThreshold : undefined;

  const TooltipContent = (
    <ContentWrapper>
      <FlexRow>
        <SquareWithValue
          size='28px'
          color='#65C2FF'
          textColor='#fff'
          style={{ fontSize: 14 }}
        >
          18
        </SquareWithValue>
        {t('Heatmap.Legend.SensorOnline', { ns: 'molecules' })}
      </FlexRow>

      {deviceOfflineThreshold &&
        <FlexRow>
          <SquareWithValue
            size='28px'
            color='#65C2FF'
            textColor='#fff'
            style={{ fontSize: 14 }}
          >
            18
            <OutOfDateCircle size='11px' />
          </SquareWithValue>
          {t('Heatmap.Legend.NoReadingInXHours', { ns: 'molecules', hours: deviceOfflineThreshold })}
        </FlexRow>
      }

      <FlexRow>
        <Square size='28px' style={{ borderColor: theme.palette.borders.medium }}>
          <DeviceIssueIcon icon={regular('sensor-triangle-exclamation')} style={{ fontSize: 14 }} />
        </Square>
        {t('Heatmap.Legend.NoReadingInXDays', { ns: 'molecules' })}
      </FlexRow>

      <FlexRow>
        <Square size='28px' style={{ borderColor: theme.palette.borders.medium }}>
          <NoDeviceIcon icon={regular('xmark')} style={{ fontSize: 14 }} />
        </Square>
        {t('Heatmap.Legend.NoSensorInstalled', { ns: 'molecules' })}
      </FlexRow>
    </ContentWrapper>
  );

  return (
    <Tooltip
      content={TooltipContent}
      tooltipColor={theme.palette.backgrounds.surface}
      placement={placement}
    >
      <InfoIcon icon={regular('circle-info')} />
    </Tooltip>
  );
};

export default HeatmapLegend;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 15px 10px;

  color: ${p => p.theme.palette.text.fair};
  font-size: 14px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const DeviceIssueIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  opacity: 0.7;
  
`;

const NoDeviceIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  opacity: 0.7;
`;