import { useMsal } from '@azure/msal-react';
import { MfaMethod } from '@api/enums/MfaMethod';
import { User } from '@api/models/User';
import { Button } from '@components/core/Button';
import { authConfig } from '@src/authentication/authConfig';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  user: User;
}

const MfaSettings = ({ user }: PropTypes) => {
  const { t } = useTranslation();
  const { instance: msalInstance } = useMsal();

  if (!user.mfaEnabled) {
    return null;
  }

  const hasMfaMethod = user.mfaMethod != null && user.mfaMethod != MfaMethod.Skipped;

  const updateMfaSettings = () => {
    msalInstance.acquireTokenRedirect(authConfig.updateMfaRequest);
  }

  return (
    <CardSection>
      <Title>
        {t('MfaSettings.Title', { ns: 'settingsUser' })}
      </Title>

      <Button
        label={hasMfaMethod ? t('MfaSettings.ResetMfa', { ns: 'settingsUser' }) : t('MfaSettings.SetupMfa', { ns: 'settingsUser' })}
        onClick={updateMfaSettings}
      />
    </CardSection>
  );
};

export default MfaSettings;

const CardSection = styled.div`
  margin-top: 30px;
  padding: 30px 0 10px 0;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Title = styled.div`
  font-weight: 500;
  padding-bottom: 20px;
`;