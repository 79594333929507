import { callPatch, IAuthConfig } from '@utils/ConnectApiUtils';
import { BaseQuery } from './BaseQuery';

export abstract class PatchQuery<TDto> extends BaseQuery<TDto> {
  public id?: number;

  constructor(id?: number) {
    super();
    this.id = id;
  }

  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.apiBaseUrl);
    const response = await callPatch(apiUrl, this, authConfig, controller);

    return response.data;
  }
}
