import { Card } from '@components/core/Card';
import { groupBy } from 'lodash';
import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

export enum SkeletonLoaderType {
  Bars = 'Bars',
  Table = 'Table'
}

type TSkeletonBar = {
  w?: number; // width
  h?: number; // height
  rowIndex?: number;
}

type PropTypes = {
  type?: SkeletonLoaderType;
  bars?: TSkeletonBar[];
  numTableRows?: number;
  barStyles?: React.CSSProperties;
  wrapperStyles?: React.CSSProperties;
}

const SkeletonLoader = ({ type, bars, barStyles, wrapperStyles, numTableRows = 5 }: PropTypes) => {

  switch (type) {
    case SkeletonLoaderType.Table:
      return (
        <SkeletonTable
          numRows={numTableRows}
        />
      );

    case SkeletonLoaderType.Bars:
    default:
      return (
        <FlexColumn>
          {Object.values(groupBy(bars, x => x.rowIndex)).map((bars, i) => (
            <FlexRow key={i} style={wrapperStyles}>
              {bars.map((bar, j) => (
                <Bar
                  key={`${i}-${j}`}
                  width={bar.w}
                  height={bar.h}
                  style={barStyles}
                />
              ))}
            </FlexRow>
          ))}
        </FlexColumn>
      );
  }
}

export default SkeletonLoader

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;


//#region Skeleton Table

const SkeletonTable = ({ numRows = 5 }: { numRows: number }) => {

  return (
    <Card noPadding style={{ padding: 32 }}>
      <Bar width={200} height={18} />

      <FlexRow style={{ margin: '50px 0' }}>
        <Bar width={200} />
        <Bar width={200} />
        <Bar width={200} />
      </FlexRow>

      <TableHeader>
        <StaticBar width={60} height={24} />
        <StaticBar width={60} height={24} />
        <StaticBar width={60} height={24} />
        <StaticBar width={60} height={24} />
        <StaticBar width={60} height={24} />
      </TableHeader>

      {[...Array(numRows)].map(() => (
        <TableRow key={uuidv4()}>
          <Bar /><Bar /><Bar /><Bar /><Bar />
        </TableRow>
      ))}
    </Card>
  )
}

//#endregion

const breatheAnimation = keyframes`
to {
    background-position-x: -200%;
  }
`;

const BaseElement = styled.div`
  @media (max-width: 1120px) { 
    &:nth-child(5) {
      display: none;
    }
  }

  @media (max-width: 920px) { 
    &:nth-child(4) {
      display: none;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${p => p.theme.action.divider};
  padding: 15px 0;

  &:nth-child(2) {
    border-top: none;
  }
`;

const Bar = styled(BaseElement) <{ width?: number, height?: number }>`
  width: ${p => p.width ?? 130}px;
  height: ${p => p.height ?? 12}px;
  background: ${p => `linear-gradient(110deg, ${p.theme.skeleton.background} 8%, ${transparentize(0.8, '#fff')} 18%, ${p.theme.skeleton.background} 33%)`};
  border-radius: 30px;
  background-size: 200% 100%;
  animation: ${breatheAnimation} 2.5s linear infinite;

  @media (max-width: 720px) { 
    width: 80px;
  }

  @media (max-width: 560px) { 
    width: 40px;
  }

  @media (max-width: 400px) { 
    width: 25px;
  }
`;

const StaticBar = styled(BaseElement) <{ width?: number, height?: number }>`
  width: ${p => p.width ?? 130}px;
  height: ${p => p.height ?? 12}px;
  background: ${p => p.theme.skeleton.background};
  border-radius: 30px;
  margin-right: 70px;

  @media (max-width: 720px) { 
    width: 40px;
    margin-right: 40px;
  }

  @media (max-width: 560px) { 
    width: 20px;
    margin-right: 20px;
  }

  @media (max-width: 400px) { 
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
`;