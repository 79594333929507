import DeviceStatusCountsGetBySiteIdQuery from '@api/queries/devices/DeviceStatusCountsGetBySiteIdQuery';
import { Site } from '@api/models/Site';
import { useApiState } from '@hooks/useApiState';
import { useTranslation } from 'react-i18next';
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DefaultTheme, useTheme } from 'styled-components';
import WidgetCard from '../../WidgetCard';
import DoughnutChart from '@components/charts/DoughnutChart';
import DeviceStatusCountsDto from '@api/models/DevicesStatusCountsDto';
import DeviceStatusInfoModal from './DeviceStatusInfoModal';
import { useMemo } from 'react';
import { TDoughnutChartLegend } from '@components/charts/DoughnutChartLegend';
import { TFunction } from 'i18next';
import { createTooltipOptions } from '../temperature/ChartUtils';

const createDataset = (dto: DeviceStatusCountsDto, theme: DefaultTheme, t: TFunction) => {
  return {
    data: [dto.online, dto.total - dto.online],
    colors: [theme.widget.green, theme.palette.siteOverview.charts.secondary],
    labels: [t('Online', { ns: 'common' }), t('Offline', { ns: 'common' })]
  };
}

type PropTypes = {
  site: Site
}

const DeviceStatusWidget = ({ site }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { data, loading } = useApiState({
    query: new DeviceStatusCountsGetBySiteIdQuery(site.id)
  }, [site]);

  const chartLegend: TDoughnutChartLegend = useMemo(() => ({
    items: [
      {
        label: t('Online', { ns: 'common' }),
        color: theme.widget.green,
        link: { path: 'devices-status/online', analytics: { action: 'devices_online', category: 'overview_live_updates' } }
      },
      {
        label: t('Offline', { ns: 'common' }),
        color: theme.palette.siteOverview.charts.secondary,
        link: { path: 'devices-status/offline', analytics: { action: 'devices_offline', category: 'overview_live_updates' } }
      }
    ]
  }), [t, theme]);

  return (
    <WidgetCard
      icon={solid('wifi')}
      title={t('Devices', { ns: 'assets' })}
      titleElement={<DeviceStatusInfoModal iconStyles={{ marginLeft: 'auto' }} />}
      loading={{
        state: loading,
        icon: duotone('wifi'),
        label: t('SiteOverview.LoadingDeviceStatus', { ns: 'molecules' })
      }}
    >
      {data &&
        <DoughnutChart
          dataset={createDataset(data, theme, t)}
          size="120px"
          cutout="83%"
          arcBorderWidth={2}
          innerValue={data.online}
          innerSubLabel={`/${data.total}`}
          legend={chartLegend}
          tooltipOptions={createTooltipOptions(theme)}
        />
      }
    </WidgetCard>
  )
}

export default DeviceStatusWidget;