import { TenantFeature } from '@api/models/TenantFeature';
import { PostQuery } from '@api/queries/common/PostQuery';

export class TenantFeatureBulkCreateCommand extends PostQuery<TenantFeature> {
  tenantFeatures: TenantFeature[];

  constructor(tenantFeatures: TenantFeature[]) {
    super();
    this.tenantFeatures = tenantFeatures;
  }

  public targetUrl(): string {
    return '/features/TenantFeatureBulkCreate';
  }
}
