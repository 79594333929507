import { DeviceGateway } from '@api/models/DeviceGateway';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class DeviceGatewayUpdateCommand extends PatchQuery<DeviceGateway> {
  friendlyName: string;

  constructor(id: number, friendlyName: string) {
    super(id);
    this.friendlyName = friendlyName;
  }

  public targetUrl(): string {
    return '/gateway';
  }
}
