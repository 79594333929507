import { DeviceStatusDto } from '@api/models/DeviceStatusDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DeviceStatusGetQuery extends GetQuery<DeviceStatusDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/devices/status/${this.id}`;
  }
}
