import { BuildingsGetBySiteIdQuery } from '@api/queries/buildings/BuildingsGetBySiteIdQuery';
import AssetList from '@components/core/AssetList';
import { Asset } from '@api/models/Asset';
import { useApiState } from '@hooks/useApiState';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useTranslation } from 'react-i18next';

const SiteBuildingList = () => {
  const { site } = useSiteContext();
  const { t } = useTranslation();
  const { transform: buildings, loading } = useApiState({
    query: new BuildingsGetBySiteIdQuery(site.id),
    transformFunc: (buildings) => buildings.map(x => new Asset(x.id, x.name, x.address, x.buildingImageId, x.siteId)),
    initialTransformState: []
  }, []);

  return (
    <AssetList
      items={buildings}
      loading={loading}
      assetTypeName={t('Buildings', { ns: 'common' })}
      createlinkPath={assetId => `/site/${site.id}/building/${assetId}/settings`}
      showCreateButton
    />
  );
}

export default SiteBuildingList;