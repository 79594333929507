import styled from 'styled-components';
import useUtilityChart from './useUtilityChart';
import UnitToggle from './UnitToggle';
import { TimeRange, TimeRangeType } from '@components/time-range-select/TimeRangeSelect';
import LoadingWidget from '@components/core/LoadingWidget';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import NoData from '@components/core/NoData';
import { SiteUtilityDailyConsumptionDto } from '@api/models/SiteUtilityDailyConsumptionDto';
import { ConsumptionChartView } from './ChartViewToggle';
import { TUtilityTab } from '../SiteUtilitiesWidget';
import ChartLegend from './ChartLegend';

type PropTypes = {
  utility: TUtilityTab;
  data?: SiteUtilityDailyConsumptionDto;
  loading: boolean;
  timeRangeType: TimeRangeType;
  timeRange: TimeRange;
  view: ConsumptionChartView;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
  onCsvGenerated: (csv: string, fileName: string) => void;
};

const UtilityChart = ({ utility, data, loading, timeRangeType, timeRange, view, conversions, onSetConversions, onCsvGenerated }: PropTypes) => {
  const { t } = useTranslation();
  const { canvasRef, datasets, hiddenDatasets, toggleDataset } = useUtilityChart({ utility, data, timeRangeType, timeRange, view, applyConversion: !!conversions[utility.metricType], onCsvGenerated });

  if (loading) {
    return (
      <LoadingWidget
        icon={duotone('chart-column')}
        label={t('LoadingData', { ns: 'status' })}
        styles={{ height: 320, justifyContent: 'center' }}
        iconStyles={{ width: 30 }}
      />
    );
  }

  if (datasets?.length === 0) {
    return (
      <NoData
        large
        styles={{ height: 320, justifyContent: 'center' }}
        icon={duotone('chart-column')}
        label={t('NoUtilityData', { ns: 'status' })}
        subLabel={t('TryDifferentTimeRange', { ns: 'status' })}
      />
    );
  }

  return (
    <Wrapper>
      <UnitToggle
        utility={utility}
        conversions={conversions}
        onChange={onSetConversions}
      />

      <ChartContainer>
        <canvas id="utilityBarChart" ref={canvasRef} />
      </ChartContainer>

      <ChartLegend
        datasets={datasets}
        hiddenDatasets={hiddenDatasets}
        toggleDataset={toggleDataset}
      />
    </Wrapper>
  );
};

export default UtilityChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  margin-bottom: 25px;
  margin-left: -10px;
  transition: height 200ms ease;
`;