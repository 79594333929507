import { Route, Routes, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Route as RouteType } from '@src/types/Route';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { ProtectedRoute } from '@src/navigation/ProtectedRoute/ProtectedRoute';
import styled from 'styled-components';
import { PaddedContainer } from '@components/core/PaddedContainer';
import NoData from '@components/core/NoData';
import { useTranslation } from 'react-i18next';
import TabBar from '@components/core/TabBar';
import { useSpaceContext } from '@pages/site/buildings/floor/space/SpaceProvider';
import Sidebar from './components/layout/Sidebar';

type PropTypes = {
  routes?: RouteType[];
};

const DevicePage = ({ routes }: PropTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = useResolvedPath('').pathname;
  const { deviceId } = useParams<{ deviceId: string }>();
  const { devices } = useSpaceContext();
  const { getDeviceConfig } = useDeviceConfigContext();
  const [device, setDevice] = useState<DeviceWithMetrics>();
  const [isExternalAggregateDevice, setIsExternalAggregateDevice] = useState<boolean>(false);

  useEffect(() => {
    if (!devices) {
      return;
    }

    const device = devices.find(x => deviceId !== undefined && x.id === parseInt(deviceId));

    if (!device) {
      navigate('..');
      return;
    }

    setDevice(device);
    setIsExternalAggregateDevice(url.includes('summary'));
  }, [devices, deviceId, url, navigate]);


  if (!device) {
    return (
      <NoData
        label={t('DeviceNotFound', { ns: 'status' })}
        styles={{ margin: 80 }}
      />
    );
  }

  return (
    <Flex>
      <Sidebar device={device} />

      <Flex style={{ flexDirection: 'column' }}>
        <TabBarWrapper>
          <TabBar
            routes={routes}
            customProps={{ deviceConfig: getDeviceConfig(device.deviceModel) }}
          />
        </TabBarWrapper>

        <Content>
          <PaddedContainer>
            <Routes>
              {routes?.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      route={route}
                      customProps={{ device: device, deviceConfig: getDeviceConfig(device.deviceModel), isExternalAggregateDevice: isExternalAggregateDevice }}
                    />
                  }
                />
              ))}
            </Routes>
          </PaddedContainer>
        </Content>
      </Flex>
    </Flex>
  );
};

export default DevicePage;

const Flex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const TabBarWrapper = styled.div`
  padding: 0 40px;
  background-color: ${p => p.theme.background.container};
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;