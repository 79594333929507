import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@components/core/Loading';
import styled from 'styled-components';

type PropTypes = {
  icon?: IconDefinition;
  iconColor?: string;
  label?: string;
  styles?: React.CSSProperties;
  iconStyles?: React.CSSProperties;
};

const LoadingWidget = ({ icon, iconColor, label, styles, iconStyles }: PropTypes) => {

  return (
    <FlexColumn style={styles}>
      {icon &&
        <Icon
          icon={icon}
          color={iconColor}
          beatFade
          style={iconStyles}
        />
      }

      {label &&
        <Label>
          <Loading
            inline
            size='13px'
            style={{ marginRight: 8 }}
          />
          {label}
        </Label>
      }
    </FlexColumn>
  );
};

export default LoadingWidget;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string }>`
  width: 58px;
  height: 58px;
  color: ${p => p.color ?? p.theme.palette.primary};
  animation-duration: 1.8s;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${p => p.theme.palette.text.medium};
`;