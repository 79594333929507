import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@components/core/Loading';
import { usePopover } from '@hooks/usePopover';
import { useSize } from '@hooks/useSize';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { calculateOverflowingLabelCount } from '@utils/CascaderMultiSelectUtils';
import { ICheckboxSelect, ICheckboxSelectGroup } from './CheckboxSelect.types';
import { CheckboxSelectDropdown } from './CheckboxSelectDropdown';

export const CheckboxSelect = <TValue,>({ options: optionsProp, onChange, placeholder, loading, disabled, dropdownMaxHeight, hideSearch, alignAbove }: ICheckboxSelect<TValue>) => {
  const labelContainerRef = useRef<HTMLDivElement>(null);
  const { width: labelContainerWidth } = useSize(labelContainerRef);
  const selectFieldRef = useRef<HTMLDivElement>(null);
  const { ref, visible, toggle } = usePopover({});
  const [optionGroups, setOptionGroups] = useState<ICheckboxSelectGroup<TValue>[]>([]);
  const [selectedLabels, setSelectedlabels] = useState<string[]>([]);
  const [numHiddenItems, setNumHiddenItems] = useState<number>(0);
  const hasSomeSelected = optionGroups.some(group => group.options.some(x => x.selected));

  useEffect(() => {
    setOptionGroups(optionsProp);
  }, [optionsProp]);

  useEffect(() => {
    const selectedOptionLabels = optionGroups.flatMap(x => x.options).filter(x => x.selected).map(x => x.label);
    setSelectedlabels(selectedOptionLabels);
  }, [optionGroups]);

  // Determine how many items are not visible in the select field
  useEffect(() => {
    setNumHiddenItems(calculateOverflowingLabelCount(labelContainerRef, labelContainerWidth, selectedLabels))
  }, [labelContainerWidth, selectedLabels]);

  const handleChange = useCallback((options: ICheckboxSelectGroup<TValue>[]) => {
    onChange(options.flatMap(x => x.options).filter(x => x.selected).map(x => x.value));
  }, [onChange]);

  const onSelect = (groupIndex: number, optionIndex: number) => {
    const clonedOptions = cloneDeep(optionGroups);
    clonedOptions[groupIndex] = {
      ...clonedOptions[groupIndex],
      options: clonedOptions[groupIndex].options.map((option, i) => ({
        ...option,
        selected: i === optionIndex ? !option.selected : option.selected
      }))
    };
    setOptionGroups(clonedOptions);
    handleChange(clonedOptions);
  };

  const setAllTo = useCallback((state: boolean) => {
    const clonedOptions = cloneDeep(optionsProp).map(group => ({ ...group, options: group.options.map(opt => ({ ...opt, selected: state })) }));
    setOptionGroups(clonedOptions);
    handleChange(clonedOptions);
  }, [optionsProp, handleChange]);

  return (
    <Container ref={ref}>
      <SelectField
        ref={selectFieldRef}
        disabled={disabled}
        onClick={() => !disabled && !loading && toggle()}
      >
        {selectedLabels.length === 0 &&
          <Placeholder>
            {placeholder}
          </Placeholder>
        }

        {loading &&
          <Loading
            size="15px"
            color="grey"
            inline
            style={{ margin: '0 auto' }}
          />
        }

        <LabelContainer ref={labelContainerRef}>
          {selectedLabels.map((label) => (
            <SelectedLabel key={uuidv4()}>
              {label}
            </SelectedLabel>
          ))}
        </LabelContainer>


        {!loading &&
          <IconWrapper>
            {numHiddenItems > 0 &&
              <Count>
                +{numHiddenItems}
              </Count>
            }

            {!hasSomeSelected && !disabled &&
              <ChevronIcon icon={visible ? faChevronUp : faChevronDown} />
            }

            {hasSomeSelected && !disabled &&
              <ClearIcon
                icon={faXmark}
                onClick={(e) => {
                  e.stopPropagation();
                  setAllTo(false);
                }}
              />
            }
          </IconWrapper>
        }
      </SelectField>

      <DropdownWrapper show={visible} alignAbove={alignAbove}>
        <CheckboxSelectDropdown
          groups={optionGroups}
          onSelect={onSelect}
          selectAll={() => setAllTo(true)}
          deselectAll={() => setAllTo(false)}
          maxHeight={dropdownMaxHeight}
          hideSearch={hideSearch}
          alignAbove={alignAbove}
        />
      </DropdownWrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const SelectField = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: 38px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 5px;
  padding: 9px 54px 9px 10px;
  overflow: hidden;

  color: ${p => p.theme.text.secondary};
  background-color: ${p => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.action.outlineBorder};
  border-radius: 4px;
  box-shadow: ${p => p.theme.palette.forms.input.boxShadow};

  transition: all 150ms ease;

  &:hover {
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }

  ${p => p.disabled && css`
    background-color: ${p.theme.palette.forms.input.disabledBackground};
    cursor: not-allowed;
  `}
`;

const Placeholder = styled.div`
  font-size: 14px;
  margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 5px;
`;

const SelectedLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding: 2px 9px;
  border-radius: 14px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownWrapper = styled.div<{ show?: boolean, alignAbove?: boolean }>`
  position: absolute;
  display: ${p => p.show ? 'flex' : 'none'};
  z-index: 12;
  top: 45px;
  left: 0px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 6px 12px 0 ${p => p.theme.palette.dropdown.shadowWeak}, 0 3px 6px -4px ${p => p.theme.palette.dropdown.shadowStrong}, 0 9px 25px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  flex-direction: column;
  width: 100%;

  ${p => p.alignAbove && css`
    top: -6px;
    transform: translateY(-100%);
    box-shadow: 0 -6px 12px 0 ${p => p.theme.palette.dropdown.shadowWeak}, 0 -3px 6px -4px ${p => p.theme.palette.dropdown.shadowStrong}, 0 -9px 25px 5px rgba(0, 0, 0, 0.05);
  `}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearIcon = styled(FontAwesomeIcon)`
  width: 13px;
  height: 13px;
  color: ${p => p.theme.action.active};

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${p => p.theme.action.active};

  ${SelectField}:hover & {
    color: ${p => p.theme.primary.main};
  }
`;

const Count = styled.div`
  font-size: 12px;
  font-weight: 500;
  min-width: 18px;
  padding: 0px 5px;
  margin-right: 3px;
  border-radius: 8px;
  color: ${p => p.theme.text.contrast};
  background-color: ${p => p.theme.primary.main};
  text-align: center;
`;