import { useTranslation } from 'react-i18next';
import PreviewBanner from '@components/core/PreviewBanner';
import previewImage from '@assets/images/residentapp-preview.png'
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Title } from '@components/core/Title';

const ResidentAppPreview = () => {
  const { t } = useTranslation();

  return (
    <PaddedContainer>
      <Title
        text={t('ResidentApp.ResidentApp', { ns: 'molecules' })}
        size='lg'
        wrapperStyle={{ paddingBottom: '20px' }}
      />

      <PreviewBanner
        feature={'Resident App'}
        heading={t('ResidentApp.Preview.Heading', { ns: 'molecules' })}
        bulletPoints={[
          t('ResidentApp.Preview.P1', { ns: 'molecules' }),
          t('ResidentApp.Preview.P2', { ns: 'molecules' }),
          t('ResidentApp.Preview.P3', { ns: 'molecules' }),
          t('ResidentApp.Preview.P4', { ns: 'molecules' })
        ]}
        image={previewImage}
      />
    </PaddedContainer>
  );
};

export default ResidentAppPreview;