import previewImageDark from '@assets/images/climate-control-preview-empty-dark.svg';
import previewImage from '@assets/images/climate-control-preview-empty.svg';
import dotPattern from '@assets/images/dot-pattern-background.svg';
import { Button } from '@components/core/Button';
import { useThemeContext } from '@contexts/ThemeContext/ThemeContext';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type EmptyStateProps = {
  handleOnClick: () => void;
}

const EmptyState = ({ handleOnClick }: EmptyStateProps) => {
  const { t } = useTranslation();
  const { isDarkTheme } = useThemeContext();
  const theme = useTheme();

  return (
    <Container>
      <StyledImage
        src={dotPattern}
        alt='dot-pattern-backgound'
      />
      <Wrapper>
        <PreviewImage src={isDarkTheme ? previewImageDark : previewImage} alt='Temperature control Preview' />
        <Header>{t('ClimateControl.TemperatureControl', { ns: 'molecules' })}</Header>
        <Content>{t('ClimateControl.TemperatureControlEmptyDescription', { ns: 'molecules' })}</Content>
        <Button label={t('GetStarted', { ns: 'common' })} onClick={handleOnClick} style={{ color: theme.text.white }} analytics={{ action: 'setup_get_started', category: 'climate_control' }} />
      </Wrapper>
    </Container>
  )
}

export default EmptyState;

const Container = styled.div`
  min-width: 100%;
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  
  @media (max-width: 1200px) {
    width: 1200px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const PreviewImage = styled.img`
  width: 400px;
  height: 300px;  

  @media (max-width: 900px) {
    width: 300px;
    height: 200px;
  }
`;

const Header = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin: 0;
`;

const Content = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: center;
  color:  ${p => p.theme.text.primary};
  margin: 0;
  max-width: 600px;
`;  