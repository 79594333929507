import { Address } from '@api/models/Address';

/**
 * Returns the route for a given asset
 */
export const getAssetRoute = (id: number, siteId?: number): string => {
  if (siteId) {
    return `/site/${siteId}/building/${id}`;
  }

  return `/site/${id}`;
};

export class Asset {
  id: number;
  name: string;
  address: Address;
  imageId?: string;
  siteId?: number;
  siteName?: string;
  route: string;

  constructor(id: number, name: string, address: Address, imageId?: string, siteId?: number, siteName?: string) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.imageId = imageId;
    this.siteId = siteId;
    this.route = getAssetRoute(id, siteId);
    this.siteName = siteName;
  }
}