import { Feature } from '@api/models/Feature';
import { Tenant } from '@api/models/Tenant';
import FeatureListItem from './FeatureListItem';

interface IFeatureList {
  tenants: Tenant[];
  features: Feature[];
  refresh: () => void;
  searchString: string;
}

const FeatureList = ({ tenants, features, refresh, searchString }: IFeatureList) => {
  return (
    <>
      {features.map((feature, i) => (
        <FeatureListItem
          key={i}
          tenants={tenants}
          feature={feature}
          refresh={refresh}
          searchString={searchString}
        />
      ))}
    </>
  );
};

export default FeatureList;