import { DeviceModel } from '@api/enums/DeviceModel';
import { Device } from '@api/models/Device';
import { PostQuery } from '@api/queries/common/PostQuery';

export class DevicesGetByDeviceModelsQuery extends PostQuery<Device[]> {
  deviceModels: DeviceModel[];

  constructor(deviceModels: DeviceModel[]) {
    super();
    this.deviceModels = deviceModels;
  }

  public targetUrl(): string {
    return '/devices/DevicesGetByDeviceModels';
  }
}
