import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useApiState } from '@hooks/useApiState';
import AlertRulesGetBySiteQuery from '@api/queries/alert-rules/AlertRulesGetBySiteQuery';
import LoadingWidget from '@components/core/LoadingWidget';
import { useSiteContext } from '@pages/site/SiteProvider';
import AlertRuleManagementV2 from '../../components/AlertRuleManagementV2';

const AlertRulesPageV2 = () => {
  const { t } = useTranslation(['settingsAlerting']);
  const { site } = useSiteContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: alertingRules, loading, execute: refreshRules } = useApiState({
    query: new AlertRulesGetBySiteQuery(site.id),
  }, [site]);

  // Select first rule on page load/refresh
  useEffect(() => {
    if (!alertingRules) {
      return;
    }

    if (location.pathname.split('/').pop() === 'rules') {
      if (alertingRules.length > 0) {
        navigate(`${alertingRules[0]?.id}`);
      } else {
        navigate('new');
      }
    }
  }, [alertingRules, location.pathname, navigate]);

  if (loading) {
    return (
      <LoadingWidget
        label={t('LoadingAlertingRules', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    );
  }

  return (
    <Routes>
      {alertingRules &&
        <Route
          path=':alertRuleId'
          element={
            <AlertRuleManagementV2
              rules={alertingRules}
              refreshRules={refreshRules}
            />
          }
        />
      }
    </Routes>
  );
};

export default AlertRulesPageV2;
