import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { useTranslation } from 'react-i18next';
import { LabelValuePair } from '@components/core/LabelValuePair';
import styled from 'styled-components';
import { Button } from '@components/core/Button';
import { useModal } from '@hooks/useModal';
import ConsentModal from './ConsentModal';
import { Space } from '@api/models/Space';
import { useApiState } from '@hooks/useApiState';
import SpaceConsentGetBySpaceIdQuery from '@api/queries/space-consent/SpaceConsentGetBySpaceIdQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import dayjs from 'dayjs';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import ConsentHistoryModal from './ConsentHistoryModal';
import SpaceConsentHistoryGetBySpaceIdQuery from '@api/queries/space-consent/SpaceConsentHistoryGetBySpaceIdQuery';
import { LettingStatus } from '@api/enums/LettingStatus';

type PropTypes = {
  space: Space;
  smartMeter: DeviceWithMetrics;
}

const SmartMeterWidget = ({ space, smartMeter }: PropTypes) => {
  const { t } = useTranslation();
  const { localisation } = useLocalisation();
  const { ref, isOpen, toggle } = useModal({ disableCloseOnClickOutside: true });
  const { ref: historyModalRef, isOpen: historyModalIsOpen, toggle: toggleHistoryModal } = useModal({});

  const { data: consent, execute: refreshConsent } = useApiState({
    query: new SpaceConsentGetBySpaceIdQuery(space.id)
  }, [space]);

  const { data: consentHistory, execute: refreshConsentHistory } = useApiState({
    query: new SpaceConsentHistoryGetBySpaceIdQuery(space.id),
    initialState: []
  }, [space]);

  return (
    <>
      <Column>
        <FlexRow>
          <Title>
            {t('SmartMetering', { ns: 'common' })}
          </Title>

          <EditIcon
            icon={solid('edit')}
            onClick={toggle}
          />
        </FlexRow>

        <LabelValuePair
          label={t('LettingStatus', { ns: 'common' })}
          value={consent?.lettingStatus === LettingStatus.Let ? t('Let', { ns: 'common' }) : t('Unlet', { ns: 'common' })}
          size="xs"
        />

        <LabelValuePair
          label={t('CollectData', { ns: 'common' })}
          value={consent?.consentGranted ? t('Yes', { ns: 'common' }) : t('No', { ns: 'common' })}
          size="xs"
        />

        <LabelValuePair
          label={t('StartDate', { ns: 'common' })}
          value={consent?.startDate && dayjs(consent.startDate).format(localisation.dateFormats.date)}
          size="xs"
        />

        <LabelValuePair
          label={t('EndDate', { ns: 'common' })}
          value={consent?.endDate && dayjs(consent.endDate).format(localisation.dateFormats.date)}
          size="xs"
        />

        <Button
          small
          tertiary
          label={t('ViewHistory', { ns: 'common' })}
          onClick={toggleHistoryModal}
        />
      </Column>

      <ConsentModal
        modalRef={ref}
        isOpen={isOpen}
        toggle={toggle}
        space={space}
        device={smartMeter}
        consent={consent}
        refreshConsent={refreshConsent}
        refreshConsentHistory={refreshConsentHistory}
      />

      <ConsentHistoryModal
        modalRef={historyModalRef}
        isOpen={historyModalIsOpen}
        toggle={toggleHistoryModal}
        consentHistory={consentHistory}
      />
    </>
  );
};

export default SmartMeterWidget;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid ${p => p.theme.action.divider};
  padding: 20px 0;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
`;

const EditIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => p.theme.action.active};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;