import styled from 'styled-components';
import DeviceCardHeader from './DeviceCardHeader';
import DeviceCardCommon from './DeviceCardCommon';
import DeviceCardFooter from './DeviceCardFooter';
import DeviceCardDetails from './DeviceCardDetails';
import DeviceCardClimateControl from './DeviceCardClimateControl';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { DeviceCardTrvConfig } from './DeviceCardTrvConfig';
import DeviceCardPulseAssignments from './DeviceCardPulseAssignments';

type PropTypes = {
  device: DeviceWithMetrics,
  width?: string,
  inSidebar?: boolean
}

const DeviceCard = ({ device, width, inSidebar }: PropTypes) => {

  return (
    <Wrapper width={width}>
      <DeviceCardHeader device={device} inSidebar={inSidebar} />

      <DeviceCardCommon device={device} inSidebar={inSidebar} />

      <DeviceCardDetails device={device} inSidebar={inSidebar} />

      <DeviceCardClimateControl device={device} inSidebar={inSidebar} />

      <DeviceCardTrvConfig device={device} inSidebar={inSidebar} />

      <DeviceCardPulseAssignments device={device} inSidebar={inSidebar} />

      <DeviceCardFooter device={device} inSidebar={inSidebar} />
    </Wrapper>
  );
};

export default DeviceCard;

const Wrapper = styled.div<{ width?: string }>`
  width: ${p => p.width ?? '100%'};
`;