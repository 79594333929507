import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BackButton } from '@components/core/BackButton';
import { PaddedContainer } from '@components/core/PaddedContainer';
import styled from 'styled-components';
import { Card } from '@components/core/Card';
import SalesOrderEditForm from '../components/SalesOrderEditForm';
import { SalesOrderFormValues } from '../components/SalesOrderCreateForm';

const SalesOrderEdit = () => {
  const { salesOrderItemId } = useParams<{ salesOrderItemId: string }>();
  const { t } = useTranslation();
  const formMethods = useForm<SalesOrderFormValues>();

  if (!salesOrderItemId) {
    return
  }

  return (
    <>
      <BackButton
        label={t('BackToSalesOrder', { ns: 'navigation' })}
        url='./..'
        styles={{ margin: '20px' }}
      />
      <PaddedContainer centered>
        <Container>
          <Card>
            <FormProvider {...formMethods}>
              <SalesOrderEditForm
                itemId={salesOrderItemId}
              />
            </FormProvider>
          </Card>
        </Container>
      </PaddedContainer>
    </>
  )
}

export default SalesOrderEdit;

const Container = styled.div`
  width: 100%;
  height: 538px;
  max-width: 340px;
`;