import { BuildingHierarchiesGetBySiteId } from '@api/queries/buildings/BuildingHierarchiesGetBySiteId';
import { FlexColumn } from '@components/core/FlexColumn';
import FlexRow from '@components/core/FlexRow';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@api/enums/MetricType';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { HierarchyBuilding } from '@api/models/Hierarchy';
import SpaceClimateControlUpdateCommand from '@api/queries/climate-control/Space/SpaceClimateControlUpdateCommand';
import { Button } from '@components/core/Button';
import { DeleteDialog } from '@components/core/DeleteDialog';
import { Title } from '@components/core/Title';
import { Card } from '@components/core/Card';
import { Modal } from '@components/core/Modal';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useModal } from '@hooks/useModal';
import { dateToUtcDate } from '@utils/DateUtils';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled, { useTheme } from 'styled-components';
import { ExceptionForm } from '../types/ExceptionForm';
import CreateException from './CreateException';
import EditException from './EditException';
import ExceptionsTable from './ExceptionsTable';
import { DeleteSpaceClimateControlExceptionQuery, filterBuildingsByRelevantSpaces } from '@pages/site/temperature-control/ClimateControlUtils';

type SpaceExceptionsProps = {
  siteClimateControl: SiteClimateControlDto;
  exceptions: SpaceClimateControlDto[];
  showStatus?: boolean;
  refreshSiteClimateControl: () => void;
}

const SpaceExceptions = ({ siteClimateControl, exceptions, showStatus, refreshSiteClimateControl }: SpaceExceptionsProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const theme = useTheme();
  const { fromLocale } = useLocalisation();
  const [selectedExceptions, setSelectedExceptions] = useState<SpaceClimateControlDto[]>([]);
  const [singleSelectedException, setSingleSelectedException] = useState<SpaceClimateControlDto>();
  const smallScreen = useMediaQuery('(max-width: 900px)');
  const mobileScreen = useMediaQuery('(max-width: 375px)');
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});
  const { isOpen: createDialogIsOpen, toggle: toggleCreateDialog, ref: createDialogRef } = useModal({ disableCloseOnClickOutside: true });
  const { isOpen: editDialogIsOpen, toggle: toggleEditDialog, ref: editDialogRef } = useModal({ disableCloseOnClickOutside: true });
  const exceptionFormMethods = useForm<ExceptionForm>({ mode: 'onChange' });
  const { data: buildings } = useApiState({
    query: new BuildingHierarchiesGetBySiteId(siteClimateControl.siteId),
    initialState: [],
  }, [siteClimateControl.siteId]);
  const [relevantBuildings, setRelevantBuildings] = useState<HierarchyBuilding[]>([]);

  useEffect(() => {
    const spacesWithClimateControl = siteClimateControl.spaceClimateControls.map(x => x.spaceId);
    const spacesWithExceptions = exceptions.map(x => x.spaceId);
    const relevantSpaces = spacesWithClimateControl.filter(x => !spacesWithExceptions.includes(x));
    const filteredBuildings = filterBuildingsByRelevantSpaces(buildings, relevantSpaces);
    setRelevantBuildings(filteredBuildings);
  }, [buildings, exceptions, siteClimateControl.spaceClimateControls]);

  const hasExceptions = () => {
    return exceptions && exceptions.length > 0;
  }
  const hasErrors = (exception: ExceptionForm): boolean => {
    let hasError = false;

    if ((!exception.spaceIds || exception.spaceIds.length === 0) && exception.showEndDate && !exception.endDate) {
      exceptionFormMethods.setError('spaceIds', { type: 'required', message: t('RequiredField', { ns: 'validation' }) });
      exceptionFormMethods.setError('endDate', { type: 'required', message: t('RequiredField', { ns: 'validation' }) });
      hasError = true;
    }

    if (!exception.spaceIds || exception.spaceIds.length === 0) {
      exceptionFormMethods.setError('spaceIds', { type: 'required', message: t('RequiredField', { ns: 'validation' }) });
      hasError = true;
    }

    if (exception.showEndDate && !exception.endDate) {
      exceptionFormMethods.setError('endDate', { type: 'required', message: t('RequiredField', { ns: 'validation' }) });
      hasError = true;
    }

    return hasError;
  }

  const onDeleteException = async (exceptionsToDelete: SpaceClimateControlDto[]) => {
    if (exceptionsToDelete.length > 0) {
      const deletionPromises = exceptionsToDelete.map((exception: SpaceClimateControlDto) =>
        execute({
          query: DeleteSpaceClimateControlExceptionQuery(exception)
        })
      );
      try {
        await Promise.all(deletionPromises);
        toast.success(t('ChangesSaveSuccess', { ns: 'status' }));
      } catch (error) {
        toast.error(t('ChangesSaveError', { ns: 'status' }));
      }
      finally {
        toggleDeleteDialog();
        setSingleSelectedException(undefined);
        refreshSiteClimateControl();
      }
    }
  }

  const onCreateException = async () => {
    const exception = exceptionFormMethods.getValues();

    if (hasErrors(exception)) {
      return;
    }

    const createPromises = exception.spaceIds.map((spaceId: number) => {
      const spaceClimateControl = siteClimateControl.spaceClimateControls.find(x => x.spaceId === spaceId);

      if (spaceClimateControl) {
        const modified = {
          ...spaceClimateControl,
          minTemp: fromLocale(MetricType.Temperature, exception.minTemp),
          maxTemp: fromLocale(MetricType.Temperature, exception.maxTemp),
          targetTemp: fromLocale(MetricType.Temperature, exception.targetTemp),
          controlMode: exception.controlMode,
          endDate: exception.endDate ? dateToUtcDate(exception.endDate).toISOString() : undefined
        };

        return execute({
          query: new SpaceClimateControlUpdateCommand(modified, true)
        })
      }
    }
    );

    try {
      await Promise.all(createPromises);
    } catch (error) {
      toast.error(t('ChangesSaveError', { ns: 'status' }));
    }
    finally {
      refreshSiteClimateControl();
      toggleCreateDialog();
      toast.success(t('ChangesSaveSuccess', { ns: 'status' }));
      exceptionFormMethods.reset();
    }
  }

  const onEditException = async () => {
    exceptionFormMethods.trigger();
    const exception = exceptionFormMethods.getValues();

    if (hasErrors(exception)) {
      return;
    }

    const editPromises = exception.spaceIds.map((spaceId: number) => {
      const spaceClimateControl = siteClimateControl.spaceClimateControls.find(x => x.spaceId === spaceId);

      if (spaceClimateControl) {
        const modified = {
          ...spaceClimateControl,
          minTemp: fromLocale(MetricType.Temperature, exception.minTemp),
          maxTemp: fromLocale(MetricType.Temperature, exception.maxTemp),
          targetTemp: fromLocale(MetricType.Temperature, exception.targetTemp),
          controlMode: exception.controlMode,
          endDate: exception.endDate ? dateToUtcDate(exception.endDate).toISOString() : undefined
        };

        return execute({
          query: new SpaceClimateControlUpdateCommand(modified)
        })
      }
    }
    );

    try {
      await Promise.all(editPromises);
    } catch (error) {
      toast.error(t('ChangesSaveError', { ns: 'status' }));
    }
    finally {
      setSingleSelectedException(undefined);
      refreshSiteClimateControl();
      toggleEditDialog();
      toast.success(t('ChangesSaveSuccess', { ns: 'status' }));
      exceptionFormMethods.reset();
    }
  }

  return (
    <>
      <DeleteDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        header={selectedExceptions.length > 1 && !singleSelectedException ?
          t('ClimateControl.DeleteMultipleExceptionsModalHeader', { ns: 'molecules', numberOfExceptions: selectedExceptions.length }) :
          t('ClimateControl.DeleteExceptionModalHeader', { ns: 'molecules' })
        }
        body={selectedExceptions.length > 1 && !singleSelectedException ?
          t('ClimateControl.DeleteMultipleExceptionsModalContent', { ns: 'molecules' }) :
          t('ClimateControl.DeleteExceptionModalContent', { ns: 'molecules' })
        }
        onCancel={() => {
          if (singleSelectedException) {
            setSingleSelectedException(undefined)
          }
          toggleDeleteDialog()
        }}
        onConfirm={() => {
          if (singleSelectedException) {
            onDeleteException([singleSelectedException])
          } else {
            onDeleteException(selectedExceptions)
          }
        }}
      />

      <Modal
        ref={createDialogRef}
        isOpen={createDialogIsOpen}
        hide={toggleCreateDialog}
        plainModal={true}
        width={'450px'}
      >
        <FormProvider {...exceptionFormMethods}>
          <CreateException
            buildings={relevantBuildings}
            siteClimateControl={siteClimateControl}
            onCreateException={onCreateException}
            onCancel={toggleCreateDialog} />
        </FormProvider>
      </Modal>

      <Modal
        ref={editDialogRef}
        isOpen={editDialogIsOpen}
        hide={toggleEditDialog}
        plainModal={true}
        width={'450px'}
      >
        <FormProvider {...exceptionFormMethods}>
          <EditException
            singleSelectedException={singleSelectedException}
            selectedExceptions={selectedExceptions}
            externalSensorModeEnabled={siteClimateControl.externalSensorModeEnabled}
            onEditException={onEditException}
            onCancel={() => {
              if (singleSelectedException) {
                setSingleSelectedException(undefined)
              }
              toggleEditDialog()
            }} />
        </FormProvider>
      </Modal>

      <Card style={{ paddingBottom: 10 }}>
        <StyledFlexColumn>
          <ExceptionsHeader hasExcpetions={hasExceptions()}>
            <StyledFlexRow>
              <Title
                text={t('Exceptions', { ns: 'molecules' })}
              />
            </StyledFlexRow>

            {!hasExceptions() &&
              <StyledLabel>
                {t('ClimateControl.ExceptionsDescription', { ns: 'molecules' })}
              </StyledLabel>
            }

            <StyledFlexRow>
              {(hasExceptions() && selectedExceptions.length > 0) &&
                <>
                  <Button
                    label={
                      <>
                        <ActionButtonIcon icon={solid('trash')} />
                        {!smallScreen &&
                          `${selectedExceptions.length === 1 ? t('ClimateControl.DeleteException', { ns: 'molecules' }) : t('ClimateControl.DeleteExceptions', { ns: 'molecules' })}`
                        }
                        &nbsp;({selectedExceptions.length})
                      </>
                    }
                    tertiary
                    color={theme.palette.red}
                    style={{ padding: smallScreen ? '8px' : '8px 16px' }}
                    onClick={() => toggleDeleteDialog()}
                  />
                  <Button
                    label={
                      <>
                        <ActionButtonIcon icon={solid('pen-to-square')} />
                        {!smallScreen &&
                          `${selectedExceptions.length === 1 ? t('ClimateControl.EditException', { ns: 'molecules' }) : t('ClimateControl.EditExceptions', { ns: 'molecules' })}`
                        }
                        &nbsp;({selectedExceptions.length})
                      </>
                    }
                    tertiary
                    style={{ padding: smallScreen ? '8px' : '8px 16px' }}
                    onClick={() => toggleEditDialog()}
                  />
                </>
              }
              {relevantBuildings.length > 0 &&
                <Button
                  label={
                    <>
                      <PlusIcon icon={solid('plus')} />
                      {!mobileScreen &&
                        t('ClimateControl.CreateException', { ns: 'molecules' })
                      }
                    </>
                  }
                  style={{ padding: mobileScreen ? '8px' : '8px 16px' }}
                  onClick={toggleCreateDialog}
                />
              }
            </StyledFlexRow>
          </ExceptionsHeader>
          {hasExceptions() &&
            <StyledFlexRow style={{ marginBottom: 10 }}>
              <StyledLabel>
                {t('ClimateControl.ExceptionsDescription', { ns: 'molecules' })}
              </StyledLabel>
            </StyledFlexRow>
          }
          {exceptions && exceptions.length > 0 &&
            <ExceptionsTable
              exceptions={exceptions}
              selectable={true}
              showStatus={showStatus}
              showActions={true}
              setSelectedExceptions={setSelectedExceptions}
              onEditException={(exception: SpaceClimateControlDto) => {
                setSingleSelectedException(exception)
                toggleEditDialog()
              }}
              onDeleteException={(exception: SpaceClimateControlDto) => {
                setSingleSelectedException(exception)
                toggleDeleteDialog()
              }}
            />
          }
        </StyledFlexColumn>
      </Card>
    </>
  )
}

export default SpaceExceptions

const StyledFlexColumn = styled(FlexColumn)`
  gap: 10px;
`;

const StyledFlexRow = styled(FlexRow) <{ disabled?: boolean }>`
  align-items: center;
  gap: 10px;
  opacity: ${p => p.disabled ? 0.5 : 1};
  transition: opacity .1s;
`;

const ExceptionsHeader = styled.div<{ hasExcpetions?: boolean }>`
  display: flex;
  flex-direction: ${p => p.hasExcpetions ? 'row' : 'column'};
  justify-content: space-between;
  align-items: ${p => p.hasExcpetions ? 'center' : 'flex-start'};
  gap: 20px;

  ${p => !p.hasExcpetions && 'margin-bottom: 20px;'}
`;

const StyledLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${p => p.theme.text.secondary};
`;

const ActionButtonIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const PlusIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;

  @media (max-width: 375px) {
    margin-right: 0;
  }
`;