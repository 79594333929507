import { MetricType } from '@api/enums/MetricType';

/**
 * 
 */
export enum DeviceWidgetType {
  List = 'List',
  Score = 'Score',
  RadarChart = 'RadarChart',
  Meter = 'Meter',
  Multisensor = 'Multisensor'
}

/**
 * An interface defining which widgets to display for a device.
 * The type determines which widget component is rendered, hence the appropriate config must be defined,
 * e.g. when the type is "List", the "listWidget" config must be defined
 */
export interface IWidgetConfig {
  type: DeviceWidgetType;
  group?: IWidgetGroup;
  minWidth?: string;
  listWidget?: IListWidgetConfig;
  scoreWidget?: IScoreWidgetConfig;
  meterWidget?: IMeterWidgetConfig;
  radarChartWidget?: IRadarChartWidgetConfig;
  multisensorWidget?: IMultisensorWidgetConfig;
}

export interface IWidgetGroup {
  name: string;
  sortOrder: number;
}

/**
 * A list of metrics, each displayed as a LabelValuePair with an optional icon
 * Used for: ListWidget
 */
export interface IListWidgetConfig {
  metrics: IListWidgetMetric[];
}

export interface IListWidgetMetric {
  metricType: MetricType;                      // (Required) The metric to get the value from
  label?: string;                              // The label displayed above the value. Defaults to the MetricType's display string if undefined
  icon?: {
    name: string;                              // Icon name from the FontAwesome Pro solid/regular collection (e.g. "faBolt")
    collection?: FontAwesomeCollection;        // Which FontAwesome Pro collection to load the icon from (Default: 'Regular')
    color?: string;                            // Color code (e.g. "#FFCC00"), defaults to the theme's primary color if undefined
    colorThresholds?: IColorThreshold[];       // If the 'color' property is defined, these thresholds are ignored. Only specify one or the other
    rotate?: {
      angleMetricType?: MetricType;
      angleOffset?: number;
    };
  };
}

export interface IColorThreshold {
  threshold: string | number | Date;             // (Required) Threshold, compared with the '<' operator (e.g. if value < threshold then use this color). If value is >= than last threshold, the color of last threshold is applied.
  color: string;                                 // (Required) Color code (e.g. "#FFCC00")
}

export enum FontAwesomeCollection {
  'Regular' = 'Regular',
  'Solid' = 'Solid'
}

/**
 * A list of metrics, each defining one data point on a radar chart
 * Used for: RadarChartWidget
 */
export interface IRadarChartWidgetConfig {
  metrics: {
    metricType: MetricType;              // (Required) The metric to get the value from
    label?: string;                      // The label displayed on the chart. Defaults to the MetricType's display string if undefined
    tooltipValue?: {
      prefix?: string;                   // A prefix for the tooltip value
      suffix?: string;                   // A suffix for the tooltip value
    };
    secondTooltipValue?: {               // An optional second row in the tooltip to show a related metric
      metricType?: MetricType;           // The metric to get the value from
      prefix?: string;                   // A prefix for the additional tooltip value
      suffix?: string;                   // A suffix for the additional tooltip value
    };
  }[];
}

/**
 * A score value from a metric with an optional grade label
 * Used for: ScoreWidget
 */
export interface IScoreWidgetConfig {
  score: {
    metricType: MetricType;              // (Required) The metric to get the score value from 
    decimalPlaces?: number;              // The number of decimal places when rounding the value (Default: 0)
  };
  maxScore: number;                      // (Required) The maximum score
  label: string;                         // (Required) A label describing what the score represents
  grades?: IGradeConfig[];               // Grade thresholds. No grade label is displayed if undefined
}

/**
 * A widget for the wellness score, radar chart and metrics for the multisensor
 * Used for: MultisensorWidget
 */
export interface IMultisensorWidgetConfig {
  // Score widget & radar config
  scoreWidget: IScoreWidgetConfig,
  radarChartWidget: IRadarChartWidgetConfig,
  metrics : MetricType[]
}

export interface IGradeConfig {
  threshold: number;                     // (Required) Threshold, compared with the '<' operator (e.g. if score < threshold then use this label and color). If score is >= than last threshold, the color of last threshold is applied.
  label: string;                         // (Required) Grade label
  color: string;                         // (Required) Color code (e.g. "#FFCC00")
}

/**
 * 
 * Used for: MeterWidget
 */
export interface IMeterWidgetConfig {
  metricType: MetricType;                // (Required) The metric to get the value from
  maxValue?: number;                     // The maximum value (default: maxOccupancy value of a space)
  label?: string;                        // The label displayed above meter bar. Defaults to the MetricType's display string if undefined
  decimalPlaces?: number;                // The number of decimal places when rounding the value (Default: 0)
}