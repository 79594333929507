import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DeviceGetByIdQuery } from '@api/queries/devices/DeviceGetByIdQuery';
import DeviceDeleteCommand from '@api/queries/devices/DeviceDeleteCommand';
import DeviceUpdateCommand from '@api/queries/devices/DeviceUpdateCommand';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { Device } from '@api/models/Device';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { WarningDialog } from '@components/core/WarningDialog';
import { RouterPrompt } from '@src/navigation/RouterPrompt/RouterPrompt';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { valuesDiffer } from '@utils/ObjectUtils';
import { Card } from '@components/core/Card';
import { ErrorMessage, Input, Label } from '@components/Form';
import { Button } from '@components/core/Button';
import { useModal } from '@hooks/useModal';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SpaceClimateControlGetByTempMonitoringDeviceQuery } from '@api/queries/climate-control/Space/SpaceClimateControlGetByTempMonitoringDeviceQuery';
import { TRVControlMode } from '@api/enums/TRVControlMode';
import { BackButton } from '@components/core/BackButton';
import { EnergyMeterGetByDeviceIdQuery } from '@api/queries/energy-meters/EnergyMetersGetByDeviceIdQuery';
import { Tooltip } from '@components/core/Tooltip';

type FormValues = {
  friendlyName: string
}

const DeviceEdit = () => {
  const { t } = useTranslation(['settingsDevice']);
  const theme = useTheme();
  const { getDisplayString } = useDeviceConfigContext();
  const { deviceId } = useParams<{ deviceId: string }>();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [executePostSaveAction, setExecutePostSaveAction] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValues>();
  const formValues = watch();
  const { execute } = useApi();
  const { isOpen: deleteDialogIsOpen, toggle: deleteDialogToggle, ref: deleteDialogRef } = useModal({});
  const { data: device, setData: setDevice } = useApiState({
    query: deviceId === undefined ? undefined : new DeviceGetByIdQuery(parseInt(deviceId))
  }, [deviceId]);
  const { transform: isExternalSensor } = useApiState({
    query: deviceId === undefined ? undefined : new SpaceClimateControlGetByTempMonitoringDeviceQuery(parseInt(deviceId)),
    transformFunc: spaceClimateControl => !!spaceClimateControl && (spaceClimateControl.controlMode ?? spaceClimateControl.siteClimateControl.controlMode) === TRVControlMode.External
  }, [deviceId]);
  const [isBillingMeterWithMeasurements, setIsBillingMeterWithMeasurements] = useState<boolean>(false);

  useEffect(() => {
    if (device) {
      const hasBeenModified = valuesDiffer<FormValues, Device>(formValues, device);
      setHasUnsavedChanges(hasBeenModified);
    }
  }, [device, formValues]);

  const { data: energyMeter } = useApiState({
    query: device === undefined ? undefined : new EnergyMeterGetByDeviceIdQuery(device.id),
  }, [device]);

  useEffect(() => {
    if (energyMeter && energyMeter.some(x => x.isBillingMeter && x.device.lastMeasuredOn)) {
      setIsBillingMeterWithMeasurements(true)
    }
  }, [energyMeter])

  /**
   * Execute after saving, redirect back to the device list
   */
  useEffect(() => {
    if (executePostSaveAction && !hasUnsavedChanges) {
      navigate('../devices');
    }
  }, [executePostSaveAction, hasUnsavedChanges, navigate]);

  if (!device) {
    return null;
  }

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const modifiedDevice = cloneDeep(device);
    modifiedDevice.friendlyName = data.friendlyName;

    if (!valuesDiffer(modifiedDevice, device)) {
      return;
    }

    const result = await execute({
      query: new DeviceUpdateCommand(device.id, modifiedDevice.friendlyName),
      successMessage: t('DeviceEditing.ChangesSaved', { ns: 'settingsDevice' }),
      errorMessage: t('DeviceEditing.SavingFailed', { ns: 'settingsDevice' })
    });

    if (result === undefined) {
      return;
    }

    setDevice(modifiedDevice);
    setSavingInProgress(false);
    setExecutePostSaveAction(true);
  }

  const onDelete = async () => {
    setHasUnsavedChanges(false); // so that the RouterPrompt doesn't trigger as well

    await execute({
      query: new DeviceDeleteCommand(device.id),
      successMessage: t('DeviceEditing.DeviceDeleted', { ns: 'settingsDevice' }),
      errorMessage: t('DeviceEditing.DeviceDeleteFailed', { ns: 'settingsDevice' })
    });

    navigate('../devices');
  }

  return (
    <PaddedContainer centered>
      <RouterPrompt when={hasUnsavedChanges} />

      <Container>
        <BackButton label={t('BackToDevices', { ns: 'navigation' })} url="../devices" />

        <WarningDialog
          modalRef={deleteDialogRef}
          isOpen={deleteDialogIsOpen}
          sectionOne={t('DeviceEditing.DeleteDialogSectionOne', { ns: 'settingsDevice' })}
          sectionTwo={t('DeviceEditing.DeleteDialogSectionTwo', { ns: 'settingsDevice' })}
          confirmButton={t('DeviceEditing.Delete', { ns: 'settingsDevice' })}
          confirmationCheckbox={t('DeviceEditing.Understand', { ns: 'settingsDevice' })}
          onCancel={deleteDialogToggle}
          onConfirm={onDelete}
        />

        <Card cardTitle={t('DeviceEditing.EditFriendlyName', { ns: 'settingsDevice' })}>
          <FormContainer>
            <FlexRow>
              <Column>
                <Label>{t('DeviceEditing.FriendlyName', { ns: 'settingsDevice' })}</Label>
                <Input
                  {...register('friendlyName', {
                    required: t('DeviceEditing.RequiredField', { ns: 'settingsDevice' }),
                  })}
                  defaultValue={device.friendlyName}
                />
                <ErrorMessage>{errors.friendlyName?.message}</ErrorMessage>
              </Column>
            </FlexRow>
            <FlexRow>
              <Column>
                <Label>{t('DeviceEditing.SpaceName', { ns: 'settingsDevice' })}</Label>
                <Input type="deviceIdentifier" defaultValue={device.spaceName} disabled />
              </Column>
            </FlexRow>
            <FlexRow>
              <Column>
                <Label>{t('DeviceEditing.DeviceModel', { ns: 'settingsDevice' })}</Label>
                <Input
                  type="deviceModel"
                  defaultValue={getDisplayString(device.deviceModel)}
                  disabled
                />
              </Column>
            </FlexRow>
            <FlexRow>
              <Column>
                <Label>{t('DeviceEditing.LastMeasuredOn', { ns: 'settingsDevice' })}</Label>
                <Input
                  type="deviceIdentifier"
                  defaultValue={
                    device.lastMeasuredOn
                      ? formatDistance(new Date(device.lastMeasuredOn), new Date(), {
                        addSuffix: true,
                      })
                      : 'Never'
                  }
                  disabled
                />
              </Column>
            </FlexRow>
            <FlexRow>
              <Column>
                <Label>{t('DeviceEditing.DeviceIdentifier', { ns: 'settingsDevice' })}</Label>
                <Input type="deviceIdentifier" defaultValue={device.deviceIdentifier} disabled />
              </Column>
            </FlexRow>
          </FormContainer>
        </Card>

        <Card>
          <div style={{ display: 'flex' }}>
            <Tooltip
              content={
                isBillingMeterWithMeasurements
                  ? t('DeviceEditing.CannotDeleteBillingMeterMessage', { ns: 'settingsDevice' })
                  : ''
              }
            >
              <Button
                label={
                  <>
                    {t('DeviceEditing.DeleteDevice', { ns: 'settingsDevice' })}
                    <FontAwesomeIcon icon={solid('trash')} style={{marginLeft: 8}}/>
                  </>
                }
                onClick={deleteDialogToggle}
                disabled={isExternalSensor || isBillingMeterWithMeasurements}
                color={theme.error.main}
              />
            </Tooltip>
            <div style={{ marginLeft: 'auto' }} />
            <Link to={'../devices'}>
              <Button
                color={theme.palette.red}
                tertiary
                label={t('Cancel', { ns: 'common' })}
                disabled={isExternalSensor}
              />
            </Link>
            <Button
              label={t('DeviceEditing.Save', { ns: 'settingsDevice' })}
              onClick={handleSubmit(onSave)}
              disabled={!hasUnsavedChanges || !isEmpty(errors)}
              loading={savingInProgress}
            />
          </div>

          {isExternalSensor && (
            <InfoBox>
              <InfoIcon icon={regular('circle-info')} />
              {t('DeviceEditing.ClimateControlDeviceMessage', { ns: 'settingsDevice' })}
            </InfoBox>
          )}
        </Card>
      </Container>
    </PaddedContainer>
  );
};

export default DeviceEdit;

const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  width: 100%;
`;

const InfoBox = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.medium};
  padding: 10px;
  margin-top: 20px;

  border-radius: 5px;
  border: 1px dashed ${p => transparentize(0.4, p.theme.palette.primary)};
  background-color: ${p => transparentize(0.95, p.theme.palette.primary)};
  transition: height 300ms ease;
    
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => transparentize(0.1, p.theme.palette.primary)};
  margin-right: 10px;
`;