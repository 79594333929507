import { OperatorChallengeDto } from '@api/models/OperatorChallengeDto';
import { OperatorPatchQuery } from '@api/queries/resident-app/common/OperatorPatchQuery';

export class ChallengesResidentAppCancelCommand extends OperatorPatchQuery<OperatorChallengeDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return '/Challenges/Cancel';
  }
}
