import styled from 'styled-components';
import { TDataset } from './useChart';
import DashedLine from '@components/charts/legend/DashedLine';

type PropTypes = {
  dataset: TDataset;
}

const LegendItem = ({ dataset }: PropTypes) => {

  const hasDashes = !!dataset.borderDash;

  return (
    <Container>
      {hasDashes
        ? <DashedLine color={dataset.borderColor as string} />
        : <Square borderColor={dataset.borderColor as string} backgroundColor={dataset.backgroundColor as string} />
      }

      {dataset.label}
    </Container>
  );
};

export default LegendItem;

const Container = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
  display: flex;
  gap: 5px;
  align-items: center;
  user-select: none;
`;

const Square = styled.div<{ borderColor: string, backgroundColor: string }>`
  width: 12px;
  height: 12px;
  border-radius: 4px;
  border: 1px solid ${p => p.borderColor};
  background-color: ${p => p.backgroundColor};
`;