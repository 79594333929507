import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { usePopover } from '@hooks/usePopover';
import { Route } from '@src/types/Route';
import { BreadcrumbHeight } from '@src/constants/LayoutConstants';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  route: Route;
}

const TabBarDropdown = ({ route }: PropTypes) => {
  const { t } = useTranslation()
  const { ref, visible, toggle } = usePopover({});
  const targetDivRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={ref} id="popover">
      <StyledDropdown isVisible={visible}>
        {route.children?.map(x => x.link?.label && (
          <LinkInDropdown
            key={x.path}
            link={x.link}
            onClick={toggle}
          >
            {t(x.link.label, { ns: 'routes' })}
          </LinkInDropdown>
        ))}
      </StyledDropdown>

      <StyledLink ref={targetDivRef} onClick={toggle}>
        {route.link?.label && t(route.link.label, { ns: 'routes' })}
        <ChevronIcon icon={visible ? solid('chevron-up') : solid('chevron-down')} />
      </StyledLink>
    </Wrapper>
  );
};

export default TabBarDropdown;

const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`;

const StyledDropdown = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: ${BreadcrumbHeight + 7}px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  overflow: hidden;
  padding: 10px 12px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0px 0px 12px -5px ${p => p.theme.palette.shadows.strong};
  border-radius: 5px;
  z-index: 1;
  
  display: ${p => p.isVisible ? 'flex' : 'none'};
  flex-direction: column;
`;

const StyledLink = styled.div`
  padding: 15px 16px;
  cursor: pointer;
  color: ${p => p.theme.text.secondary} !important;

  &.active {
    color: ${p => p.theme.text.primary} !important;
  }
`;

const LinkInDropdown = styled(ProtectedLink)`
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary} !important;
  border-radius: 5px;
  padding: 10px 30px 10px 15px;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};
  }
  
  &.active {
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};
  }

  &.active,
  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1px;
      width: 3px;
      height: 15px;
      border-radius: 8px;
      background-color: ${p => p.theme.primary.main};
    }
  }
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  font-size: 10px;
  margin-left: 8px;
`;