import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect, useState } from 'react';
import EsgExplorerRuleDto from '@api/models/EsgExplorerRuleDto';
import { useApi } from '@hooks/useApi';
import EsgExplorerRuleUpdateCommand from '@api/queries/esg-explorer/EsgExplorerRuleUpdateCommand';
import EsgExplorerRulePeriod from '@api/enums/EsgExplorerRulePeriod';
import { Switch } from '@components/core/Switch';
import RuleModal from './RuleModal';
import { useModal } from '@hooks/useModal';
import { useTranslation } from 'react-i18next';

interface IRule {
  rule: EsgExplorerRuleDto;
  refreshRules: () => void;
  siteName: string;
}

const Rule = ({ rule, refreshRules, siteName }: IRule) => {
  const { t } = useTranslation(['status']);
  const { execute } = useApi();
  const { isOpen: isModalOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const [enabled, setEnabled] = useState(rule.enabled);

  useEffect(() => {
    setEnabled(rule.enabled);
  }, [rule]);

  const updateRule = async (enabledState: boolean) => {
    setEnabled(enabledState);

    await execute({
      query: new EsgExplorerRuleUpdateCommand(rule.id, enabledState),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });
  }

  return (
    <>
      <RuleModal
        modalRef={modalRef}
        isOpen={isModalOpen}
        toggle={toggleModal}
        refreshRules={refreshRules}
        rule={rule}
        siteName={siteName}
      />

      <RowWrapper>
        <StyledRow onClick={() => toggleModal()}>
          <Value style={{ width: 130 }}>
            {rule.name}
          </Value>
          <Value style={{ width: 100 }}>
            <FlexRow>
              <InfoCardIcon icon={regular('calendar-range')} />
              <span style={{ marginTop: '2px' }}>
                {EsgExplorerRulePeriod.getDisplayString(rule.period)}
              </span>
            </FlexRow>
          </Value>
          <Value style={{ width: 60 }}>
            <Switch
              checked={enabled}
              onChange={() => updateRule(!enabled)}
            />
          </Value>
        </StyledRow>
      </RowWrapper>
    </>
  );
}

export default Rule;

const RowWrapper = styled.div`
  width: 100%;
  padding: 18px 15px;

  &:nth-child(odd) {
    background-color: ${(p) => p.theme.palette.tables.evenRow};
  }

  &:nth-child(even) {
    background-color: ${(p) => p.theme.palette.tables.unevenRow};
  }

  &:hover {
    background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  > * {
    flex-shrink: 0;
    word-wrap: break-word;
  }
`;

const Value = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const InfoCardIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;
  color: ${p => transparentize(0.2, p.theme.palette.primary)};
`;