import { SpaceType } from './SpaceType';

/**
 * Get color
 */
export const SpaceType_Color = (spaceType: SpaceType): string => {
  return spaceTypeToColor[spaceType] ?? '#000';
};

/**
 * Map SpaceType to a color
 */
const spaceTypeToColor: { [key in SpaceType]: string } = Object.freeze({
  /** Common */
  [SpaceType.PrivateDining]: '#FFAA37',
  [SpaceType.Reception]: '#603FAD',
  [SpaceType.Gym]: '#FF733D',
  [SpaceType.Cinema]: '#863EFF',
  [SpaceType.Cupboard]: '#40adc0',
  [SpaceType.Kitchen]: '#45a902',
  [SpaceType.Bathroom]: '#516EFF',
  [SpaceType.Toilet]: '#516EFF',
  [SpaceType.Elevator]: '#40adc0',
  [SpaceType.Library]: '#00AEED',
  [SpaceType.Lounge]: '#00AEED',
  [SpaceType.TvRoom]: '#00AEED',
  [SpaceType.GamesRoom]: '#00AEED',
  [SpaceType.Study]: '#00AEED',
  [SpaceType.MailRoom]: '#00AEED',
  [SpaceType.CycleStore]: '#00AEED',
  [SpaceType.LaundryRoom]: '#00AEED',
  [SpaceType.PlantRoom]: '#00AEED',
  [SpaceType.BinStore]: '#00AEED',
  [SpaceType.Other]: '#8a94bd',

  /** Office */
  [SpaceType.Boardroom]: '#ff4b5a',
  [SpaceType.MeetingRoom]: '#ff4b5a',
  [SpaceType.OpenPlan]: '#0F93C9',
  [SpaceType.PrivateOffice]: '#FF6352',

  /** Apartment */
  [SpaceType.Studio]: '#0F93C9',
  [SpaceType.OneBed]: '#1D77A4',
  [SpaceType.TwoBed]: '#1D77A4',
  [SpaceType.ThreeBed]: '#1D77A4',
  [SpaceType.FourBed]: '#1D77A4',
  [SpaceType.DuplexTwoBed]: '#586289',
  [SpaceType.DuplexThreeBed]: '#586289',
  [SpaceType.DuplexFourBed]: '#586289',
  [SpaceType.SharerTwoBed]: '#3B6D97',
  [SpaceType.SharerThreeBed]: '#3B6D97',
  [SpaceType.SharerFourBed]: '#3B6D97',
  [SpaceType.SharerFiveBed]: '#3B6D97',
  [SpaceType.SharerSixBed]: '#3B6D97',
  [SpaceType.SharerSevenBed]: '#3B6D97',
  [SpaceType.SharerEightBed]: '#3B6D97',
  [SpaceType.Penthouse]: '#2C5C7F',
  [SpaceType.LKD]: '#3B6D97',
  [SpaceType.Bedroom]: '#1D77A4'
});