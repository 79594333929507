import { PostQuery } from '@api/queries/common/PostQuery';
import { NewFeedback } from '@api/models/NewFeedback';

export class FeedbackCreateCommand extends PostQuery<NewFeedback> {
  email: string;
  feedbackComments: string;

  constructor(feedback: NewFeedback) {
    super();
    this.email = feedback.email;
    this.feedbackComments = feedback.feedbackComments;
  }

  public targetUrl(): string {
    return '/feedback';
  }
}
