import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import DailyConsumptionChartWrapper from './DailyConsumptionChartWrapper';
import MonthlyConsumptionChartWrapper from './MonthlyConsumptionChartWrapper';
import MonthlyConsumptionChartLegend from './MonthlyConsumptionChartLegend';
import CarbonEmissions from './CarbonEmissions';
import { useTranslation } from 'react-i18next';
import { ReportMetadataDto } from '@api/models/ReportMetadataDto';
import { numberWithCommas } from '@utils/NumberUtils';
import EnergyWidgetUnitToggle from './EnergyWidgetUnitToggle';
import { useSiteContext } from '@pages/site/SiteProvider';
import { EnergyConsumptionWidgetConfig, getMetricTypeFromEnergyMeterType } from './helpers/ModifierHelpers';
import InfoTooltip from './InfoTooltip';
import { ReportAggregateCategory } from '@api/enums/ReportAggregateCategory';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';

type EnergyConsumptionWidgetProps = {
  reportDate: Date;
  config: EnergyConsumptionWidgetConfig;
  metadata: ReportMetadataDto[];
  setUseTransform: (state: boolean) => void;
  hasUpwardsTrend?: boolean;
  inPrintMode: boolean;
  totalBuildingArea?: string;
  numColumns: number;
  category: ReportAggregateCategory;
}

const EnergyConsumptionWidget = ({ reportDate, config, metadata, setUseTransform, hasUpwardsTrend, inPrintMode, totalBuildingArea, numColumns, category }: EnergyConsumptionWidgetProps) => {
  const { t } = useTranslation(['molecules']);
  const { siteCurrencyFormat } = useSiteContext();
  const { toLocale, localisation } = useLocalisation();

  const costDisplayString = (config.data.totalCost !== null && config.data.totalCost !== undefined)
    ? `${siteCurrencyFormat(config.data.totalCost / 100)}`
    : <span style={{ fontWeight: 400 }}> --</span>;

  return (
    <Wrapper numColumns={numColumns}>
      <Card>
        <InfoContainer>
          <FlexRow style={{ alignItems: 'start', justifyContent: 'space-between' }}>
            <HeadingRow>
              <FlexRow>
                <EnergyTypeLabel>
                  {config.data.energyType}
                </EnergyTypeLabel>
                {config.icon &&
                  <EnergyTypeIcon
                    icon={config.icon}
                    color={config.color}
                  />
                }

                <EnergyWidgetUnitToggle
                  labels={config.toggleLabels}
                  onChange={setUseTransform}
                />
              </FlexRow>

              <SeparatorDot />

              <FlexRow>
                <Total>
                  {numberWithCommas(toLocale(getMetricTypeFromEnergyMeterType(config.data.energyType), config.data.totalUsage), 0)}
                  <TotalSuffix>{config.unitSuffix}</TotalSuffix>

                  {config.transform &&
                    <TransformTotal>
                      ({numberWithCommas(config.transform.data.totalUsage, 0)}{config.transform.unitSuffix})
                    </TransformTotal>
                  }
                </Total>
                {hasUpwardsTrend !== undefined &&
                  <TrendIcon up={hasUpwardsTrend}>
                    <FontAwesomeIcon icon={hasUpwardsTrend ? solid('caret-up') : solid('caret-down')} />
                  </TrendIcon>
                }
              </FlexRow>

              <SeparatorDot />

              {localisation.showPriceLine &&
                <Cost>
                  {costDisplayString}
                </Cost>
              }

              {inPrintMode &&
                <CarbonEmissions
                  data={config.data}
                  inPrintMode={inPrintMode}
                  totalBuildingArea={totalBuildingArea}
                  metadata={metadata}
                />
              }
            </HeadingRow>

            <RightAligned>
              <FlexRow>
                <Label>{t('ESG.Avg', { ns: 'molecules' })}</Label><Medium>{numberWithCommas(toLocale(getMetricTypeFromEnergyMeterType(config.data.energyType), config.data.averageUsage), 0)}{config.unitSuffix}</Medium>
              </FlexRow>
              <SeparatorDot />
              <FlexRow>
                <Label>{t('ESG.Min', { ns: 'molecules' })}</Label><Medium>{numberWithCommas(toLocale(getMetricTypeFromEnergyMeterType(config.data.energyType), config.data.minimumUsage), 0)}{config.unitSuffix}</Medium>
              </FlexRow>
              <SeparatorDot />
              <FlexRow>
                <Label>{t('ESG.Max', { ns: 'molecules' })}</Label><Medium>{numberWithCommas(toLocale(getMetricTypeFromEnergyMeterType(config.data.energyType), config.data.maximumUsage), 0)}{config.unitSuffix}</Medium>
              </FlexRow>
            </RightAligned>
          </FlexRow>
        </InfoContainer>

        <DailyConsumptionChartWrapper
          seriesData={config.data.seriesData}
          color={config.color}
          dataUnit={config.unitSuffix}
          inPrintMode={inPrintMode}
          energyMeterType={config.data.energyType}
        />

        <DividerWrapper>
          <Divider />
        </DividerWrapper>

        <MonthlyConsumptionChartWrapper
          reportDate={reportDate}
          historicSeriesData={config.data.historicSeriesData}
          color={config.color}
          dataUnit={config.unitSuffix}
          inPrintMode={inPrintMode}
          energyMeterType={config.data.energyType}
        />

        {inPrintMode &&
          <MonthlyConsumptionChartLegend
            reportDate={reportDate}
            historicSeriesData={config.data.historicSeriesData}
            color={config.color}
          />
        }

        {!inPrintMode &&
          <CarbonEmissions
            data={config.data}
            inPrintMode={inPrintMode}
            totalBuildingArea={totalBuildingArea}
            metadata={metadata}
          />
        }

        {!inPrintMode &&
          <InfoTooltip
            config={config}
            category={category}
          />
        }
      </Card >
    </Wrapper>
  );
};

export default EnergyConsumptionWidget;

const Wrapper = styled.div<{ numColumns: number }>`
  display: flex;
  flex-direction: column;
  gap: 5mm;

  width: 100%;
  height: max-content;

  /*
   * Calculate width of the widget, given the numColumns which is determined by the with of the parent div
   * Subtract 35px n times to accomodate for the gaps between the widgets
   */
  ${({ numColumns }) => numColumns > 1 && css`
    flex: ${`0 0 calc((100% - ${(numColumns - 1) * 35}px) / ${numColumns})`};
    max-width: ${`calc((100% - ${(numColumns - 1) * 35}px) / ${numColumns})`};
  `}

  @media print { 
    width: 100%;
    max-width: unset;
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 10px;
  box-shadow: 0px 3px 8px ${p => p.theme.palette.esgReport.cardShadow}, 0 0 4px 1px ${p => p.theme.palette.esgReport.cardShadow};

  @media print {    
    box-shadow: 0 0 4px 1px ${p => p.theme.palette.esgReport.cardShadow}, 0 0 1px 0px ${p => p.theme.palette.esgReport.cardShadow};
    border-radius: 5px;
  }
`;

const HeadingRow = styled.div`
  display: block;

  @media print {    
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const InfoContainer = styled.div`
  padding: 10px 14px 0px 14px;

  @media print {
    padding: 10px 10px 10px 10px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const RightAligned = styled.div`
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  text-align: right;
  color: ${p => p.theme.palette.text.fair};

  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 2px;

  @media print {
    height: 16px;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 9px;
    line-height: 9px;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const Label = styled.span`
  text-align: right;
  width: 30px;
  margin-right: 5px;

  @media print {
    width: max-content;
    margin-right: 2px;
  }
`;

const Medium = styled.span`
  font-weight: 500;
  text-align: right;
  margin-left: auto;

  @media print {
    font-weight: 400;
  }
`;

const EnergyTypeLabel = styled.div`
  font-size: 15px;
  font-weight: 500;

  @media print {
    font-size: 14px;
    line-height: 18px;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const Total = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 2px;

  @media print {
    position: relative;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 0px;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const TotalSuffix = styled.span`
  font-size: 16px;
  line-height: 16px;

  @media print {
    font-size: 12px;
    line-height: 12px;
  }
`;

const TransformTotal = styled.div`
  display: none;
  
  position: absolute;
  top: 15px;
  font-size: 10px;
  color: ${p => p.theme.palette.printStyles.text.fair};

  @media print {
    display: block;
  }
`;

const TrendIcon = styled.div<{ up: boolean }>`
  color: ${p => p.up ? p.theme.palette.red : p.theme.palette.green};
  margin: -8px 0 0 4px;
  font-size: 18px;

  @media print {
    font-size: 12px;
    margin: -7px 0 0 2px;
  }
`;

const Cost = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;

  @media print {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const EnergyTypeIcon = styled(FontAwesomeIcon) <{ color?: string }>`
  font-size: 11px;
  margin-left: 4px;
  color: ${p => p.color};

  @media print {
    font-size: 9px;
    margin-left: 3px;
  }
`;

const DividerWrapper = styled.div`
  margin-top: 18px;

  @media print {
    display: none;
  }
`;

const Divider = styled.hr`
  width: 70%;
  border: none;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

export const SeparatorDot = styled.div`
  display: none;

  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${p => p.theme.palette.printStyles.text.weak};

  @media print {
    display: inline-block;
  }
`;