import { useEffect, useState } from 'react';
import { Select } from '@components/select/Select';
import { useTranslation } from 'react-i18next';
import { DeviceModelWithMetricTypes } from '@api/models/DeviceModelWithMetricTypes';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { groupBy } from 'lodash';
import { getCookieConsent } from '@components/consent-banner/ConsentBanner';
import DeviceModelsGetByBuildingIdQuery from '@api/queries/device-models/DeviceModelsGetByBuildingIdQuery';
import { useApi } from '@hooks/useApi';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { BuildingWithFloors } from '@api/models/BuildingWithFloors';

type OptionGroup = {
  label: string;
  options: Option[];
}

type Option = {
  label: string;
  value: DeviceModelWithMetricTypes;
}

type PropTypes = {
  building: BuildingWithFloors;
  onChange: (deviceModel: DeviceModelWithMetricTypes) => void;
  onLoading?: (state: boolean) => void;
  defaultModel?: string;
  localStorageKey?: string;
}

const DeviceModelSelect = ({ building, onChange, onLoading, defaultModel, localStorageKey }: PropTypes) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { execute, loading: loadingDeviceModels } = useApi();
  const { getDisplayString } = useDeviceConfigContext();
  const [selected, setSelected] = useState<Option | null>();
  const [options, setOptions] = useState<OptionGroup[]>([]);

  useEffect(() => {
    onLoading && onLoading(loadingDeviceModels);
  }, [loadingDeviceModels, onLoading]);

  // Get deviceModels in building and create options for select component
  useEffect(() => {
    const fetch = async () => {
      // Reset selected option when the buildingId changes
      setSelected(null);

      const models = await execute({
        query: new DeviceModelsGetByBuildingIdQuery(building.id),
      });

      // Create options grouped by Manufacturer
      const options = Object.entries(groupBy(models, x => x.manufacturer))
        .map(group => ({
          label: group[0],
          options: group[1].map(x => ({
            label: getDisplayString(x.deviceModel),
            value: x
          }))
        }));

      setOptions(options);

      // Set the default value (or value from the browser's local storage) if it exists in the options array
      const modelInLocalStorage = localStorageKey && localStorage.getItem(localStorageKey);
      const defaultOption = options.flatMap(x => x.options).find(x => x.value?.deviceModel === (modelInLocalStorage ?? defaultModel));

      if (defaultOption) {
        setSelected(defaultOption);
        onChange(defaultOption.value);
      }
    };

    fetch();
  }, [building.id, defaultModel, localStorageKey, onChange, execute, getDisplayString]);

  const handleChange = (selected: Option) => {
    setSelected(selected);
    onChange(selected.value);
    trackAction('select_device', 'heatmap', { heatmap_building: building.name });

    // only use local storage if user has consented
    if (localStorageKey && getCookieConsent()?.functional) {
      localStorage.setItem(localStorageKey, selected.value.deviceModel);
    }
  };

  return (
    <Select
      isLoading={loadingDeviceModels}
      value={selected}
      options={options}
      onChange={option => option && handleChange(option)}
      closeMenuOnSelect={true}
      isSearchable={true}
      hideSelectedOptions={false}
      menuHeight={200}
      placeholder={t('Heatmap.SelectDevice', { ns: 'molecules' })}
    />
  );
};

export default DeviceModelSelect;