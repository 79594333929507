import { convertToCSV, exportCSV } from '@utils/ExportUtils';
import { Button } from '@components/core/Button';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { TOutlierConfig } from './OutlierWidget';
import { useCallback, useMemo } from 'react';
import { EsgExplorerMetricsSpaceDto } from '@api/models/EsgExplorerMetricsDto';
import EsgMetricType from '@api/enums/EsgMetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

type PropTypes = {
  config: TOutlierConfig;
  spaces: EsgExplorerMetricsSpaceDto[];
}

const OutlierExport = ({ spaces, config }: PropTypes) => {
  const { t } = useTranslation();
  const { localisation, getUnit, toLocale } = useLocalisation();
  const { trackAction } = useAnalytics();
  const { tenant } = useTenantContext();
  const { site } = useSiteContext();

  const csv = useMemo(() => {
    const hasClusters = spaces.some(x => x.clusterName);

    return convertToCSV([
      {
        header: t('Space', { ns: 'common' }),
        key: 'spaceName'
      },
      {
        header: t('Building', { ns: 'common' }),
        key: 'buildingName'
      },
      {
        header: t('Type', { ns: 'molecules' }),
        key: 'spaceType',
        modifier: ({ spaceType }) => t(spaceType, { ns: 'enums' })
      },
      {
        header: t('ClusterName', { ns: 'common' }),
        key: 'clusterName',
        hidden: !hasClusters
      },
      {
        header: t('NoOfBedrooms', { ns: 'common' }),
        key: 'bedroomsInCluster',
        hidden: !hasClusters
      },
      {
        header: t(`TitleRowLabel.${config.metric}`, { ns: 'enums', unit: config.metric ? getUnit(EsgMetricType.toMetricType(config.metric)) : '', areaUnit: getUnit(AltUnits.Area) }).replace('<0/>', ' '),
        key: 'metric',
        modifier: ({ esgMeasurements }) => (esgMeasurements[0].value && config.metric) ? toLocale(EsgMetricType.toMetricType(config.metric), esgMeasurements[0].value, { round: 2 }).toString() : ''
      }
    ], spaces);
  }, [t, spaces, toLocale, getUnit, config]);

  const handleExport = useCallback(() => {
    const fileName = `${tenant.name}_${site.name}_${t('Outliers', { ns: 'common' })}_${dayjs(config.timeRange.from).format(localisation.dateFormats.date)}-${dayjs(config.timeRange.to).format(localisation.dateFormats.date)}`;

    exportCSV(fileName, csv);
    trackAction('export_excel', 'overview_outliers', { metric: config.metric, time_span: config.timeRangeType, filter: config.filter?.type });
  }, [csv, config, site, tenant, localisation, t, trackAction]);

  return (
    <Button
      secondary
      label={t('Export', { ns: 'common' })}
      onClick={handleExport}
      style={{ marginLeft: 'auto' }}
    />
  );
};

export default OutlierExport;