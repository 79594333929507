import { EsgExplorerFilter, EsgExplorerFilterType } from '@api/models/EsgExplorerConfiguration';
import EsgMetricType from '@api/enums/EsgMetricType';
import { Input } from '@components/Form';
import styled from 'styled-components';
import { EsgExplorerConfigLabel } from '../ExplorerConfiguration';
import { useTranslation } from 'react-i18next';
import { LocalisationFunction, useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

const expectsSingleValue = (c: EsgExplorerFilterType) => {
  return (c === EsgExplorerFilterType.EqualTo || c === EsgExplorerFilterType.NotEqualTo || c === EsgExplorerFilterType.GreaterThan
    || c === EsgExplorerFilterType.GreaterThanOrEqualTo || c === EsgExplorerFilterType.LessThan || c === EsgExplorerFilterType.LessThanOrEqualTo);
}

const expectsRange = (c: EsgExplorerFilterType) => {
  return (c === EsgExplorerFilterType.InsideOfRange || c === EsgExplorerFilterType.OutsideOfRange);
}

const localise = (conversion: LocalisationFunction, metricType: MetricType | AltUnits, value?: number | null, round?: number) => {
  if (value === null || value === undefined) {
    return undefined;
  }

  return conversion(metricType, value, { round: round ?? undefined });
};

interface IFilterTypeConfiguration {
  filter: EsgExplorerFilter;
  onChange: (filter: EsgExplorerFilter) => void;
}

const FilterTypeConfiguration = ({ filter, onChange }: IFilterTypeConfiguration) => {
  const { t } = useTranslation();
  const { toLocale, fromLocale, getUnit } = useLocalisation();

  if (!filter.metric || !filter.type) {
    return null;
  }

  const metricType = EsgMetricType.toMetricType(filter.metric);
  const unit = EsgMetricType.isVarianceMetric(filter.metric) ? '%' : getUnit(metricType);

  if (expectsSingleValue(filter.type)) {
    return (
      <div style={{ width: '80px', overflow: 'visible', whiteSpace: 'nowrap' }}>
        <EsgExplorerConfigLabel>{unit ? `${unit}` : <>&nbsp;</>}</EsgExplorerConfigLabel>
        <Input
          type="number"
          value={localise(toLocale, metricType, filter.threshold, 2)}
          onInput={(e) => {
            onChange({
              metric: filter.metric,
              type: filter.type,
              threshold: e.currentTarget.value === '' ? undefined : localise(fromLocale, metricType, parseFloat(e.currentTarget.value)),
              min: null,
              max: null
            });
          }}
        />
      </div>
    )
  }

  if (expectsRange(filter.type)) {
    return (
      <>
        <div style={{ width: '80px', overflow: 'visible', whiteSpace: 'nowrap' }}>
          <EsgExplorerConfigLabel>{t('ESG.min', { ns: 'molecules' })}&nbsp;<SuffixLabel>{unit && `(${unit})`}</SuffixLabel></EsgExplorerConfigLabel>
          <Input
            type="number"
            value={localise(toLocale, metricType, filter.min, 2)}
            onInput={(e) => {
              onChange({
                metric: filter.metric,
                type: filter.type,
                threshold: null,
                min: e.currentTarget.value === '' ? undefined : localise(fromLocale, metricType, parseFloat(e.currentTarget.value)),
                max: filter.max
              });
            }}
          />
        </div>
        <div style={{ width: '80px', overflow: 'visible', whiteSpace: 'nowrap' }}>
          <EsgExplorerConfigLabel>{t('ESG.max', { ns: 'molecules' })}&nbsp;<SuffixLabel>{unit && `(${unit})`}</SuffixLabel></EsgExplorerConfigLabel>
          <Input
            type="number"
            value={localise(toLocale, metricType, filter.max, 2)}
            onInput={(e) => {
              onChange({
                metric: filter.metric,
                type: filter.type,
                threshold: null,
                min: filter.min,
                max: e.currentTarget.value === '' ? undefined : localise(fromLocale, metricType, parseFloat(e.currentTarget.value)),
              });
            }}
          />
        </div>
      </>
    )
  }

  return (
    <>
    </>
  );
};

export default FilterTypeConfiguration;

const SuffixLabel = styled.span`
  font-size: 12px;
`;