import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { SpacesFairUseEnergyConsumption } from '@api/models/SpaceFairUseEnergyConsumption';
import { PostQuery } from '../../common/PostQuery';

export class SiteFairUseExceedingSpacesGetQuery extends PostQuery<SpacesFairUseEnergyConsumption> {
  policyId: number;
  siteId: number;
  seasonalityAdjustmentIds: number[];
  meterType: EnergyMeterType;

  constructor(policyId: number, siteId: number, seasonalityAdjustmentIds: number[], meterType: EnergyMeterType) {
    super();
    this.policyId = policyId;
    this.siteId = siteId;
    this.seasonalityAdjustmentIds = seasonalityAdjustmentIds;
    this.meterType = meterType;
  }

  public targetUrl(): string {
    return '/FairUse/ExceedingSpaces';
  }
}
