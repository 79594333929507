import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@components/core/Title';
import { Space } from '@api/models/Space';
import { useApi } from '@hooks/useApi';
import SpaceUpdateCommand from '@api/queries/spaces/SpaceUpdateCommand';
import { Switch } from 'antd';
import { LabelValuePair } from '@components/core/LabelValuePair';
import { SpaceType_DisplayString } from '@api/enums/SpaceType_DisplayString';
import { BackButton } from '@components/core/BackButton';
import { CommonSidebar } from '@components/core/CommonSidebar';
import { useLayoutContext } from '@contexts/LayoutContext';
import { BuildingHierarchyLevel, useBuildingContext } from '@pages/site/buildings/BuildingProvider';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import SmartMeterWidget from '../smart-meter/SmartMeterWidget';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import { SiteLevelFeatureFlag } from '@api/enums/SiteLevelFeatureFlag';

type PropTypes = {
  space: Space;
  devices?: DeviceWithMetrics[]
}

const Sidebar = ({ space, devices }: PropTypes) => {
  const { t } = useTranslation();
  const { hasSiteFeature } = useSiteContext();
  const { isFullScreen } = useLayoutContext();
  const { hierarchyLevel } = useBuildingContext();
  const { getDeviceConfig } = useDeviceConfigContext();
  const { execute } = useApi();
  const [isOccupied, setIsOccupied] = useState(space?.occupied);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const smartMeter = devices?.find(x => getDeviceConfig(x.deviceModel)?.isSmartMeter);

  const updateOccupiedState = async () => {
    setSavingInProgress(true);

    const originalState = isOccupied;
    const newState = !isOccupied;
    setIsOccupied(newState);

    const success = await execute({
      query: new SpaceUpdateCommand({ ...space, occupied: newState }),
      pendingMessage: t('Space.Saving', { ns: 'organisms' }),
      successMessage: t('Space.ChangesSaved', { ns: 'organisms' }),
      errorMessage: t('Space.OperationFailed', { ns: 'organisms' }),
    });

    if (!success) {
      setIsOccupied(originalState);
      return;
    }

    setSavingInProgress(false);
  };

  return (
    <CommonSidebar isHidden={isFullScreen || hierarchyLevel !== BuildingHierarchyLevel.Space}>
      <Header>
        <BackButton
          label={t('BackToFloor', { ns: 'navigation' })}
          url='..'
        />

        <Title
          text={space.name}
          size='sm'
        />
      </Header>
      <Body>
        <ToggleWrapper>
          <div style={{ marginBottom: '5px' }}>Occupied</div>
          <Switch
            checked={isOccupied}
            onClick={updateOccupiedState}
            style={{ width: '30px' }}
            size='small'
            disabled={savingInProgress}
          />
        </ToggleWrapper>
        <LabelValuePair
          label={t('Type', { ns: 'common' })}
          value={SpaceType_DisplayString(space.spaceType)}
          size="xs"
        />
        <LabelValuePair
          label={t('Area', { ns: 'common' })}
          value={space.area}
          suffix="m²"
          size="xs"
        />

        {hasSiteFeature(SiteLevelFeatureFlag.SmartMeters) && smartMeter &&
          <SmartMeterWidget
            space={space}
            smartMeter={smartMeter}
          />
        }
      </Body>
    </CommonSidebar>
  );
};

export default Sidebar;

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 0;
`;

const ToggleWrapper = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
  display: flex;
  flex-direction: column;
`;