import ExportDynamicReportGroupedByMetricTypeGetQuery from '@api/queries/exports/ExportDynamicReportGroupedByMetricTypeGetQuery';
import ExportDynamicReportGroupedBySpaceGetQuery from '@api/queries/exports/ExportDynamicReportGroupedBySpaceGetQuery';
import useCsvExport from '@hooks/useCsvExport';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Site } from '@api/models/Site';
import { Button } from '@components/core/Button';
import FullScreenToggle from '@components/core/FullScreenToggle';
import NoData from '@components/core/NoData';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Title } from '@components/core/Title';
import { LineBarChartType } from '@components/charts/Chart.types';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IDynamicReportData } from '../../Reporting';
import { ReportGroupingType } from '../config-panel/configuration/GroupingTypeSelect';
import ChartGroupedByMetric from './charts/ChartGroupedByMetric';
import ChartGroupedBySpace from './charts/ChartGroupedBySpace';

interface IReportSection {
  data: IDynamicReportData;
  site: Site;
}

const ReportSection = ({ data, site }: IReportSection) => {
  const { t } = useTranslation(['organisms']);
  const [hasData, setHasData] = useState(false);
  const [chartType, setChartType] = useState<LineBarChartType>(LineBarChartType.Line);
  const { handleCsvExport, loading: loadingExport } = useCsvExport();

  useEffect(() => {
    setHasData((data.config.grouping === ReportGroupingType.Space && data.spaces.length > 0) || (data.config.grouping === ReportGroupingType.MetricType && data.metrics.length > 0));
  }, [data]);

  const handleExport = async () => {
    if (!data.config.timeRange) {
      return;
    }

    const fromString = format(data.config.timeRange.from, 'yyyyMMdd');
    const toString = format(data.config.timeRange.to, 'yyyyMMdd');
    const spaceIds = data.config.grouping === ReportGroupingType.MetricType ?
      data.metrics.flatMap(x => x.spaces.map(y => y.spaceId)).filter((space, i, arr) => arr.findIndex(t => t === space) === i) :
      data.spaces.map(x => x.spaceId);
    const metricTypes = data.config.grouping === ReportGroupingType.MetricType ?
      data.metrics.map(x => x.metricType) :
      data.spaces.flatMap(x => x.metricTypes.map(y => y.metricType)).filter((metric, i, arr) => arr.findIndex(t => t === metric) === i);

    if (data.config.grouping === ReportGroupingType.MetricType) {
      const query = new ExportDynamicReportGroupedByMetricTypeGetQuery(spaceIds, metricTypes, data.config.timeRange.from.toJSON(), data.config.timeRange.to.toJSON());
      const fileName = `${site.name}${t('Building.FileNameReportByMetric', { ns: 'organisms' })}${fromString}-${toString}.csv`
      handleCsvExport(query, fileName);
    } else {
      const query = new ExportDynamicReportGroupedBySpaceGetQuery(spaceIds, metricTypes, data.config.timeRange.from.toJSON(), data.config.timeRange.to.toJSON());
      const fileName = `${site.name}${t('Building.FileNameReportBySpace', { ns: 'organisms' })}${fromString}-${toString}.csv`
      handleCsvExport(query, fileName);
    }
  }

  return (
    <PaddedContainer>
      {!hasData ? (
        <NoData
          large
          label={t('NoDataFound', { ns: 'status' })}
          subLabel={t('AdjustConfigAndRerun', { ns: 'status' })}
          styles={{ margin: '100px auto' }}
          icon={solid('chart-simple')}
        />
      ) : (
        <>
          <FlexRow>
            <Title
              size='lg'
              text={t('Report', { ns: 'common' })}
            />

            <div style={{ marginLeft: 'auto' }} />

            <FullScreenToggle />

            <Button
              secondary
              label={t('Export', { ns: 'common' })}
              onClick={handleExport}
              loading={loadingExport}
              analytics={{ action: 'export_excel', category: 'reporting' }}
            />
          </FlexRow>
          {data.config.grouping === ReportGroupingType.Space && data.spaces.map((space) => (
            <ChartGroupedBySpace
              key={space.spaceId}
              data={space}
              chartType={chartType}
              onChartTypeChange={(chartType: LineBarChartType) => setChartType(chartType)}
              timeRange={data.config.timeRange}
            />
          ))}

          {data.config.grouping === ReportGroupingType.MetricType && data.metrics.map((metric, i) => (
            <ChartGroupedByMetric
              key={i}
              data={metric}
              chartType={chartType}
              onChartTypeChange={(chartType: LineBarChartType) => setChartType(chartType)}
              timeRange={data.config.timeRange}
            />
          ))}
        </>
      )}
    </PaddedContainer>
  );
};

export default ReportSection;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;