import { Site } from '@api/models/Site';
import { WarningDialog } from '@components/core/WarningDialog';
import { useModal } from '@hooks/useModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SiteFeature from '@pages/site/settings/site/features/components/SiteFeature';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useApi } from '@hooks/useApi';
import SiteOperatorResidentAppCreateCommand from '@api/queries/resident-app/SiteOperators/SiteOperatorResidentAppCreateCommand';
import { SiteOperatorResidentAppDeleteCommand } from '@api/queries/resident-app/SiteOperators/SiteOperatorResidentAppDeleteCommand';
import ResidentAppMetricFeature from './ResidentAppFeatureToggle';
import { MetricType } from '@api/enums/MetricType';
import styled from 'styled-components';
import { CustomResidentAppFeature } from '@api/enums/ResidentAppFeatureType';

type ResidentAppFeatureProps = {
  site: Site;
}

const ResidentAppFeature = ({ site }: ResidentAppFeatureProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const { siteOperator, refreshSiteOperator} = useSiteContext();
  const { isOpen: isDialogOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(!!siteOperator);
  }, [siteOperator]);

  const handleChange = (enabled: boolean) => {
    if (enabled) {
      setIsEnabled(enabled);
      enableResidentApp();
    } else {
      toggleDialog();
    }
  };

  const enableResidentApp = async () => {
    await execute({
      query: new SiteOperatorResidentAppCreateCommand(site.id),
      successMessage: t('ResidentApp.Enabled', { ns: 'molecules' }),
      errorMessage: t('ResidentApp.EnabledError', { ns: 'molecules' }),
      pendingMessage: t('ResidentApp.EnabledPending', { ns: 'molecules' }),
    });

    refreshSiteOperator();
  };

  const disableResidentApp = async () => {
    if (!siteOperator) {
      return;
    }

    await execute({
      query: new SiteOperatorResidentAppDeleteCommand(siteOperator.id),
      successMessage: t('ResidentApp.Disabled', { ns: 'molecules' }),
      errorMessage: t('ResidentApp.DisabledError', { ns: 'molecules' }),
      pendingMessage: t('ResidentApp.DisabledPending', { ns: 'molecules' })
    })

    toggleDialog();
    refreshSiteOperator();
  };

  return (
    <>
      <WarningDialog
        modalRef={dialogRef}
        isOpen={isDialogOpen}
        sectionOne={t('ResidentApp.DisableResidentAppDialog', { ns: 'molecules' })}
        confirmButton={t('Disable', { ns: 'common' })}
        onCancel={toggleDialog}
        onConfirm={disableResidentApp}
      />

      <SiteFeature
        name={t('ResidentApp.ResidentApp', { ns: 'molecules' })}
        description={t('ResidentApp.FeatureDescription', { ns: 'molecules' })}
        icon={solid('mobile-screen-button')}
        isEnabled={isEnabled}
        onChange={handleChange}
      />

      {isEnabled &&
      <ResidentMetric>
        <ResidentAppMetricFeature featureType={MetricType.Temperature} /> 
        <ResidentAppMetricFeature featureType={MetricType.ElectricityKwh} /> 
        <ResidentAppMetricFeature featureType={CustomResidentAppFeature.NoChallenges} />
      </ResidentMetric>
      }
      
    </>
  );
};

export default ResidentAppFeature;

const ResidentMetric = styled.div`
  padding-left: 25px;
`;