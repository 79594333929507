import { OperatorPatchQuery } from '@api/queries/resident-app/common/OperatorPatchQuery';
import { OperatorPrizeDto } from '@api/models/OperatorPrizeDto';

export class PrizesResidentAppBulkUpdateCommand extends OperatorPatchQuery<OperatorPrizeDto[]> {
  prizes: OperatorPrizeDto[];

  constructor(prizes: OperatorPrizeDto[]) {
    super();
    this.prizes = prizes;
  }

  public targetUrl(): string {
    return '/Prizes/BulkUpdate';
  }
}
