import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export class SpaceClimateControlGetBySpaceQuery extends GetQuery<SpaceClimateControlDto> {
  spaceId: number;

  constructor(spaceId: number) {
    super();
    this.spaceId = spaceId;
  }

  public targetUrl(): string {
    return `/ClimateControl/space/GetBySpace/${this.spaceId}`;
  }
}
