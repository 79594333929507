import styled from 'styled-components';
import { Link } from 'react-router-dom';
import placeholderImage from '@assets/images/buildingImagePlaceholder.png'
import { TopBarHeight } from '@src/constants/designConstants';
import { useFileFromBlobStorage } from '@hooks/useFileFromBlobStorage';
import { SearchField } from '@components/search-field/SearchField';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Site } from '@api/models/Site';
import NoData from '@components/core/NoData';
import { Title } from '@components/core/Title';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { Button } from '@components/core/Button';
import { useUserContext } from '@contexts/UserContext/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Permission } from '@api/enums/Permission';

//#region SiteList component

type SiteListProps = {
  sites: Site[],
  onHover: (id: number) => void,
  onHoverEnd: () => void,
};

const SiteList = ({ sites, onHover, onHoverEnd }: SiteListProps) => {
  const { t } = useTranslation(['main']);
  const { hasPermission } = useUserContext();
  const [filter, setFilter] = useState<string>();
  const showSearchField = sites.length > 2;

  const filteredSites = useMemo(() => {
    if (filter) {
      return sites.filter(x => x.name.toUpperCase().includes(filter.toUpperCase()))
    }
    return sites;
  }, [sites, filter]);

  return (
    <StyledList>
      <FlexRow>
        <Title
          text={t('YourSites', { ns: 'common' })}
          automationId='yourSitesTitle'
        />

        {hasPermission(Permission.SiteWrite) &&
          <Link to={'add-site'}>
            <Button
              tertiary
              circle
              label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
              style={{ padding: 7 }}
            />
          </Link>
        }
      </FlexRow>

      {showSearchField &&
        <SearchField
          style={{ margin: '0 15px 10px 15px' }}
          placeholder={t('Search', { ns: 'common' })}
          onSearchChange={(str) => setFilter(str)}
        />
      }

      <ScrollContainer showSearchField={showSearchField}>
        {filteredSites.length > 0
          ? filteredSites.map((site) => (
            <SiteListItem
              key={site.id}
              site={site}
              onHover={onHover}
              onHoverEnd={onHoverEnd}
              automationId={`SiteNo. ${filteredSites.indexOf(site)}`}
            />
          ))
          : (
            <NoResultsWrapper>
              <NoData
                label={t('NoResultsFound', { ns: 'status' })}
                subLabel={t('NoSiteMatchedSearch', { ns: 'status', filter: filter })}
              />
            </NoResultsWrapper>
          )
        }
      </ScrollContainer>
    </StyledList>
  );
}

export default SiteList;

const StyledList = styled.div`
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 15px;
`;

const ScrollContainer = styled.div<{ showSearchField: boolean }>`
  overflow: auto;
  height: ${p => `calc(100vh - ${TopBarHeight}px - ${p.showSearchField ? 56 + 48 : 56}px)`};
`;

const NoResultsWrapper = styled.div`
  padding: 100px 15px 0;
`;

//#endregion

//#region SiteListItem component

type SiteListItemProps = {
  site: Site,
  onHover: (id: number) => void,
  onHoverEnd: () => void,
  automationId: string,
};

const SiteListItem = ({ site, onHover, onHoverEnd, automationId }: SiteListItemProps) => {
  const { blobUri } = useFileFromBlobStorage(site.siteImageId);
  const { trackAction } = useAnalytics();

  return (
    <Link
      to={`/site/${site.id}`}
      onClick={() => trackAction('individual_site', 'site_navigation', { new_site_name: site.name, new_site_id: site.id })}
    >
      <ListItem
        onMouseEnter={() => onHover(site.id)}
        onMouseLeave={onHoverEnd}
        data-cy={automationId}
      >
        <Image src={blobUri ?? placeholderImage} />

        <AssetName>{site.name}</AssetName>
        <AssetAddress>
          {site.address.addressLine1}, {site.address.city}
        </AssetAddress>
      </ListItem>
    </Link>
  );
}

const ListItem = styled.div`
  padding: 12px 15px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.primary.hover};
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 95px;
  box-sizing: border-box;
  box-shadow: 0 7px 12px -6px ${p => p.theme.palette.shadows.strong};
  border-radius: 4px;
  flex-shrink: 0;
`;

const AssetName = styled.span`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: ${p => p.theme.text.primary};
  
  ${ListItem}:hover & {
    color: ${p => p.theme.primary.main};
  }
`;

const AssetAddress = styled.span`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${p => p.theme.text.secondary};
`;

//#endregion