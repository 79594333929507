import { DeviceWithMetricTypes } from '@api/models/DeviceWithMetricTypes';
import { GetQuery } from '@api/queries/common/GetQuery';

export class DevicesGetWithLatestMetricsQuery extends GetQuery<DeviceWithMetricTypes> {
  siteId?: number;
  buildingIds?: number[];

  constructor(siteId: number, buildingIds?:number[]) {
    super();
    this.siteId = siteId;
    this.buildingIds = buildingIds;
  }

  public targetUrl(): string {
    const queryParams = new URLSearchParams();

    if (this.siteId) {
      queryParams.append('siteId', this.siteId.toString());
    }

    if (this.buildingIds) {
      this.buildingIds.forEach(buildingId => {
        queryParams.append('buildingIds', buildingId.toString());
      });
    }

    return `/devices/DevicesGetWithLatestMetrics?${queryParams.toString()}`;
  }
}
