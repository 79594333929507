import { BuildingWithFloors } from '@api/models/BuildingWithFloors';
import { GetQuery } from '@api/queries/common/GetQuery';

export class BuildingsWithFloorsGetBySiteIdQuery extends GetQuery<BuildingWithFloors[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/buildings/withFloors/${this.siteId}`;
  }
}
