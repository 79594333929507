import { useTranslation } from 'react-i18next';
import { Building } from '@api/models/Building';
import { DeviceDetails } from '@api/models/DeviceDetails';
import { useApiState } from '@hooks/useApiState';
import { BackButton } from '@components/core/BackButton';
import { useNavigate } from 'react-router-dom';
import DeviceManagementHeader from '@components/device-management/DeviceManagementHeader';
import { useSiteContext } from '@pages/site/SiteProvider';
import { DevicesGetWithLatestMetricsQuery } from '@api/queries/devices/DevicesGetWithLatestMetricsQuery';
import DeviceManagementTable from '@components/device-management/DeviceManagementTable';
import { useDeviceManagement } from '@hooks/useDeviceManagement';

interface IBuildingDeviceList {
  building: Building;
}

const BuildingDeviceList = ({ building }: IBuildingDeviceList) => {
  const { t } = useTranslation(['settingsAsset']);
  const navigate = useNavigate();
  const { site } = useSiteContext();
  const {
    spaceFilter,
    setSpaceFilter,
    modelFilter,
    setModelFilter,
    setTypeFilter,
    typeFilter,
    searchFilter,
    locationFilter,
    deviceModelFilter,
    deviceTypeFilter,
    tableRecordCount,
    setTableRecordCount,
    setSearchString,
    searchString,
    handleExportCsv
  } = useDeviceManagement();
  const { data: devices, loading, execute: refreshData } = useApiState({
    query: new DevicesGetWithLatestMetricsQuery(site.id, [building.id]),
    initialState: {devices: [], metricTypes: []},
  }, []);

  return (
    <>
      <BackButton
        url='./..'
        label={t('BackToDevices', { ns: 'navigation' })}
      />
      <DeviceManagementHeader 
        devices={devices?.devices || []}
        tableRecordCount={tableRecordCount}
        onExportCsv={() => handleExportCsv(devices?.devices || [], building.name)}
        onSetSpaceFilter={setSpaceFilter}
        spaceFilter={spaceFilter}
        modelFilter={modelFilter}
        onSetModelFilter={setModelFilter}
        typeFilter={typeFilter}
        onSetTypeFilter={setTypeFilter}
        onSetSearchString={setSearchString}
        createDeviceLink='../../settings/devices/create'
        deviceBulkImport={{
          buildingId: building.id,
          onComplete: () => { refreshData() }
        }}
      />

      <DeviceManagementTable
        devices={devices?.devices || []}
        setTableRecordCount={setTableRecordCount}
        searchString={searchString}
        loading={loading}
        filters={[searchFilter, locationFilter, deviceModelFilter, deviceTypeFilter]}
        onRowClick={(row: DeviceDetails) => {
          if (row.deviceModel !== 'HikvisionPeopleCounting') {
            navigate(`./../../../floor/${row.floorId}/space/${row.spaceId}/device/${row.deviceId}`)
          }
        }}
      />
    </>
  );
}

export default BuildingDeviceList;