import IReportDto from '@api/models/IReportDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class ReportsGetAllBySiteIdQuery extends GetQuery<IReportDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/reports/getReportsForSite/${this.siteId}`;
  }
}
