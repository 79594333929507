import { Route } from '@src/types/Route';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import SitePerformance from '@pages/site/performance/SitePerformance';

export const SitePerformanceRoutes: Route[] = [
  {
    path: 'performance',
    element: SitePerformance,
    analytics: { page: 'site_performance', category: 'site_performance' },
    link: {
      label: 'SitePerformance',
      path: 'performance',
      icon: regular('bullseye'),
      analytics: { action: 'site_performance', category: 'feature_navigation' }
    }
  }
];