import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import { useModal } from '@hooks/useModal';
import HeatmapTooltip from '@pages/site/heatmap/components/heatmap-square//HeatmapTooltip';
import HeatmapSpaceModal from '@pages/site/heatmap/components/heatmap-square//HeatmapSpaceModal';
import HeatmapSquare from '@pages/site/heatmap/components/heatmap-square//HeatmapSquare';
import { HeatmapConfig } from '@pages/site/heatmap/HeatmapPage';

type PropTypes = {
  space: HeatmapSpaceDto;
  config: HeatmapConfig;
  linkPathToSpace: string;
  onFloorplan?: boolean;
  size?: number;
  loadingRefetch?: boolean;
}

const HeatmapSquareWrapper = ({ space, config, linkPathToSpace, onFloorplan, size, loadingRefetch }: PropTypes) => {
  const { isOpen, toggle, ref } = useModal({});
  const showSpaceIcon = !config.model;

  return (
    <>
      <HeatmapTooltip
        space={space}
        config={config}
        noContent={showSpaceIcon}
      >
        <HeatmapSquare
          space={space}
          toggleModal={toggle}
          onFloorplan={onFloorplan}
          showSpaceIcon={showSpaceIcon}
          size={size}
          loadingRefetch={loadingRefetch}
          metricType={config.metricType}
        />
      </HeatmapTooltip>

      <HeatmapSpaceModal
        ref={ref}
        spaceId={space.spaceId}
        isOpen={isOpen}
        linkPathToSpace={linkPathToSpace}
      />
    </>
  );
};

export default HeatmapSquareWrapper;