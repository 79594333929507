import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { Title } from '@components/core/Title';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  device: DeviceWithMetrics,
  inSidebar?: boolean
}

const DeviceCardHeader = ({ device, inSidebar }: PropTypes) => {
  const { t } = useTranslation();
  const { getDeviceType } = useDeviceConfigContext();

  return (
    <Header inSidebar={inSidebar}>
      <Title
        text={t(`DeviceTypes.${getDeviceType(device.deviceModel)}`, { ns: 'enums' })}
        size='sm'
        subtitle={{ text: device.friendlyName }}
      />
      {!inSidebar &&
        <LinkContainer>
          <Link to={`device/${device.id}`}>
            <IconContainer>
              <ArrowRightIcon icon={solid('arrow-right')} />
            </IconContainer>
          </Link>
        </LinkContainer>
      }
    </Header>
  );
};

export default DeviceCardHeader;

const Header = styled.div<{ inSidebar?: boolean }>`
  display: flex;
  align-items: center;
  
  padding: ${p => p.inSidebar ? '0 0 12px 0' : '16px 24px'};
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const LinkContainer = styled.div`
  flex-shrink: 0;
  margin-left: auto;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  margin-left: auto;
  border: 1px solid ${p => p.theme.primary.outlinedBorder};
  border-radius: 50%;
`;

const ArrowRightIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.primary.main};
`;