import { RefObject } from 'react';
import { ICascaderMultiSelectNode, ICascaderMultiSelectNodeGroup, IInternalNode, IInternalNodeGroup } from '@components/cascader-multi-select/CascaderMultiSelect.types';

/**
 * Returns true if a node has any selected descendants, false otherwise.
 */
const hasSelectedDescendants = <TValue,>(node: ICascaderMultiSelectNode<TValue>): boolean => {
  return node.childGroup
    ? node.childGroup.nodes.some(child => child.selected || hasSelectedDescendants(child))
    : false;
};

/**
 * Assigns a unique ID to each node and sets 'selected' and 'partial' properties.
 */
export const createNodeTree = <TValue,>(parentId: string, group?: ICascaderMultiSelectNodeGroup<TValue>, parentIsSelected?: boolean): IInternalNodeGroup<TValue> | undefined => {
  if (group === undefined) {
    return undefined;
  }

  const groupWithIds: IInternalNodeGroup<TValue> = {
    ...group,
    id: parentId,
    nodes: group.nodes.map((node, i) => ({
      ...node,
      selected: parentIsSelected || node.selected,
      partial: hasSelectedDescendants(node),
      id: `${parentId}_${i}`,
      childGroup: createNodeTree(`${parentId}_${i}`, node.childGroup, parentIsSelected || node.selected)
    }))
  }

  return groupWithIds;
};

/**
 * Returns an object containing
 *  - labels: an array of all selected labels (sorted by top-down hierarchy), excluding children when the parent is selected.
 *  - items: an array of all values of the selected nodes
 */
export const getSelectedLabelsAndValues = <TValue,>(group?: IInternalNodeGroup<TValue>, ignoreLabels?: boolean, includeChildValues?: boolean): { labels: string[], values: TValue[] } => {
  if (!group) {
    return { labels: [], values: [] };
  }

  const combineValues = (values: TValue[], node: IInternalNode<TValue>) => {
    return node.selected && node.value ? [...values, node.value] : values;
  }

  const combineLabels = (groupLabels: string[], node: IInternalNode<TValue>) => {
    return node.selected ? [...groupLabels, node.label] : groupLabels
  }

  // Both variables 'groupLabels' and 'childLabels' are required to maintain correct order of selected labels
  let groupLabels: string[] = [];
  let childLabels: string[] = [];
  let values: TValue[] = [];

  group.nodes.forEach(node => {
    if (node.selected && !includeChildValues) {
      if (!ignoreLabels) {
        groupLabels = combineLabels(groupLabels, node);
      }

      values = combineValues(values, node);
    } else {
      const { labels: selectedLabels, values: selectedItems } = getSelectedLabelsAndValues(node.childGroup, node.selected, includeChildValues);

      if (!ignoreLabels) {
        groupLabels = combineLabels(groupLabels, node);
        childLabels = [...childLabels, ...selectedLabels];
      }

      values = combineValues(values, node);
      values = [...values, ...selectedItems];
    }
  });

  return { labels: [...groupLabels, ...childLabels], values: values }
};

/**
 * Set selected or unselected state (also set partial = false) of node and all its children.
 */
export const updateNodeAndChildren = <TValue,>(node: IInternalNode<TValue>, state: boolean): IInternalNode<TValue> => {
  return {
    ...node,
    selected: state,
    partial: false,
    childGroup: node.childGroup
      ? {
        ...node.childGroup,
        nodes: node.childGroup.nodes.map(childNode => updateNodeAndChildren(childNode, state)),
      }
      : undefined
  }
};

export const calculateOverflowingLabelCount = (labelContainerRef: RefObject<HTMLDivElement>, labelContainerWidth: number | undefined, selectedLabels: string[]) => {
  if (!labelContainerRef.current || !labelContainerWidth || selectedLabels.length === 0) {
    return 0;
  }

  const gap = 5;
  let count = 0;
  let accWidthOfChildren = 0;

  for (const child of Object.values(labelContainerRef.current.children)) {
    accWidthOfChildren += (child as HTMLElement).clientWidth + (count === 0 ? 0 : gap);

    if (accWidthOfChildren >= labelContainerWidth) {
      break;
    }

    count += 1;
  }

  return selectedLabels.length - count;
}