import { PatchQuery } from '@api/queries/common/PatchQuery';
import { Device } from '@api/models/Device';

export default class DeviceMergeCommand extends PatchQuery<Device> {
  oldId: number;
  newId: number;
  newDeviceIdentifier: string;

  constructor(oldId: number, newId: number, newDeviceIdentifier: string){
    super()
    this.oldId = oldId;
    this.newId = newId;
    this.newDeviceIdentifier = newDeviceIdentifier
  }

  public targetUrl(): string {
    return '/devices/merge';
  }
}