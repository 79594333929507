import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { PatchQuery } from '../../common/PatchQuery';

export default class SpaceClimateControlUpdateCommand extends PatchQuery<SpaceClimateControlDto> {
  minTemp?: number;
  maxTemp?: number;
  targetTemp?: number;
  controlMode?: string;
  endDate?: string;
  addedAsException?: boolean;

  constructor(spaceClimateControl: SpaceClimateControlDto, addedAsException?: boolean) {
    super(spaceClimateControl.id);
    this.minTemp = spaceClimateControl.minTemp;
    this.maxTemp = spaceClimateControl.maxTemp;
    this.targetTemp = spaceClimateControl.targetTemp;
    this.controlMode = spaceClimateControl.controlMode;
    this.endDate = spaceClimateControl.endDate;
    this.addedAsException = addedAsException;
  }

  public targetUrl(): string {
    return '/ClimateControl/space';
  }
}
