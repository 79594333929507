import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayoutContext } from '@contexts/LayoutContext';
import styled from 'styled-components';
import { Button } from '@components/core/Button';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type PropTypes = {
  styles?: React.CSSProperties;
}

const FullScreenToggle = ({ styles }: PropTypes) => {
  const { isFullScreen, toggleFullScreen } = useLayoutContext();

  const icon = isFullScreen
    ? solid('arrow-down-left-and-arrow-up-right-to-center')
    : solid('arrow-up-right-and-arrow-down-left-from-center');

  return (
    <Button
      tertiary
      circle
      label={<Icon icon={icon} />}
      onClick={toggleFullScreen}
      style={styles}
    />
  );
};

export default FullScreenToggle;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${p => p.theme.palette.primary};
  font-size: 16px;
`;