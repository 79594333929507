import { MetricType } from '@api/enums/MetricType';
import { SpaceWithDeviceMetrics } from '@api/models/SpaceWithDeviceMetrics';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class SpaceWithMetricsQuery extends PostQuery<SpaceWithDeviceMetrics> {
  spaceId: number;
  metricTypes: readonly MetricType[];

  constructor(spaceId: number, metricTypes: readonly MetricType[]) {
    super();
    this.spaceId = spaceId;
    this.metricTypes = metricTypes;
  }

  public targetUrl(): string {
    return '/metrics/space';
  }
}
