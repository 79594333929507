
import { Route } from '@src/types/Route';
import { Permission } from '@api/enums/Permission';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FeatureFlag } from '@api/enums/FeatureFlag';
import SiteEdit from '@pages/site/settings/site/edit/SiteEdit';
import SiteMetadataEdit from '@pages/site/settings/site/metadata/SiteMetadataEdit';
import SiteSettings_Features from '@pages/site/settings/site/features/SiteSettings_Features';
import DeviceList from '@pages/site/settings/devices/DeviceList';
import SiteBuildingList from '@pages/site/settings/site/buildings/SiteBuildingList';
import BuildingCreate from '@pages/site/settings/site/buildings/create/BuildingCreate';
import PeopleCountingZoneList from '@pages/site/settings/people-counting/PeopleCountingZoneList';
import PeopleCountingZoneAssign from '@pages/site/settings/people-counting/assign/PeopleCountingZoneAssign';
import PeopleCountingZoneCreate from '@pages/site/settings/people-counting/create/PeopleCountingZoneCreate';
import DeviceCreate from '@pages/site/settings/devices/create/DeviceCreate';
import DeviceEdit from '@pages/site/settings/devices/edit/DeviceEdit';
import DeviceReplace from '@pages/site/settings/devices/replace/DeviceReplace';
import DeviceMerge from '@pages/site/settings/devices/merge/DeviceMerge';
import DeviceMove from '@pages/site/settings/devices/move/DeviceMove';
import UtilityConsumptionList from '@pages/site/settings/utility-management/UtilityConsumptionList';
import UtilityConsumptionCreate from '@pages/site/settings/utility-management/create/UtilityConsumptionCreate';
import UtilityConsumptionEdit from '@pages/site/settings/utility-management/edit/UtilityConsumptionEdit';
import SiteManagement from '@pages/site/settings/site/SiteManagement';
import GatewayEdit from '@pages/site/settings/devices/gateway/edit/GatewayEdit';
import SalesOrderManagement from '@pages/site/settings/sales-order/SalesOrderManagement';
import SalesOrderCreate from '@pages/site/settings/sales-order/create/SalesOrderCreate';
import SalesOrderEdit from '@pages/site/settings/sales-order/edit/SalesOrderEdit';
import SiteSettingsPage from '@pages/site/settings/SiteSettingsPage';

export const SiteManagementRoutes: Route[] = [
  {
    path: 'settings/*',
    element: SiteSettingsPage,
    permission: Permission.SiteWrite,
    link: {
      label: 'SiteSettings',
      path: 'settings',
      icon: regular('cog'),
      analytics: { action: 'site_settings', category: 'feature_navigation' },
      permission: Permission.SiteWrite
    },
    children: [
      {
        path: 'site/*',
        element: SiteManagement,
        link: {
          label: 'SiteInformation',
          path: 'site',
          icon: regular('buildings'),
          analytics: { action: 'site_information', category: 'feature_navigation' }
        },
        children: [
          {
            path: '',
            element: SiteEdit,
            analytics: { page: 'site', category: 'site_settings' },
            link: {
              label: 'Site',
              path: '',
              end: true,
              analytics: { action: 'site_tab', category: 'site_settings' }
            }
          },
          {
            path: 'metadata',
            element: SiteMetadataEdit,
            analytics: { page: 'metadata', category: 'site_settings' },
            link: {
              label: 'Metadata',
              path: 'metadata',
              analytics: { action: 'metadata_tab', category: 'site_settings' }
            }
          },
          {
            path: 'features',
            element: SiteSettings_Features,
            analytics: { page: 'features', category: 'site_settings' },
            link: {
              label: 'Features',
              path: 'features',
              analytics: { action: 'features_tab', category: 'site_settings' }
            }
          },
          {
            path: 'buildings',
            element: SiteBuildingList,
            analytics: { page: 'buildings', category: 'site_settings' },
            link: {
              label: 'Buildings',
              path: 'buildings',
              analytics: { action: 'buildings_tab', category: 'site_settings' }
            }
          },
          {
            path: 'buildings/create',
            element: BuildingCreate,
            analytics: { page: 'building_create', category: 'site_settings' },
          },
        ]
      },
      {
        path: 'devices',
        element: DeviceList,
        analytics: { page: 'device_management', category: 'site_settings' },
        link: {
          label: 'DeviceManagement',
          path: 'devices',
          icon: regular('album'),
          analytics: { action: 'device_management', category: 'site_settings' }
        },
      },
      {
        path: 'devices/create',
        element: DeviceCreate,
        analytics: { page: 'device_create', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId/edit',
        element: DeviceEdit,
        analytics: { page: 'device_edit', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId/replace',
        element: DeviceReplace,
        analytics: { page: 'device_replace', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId/merge',
        element: DeviceMerge,
        analytics: { page: 'device_merge', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId/move',
        element: DeviceMove,
        analytics: { page: 'device_move', category: 'site_settings' },
      },
      {
        path: 'devices/gateway/:deviceId',
        element: GatewayEdit,
        analytics: { page: 'gateway_edit', category: 'site_settings' },
      },
      {
        path: 'utility-management',
        element: UtilityConsumptionList,
        analytics: { page: 'utility_bill_management', category: 'site_settings' },
        link: {
          label: 'UtilityManagement',
          path: 'utility-management',
          icon: regular('bolt'),
          analytics: { action: 'utility_bill_management', category: 'site_settings' },
          featureFlag: FeatureFlag.UtilityConsumption,
          permission: Permission.SuperAdministrator
        },
        featureFlag: FeatureFlag.UtilityConsumption,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'utility-management/create',
        element: UtilityConsumptionCreate,
        analytics: { page: 'utility_bill_create', category: 'site_settings' },
        featureFlag: FeatureFlag.UtilityConsumption,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'utility-management/:billId',
        element: UtilityConsumptionEdit,
        analytics: { page: 'utility_bill_edit', category: 'site_settings' },
        featureFlag: FeatureFlag.UtilityConsumption,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'people-counting',
        element: PeopleCountingZoneList,
        analytics: { page: 'people_counting', category: 'site_settings' },
        link: {
          label: 'PeopleCounting',
          path: 'people-counting',
          icon: regular('people-roof'),
          analytics: { action: 'people_counting', category: 'site_settings' }
        },
      },
      {
        path: 'people-counting/assign/:virtualDeviceId',
        element: PeopleCountingZoneAssign,
        analytics: { page: 'people_counting_assign', category: 'site_settings' },
      },
      {
        path: 'people-counting/create',
        element: PeopleCountingZoneCreate,
        analytics: { page: 'people_counting_create', category: 'site_settings' },
      },
      {
        path: 'sales-order',
        element: SalesOrderManagement,
        analytics: { page: 'sales_order', category: 'site_settings' },
        link: {
          label: 'SalesOrder',
          path: 'sales-order',
          icon: regular('file-invoice'),
          analytics: { action: 'sales_order', category: 'site_settings' },
          permission: Permission.SuperAdministrator
        },
        permission: Permission.SuperAdministrator
      },
      {
        path: 'sales-order/new',
        element: SalesOrderCreate,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'sales-order/:salesOrderItemId',
        element: SalesOrderEdit,
        permission: Permission.SuperAdministrator
      }
    ]
  }
];
