import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OperatorChallengeDto } from '@api/models/OperatorChallengeDto';
import { Button } from '@components/core/Button';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import InputFormField from '@pages/site/resident-app/components/common/InputFormField';
import LabelWithCharacterCount from '@pages/site/resident-app/components/common/LabelWithCharacterCount';
import { TextArea } from '@components/Form';
import { CreateChallengeForm } from '../../create-challenge/ResidentApp_CreateChallenge';
import ResidentAppFormErrorMessage from '@pages/site/resident-app/components/common/ResidentAppFormErrorMessage';
import { getDisplayChallengeStatus, getSeasonFromDates, isEndDateAfterStartDate, isOverlappingOpenChallenge } from '@pages/site/resident-app/ResidentAppUtils';
import { format } from 'date-fns';
import TitleWithButton from '@pages/site/resident-app/components/common/TitleWIthButton';
import IconWithText from '@pages/site/resident-app/components/common/IconWithText';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';
import en from 'antd/es/date-picker/locale/en_US';
import Label from '@pages/site/resident-app/components/common/Label';
import { useEffect, useRef, useState } from 'react';
import SpaceTypeTargetTable from './SpaceTypeTargetTable';

type ChallengeInfoContentProps = {
  challenge?: OperatorChallengeDto;
  onEdit?: () => void;
  isEditDisabled?: boolean;
  methods?: UseFormReturn<CreateChallengeForm>;
  metricType: MetricType;
  challenges?: OperatorChallengeDto[];
}

const ChallengeInfoContent = ({challenge, onEdit, isEditDisabled, methods, metricType, challenges}: ChallengeInfoContentProps) => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const { toLocale, getUnit, localisation } = useLocalisation();
  const spanRef = useRef<HTMLSpanElement>(null); // Reference to measure text width
  const [leftPosition, setLeftPosition] = useState(10);
  const modalTemperature = `${toLocale(MetricType.Temperature, 22)}${getUnit(MetricType.Temperature)}`;

  const elecPercentageValue = methods ? methods.watch('electricityPercentage') : undefined;

  useEffect(() => {
    if (spanRef.current) {
      const textWidth = spanRef.current.offsetWidth;
      setLeftPosition(textWidth + 10);
    }
  }, [elecPercentageValue]);

  const TitleModalContent = (
    <>
      <ModalTitle>{t('ResidentApp.TitleHelp')}</ModalTitle>
      <ModalDescription>{t('ResidentApp.TitleHelpSummary')}</ModalDescription>
      <List>
        <ListItem>
          {t(`ResidentApp.TitleHelpList.${metricType}.ItemOne`, {
            temperature: modalTemperature,
            currency: localisation.currency,
          })}
        </ListItem>
        <ListItem>
          {t(`ResidentApp.TitleHelpList.${metricType}.ItemTwo`, {
            temperature: modalTemperature,
            currency: localisation.currency,
          })}
        </ListItem>
        <ListItem>
          {t(`ResidentApp.TitleHelpList.${metricType}.ItemThree`, {
            temperature: modalTemperature,
          })}
        </ListItem>
      </List>
    </>
  );

  const DescriptionModalContent = (
    <>
      <ModalTitle>{t('ResidentApp.DescriptionHelp')}</ModalTitle>
      <ModalDescription>{t('ResidentApp.DescriptionHelpSummary')}</ModalDescription>
      <List>
        <ListItem>
          {t(`ResidentApp.DescriptionHelpList.${metricType}.ItemOne`, {
            temperature: modalTemperature,
            currency: localisation.currency,
          })}
        </ListItem>
      </List>
    </>
  );

  return (
    <div>
      <TitleWithButton
        title={t('ResidentApp.ChallengeInfo')}
        button={
          challenge &&
          onEdit && (
            <Button
              label={
                <IconWithText
                  icon={regular('pencil')}
                  color={theme.palette.text.fair}
                  text={t('Edit', { ns: 'common' })}
                />
              }
              tertiary
              onClick={onEdit}
              disabled={isEditDisabled}
            />
          )
        }
      />

      <Content>
        {challenge && onEdit && (
          <div>
            <Label text={t('ResidentApp.Status')} marginBottom />
            <Text>{t(getDisplayChallengeStatus(challenge))}</Text>
          </div>
        )}
        <div>
          <DateInputs>
            <DateWrapper>
              <Label inputName="startDate" text={t('ResidentApp.StartDate')} marginBottom />
              {methods ? (
                <>
                  <Controller
                    name="startDate"
                    control={methods.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('StartDateRequired', { ns: 'validation' }),
                      },
                    }}
                    render={({ field }) => (
                      <StyledDatepicker
                        {...field}
                        locale={{
                          lang: {
                            ...en.lang,
                            fieldDateFormat: localisation.dateFormats.datepickerField,
                          },
                          timePickerLocale: { ...en.timePickerLocale },
                        }}
                        minDate={dayjs(new Date())}
                        placeholder={localisation.dateFormats.datepickerField.toLowerCase()}
                        error={!!methods.formState.errors.startDate}
                      />
                    )}
                  />
                  {methods.formState.errors.startDate && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.startDate.message}
                    />
                  )}

                  {/* show error if dates not within the same season (if Electricity challenge) */}
                  {metricType === MetricType.ElectricityKwh &&
                    methods.watch('startDate') &&
                    methods.watch('endDate') &&
                    !getSeasonFromDates(methods.watch('startDate'), methods.watch('endDate')) && (
                    <ResidentAppFormErrorMessage
                      message={t('DatesNotWithinSameSeason', { ns: 'validation' })}
                    />
                  )}

                  {methods.formState.isDirty &&
                    challenges &&
                    isOverlappingOpenChallenge(
                      challenges,
                      metricType,
                      methods.watch('startDate'),
                      methods.watch('endDate'),
                      challenge?.id
                    ) && (
                    <ResidentAppFormErrorMessage
                      message={t('ExistingChallengeAlready', {
                        ns: 'validation',
                        metricType: t(metricType, { ns: 'enums' }).toLowerCase(),
                      })}
                    />
                  )}
                </>
              ) : (
                <Text>
                  {challenge &&
                    format(
                      new Date(challenge.startDate),
                      localisation.dateFormats.slashFormatDisplay
                    )}
                </Text>
              )}
            </DateWrapper>
            <DateWrapper>
              <Label inputName="endDate" text={t('ResidentApp.EndDate')} marginBottom />
              {methods ? (
                <>
                  <Controller
                    name="endDate"
                    control={methods.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('EndDateRequired', { ns: 'validation' }),
                      },
                    }}
                    render={({ field }) => (
                      <StyledDatepicker
                        {...field}
                        locale={{
                          lang: {
                            ...en.lang,
                            fieldDateFormat: localisation.dateFormats.datepickerField,
                          },
                          timePickerLocale: { ...en.timePickerLocale },
                        }}
                        minDate={dayjs(new Date())}
                        placeholder={localisation.dateFormats.datepickerField.toLowerCase()}
                        error={!!methods.formState.errors.endDate}
                      />
                    )}
                  />
                  {metricType === MetricType.ElectricityKwh && (
                    <Note>{t('ResidentApp.MustBeWithinSameSeasonNote')}</Note>
                  )}

                  {methods.formState.errors.endDate && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.endDate.message}
                    />
                  )}
                </>
              ) : (
                <Text>
                  {challenge &&
                    format(
                      new Date(challenge.endDate),
                      localisation.dateFormats.slashFormatDisplay
                    )}
                </Text>
              )}
            </DateWrapper>
          </DateInputs>
          {methods &&
            !isEndDateAfterStartDate(methods.watch('startDate'), methods.watch('endDate')) &&
            methods.formState.isDirty && (
            <ResidentAppFormErrorMessage
              message={t('StartDateBeforeEndDate', { ns: 'validation' })}
            />
          )}
        </div>

        {metricType === MetricType.Temperature && (
          <div>
            {methods ? (
              <>
                <InputFormField
                  inputName="averageTemperature"
                  value={challenge?.targetValue}
                  inputType="number"
                  label={t('ResidentApp.AverageTemperatureUnit', {
                    temperatureUnit: getUnit(MetricType.Temperature),
                  })}
                  isRequired={true}
                  min={'0'}
                  error={!!methods.formState.errors.averageTemperature}
                />
                {methods.formState.errors.averageTemperature && (
                  <ResidentAppFormErrorMessage
                    message={methods.formState.errors.averageTemperature.message}
                  />
                )}
              </>
            ) : (
              <>
                <Label
                  text={t('ResidentApp.AverageTemperatureUnit', {
                    temperatureUnit: getUnit(MetricType.Temperature),
                  })}
                  marginBottom
                />
                <Text>
                  {toLocale(MetricType.Temperature, challenge?.targetValue ?? 0, { round: 1 })}
                </Text>
              </>
            )}

            <ChallengeTargetInfo>{t('ResidentApp.AverageTemperatureInfo')}</ChallengeTargetInfo>
          </div>
        )}

        {metricType === MetricType.ElectricityKwh && (
          <div>
            {methods ? (
              <>
                <Label text={t('ResidentApp.ElectricityChallengeTargetLabel')} marginBottom />
                <InputWithPercentage>
                  <InputFormField
                    style={{ paddingRight: '25px' }}
                    inputName="electricityPercentage"
                    value={challenge?.targetValue}
                    inputType="number"
                    isRequired={true}
                    min={1}
                    max={100}
                    placeholder={t('ResidentApp.EnterPercentage')}
                    error={!!methods.formState.errors.electricityPercentage}
                  />
                  {/* Hidden span to measure the width of the text */}
                  {elecPercentageValue && (
                    <>
                      <HiddenSpan ref={spanRef} aria-hidden>
                        {elecPercentageValue}
                      </HiddenSpan>
                      <PercentageSign style={{ left: `${leftPosition}px` }}>%</PercentageSign>
                    </>
                  )}
                </InputWithPercentage>

                {methods.formState.errors.electricityPercentage && (
                  <ResidentAppFormErrorMessage
                    message={methods.formState.errors.electricityPercentage.message}
                  />
                )}
              </>
            ) : (
              <>
                {challenge && (
                  <Label
                    text={`${t('ResidentApp.ElectricityChallengeTargetLabel')} (${t(
                      `ThresholdSeason.${getSeasonFromDates(
                        dayjs(challenge.startDate),
                        dayjs(challenge.endDate)
                      )}`,
                      { ns: 'enums' }
                    )})`}
                    marginBottom
                  />
                )}
                <Text>
                  {`${toLocale(MetricType.Temperature, challenge?.targetValue ?? 0, {
                    round: 1,
                  })}%`}
                </Text>
              </>
            )}

            <ChallengeTargetInfo>{t('ResidentApp.ElectricityTargetInfo')}</ChallengeTargetInfo>

            {/* show calculated space type targets */}
            <TargetContainer>
              <SpaceTypeTargetTable
                percentage={methods ? elecPercentageValue : challenge?.targetValue}
                season={
                  methods
                    ? getSeasonFromDates(methods?.watch('startDate'), methods?.watch('endDate'))
                    : getSeasonFromDates(dayjs(challenge?.startDate), dayjs(challenge?.endDate))
                }
              />
            </TargetContainer>
          </div>
        )}

        <div>
          {methods ? (
            <>
              <LabelWithCharacterCount
                inputName="title"
                label={t('ResidentApp.Title')}
                modalContent={TitleModalContent}
                currentCharacterCount={methods.watch('title')?.length ?? 0}
                characterLimit={100}
              />
              <InputFormField
                inputName="title"
                value={challenge?.title}
                placeholder={t('ResidentApp.ChallengeTitleInputPlaceholder')}
                maxLength={100}
                isRequired={true}
                requiredErrorMessage={t('TitleRequired', { ns: 'validation' })}
                error={!!methods.formState.errors.title}
              />
              {methods.formState.errors.title && (
                <ResidentAppFormErrorMessage message={methods.formState.errors.title.message} />
              )}
            </>
          ) : (
            <>
              <Label text={t('ResidentApp.Title')} marginBottom />
              <Text>{challenge?.title}</Text>
            </>
          )}
        </div>

        <div>
          {methods ? (
            <>
              <LabelWithCharacterCount
                inputName="challengeDescription"
                label={t('ResidentApp.ChallengeDescription')}
                modalContent={DescriptionModalContent}
                currentCharacterCount={methods.watch('challengeDescription')?.length ?? 0}
                characterLimit={800}
              />
              <StyledTextArea
                id="challengeDescription"
                error={!!methods.formState.errors.challengeDescription}
                placeholder={t('ResidentApp.ChallengeDescriptionInputPlaceholder')}
                rows={5}
                {...methods.register('challengeDescription', {
                  value: challenge?.description,
                  maxLength: {
                    value: 800,
                    message: t('InvalidLength', { ns: 'validation', maxLength: 800 }),
                  },
                  required: t('ChallengeDescriptionRequired', { ns: 'validation' }),
                })}
              />
              {methods.formState.errors.challengeDescription && (
                <ResidentAppFormErrorMessage
                  message={methods.formState.errors.challengeDescription.message}
                />
              )}
            </>
          ) : (
            <>
              <Label text={t('ResidentApp.ChallengeDescription')} marginBottom />
              <Text>{challenge?.description}</Text>
            </>
          )}
        </div>
      </Content>
      {methods && <GreyText>{t('NBText', { ns: 'residentAppTermsConditions' })}</GreyText>}
    </div>
  );
}

export default ChallengeInfoContent;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 640px;
  margin-top: 25px;
`;

const DateInputs = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledTextArea = styled(TextArea)<{error?: boolean}>`
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  background-color: ${(p) => p.theme.palette.forms.input.background};
  border: 1px solid ${(p) => p.error ? p.theme.error.main : p.theme.palette.forms.input.border};
`;

const ModalTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin: 0;

  @media (min-width: 1024px){
    font-size: 18px;
  }
`;

const ModalDescription = styled.p`
  margin: 15px 0;
  font-size: 14px;

  @media (min-width: 1024px){
    font-size: 16px;
  }
`;

const List = styled.ul`
  padding-left: 25px;
`;

const ListItem = styled.li`
  white-space: pre-line;
  font-weight: 500;

  @media (min-width: 1024px){
    font-size: 16px;
  }

  & + li {
    margin-top: 5px;
  }
`;

const DateWrapper = styled.div`
  width: 50%;
`;

const StyledDatepicker = styled(DatePicker)<{error?: boolean}>`
  background-color: ${(p) => p.theme.palette.forms.input.background};
  border: 1px solid ${(p) => p.error ? p.theme.error.main : p.theme.palette.forms.input.border};
  border-radius: 5px;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};
  width: 100%;
`;

const ChallengeTargetInfo = styled.span`
  font-size: 12px;
  color: ${(p) => p.theme.palette.text.fair};
  display: block;
  margin-top: 10px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  white-space: pre-line;
  font-weight: 500;
`;

const GreyText = styled(Text)`
  color: ${(p) => p.theme.palette.text.fair};
  margin-top: 25px;
`;

const InputWithPercentage = styled.div`
  position: relative;
`;

const PercentageSign = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 14px;
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  font-size: inherit;
  font-family: inherit;
  font-size: 14px;
  max-width: calc(100% - 30px);
`;

const TargetContainer = styled.div`
  margin-top: 32px;
`;

const Note = styled.span`
  color: ${({ theme }) => theme.palette.text.fair};
  font-size: 12px;
`;