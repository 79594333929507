import SpaceOccupancyDto from '@api/models/SpaceOccupancyDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class PeopleCountingGetBySiteIdQuery extends GetQuery<SpaceOccupancyDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/metrics/peopleCount/currentCountBySiteId/${this.siteId}`;
  }
}
