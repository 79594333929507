import { MetricType } from '@api/enums/MetricType';
import { WaterMeterAlarmCode } from '@api/enums/WaterMeterAlarmCode';
import { AlertRuleTrigger, AlertRuleTriggerType } from '@api/models/AlertRule';
import { Select } from '@components/select/Select';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

export enum AlertRuleTriggerCategory {
  Numeric = 'Numeric',
  State = 'State'
}

/**
 * Get the trigger category (default: Numeric)
 */
const getTriggerCategory = (metricType: string): AlertRuleTriggerCategory => {
  switch (metricType) {
    case MetricType.WaterLeak:
    case MetricType.WaterMeterAlarm:
      return AlertRuleTriggerCategory.State;

    default:
      return AlertRuleTriggerCategory.Numeric
  }
};

const getOptionsForStateTypes = (metricType: MetricType, t: TFunction) => {
  let options: string[] = [];

  switch (metricType) {
    case MetricType.WaterLeak:
      options = ['True', 'False'];
      break;

    case MetricType.RelayOutputStatus:
      options = ['Open', 'Closed'];
      break;

    case MetricType.WaterMeterAlarm:
      options = Object.values(WaterMeterAlarmCode);
      break;
  }

  return options.map(x => ({ label: t(`TriggerConfiguration.States.${x}`, { ns: 'settingsAlerting' }), value: x }));
}

const getOptionsForNumericTypes = (t: TFunction) => {
  const options = [AlertRuleTriggerType.GreaterThan, AlertRuleTriggerType.LessThan, AlertRuleTriggerType.WithinRange, AlertRuleTriggerType.OutsideRange];
  return options.map(x => ({ label: t(`TriggerConfiguration.Types.${x}`, { ns: 'settingsAlerting' }), value: x }));
};

type TriggerTypeSelectProps = {
  trigger: AlertRuleTrigger;
  onChange: (trigger: AlertRuleTrigger) => void;
}

const TriggerTypeSelect = ({ trigger, onChange }: TriggerTypeSelectProps) => {
  const { t } = useTranslation();

  if (!trigger.metric) {
    return null;
  }

  const triggerCategory = getTriggerCategory(trigger.metric);

  if (!triggerCategory) {
    return null;
  }

  if (triggerCategory === AlertRuleTriggerCategory.State) {
    return (
      <Select
        value={!trigger.state ? null : { label: t(`TriggerConfiguration.States.${trigger.state}`, { ns: 'settingsAlerting' }), value: trigger.state }}
        options={getOptionsForStateTypes(trigger.metric, t)}
        onChange={selected => selected && onChange({ metric: trigger.metric, type: AlertRuleTriggerType.State, state: selected.value })}
      />
    );
  }

  return (
    <Select
      value={trigger.type && { label: t(`TriggerConfiguration.Types.${trigger.type}`, { ns: 'settingsAlerting' }), value: trigger.type }}
      options={getOptionsForNumericTypes(t)}
      onChange={selected => selected && onChange({ metric: trigger.metric, type: selected.value })}
    />
  );
};

export default TriggerTypeSelect;