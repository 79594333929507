import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpaceClimateControlDetailsDto } from '@api/models/SpaceClimateControlDetailsDto';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { usePopover } from '@hooks/usePopover';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type TableActionsProp = {
  spaceDetails: SpaceClimateControlDetailsDto;
  onViewDetails: () => void;
  onNavigateToSpace: (spaceDetails: SpaceClimateControlDetailsDto) => void;
  onCreateException: (spaceId: number) => void;
  onEditException: (spaceId: number) => void;
}

const TableActions = ({ spaceDetails, onViewDetails, onNavigateToSpace, onCreateException, onEditException }: TableActionsProp) => {
  const { t } = useTranslation();
  const { visible, toggle, ref } = usePopover({});
  const theme = useTheme();

  const PopoverContent = (
    <ActionMenu>
      <ActionLabel onClick={onViewDetails}>
        {t('ClimateControl.ViewDetails', { ns: 'molecules' })}
      </ActionLabel>

      <ActionLabel onClick={() => onNavigateToSpace(spaceDetails)}>
        {t('ClimateControl.GoToSpace', { ns: 'molecules' })}
      </ActionLabel>

      {spaceDetails.isAnException
        ? (
          <ActionLabel onClick={() => onEditException(spaceDetails.spaceId)}>
            {t('ClimateControl.EditException', { ns: 'molecules' })}
          </ActionLabel>
        )
        : (
          <ActionLabel onClick={() => onCreateException(spaceDetails.spaceId)}>
            {t('ClimateControl.AddException', { ns: 'molecules' })}
          </ActionLabel>
        )
      }
    </ActionMenu>
  );

  return (
    <Popover
      ref={ref}
      visible={visible}
      popoverContent={PopoverContent}
      placement={PopoverPlacement.BottomRight}
      hideTriangle={true}
      offsetY={-8}
      offsetX={-58}
      containerStyles={{
        boxShadow: `0px 1px 3px 1px ${theme.palette.shadows.extraLight}`,
      }}
    >
      <MenuIcon
        open={visible}
        icon={solid('ellipsis-vertical')}
        onClick={() => toggle()}
      />
    </Popover >
  )
}

export default TableActions;

const ActionMenu = styled.div`
  width: 150px;
`;

const ActionLabel = styled.div`
  width: 100%;
  padding: 8px 12px;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;

const MenuIcon = styled(FontAwesomeIcon) <{ open: boolean }>`
  margin-top: 5px;
  color: ${p => p.open ? p.theme.primary.main : p.theme.action.active};
  cursor: pointer;
  padding: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;