import { useTranslation } from 'react-i18next';
import { Title } from '@components/core/Title';
import { useLayoutContext } from '@contexts/LayoutContext';
import { Route as RouteType } from '@src/types/Route';
import { CommonSidebar } from '@components/core/CommonSidebar';
import NavItem from '@pages/site/settings/components/NavItem';
import styled from 'styled-components';

type PropTypes = {
  routes?: RouteType[];
}

const Sidebar = ({ routes }: PropTypes) => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();

  return (
    <CommonSidebar isHidden={isFullScreen}>
      <Title
        text={t('SiteSettings', { ns: 'common' })}
        size='sm'
        style={{ paddingBottom: 20 }}
      />

      <List>
        {routes?.map((route) => route.link && (
          <NavItem
            key={route.link.label}
            route={route}
          />
        ))}
      </List>
    </CommonSidebar>
  );
};

export default Sidebar;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;