import { calculateOverflowingLabelCount, createNodeTree, getSelectedLabelsAndValues } from '@utils/CascaderMultiSelectUtils';
import { useSize } from '@hooks/useSize';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CascaderDropdownSelectNodeGroup, InternalNode, InternalNodeGroup } from '../components/searchable-dropdown-select/SearchableDropdownSelect.types';

type UseSearchableDropdownSelectProps<TValue> = {
  /**
     * Group of nodes with N number of child nodes
     */
  group: CascaderDropdownSelectNodeGroup<TValue>;
  /**
   * Array of values returned when selection changes.
   * Returns an empty array if a selection is made but no values are directly selected or present as children of the selected nodes.
   * Returns undefined when no selection is made, this is to distinguish between a selection without values and no selection at all.
   */
  onChange: (values?: TValue[]) => void;
  /**
   * Include values of selected descendants in returned array of values
   */
  includeChildValues?: boolean;
  /**
   * Triggering this pulse will reset the options to their original state
   */
  resetPulse?: boolean;
}

const useSearchableDropdownSelect = <TValue,>({ group, onChange, includeChildValues, resetPulse }: UseSearchableDropdownSelectProps<TValue>) => {
  const [appliedNodeGroup, setAppliedNodeGroup] = useState<InternalNodeGroup<TValue> | undefined>();
  const [transientNodeGroup, setTransientNodeGroup] = useState<InternalNodeGroup<TValue> | undefined>();
  const [allNodes, setAllNodes] = useState<InternalNode<TValue>[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const labelContainerRef = useRef<HTMLDivElement>(null);
  const { width: labelContainerWidth } = useSize(labelContainerRef);
  const [numHiddenItems, setNumHiddenItems] = useState<number>(0);

  const collectAllNodes = <TValue,>(nodeGroup: InternalNodeGroup<TValue> | undefined): InternalNode<TValue>[] => {
    const allNodes: InternalNode<TValue>[] = [];
    const stack: InternalNode<TValue>[] = nodeGroup?.nodes ? [...nodeGroup.nodes] : [];

    while (stack.length > 0) {
      const currentNode = stack.pop();
      if (currentNode) {
        allNodes.push(currentNode);
        if (currentNode.childGroup?.nodes) {
          stack.push(...currentNode.childGroup.nodes);
        }
      }
    }

    return allNodes;
  };

  const initializeNodeGroups = useCallback((group: CascaderDropdownSelectNodeGroup<TValue>, includeChildValues?: boolean) => {
    const nodeTree = createNodeTree('', cloneDeep(group));
    setAppliedNodeGroup(nodeTree);
    setTransientNodeGroup(cloneDeep(nodeTree));
    const { labels } = getSelectedLabelsAndValues(nodeTree, false, includeChildValues);
    setSelectedLabels(labels);
  }, []);

  useEffect(() => {
    initializeNodeGroups(group, includeChildValues);
  }, [group, includeChildValues, initializeNodeGroups, resetPulse]);

  useEffect(() => {
    setAllNodes(collectAllNodes(appliedNodeGroup));
    setTransientNodeGroup(cloneDeep(appliedNodeGroup));
  }, [appliedNodeGroup]);

  useEffect(() => {
    setNumHiddenItems(calculateOverflowingLabelCount(labelContainerRef, labelContainerWidth, selectedLabels))
  }, [labelContainerRef, labelContainerWidth, selectedLabels]);

  const updateNodeAndChildrenRecursively = (node: InternalNode<TValue>, isSelected: boolean): InternalNode<TValue> => {
    node.selected = isSelected;
    node.partial = false;

    if (node.childGroup?.nodes) {
      let partial: boolean | undefined = false;
      for (const childNode of node.childGroup.nodes) {
        const updatedChildNode = updateNodeAndChildrenRecursively(childNode, isSelected);
        partial = partial || updatedChildNode.selected || updatedChildNode.partial;
      }
      node.partial = partial;
    }
    return node;
  };

  const updateSelectionAndParentState = (node: InternalNode<TValue>, isSelected: boolean, parent: InternalNode<TValue> | null = null): InternalNode<TValue> => {
    const updatedNode = updateNodeAndChildrenRecursively(node, isSelected);
    if (parent?.childGroup?.nodes) {
      const { allSiblingsSelected, anyPartial } = parent.childGroup.nodes.reduce((acc, sibling) => {
        if (!sibling.selected) {
          acc.allSiblingsSelected = false;
          if (isSelected) acc.breakLoop = true;
        }
        if (sibling.partial) {
          acc.anyPartial = true;
          acc.breakLoop = true;
        }
        return acc;
      }, { allSiblingsSelected: true, anyPartial: false, breakLoop: false });

      parent.selected = allSiblingsSelected && !anyPartial;
      parent.partial = !allSiblingsSelected || anyPartial;
    }
    return updatedNode;
  };

  const findAndUpdateNodeById = (nodes: InternalNode<TValue>[], id: string, isSelected: boolean, parent: InternalNode<TValue> | null = null): InternalNode<TValue>[] => {
    return nodes.map(node => {
      if (node.id === id) {
        return updateSelectionAndParentState(node, isSelected, parent);
      } else if (node.childGroup?.nodes) {
        node.childGroup.nodes = findAndUpdateNodeById(node.childGroup.nodes, id, isSelected, node);
        const allChildrenSelected = node.childGroup.nodes.every(n => n.selected);
        node.selected = allChildrenSelected;
        node.partial = !allChildrenSelected && node.childGroup.nodes.some(n => n.selected || n.partial);
      }
      return node;
    });
  };

  const handleSelect = (node: InternalNode<TValue>) => {
    if (!appliedNodeGroup || !node.id) return;

    const updatedNodes = findAndUpdateNodeById(appliedNodeGroup.nodes, node.id, !node.selected);
    appliedNodeGroup.nodes = updatedNodes;

    setAppliedNodeGroup({ ...appliedNodeGroup });

    const { labels, values } = getSelectedLabelsAndValues(appliedNodeGroup, false, includeChildValues);
    setSelectedLabels(labels);
    if (labels.length > 0) {
      onChange(values);
    }
  };

  const handleRemoveSpace = (node: InternalNode<TValue>): void => {
    if (!appliedNodeGroup || !node.id) return;

    const updatedNodes = findAndUpdateNodeById(appliedNodeGroup.nodes, node.id, false);
    if (updatedNodes !== appliedNodeGroup.nodes) {
      const modifiedNodeGroup = { ...appliedNodeGroup, nodes: updatedNodes };

      setAppliedNodeGroup(modifiedNodeGroup);

      const { labels, values } = getSelectedLabelsAndValues(modifiedNodeGroup, false, includeChildValues);
      setSelectedLabels(labels);
      onChange(labels.length > 0 ? values : undefined);
    }
  };

  return {
    transientNodeGroup,
    selectedLabels,
    labelContainerRef,
    numHiddenItems,
    allNodes,
    handleSelect,
    handleRemoveSpace
  };
};

export default useSearchableDropdownSelect;