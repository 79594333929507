import DevicePage_Diagnostics from '@pages/site/buildings/floor/space/device/diagnostics/Diagnostics';
import DevicePage_HistoricData from '@pages/site/buildings/floor/space/device/historic-data/HistoricData';
import DevicePage_Notes from '@pages/site/buildings/floor/space/device/notes/Notes';
import FloorDashboard from '@pages/site/buildings/floor/dashboard/FloorDashboard';
import Floorplan from '@pages/site/buildings/floor/floor-plan/Floorplan';
import SpacePage from '@pages/site/buildings/floor/space/SpacePage';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IDeviceConfig } from '@contexts/DeviceConfigContext/IDeviceConfig/IDeviceConfig';
import { Route } from '@src/types/Route';
import BuildingProvider from '@pages/site/buildings/BuildingProvider';
import SpaceProvider from '@pages/site/buildings/floor/space/SpaceProvider';
import DevicePage from '@pages/site/buildings/floor/space/device/DevicePage';

export const BuildingRoutes: Route[] = [
  {
    path: 'building/:buildingId?/floor?/:floorId?/*',
    element: BuildingProvider,
    link: {
      label: 'Buildings',
      path: 'building',
      icon: regular('buildings'),
      analytics: { action: 'buildings', category: 'feature_navigation' }
    },
    children: [
      {
        path: '',
        element: FloorDashboard,
        analytics: { page: 'dashboard', category: 'floor' },
      },
      {
        path: 'floorplan',
        element: Floorplan,
        analytics: { page: 'floorplan', category: 'floor' },
      },
      {
        path: 'space/:spaceId/*',
        element: SpaceProvider,
        children: [
          {
            path: '',
            element: SpacePage,
            analytics: { page: 'devices', category: 'space' },
          },
          {
            path: 'device/:deviceId/*',
            element: DevicePage,
            children: [
              {
                path: '',
                element: DevicePage_HistoricData,
                analytics: { page: 'histroic_data', category: 'device' },
                link: {
                  label: 'HistoricData',
                  path: '',
                  end: true
                }
              },
              {
                path: 'notes',
                element: DevicePage_Notes,
                analytics: { page: 'notes', category: 'device' },
                link: {
                  label: 'Notes',
                  path: 'notes',
                }
              },
              {
                path: 'diagnostics',
                element: DevicePage_Diagnostics,
                analytics: { page: 'diagnostics', category: 'device' },
                link: {
                  label: 'Diagnostics',
                  path: 'diagnostics',
                  hideWhen: (p?: { deviceConfig?: IDeviceConfig }) => !p?.deviceConfig?.ui.diagnosticsPage
                },
                hideWhen: (p?: { deviceConfig?: IDeviceConfig }) => !p?.deviceConfig?.ui.diagnosticsPage
              }
            ]
          }
        ]
      }
    ]
  }
];