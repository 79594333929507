import EsgExplorerRuleDto from '@api/models/EsgExplorerRuleDto';
import EsgExplorerTemplateDto from '@api/models/EsgExplorerTemplateDto';
import EsgExplorerTemplateCreateCommand from '@api/queries/esg-explorer/EsgExplorerTemplateCreateCommand';
import EsgExplorerTemplateDeleteCommand from '@api/queries/esg-explorer/EsgExplorerTemplateDeleteCommand';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { WarningDialog } from '@components/core/WarningDialog';
import { useApi } from '@hooks/useApi';
import { useModal } from '@hooks/useModal';
import { usePopover } from '@hooks/usePopover';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

interface ITemplateListActions {
  siteId: number;
  template: EsgExplorerTemplateDto;
  refreshTemplates: () => void;
  refreshRules: () => void;
  rules: EsgExplorerRuleDto[];
}

const TemplateListActions = ({ siteId, template, refreshTemplates, refreshRules, rules }: ITemplateListActions) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});
  const { visible, toggle, setVisible, ref } = usePopover({});
  const templateRules = rules.filter(x => x.template.id === template.id);

  const cloneTemplate = async (siteId: number, template: EsgExplorerTemplateDto) => {
    toggle();

    await execute({
      query: new EsgExplorerTemplateCreateCommand(siteId, `Copy of ${template.name}`, template.configuration, true),
      successMessage: t('TemplateCreateSuccess', { ns: 'status' }),
      errorMessage: t('TemplateCreateError', { ns: 'status' })
    });

    refreshTemplates();
  };

  const deleteTemplate = async (template: EsgExplorerTemplateDto) => {
    if (!template.id) {
      return;
    }

    await execute({
      query: new EsgExplorerTemplateDeleteCommand(template.id),
      successMessage: t('TemplateDeleteSuccess', { ns: 'status' }),
      errorMessage: t('TemplateDeleteError', { ns: 'status' })
    });

    refreshTemplates();
    refreshRules();

    // Hide Tooltip and Modal
    visible && setVisible(false);
    deleteDialogIsOpen && toggleDeleteDialog();
  };

  const popoverContent = (
    <PopoverContent onClick={(e) => { e.stopPropagation(); }}>
      <Action
        onClick={() => cloneTemplate(siteId, template)}
      >
        <ActionIcon icon={regular('clone')} color={theme.palette.primary} />
        {t('Duplicate', { ns: 'common' })}
      </Action>
      <Action onClick={() => {
        toggleDeleteDialog();
        toggle();
      }}>
        <ActionIcon icon={solid('trash-xmark')} color={theme.palette.red} />
        {t('Delete', { ns: 'common' })}
      </Action>
    </PopoverContent>
  );

  const deleteDialogContent = (
    <>
      <>
        {t('ESG.DeleteTextPart1', { ns: 'molecules' })} {templateRules.length === 1 ? t('ESG.Rule', { ns: 'molecules' }) : t('ESG.Rules', { ns: 'molecules' })} {t('ESG.DeleteTextPart2', { ns: 'molecules' })}
      </>

      <ul>
        {templateRules.map((x) => (
          <li key={x.id}>
            {x.name}
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <>
      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('ESG.DeleteTemplateDialog', { ns: 'molecules' })}
        sectionTwo={templateRules.length > 0 && deleteDialogContent}
        confirmButton={t('Confirm', { ns: 'common' })}
        onCancel={e => {
          e.stopPropagation();
          toggleDeleteDialog();
        }}
        onConfirm={e => {
          e.stopPropagation();
          deleteTemplate(template);
        }}
      />

      <Popover
        ref={ref}
        visible={visible}
        popoverContent={popoverContent}
        placement={PopoverPlacement.BottomRight}
        alignWithTarget={true}
        hideTriangle={true}
        offsetY={-10}
        offsetX={5}
      >
        <MenuIcon
          data-cy={`ThreedotsIcon_${template.id}`}
          icon={solid('ellipsis-vertical')}
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
        />
      </Popover>
    </>
  );
};

export default TemplateListActions;

const MenuIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  padding: 5px;
  margin-top: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;

const PopoverContent = styled.div`
  width: 140px;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
`;

const Action = styled.div`
  padding: 5px 12px;

  &:hover {
    background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  }
`;

const ActionIcon = styled(FontAwesomeIcon) <{ color: string }>`
  font-size: 14px;
  color: ${p => p.color};
  margin-right: 8px;
  width: 15px;
`;