import { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Chart, CategoryScale, Filler, Tooltip, Legend } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import ChartOptionsFactory from '@utils/ChartOptionsFactory';
import { ChartDataPoint, ChartDataset } from '@components/charts/Chart.types';
import { TimeScaleRange, getCategoryChartColor } from '@utils/ChartUtils';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';

Chart.register(CategoryScale);

export interface ICategoryChartProps {
  /**
   * Array of labels
   */
  labels: string[];
  /**
   * Chart datasets
   */
  datasets: ChartDataset[];
  /**
   * Time range to zoom into
   */
  timeScaleRange?: TimeScaleRange;
  /**
   * Calls function with last zoom/pan min and max tick values
   */
  onZoomPanComplete?: (min: number, max: number) => void;
}

const createSteppedDataset = (dataPoints: ChartDataPoint[]) => {
  if (dataPoints.length === 0) {
    return [];
  }

  // Create a new dataset which can contain a NaN value on the y-axis 
  const dataset: ({ x: number, y: string | number })[] = [];

  // Add the first data point to the array and set the category to that category
  dataset.push({ x: dataPoints[0].x, y: dataPoints[0].y });
  let currentCatgeory = dataPoints[0].y;

  // Iterate through each data point and look for changes of the category
  for (const dataPoint of dataPoints) {
    if (dataPoint.y !== currentCatgeory) {
      // Push a data point on with the last category to finish the line where the line on the other categroy starts
      dataset.push({ x: dataPoint.x, y: dataset[dataset.length - 1].y, });
      // Push a NaN value when the category changes to stop line from connecting different categories
      dataset.push({ x: dataPoint.x, y: NaN });
      // Push current data point
      dataset.push({ x: dataPoint.x, y: dataPoint.y });
      // Change category to the category of the last pushed data point
      currentCatgeory = dataPoint.y;
    }
  }

  /**
   * Push another data point with the same category as the last data point and the current datetime,
   * to draw the line for that category/state to the end of the chart
   */
  dataset.push({ x: (new Date()).valueOf(), y: dataset[dataset.length - 1].y, });

  return dataset;
}

const CategoryChart = ({ labels, datasets, timeScaleRange, onZoomPanComplete }: ICategoryChartProps) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart<'bar' | 'line', { x: number; y: string | number; }[], string>>();
  const { localisation } = useLocalisation();

  const OptionsFactory = useMemo(() => {
    return new ChartOptionsFactory(theme, undefined, undefined, undefined, undefined, undefined, onZoomPanComplete, undefined, localisation);
  }, [theme, localisation, onZoomPanComplete]);

  const data = useMemo(() => ({
    labels: labels,
    datasets: datasets.map(x => {
      const steppedDataset = createSteppedDataset(x.dataset);

      return {
        label: x.label,
        displayLabel: x.displayLabel,
        data: steppedDataset,
        showLine: true,
        stepped: true,
        segment: {
          borderColor: (ctx: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            const categoryIndex = ctx.p0.parsed.y;
            const color = categoryIndex !== null ? getCategoryChartColor(categoryIndex) : 'transparent';

            if (x.categoryColors) {
              const categoryLabel = ctx.p0.raw.y;
              return x.categoryColors[categoryLabel] ?? color;
            }

            return color;
          }
        }
      }
    }),
  }), [labels, datasets]);

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const chart = new Chart(context, {
        type: 'line',
        data: data,
        plugins: [Filler, Tooltip, Legend, zoomPlugin],
        options: OptionsFactory.CategoryChartOptions()
      });

      setChart(chart);

      return () => chart.destroy();
    }
  }, [data, OptionsFactory]);

  useMemo(() => {
    if (chart && timeScaleRange) {
      (chart.options.scales as any).x.time.unit = timeScaleRange.xScaleTimeUnit; // eslint-disable-line @typescript-eslint/no-explicit-any
      chart.stop(); // make sure animations are not running
      chart.zoomScale('x', timeScaleRange.scaleRange, 'none');
    }
  }, [chart, timeScaleRange]);

  return (
    <div style={{ position: 'relative', height: '100%', width: '99%' }}>
      <canvas id="myChart" ref={canvasRef} />
    </div>
  );

};

export default CategoryChart;