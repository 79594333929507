import styled from 'styled-components';
import Floorplan from './Floorplan';
import { Floor } from '@api/models/Floor';
import { useFileFromBlobStorage } from '@hooks/useFileFromBlobStorage';
import { HeatmapConfig } from '@pages/site/heatmap/HeatmapPage';
import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import FloorplanLoading from './FloorplanLoading';
import FloorplanNoData from './FloorplanNoData';
import FloorplanUnavailable from './FloorplanUnavailable';

export type FloorplanData = {
  spaces: HeatmapSpaceDto[];
  config: HeatmapConfig;
};

type PropTypes = {
  floor?: Floor;
  data?: FloorplanData;
  loading: boolean;
}

const FloorplanContainer = ({ floor, data, loading }: PropTypes) => {
  const { blobUri } = useFileFromBlobStorage(floor?.floorplanId);

  if (loading) {
    return <FloorplanLoading />;
  }

  if (!data) {
    return <FloorplanNoData />;
  }

  if (!blobUri) {
    return <FloorplanUnavailable />;
  }

  return (
    <Wrapper>
      <Floorplan
        data={data}
        blobUri={blobUri}
      />
    </Wrapper>
  );
};

export default FloorplanContainer;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;