import { ReactComponent as ElectricitySvg } from '@assets/icons/esgReport/electricity.svg';
import { ReactComponent as WaterSvg } from '@assets/icons/esgReport/water.svg';
import { ReactComponent as GasSvg } from '@assets/icons/esgReport/gas.svg';
import { ReactComponent as TemperatureSvg } from '@assets/icons/esgReport/temperature.svg';
import { ReactComponent as HumiditySvg } from '@assets/icons/esgReport/humidity.svg';
import { ReactComponent as CO2Svg } from '@assets/icons/esgReport/co2.svg';
import { ReactComponent as NoiseSvg } from '@assets/icons/esgReport/noise.svg';
import { ReactComponent as CarbonIntensityElectricitySvg } from '@assets/icons/esgReport/co2-emissions.svg';
import { ReactComponent as LightSvg } from '@assets/icons/esgReport/light.svg';
import { ReactComponent as OccupancySvg } from '@assets/icons/esgReport/occupancy.svg';
import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';
import { ReportSpaceTypePerformanceMetricDto } from '../models/ReportSpaceTypePerformanceDto';

//#region Enum
enum ReportMetricType {
  /** Environmental */
  CO2 = 'CO2',
  Humidity = 'Humidity',
  Light = 'Light',
  Noise = 'Noise',
  Temperature = 'Temperature',

  /** Metering */
  ElectricityKwhDelta = 'ElectricityKwhDelta',
  WaterVolumeDelta = 'WaterVolumeDelta',
  HeatingKwhDelta = 'HeatingKwhDelta',
  Co2Emissions = 'Co2Emissions',

  /** Occupancy */
  Occupancy = 'Occupancy',

  /** Site/Building Metadata */
  ElectricityPricePerKwh = 'ElectricityPricePerKwh',
  HeatingPricePerKwh = 'HeatingPricePerKwh',
  WaterPricePerM3 = 'WaterPricePerM3',
  WasteWaterPricePerM3 = 'WasteWaterPricePerM3',
  GasPricePerM3 = 'GasPricePerM3',
  TargetCarbon = 'TargetCarbon',
  BaselineCarbon = 'BaselineCarbon',
  TotalFloors = 'TotalFloors',
  TotalSpaces = 'TotalSpaces',
  TotalAreaM3 = 'TotalAreaM3',
  ChildTotalFloors = 'ChildTotalFloors',
  ChildTotalSpaces = 'ChildTotalSpaces',
  ChildTotalAreaM3 = 'ChildTotalAreaM3',
  GasCalorificValue = 'GasCalorificValue',
  Gresb = 'Gresb',
  Epc = 'Epc',
  Breeam = 'Breeam',
  Fitwel = 'Fitwel',
  ElectricityCarbonIntensity = 'ElectricityCarbonIntensity',
  HeatingCarbonIntensity = 'HeatingCarbonIntensity',
  WaterCarbonIntensity = 'WaterCarbonIntensity',
  WasteWaterCarbonIntensity = 'WasteWaterCarbonIntensity',
  GasCarbonIntensity = 'GasCarbonIntensity',
  WasteWaterProportion = 'WasteWaterProportion'
}

export default ReportMetricType;
//#endregion

//#region Metric helper functions
export const getReportMetricScale = (metricType: ReportMetricType): number[] => {
  switch (metricType) {
    case ReportMetricType.Temperature:
      return [10, 16, 22, 28, 34];
    case ReportMetricType.Humidity:
      return [0, 25, 50, 75, 100];
    case ReportMetricType.CO2:
      return [400, 2300, 4200, 6100, 8000];
    case ReportMetricType.Light:
      return [0, 100, 200, 300, 400];
    case ReportMetricType.Noise:
      return [40, 60, 80, 100, 120];
    case ReportMetricType.HeatingKwhDelta:
      return [0, 500, 1000, 1500, 2000];
    case ReportMetricType.ElectricityKwhDelta:
      return [0, 500, 1000, 1500, 2000];
    case ReportMetricType.WaterVolumeDelta:
      return [0, 5, 10, 15];
    case ReportMetricType.Co2Emissions:
      return [0, 10, 20, 30];

    /** Default */
    default:
      return [];
  }
}

export const formatMetric = (metric: ReportSpaceTypePerformanceMetricDto): ReportSpaceTypePerformanceMetricDto => {
  switch (metric.metricType) {
    case ReportMetricType.Humidity: // From decimal value to a percentage
      return {
        ...metric,
        minimum: metric.minimum * 100,
        maximum: metric.maximum * 100,
        average: metric.average * 100
      };
    case ReportMetricType.Co2Emissions: // From grams to kg
      return {
        ...metric,
        minimum: metric.minimum / 1000,
        maximum: metric.maximum / 1000,
        average: metric.average / 1000
      };
    default:
      return metric;
  }
}
//#endregion

//#region Mapping accessor functions
namespace ReportMetricType { // eslint-disable-line
  /**
   * Get suffix
  */
  export const getSuffix = (reportMetricType: ReportMetricType): string => {
    return reportMetricTypeToSuffix[reportMetricType] ?? '';
  }

  /**
   * Get icon
  */
  export const getIcon = (reportMetricType: ReportMetricType): JSX.Element | null => {
    return reportMetricTypeToIcon[reportMetricType] ?? null;
  }

  /**
   * Get color
   */
  export const getColor = (reportMetricType: ReportMetricType): string => {
    return reportMetricTypeToColor[reportMetricType] ?? '#000';
  }

  /**
   * Get MetricType from ReportMetricType
   */
  export const getMetricType = (reportMetricType: ReportMetricType): MetricType | AltUnits => {
    return reportMetricTypeToMetricType[reportMetricType];
  }
}
//#endregion

//#region Mappings
/**
 * Map ReportMetricType to a suffix
 */
const reportMetricTypeToSuffix: { [key in ReportMetricType]: string } = Object.freeze({
  /** Environmental */
  [ReportMetricType.CO2]: 'ppm',
  [ReportMetricType.Humidity]: '%',
  [ReportMetricType.Light]: 'lux',
  [ReportMetricType.Noise]: 'db',
  [ReportMetricType.Temperature]: '°C',
  [ReportMetricType.Occupancy]: '',

  /** Metering */
  [ReportMetricType.ElectricityKwhDelta]: 'kWh',
  [ReportMetricType.WaterVolumeDelta]: 'm³',
  [ReportMetricType.HeatingKwhDelta]: 'kWh',
  [ReportMetricType.Co2Emissions]: 'kg',

  /** Site/Building Metadata */
  [ReportMetricType.ElectricityPricePerKwh]: '',
  [ReportMetricType.HeatingPricePerKwh]: '',
  [ReportMetricType.WaterPricePerM3]: '',
  [ReportMetricType.GasPricePerM3]: '',
  [ReportMetricType.WasteWaterPricePerM3]: '',
  [ReportMetricType.TargetCarbon]: '',
  [ReportMetricType.BaselineCarbon]: '',
  [ReportMetricType.TotalFloors]: '',
  [ReportMetricType.TotalSpaces]: '',
  [ReportMetricType.TotalAreaM3]: '',
  [ReportMetricType.ChildTotalFloors]: '',
  [ReportMetricType.ChildTotalSpaces]: '',
  [ReportMetricType.ChildTotalAreaM3]: '',
  [ReportMetricType.GasCalorificValue]: '',
  [ReportMetricType.Gresb]: '',
  [ReportMetricType.Epc]: '',
  [ReportMetricType.Breeam]: '',
  [ReportMetricType.Fitwel]: '',
  [ReportMetricType.ElectricityCarbonIntensity]: '',
  [ReportMetricType.HeatingCarbonIntensity]: '',
  [ReportMetricType.WaterCarbonIntensity]: '',
  [ReportMetricType.WasteWaterCarbonIntensity]: '',
  [ReportMetricType.GasCarbonIntensity]: '',
  [ReportMetricType.WasteWaterProportion]: ''
});

/**
 * Map ReportMetricType to a Metrictype 
 */
const reportMetricTypeToMetricType: { [key in ReportMetricType]: MetricType | AltUnits } = Object.freeze({
  /** Environmental */
  [ReportMetricType.CO2]: MetricType.CO2,
  [ReportMetricType.Humidity]: MetricType.Humidity,
  [ReportMetricType.Light]: MetricType.Light,
  [ReportMetricType.Noise]: MetricType.Noise,
  [ReportMetricType.Temperature]: MetricType.Temperature,
  [ReportMetricType.Occupancy]: MetricType.PIR,

  /** Metering */
  [ReportMetricType.ElectricityKwhDelta]: MetricType.ElectricityKwhDelta,
  [ReportMetricType.WaterVolumeDelta]: MetricType.WaterVolumeDelta,
  [ReportMetricType.HeatingKwhDelta]: MetricType.HeatingKwhDelta,
  [ReportMetricType.Co2Emissions]: AltUnits.Weight,

  /** Site/Building Metadata */
  [ReportMetricType.ElectricityPricePerKwh]: AltUnits.Default,
  [ReportMetricType.HeatingPricePerKwh]: AltUnits.Default,
  [ReportMetricType.WaterPricePerM3]: AltUnits.Default,
  [ReportMetricType.WasteWaterPricePerM3]: AltUnits.Default,
  [ReportMetricType.GasPricePerM3]: AltUnits.Default,
  [ReportMetricType.TargetCarbon]: AltUnits.Default,
  [ReportMetricType.BaselineCarbon]: AltUnits.Default,
  [ReportMetricType.TotalFloors]: AltUnits.Default,
  [ReportMetricType.TotalSpaces]: AltUnits.Default,
  [ReportMetricType.TotalAreaM3]: AltUnits.Default,
  [ReportMetricType.ChildTotalFloors]: AltUnits.Default,
  [ReportMetricType.ChildTotalSpaces]: AltUnits.Default,
  [ReportMetricType.ChildTotalAreaM3]: AltUnits.Default,
  [ReportMetricType.GasCalorificValue]: AltUnits.Default,
  [ReportMetricType.Gresb]: AltUnits.Default,
  [ReportMetricType.Epc]: AltUnits.Default,
  [ReportMetricType.Breeam]: AltUnits.Default,
  [ReportMetricType.Fitwel]: AltUnits.Default,
  [ReportMetricType.ElectricityCarbonIntensity]: AltUnits.CarbonIntensityForecast,
  [ReportMetricType.HeatingCarbonIntensity]: AltUnits.CarbonIntensityForecast,
  [ReportMetricType.WaterCarbonIntensity]: AltUnits.CarbonIntensityVolume,
  [ReportMetricType.WasteWaterCarbonIntensity]: AltUnits.CarbonIntensityVolume,
  [ReportMetricType.GasCarbonIntensity]: AltUnits.CarbonIntensityVolume,
  [ReportMetricType.WasteWaterProportion]: AltUnits.Default
})

/**
 * Map ReportMetricType to an icon
 */
const reportMetricTypeToIcon: { [key in ReportMetricType]: JSX.Element | null } = Object.freeze({
  /** Environmental */
  [ReportMetricType.CO2]: <CO2Svg />,
  [ReportMetricType.Humidity]: <HumiditySvg />,
  [ReportMetricType.Light]: <LightSvg />,
  [ReportMetricType.Noise]: <NoiseSvg />,
  [ReportMetricType.Temperature]: <TemperatureSvg />,
  [ReportMetricType.Occupancy]: <OccupancySvg />,

  /** Metering */
  [ReportMetricType.ElectricityKwhDelta]: <ElectricitySvg />,
  [ReportMetricType.WaterVolumeDelta]: <WaterSvg />,
  [ReportMetricType.HeatingKwhDelta]: <GasSvg />,
  [ReportMetricType.Co2Emissions]: <CarbonIntensityElectricitySvg />,

  /** Site/Building Metadata */
  [ReportMetricType.ElectricityPricePerKwh]: null,
  [ReportMetricType.HeatingPricePerKwh]: null,
  [ReportMetricType.WaterPricePerM3]: null,
  [ReportMetricType.WasteWaterPricePerM3]: null,
  [ReportMetricType.GasPricePerM3]: null,
  [ReportMetricType.TargetCarbon]: null,
  [ReportMetricType.BaselineCarbon]: null,
  [ReportMetricType.TotalFloors]: null,
  [ReportMetricType.TotalSpaces]: null,
  [ReportMetricType.TotalAreaM3]: null,
  [ReportMetricType.ChildTotalFloors]: null,
  [ReportMetricType.ChildTotalSpaces]: null,
  [ReportMetricType.ChildTotalAreaM3]: null,
  [ReportMetricType.GasCalorificValue]: null,
  [ReportMetricType.Gresb]: null,
  [ReportMetricType.Epc]: null,
  [ReportMetricType.Breeam]: null,
  [ReportMetricType.Fitwel]: null,
  [ReportMetricType.ElectricityCarbonIntensity]: null,
  [ReportMetricType.HeatingCarbonIntensity]: null,
  [ReportMetricType.WaterCarbonIntensity]: null,
  [ReportMetricType.WasteWaterCarbonIntensity]: null,
  [ReportMetricType.GasCarbonIntensity]: null,
  [ReportMetricType.WasteWaterProportion]: null
});

/**
 * Map ReportMetricType to a color
 */
const reportMetricTypeToColor: { [key in ReportMetricType]: string } = Object.freeze({
  /** Environmental */
  [ReportMetricType.CO2]: '#c0cdd3',
  [ReportMetricType.Humidity]: '#27c8c3',
  [ReportMetricType.Light]: '#f3d91f',
  [ReportMetricType.Noise]: '#c874b4',
  [ReportMetricType.Temperature]: '#f4515e',
  [ReportMetricType.Occupancy]: '#78c980',

  /** Metering */
  [ReportMetricType.ElectricityKwhDelta]: '#FFB500',
  [ReportMetricType.WaterVolumeDelta]: '#1B98FF',
  [ReportMetricType.HeatingKwhDelta]: '#FF821B',
  [ReportMetricType.Co2Emissions]: '#f67045',

  /** Building Metadata */
  [ReportMetricType.ElectricityPricePerKwh]: '#000',
  [ReportMetricType.HeatingPricePerKwh]: '#000',
  [ReportMetricType.WaterPricePerM3]: '#000',
  [ReportMetricType.WasteWaterPricePerM3]: '#000',
  [ReportMetricType.GasPricePerM3]: '#000',
  [ReportMetricType.TargetCarbon]: '#000',
  [ReportMetricType.BaselineCarbon]: '#000',
  [ReportMetricType.TotalFloors]: '#000',
  [ReportMetricType.TotalSpaces]: '#000',
  [ReportMetricType.TotalAreaM3]: '#000',
  [ReportMetricType.ChildTotalFloors]: '#000',
  [ReportMetricType.ChildTotalSpaces]: '#000',
  [ReportMetricType.ChildTotalAreaM3]: '#000',
  [ReportMetricType.GasCalorificValue]: '#000',
  [ReportMetricType.Gresb]: '#000',
  [ReportMetricType.Epc]: '#000',
  [ReportMetricType.Breeam]: '#000',
  [ReportMetricType.Fitwel]: '#000',
  [ReportMetricType.ElectricityCarbonIntensity]: '#000',
  [ReportMetricType.HeatingCarbonIntensity]: '#000',
  [ReportMetricType.WaterCarbonIntensity]: '#000',
  [ReportMetricType.WasteWaterCarbonIntensity]: '#000',
  [ReportMetricType.GasCarbonIntensity]: '#000',
  [ReportMetricType.WasteWaterProportion]: '#000'
});

//#endregion