import { AlertRule } from '@api/models/AlertRule';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class AlertRuleCreateCommand extends PostQuery<AlertRule> {
  siteId: number;
  rule: AlertRule;

  constructor(siteId: number, rule: AlertRule) {
    super();
    this.siteId = siteId;
    this.rule = rule;
  }

  public targetUrl(): string {
    return '/alerting/CreateRule';
  }
}
