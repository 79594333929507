import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Marker from './Marker.types';
import { SpaceType_Color } from '@api/enums/SpaceType_Color';
import { SpaceType_Icon } from '@api/enums/SpaceType_Icon';
import { Floor } from '@api/models/Floor';
import { Icon } from '@components/core/Icon';
import { TopBarHeight, HorizontalNavbarHeight } from '@src/constants/designConstants';
import { Card } from '@components/core/Card';
import { Title } from '@components/core/Title';

type PropTypes = {
  floor: Floor,
  markers: Marker[]
}

const MarkerListSidebar = ({ floor, markers }: PropTypes) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, i: number) => {
    e.dataTransfer.setData('markerIndex', i.toString());
    e.currentTarget.style.cursor = 'grabbing';
  };

  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.cursor = 'grab';
  };

  return (
    <Card
      cardTitle={floor.name}
      noMargin
      noPadding
      style={{
        width: '300px',
        marginTop: '52px',
        height: 'max-content',
        flexShrink: 0
      }}
    >
      <List>
        <Title
          text={t('Buildings.Floorplan.MarkerListSidebar.Spaces', { ns: 'settingsAsset' })}
          subtitle={{
            text: t('Buildings.Floorplan.MarkerListSidebar.DragAndDrop', { ns: 'settingsAsset' }),
            style: { paddingTop: '5px' }
          }}
          style={{ color: theme.palette.text.weak, fontSize: '16px' }}
          wrapperStyle={{ paddingBottom: '10px' }}
        />
        {markers.map((marker, i) => (
          <ListItem key={i} hasNoCoordinates={!marker.xCoordinate && !marker.yCoordinate}>
            <IconContainer
              draggable
              onDragStart={(e) => onDragStart(e, i)}
              onDragEnd={(e) => onDragEnd(e)}
              title={t('Buildings.Floorplan.MarkerListSidebar.DragOnto', { ns: 'settingsAsset' })}
            >
              <Icon
                icon={SpaceType_Icon(marker.spaceType)}
                iconSize='20px'
                squareSize='32px'
                squareColor={SpaceType_Color(marker.spaceType)}
                iconColor='#fff'
                shadowBottom
              />
            </IconContainer>
            <Name>
              {marker.name}
            </Name>
          </ListItem>
        )
        )}
      </List>
    </Card>
  );
};

export default MarkerListSidebar;

const List = styled.div`
  white-space: normal;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  height: max-content;
  min-height: 275px;
  max-height: ${`calc(100vh - ${TopBarHeight}px - ${HorizontalNavbarHeight}px - 200px)`};
  overflow: auto;
`;

const ListItem = styled.div<{ hasNoCoordinates: boolean }>`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 10px;

  padding: 5px 7px;
  border-radius: 3px;
  background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  border: 2px dashed transparent;

  ${p => p.hasNoCoordinates && css`
    background-color: transparent;
    border-color:  ${p => p.theme.palette.borders.medium};
  `}
`;

const Name = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-weight: 400;
  font-size: 16px;
`;

const IconContainer = styled.div`
  cursor: grab;
`;