import styled from 'styled-components';
import FloorIdentifierSquare from './components/FloorIdentifierSquare';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import HeatmapSquareWrapper from './components/heatmap-square/HeatmapSquareWrapper';
import HeatmapNoData from './components/HeatmapNoData';
import HeatmapLoading from './components/HeatmapLoading';
import { HeatmapData } from './HeatmapPage';
import { groupBy, orderBy } from 'lodash';

type PropTypes = {
  data?: HeatmapData;
  loading: boolean;
  loadingRefetch?: boolean;
}

const Heatmap = ({ data, loading, loadingRefetch }: PropTypes) => {
  const { t } = useTranslation();

  if (loading) {
    return <HeatmapLoading />;
  }

  if (!data) {
    return <HeatmapNoData />;
  }

  // Group spaces by floor and sort floors by FloorNumber in descending order, and spaces by name
  const floorGroups = groupBy(data.spaces, x => x.floorId);
  let floors = Object.entries(floorGroups).map(([, spaces]) => orderBy(spaces, x => x.spaceName));
  floors = orderBy(floors, x => x[0].floorNumber, ['desc']);

  // Count of spaces on the floor with the most spaces
  const maxNumSpaces = Math.max(...floors.map(spaces => spaces.length));

  return (
    <Wrapper>
      <FlexRow>
        <FloorsColumn>
          <Label>
            {t('Floors', { ns: 'assets' })}
          </Label>

          {floors.map((spaces, i) => (
            <FloorIdentifierSquare
              key={uuidv4()}
              space={spaces[0]}
              isFirstOne={i === 0}
              buildingId={data.config.building?.id}
            />
          ))}
        </FloorsColumn>

        <SpacesColumn>
          <Label>
            {t('Spaces', { ns: 'assets' })}
          </Label>

          <SpaceNumbersWrapper>
            <SpaceNumbers>
              {[...Array(maxNumSpaces)].map((_, i) =>
                <SpaceNumber key={uuidv4()}>
                  {i + 1}
                </SpaceNumber>
              )}
            </SpaceNumbers>
          </SpaceNumbersWrapper>

          {floors.map(spaces => (
            <SpacesRow key={uuidv4()}>
              {spaces.map((space) => (
                <HeatmapSquareWrapper
                  key={`${space.floorId}-${space.spaceId}`}
                  space={space}
                  config={data.config}
                  linkPathToSpace={`./../building/${data.config.building?.id}/floor/${space.floorId}/space/${space.spaceId}`}
                  loadingRefetch={loadingRefetch}
                />
              ))}
            </SpacesRow>
          ))}
        </SpacesColumn>
      </FlexRow>
    </Wrapper>
  );
};

export default Heatmap;

const Wrapper = styled.div`
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
`;

const FloorsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  width: 80px;
  align-items: center;
  justify-content: start;
  text-align: center;
  border-right: 1px solid ${p => p.theme.palette.borders.weak};
  padding-bottom: 20px;
`;

const SpacesColumn = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  color: ${p => p.theme.palette.text.weak};
`;

const SpacesRow = styled(FlexRow)`
  padding: 6px 20px 0;
  height: 42px;
  gap: 6px;
`;

const Label = styled.div`
  width: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 10px;

  color: ${p => p.theme.palette.text.weak};
  font-size: 16px;
`;

const SpaceNumbersWrapper = styled.div`
  display: flex;
  padding-bottom: 8px;
`;

const SpaceNumbers = styled.div`
  width: 100%;
  padding-left: 20px;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};

  display: flex;
  gap: 6px;
`;

const SpaceNumber = styled.div`
  width: 36px;
  height: 36px;

  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};

  display: flex;
  justify-content: center;
  align-items: center;
`;