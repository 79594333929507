import styled from 'styled-components';
import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import { HeatmapConfig } from '@pages/site/heatmap/HeatmapPage';
import HeatmapSquareWrapper from '@pages/site/heatmap/components/heatmap-square/HeatmapSquareWrapper';

type PropTypes = {
  space: HeatmapSpaceDto;
  widthFactor: number;
  heightFactor: number;
  config: HeatmapConfig;
}

const DEFAULT_SQUARE_SIZE = 42;
const MIN_SQUARE_SIZE = 15;
const MAX_SQUARE_SIZE = 40;

const Marker = ({ space, widthFactor, heightFactor, config }: PropTypes) => {
  let squareSize = Math.max(MIN_SQUARE_SIZE, DEFAULT_SQUARE_SIZE * widthFactor);
  squareSize = Math.min(MAX_SQUARE_SIZE, squareSize);

  if (!space.spaceXCoordinate || !space.spaceYCoordinate) {
    return null;
  }

  return (
    <MarkerContainer
      x={space.spaceXCoordinate}
      y={space.spaceYCoordinate}
      width={squareSize}
      widthFactor={widthFactor}
      heightFactor={heightFactor}
    >
      <HeatmapSquareWrapper
        key={space.spaceId}
        space={space}
        config={config}
        onFloorplan={true}
        size={squareSize}
        linkPathToSpace={`../space/${space.spaceId}`}
      />
    </MarkerContainer>
  );
};

export default Marker;

const MarkerContainer = styled.div.attrs<{ x: number, y: number, width: number, widthFactor: number, heightFactor: number }>(p => ({
  style: {
    top: `${p.y * p.widthFactor - (p.width / 2)}px`,
    left: `${p.x * p.heightFactor - (p.width / 2)}px`
  }
})) <{ x: number, y: number, width: number, widthFactor: number, heightFactor: number }>`
  position: absolute;  
  border-radius: 3px;

  &:hover + .tooltip {
    opacity: 1;
  }
`;