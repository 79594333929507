import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ResetPassword from './components/ResetPassword';
import UserEditingSites from './components/UserEditSites';
import { useTranslation } from 'react-i18next';
import { useApiState } from '@hooks/useApiState';
import UserGetByIdQuery from '@api/queries/users/UserGetByIdQuery';
import { useApi } from '@hooks/useApi';
import { valuesDiffer } from '@utils/ObjectUtils';
import { useFileHandler } from '@hooks/useFileHandler';
import { User } from '@api/models/User';
import UserUpdateCommand from '@api/queries/users/UserUpdateCommand';
import UserProfileImageUploadCommand from '@api/queries/users/UserProfileImageUploadCommand';
import UserProfileImageDeleteCommand from '@api/queries/users/UserProfileImageDeleteCommand';
import { BlobFileUploadArea } from '@components/blob-file-upload-area/BlobFileUploadArea';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';
import { ErrorMessage, Form, Input, Label } from '@components/Form';
import { nullIfEmptyString } from '@utils/StringUtils';
import { Button } from '@components/core/Button';
import { Card } from '@components/core/Card';
import { BackButton } from '@components/core/BackButton';
import UserMfaSettings from './components/UserMfaSettings';
import LoadingWidget from '@components/core/LoadingWidget';

type FormValues = {
  fullName: string,
  displayName?: string,
  phoneNumber: string
}

const UserEdit = () => {
  const { t } = useTranslation(['settingsUser']);
  const { userId } = useParams<{ userId: string }>();
  const { data: user, setData: setUser, execute: refreshUser, loading } = useApiState({
    query: userId === undefined ? undefined : new UserGetByIdQuery(parseInt(userId))
  }, [userId]);
  const { execute } = useApi();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValues>();
  const { file, fileName, fileHasChanged, fileToBeDeleted, handleFileChange, handleFileDelete } = useFileHandler(user?.profileImageId);
  const formValues = watch();

  useEffect(() => {
    if (user) {
      const hasBeenModified = valuesDiffer<FormValues, User>(formValues, user);
      setHasUnsavedChanges(hasBeenModified || fileHasChanged || fileToBeDeleted);
    }
  }, [user, formValues, fileHasChanged, fileToBeDeleted]);

  if (!user && loading) {
    return (
      <LoadingWidget
        label={t('LoadingUser', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    );
  }

  if (!user) {
    return null;
  }

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const modifiedUser = cloneDeep(user);
    modifiedUser.fullName = data.fullName;
    modifiedUser.phoneNumber = data.phoneNumber;
    modifiedUser.displayName = data.displayName;

    const updateResult = await updateUser(modifiedUser);
    const updateImageResult = await updateProfileImage(modifiedUser);

    if (!updateResult || !updateImageResult) {
      return;
    }

    setSavingInProgress(false);
    setUser(modifiedUser);
  }

  const updateUser = async (modifiedUser: User) => {
    if (valuesDiffer(modifiedUser, user)) {
      return await execute({
        query: new UserUpdateCommand(user.id, modifiedUser.fullName, modifiedUser.phoneNumber, user.mfaEnabled, user.mfaMethod, modifiedUser.displayName),
        successMessage: t('Editing.UserUpdated', { ns: 'settingsUser' }),
        errorMessage: t('Editing.UserUpdateFailed', { ns: 'settingsUser' })
      });
    }
  };

  const updateProfileImage = async (user: User) => {
    let isMounted = true;

    if (fileHasChanged && file) {
      const result = await execute({
        query: new UserProfileImageUploadCommand(file, user.id),
        successMessage: t('Editing.ProfileImageUploaded', { ns: 'settingsUser' }),
        errorMessage: t('Editing.ProfileImageUploadFailed', { ns: 'settingsUser' })
      });
      user.profileImageId = result?.blobName;
      isMounted = result !== undefined;
    } else if (fileToBeDeleted && user.profileImageId) {
      const result = await execute({
        query: new UserProfileImageDeleteCommand(user.profileImageId, user.id),
        successMessage: t('Editing.ProfileImageDeleted', { ns: 'settingsUser' }),
        errorMessage: t('Editing.ProfileImageDeleteFailed', { ns: 'settingsUser' })
      });
      user.profileImageId = undefined;
      isMounted = result !== undefined;
    }

    return isMounted;
  };

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('BackToUsers', { ns: 'navigation' })}
          url='./..'
        />

        <StyledCardTop noPadding>
          <ProfileImageContainer>
            <BlobFileUploadArea
              blobName={fileName}
              blobContainer={BlobStorageContainerType.Shared}
              mainText={t('Editing.ProfileImage', { ns: 'settingsUser' })}
              acceptedTypes={['image/*']}
              onFileChange={handleFileChange}
              onFileDelete={handleFileDelete}
              isCircle
            />
          </ProfileImageContainer>
        </StyledCardTop>

        <StyledCardBottom>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Editing.FullName', { ns: 'settingsUser' })}</Label>
                  <Input {...register('fullName', { required: t('Editing.RequiredField', { ns: 'settingsUser' }) })} defaultValue={user.fullName} />
                  <ErrorMessage>{errors.fullName?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Editing.DisplayName', { ns: 'settingsUser' })}</Label>
                  <Input {...register('displayName', { required: t('Editing.RequiredField', { ns: 'settingsUser' }) })} defaultValue={user.displayName} />
                  <ErrorMessage>{errors.displayName?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Editing.Email', { ns: 'settingsUser' })}</Label>
                  <Input
                    type="email"
                    defaultValue={user.email}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <Label>{t('Editing.PhoneNumber', { ns: 'settingsUser' })}</Label>
                  <Input
                    {...register('phoneNumber', {
                      required: t('Editing.RequiredField', { ns: 'settingsUser' }),
                      setValueAs: value => nullIfEmptyString(value)
                    })}
                    defaultValue={user.phoneNumber}
                  />
                  <ErrorMessage>{errors.phoneNumber?.message}</ErrorMessage>
                </div>
              </div>
            </div>
          </Form>
          <div className="row" style={{ marginTop: '32px' }}>
            <div className="col">
              <Button
                label={t('Editing.Save', { ns: 'settingsUser' })}
                onClick={handleSubmit(onSave)}
                disabled={!hasUnsavedChanges || !isEmpty(errors)}
                loading={savingInProgress}
              />
            </div>
            {userId !== undefined &&
              <div className="col-auto ml-auto">
                <ResetPassword
                  userId={parseInt(userId)}
                />
              </div>
            }
          </div>
        </StyledCardBottom>

        <UserEditingSites user={user} refreshUser={refreshUser} />
        <UserMfaSettings user={user} refreshUser={refreshUser} />
      </Container>
    </PaddedContainer>
  );
};

export default UserEdit;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;

const StyledCardTop = styled(Card)`
  border-top: 4px solid #00AEED !important;
  border-radius: 3px 3px 0 0 !important;
  border-bottom: none;
  margin-bottom: 0 !important;
`;

const StyledCardBottom = styled(Card)`
  border-radius: 0 0 3px 3px !important;
  margin-top: 0px;
  padding-top: 55px;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;

  height: 110px;
  background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  padding-top: 55px;
`;