import { User } from '@api/models/User';
import { DeleteBlobQuery } from '@api/queries/common/DeleteBlobQuery';

export default class UserProfileImageDeleteCommand extends DeleteBlobQuery<User> {
  userId: number;

  constructor(blobName: string, userId: number) {
    super(blobName);
    this.userId = userId;
  }

  public targetUrl(): string {
    return '/users/profileImage';
  }
}
