import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import TenantUserSelectWrapper from './components/TenantUserSelectWrapper';
import { useTranslation } from 'react-i18next';
import { useApi } from '@hooks/useApi';
import { BlobGetSasUriQuery } from '@api/queries/blob/BlobGetSasUriQuery';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';
import UsersGetAllAcrossTenantsQuery from '@api/queries/users/UsersGetAllAcrossTenantsQuery';
import RolesGetAllAcrossTenantsQuery from '@api/queries/roles/RolesGetAllAcrossTenantaQuery';
import { useApiState } from '@hooks/useApiState';
import TenantsGetAllQuery from '@api/queries/tenants/TenantsGetAllQuery';
import { includesCI } from '@utils/StringUtils';
import { UserWithTenants } from '@api/models/UserWithTenants';
import { ITableColumn } from '@components/table/Table.types';
import UserListProfileImage from '../../user-management/users/components/UserListProfileImage';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { SearchField } from '@components/search-field/SearchField';
import { Table } from '@components/table/Table';
import { TopBarHeight } from '@src/constants/designConstants';
import IconTooltip from '@components/core/IconTooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export type TenantWithLogoUri = {
  tenantId: string,
  name: string,
  email: string,
  operatorLogoId?: string,
  logoUri?: string
};

const TenantUserList = () => {
  const { t } = useTranslation(['settingsTenantUser']);
  const { execute } = useApi();
  const [searchString, setSearchString] = useState('');
  const [tenantsWithLogoUri, setTenantsWithLogoUri] = useState<TenantWithLogoUri[]>([]);

  const getBlobUri = useCallback(async (blobName?: string) => {
    if (!blobName) {
      return;
    }

    const res = await execute({
      query: new BlobGetSasUriQuery(blobName, BlobStorageContainerType.Shared)
    });

    return res?.blobUri;
  }, [execute]);

  const { data: users, execute: refreshUsers, loading: loadingUsers } = useApiState({
    query: new UsersGetAllAcrossTenantsQuery(),
    transformFunc: x => x,
    initialState: [],
    initialTransformState: []
  }, []);

  const { data: tenants, loading: loadingTenants } = useApiState({
    query: new TenantsGetAllQuery(),
    initialState: []
  }, []);

  const { data: allRoles, loading: loadingRoles } = useApiState({
    query: new RolesGetAllAcrossTenantsQuery(),
    initialState: []
  }, []);

  useEffect(() => {
    const createTenantsWithLogoUris = async () => {
      const tenantsWithUris = await Promise.all(tenants.map(async (x) => ({
        ...x,
        logoUri: await getBlobUri(x.operatorLogoId)
      })));

      setTenantsWithLogoUri(tenantsWithUris);
    };

    createTenantsWithLogoUris();
  }, [tenants, getBlobUri]);

  const searchFilter = useCallback((user: UserWithTenants) => {
    const roleName = allRoles.find(x => x.id === user.roleId)?.name;

    return includesCI(user.fullName, searchString)
      || includesCI(user.email, searchString)
      || includesCI(user.tenantName, searchString)
      || (roleName ? includesCI(roleName, searchString) : false);
  }, [allRoles, searchString]);

  const tableColumns: ITableColumn<UserWithTenants>[] = [
    {
      width: 4,
      label: t('List.User', { ns: 'settingsTenantUser' }),
      key: 'fullName',
      customElement: (user) => (
        <>
          <UserListProfileImage blobName={user.profileImageId} />
          <Name>
            {user.fullName}
            <Email>{user.email}</Email>
          </Name>
        </>
      )
    },
    {
      label: t('List.Role', { ns: 'settingsTenantUser' }),
      key: 'roleId',
      width: 2,
      customElement: (user) => <Role>{allRoles.find(x => x.id === user.roleId)?.displayName}</Role>
    },
    {
      width: 2,
      label: t('List.DefaultTenant', { ns: 'settingsTenantUser' }),
      key: 'tenantName',
    },
    {
      label: t('List.TenantAccess', { ns: 'settingsTenantUser' }),
      key: 'tenantSelect',
      disableSort: true,
      width: 3,
      customElement: (user) =>
        <TenantUserSelectWrapper
          user={user}
          tenants={tenantsWithLogoUri}
          refreshUsers={refreshUsers}
        />
    }
  ];

  return (
    <PaddedContainer>
      <FlexContainer>
        <Heading>
          {t('List.Users', { ns: 'settingsTenantUser' })} <ThemeColored>({users.length})</ThemeColored>
        </Heading>
        <RightAligned>
          <IconTooltip
            icon={regular('circle-info')}
            tooltip={{
              content: t('List.InfoMessage', { ns: 'settingsTenantUser' }),
              placement: TooltipPlacement.Left
            }}
            tooltipStyles={{ width: '280px' }}
            wrapperStyles={{ margin: '5px 15px 0 0' }}
          />
          <SearchField
            placeholder={t('List.SearchUsers', { ns: 'settingsTenantUser' })}
            onSearchChange={setSearchString}
          />
        </RightAligned>
      </FlexContainer>

      <Table
        columns={tableColumns}
        records={users}
        recordKey='id'
        defaultSortColumn="fullName"
        cardEffect
        fullHeightSubtractor={TopBarHeight + 250}
        loading={loadingUsers || loadingTenants || loadingRoles}
        highlightString={searchString}
        filters={[searchFilter]}
      />
    </PaddedContainer>
  );
}

export default TenantUserList;

const FlexContainer = styled.div` 
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

const RightAligned = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
`;

const Name = styled.div`
  font-size: 14px;
`;

const Role = styled.div`
  font-size: 14px;
`;

const Email = styled.div`
  font-size: 14px;
  color: ${p => p.theme.palette.text.fair};
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;