import { SpaceType } from '@api/enums/SpaceType';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class ResidentialSpaceTypesGetBySiteIdQuery extends GetQuery<SpaceType[]> {
  siteId: number;
  
  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/spaces/ResidentialSpaceTypesBySite/${this.siteId}`;
  }
}