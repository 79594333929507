import styled from 'styled-components';
import backdrop from '@assets/images/london-backdrop.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHexagonExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { IErrorPage } from './ErrorPage.types';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@contexts/UserContext/UserContext';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { MetadataVersionGetQuery } from '@api/queries/metadata/MetadataVersionGetQuery';
import { ReportUIBugCommand } from '@api/queries/feedback/ReportUIBugCommand';
import { env } from '@src/env/env';
import { Button } from '@components/core/Button';

export const ErrorPage = ({ error }: IErrorPage) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { execute } = useApi();
  const [reported, setReported] = useState(false);
  const { data: apiVersion } = useApiState(
    {
      query: new MetadataVersionGetQuery()
    },
    []
  );

  const sendReport = () => {
    setReported(true);

    execute({
      query: new ReportUIBugCommand(
        window.location.toString(),
        user?.email,
        error.message,
        error.stack,
        env.version,
        apiVersion
      ),
      errorMessage: t('BugReportSendError', { ns: 'commonApp' })
    });
  };

  return (
    <StyledContainer>
      <Wrapper>
        <Icon icon={faHexagonExclamation} />
        <Card>
          <Title>{t('SomethingWentWrong', { ns: 'commonApp' })}</Title>

          <p style={{ marginBottom: '30px' }}>
            {t('ErrorPageMessage', { ns: 'commonApp' })}
          </p>

          {!reported && (
            <Button
              label={t('SendReport', { ns: 'commonApp' })}
              onClick={sendReport}
              disabled={reported}
            />
          )}
          {reported && <p>{t('BugReportReceivedMessage', { ns: 'commonApp' })}</p>}
        </Card>
      </Wrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backdrop});
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Card = styled.div`
  position: relative;
  max-width: 500px;
  overflow: auto;
  background: ${(p) => p.theme.palette.backgrounds.surface};
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgb(34 37 51 / 7%);
  padding: 40px 30px;
  margin: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.primary};
  margin-bottom: 20px;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 1px;
  right: 60px;
  color: ${(p) => p.theme.palette.primary};
  font-size: 40px;
  z-index: 1;
`;
