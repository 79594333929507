import { SiteFairUsePolicy } from '@api/models/SiteFairUsePolicy';
import { SpaceTypeFairUsePolicy } from '@api/models/SpaceTypeFairUsePolicy';
import { SiteFairUseSeasonalityAdjustment } from '@api/models/SiteFairUseSeasonalityAdjustment';
import { PatchQuery } from '../../common/PatchQuery';

export class SiteFairUsePolicyUpdateCommand extends PatchQuery<SiteFairUsePolicy> {
  state: string;
  spaceTypeFairUsePolicies: SpaceTypeFairUsePolicy[];
  seasonalityAdjustments: SiteFairUseSeasonalityAdjustment[];

  constructor(policyId: number, state: string, spaceTypeFairUsePolicies: SpaceTypeFairUsePolicy[], seasonalityAdjustments: SiteFairUseSeasonalityAdjustment[]) {
    super(policyId);
    this.state = state
    this.spaceTypeFairUsePolicies = spaceTypeFairUsePolicies;
    this.seasonalityAdjustments = seasonalityAdjustments;
  }

  public targetUrl(): string {
    return '/FairUse/policies';
  }
}