import { Feature } from '@api/models/Feature';
import { Tenant } from '@api/models/Tenant';
import { useState } from 'react';
import styled from 'styled-components';
import TenantLogo from './TenantLogo';
import { Card } from '@components/core/Card';
import TenantFeatureList from './TenantFeatureList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TextHighlighter from '@components/table/TextHighlighter';

interface ITenantListItem {
  tenant: Tenant;
  features: Feature[];
  refresh: () => void;
  searchString: string;
}
const TenantListItem = ({ tenant, features, refresh, searchString }: ITenantListItem) => {
  const [isOpen, setIsOpen] = useState(false);
  const isVisible = tenant.name.toLowerCase().includes(searchString);

  return (
    <Container visible={isVisible}>
      <Card noPadding>
        <Header onClick={() => setIsOpen(prevState => !prevState)}>
          <TenantLogo tenant={tenant} />

          <TextHighlighter
            text={tenant.name}
            highlight={searchString}
          />

          <Icon
            icon={isOpen ? solid('chevron-up') : solid('chevron-down')}
          />
        </Header>
      </Card>

      <Collapsible isOpen={isOpen}>
        <TenantFeatureList
          features={features}
          tenant={tenant}
          refresh={refresh}
        />
      </Collapsible>
    </Container>
  );
};

export default TenantListItem;

const Container = styled.div<{ visible: boolean }>`
  height: ${p => p.visible ? 'auto' : '0px'};
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  padding: 5px 15px;
  border-left: 5px solid ${p => p.theme.palette.primary};
  border-radius: 5px 0 0 5px;
`;

const Collapsible = styled.div<{ isOpen: boolean }>`
  display: ${p => p.isOpen ? 'block' : 'none'};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  padding: 5px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  margin-left: auto;
  transition: all 150ms ease;

  ${Header}:hover & {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;