import { BlobName } from '@api/models/BlobName';
import { PostFormDataQuery } from '@api/queries/common/PostFormDataQuery';

export default class UserProfileImageUploadCommand extends PostFormDataQuery<BlobName> {

  constructor(file: File, userId: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', userId.toString());

    super(formData);
  }

  public targetUrl(): string {
    return '/users/profileImage';
  }
}
