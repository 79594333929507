import React, { RefObject, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import NoteAttachment from './NoteAttachment';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useApi } from '@hooks/useApi';
import { Modal } from '@components/core/Modal';
import { FileDropzone } from '@components/core/FileDropzone';
import { alphabeticalSort } from '@utils/StringUtils';
import { Button } from '@components/core/Button';
import { NoteDto } from '@api/models/NoteDto';
import { NoteAttachmentUploadCommand } from '@api/queries/notes/NoteAttachmentUploadCommand';

interface IDeviceNoteAttachmentModal extends React.ComponentPropsWithoutRef<'div'> {
  modalRef: RefObject<HTMLDivElement>;
  open: boolean,
  hide: () => void;
  note: NoteDto;
  refreshNotes: () => void;
  userCanEdit: boolean;
}

const DeviceNoteAttachmentModal = ({ modalRef, open, hide, note, refreshNotes, userCanEdit }: IDeviceNoteAttachmentModal) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute, loading } = useApi();
  const [file, setFile] = useState<File>();

  const sortedNotes = [...note.attachments].sort((a, b) => alphabeticalSort(a.fileName, b.fileName));

  const handleAttachments = (files: File[]) => {
    if (!files[0]) {
      setFile(undefined);
    }

    setFile(files[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: handleAttachments,
    accept: '.txt, .pdf, .csv, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png, .pst, .eml, .msg'
  });


  const handleAttachmentUpload = async () => {
    if (note && file) {
      const response = await execute({
        query: new NoteAttachmentUploadCommand(note.id, file),
        errorMessage: t('DeviceCard.AttachmentUploadFailed', { ns: 'molecules' }),
        successMessage: t('DeviceCard.AttachmentUploadSuccess', { ns: 'molecules' }),
      });

      if (!response) {
        return;
      }

      setFile(undefined);
      refreshNotes();
    }
  };

  return (
    <Modal
      ref={modalRef}
      isOpen={open}
      title={t('DeviceCard.Attachments', { ns: 'molecules' })}
      hide={hide}
      width="500px"
    >
      <FlexColumn>
        {sortedNotes.length > 0 &&
          <AttachmentList>
            {sortedNotes.map(attachment => (
              <NoteAttachment
                key={attachment.id}
                attachment={attachment}
                refreshNotes={refreshNotes}
                userCanEdit={userCanEdit}
              />
            ))}
          </AttachmentList>
        }

        {userCanEdit &&
          <>
            <Label>
              {t('DeviceCard.UploadNewAttachment', { ns: 'molecules' })}
            </Label>

            <FileDropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              onDelete={() => setFile(undefined)}
              fileName={file?.name}
              dimText={file ? '' : t('DeviceCard.UploadFile', { ns: 'molecules' })}
              containerStyle={{ height: '70px', boxShadow: `0 1px 3px 1px ${theme.palette.shadows.medium}` }}
            />

            {file &&
              <Button
                label={t('Upload', { ns: 'common' })}
                onClick={handleAttachmentUpload}
                disabled={!file}
                loading={loading}
              />
            }

          </>
        }

        <Button
          tertiary
          label={t('Close', { ns: 'common' })}
          onClick={hide}
          style={{ marginLeft: 'auto' }}
        />
      </FlexColumn>
    </Modal>
  );
};

export default DeviceNoteAttachmentModal;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  max-height: 250px;
  overflow: auto;
  padding-bottom: 12px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  padding-bottom: 7px;
`;