import { Tenant } from '@api/models/Tenant';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class TenantCreateCommand extends PostQuery<Tenant> {
  tenantName: string;
  contactEmail: string;
  databaseConnectionId: number;

  constructor(name: string, email: string, databaseConnectionId: number) {
    super();
    this.tenantName = name;
    this.contactEmail = email;
    this.databaseConnectionId = databaseConnectionId;
  }

  public targetUrl(): string {
    return '/tenants/create';
  }
}
