import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { SiteFairUseEnergyConsumption } from '@api/models/SiteFairUseEnergyConsumption';
import { GetQuery } from '@api/queries/common/GetQuery';

export class SiteFairUseGetEnergyConsumptionQuery extends GetQuery<SiteFairUseEnergyConsumption> {
  policyId: number;
  siteId: number;
  meterType: EnergyMeterType;

  constructor(policyId: number, siteId: number, meterType: EnergyMeterType) {
    super();
    this.policyId = policyId;
    this.siteId = siteId;
    this.meterType = meterType;
  }

  public targetUrl(): string {
    return `/FairUse/EnergyConsumption/${this.policyId}/${this.siteId}/${this.meterType}`;
  }
}
