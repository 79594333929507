import { BuildingHierarchiesWithDevicesGetBySiteIdQuery } from '@api/queries/buildings/BuildingHierarchiesWithDevicesGetBySiteIdQuery';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeviceBulkImport, { DeviceBulkImportType } from '@pages/site/buildings/settings/devices/all/components/DeviceBulkImport';
import { Device } from '@api/models/Device';
import { DeviceDetails } from '@api/models/DeviceDetails';
import { HierarchySpace } from '@api/models/Hierarchy';
import { Button } from '@components/core/Button';
import { ICascaderMultiSelectNodeGroup } from '@components/cascader-multi-select/CascaderMultiSelect.types';
import FullScreenToggle from '@components/core/FullScreenToggle';
import { SearchField } from '@components/search-field/SearchField';
import { Title } from '@components/core/Title';
import { useApiState } from '@hooks/useApiState';
import { useSiteContext } from '@pages/site/SiteProvider';
import DeviceModelFilter from '@components/core/DeviceModelFilter';
import DeviceTypeFilter from '@components/core/DeviceTypeFilter';
import { LocationFilter } from '@components/core/LocationFilter';
import { ThemeColored } from '@components/core/ThemeColoredSpan';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

type DeviceManagementHeaderProps = {
  devices: Device[] | DeviceDetails[];
  tableRecordCount?: number;
  onExportCsv: () => void;
  modelFilter: string[];
  onSetModelFilter: (modelFilter: string[]) => void;
  typeFilter: string[];
  onSetTypeFilter: (typeFilter: string[]) => void;
  spaceFilter?: HierarchySpace[];
  onSetSpaceFilter: (spaceFilter?: HierarchySpace[]) => void;
  onSetSearchString: (searchString: string) => void;
  createDeviceLink?: string;
  deviceBulkImport?: DeviceBulkImportType;
}

const DeviceManagementHeader = ({
  devices,
  tableRecordCount,
  onExportCsv,
  modelFilter,
  onSetModelFilter,
  typeFilter,
  onSetTypeFilter,
  spaceFilter,
  onSetSpaceFilter,
  onSetSearchString,
  createDeviceLink,
  deviceBulkImport
}: DeviceManagementHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { site } = useSiteContext();
  const [resetPulse, setResetPulse] = useState<number>(0);
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<HierarchySpace>>();
  const { data: buildings } = useApiState({
    query: new BuildingHierarchiesWithDevicesGetBySiteIdQuery(site.id),
    initialState: [],
  }, []);

  const hasFilters = useMemo(
    () => modelFilter.length > 0 || typeFilter.length > 0 || spaceFilter,
    [modelFilter, typeFilter, spaceFilter]
  );

  const deviceModelOptions = useMemo(() => (
    devices.map(x => x.deviceModel)
  ), [devices]);

  useEffect(() => {
    const options: ICascaderMultiSelectNodeGroup<HierarchySpace> = {
      header: t('DeviceManagement.Buildings', { ns: 'settingsDevice' }),
      nodes: orderBy(buildings, x => x.name.toLocaleLowerCase()).map(building => ({
        label: `${building.name}`,
        childGroup: {
          header: t('DeviceManagement.Floors', { ns: 'settingsDevice' }),
          nodes: orderBy(building.floors, x => x.floorNumber).map(floor => ({
            label: `${floor.name}`,
            childGroup: {
              header: t('DeviceManagement.Spaces', { ns: 'settingsDevice' }),
              nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map(space => ({
                label: `${space.name}`,
                value: space
              }))
            }
          }))
        }
      }))
    };

    setCascaderOptions(options);
  }, [buildings, t]);

  return (
    <>
      <FlexContainer>
        <Title
          text={
            <>
              {t('DeviceManagement', { ns: 'common' })}{' '}
              {tableRecordCount !== undefined && <ThemeColored>({tableRecordCount})</ThemeColored>}
            </>
          }
        />

        <FlexRow>
          <Button label={t('Export', { ns: 'common' })} onClick={onExportCsv} secondary />
          <Link to={createDeviceLink ?? 'create'}>
            <Button
              label={
                <FlexRow>
                  <FontAwesomeIcon icon={solid('plus')} style={{ width: '16px', height: '16px' }} />
                  {t('DeviceManagement.AddDevice', { ns: 'settingsDevice' })}
                </FlexRow>
              }
            />
          </Link>
        </FlexRow>
      </FlexContainer>

      <FlexContainer>
        <FlexRow>
          <DeviceModelFilter
            deviceModels={deviceModelOptions}
            onChange={onSetModelFilter}
            resetPulse={resetPulse}
          />

          <DeviceTypeFilter devices={devices} onChange={onSetTypeFilter} resetPulse={resetPulse} />

          {cascaderOptions && (
            <LocationFilter
              label={t('Space', { ns: 'common' })}
              group={cascaderOptions}
              onChange={onSetSpaceFilter}
              resetPulse={resetPulse}
            />
          )}

          {hasFilters && (
            <Button
              tertiary
              label="Clear filters"
              onClick={() => setResetPulse((prev) => prev + 1)}
            />
          )}
        </FlexRow>

        <FlexRow>
          {deviceBulkImport && (
            <DeviceBulkImport
              buildingId={deviceBulkImport.buildingId}
              onComplete={deviceBulkImport.onComplete}
            />)
          }
          <SearchField
            placeholder={t('DeviceManagement.SearchDevices', { ns: 'settingsDevice' })}
            onSearchChange={onSetSearchString}
            style={{ background: theme.action.filled }}
          />
          <FullScreenToggle />
        </FlexRow>
      </FlexContainer>
    </>
  );
};

export default DeviceManagementHeader;

const FlexContainer = styled.div` 
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
  justify-content: space-between;
`;

const FlexRow = styled.div` 
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;