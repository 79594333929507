import { LocalisationType } from '@i18n//localisation';
import { ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import dayjs from 'dayjs';
import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';
import { TDataset } from './useChart';

export const constructChartOptions = (theme: DefaultTheme, localisation: LocalisationType): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            'hour': 'HH:mm'
          },
        },
        ticks: {
          display: true,
          source: 'data',
          align: 'center',
          autoSkip: true,
          autoSkipPadding: 30,
          maxRotation: 0,
          padding: 5,
          color: theme.text.secondary,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '400',
          },
        },
        grid: {
          display: true,
          drawOnChartArea: false,
          borderColor: transparentize(0.2, theme.palette.siteOverview.charts.lines),
        }
      },
      y: {
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.4, theme.palette.siteOverview.charts.lines),
        },
        ticks: {
          stepSize: 5,
          maxTicksLimit: 6,
          padding: 10,
          color: theme.text.secondary,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '400',
          }
        }
      },
      y1: {
        display: 'auto',
        position: 'right',
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          color: transparentize(0.4, theme.palette.siteOverview.charts.lines),
        },
        suggestedMin: 0,
        suggestedMax: 50,
        ticks: {
          stepSize: 10,
          maxTicksLimit: 6,
          color: theme.text.secondary,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '400',
          }
        }
      }
    },
    elements: {
      line: {
        borderWidth: 1,
        tension: 0.3,
      },
      point: {
        radius: 0,
        hoverRadius: 3.5,
        hitRadius: 20,
        hoverBorderWidth: 2,
        hoverBackgroundColor: theme.background.container
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
        displayColors: false,
        caretPadding: 12,
        caretSize: 7,
        backgroundColor: theme.background.container,
        cornerRadius: 4,
        padding: 10,
        borderColor: theme.action.divider,
        borderWidth: 1,
        titleColor: theme.text.secondary,
        titleFont: {
          family: theme.fontFamily,
          weight: '400',
          size: 14,
        },
        bodyColor: theme.palette.text.medium,
        bodyFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 14,
        },
        callbacks: {
          title: (items: TooltipItem<'line'>[]) => {
            return dayjs(new Date(items[0].parsed.x)).format(localisation.dateFormats.dateAndTime);
          },
          label: (item: TooltipItem<'line'>) => {
            const dataset = (item.dataset as unknown as TDataset);
            return `${dataset.label}: ${dataset?.dataUnit?.prefix ?? ''}${item.parsed.y}${dataset?.dataUnit?.suffix ?? ''}`;
          }
        },
      }
    }
  };
}