import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { round } from '@utils/NumberUtils';
import SystemMessage, { SystemMessageType } from '@components/core/SystemMessage';
import PercentageLabel from './UtilityWidgetPercentageLabel';
import ChartViewToggle, { ConsumptionChartView } from './chart/ChartViewToggle';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import TimeRangeSelect, { DefaultTimeRanges, TimeRange, TimeRangeType } from '@components/time-range-select/TimeRangeSelect';
import { useCallback, useEffect, useState } from 'react';
import SiteUtilityDailyConsumptionGetQuery from '@api/queries/metrics/SiteUtilityDailyConsumptionGetQuery';
import { useSiteContext } from '@pages/site/SiteProvider';
import { dateToUtcDateTime } from '@utils/DateUtils';
import dayjs from 'dayjs';
import { useApiState } from '@hooks/useApiState';
import SkeletonLoader from '@components/core/SkeletonLoader';
import { useApi } from '@hooks/useApi';
import { SiteUtilityDailyConsumptionDto } from '@api/models/SiteUtilityDailyConsumptionDto';
import { TUtilityTab } from './SiteUtilitiesWidget';
import UtilityChart from './chart/UtilityChart';

const getTotalLabel = (unit: string, total?: number) => {
  if (!total) {
    return `0${unit}`;
  }

  return `${round(total, 2)}${unit}`;
};

type ConsumptionWidgetProps = {
  utility: TUtilityTab;
  view: ConsumptionChartView;
  onViewChange: (view: ConsumptionChartView) => void;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
  onCsvGenerated: (csv: string, fileName: string) => void;
};

const UtilityWidget = ({ utility, view, onViewChange, conversions, onSetConversions, onCsvGenerated }: ConsumptionWidgetProps) => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const { execute, loading: loadingChartData } = useApi();
  const [showPreviousYear, setShowPreviousYear] = useState(false);
  const [timeRangeType, setTimeRangeType] = useState(TimeRangeType.Year);
  const [timeRange, setTimeRange] = useState<TimeRange>(DefaultTimeRanges[TimeRangeType.Year]);
  const [chartData, setChartData] = useState<SiteUtilityDailyConsumptionDto>();
  const { data: dataYearToDate, loading } = useApiState({
    query: new SiteUtilityDailyConsumptionGetQuery(site.id, utility.meterType, dateToUtcDateTime(dayjs().startOf('year')).toISOString(), dateToUtcDateTime(dayjs()).toISOString())
  }, [site]);

  const fetchChartData = useCallback(async () => {
    const data = await execute({
      query: new SiteUtilityDailyConsumptionGetQuery(site.id, utility.meterType, dateToUtcDateTime(timeRange.from).toISOString(), dateToUtcDateTime(timeRange.to).toISOString(), showPreviousYear)
    });

    if (data) {
      setChartData(data);
    }
  }, [site, utility, timeRange, showPreviousYear, execute]);

  useEffect(() => {
    fetchChartData();
  }, [fetchChartData]);

  const handleChange = useCallback((timeRangeType: TimeRangeType, range: TimeRange, showPreviousYear: boolean) => {
    setTimeRangeType(timeRangeType);
    setTimeRange(range);
    setShowPreviousYear(showPreviousYear);
  }, []);

  const systemMessage = (
    <span>
      <Trans i18nKey='molecules:SiteOverview.MissingConsumptionTarget'>
        {t(`SiteOverview.${utility.metricType}`, { ns: 'molecules' })}
        <StyledLink
          link={{
            path: './../settings/site/metadata',
            analytics: { action: 'missing_target', category: 'performance_utilities', utilities_tab: utility.meterType.toLowerCase() }
          }}
        />
      </Trans>
    </span>
  );

  return (
    <Wrapper>
      <div>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          {loading ? (
            <SkeletonLoader
              bars={[{ w: 180 }, { w: 90 }]}
              wrapperStyles={{}}
            />
          ) : (
            <FlexRow>
              <Label>
                {new Date().getFullYear()} {t('SiteOverview.YearToDate', { ns: 'molecules' })}: <LabelValue>{getTotalLabel(utility.unit, dataYearToDate?.currentYearPeriodTotal)}</LabelValue>
              </Label>

              <PercentageLabel total={dataYearToDate?.currentYearPeriodTotal} target={dataYearToDate?.currentYearPeriodTarget} />
            </FlexRow>
          )
          }

          <ChartViewToggle
            selected={view}
            onChange={onViewChange}
          />
        </FlexRow>
      </div>

      {dataYearToDate && !dataYearToDate.currentYearPeriodTarget &&
        <SystemMessage
          messages={[systemMessage]}
          type={SystemMessageType.Information}
          dissmisable
        />
      }

      <TimeRangeSelect
        timeRangeType={timeRangeType}
        range={timeRange}
        showPreviousYear={showPreviousYear}
        onChange={handleChange}
      />

      <UtilityChart
        utility={utility}
        data={chartData}
        loading={loadingChartData}
        timeRangeType={timeRangeType}
        timeRange={timeRange}
        view={view}
        conversions={conversions}
        onSetConversions={onSetConversions}
        onCsvGenerated={onCsvGenerated}
      />
    </Wrapper>
  );
};

export default UtilityWidget;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

const LabelValue = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const StyledLink = styled(ProtectedLink)`
  font-weight: 500;
  color: ${p => p.theme.palette.primary} !important;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;