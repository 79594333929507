import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { EnergyMeterBillDto } from '@api/models/EnergyMeterBillDto';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class EnergyMeterBillUpdateCommand extends PatchQuery<EnergyMeterBillDto> {
  siteId: number;
  buildingId: number | undefined;
  siteName: string;
  buildingName: string | undefined;
  meterNumber: string;
  meterId: number;
  meterReading?: string;
  measuredOn?: Date;
  consumption?: string;
  periodStartDate?: Date;
  periodEndDate?: Date;
  meterType: EnergyMeterType;

  constructor(
    id: number, 
    siteId: number,
    buildingId: number | undefined,
    siteName: string,
    buildingName: string | undefined,
    meterNumber: string,
    meterId: number,
    meterType: EnergyMeterType,
    meterReading?: string,
    measuredOn?: Date,
    consumption?: string,
    periodStartDate?: Date,
    periodEndDate?: Date,
  ) {
    super(id);
    this.siteId = siteId;
    this.buildingId = buildingId;
    this.siteName = siteName;
    this.buildingName = buildingName;
    this.meterNumber = meterNumber;
    this.meterId = meterId;
    this.meterReading = meterReading;
    this.measuredOn = measuredOn;
    this.consumption = consumption;
    this.periodStartDate = periodStartDate;
    this.periodEndDate = periodEndDate;
    this.meterType = meterType;
  }

  public targetUrl(): string {
    return '/energymeters/updateenergymeterbill';
  }
}