import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formatDistance } from 'date-fns';
import { transparentize } from 'polished';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DeviceGetByIdQuery } from '@api/queries/devices/DeviceGetByIdQuery';
import DeviceReplaceCommand from '@api/queries/devices/DeviceReplaceCommand';
import BarcodeScanner from '@components/core/BarcodeScanner';
import { Device } from '@api/models/Device';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { InteractiveDialog } from '@components/core/InteractiveDialog';
import { RouterPrompt } from '@src/navigation/RouterPrompt/RouterPrompt';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { valuesDiffer } from '@utils/ObjectUtils';
import { useModal } from '@hooks/useModal';
import { Card } from '@components/core/Card';
import { ErrorMessage, Form, Input, Label } from '@components/Form';
import { Button } from '@components/core/Button';
import { BackButton } from '@components/core/BackButton';

type FormValues = {
  deviceIdentifier: string
}

const DeviceReplace = () => {
  const { t } = useTranslation(['settingsDevice']);
  const theme = useTheme();
  const { deviceId } = useParams<{ deviceId: string }>();
  const { getDisplayString } = useDeviceConfigContext();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [executePostSaveAction, setExecutePostSaveAction] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<FormValues>();
  const formValues = watch();
  const { execute } = useApi();
  const [barcodeScannerEnabled, setBarcodeScannerEnabled] = useState(false);
  const { isOpen: replaceDeviceIsOpen, toggle: toggleDeviceReplaceDialog, ref: replaceDeviceRef } = useModal({});
  const { data: device, setData: setDevice } = useApiState({
    query: deviceId === undefined ? undefined : new DeviceGetByIdQuery(parseInt(deviceId))
  }, [deviceId]);

  useEffect(() => {
    if (device) {
      const hasBeenModified = valuesDiffer<FormValues, Device>(formValues, device);
      setHasUnsavedChanges(hasBeenModified);
    }
  }, [device, formValues]);

  /**
   * Execute after saving, redirect back to devices
   */
  useEffect(() => {
    if (executePostSaveAction && !hasUnsavedChanges) {
      navigate('../devices');
    }
  }, [executePostSaveAction, hasUnsavedChanges, deviceId, navigate]);

  if (!device) {
    return null;
  }

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const modifiedDevice = cloneDeep(device);
    modifiedDevice.deviceIdentifier = data.deviceIdentifier;

    if (!valuesDiffer(modifiedDevice, device)) {
      return;
    }

    const result = await execute({
      query: new DeviceReplaceCommand(device.id, modifiedDevice.deviceIdentifier),
      successMessage: t('DeviceReplace.ChangesSaved', { ns: 'settingsDevice' }),
      errorMessage: t('DeviceReplace.SavingFailed', { ns: 'settingsDevice' }),
      displayApiError: true,
      toastOptions: { autoClose: 5000 }
    });

    setSavingInProgress(false);

    if (result === undefined) {
      return;
    }

    setDevice(modifiedDevice);
    setExecutePostSaveAction(true);
  }

  const ReplaceDeviceDialogContent = (
    <ReplaceDeviceDialogWrapper>
      <FlexRow style={{ gap: '5px' }}>
        <DialogLabel>Device Identifier:</DialogLabel>
      </FlexRow>

      <Row>
        <RowLabel>{device.deviceIdentifier}</RowLabel>
        <ArrowIcon icon={solid('arrow-right')} />
        <NewRowLabel>{formValues.deviceIdentifier}</NewRowLabel>
      </Row>
    </ReplaceDeviceDialogWrapper>
  );

  return (
    <PaddedContainer centered>
      <RouterPrompt when={hasUnsavedChanges} />

      <Container>
        <BackButton
          label={t('BackToDevices', { ns: 'navigation' })}
          url='../devices'
        />

        <Card cardTitle={t('DeviceReplace.ReplaceDevice', { ns: 'settingsDevice' })}>
          <DescriptionText>{t('DeviceReplace.ReplaceDeviceText', { ns: 'settingsDevice' })}</DescriptionText>
          <Form>
            <FormContainer>
              <FlexRow>
                <Column>
                  <Label>{t('DeviceReplace.FriendlyName', { ns: 'settingsDevice' })}</Label>
                  <Input
                    defaultValue={device.friendlyName}
                    disabled
                  />
                </Column>
              </FlexRow>
              <FlexRow>
                <Column>
                  <Label>{t('DeviceReplace.SpaceName', { ns: 'settingsDevice' })}</Label>
                  <Input
                    defaultValue={device.spaceName}
                    disabled
                  />
                </Column>
              </FlexRow>
              <FlexRow>
                <Column>
                  <Label>{t('DeviceReplace.DeviceModel', { ns: 'settingsDevice' })}</Label>
                  <Input
                    type="deviceModel"
                    defaultValue={getDisplayString(device.deviceModel)}
                    disabled
                  />
                </Column>
              </FlexRow>
              <FlexRow>
                <Column>
                  <Label>{t('DeviceReplace.LastMeasuredOn', { ns: 'settingsDevice' })}</Label>
                  <Input
                    defaultValue={device.lastMeasuredOn ? formatDistance(new Date(device.lastMeasuredOn), new Date(), { addSuffix: true }) : 'Never'}
                    disabled
                  />
                </Column>
              </FlexRow>
              <FlexRow>
                <Column>
                  <FlexRow style={{ justifyContent: 'space-between' }}>
                    <Label>{t('DeviceReplace.DeviceIdentifier', { ns: 'settingsDevice' })}</Label>
                    <IconWrapper onClick={() => setBarcodeScannerEnabled(!barcodeScannerEnabled)}>
                      <Icon icon={regular('qrcode')} />
                    </IconWrapper>
                  </FlexRow>
                  <Input
                    {...register('deviceIdentifier', {
                      required: t('DeviceReplace.RequiredField', { ns: 'settingsDevice' }),
                    })}
                    type="deviceIdentifier"
                    defaultValue={device.deviceIdentifier}
                  />
                  <ErrorMessage>{errors.deviceIdentifier?.message}</ErrorMessage>
                  <BarcodeScanner
                    enabled={barcodeScannerEnabled}
                    onUpdate={(value) => {
                      setValue('deviceIdentifier', value);
                      setBarcodeScannerEnabled(false);
                    }}
                  />
                </Column>
              </FlexRow>
            </FormContainer>
          </Form>
        </Card>

        <Card>
          <FlexRow style={{ justifyContent: 'flex-end', gap: 8 }}>
            <Link to='../devices'>
              <Button
                tertiary
                color={theme.palette.red}
                label={t('Cancel', { ns: 'common' })}
              />
            </Link>
            <Button
              label={t('DeviceReplace.Save', { ns: 'settingsDevice' })}
              onClick={toggleDeviceReplaceDialog}
              disabled={!hasUnsavedChanges || !isEmpty(errors)}
              loading={savingInProgress}
            />
          </FlexRow>

          <InteractiveDialog
            modalRef={replaceDeviceRef}
            isOpen={replaceDeviceIsOpen}
            hide={toggleDeviceReplaceDialog}
            title={t('DeviceReplace.ReplaceDevice', { ns: 'settingsDevice' })}
            content={ReplaceDeviceDialogContent}
            confirmButton={{
              label: t('DeviceReplace.Confirm', { ns: 'settingsDevice' }),
              onClick: handleSubmit(onSave),
              loading: savingInProgress
            }}
            cancelButton={{
              label: t('Cancel', { ns: 'common' }),
              onClick: toggleDeviceReplaceDialog
            }}
          />

        </Card>
      </Container>
    </PaddedContainer>
  );
};

export default DeviceReplace;

const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  width: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: ${p => p.theme.palette.text.weak};
`;

const IconWrapper = styled.div`
  width: 23px;
  height: 23px;
  margin-top: -5px;
  border: 2px dashed ${p => transparentize(0.15, p.theme.palette.text.weak)};
  border-radius: 2px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReplaceDeviceDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  box-shadow: 0 6px 4px -2px ${p => p.theme.palette.shadows.medium}, 0 2px 18px 0px ${p => p.theme.palette.shadows.medium};
  border-radius: 25px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.primary};
  font-size: 16px;
`;

const RowLabel = styled.div`
  color: ${p => p.theme.palette.text.weak};
  font-size: 16px;
  font-weight: 400;
`;

const NewRowLabel = styled.div`
  color: ${p => p.theme.palette.text.medium};
  font-size: 16px;
  font-weight: 500;
`;

const DialogLabel = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-size: 18px;
  font-weight: 500;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  padding-bottom: 20px;
`;