import Filter from '@components/core/Filter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { DeviceModel } from '@api/enums/DeviceModel';

type PropTypes = {
  deviceModels: DeviceModel[];
  onChange: (selected: string[]) => void;
  resetPulse?: number;
  rightAlign?: boolean;
  label?: string;
}

const DeviceModelFilter = ({ deviceModels, onChange, resetPulse, rightAlign, label }: PropTypes) => {
  const { t } = useTranslation();

  const uniqueModels = useMemo(() => Array.from(new Set(deviceModels.map(x => x))), [deviceModels]);
  const options = useMemo(() => orderBy(uniqueModels, x => x).map(x => ({ label: t(`${x}.DisplayString`, { ns: 'deviceConfigs' }), value: x })), [uniqueModels, t]);

  return (
    <Filter
      label={label ?? t('DeviceModel', { ns: 'common' })}
      options={options}
      onChange={onChange}
      resetPulse={resetPulse}
      rightAlign={rightAlign}
    />
  );
};

export default DeviceModelFilter;