import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import DeviceWidgets from './device-widgets/DeviceWidgets';
import { useTranslation } from 'react-i18next';
import { SpaceWithDeviceMetrics } from '@api/models/SpaceWithDeviceMetrics';
import { SpaceType_Color } from '@api/enums/SpaceType_Color';
import { SpaceType_DisplayString } from '@api/enums/SpaceType_DisplayString';
import { SpaceType_Icon } from '@api/enums/SpaceType_Icon';
import { Tooltip } from '@components/core/Tooltip';
import { Icon } from '@components/core/Icon';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { RouteLinkAnalytics } from '@src/types/Route';

interface ISpaceCard {
  space: SpaceWithDeviceMetrics;
  linkPathToSpace: string;
  showDeltas?: boolean;
  linkAnalytics?: RouteLinkAnalytics;
}

const SpaceCard = ({ space, linkPathToSpace, showDeltas, linkAnalytics }: ISpaceCard) => {
  const { t } = useTranslation(['molecules']);
  const spaceColor = SpaceType_Color(space.spaceType);

  return (
    <>
      <Header>
        <Tooltip
          content={SpaceType_DisplayString(space.spaceType)}
          placement={TooltipPlacement.TopLeft}
        >
          <Icon
            icon={SpaceType_Icon(space.spaceType)}
            iconSize='23px'
            squareSize='38px'
            iconColor='#fff'
            squareColor={spaceColor}
            shadowBottom
          />
        </Tooltip>
        <Title>
          {space.name}
        </Title>
        <LinkContainer>
          <ProtectedLink link={{ path: linkPathToSpace, analytics: linkAnalytics }}>
            {t('Widgets.EnterSpace', { ns: 'molecules' })}
            <ArrowRightIcon icon={regular('arrow-right-long')} />
          </ProtectedLink>
        </LinkContainer>
      </Header>

      <DeviceWidgets
        devices={space.devices}
        maxOccupancy={space.maxOccupancy}
        utilitySums={space.utilitySums}
        spaceColor={spaceColor}
        showDeltas={showDeltas}
      />
    </>
  );
};

export default SpaceCard;

const Header = styled.div`
  width: 100%;
  min-height: 66px;

  display: flex;
  align-items: center;
  
  padding-left: 20px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding: 2px 2px 2px 16px;
`;

const LinkContainer = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  padding: 22px 25px;

  font-weight: 400;
  font-size: 16px;
  color: ${p => p.theme.palette.text.weak};
  border-left: 1px solid ${p => p.theme.palette.borders.weak};
`;

const ArrowRightIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-left: 14px;
  color: ${p => p.theme.palette.text.weak};
`;