import { PaddedContainer } from '@components/core/PaddedContainer';
import { Card } from '@components/core/Card';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import ChallengeInfoContent from '../components/challenges/ChallengeInfoContent';
import { FormProvider, useForm } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import PrizeInfoContent from '../components/challenges/PrizeInfoContent';
import ImageInfoContent, { ImageType } from '../components/challenges/ImageInfoContent';
import { useState } from 'react';
import { Button } from '@components/core/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '@hooks/useApi';
import { ChallengesResidentAppCreateCommand } from '@api/queries/resident-app/Challenges/ChallengesResidentAppCreateCommand';
import { MetricType } from '@api/enums/MetricType';
import { OperatorPrizeDto } from '@api/models/OperatorPrizeDto';
import { isMetricEnabled, isEndDateAfterStartDate, isOverlappingOpenChallenge, getSeasonFromDates } from '@pages/site/resident-app/ResidentAppUtils';
import { ChallengesResidentAppUploadImageCommand } from '@api/queries/resident-app/Challenges/ChallengesResidentAppUploadImageCommand';
import { useFileHandler } from '@hooks/useFileHandler';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { dateToUtcDate } from '@utils/DateUtils';
import TitleWithButton from '@pages/site/resident-app/components/common/TitleWIthButton';
import { CustomResidentAppFeature } from '@api/enums/ResidentAppFeatureType';
import PageWrapper from '@pages/site/resident-app/components/common/PageWrapper';
import { useApiState } from '@hooks/useApiState';
import { ChallengesResidentAppGetAllBySiteQuery } from '@api/queries/resident-app/Challenges/ChallengesResidentAppGetAllBySiteQuery';
import LoadingWidget from '@components/core/LoadingWidget';
import { round } from '@utils/NumberUtils';

export type CreateChallengeForm = {
  startDate: Dayjs;
  endDate: Dayjs;
  averageTemperature?: number;
  electricityPercentage?: number;
  title: string;
  challengeDescription: string;
  prizes: Array<{
    id?: number;
    prizeName: string;
    prizeValue?: number;
  }>;
  shortTitle?: string;
  imageType: ImageType;
}

const ResidentApp_CreateChallenge = () => {
  const { t } = useTranslation('molecules');
  const { metricType } = useParams<{ metricType: MetricType }>(); 
  const theme = useTheme();
  const navigate = useNavigate();
  const { execute, loading, error } = useApi();
  const { site, siteOperator } = useSiteContext();
  const {file, handleFileChange, handleFileDelete} = useFileHandler();
  const { fromLocale } = useLocalisation();
  const { data: challenges, loading: challengesLoading } = useApiState(
    {
      query: new ChallengesResidentAppGetAllBySiteQuery(site.id),
      errorMessage: t('ResidentApp.ChallengesLoadFailed'),
    }, []);

  const methods = useForm<CreateChallengeForm>({
    defaultValues: {
      prizes: [{prizeName: '', prizeValue: undefined}],
      imageType: ImageType.UseTemplate
    }
  })

  const [customImageUrl, setCustomImageUrl] = useState<string | undefined>(undefined)

  const handleImageChange = (files: File[]) => {
    const url = URL.createObjectURL(files[0]);
    setCustomImageUrl(url);
    handleFileChange(files[0]);
  };

  const handleImageDelete = () => {
    setCustomImageUrl(undefined);
    handleFileDelete();
  };

  const handleCreateChallenge = async (data: CreateChallengeForm) => {
    const challengeMetricType = metricType === MetricType.Temperature ? MetricType.Temperature : MetricType.ElectricityKwh;

    if (challengeMetricType === MetricType.ElectricityKwh && !getSeasonFromDates(data.startDate, data.endDate)) {
      methods.setFocus('startDate');
      return;
    }

    if (challenges && isOverlappingOpenChallenge(challenges, challengeMetricType, data.startDate, data.endDate)) {
      methods.setFocus('startDate');
      return;
    }

    if (!isEndDateAfterStartDate(data.startDate, data.endDate)) {
      methods.setFocus('startDate');
      return;
    }

    if (data.imageType === ImageType.UploadImage && !customImageUrl) {
      methods.setFocus('imageType');
      return;
    }

    const shortTitleData = data.shortTitle && data.shortTitle.length > 0 ? data.shortTitle : t('ResidentApp.NewChallenge');
    const prizes: OperatorPrizeDto[] = data.prizes.map((prize) => ({
      prizeName: prize.prizeName,
      prizeValue: prize.prizeValue ?? 0,
      id: 0,
      challengeId: 0,
    }));

    const targetTemp = data.averageTemperature ? fromLocale(MetricType.Temperature, data.averageTemperature, {round: 1}) : 0;
    const targetElec = data.electricityPercentage ? round(data.electricityPercentage, 0) : 0;

    const targetValue = metricType === MetricType.Temperature ? targetTemp : targetElec;

    const response = await execute({
      query: new ChallengesResidentAppCreateCommand(
        data.title,
        shortTitleData,
        data.challengeDescription,
        challengeMetricType,
        targetValue,
        dateToUtcDate(data.startDate).toISOString(),
        dateToUtcDate(data.endDate).toISOString(),
        site.id,
        prizes
      ),
      successMessage: t('CreateChallengeSuccess', { ns: 'status' }),
      errorMessage: t('CreateChallengeError', { ns: 'status' }),
      pendingMessage: t('CreateChallengePending', { ns: 'status' }),
    });

    if (response && !error) {
      // if it's a custom image then call upload image api
      if(data.imageType === ImageType.UploadImage && file) {
        await execute({
          query: new ChallengesResidentAppUploadImageCommand(response.id, file),
          successMessage: t('UploadChallengeImageSuccess', { ns: 'status' }),
          errorMessage: t('UploadChallengeImageError', { ns: 'status' }),
          pendingMessage: t('UploadChallengeImagePending', { ns: 'status' }),
        })
      }
      // navigate to challenge view page
      navigate(`../resident-app/view-challenge/${response.id}`);
    }
  };

  if (siteOperator) {
    const isMetricInUrlEnabled = metricType && isMetricEnabled(siteOperator, metricType);

    if (isMetricEnabled(siteOperator, CustomResidentAppFeature.NoChallenges) || !isMetricInUrlEnabled) {
      return null;
    }
  }

  if (challengesLoading) {
    return (
      <LoadingWidget
        label={t('Loading')}
        styles={{ marginTop: 80 }}
      />
    );
  }
  
  return (
    <PageWrapper>
      <PaddedContainer>
        <TitleWithButton
          title={t('ResidentApp.CreateChallenge')}
          backButtonUrl="../resident-app"
          style={{ padding: '0 0 30px 0' }}
        />
        <CreateChallengeContainer noPadding maxWidth="fit-content">
          <FormProvider {...methods}>
            <CreateChallengeForm>
              {metricType && (
                <ChallengeInfoContent
                  methods={methods}
                  metricType={metricType}
                  challenges={challenges}
                />
              )}
              <PrizeInfoContent methods={methods} />
              <ImageInfoContent
                methods={methods}
                onImageChange={handleImageChange}
                onImageDelete={handleImageDelete}
                imageUrl={customImageUrl}
              />

              <Buttons>
                <Button
                  label={t('Cancel', { ns: 'common' })}
                  onClick={() => navigate('../resident-app')}
                  tertiary
                  color={theme.palette.systemMessage.error}
                  disabled={loading}
                />
                <Button
                  label={t('Create', { ns: 'common' })}
                  onClick={methods.handleSubmit((data) => handleCreateChallenge(data))}
                  disabled={loading}
                />
              </Buttons>
            </CreateChallengeForm>
          </FormProvider>
        </CreateChallengeContainer>
      </PaddedContainer>
    </PageWrapper>
  );
}

export default ResidentApp_CreateChallenge;

const CreateChallengeContainer = styled(Card)`
  padding: 25px 20px;
  margin-top: 30px;
`;

const CreateChallengeForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;