import { TenancyDto } from '@api/models/TenancyDto';
import { OperatorGetQuery } from '@api/queries/resident-app/common/OperatorGetQuery';

export class UsersResidentAppGetAllBySiteQuery extends OperatorGetQuery<TenancyDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/Users/GetAllBySite/${this.siteId}`;
  }
}
