import styled from 'styled-components';
import { isNullOrUndefined } from '@utils/NumberUtils';
import { Tooltip } from '@components/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ITooltip } from '@components/core/Tooltip.types';

type LabelValuePairProps = {
  label: string;
  value?: string | number;
  /**
   * Value prefix (optional)
   */
  prefix?: string | number;
  /**
   * Value suffix (optional)
   */
  suffix?: string | number;
  /**
   * Size of the value
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /**
   * Smaller gap between label and value
   */
  smallGap?: boolean;
  /**
   * Display label and value inline (next to each other)
   */
  inline?: boolean;
  /**
   * Additonal styles
   */
  style?: React.CSSProperties;
  /**
   * Additonal label styles
   */
  labelStyle?: React.CSSProperties;
  /**
   * Additonal value styles
   */
  valueStyle?: React.CSSProperties;
  /**
   * Tooltip
   */
  tooltip?: ITooltip;
}

export const LabelValuePair = ({
  label,
  value,
  prefix,
  suffix,
  size,
  smallGap,
  inline,
  style,
  labelStyle,
  valueStyle,
  tooltip
}: LabelValuePairProps) => {
  return (
    <div style={style}>
      <FlexRow inline={inline}>
        <Label style={labelStyle}>
          {label}
          {inline && <>&nbsp;</>}
        </Label>

        {tooltip &&
          <Tooltip fontSize='12px' {...tooltip}>
            <TooltipIcon icon={regular('info-circle')} />
          </Tooltip>
        }
      </FlexRow>
      <Value style={valueStyle} size={size} smallGap={smallGap} inline={inline}>
        {!isNullOrUndefined(value) && prefix && (
          <PrefixSuffix>{prefix}&nbsp;</PrefixSuffix>
        )}
        {!isNullOrUndefined(value) ? value : '--'}
        {!isNullOrUndefined(value) && suffix && (
          <PrefixSuffix>&nbsp;{suffix}</PrefixSuffix>
        )}
      </Value>
    </div>
  );
};

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.text.secondary};
`;

const Value = styled.div<{
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  smallGap?: boolean;
  inline?: boolean;
}>`
  display: ${(p) => (p.inline ? 'inline-block' : 'block')};

  font-size: ${(p) =>
    (p.size === 'xs' && '14px') ||
    (p.size === 'sm' && '16px') ||
    (p.size === 'lg' && '26px') ||
    (p.size === 'xl' && '32px') ||
    '20px'};

  line-height: ${(p) => (p.smallGap ? '16px' : 'normal')};

  font-weight: 500;
  color: ${(p) => p.theme.text.primary};
`;

const PrefixSuffix = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: ${(p) => p.theme.text.primary};
`;

const FlexRow = styled.div<{ inline?: boolean }>`
  display: ${p => p.inline ? 'inline-block' : 'flex'};
  align-items: center;
  gap: 5px;
  padding-bottom: 2px;
`;

const TooltipIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
  transition: all 150ms ease;
  cursor: pointer;
  margin-bottom: 1px;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;