import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import backdrop from '@assets/images/london-backdrop.png';
import UtopiLogoU from '@assets/images/utopi-logo-u.png';

type LoadingFullPageProps = {
  icon: IconDefinition;
  iconSize?: string;
  iconPosTop?: string;
  message?: string;
}

export const LoadingFullPage = ({ message, icon, iconSize, iconPosTop }: LoadingFullPageProps) => (
  <StyledContainer>
    <Wrapper>
      <Icon icon={icon} iconsize={iconSize} $iconPosTop={iconPosTop} />
      <Card>
        <FlexRow>
          <SpinnerContainer>
            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
          </SpinnerContainer>

          <Title>{message}</Title>
        </FlexRow>

        <FlexRow style={{ marginTop: '20px' }}>
          <Logo src={UtopiLogoU} />
          <CompanyName>Utopi</CompanyName>
        </FlexRow>
      </Card>
    </Wrapper>
  </StyledContainer>
);

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${backdrop});
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Card = styled.div`
  position: relative;
  max-width: 500px;
  overflow: auto;
  background: ${(p) => p.theme.palette.backgrounds.surface};
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgb(34 37 51 / 7%);
  padding: 40px 30px 20px 30px;
  margin: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.primary};
  margin: 0 0 4px 15px;
`;

const Icon = styled(FontAwesomeIcon) <{ iconsize?: string; $iconPosTop?: string; }>`
  position: absolute;
  top: ${(p) => p.$iconPosTop ?? '0px'};
  right: 60px;
  color: ${(p) => p.theme.palette.primary};
  font-size: ${(p) => p.iconsize ?? '30px'};
  z-index: 1;
`;

const SpinnerContainer = styled.div`
  svg {
    width: 34px;
    height: 34px;

    path {
      fill: ${(p) => p.theme.palette.primary};
    }
  }
`;

const Logo = styled.img`
  width: 12px;
  margin-right: 4px;
`;

const CompanyName = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.text.medium};
`;
