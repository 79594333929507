import { Space } from '@api/models/Space';
import { ClusterFormValues, SpaceFormValues, SpaceRequestType } from '@pages/site/buildings/settings/spaces/list/BuildingSpaceList';
import { SpaceType } from '@api/enums/SpaceType';
import { Building } from '@api/models/Building';
import { Floor } from '@api/models/Floor';
import { Cluster } from '@api/models/Cluster';
import { ClustersAndSpaces } from '@api/models/ClustersAndSpaces';
import { isMatch } from 'lodash';
import SpaceTypeOptions from '@pages/site/buildings/settings/spaces/list/components/SpaceTypeOptions';

export const isModified = (spaces: Space[], formValues?: ClusterFormValues, clusters?: Cluster[]): boolean => {
  if (!formValues || formValues.toBeCreated || formValues.toBeDeleted) {
    return false;
  }

  if (clusters !== undefined && clusters.length > 0) {
    const originalCluster = clusters.find((cluster: Cluster) => cluster.id === formValues.id);
    const originalClusterSpaces = originalCluster?.spaces;
    const clusterFormValuesSpaces = formValues.spaces;
    return originalCluster?.clusterName !== formValues.clusterName || clusterFormValuesSpaces?.length !== originalClusterSpaces?.length;
  }

  const originalSpace = spaces.find((x) => x.id === formValues.id);
  return originalSpace !== undefined && !isMatch(originalSpace, formValues);
}

export const sortSpaces = (spaces: Space[]): Space[] => {
  const numericSpaces: Space[] = spaces.filter(space => !Number.isNaN(parseInt(space.name))).sort(function (currentSpace: Space, nextSpace: Space) {
    const currentSpaceNumber: number = parseInt(currentSpace.name);
    const nextSpaceNumber: number = parseInt(nextSpace.name);
    return currentSpaceNumber - nextSpaceNumber;
  });

  const nonNumericSpaces: Space[] = spaces.filter(space => Number.isNaN(parseInt(space.name))).sort((currentSpace: Space, nextSpace: Space) => currentSpace.name.localeCompare(nextSpace.name));

  const sortedSpaces = numericSpaces.concat(nonNumericSpaces);

  return sortedSpaces;
};

export const sortClusters = (clusters: Cluster[]): Cluster[] => {
  const numericSpaces: Cluster[] = clusters.filter(cluster => !Number.isNaN(parseInt(cluster.clusterName))).sort((currentCluster: Cluster, nextCluster: Cluster) => {
    const currentSpaceNumber: number = parseInt(currentCluster.clusterName);
    const nextSpaceNumber: number = parseInt(nextCluster.clusterName);
    return currentSpaceNumber - nextSpaceNumber;
  });

  const nonNumericClusters: Cluster[] = clusters.filter(cluster => Number.isNaN(parseInt(cluster.clusterName))).sort((currentCluster: Cluster, nextCluster: Cluster) =>
    currentCluster.clusterName.localeCompare(nextCluster.clusterName));

  const sortedSpaces = numericSpaces.concat(nonNumericClusters);

  return sortedSpaces;
};

export const getClustersAndSpacesByRequestType = (requestType: SpaceRequestType, spacesFormValues: SpaceFormValues[], building: Building, floor?: Floor, clusterAndSpaces?: ClustersAndSpaces): ClustersAndSpaces => {
  switch (requestType) {
    case SpaceRequestType.Create: {
      const spacesToBeCreated = spacesFormValues.filter((spaceFormValues: SpaceFormValues) => spaceFormValues.toBeCreated && !isCluster(spaceFormValues))
        .map((spaceFormValues: SpaceFormValues) => ({ ...spaceFormValues, floor: floor, building: building })) as Space[];

      const clustersToBeCreated = spacesFormValues.filter((clusterFormValues: ClusterFormValues) => clusterFormValues.toBeCreated && isCluster(clusterFormValues))
        .map((clusterFormValues: ClusterFormValues) => clusterFormValuesToCluster(clusterFormValues, clusterFormValues.spaces ?? [], building, floor));

      return { spaces: spacesToBeCreated, clusters: clustersToBeCreated };
    }
    case SpaceRequestType.Update: {
      let spacesToBeUpdated = spacesFormValues
        .filter((spaceFormValues: SpaceFormValues) => !isCluster(spaceFormValues) && isModified(clusterAndSpaces?.spaces ?? [], spaceFormValues)) as Space[];

      const clusterSpacestoBeUpdated = spacesFormValues.flatMap((clusterFormValues: ClusterFormValues) => clusterFormValues.spaces)
        .filter((spaceFormValues: SpaceFormValues | undefined) => isModified(clusterAndSpaces?.clusters.flatMap((clusterSpaces: Cluster) => clusterSpaces.spaces) ?? [], spaceFormValues)) as Space[];

      const clustersToBeUpdate = spacesFormValues
        .filter((clusterFormValues: ClusterFormValues) => isCluster(clusterFormValues) && clusterAndSpaces?.spaces &&
          isModified(clusterAndSpaces?.spaces, clusterFormValues, clusterAndSpaces?.clusters))
        .map((clusterFormValues: ClusterFormValues) => clusterFormValuesToCluster(clusterFormValues, clusterFormValues.spaces ?? [], building, floor));

      spacesToBeUpdated = [...spacesToBeUpdated, ...clusterSpacestoBeUpdated];

      return { spaces: spacesToBeUpdated, clusters: clustersToBeUpdate };
    }
    case SpaceRequestType.Delete: {
      let spacesToBeDeleted = spacesFormValues.filter((spaceFormValues: SpaceFormValues) => spaceFormValues.toBeDeleted && spaceFormValues.id && !isCluster(spaceFormValues)) as Space[]

      const clusterSpacesToBeDeleted = spacesFormValues.flatMap((clusterFormValues: ClusterFormValues) => clusterFormValues.spaces)
        .filter(x => x?.id && x?.toBeDeleted) as Space[];

      const clustersToBeDeleted = spacesFormValues
        .filter((clusterFormValues: ClusterFormValues) => clusterFormValues.toBeDeleted && isCluster(clusterFormValues) ||
          clusterFormValues.spaces?.every((space: SpaceFormValues) => clusterSpacesToBeDeleted.find(y => y.id === space.id)) && isCluster(clusterFormValues))
        .map((clusterFormValues: ClusterFormValues) => clusterFormValuesToCluster(clusterFormValues, clusterFormValues.spaces ?? [], building, floor));

      spacesToBeDeleted = [...spacesToBeDeleted, ...clusterSpacesToBeDeleted];

      return { spaces: spacesToBeDeleted, clusters: clustersToBeDeleted };
    }
    default:
      return { spaces: [], clusters: [] };
  }
}

const isCluster = (clusterFormValues: ClusterFormValues): boolean => {
  return clusterFormValues.spaces !== undefined && clusterFormValues.spaces?.length > 0;
}

const clusterFormValuesToCluster = (clusterFormValues: ClusterFormValues, clusterSpaces: SpaceFormValues[], building: Building, floor?: Floor): Cluster => {
  return {
    id: clusterFormValues.id ?? 0,
    clusterName: clusterFormValues.clusterName ?? '',
    primarySpaceId: clusterSpaces[0].id ?? 0,
    spaces: clusterSpaces.map((spaceFormValues: SpaceFormValues) => ({ ...spaceFormValues, floor: floor, building: building })) as Space[]
  }
}

export const isCommonSpaceType = (spaceType: SpaceType): boolean => {
  return SpaceTypeOptions.groups.flatMap((group) => {
    if (group.label.includes('Common')) {
      return group.options;
    }
  }).filter(x => x !== undefined).includes(spaceType);
}