import { AlertCountBySiteGetQuery } from '@api/queries/alerts/AlertCountBySiteGetQuery';
import { AlertsGetBySiteQuery } from '@api/queries/alerts/AlertsGetBySiteQuery';
import { UnreadAlertCountBySiteGetQuery } from '@api/queries/alerts/UnreadAlertCountBySiteGetQuery';
import { UnreadAlertsGetBySiteQuery } from '@api/queries/alerts/UnreadAlertsGetBySiteQuery';
import NoResultsError from '@components/core/NoResultsError';
import DetailsPanel from '@pages/site/alerts/components/DetailsPanel';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import AlertRulesGetBySiteQuery from '@api/queries/alert-rules/AlertRulesGetBySiteQuery';
import { Alert, AlertStatus } from '@api/models/Alert';
import { BulkAlertUpdateCommand } from '@api/queries/alerts/BulkAlertUpdateCommand';
import { Button } from '@components/core/Button';
import { Loading } from '@components/core/Loading';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Title } from '@components/core/Title';
import { Modal } from '@components/core/Modal';
import { Table } from '@components/table/Table';
import { ITableColumn } from '@components/table/Table.types';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useModal } from '@hooks/useModal';
import { formatDistanceToNowStrict } from 'date-fns';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { useSiteContext } from '@pages/site/SiteProvider';

const AlertsPageV2 = () => {
  const { t } = useTranslation(['common'])
  const { site } = useSiteContext();
  const { execute } = useApi();
  const onMobile = useMediaQuery('(max-width: 1400px)');
  const theme = useTheme();
  const [selectedAlerts, setSelectedAlerts] = useState<Alert[]>([]);
  const [openAlert, setOpenAlert] = useState<Alert>();
  const [isClickedAll, setIsClickedAll] = useState(true);
  const [isClickedUnread, setIsClickedUnread] = useState(false);
  const [alertsToRender, setAlertsToRender] = useState<Alert[]>([]);
  const [resetTable, setResetTable] = useState(false);
  const alertIconHeight = 48;
  const alertContainerHeight = 600;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const { isOpen, toggle, ref: modalRef } = useModal({});

  const { data: alertCount, execute: refreshAlertCount } = useApiState({
    query: new AlertCountBySiteGetQuery(site.id),
    initialState: 0,
  }, []);

  const { data: unreadAlertCount, execute: refreshUnreadAlertCount } = useApiState({
    query: new UnreadAlertCountBySiteGetQuery(site.id),
    initialState: 0,
  }, []);

  const { transform: alertsAll, loading: loadingAll, execute: refreshAllAlerts } = useApiState({
    query: isClickedAll ? new AlertsGetBySiteQuery(site.id, page, pageSize) : new UnreadAlertsGetBySiteQuery(site.id, page, pageSize),
    transformFunc: alerts => alerts,
    initialTransformState: [],
  }, [site, alertCount, unreadAlertCount, page, pageSize, isClickedAll, isClickedUnread]);

  const { data: alertingRules } = useApiState({
    query: new AlertRulesGetBySiteQuery(site.id),
  }, [site]);

  const handleGetAllAlerts = () => {
    setIsClickedAll(true)
    setIsClickedUnread(false)
  }

  const handleGetUnreadAlerts = () => {
    setIsClickedUnread(true)
    setIsClickedAll(false)
  }

  useEffect(() => {
    setAlertsToRender(alertsAll)
  }, [alertsAll]);

  const markAlertsAsRead = async () => {
    const ids = selectedAlerts.map((alert) => {
      return alert.id;
    });

    await execute({
      query: new BulkAlertUpdateCommand(ids, AlertStatus.Acknowledged),
    });

    refreshAllAlerts();
    refreshAlertCount();
    refreshUnreadAlertCount();
    setSelectedAlerts([]);
    setIsClickedAll(true);
    setIsClickedUnread(false);
    setResetTable(false);
  };

  const findAlertById = (id: number): Alert => {
    return alertsAll.filter(x => x.id === id)[0]
  }

  const tableColumns: ITableColumn<Alert>[] = useMemo(() => ([
    {
      label: t('RuleName', { ns: 'common' }),
      key: 'message',
      displayFormat: alert => alert.message.ruleName,
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'space',
      displayFormat: alert => alert.space.floor.building.name,
    },
    {
      label: t('Space', { ns: 'common' }),
      key: 'spaceId',
      displayFormat: alert => alert.space.name,
    },
    {
      label: t('AlertedOn', { ns: 'common' }),
      key: 'createdOn',
      displayFormat: alert => formatDistanceToNowStrict(new Date(alert.createdOn), { addSuffix: true, }),
    }
  ]), [t]);

  const pageSizeOptions = [
    { label: 'Show 10', value: 10 },
    { label: 'Show 20', value: 20 },
    { label: 'Show 50', value: 50 },
    { label: 'Show 250', value: 250 },
    { label: 'Show All', value: isClickedAll ? alertCount : unreadAlertCount }
  ];

  const renderTable = (): ReactNode => {
    return (
      <Table
        columns={tableColumns}
        records={alertsToRender}
        recordKey={'id'}
        cardEffect
        disableSorting
        fullHeightSubtractor={350}
        loading={loadingAll}
        onRowClick={(row) => {
          setOpenAlert(findAlertById(row.id))
          toggle()
        }}
        checkedRecords={alertsAll.filter(x => x.status === AlertStatus.New)}
        removeDefaultStyling={true}
        resetTable={resetTable}
        pageSizeOptions={pageSizeOptions}
        onPageSizeChange={(pagesize) => {
          setPageSize(pagesize);
        }}
        onPageChange={(page) => {
          setPage(page)
        }}
        getPagedRecords={async (tablePage, _, existingRecords) => {
          if (tablePage != page || existingRecords.length === 0) {
            setPage(tablePage);
            return alertsToRender;
          }
          return existingRecords;
        }}
        recordCount={isClickedAll ? alertCount : unreadAlertCount}
        selectable
        onSelect={(selected) => {
          setSelectedAlerts(selected)
        }}
        canSelectRecord={record =>
          record.status === AlertStatus.New
        }
      />
    )
  }

  const renderErrorComponent = (icon: IconProp, title: string, subTitle: string, iconHeight: number, containerHeight: number): ReactNode => {
    return (
      <NoResultsError
        noResultsError={{
          errorIcon: icon,
          errorTitle: title,
          errorSubtitle: subTitle
        }}
        customIconHeight={iconHeight}
        customContainerHeight={containerHeight}
      />
    )
  }

  return (
    <FullWidthContainer>
      <PaddedContainer>
        <FlexRow>
          <Title
            size='lg'
            text={
              <TitleCountWrapper>
                {t('AlertsPageTitle', { ns: 'common' })}
                {unreadAlertCount > 0 &&
                  <ThemeColoredSpan>
                    {`\u00A0(${unreadAlertCount})`}
                  </ThemeColoredSpan>}
              </TitleCountWrapper>
            }
          />

          <ProtectedLink link={{ path: 'ruleslist', analytics: { action: 'alerts_list', category: 'alerts' } }}>
            <Button
              secondary
              label={t('ManageAlertRules', { ns: 'common' })}
            />
          </ProtectedLink>
        </FlexRow>

        {loadingAll &&
          <Loading
            fullWidthCentered
            message={t('LoadingData', { ns: 'molecules' })}
          />
        }

        {!loadingAll &&
          <FlexRow>
            <ButtonsWrapper>
              <Button
                tertiary
                label={'All'}
                onClick={handleGetAllAlerts}
                style={isClickedAll ? { background: `${theme.palette.buttons.hoverBackground}` } : { background: `${theme.palette.backgrounds.background}` }}
              />
              <Button
                tertiary
                label={'Unread'}
                onClick={handleGetUnreadAlerts}
                style={isClickedUnread ? { background: `${theme.palette.buttons.hoverBackground}` } : { background: `${theme.palette.backgrounds.background}` }}

              />
            </ButtonsWrapper>

            {selectedAlerts.length !== 0 &&
              <Button
                tertiary
                label={
                  <>
                    {t('MarkAsRead', { ns: 'common' })} {selectedAlerts.length > 0 &&
                      <ThemeColoredSpan>
                        {`\u00A0(${selectedAlerts.length})`}
                      </ThemeColoredSpan>}
                  </>
                }
                onClick={markAlertsAsRead}
              />
            }
          </FlexRow>
        }

        {alertingRules?.length === 0 && alertsAll.length === 0 && !loadingAll &&
          <>
            {renderErrorComponent((light('bell-slash')), t('Alerting.NoAlerts', { ns: 'molecules' }), t('Alerting.SeeManageAlertRules', { ns: 'molecules' }), alertIconHeight, alertContainerHeight)}
          </>
        }

        {alertsAll.length === 0 && alertingRules?.length !== 0 && !loadingAll &&
          <>
            {renderErrorComponent((light('bell')), t('Alerting.NoNewAlerts', { ns: 'molecules' }), t('Alerting.YouAreUpToDate', { ns: 'molecules' }), alertIconHeight, alertContainerHeight)}
          </>
        }

        {alertsAll.length !== 0 && alertsAll.filter(x => x.status === AlertStatus.New).length === 0 && isClickedAll === false && !loadingAll &&
          <>
            {renderErrorComponent((light('bell')), t('Alerting.NoNewAlerts', { ns: 'molecules' }), t('Alerting.YouAreUpToDate', { ns: 'molecules' }), alertIconHeight, alertContainerHeight)}
          </>
        }

        {((isClickedAll && alertCount > 0) || (isClickedUnread && unreadAlertCount > 0)) &&
          !(loadingAll && alertsAll.length === 0) && // If we've ever had data, don't lose the table state
          renderTable()
        }

        {openAlert &&
          <Modal
            width="935px"
            ref={modalRef}
            isOpen={isOpen}
            plainModal
          >
            <DetailsPanel
              alert={openAlert}
              close={() => setOpenAlert(undefined)}
              onMobile={onMobile}
            />
          </Modal>
        }

      </PaddedContainer>
    </FullWidthContainer >
  );
};

export default AlertsPageV2;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const ThemeColoredSpan = styled.span`
  color: ${p => p.theme.palette.primary};
`;

const TitleCountWrapper = styled.div`
  width: 110px;
  display: flex;
  justify-content: space-between;
`;

