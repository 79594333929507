import CarbonGenerationMixDto from '@api/models/CarbonGenerationMixDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class CarbonGenerationMixGetQuery extends GetQuery<CarbonGenerationMixDto> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/carbonIntensity/generationMix/${this.siteId}`;
  }
}
