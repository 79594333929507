import ServiceIntegrationAvailability from '@api/models/ServiceIntegrationAvailability';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class ServiceIntegrationExistsQuery extends GetQuery<ServiceIntegrationAvailability> {
  buildingId: number;

  constructor(id: number) {
    super();
    this.buildingId = id;
  }

  public targetUrl(): string {
    return `/serviceintegrations/serviceintegrationexists/${this.buildingId}`;
  }
}
