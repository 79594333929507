import { InteractiveDialog } from '@components/core/InteractiveDialog';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import InputFormField from '@pages/site/resident-app/components/common/InputFormField';
import { useSiteContext } from '@pages/site/SiteProvider';
import { Select } from '@components/select/Select';
import { DatePicker } from 'antd';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import en from 'antd/es/date-picker/locale/en_US';
import { HierarchyBuilding, HierarchySpace } from '@api/models/Hierarchy';
import { useApiState } from '@hooks/useApiState';
import { useApi } from '@hooks/useApi';
import { BuildingHierarchiesGetBySiteId } from '@api/queries/buildings/BuildingHierarchiesGetBySiteId';
import { isEndDateAfterStartDate } from '@pages/site/resident-app/ResidentAppUtils';
import ResidentAppFormErrorMessage from '@pages/site/resident-app/components/common/ResidentAppFormErrorMessage';
import UsersResidentAppCreateCommand from '@api/queries/resident-app/Users/UsersResidentAppCreateCommand';
import { dateToUtcDate } from '@utils/DateUtils';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import UsersResidentAppUpdateCommand from '@api/queries/resident-app/Users/UsersResidentAppUpdateCommand';
import { UserUpdate } from '@pages/site/resident-app/manage-users/ResidentApp_ManageUsers';
import Label from '@pages/site/resident-app/components/common/Label';

type UserForm = {
  firstName: string;
  lastName: string;
  email: string;
  building: {label: string, value: HierarchyBuilding};
  space: {label: string, value: HierarchySpace};
  tenancyStartDate: Dayjs;
  tenancyEndDate: Dayjs;
}

type UserFormModalProps = {
  user?: UserUpdate;
  modalRef: React.RefObject<HTMLDivElement>;
  isModalOpen: boolean;
  toggleModal: () => void;
  onRefresh: () => void;
}

const UserFormModal = ({user, modalRef, isModalOpen, toggleModal, onRefresh}: UserFormModalProps) => {
  const { t } = useTranslation(['molecules', 'common']);
  const methods = useForm<UserForm>();
  const { site } = useSiteContext();
  const { localisation } = useLocalisation();
  const { execute: createUser, loading: createUserLoading, error: createUserError } = useApi();
  const { execute: updateUser, loading: updateUserLoading, error: updateUserError } = useApi();
  const { data: buildings, loading } = useApiState({
    query: new BuildingHierarchiesGetBySiteId(site.id)
  }, []);

  const buildingSelectOptions = buildings ? buildings.map(x => ({
    label: x.name,
    value: x
  })) : [];

  const onSubmitForm = async (data: UserForm) => {
    // if invalid dates or space then return
    if (
      !isEndDateAfterStartDate(data.tenancyStartDate, data.tenancyEndDate) ||
      !getListOfSpacesInBuilding(data.building.value).find(
        (x) => x.name === methods.watch('space')?.value.name
      )
    ) {
      return;
    }

    if(user) {
      await updateUser({
        query: new UsersResidentAppUpdateCommand(
          user.id,
          data.firstName,
          data.lastName,
          data.space.value.id,
          site.id,
          dateToUtcDate(data.tenancyStartDate).toISOString(),
          dateToUtcDate(data.tenancyEndDate).toISOString(),
        ),
        successMessage: t('UpdateUserSuccess', {ns: 'status'}),
        errorMessage: t('UpdateUserError', {ns: 'status'}),
        pendingMessage: t('UpdateUserPending', {ns: 'status'}),
      });
    } else {
      await createUser({
        query: new UsersResidentAppCreateCommand(
          data.email,
          data.firstName,
          data.lastName,
          data.space.value.id,
          site.id,
          dateToUtcDate(data.tenancyStartDate).toISOString(),
          dateToUtcDate(data.tenancyEndDate).toISOString(),
        ),
        errorMessage: t('CreateUserError', {ns: 'status'}),
        pendingMessage: t('CreateUserPending', {ns: 'status'}),
        successMessage: t('CreateUserSuccess', {ns: 'status'}),
      });
    }

    if(!createUserError && !updateUserError) {
      onRefresh();
      onCloseModal();
    }
  };

  const getListOfSpacesInBuilding = (building?: HierarchyBuilding) => {
    if (!building) {
      return [];
    }

    return building.floors.flatMap((floor) => floor.spaces);
  };

  const onCloseModal = () => {
    methods.reset();
    toggleModal();
  };

  useEffect(() => {
    if(user) {
      const building = buildings ? buildings.find(x => x.name === user.buildingName) : undefined;
      const space = getListOfSpacesInBuilding(building).find(x => x.name === user.spaceName);

      methods.setValue('firstName', user.firstName ?? '');
      methods.setValue('lastName', user.lastName ?? '');
      methods.setValue('email', user.email ?? '');
      building && methods.setValue('building', {label: building.name, value: building});
      space && methods.setValue('space', {label: space.name, value: space});
      methods.setValue('tenancyStartDate', dayjs(user.startTenancy));
      methods.setValue('tenancyEndDate', dayjs(user.endTenancy));
    } 
  }, [buildings, methods, user]);

  return (
    <InteractiveDialog
      width="320px"
      title={user ? t('ResidentApp.EditUser') : t('ResidentApp.AddUser')}
      isOpen={isModalOpen}
      modalRef={modalRef}
      hide={onCloseModal}
      confirmButton={{
        label: user ? t('Update', {ns: 'common'}) : t('Add', { ns: 'common' }),
        onClick: methods.handleSubmit(onSubmitForm),
        disabled: loading || createUserLoading || updateUserLoading,
      }}
      cancelButton={{ label: t('Cancel', { ns: 'common' }), onClick: onCloseModal, disabled: createUserLoading || updateUserLoading}}
      content={
        <FormProvider {...methods}>
          <UserForm>
            <div>
              <InputFormField
                inputName="firstName"
                label={t('ResidentApp.FirstName')}
                isRequired
                requiredErrorMessage={t('FirstNameRequired', { ns: 'validation' })}
              />
              {methods.formState.errors.firstName && (
                <ResidentAppFormErrorMessage
                  message={methods.formState.errors.firstName?.message}
                />
              )}
            </div>

            <div>
              <InputFormField
                inputName="lastName"
                label={t('ResidentApp.LastName')}
                isRequired
                requiredErrorMessage={t('LastNameRequired', { ns: 'validation' })}
              />
              {methods.formState.errors.lastName && (
                <ResidentAppFormErrorMessage message={methods.formState.errors.lastName?.message} />
              )}
            </div>

            <div>
              <InputFormField
                inputName="email"
                inputType='email'
                label={t('ResidentApp.Email')}
                isRequired
                requiredErrorMessage={t('EmailRequired', { ns: 'validation' })}
                readOnly={!!user}
                onValidate={(value) => value.includes('@') || t('InvalidEmail', { ns: 'validation' })}
              />
              {methods.formState.errors.email && (
                <ResidentAppFormErrorMessage message={methods.formState.errors.email?.message} />
              )}
            </div>

            <Controller
              name="building"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t('BuildingRequired', {ns: 'validation'}),
                },
              }}
              render={({ field: { ref, onChange, value } }) => (
                <div>
                  <Label text={t('ResidentApp.Building')} inputName="building" marginBottom/>
                  <StyledSelect
                    innerRef={ref}
                    options={buildingSelectOptions}
                    isSearchable
                    value={value}
                    onChange={onChange}
                  />
                  {methods.formState.errors.building && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.building?.message}
                    />
                  )}
                </div>
              )}
            />
            <Controller
              name="space"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t('SpaceRequired', {ns: 'validation'}),
                },
              }}
              render={({ field: { ref, onChange, value } }) => (
                <div>
                  <Label text={t('Space', {ns: 'common'})} inputName="space" marginBottom />
                  <StyledSelect
                    innerRef={ref}
                    options={getListOfSpacesInBuilding(methods.watch('building')?.value).map(
                      (x) => ({ label: x.name, value: x })
                    )}
                    isSearchable
                    onChange={onChange}
                    value={value}
                  />
                  {methods.formState.errors.space && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.space?.message}
                    />
                  )}
                  {!getListOfSpacesInBuilding(methods.watch('building')?.value).find(
                    (x) => x.name === methods.watch('space')?.value.name
                  ) && methods.getFieldState('space').isDirty && (
                    <ResidentAppFormErrorMessage message={t('ResidentApp.SpaceNotInBuilding')} />
                  )}
                </div>
              )}
            />
            <Controller
              name="tenancyStartDate"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t('TenancyStartDateRequired', {ns: 'validation'}),
                },
              }}
              render={({ field }) => (
                <div>
                  <Label
                    text={t('ResidentApp.TenancyStartDate')}
                    inputName="tenancyStartDate"
                    marginBottom
                  />
                  <StyledDatepicker
                    {...field}
                    locale={{
                      lang: {
                        ...en.lang,
                        fieldDateFormat: localisation.dateFormats.datepickerField,
                      },
                      timePickerLocale: { ...en.timePickerLocale },
                    }}
                    placeholder={localisation.dateFormats.datepickerField.toLowerCase()}
                  />
                  {methods.formState.errors.tenancyStartDate && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.tenancyStartDate?.message}
                    />
                  )}
                </div>
              )}
            />
            <Controller
              name="tenancyEndDate"
              control={methods.control}
              rules={{
                required: {
                  value: true,
                  message: t('TenancyEndDateRequired', {ns: 'validation'}),
                },
              }}
              render={({ field }) => (
                <div>
                  <Label
                    text={t('ResidentApp.TenancyEndDate')}
                    inputName="tenancyEndtDate"
                    marginBottom
                  />
                  <StyledDatepicker
                    {...field}
                    locale={{
                      lang: {
                        ...en.lang,
                        fieldDateFormat: localisation.dateFormats.datepickerField,
                      },
                      timePickerLocale: { ...en.timePickerLocale },
                    }}
                    placeholder={localisation.dateFormats.datepickerField.toLowerCase()}
                  />
                  {methods.formState.errors.tenancyEndDate && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.tenancyEndDate?.message}
                    />
                  )}
                  {!isEndDateAfterStartDate(
                    methods.watch('tenancyStartDate'),
                    methods.watch('tenancyEndDate')
                  ) &&
                    (methods.getFieldState('tenancyEndDate').isDirty || methods.getFieldState('tenancyStartDate').isDirty) && (
                    <ResidentAppFormErrorMessage
                      message={t('StartDateBeforeEndDate', {ns: 'validation'})}
                    />
                  )}
                </div>
              )}
            />
          </UserForm>
        </FormProvider>
      }
    />
  );
}

export default UserFormModal;

const UserForm = styled.form`
  display: grid;
  gap: 20px;
`;

const StyledDatepicker = styled(DatePicker)`
  background-color: ${(p) => p.theme.action.filled};
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  border-radius: 5px;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};
  width: 100%;
`;

const StyledSelect = styled(Select)`
  background-color: ${(p) => p.theme.action.filled};
`;