import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { SiteFairUsePolicy } from '@api/models/SiteFairUsePolicy';
import { SiteFairUseSeasonalityAdjustment } from '@api/models/SiteFairUseSeasonalityAdjustment';
import { SpaceTypeFairUsePolicy } from '@api/models/SpaceTypeFairUsePolicy';
import { round } from '../../../utils/NumberUtils';
import { FairUseForm } from '@api/models/FairUseForm';
import { SpaceFairUsePolicy } from '@api/models/SpaceFairUsePolicy';

/**
 * Get the total allowance for a fair use policy
 */
export const getTotalAllowance = (spaceTypeFairUsePolicies: SpaceTypeFairUsePolicy[], energyMeterType: EnergyMeterType): number => {
  const filteredSpaceTypeFairUsePolicies = spaceTypeFairUsePolicies.filter((spaceTypeFairUsePolicy: SpaceTypeFairUsePolicy) => spaceTypeFairUsePolicy.meterType === energyMeterType);
  const totalValueMap = filteredSpaceTypeFairUsePolicies.map((spaceTypeFairUsePolicy: SpaceTypeFairUsePolicy) => spaceTypeFairUsePolicy.allowance * spaceTypeFairUsePolicy.occurrences);
  const total = totalValueMap.length > 0 ? totalValueMap.reduce((partialSum: number, value: number) => partialSum + value) : 0;
  return round(total / 1000, 2)
}

/**
 * Get the remaining seasonality percentage for a fair use policy
 */
export const getRemainingPercentage = (seasonalityAdjustments: SiteFairUseSeasonalityAdjustment[], energyMeterType: EnergyMeterType): number => {
  const filterSeasonalityAdjustments = seasonalityAdjustments.filter((seasonalityAdjustment: SiteFairUseSeasonalityAdjustment) => seasonalityAdjustment.meterType === energyMeterType);
  const totalPercentage = 100;
  const calculatedPercentage = filterSeasonalityAdjustments.reduce((partialSum: number, value: SiteFairUseSeasonalityAdjustment) => partialSum + value.adjustment, 0);
  return totalPercentage - calculatedPercentage;
}

/**
 * Populate default values for fair use form
 */
export const getDefaultValues = (siteFairUsePolicy: SiteFairUsePolicy, editMode: boolean, energyMeterType: EnergyMeterType, exceptions?: SpaceFairUsePolicy[]): FairUseForm => {
  return {
    editMode: editMode,
    total: getTotalAllowance(siteFairUsePolicy.spaceTypeFairUsePolicies, energyMeterType),
    remainingPercentage: getRemainingPercentage(siteFairUsePolicy.seasonalityAdjustments, energyMeterType),
    energyMeterType: energyMeterType,
    spaceTypePolicies: siteFairUsePolicy.spaceTypeFairUsePolicies,
    seasonalityAdjustments: siteFairUsePolicy.seasonalityAdjustments,
    start: siteFairUsePolicy.start,
    id: siteFairUsePolicy.id,
    exceptions: exceptions
  }
}