import { BuildingHierarchiesWithDevicesGetBySiteIdQuery } from '@api/queries/buildings/BuildingHierarchiesWithDevicesGetBySiteIdQuery';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RangeStatus } from '@api/enums/RangeStatus';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { DeviceIssuesGetBySiteIdQuery } from '@api/queries/climate-control/Device/DeviceIssuesGetBySiteIdQuery';
import { SpaceClimateControlDetailsGetBySiteQuery } from '@api/queries/climate-control/Space/SpaceClimateControlDetailsGetBySiteQuery';
import { Select } from '@components/select/Select';
import { useApiState } from '@hooks/useApiState';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import styled, { css, useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import CSVExport from './CSVExport';
import DeviceIssuesTable from './DeviceIssuesTable';
import SpacesTable from './SpacesTable';
import TableSkeletonLoader from './TableSkeletonLoader';
import { TableTab, TempControlTabType } from '../types/TableTab';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { RouteLinkAnalytics } from '@src/types/Route';
import { useSiteContext } from '@pages/site/SiteProvider';
import HeatingSchedulesGetBySiteIdQuery from '@api/queries/heating-schedules/HeatingSchedulesGetBySiteIdQuery';
import HeatingSchedulesTable from './HeatingSchedulesTable';
import { SiteLevelFeatureFlag } from '@api/enums/SiteLevelFeatureFlag';

type TableTabsProps = {
  siteClimateControl: SiteClimateControlDto;
  refreshSiteClimateControl: () => void;
}

const TableTabs = ({ siteClimateControl, refreshSiteClimateControl }: TableTabsProps) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { hasSiteFeature } = useSiteContext();
  const [selected, setSelected] = useState<number>(0);
  const mediumScreen = useMediaQuery('(max-width: 900px)');
  const theme = useTheme();
  const { data: spaceClimateControlDetails, loading: loadingSpaces } = useApiState({
    query: new SpaceClimateControlDetailsGetBySiteQuery(siteClimateControl.siteId),
    initialState: [],
  }, [siteClimateControl]);
  const { data: deviceIssues, loading: loadingDeviceIssues } = useApiState({
    query: new DeviceIssuesGetBySiteIdQuery(siteClimateControl.siteId),
    initialState: [],
  }, [siteClimateControl]);
  const { data: buildings } = useApiState({
    query: new BuildingHierarchiesWithDevicesGetBySiteIdQuery(siteClimateControl.siteId),
    initialState: [],
  }, []);
  const { data: heatingSchedules, loading: loadingHeatingSchedules } = useApiState({
    query: hasSiteFeature(SiteLevelFeatureFlag.HeatingSchedules) ? new HeatingSchedulesGetBySiteIdQuery(siteClimateControl.siteId) : undefined,
    initialState: [],
  }, [hasSiteFeature]);

  const tabs: TableTab[] = [
    {
      type: TempControlTabType.Spaces,
      label: t('AllSpaces', { ns: 'common' }),
      data: spaceClimateControlDetails,
      analytics: { action: 'list_all_spaces', category: 'climate_control' },
      component: (
        <SpacesTable
          tableData={spaceClimateControlDetails}
          buildings={buildings}
          siteClimateControl={siteClimateControl}
          refreshSiteClimateControl={refreshSiteClimateControl}
        />
      )
    },
    {
      type: TempControlTabType.Spaces,
      label: t('Overheated', { ns: 'common' }),
      icon: solid('chevrons-up'),
      iconColor: theme.error.main,
      data: spaceClimateControlDetails.filter(x => x.rangeStatus === RangeStatus.Over),
      analytics: { action: 'list_overheated_spaces', category: 'climate_control' },
      component: (
        <SpacesTable
          tableData={spaceClimateControlDetails.filter(x => x.rangeStatus === RangeStatus.Over)}
          buildings={buildings}
          siteClimateControl={siteClimateControl}
          emptyListLabel={t('NoOverheatedSpacesMessage', { ns: 'status' })}
          refreshSiteClimateControl={refreshSiteClimateControl}
        />
      )
    },
    {
      type: TempControlTabType.Spaces,
      label: t('Underheated', { ns: 'common' }),
      icon: solid('chevrons-down'),
      iconColor: theme.complimentary.blue.main,
      data: spaceClimateControlDetails.filter(x => x.rangeStatus === RangeStatus.Under),
      analytics: { action: 'list_underheated_spaces', category: 'climate_control' },
      component: (
        <SpacesTable
          tableData={spaceClimateControlDetails.filter(x => x.rangeStatus === RangeStatus.Under)}
          buildings={buildings}
          siteClimateControl={siteClimateControl}
          emptyListLabel={t('NoUnderheatedSpacesMessage', { ns: 'status' })}
          refreshSiteClimateControl={refreshSiteClimateControl}
        />
      )
    },
    {
      type: TempControlTabType.DeviceIssues,
      label: t('ClimateControl.DeviceIssues.Label', { ns: 'molecules' }),
      icon: solid('triangle-exclamation'),
      iconColor: theme.warning.main,
      loading: loadingDeviceIssues,
      data: deviceIssues,
      analytics: { action: 'list_device_issues', category: 'climate_control' },
      component: (
        <DeviceIssuesTable
          tableData={deviceIssues}
          buildings={buildings}
          emptyListLabel={t('NoDeviceIssuesMessage', { ns: 'status' })}
        />
      )
    },
    {
      type: TempControlTabType.HeatingSchedules,
      label: t('Schedules', { ns: 'common' }),
      icon: solid('timer'),
      iconColor: theme.primary.main,
      loading: loadingHeatingSchedules,
      data: heatingSchedules,
      analytics: { action: 'list_heating_schedules', category: 'climate_control' },
      component: (
        <HeatingSchedulesTable
          tableData={heatingSchedules}
          buildings={buildings}
          emptyListLabel={t('NoHeatingSchedulesMessage', { ns: 'status' })}
        />
      ),
      siteLevelFeatureFlag: SiteLevelFeatureFlag.HeatingSchedules
    }
  ];

  const tabSelectOptions = tabs?.map((x, index) => ({ value: index.toString(), label: x.label, analytics: x.analytics }));

  if (loadingSpaces) {
    return <TableSkeletonLoader />;
  }

  return (
    <StyledCard>
      <Header>
        {mediumScreen ?
          <StyledSelect>
            <Select
              options={tabSelectOptions}
              value={tabSelectOptions?.find(x => x.value === selected.toString())}
              onChange={(selected: SingleValue<{ label: string, value: string, analytics: RouteLinkAnalytics } | undefined>) => {
                if (selected) {
                  setSelected(parseInt(selected.value));
                  trackAction(selected.analytics.action, selected.analytics.category);
                }
              }}
            />
          </StyledSelect> :
          <Tabs>
            {tabs?.map((tab, i) => {
              if (tab.siteLevelFeatureFlag && !hasSiteFeature(tab.siteLevelFeatureFlag)) {
                return false;
              }

              return (
                <Tab
                  key={uuidv4()}
                  selected={selected === i}
                  onClick={() => {
                    if (!tab.loading) {
                      setSelected(i);
                      trackAction(tab.analytics.action, tab.analytics.category);
                    }
                  }}
                  disabled={tab.loading}
                >
                  {(tab.icon && tab.iconColor) &&
                    <Icon icon={tab.icon} color={tab.iconColor} />
                  }
                  <Label>{tab.label} {tab.loading ? <LoadingSpinner icon={solid('spinner')} className="fa-pulse" /> : `(${tab.data.length})`}</Label>
                </Tab>
              );
            })}
          </Tabs>
        }

        {tabs && spaceClimateControlDetails.length > 0 &&
          <CSVExport
            data={tabs}
          />
        }
      </Header>

      {tabs?.map((tab, i) => (
        <Content
          key={uuidv4()}
          selected={selected === i}
        >
          {tab.component}
        </Content>
      ))}
    </StyledCard>
  )
}

export default TableTabs;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${p => p.theme.action.divider};
  background-color: ${(p) => p.theme.background.container};
  box-shadow: 0px 3px 8px ${(p) => p.theme.palette.shadows.medium};
  margin: 40px;
  padding: 25px 30px;
  gap: 20px;

  @media (max-width: 600px) {
    padding: 25px;
  }

  @media (max-width: 375px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 375px) {
    gap: 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 12px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
`;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

const Tab = styled.div<{ selected: boolean, disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 8px;
  transition: all 150ms ease;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  border-radius: 4px;

  ${p => p.selected && css`
    background-color: ${p => p.theme.primary.background};

    ${Label} {
      color: ${p => p.theme.primary.main};
    }
  `}

  ${p => p.disabled && css`
    opacity: 0.6;
    cursor: unset;
  `}
`;

const Content = styled.div<{ selected: boolean }>`
  display: none;

  ${p => p.selected && css`
    display: inline;
  `}
`;

const StyledSelect = styled.div`
  width: 100%;
`;

const LoadingSpinner = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 2px;
`;