import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ClimateControlStatus } from '@api/enums/ClimateControlStatus';
import { TRVControlMode } from '@api/enums/TRVControlMode';
import { FeatureFlag } from '@api/enums/FeatureFlag';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { Site } from '@api/models/Site';
import SiteClimateControlCreateCommand from '@api/queries/climate-control/Site/SiteClimateControlCreateCommand';
import SiteClimateControlUpdateCommand from '@api/queries/climate-control/Site/SiteClimateControlUpdateCommand';
import { BaseQuery } from '@api/queries/common/BaseQuery';
import { WarningDialog } from '@components/core/WarningDialog';
import { useApi } from '@hooks/useApi';
import { useModal } from '@hooks/useModal';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SiteFeature from '../SiteFeature';

type ClimateControlFeatureProps = {
  site: Site;
}

const ClimateControlFeature = ({ site }: ClimateControlFeatureProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const { hasFeatureFlag } = useTenantContext();
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();
  const { isOpen: isDialogOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSubVisible, setIsSubVisible] = useState(false);
  const [isSubEnabled, setIsSubEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(!!siteClimateControl && siteClimateControl.status !== ClimateControlStatus.Disabled);
    setIsSubVisible(!!siteClimateControl && hasFeatureFlag(FeatureFlag.ClimateControlV2ESM))
    setIsSubEnabled(!!siteClimateControl && siteClimateControl.externalSensorModeEnabled);
  }, [siteClimateControl, hasFeatureFlag]);

  const handleChange = (enabled: boolean) => {
    if (enabled) {
      setIsEnabled(enabled);
      createOrUpdateSiteClimateControl(enabled ? ClimateControlStatus.Setup : ClimateControlStatus.Disabled);
    } else {
      toggleDialog();
    }
  };

  const createOrUpdateSiteClimateControl = async (status: ClimateControlStatus) => {
    let query: BaseQuery<SiteClimateControlDto> | undefined = undefined;

    if (!siteClimateControl) {
      query = new SiteClimateControlCreateCommand(site.id);
    } else {
      query = new SiteClimateControlUpdateCommand({ ...siteClimateControl, status: status });
    }

    await execute({
      query: query,
      successMessage: t('EnableClimateControlSuccess', { ns: 'status' }),
      errorMessage: t('EnableClimateControlError', { ns: 'status' }),
      pendingMessage: t('EnableClimateControlPending', { ns: 'status' })
    });

    refreshSiteClimateControl();
  };

  const handleExternalSensorModeToggle = async (externalSensorModeEnabled: boolean) => {
    if (!siteClimateControl || !isSubVisible) {
      return;
    }

    await execute({
      query: new SiteClimateControlUpdateCommand({ ...siteClimateControl, externalSensorModeEnabled, controlMode: externalSensorModeEnabled ? siteClimateControl.controlMode : TRVControlMode.TRV }),
      successMessage: t('UpdateClimateControlSuccess', { ns: 'status' }),
      errorMessage: t('UpdateClimateControlError', { ns: 'status' }),
      pendingMessage: t('UpdateClimateControlPending', { ns: 'status' })
    });

    refreshSiteClimateControl();
  };

  const disableClimateControl = async () => {
    if (!siteClimateControl) {
      return;
    }

    await execute({
      query: new SiteClimateControlUpdateCommand({ ...siteClimateControl, status: ClimateControlStatus.Disabled }),
      successMessage: t('DisableClimateControlSuccess', { ns: 'status' }),
      errorMessage: t('DisableClimateControlError', { ns: 'status' }),
      pendingMessage: t('DisableClimateControlPending', { ns: 'status' })
    });

    toggleDialog();
    refreshSiteClimateControl();
  };



  return (
    <>
      <WarningDialog
        modalRef={dialogRef}
        isOpen={isDialogOpen}
        sectionOne={t('ClimateControl.DisableClimateControlDialog', { ns: 'molecules' })}
        confirmButton={t('Disable', { ns: 'common' })}
        onCancel={toggleDialog}
        onConfirm={disableClimateControl}
      />

      <SiteFeature
        name={t('ClimateControl.TemperatureControl', { ns: 'molecules' })}
        description={t('ClimateControl.FeatureDescription', { ns: 'molecules' })}
        icon={solid('temperature-half')}
        isEnabled={isEnabled}
        onChange={handleChange}
      />
      {isEnabled && isSubVisible &&
        <SubFeature>
          <SiteFeature
            name={t('ClimateControl.ExternalSensor', { ns: 'molecules' })}
            description={t('ClimateControl.ExternalSensorFeatureDescription', { ns: 'molecules' })}
            icon={solid('temperature-half')}
            isEnabled={isSubEnabled}
            onChange={handleExternalSensorModeToggle}
          />
        </SubFeature>
      }
    </>
  );
};

export default ClimateControlFeature;

const SubFeature = styled.div`
  padding-left: 25px;
`;