import { NoteAttachmentDto } from '@api/models/NoteAttachmentDto';
import { PostFormDataQuery } from '@api/queries/common/PostFormDataQuery';

export class NoteAttachmentUploadCommand extends PostFormDataQuery<NoteAttachmentDto> {

  constructor(noteId: number, file: File) {
    const formData = new FormData();
    formData.append('NoteId', noteId.toString());
    formData.append('File', file)

    super(formData);
  }

  public targetUrl(): string {
    return '/notes/uploadAttachment';
  }
}