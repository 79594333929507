import ExportCsvDto from '@api/models/ExportCsvDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class CsvGatewayDevicesGetQuery extends GetQuery<ExportCsvDto> {
  buildingId: number;

  constructor(buildingId: number) {
    super();
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return `/exports/CsvGatewayDevices/${this.buildingId}`;
  }
}