import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MetricPerformanceMeter from './MetricPerformanceMeter';
import ReportMetricType, { formatMetric, getReportMetricScale } from '@api/enums/ReportMetricType';
import { ReportSpaceTypePerformanceMetricDto } from '@api/models/ReportSpaceTypePerformanceDto';
import { round } from '@utils/NumberUtils';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';

interface IMetricPerformance {
  metric: ReportSpaceTypePerformanceMetricDto;
  inPrintMode: boolean;
}

const MetricPerformance = ({ metric, inPrintMode }: IMetricPerformance) => {
  const { t } = useTranslation(['enums']);
  const { getUnit } = useLocalisation();
  const scale = getReportMetricScale(metric.metricType);
  const formattedMetric = formatMetric(metric);
  // The dto form the API returns zero values when there is no data so for now we can assume that when all three values are zero, there was no data.
  const noData = formattedMetric.average === 0 && formattedMetric.minimum === 0 && formattedMetric.maximum === 0;

  return (
    <FlexRow>
      <IconWrapper color={ReportMetricType.getColor(formattedMetric.metricType)}>
        {ReportMetricType.getIcon(formattedMetric.metricType)}
      </IconWrapper>
      <Label faint={noData}>
        {t(formattedMetric.metricType, { ns: 'enums' })}
      </Label>
      <MetricPerformanceMeter
        scaleMin={scale[0] ?? 0}
        scaleMax={scale[scale.length - 1] ?? 0}
        min={round(formattedMetric.minimum, 1)}
        mean={round(formattedMetric.average, 1)}
        max={round(formattedMetric.maximum, 1)}
        scaleLabels={scale}
        scaleLabelSuffix={getUnit(ReportMetricType.getMetricType(formattedMetric.metricType))}
        inPrintMode={inPrintMode}
        noData={noData}
        metricType={ReportMetricType.getMetricType(formattedMetric.metricType)}
      />
    </FlexRow>
  );
};

export default MetricPerformance;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  page-break-inside: avoid;
`;

const IconWrapper = styled.div<{ color: string }>`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px rgb(0 0 0 / 14%);
  background-color: ${p => transparentize(0.75, p.color)};
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
    fill: ${p => p.color};
  }

  @media print {
    width: 25px;
    height: 25px;
    margin-right: 8px;
    box-shadow: 0px 3px 4px -1px rgb(0 0 0 / 14%);

    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

const Label = styled.div<{ faint: boolean }>`
  font-size: 14px;
  width: 110px;
  flex-shrink: 0;
  color: ${p => p.faint ? p.theme.palette.text.weak : p.theme.palette.text.medium};
  
  @media print {
    font-size: 10px;
    width: 80px;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;