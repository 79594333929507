import { Select } from '@components/select/Select';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { transparentize } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import styled, { css } from 'styled-components';
import { Option } from '@src/types/Option'

interface IPaging {
  numItems: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (page: number) => void;
  compact?: boolean;
  pageSizeOptions?: Option[];
  small?: boolean;
}

export const Paging = React.memo(({ numItems, onPageChange, onPageSizeChange, compact: compactProp, small, pageSizeOptions }: IPaging) => {
  const onMobile = useMediaQuery('(max-width: 600px)');
  const compact = compactProp ?? onMobile;
  const options = useMemo(() => pageSizeOptions ?? [
    { label: 'Show 10', value: 10 },
    { label: 'Show 20', value: 20 },
    { label: 'Show 50', value: 50 },
    { label: 'Show All', value: numItems }
  ], [pageSizeOptions, numItems]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(options[0].value);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  const changePage = useCallback((value: number) => {
    setPage(value);
    onPageChange(value);
  }, [onPageChange]);

  useEffect(() => {
    const newPageCount = numItems === 0 ? 0 : Math.ceil(numItems / pageSize);
    setPageCount(newPageCount);
    setPageNumbers(Array.from(Array(newPageCount).keys()));
    changePage(0);
  }, [numItems, pageSize, setPageCount, setPageNumbers, onPageSizeChange, changePage]);

  const changePageSize = (value: number) => {
    setPage(0);
    setPageSize(value);
    onPageChange(0);
    onPageSizeChange(value);
  };

  const pageNumberOptions = pageNumbers.map((pageNumber) => {
    return {
      label: `${pageNumber + 1}`,
      value: pageNumber,
    };
  });


  return (
    <div>
      <PagingWrapper small={small}>
        {!compact && (
          <IconWrapper
            key={'PageNumberFirstPage'}
            disabled={page === 0}
            onClick={() => changePage(0)}
            small={small}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconWrapper>
        )}
        <IconWrapper
          key={'PageNumberPreviousPage'}
          disabled={page === 0}
          onClick={() => changePage(Math.max(page - 1, 0))}
          small={small}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconWrapper>
        <PageSelect>
          <Select
            isSearchable={false}
            defaultValue={pageNumberOptions[0]}
            value={pageNumberOptions[page]}
            onChange={(selected: SingleValue<Option>) => selected && changePage(selected.value)}
            options={pageNumberOptions}
            menuPlacement="top"
            placeholder=''
            small={small}
          />
        </PageSelect>
        <IconWrapper
          key={'PageNumberNextPage'}
          disabled={page === pageCount - 1}
          onClick={() => changePage(Math.min(page + 1, pageCount - 1))}
          small={small}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconWrapper>
        {!compact && (
          <IconWrapper
            key={'PageNumberLastPage'}
            disabled={page === pageCount - 1}
            onClick={() => changePage(pageCount - 1)}
            small={small}
          >
            <FontAwesomeIcon icon={faChevronRight} />
            <FontAwesomeIcon icon={faChevronRight} />
          </IconWrapper>
        )}
        <PageSizeSelect>
          <Select
            isSearchable={false}
            defaultValue={options[0]}
            onChange={(selected: SingleValue<Option>) => selected && changePageSize(selected.value)}
            options={options}
            menuPlacement="top"
            small={small}
          />
        </PageSizeSelect>
      </PagingWrapper>
    </div>
  );
});

const PagingWrapper = styled.div<{ small?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${p => p.small ? '5px' : '10px'};

  text-align: center;

  /* On large screens */
  @media (min-width: 600px) {
    margin-left: auto;
  }

  vertical-align: middle;
`;

const IconWrapper = styled.div<{ disabled?: boolean, small?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;

  width: ${p => p.small ? '30px' : '38px'};
  height: ${p => p.small ? '30px' : '38px'};
  
  font-size: ${p => p.small ? '10px' : '14px'};
  color: ${(p) => p.theme.text.secondary};
  transition: all 150ms ease;

  box-sizing: border-box;
  background-color: ${(p) => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  border-radius: 5px;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};

  &:hover {
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }

  ${p => p.disabled && css`
    color: ${p => transparentize(0.2, p.theme.palette.forms.input.disabledText)};
    background-color: ${p => p.theme.palette.forms.input.disabledBackground};
    cursor: unset;

    &:hover {
      border-color: ${p => p.theme.palette.forms.input.border};
    }
  `}
`;

const PageSelect = styled.div`
  display: block;
  width: 80px;
`;

const PageSizeSelect = styled.div`
  display: block;
  width: 120px;
`;