import { exportCSV } from '@utils/ExportUtils';
import { MetricType } from '@api/enums/MetricType';
import { DeviceIssueDto } from '@api/models/DeviceIssueDto';
import { SpaceClimateControlDetailsDto } from '@api/models/SpaceClimateControlDetailsDto';
import { Button } from '@components/core/Button';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import { format, formatDistanceToNowStrict } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableTab, TempControlTabType } from '../types/TableTab';
import { HeatingScheduleDto } from '@api/models/HeatingScheduleDto';

const lineBreak = '\n';

type PropTypes = {
  data: TableTab[];
};

const CSVExport = ({ data }: PropTypes) => {
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  const { site, hasSiteFeature } = useSiteContext();
  const { getUnit, toLocale, localisation } = useLocalisation();
  const unit = getUnit(MetricType.Temperature);

  const getSpaceTableHeaders = useCallback(() => {
    return `${t('Space', { ns: 'common' })}, ${t('Floor', { ns: 'common' })}, ${t('Building', { ns: 'common' })}, ${t('Min', { ns: 'common' })}, ${t('Max', { ns: 'common' })}, ${t('DeviceTarget', { ns: 'common' })}, ${t('DeviceActual', { ns: 'common' })}, ${t('Multisensor', { ns: 'common' })}, ${t('Status', { ns: 'common' })}, ${t('MotionMeasuredOn', { ns: 'enums' })}`;
  }, [t]);

  const getDeviceIssuesTableHeaders = useCallback(() => {
    return `${t('Device', { ns: 'common' })}, ${t('ClimateControl.Model', { ns: 'molecules' })}, ${t('Space', { ns: 'common' })}, ${t('Floor', { ns: 'common' })}, ${t('Building', { ns: 'common' })}, ${t('ClimateControl.DeviceIssues.IssueType', { ns: 'molecules' })}, ${t('ClimateControl.DeviceIssues.IssueRule', { ns: 'molecules' })}`;
  }, [t]);


  const getHeatingScheduleTableHeaders = useCallback(() => {
    return `${t('StartDate', { ns: 'common' })}, ${t('EndDate', { ns: 'common' })}, ${t('Target', { ns: 'common' })}, ${t('Space', { ns: 'common' })}, ${t('Floor', { ns: 'common' })}, ${t('Building', { ns: 'common' })}, ${t('ScheduleStatus', { ns: 'common' })}`;
  }, [t]);

  const getSpaceTableData = useCallback((tableData: SpaceClimateControlDetailsDto[]) => {
    const rows = tableData.map((x: SpaceClimateControlDetailsDto) => {
      const spaceName = x.isAnException ? x.spaceName + '-' + t('Exception', { ns: 'common' }) : x.spaceName;
      const minTemp = `${toLocale(MetricType.Temperature, x.minTemp, { round: 1 }).toString()} ${unit}`;
      const maxTemp = `${toLocale(MetricType.Temperature, x.maxTemp, { round: 1 }).toString()} ${unit}`;
      const targetTemp = x.targetTemp ? `${toLocale(MetricType.Temperature, x.targetTemp, { round: 1 }).toString()} ${unit}` : '-';
      const deviceTemp = x.deviceTemp ? `${toLocale(MetricType.Temperature, x.deviceTemp, { round: 1 }).toString()} ${unit}` : '-';
      const multisensorTemp = x.multisensorTemp ? `${toLocale(MetricType.Temperature, x.multisensorTemp, { round: 1 }).toString()} ${unit}` : '-';
      const lastMotionOn = x.lastMotionOn ? formatDistanceToNowStrict(new Date(x.lastMotionOn), { addSuffix: true, }) : t('OverNHoursAgo', { ns: 'common', hours: 24 });
      return `${spaceName}, ${x.floorName}, ${x.buildingName}, ${minTemp}, ${maxTemp}, ${targetTemp}, ${deviceTemp}, ${multisensorTemp}, ${x.deviceStatus}, ${lastMotionOn}`;
    });

    return rows.join(lineBreak) + lineBreak;
  }, [t, toLocale, unit]);

  const getDeviceIssuesTableData = useCallback((tableData: DeviceIssueDto[]) => {
    const rows = tableData.map((x: DeviceIssueDto) => {
      const issueType = t(`ClimateControl.DeviceIssues.IssueTypes.${x.issueType}`, { ns: 'molecules' });
      const issue = t(`ClimateControl.DeviceIssues.IssueRules.${x.issue}`, { ns: 'molecules' }).replace(/,/g, '');
      return `${x.friendlyName}, ${x.deviceModel}, ${x.spaceName}, ${x.floorName}, ${x.buildingName}, ${issueType}, ${issue}`;
    });
    return rows.join(lineBreak) + lineBreak;
  }, [t]);


  const getHeatingSchedulesTableData = useCallback((tableData: HeatingScheduleDto[]) => {
    const rows = tableData.map((x: HeatingScheduleDto) => {
      const startDate = format(new Date(x.startDateTime), localisation.dateFormats.default);
      const endDate = x.endDateTime ? format(new Date(x.endDateTime), localisation.dateFormats.default) : '--';
      const targetTemp = `${toLocale(MetricType.Temperature, x.targetTemp, { round: 1 })}${unit}`;
      const status = t(`ScheduleStatus.${x.status}`, { ns: 'enums' });
      return `${startDate}, ${endDate}, ${targetTemp}, ${x.spaceName}, ${x.floorName}, ${x.buildingName}, ${status}`;
    });

    return rows.join(lineBreak) + lineBreak;
  }, [t, toLocale, unit, localisation]);

  const getHeaders = useCallback((tab: TableTab): string => {
    switch (tab.type) {
      case TempControlTabType.Spaces:
        return getSpaceTableHeaders();
      case TempControlTabType.DeviceIssues:
        return getDeviceIssuesTableHeaders();
      case TempControlTabType.HeatingSchedules:
        return getHeatingScheduleTableHeaders();
      default:
        return '';
    }
  }, [getSpaceTableHeaders, getDeviceIssuesTableHeaders, getHeatingScheduleTableHeaders]);

  const getRowData = useCallback((tab: TableTab): string => {
    switch (tab.type) {
      case TempControlTabType.Spaces:
        return getSpaceTableData(tab.data as SpaceClimateControlDetailsDto[]);
      case TempControlTabType.DeviceIssues:
        return getDeviceIssuesTableData(tab.data as DeviceIssueDto[]);
      case TempControlTabType.HeatingSchedules:
        return getHeatingSchedulesTableData(tab.data as HeatingScheduleDto[]);
      default:
        return '';
    }
  }, [getSpaceTableData, getDeviceIssuesTableData, getHeatingSchedulesTableData]);

  const csvString = useMemo(() => {
    let csv = lineBreak;

    data.forEach(dto => {
      if (dto.siteLevelFeatureFlag && !hasSiteFeature(dto.siteLevelFeatureFlag)) {
        return;
      }

      csv += dto.label + lineBreak;
      csv += getHeaders(dto) + lineBreak;
      csv += getRowData(dto);
      csv += lineBreak;
    });

    return csv;
  }, [data, getHeaders, getRowData, hasSiteFeature]);

  const downloadFile = useCallback(async () => {
    const fileName = `${tenant.name}_${site.name}_${t('ClimateControl.TemperatureControl', { ns: 'molecules' }).replace(/ /g, '_')}_${(new Date).getFullYear()}`;

    exportCSV(fileName, csvString);
  }, [csvString, site, tenant, t]);

  return (
    <Button
      secondary
      label={t('Export', { ns: 'common' })}
      onClick={() => downloadFile()}
      analytics={{ action: 'export', category: 'climate_control' }}
    />
  );
};

export default CSVExport;