import { DeviceGateway } from '@api/models/DeviceGateway';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DeviceGatewayGetByIdQuery extends GetQuery<DeviceGateway> {
  deviceGatewayId: number;

  constructor(id: number) {
    super();
    this.deviceGatewayId = id;
  }

  public targetUrl(): string {
    return `/gateway/${this.deviceGatewayId}`;
  }
}
