import EsgExplorerRuleDto from '@api/models/EsgExplorerRuleDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EsgExplorerRulesGetQuery extends PostQuery<EsgExplorerRuleDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return '/esgExplorer/rules';
  }
}
