import { Site } from '@api/models/Site';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  site: Site;
}

const HeatSourceLabel = ({ site }: PropTypes) => {
  const { t } = useTranslation();

  if (!site.metadata?.metadata.heatSources || site.metadata.metadata.heatSources.length === 0) {
    return null;
  }

  return (
    <Container>
      <Icon icon={solid('fire-flame-curved')} />

      {site.metadata.metadata.heatSources.map(source => (
        <Label key={source}>
          {t(`HeatSource.${source}`, { ns: 'enums' })} {t('Heating', { ns: 'common' })}
        </Label>
      ))}
    </Container>
  );
};

export default HeatSourceLabel;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px 9px;
  border-radius: 4px;
  background-color: ${p => p.theme.action.hover};

  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  color: ${p => p.theme.text.primary};
  text-transform: uppercase;
`;

const Label = styled.div`
  margin-bottom: -1px;

  &:not(:last-child)::after {
    content: ", ";
    white-space: pre;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.text.disabled};
  margin-right: 8px;
`;