import { PaddedContainer } from '@components/core/PaddedContainer'
import TitleWithButton from '@pages/site/resident-app/components/common/TitleWIthButton';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Title } from '@components/core/Title';
import { SearchField } from '@components/search-field/SearchField';
import { Button } from '@components/core/Button';
import IconWithText from '@pages/site/resident-app/components/common/IconWithText';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useApiState } from '@hooks/useApiState';
import { UsersResidentAppGetAllBySiteQuery } from '@api/queries/resident-app/Users/UsersResidentAppGetAllBySiteId';
import { useSiteContext } from '@pages/site/SiteProvider';
import { ITableColumn } from '@components/table/Table.types';
import TableActionButton from '@pages/site/resident-app/components/common/TableActionButton';
import { format } from 'date-fns';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { Loading } from '@components/core/Loading';
import { Table } from '@components/table/Table';
import { useState } from 'react';
import { includesCI } from '@utils/StringUtils';
import { InteractiveDialog } from '@components/core/InteractiveDialog';
import { useModal } from '@hooks/useModal';
import { useDropzone } from 'react-dropzone';
import { FileDropzone } from '@components/core/FileDropzone';
import { downloadFile } from '@utils/DownloadUtils';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';
import { useApi } from '@hooks/useApi';
import { BlobGetSasUriQuery } from '@api/queries/blob/BlobGetSasUriQuery';
import { parseCsv, trimAndSplitCsvLines } from '@pages/site/buildings/settings/building/components/CsvImport/CsvUtils';
import { mapStringArrayToResidentCsvObject } from '@pages/site/resident-app/ResidentAppUtils';
import CsvPreview from '@pages/site/resident-app/components/users/CsvPreview';
import ResidentAppFormErrorMessage from '@pages/site/resident-app/components/common/ResidentAppFormErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserFormModal from '@pages/site/resident-app/components/users/UserFormModal';
import { UserDto } from '@api/models/UserDto';
import UsersResidentAppDeleteCommand from '@api/queries/resident-app/Users/UsersResidentAppDeleteCommand';

export type ResidentCsvObject = {
  key: string;
  buildingName: string;
  spaceName: string;
  email: string;
  firstName: string;
  lastName: string;
  startTenancy: string;
  endTenancy: string;
}

type UserTableItem = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  building: string;
  spaceName: string;
  startTenancy: string;
  endTenancy: string;
  tenancyDuration: string;
}

export interface UserUpdate extends UserDto {
  id: number;
}

const ResidentApp_ManageUsers = () => {
  const { t } = useTranslation('molecules');
  const { site } = useSiteContext();
  const { localisation } = useLocalisation();
  const { execute: executeBlobUri, loading: isBlobUriLoading } = useApi();
  const { execute: deleteUser, loading: isDeleteUserLoading, error: isDeleteUserError } = useApi();
  const { isOpen: uploadCsvModalIsOpen, toggle: toggleUploadCsvModal, ref: refUploadCsvModal } = useModal({});
  const { isOpen: csvPreviewModalIsOpen, toggle: toggleCsvPreviewModal, ref: refCsvPreviewModal } = useModal({});
  const { isOpen: addUserModalIsOpen, toggle: toggleAddUserModal, ref: refAddUserModal } = useModal({disableCloseOnClickOutside: true});
  const { isOpen: editUserModalIsOpen, toggle: toggleEditUserModal, ref: refEditUserModal } = useModal({disableCloseOnClickOutside: true});

  const [csvFile, setCsvFile] = useState<File>();
  const [csvObject, setCsvObject] = useState<ResidentCsvObject[] | undefined>();
  const [search, setSearch] = useState('');
  const [isInvalidCsv, setIsInvalidCsv] = useState(false);
  const [editUser, setEditUser] = useState<UserUpdate | undefined>(undefined);

  const { data: users, loading, execute: refresh } = useApiState({
    query: new UsersResidentAppGetAllBySiteQuery(site.id),
    errorMessage: t('ResidentApp.UsersLoadFailed')
  }, []);

  const handleFiles = (files: File[]) => {
    if (files[0]) {
      setCsvFile(files[0]);
      setIsInvalidCsv(false);
    }
    else {
      setCsvFile(undefined);
    }
  };

  const handleEditUser = (userItem: UserTableItem) => {
    setEditUser({
      id: userItem.id,
      email: userItem.email,
      firstName: userItem.firstName,
      lastName: userItem.lastName,
      buildingName: userItem.building,
      spaceName: userItem.spaceName,
      startTenancy: userItem.startTenancy,
      endTenancy: userItem.endTenancy,
    });

    toggleEditUserModal();
  };

  const handleDeleteUser = async (userId: number) => {
    await deleteUser({
      query: new UsersResidentAppDeleteCommand(userId),
      successMessage: t('UserDeleted', {ns: 'status'}),
      errorMessage: t('UserDeleteError', {ns: 'status'}),
      pendingMessage: t('UserDeletePending', {ns: 'status'})
    });
    
    if(!isDeleteUserError) {
      refresh();
    }
  };
  
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: handleFiles,
    accept: '.csv'
  });

  const tableColumns: ITableColumn<UserTableItem>[] = [
    {
      label: t('ResidentApp.Name'),
      key: 'fullName',
    },
    {
      label: t('ResidentApp.Email'),
      key: 'email',
    },
    {
      label: t('ResidentApp.Building'),
      key: 'building',
    },
    {
      label: t('ResidentApp.SpaceName'),
      key: 'spaceName',
    },
    {
      label: t('ResidentApp.TenancyDuration'),
      key: 'tenancyDuration',
      disableSort: true
    },
    {
      customElement: (record: UserTableItem) => (
        <TableActionButton
          primaryLabel={t('Edit', { ns: 'common' })}
          primaryAction={() => handleEditUser(record)}
          isPrimaryDisabled={isDeleteUserLoading}
          secondaryLabel={t('Delete', { ns: 'common' })}
          secondaryAction={() => handleDeleteUser(record.id)}
          isSecondaryDisabled={isDeleteUserLoading}
          showWarningDialog={true}
          warningDialogTitle={t('ResidentApp.DeleteUserWarningTitle')}
          warningDialogMessage={t('ResidentApp.DeleteUserWarningMessage')}
          warningDialogConfirmButtonLabel={t('Delete', { ns: 'common' })}
        />
      ),
      rightAlign: true,
      fixedWidth: '80px',
    },
  ];

  // sort users by tenancy date
  const records =
    users && users.length > 0
      ? [...users]
        .sort(
          (a, b) => new Date(b.moveInDate ?? 0).getTime() - new Date(a.moveInDate ?? 0).getTime()
        )
        .map((user) => ({
          id: user.userId,
          firstName: user.user.firstName ?? '-',
          lastName: user.user.lastName ?? '-',
          fullName: `${user.user.firstName} ${user.user.lastName}`,
          email: user.user.email ?? '-',
          building: user.user.buildingName ?? '-',
          spaceName: user.user.spaceName ?? '-',
          startTenancy: user.moveInDate ?? '',
          endTenancy: user.moveOutDate ?? '',
          tenancyDuration: `${format(
            new Date(user.moveInDate ?? 0),
            localisation.dateFormats.slashFormatDisplay
          )} - ${format(
            new Date(user.moveOutDate ?? 0),
            localisation.dateFormats.slashFormatDisplay
          )}`,
        }))
      : [];

  const onClickDownloadTemplate = async () => {
    const blobUri = await executeBlobUri({
      query: new BlobGetSasUriQuery(
        'ResidentsBulkImport.xlsx',
        BlobStorageContainerType.Shared
      )
    });

    if (blobUri) {
      downloadFile(blobUri, 'UtopiConnect-ResidentsBulkImportTemplate.xlsx');
    }
  };

  const handlePreviewFile = () => {
    if (csvFile) {
      parseCsv(csvFile)
        .then(async (rawCsvData) => {
          const trimmedCsvData = trimAndSplitCsvLines(rawCsvData);
          const mappedCsvData = mapStringArrayToResidentCsvObject(trimmedCsvData);

          if (mappedCsvData.length === 0) {
            setIsInvalidCsv(true);
            return;
          }

          setCsvObject(mappedCsvData);

          toggleUploadCsvModal();
          toggleCsvPreviewModal();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <PaddedContainer>
        <TitleWithButton
          title={t('ResidentApp.ManageUsers')}
          backButtonUrl="../resident-app"
          style={{ padding: '0 0 30px 0' }}
        />
        <UsersHeader>
          <Title text={t('ResidentApp.Users')} size="md" />
          <ButtonsWithSearch>
            <SearchField
              placeholder={t('ResidentApp.SearchUsers')}
              onSearchChange={(value) => setSearch(value)}
            />
            <Button
              label={<IconWithText text={t('ResidentApp.UploadCsv')} icon={regular('file-plus')} />}
              onClick={toggleUploadCsvModal}
            />
            <Button
              label={<IconWithText text={t('ResidentApp.AddUser')} icon={regular('user-plus')} />}
              onClick={() => {
                setEditUser(undefined);
                toggleAddUserModal();
              }}
            />
          </ButtonsWithSearch>
        </UsersHeader>
        {loading ? (
          <Loading message={t('ResidentApp.LoadingUsers')} />
        ) : (
          <Table
            columns={tableColumns}
            records={records.filter(
              (record) =>
                includesCI(record.fullName, search) ||
                    includesCI(record.email, search) ||
                    includesCI(record.building, search) ||
                    includesCI(record.spaceName, search)
            )}
            recordKey="id"
            cardEffect={true}
            emptyMessage={t('NoUsersFound', {ns: 'status'})}
          />
        )}
      </PaddedContainer>

      <InteractiveDialog
        modalRef={refUploadCsvModal}
        isOpen={uploadCsvModalIsOpen}
        hide={toggleUploadCsvModal}
        title={t('ResidentApp.UploadCsv')}
        confirmButton={{ label: t('Preview', { ns: 'common' }), onClick: handlePreviewFile, disabled: !csvFile || isInvalidCsv}}
        cancelButton={{ label: t('Cancel', { ns: 'common' }), onClick: toggleUploadCsvModal }}
        content={
          <>
            <FileDropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              fileName={csvFile?.name}
              dimText={csvFile ? '' : t('ResidentApp.UploadCsvFile')}
              containerStyle={{ height: '135px', width: 'fill' }}
              onDelete={() => setCsvFile(undefined)}
            />
            {isInvalidCsv && <ResidentAppFormErrorMessage message={t('InvalidCsvFile', {ns: 'validation'})} />}
            <SmallText>{t('ResidentApp.DownloadTemplateDescription')}</SmallText>
            <Button
              label={<><Icon icon={solid('download')}/> {t('ResidentApp.DownloadCsvTemplate')}</>}
              tertiary
              style={{ marginTop: '10px' }}
              onClick={onClickDownloadTemplate}
              disabled={isBlobUriLoading}
            />
          </>
        }
      />

      <CsvPreview
        isModalOpen={csvPreviewModalIsOpen}
        toggleModal={toggleCsvPreviewModal}
        modalRef={refCsvPreviewModal}
        csvData={csvObject ?? []}
        csvFile={csvFile}
        onRefresh={refresh}
        toggleCsvUploadModal={toggleUploadCsvModal}
      />

      <UserFormModal
        isModalOpen={addUserModalIsOpen}
        toggleModal={toggleAddUserModal}
        modalRef={refAddUserModal}
        onRefresh={refresh}
      />

      <UserFormModal
        user={editUser}
        isModalOpen={editUserModalIsOpen}
        toggleModal={toggleEditUserModal}
        modalRef={refEditUserModal}
        onRefresh={refresh}
      />
    </>
  );
}

export default ResidentApp_ManageUsers;

const UsersHeader = styled.div`
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsWithSearch = styled.div`
  display: flex;
  gap: 10px;
`;

const SmallText = styled.small`
  font-size: 12px;
  display: block;
  margin: 15px 0 0;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;