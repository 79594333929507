import { MetricType_ValueDisplayString } from '@api/enums/MetricType_ValueDisplayString';
import { LabelValuePair } from '@components/core/LabelValuePair';
import { faCircleArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IAlertModalTab } from './AlertModalContent';

const AlertModal_Devices = ({ alert, onMobile, closeModal }: IAlertModalTab) => {
  const { t } = useTranslation(['commonApp', 'enums']);

  return (
    <>
      {alert.message.devices.map((device) => {

        return (
          <RowWrapper key={device.deviceId} onClick={closeModal}>
            <Link to={`/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}/floor/${alert.space.floor.id}/space/${alert.spaceId}/device/${device.deviceId}`}>
              <Row className='row' $onMobile={onMobile}>
                <div className='col'>
                  <DeviceName>
                    {device.friendlyName}
                  </DeviceName>
                </div>

                <div className='col'>
                  <LabelValuePair
                    label={t(device.metricType, { ns: 'enums' })}
                    value={MetricType_ValueDisplayString({ metricType: device.metricType, value: device.value, t: t })}
                    size="xs"
                    smallGap
                  />
                </div>

                {!onMobile &&
                  <div className='col'>
                    <LabelValuePair
                      label='Measured on'
                      value={format(new Date(device.measuredOn), 'dd. MMM yyy, hh:mmaaa')}
                      size="xs"
                      smallGap
                    />
                  </div>
                }

                <div className='col-auto'>
                  <ArrowIcon icon={faCircleArrowRight} />
                </div>
              </Row>
            </Link>
          </RowWrapper>
        );
      })}
    </>
  );
};

export default AlertModal_Devices;

const RowWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  }
`;

const Row = styled.div<{ $onMobile: boolean }>`
  align-items: center;
  gap: 20px;
  padding: 20px 20px;
  cursor: pointer;

  ${p => p.$onMobile && css`
    gap: 10px;
    padding: 10px 20px;
  `}
`;

const DeviceName = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
  color: ${p => p.theme.palette.text.weak};

  ${Row}:hover & {
    color: ${p => p.theme.palette.primary};
  }
`;