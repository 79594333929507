import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import FloorSelectorSidebar from './components/FloorSelectorSidebar';
import MarkerListSidebar from './components/MarkerListSidebar';
import FloorplanContainer from './components/FloorplanContainer';
import Marker from './components/Marker.types';
import FloorsGetallQuery from '@api/queries/floors/FloorsGetAllQuery';
import SpaceUpdateCommand from '@api/queries/spaces/SpaceUpdateCommand';
import SpacesGetallQuery from '@api/queries/spaces/SpacesGetAllQuery';
import { Building } from '@api/models/Building';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { Button } from '@components/core/Button';

interface ISpaceLocations {
  building: Building
}

const SpaceLocations = ({ building }: ISpaceLocations) => {
  /**
   * Get a list of all floors
   */
  const { data: floors } = useApiState({
    query: new FloorsGetallQuery(building.id),
    initialState: []
  }, [building]);

  /**
   * Get all spaces for the selected floor
   */
  const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
  const { data: spaces } = useApiState({
    query: floors[selectedFloorIndex] && new SpacesGetallQuery(floors[selectedFloorIndex].id),
    initialState: []
  }, [floors, selectedFloorIndex]);

  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [markersClone, setMarkersClone] = useState<Marker[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [showTooltips, setShowTooltips] = useState<boolean>(false);
  const { execute } = useApi();

  /**
   * Set hasUnsavedChanges to false when the selected floor changes
   */
  useEffect(() => {
    setHasUnsavedChanges(false);
  }, [selectedFloorIndex]);

  /**
   * Create a list of markers when the list of spaces changes
   */
  useEffect(() => {
    const markers = spaces.map(x => {
      return new Marker(x.id, x.name, x.spaceType, x.xCoordinate, x.yCoordinate)
    });

    setMarkers(markers);
    setMarkersClone(cloneDeep(markers));
  }, [spaces]);

  /**
   * Update markers when one has been moved
   */
  const onChange = (modifiedMarkers: Marker[]) => {
    setMarkersClone(modifiedMarkers);
    setHasUnsavedChanges(true);
  };

  /**
   * Reset markers to original positions
   */
  const onReset = () => {
    setMarkersClone(markers);
    setHasUnsavedChanges(false);
  };

  /**
   * Save new positions of markers
   */
  const onSave = (modifiedMarkers: Marker[]) => {
    setSavingInProgress(true);
    let isMounted = true;

    modifiedMarkers.forEach(async (marker, i) => {
      const res = await execute({
        query: new SpaceUpdateCommand({
          ...spaces[i],
          xCoordinate: marker.xCoordinate,
          yCoordinate: marker.yCoordinate
        })
      });

      isMounted = isMounted ? res !== undefined : isMounted;
    });

    if (!isMounted) {
      return;
    }

    setMarkers(modifiedMarkers);
    setSavingInProgress(false);
    setHasUnsavedChanges(false);
  };

  /**
   * Remove marker from floorplan by setting x and y cooridnates to null
   */
  const onRemoveDrop = (e: React.DragEvent<HTMLDivElement>) => {
    // This is set on 'onDragStart" event.
    const index = parseInt(e.dataTransfer.getData('markerIndex'));

    if (isNaN(index)) {
      return;
    }

    const markersModified = cloneDeep(markersClone);

    markersModified[index].xCoordinate = undefined;
    markersModified[index].yCoordinate = undefined;

    onChange(markersModified);
  }

  /**
   * Return null if no floor is selected (only the case when there are no floors)
   */
  if (!floors[selectedFloorIndex]) {
    return null;
  }

  return (
    <div style={{ display: 'flex', gap: '30px' }}>
      <FloorSelectorSidebar
        floors={floors}
        selectedFloorIndex={selectedFloorIndex}
        onFloorChange={(i) => setSelectedFloorIndex(i)}
      />

      <ContentWrapper>
        <FlexRow>
          <RemoveDropzone
            draggable={false}
            onDragOver={(e) => e.preventDefault()}
            onDrop={onRemoveDrop}
          >
            {t('Buildings.Floorplan.FloorplanPositioning.DropHere', { ns: 'settingsAsset' })}
          </RemoveDropzone>

          <Button
            tertiary
            label={showTooltips ? t('Buildings.Floorplan.FloorplanPositioning.HideTooltips', { ns: 'settingsAsset' }) : t('Buildings.Floorplan.FloorplanPositioning.ShowTooltips', { ns: 'settingsAsset' })}
            onClick={() => setShowTooltips(!showTooltips)}
          />

          <div style={{ marginLeft: 'auto' }} />

          <Button
            tertiary
            label={t('Buildings.Floorplan.FloorplanPositioning.Reset', { ns: 'settingsAsset' })}
            onClick={onReset}
            color={theme.palette.red}
          />

          <Button
            label={t('Buildings.Floorplan.FloorplanPositioning.Save', { ns: 'settingsAsset' })}
            onClick={() => onSave(markersClone)}
            disabled={!hasUnsavedChanges}
            loading={savingInProgress}
          />
        </FlexRow>

        <FloorplanContainer
          floor={floors[selectedFloorIndex]}
          markers={markersClone}
          showTooltips={showTooltips}
          onChange={onChange}
        />
      </ContentWrapper>

      <MarkerListSidebar
        floor={floors[selectedFloorIndex]}
        markers={markersClone}
      />
    </div>
  );
};

export default SpaceLocations;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const RemoveDropzone = styled.div`
  width: auto;
  height: 32px;
  padding: 0 15px;
  border-radius: 3px;
  border: 2px dashed ${p => p.theme.palette.borders.medium};
  color: ${p => p.theme.palette.text.weak};
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;
`;