import styled from 'styled-components';
import Toggle, { ToggleOption } from '@components/core/Toggle';

type EnergyWidgetUnitToggleProps = {
  labels?: string[];
  onChange: (selected: boolean) => void;
};

const EnergyWidgetUnitToggle = ({ labels, onChange }: EnergyWidgetUnitToggleProps) => {
  if (!labels || labels.length !== 2) {
    return null;
  }

  const options: ToggleOption<boolean>[] = [
    {
      label: labels[0] ?? '',
      value: false
    },
    {
      label: labels[1] ?? '',
      value: true
    }
  ];

  return (
    <Wrapper>
      <Toggle
        options={options}
        onChange={(value) => onChange(value)}
        buttonStyle={{
          padding: '1px',
          width: '45px',
          fontSize: '12px'
        }}
      />
    </Wrapper>
  );
};

export default EnergyWidgetUnitToggle;

const Wrapper = styled.div`
  display: block;
  margin-left: 15px;

  @media print {
    display: none;
  }
`;