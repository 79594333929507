import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { includesCI } from '@utils/StringUtils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Checkbox from '@components/cascader-multi-select/modal/Checkbox';
import { InternalNode, InternalNodeGroup } from '../SearchableDropdownSelect.types';

type CascadeLevelProps<TValue> = {
  group: InternalNodeGroup<TValue>;
  depth: number;
  searchString?: string;
  disabled?: boolean;
  activeGroupId?: string;
  setActiveGroupId: (id?: string) => void;
  parentGroup?: InternalNodeGroup<TValue>;
  allNodes?: InternalNode<TValue>[];
  handleSelect: (node: InternalNode<TValue>) => void;
}

const CascadeLevel = <TValue,>({ group, depth, searchString, disabled, activeGroupId, setActiveGroupId, parentGroup, allNodes, handleSelect }: CascadeLevelProps<TValue>) => {
  const { t } = useTranslation();
  const [activeNodeId, setActiveNodeId] = useState<string>();
  const maxDepth = 10;
  const disableSelection = disabled || group.nodes.filter(x => x.selected || x.partial).length === group.selectLimit;

  const searchFilter = useCallback((x: string) => {
    return includesCI(x, searchString ?? '');
  }, [searchString]);

  const handleNodeClick = (node: InternalNode<TValue>, hasChildren: boolean) => {
    if (hasChildren) {
      setActiveNodeId(node.id);
      setActiveGroupId(node.childGroup?.id);
    } else {
      handleSelect(node);
    }
  };

  if (group.hide || group.nodes.length === 0 || depth >= maxDepth) {
    return null;
  }

  return (
    <>
      <FlexColumn activeGroup={activeGroupId === group.id}>
        <NodeList>
          {parentGroup &&
            <BackButton onClick={() => setActiveGroupId(parentGroup?.id)}>
              <ChevronIcon icon={solid('chevron-left')} />
              {t('Back', { ns: 'navigation' })}
            </BackButton>
          }
          {(searchString && searchString?.length > 0) ?
            allNodes?.filter(x => searchFilter(x.label)).map((node) => {
              return (
                <NodeRow
                  key={node.id}
                  onClick={() => handleSelect(node)}
                >
                  <Checkbox
                    node={node}
                    onSelect={handleSelect}
                    disabled={disableSelection}
                  />
                  <LabelRow>
                    <Label>
                      {node.label}
                    </Label>
                  </LabelRow>
                </NodeRow>
              );
            }) :
            group.nodes.map((node) => {
              const hasChildren = node.childGroup !== undefined && node.childGroup.nodes.length > 0 && !node.childGroup.hide;
              return (
                <NodeRow
                  key={node.id}
                  onClick={() => handleNodeClick(node, hasChildren)}
                >
                  <Checkbox
                    node={node}
                    onSelect={handleSelect}
                    disabled={disableSelection}
                  />
                  <LabelRow>
                    <Label>
                      {node.label}
                    </Label>
                    <RightAligned>
                      <NumNodes>
                        {node.childGroup?.nodes && node.childGroup.nodes.length > 0 && `(${node.childGroup.nodes.length})`}
                      </NumNodes>

                      {!node.childGroup?.hide && node.childGroup?.nodes && node.childGroup.nodes.length > 0 &&
                        <Icon icon={solid('chevron-right')} />
                      }
                    </RightAligned>
                  </LabelRow>
                </NodeRow>
              );
            })}
        </NodeList>
      </FlexColumn>

      {!searchString && group.nodes.filter(node => node.id === activeNodeId).map((childNode) => childNode.childGroup && (
        <CascadeLevel
          key={childNode.id}
          group={childNode.childGroup}
          depth={depth + 1}
          disabled={!childNode.partial && disableSelection}
          activeGroupId={activeGroupId}
          setActiveGroupId={setActiveGroupId}
          parentGroup={group}
          handleSelect={handleSelect}
        />
      ))}
    </>
  );
}

export default CascadeLevel;

const FlexColumn = styled.div<{ activeGroup: boolean }>`
  display: flex;
  flex-direction: column;
  width: 0px;
  height: 0px;
  overflow: hidden;
  transition: width 150ms ease;

  ${p => p.activeGroup && css`
    width: 100%;
    height: 100%;
  `}
`;

const NodeList = styled.div`
  overflow: hidden auto;
  max-height: 200px;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.primary};
  padding-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const NodeRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
  border-left: 3px solid transparent;

  &:hover {
    background-color: ${p => p.theme.palette.select.hover};
    border-left-color: ${p => p.theme.primary.main};
  }
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const RightAligned = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NumNodes = styled.div`
  margin-left: 5px;
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  color: ${p => p.theme.text.secondary};
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const BackButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
  padding: 5px 15px;
  border-radius: 4px;
  transition: all 150ms ease;
  width: 100%;
  height: 38px;

  &:hover {
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;