import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { cloneDeep, orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import BuildingFloorEdit from './components/BuildingFloorEdit';
import { useTranslation } from 'react-i18next';
import FloorsGetallQuery from '@api/queries/floors/FloorsGetAllQuery';
import { Building } from '@api/models/Building';
import { Floor } from '@api/models/Floor';
import { NewFloor, instanceOfNewFloor } from '@api/models/NewFloor';
import { useApiState } from '@hooks/useApiState';
import { Button } from '@components/core/Button';
import { Card } from '@components/core/Card';

interface IBuildingFloorList {
  building: Building
}

const BuildingFloorList = ({ building }: IBuildingFloorList) => {
  const { t } = useTranslation(['settingsAsset']);
  const [selectedFloorIndex, setSelectedFloorIndex] = useState<number>(0);
  const { transform: floors, setTransform: setFloors } = useApiState({
    query: new FloorsGetallQuery(building.id),
    transformFunc: (floors) => floors as (Floor | NewFloor)[],
    initialTransformState: []
  }, [building]);

  /**
   * Show form to add a new floor if no other floors exist
   */
  useEffect(() => {
    if (floors.length === 0) {
      const modifiedFloorList = cloneDeep(floors);

      const newFloor: NewFloor = {
        name: '',
        shortCode: '',
        floorNumber: 0
      };

      modifiedFloorList.unshift(newFloor);
      setFloors(modifiedFloorList);
      setSelectedFloorIndex(0);
    }
  }, [floors, setFloors]);

  const addFloor = () => {
    const floorsUpdated = cloneDeep(floors);

    const newFloor: NewFloor = {
      name: '',
      shortCode: '',
      floorNumber: 0
    };

    floorsUpdated.unshift(newFloor);
    setFloors(floorsUpdated);
    setSelectedFloorIndex(0);
  }

  const updateFloorInState = (updatedFloor: Floor, index: number) => {
    const floorsUpdated = cloneDeep(floors);
    floorsUpdated[index] = updatedFloor;
    setFloors(orderBy(floorsUpdated, x => x.floorNumber, 'desc'));
  }

  const deleteFloorInState = (index: number) => {
    const floorsUpdated = cloneDeep(floors);
    floorsUpdated.splice(index, 1);
    setFloors(floorsUpdated);
    setSelectedFloorIndex(0);
  }

  return (
    <div style={{ display: 'flex', gap: '30px' }}>
      <Card cardTitle={t('Buildings.Floors.FloorManagement.Floor', { ns: 'settingsAsset' })} style={{ width: '78px', height: 'max-content', marginTop: '52px' }}>
        <FloorSelector>
          {floors.map((floor, i) => (
            <IdentifierSquare
              key={i}
              onClick={() => setSelectedFloorIndex(i)}
              selected={i === selectedFloorIndex}
              newItem={instanceOfNewFloor(floor)}
            >
              {`${floor.floorNumber}`}
            </IdentifierSquare>
          ))}

          <Button
            tertiary
            circle
            label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
            onClick={addFloor}
          />
        </FloorSelector>
      </Card>

      {floors[selectedFloorIndex] &&
        <BuildingFloorEdit
          buildingId={building.id}
          floor={floors[selectedFloorIndex]}
          index={selectedFloorIndex}
          onFloorCreate={updateFloorInState}
          onFloorUpdate={updateFloorInState}
          onFloorDelete={deleteFloorInState}
        />
      }
    </div>
  );
};

export default BuildingFloorList;

const FloorSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`;

const IdentifierSquare = styled.div<{ selected: boolean, newItem: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  
  width: 40px;
  height: 32px;
  border: 1px ${p => p.newItem ? 'dashed' : 'solid'} ${p => p.theme.palette.borders.medium};
  border-radius: 3px;

  font-weight: 500;
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;

  ${p => p.selected && css`
    color: ${p => p.theme.palette.text.onPrimary};
    border-color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.primary};
    box-shadow: 0 7px 10px -4px rgba(0, 0, 0, 0.2);
  `}
`;