import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import { EsgExplorerMetricsDto } from '@api/models/EsgExplorerMetricsDto';
import { exportCSV } from '@utils/ExportUtils';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@components/core/Button';
import FullScreenToggle from '@components/core/FullScreenToggle';
import { Switch } from '@components/core/Switch';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { format } from 'date-fns';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ITableInfoWidgets {
  data?: EsgExplorerMetricsDto;
  config?: EsgExplorerConfiguration;
  fileName: string;
  showConditionalFormatting: boolean;
  setShowConditionalFormatting: (state: boolean) => void;
  isLockingEnabled: boolean;
  lockTopRows: boolean;
  setLockTopRows: (state: boolean) => void;
}

const TableInfoWidgets = ({ config, data, fileName, showConditionalFormatting, setShowConditionalFormatting, isLockingEnabled, lockTopRows, setLockTopRows }: ITableInfoWidgets) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();

  if (!config || !data || data.spaces.length === 0) {
    return null;
  }

  const handleExport = () => {
    exportCSV(fileName, data.csv);
    trackAction('export_csv', 'esg_explorer');
  };

  return (
    <Wrapper>
      <InfoCards>
        <InfoCard>
          <InfoCardIcon icon={regular('calendar-range')} />
          <span style={{ marginTop: '2px' }}>
            {config.timeRange &&
              `${format(new Date(config.timeRange.from), 'dd MMM yyyy')} - ${format(new Date(config.timeRange.to), 'dd MMM yyyy')}`
            }
          </span>
        </InfoCard>

        <InfoCard>
          <InfoCardIcon icon={regular('hashtag')} />
          {data.spaces.length} {t('ESG.Spaces', { ns: 'molecules' })}
          <div style={{ marginTop: '2px' }}>
          </div>
        </InfoCard>

        <InfoCard>
          <InfoCardIcon icon={solid('bars-sort')} />
          <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', gap: '8px' }}>
            {t('ESG.ColorFormatting', { ns: 'molecules' })}
            <Switch
              checked={showConditionalFormatting}
              onChange={() => setShowConditionalFormatting(!showConditionalFormatting)}
            />
          </div>
        </InfoCard>

        {isLockingEnabled &&
          <InfoCard>
            <InfoCardIcon icon={solid('lock')} />
            <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', gap: '8px' }}>
              {t('ESG.LockTopRows', { ns: 'molecules' })}
              <Switch
                checked={lockTopRows}
                onChange={() => setLockTopRows(!lockTopRows)}
              />
            </div>
          </InfoCard>
        }

        <FullScreenToggle />
      </InfoCards>

      <Button
        label={t('Export', { ns: 'common' })}
        onClick={handleExport}
        style={{ padding: '' }}
      />
    </Wrapper>

  );
};

export default TableInfoWidgets;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  margin-bottom: 20px;
`;

const InfoCards = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;

const InfoCard = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  height: 36px;
  padding: 0 10px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0px 3px 8px ${p => p.theme.palette.shadows.medium};
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const InfoCardIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;
  color: ${p => transparentize(0.2, p.theme.palette.primary)};
`;