import { Button } from '@components/core/Button';
import { Modal } from '@components/core/Modal';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import InputFormField from '@pages/site/resident-app/components/common/InputFormField';
import { FormProvider, useForm } from 'react-hook-form';
import ResidentAppFormErrorMessage from '@pages/site/resident-app/components/common/ResidentAppFormErrorMessage';
import { useApi } from '@hooks/useApi';
import SiteOperatorResidentAppUpdateCommand from '@api/queries/resident-app/SiteOperators/SiteOperatorResidentAppUpdateCommand';
import { useSiteContext } from '@pages/site/SiteProvider';
import { Title } from '@components/core/Title';

export type SiteOperatorForm = {
  email: string;
};

type SiteOperatorEditModalProps = {
  showModal: boolean;
  onCloseModal: () => void;
};

const SiteOperatorEditModal = ({ showModal, onCloseModal }: SiteOperatorEditModalProps) => {
  const { t } = useTranslation(['molecules', 'common']);
  const theme = useTheme();
  const methods = useForm<SiteOperatorForm>()
  const { formState: { errors } } = methods;
  const { execute, loading, error } = useApi();
  const { siteOperator, refreshSiteOperator} = useSiteContext();

  const onSubmitEdit = async (data: SiteOperatorForm) => {
    if(siteOperator){
      await execute({
        query:
          new SiteOperatorResidentAppUpdateCommand(
            siteOperator.id,
            data.email
          ),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
        pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      })}

    if (!error) {
      onCloseModal();
      refreshSiteOperator();
    }
  };

  return (
    <Modal isOpen={showModal} plainModal width='380px'>
      <Content>
        <Title
          text={t('ResidentApp.EditGeneralInformation')}
          size="sm"
          style={{ padding: '0 20px 20px 20px' }}
        />

        <FormProvider {...methods}>
          <EditForm>
            <div>
              <InputFormField
                inputName="email"
                label={t('ResidentApp.OperatorContactEmail')}
                value={siteOperator?.email}
                isRequired={true}
                inputType="email"
                requiredErrorMessage={t('EmailRequired', { ns: 'validation' })}
                onValidate={(value) => value.includes('@') || t('InvalidEmail', { ns: 'validation' })}
                error={!!errors.email}
              />
              {errors.email && <ResidentAppFormErrorMessage message={errors.email.message} />}
            </div>

            <Buttons>
              <Button
                label={t('Cancel', { ns: 'common' })}
                onClick={onCloseModal}
                tertiary
                color={theme.palette.systemMessage.error}
                disabled={loading}
              />
              <Button
                label={t('Update', {ns: 'common'})}
                onClick={methods.handleSubmit(onSubmitEdit)}
                disabled={loading}
              />
            </Buttons>
          </EditForm>
        </FormProvider>
      </Content>
    </Modal>
  );
}

export default SiteOperatorEditModal;

const Content = styled.div`
  padding: 20px 0;
`;

const EditForm = styled.form`
  padding: 20px 20px 0 20px;
  border-top: 1px solid ${({ theme }) => theme.palette.borders.medium};
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;