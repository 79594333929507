import { Device } from '@api/models/Device';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class PeopleCountingZoneCreateCommand extends PostQuery<Device> {
  spaceId?: number;

  public targetUrl(): string {
    return '/peoplecounting';
  }
}
