import RolesGetAllQuery from '@api/queries/roles/RolesGetAllQuery';
import UserCreateCommand from '@api/queries/users/UserCreateCommand';
import { UserRole } from '@api/enums/UserRole';
import { UserRolePermissions } from '@api/enums/UserRolePermissions';
import { NewUser } from '@api/models/NewUser';
import { BackButton } from '@components/core/BackButton';
import { Button } from '@components/core/Button';
import { ErrorMessage, Form, Input, Label } from '@components/Form';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Select } from '@components/select/Select';
import { Card } from '@components/core/Card';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { nullIfEmptyString } from '@utils/StringUtils';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import styled, { useTheme } from 'styled-components';

type FormValues = {
  fullName: string,
  displayName?: string,
  email: string,
  phoneNumber: string,
  roleId: number
}

const UserCreate = () => {
  const { t } = useTranslation(['settingsUser']);
  const theme = useTheme();
  const navigate = useNavigate();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm<FormValues>();
  const [selectedRole, setSelectedRole] = useState<number>();
  const { execute } = useApi();
  const { data: roles } = useApiState({
    query: new RolesGetAllQuery(),
    initialState: []
  }, []);
  const selectedRoleEnum = roles.find(x => x.id === selectedRole)?.name;

  const onSave: SubmitHandler<FormValues> = async data => {

    if (selectedRole === undefined) {
      return;
    }

    setSavingInProgress(true);

    const newUser: NewUser = {
      ...data,
      roleId: selectedRole
    };

    const userCreateDto = await execute({
      query: new UserCreateCommand(newUser),
      successMessage: t('Create.UserCreated', { ns: 'settingsUser' }),
      errorMessage: t('Create.UserCreateFailed', { ns: 'settingsUser' })
    });

    setSavingInProgress(false);

    if (userCreateDto === undefined) {
      return;
    }

    navigate(`../users/${userCreateDto.id}`);
  };

  const onUserRoleChanged = async (roleId?: number) => {
    setSelectedRole(roleId);
  };

  const onClear = () => {
    reset();
  };

  const getPermissions = (userRole: UserRole): string => {
    return UserRolePermissions[userRole];
  };

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('BackToUsers', { ns: 'navigation' })}
          url='./..'
        />

        <Card cardTitle={t('Create.CreateUser', { ns: 'settingsUser' })}>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Create.FullName', { ns: 'settingsUser' })}</Label>
                  <Input {...register('fullName', { required: t('Create.RequiredField', { ns: 'settingsUser' }) })} />
                  <ErrorMessage>{errors.fullName?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Create.DisplayName', { ns: 'settingsUser' })}</Label>
                  <Input {...register('displayName', { required: t('Create.RequiredField', { ns: 'settingsUser' }) })} />
                  <ErrorMessage>{errors.displayName?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Create.Email', { ns: 'settingsUser' })}</Label>
                  <Input type="email" {...register('email', {
                    required: t('Create.RequiredField', { ns: 'settingsUser' }),
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('Create.EmailValidation', { ns: 'settingsUser' })
                    }
                  })} />
                  <ErrorMessage>{errors.email?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Create.PhoneNumber', { ns: 'settingsUser' })}</Label>
                  <Input {...register('phoneNumber', {
                    required: t('Create.RequiredField', { ns: 'settingsUser' }),
                    setValueAs: value => nullIfEmptyString(value)
                  })} />
                  <ErrorMessage>{errors.phoneNumber?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Create.Role', { ns: 'settingsUser' })}</Label>
                  <Select
                    isSearchable={false}
                    onChange={(selected: SingleValue<{ label: string, value: number } | undefined>) => onUserRoleChanged(selected?.value)}
                    options={roles.map((r) => ({ value: r.id, label: r.displayName }))}
                  />
                </div>
              </div>
              {selectedRoleEnum &&
                <div className='row'>
                  <div className="col-md-12">
                    <Label>{t('Create.Permissions', { ns: 'settingsUser' })}</Label><br />
                    <PermissionsText>
                      {getPermissions(selectedRoleEnum)}
                    </PermissionsText>
                  </div>
                </div>
              }
            </div>
          </Form>
        </Card>
        <Card
          centered
          maxWidth='700px'
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <Button
                  label={t('Create.CreateUser', { ns: 'settingsUser' })}
                  onClick={handleSubmit(onSave)}
                  disabled={!isEmpty(errors) || selectedRole === undefined}
                  loading={savingInProgress}
                />
              </div>
              <div className="col-md-7">
                <Button
                  tertiary
                  label={t('Create.ClearFields', { ns: 'settingsUser' })}
                  onClick={onClear}
                  color={theme.palette.red}
                  style={{ marginLeft: 'auto' }}
                />
              </div>
            </div >
          </div >
        </Card >
      </Container >
    </PaddedContainer >
  );
};

export default UserCreate;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;

const PermissionsText = styled.p`
  font-size: 12px;
  color: ${p => p.theme.palette.text.fair};
`;