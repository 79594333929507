import { OperatorChallengeDto } from '@api/models/OperatorChallengeDto';
import { ChallengeStatus } from '@api/models/ChallengeStatus';
import { isFuture, isAfter } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { ResidentCsvObject } from '@pages/site/resident-app/manage-users/ResidentApp_ManageUsers';
import { t } from 'i18next';
import { SiteOperatorDto } from '@api/models/SiteOperatorDto';
import { ResidentAppFeatureType } from '@api/enums/ResidentAppFeatureType';
import { SiteThresholdDto } from '@api/models/SiteThresholdDto';
import { SiteThresholdForm } from './components/thresholds/TemperatureThresholdForm';
import { ThresholdSeason } from '@api/models/ThresholdSeason';
import { MetricType } from '@api/enums/MetricType';
import { SpaceTypeThresholdDto } from '@api/models/SpaceTypeThresholdDto';
import { Threshold } from '@pages/site/resident-app/components/thresholds/ThresholdEditableTable';
import { SpaceTypeThresholdForm } from './components/thresholds/ElectricityThresholdForm';
import { SpaceType } from '@api/enums/SpaceType';
import { LocalisationFunction } from '@contexts/LocalisationContext/LocalisationContext';
import { round } from '@utils/NumberUtils';

/**
 * Get display Challenge Status, returns the translation key for status string
 */
export const getDisplayChallengeStatus = (challenge: OperatorChallengeDto) => {
  if (isFuture(new Date(challenge.startDate)) && challenge.status === ChallengeStatus.Open) {
    return 'ResidentApp.Upcoming';
  }

  if (!challenge.winnersConfirmed && challenge.status === ChallengeStatus.Closed){
    return 'ResidentApp.PendingWinners';
  }

  return `ResidentApp.${challenge.status}`;
};

/**
 * Checks if end date comes after start date
 */
export const isEndDateAfterStartDate = (startDate: Dayjs, endDate: Dayjs) => {
  return startDate <= endDate;
};

/**
 * Validates CSV file to make sure it's in the right format as ResidentCsvObject
 */
export const isValidResidentCsvObject = (csvObject: ResidentCsvObject) => {
  // required keys
  const keys: { [key: string]: string } = {
    key: 'string',
    buildingName: 'string',
    spaceName: 'string',
    email: 'string',
    firstName: 'string',
    lastName: 'string',
    startTenancy: 'string',
    endTenancy: 'string'
  };

  for (const key in keys){
    if (!(key in csvObject) || typeof csvObject[key as keyof ResidentCsvObject] !== keys[key]) {
      return false;
    }
  }

  return true;
};

/**
 * Maps arrays of string arrays to an Object
 */
export const mapStringArrayToResidentCsvObject = (data: string[][]) => {
  const headers = data[0].map(x => x.charAt(0).toLowerCase() + x.slice(1).replace(/\s/g, ''));
  headers.push('key');

  return data.slice(1).map((row, rowNumber) => {
    const obj: ResidentCsvObject = {} as ResidentCsvObject;
    headers.forEach((header, index) => {
      obj[header as keyof ResidentCsvObject] = header === 'key' ? `${rowNumber + 1}` : row[index];
    });

    return obj;
  }).filter(isValidResidentCsvObject);
};

/**
 * Gets the ordinal number suffix for a number
 */
export const getOrdinalNumber = (num: number): string => {
  if (num % 100 >= 11 && num % 100 <= 13) {
    return num + t('OrdinalSuffix.default', {ns: 'common'});
  }
  
  const lastDigit = num % 10;
  const suffix =
    {
      1: t('OrdinalSuffix.1', { ns: 'common' }),
      2: t('OrdinalSuffix.2', { ns: 'common' }),
      3: t('OrdinalSuffix.3', { ns: 'common' }),
    }[lastDigit] || t('OrdinalSuffix.default', { ns: 'common' });
  
  return num + suffix;
}

/**
 * Returns whether a metric type is enabled for a site operator
 */
export const isMetricEnabled = (siteOperator: SiteOperatorDto, metricType: ResidentAppFeatureType) => {
  return !!siteOperator?.siteMetricTypes?.find(
    (siteMetricType) => siteMetricType.metricType === metricType
  );
}

/**
 * Maps the SiteThresholdForm to SiteThresholdDto
 */
export const mapToSiteThresholdDtos = (thresholds: SiteThresholdForm, siteOperator: SiteOperatorDto, fromLocale: LocalisationFunction): SiteThresholdDto[] => {
  const inputArray = thresholds.thresholds;

  return inputArray.reduce((acc, curr) => {
    // For each row in the input array, create two entries, one for SeasonOne and one for SeasonTwo
    acc.push({
      id: curr.seasonOne.id,
      siteId: siteOperator.siteId,
      siteOperatorId: siteOperator.id,
      metricType: MetricType.Temperature,
      thresholdSeason: ThresholdSeason.SeasonOne,
      seasonTarget: fromLocale(MetricType.Temperature, curr.seasonOne.targetValue),
    });
    acc.push({
      id: curr.seasonTwo.id,
      siteId: siteOperator.siteId,
      siteOperatorId: siteOperator.id,
      metricType: MetricType.Temperature,
      thresholdSeason: ThresholdSeason.SeasonTwo,
      seasonTarget: fromLocale(MetricType.Temperature, curr.seasonTwo.targetValue),
    });
    return acc;
  }, [] as SiteThresholdDto[]);
};

/**
 * Maps the SpaceTypeThresholds to SpaceTypeThresholdForm
 */
export const mapToSpaceTypeThresholdForm = (spaceTypeThresholds: SpaceTypeThresholdDto[]): SpaceTypeThresholdForm => {
  const grouped: Record<
    string,
    {
      seasonOne?: { id: number; targetValue: number };
      seasonTwo?: { id: number; targetValue: number };
      spaceType?: SpaceType;
      bedroomCount?: number;
    }
  > = {};

  spaceTypeThresholds.forEach((item) => {
    const { id, spaceType, bedroomCount, thresholdSeason, seasonTarget } = item;

    // Use spaceType as key if it's not null, otherwise use bedroomCount
    const key = spaceType ?? `Cluster-${bedroomCount}`;

    // Initialize the group if it doesn't exist
    if (!grouped[key]) {
      grouped[key] = {};
    }

    grouped[key].spaceType = spaceType;
    grouped[key].bedroomCount = bedroomCount;

    if (thresholdSeason === ThresholdSeason.SeasonOne) {
      grouped[key].seasonOne = { id, targetValue: seasonTarget };
    } else if (thresholdSeason === ThresholdSeason.SeasonTwo) {
      grouped[key].seasonTwo = { id, targetValue: seasonTarget };
    }
  });

  const thresholdForm: SpaceTypeThresholdForm = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    thresholds: Object.entries(grouped).map(([key, threshold]) => ({
      spaceType: threshold.spaceType,
      bedroomCount: threshold.bedroomCount,
      seasonOne: threshold.seasonOne ?? {id: 0, targetValue: 0}, // Default to 0 if not found
      seasonTwo: threshold.seasonTwo ?? {id: 0, targetValue: 0}, // Default to 0 if not found
    }))
  };

  return thresholdForm;
};

/**
 * Maps the SpaceTypeThresholds to Thresholds
 */
export const mapToSpaceTypeThresholds = (spaceTypeThresholds: SpaceTypeThresholdDto[]): Threshold[] => {
  // Create a map to hold grouped thresholds by spaceType or bedroomCount
  const grouped: Record<string, { seasonOne?: number; seasonTwo?: number }> = {};

  spaceTypeThresholds.forEach((item) => {
    const { spaceType, bedroomCount, thresholdSeason, seasonTarget } = item;

    // Use spaceType as key if it's not null, otherwise use bedroomCount
    const key = spaceType ? t(spaceType, {ns: 'enums'}) : t('ResidentApp.ClusterBedroomCount', {ns: 'molecules', count: bedroomCount});

    // Initialize the group if it doesn't exist
    if (!grouped[key]) {
      grouped[key] = {};
    }

    // Assign season values based on the thresholdSeason
    if (thresholdSeason === ThresholdSeason.SeasonOne) {
      grouped[key].seasonOne = seasonTarget;
    } else if (thresholdSeason === ThresholdSeason.SeasonTwo) {
      grouped[key].seasonTwo = seasonTarget;
    }
  });

  // Map the grouped data to an array of Threshold and sort in alphebetical order
  const thresholds: Threshold[] = Object.entries(grouped).map(([key, seasons]) => ({
    title: key,  // Use the key, which is either spaceType or bedroomCount-based
    seasonOne: seasons.seasonOne ?? 0, // Default to 0 if not found
    seasonTwo: seasons.seasonTwo ?? 0, // Default to 0 if not found
  }));

  return thresholds;
};

/**
 * Maps the SpaceTypeForm to SpaceTypeThresholds[]
 */
export const mapFormToSpaceTypeThresholdDtos = (
  form: SpaceTypeThresholdForm, 
  siteId: number, 
  siteOperatorId: number
): SpaceTypeThresholdDto[] => {

  const spaceTypeThresholdDtos: SpaceTypeThresholdDto[] = [];

  form.thresholds.forEach(threshold => {
    const { spaceType, bedroomCount, seasonOne, seasonTwo } = threshold;

    // Create the DTO for Season One
    spaceTypeThresholdDtos.push({
      id: seasonOne.id, // id from the seasonOne object
      siteId,
      siteOperatorId,
      spaceType: spaceType ?? undefined,
      bedroomCount: bedroomCount ?? undefined,
      thresholdSeason: ThresholdSeason.SeasonOne,
      seasonTarget: round(seasonOne.targetValue, 1),
    });

    // Create the DTO for Season Two
    spaceTypeThresholdDtos.push({
      id: seasonTwo.id, // id from the seasonTwo object
      siteId,
      siteOperatorId,
      spaceType: spaceType ?? undefined,  // null if no spaceType provided
      bedroomCount: bedroomCount ?? undefined, // null if no bedroomCount provided
      thresholdSeason: ThresholdSeason.SeasonTwo,
      seasonTarget: round(seasonTwo.targetValue, 1),
    });
  });

  return spaceTypeThresholdDtos;
};

/**
 * Map start and end date to a ThresholdSeason given that Season One is Oct - Mar and Season Two is Apr - Sep
 */
export const getSeasonFromDates = (startDate?: Dayjs, endDate?: Dayjs): ThresholdSeason | undefined => {
  // If either date is missing, return undefined
  if (!startDate || !endDate) {
    return undefined;
  }

  // Check if start date comes after end date
  if (isAfter(startDate.toDate(), endDate.toDate())) {
    return undefined;
  }

  // Helper function to determine the season based on a single month
  const getSeason = (month: number): ThresholdSeason => {
    return month >= 10 || month <= 3 ? ThresholdSeason.SeasonOne : ThresholdSeason.SeasonTwo;
  };

  const startMonth = startDate.month() + 1;
  const endMonth = endDate.month() + 1;

  const startSeason = getSeason(startMonth);
  const endSeason = getSeason(endMonth);

  // If both dates fall within the same season, return that season
  if (startSeason === endSeason) {
    return startSeason;
  }

  // If the dates fall across two different seasons, return undefined
  return undefined;
}

/**
 * Returns whether there are OPEN challenges of the same metric type within the date range
 */
export const isOverlappingOpenChallenge = (
  challenges: OperatorChallengeDto[], 
  metricType: MetricType, 
  startDate: Dayjs, 
  endDate: Dayjs,
  currentChallengeId?: number
): boolean => {
  const overlappingChallenges = challenges.filter(challenge => {
    const isDiffChallenge = challenge.id !== currentChallengeId;
    const isChallengeOpen = challenge.status === ChallengeStatus.Open;
    const isChallengeOfMetricType = challenge.metricType === metricType;
    const isChallengeWithinDateRange =
      dayjs(challenge.startDate).isBefore(dayjs(endDate)) &&
      dayjs(challenge.endDate).isAfter(dayjs(startDate));
    
    return isDiffChallenge && isChallengeOpen && isChallengeOfMetricType && isChallengeWithinDateRange;
  });

  return overlappingChallenges.length > 0;
};