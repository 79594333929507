import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import { EsgExplorerMetricsDto } from '@api/models/EsgExplorerMetricsDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EsgExplorerDataGetQuery extends PostQuery<EsgExplorerMetricsDto> {
  tenantId: string;
  configuration: EsgExplorerConfiguration;
  gasCalorificValue: number;

  constructor(tenantId: string, config: EsgExplorerConfiguration, gasCalorificValue: number) {
    super();
    this.tenantId = tenantId;
    this.configuration = config;
    this.gasCalorificValue = gasCalorificValue;
  }

  public targetUrl(): string {
    return '/esgExplorer/data';
  }
}
