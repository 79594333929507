import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { Title } from '@components/core/Title';
import { Card } from '@components/core/Card';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DeviceMetricsChart from '@components/charts/DeviceMetricsChart';
import { HistoricDataTimeRangeOptions } from '@utils/ChartUtils';

type PropTypes = {
  device: DeviceWithMetrics,
  isExternalAggregateDevice?: boolean
}

const HistoricData = ({ device, isExternalAggregateDevice }: PropTypes) => {
  const { t } = useTranslation();
  const { getDeviceConfig } = useDeviceConfigContext();
  const deviceConfig = getDeviceConfig(device.deviceModel);

  if (!deviceConfig) {
    return <Centered>{t('DeviceCard.DeviceNotSupported', { ns: 'molecules' })}</Centered>;
  }

  return (
    <>
      <Title
        text={t('HistoricData', { ns: 'molecules' })}
        wrapperStyle={{ paddingBottom: '20px' }}
      />

      <Card noPadding contentWidthOnMobile>
        <DeviceMetricsChart
          device={device}
          timeRangeOptions={HistoricDataTimeRangeOptions}
          defaultChartType={deviceConfig.ui.metricsChart.defaultChartType}
          hideChartTypeToggle={deviceConfig.ui.metricsChart.hideChartTypeToggle}
          chartOptions={{ ...deviceConfig.ui.metricsChart.chartOptions, tooltipLabelDecimalPlaces: 2 }}
          isExternalAggregateDevice={isExternalAggregateDevice}
        />
      </Card>
    </>
  );
}

export default HistoricData;

const Centered = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;