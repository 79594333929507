import { Site } from '@api/models/Site';
import { DeleteBlobQuery } from '@api/queries/common/DeleteBlobQuery';

export default class SiteImageDeleteCommand extends DeleteBlobQuery<Site> {
  siteId: number;

  constructor(blobName: string, siteId: number) {
    super(blobName);
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return '/sites/image';
  }
}
