import { NoteDto } from '@api/models/NoteDto';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export class NoteUpdateCommand extends PatchQuery<NoteDto> {
  content: string;

  constructor(id: number, content: string) {
    super(id);
    this.content = content;
  }

  public targetUrl(): string {
    return '/notes';
  }
}
