import { ClimateControlStatus } from '@api/enums/ClimateControlStatus';
import { useSiteContext } from '@pages/site/SiteProvider';
import Overview from './components/overview/Overview';
import Setup from './components/setup/Setup';
import ClimateControlPreview from './ClimateControlPreview';

const ClimateControl = () => {
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();

  if (!siteClimateControl || siteClimateControl.status === ClimateControlStatus.Disabled) {
    return <ClimateControlPreview />;
  }

  if (siteClimateControl.status === ClimateControlStatus.Setup) {
    return (
      <Setup
        siteClimateControl={siteClimateControl}
        refreshSiteClimateControl={refreshSiteClimateControl}
      />
    );
  }

  return (
    <Overview
      siteClimateControl={siteClimateControl}
      refreshSiteClimateControl={refreshSiteClimateControl}
    />
  );
};

export default ClimateControl;