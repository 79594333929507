import EsgExplorerRulePeriod from '@api/enums/EsgExplorerRulePeriod';
import EsgExplorerReportDto from '@api/models/EsgExplorerReportDto';
import EsgExplorerRuleDto from '@api/models/EsgExplorerRuleDto';
import EsgExplorerRuleDeleteCommand from '@api/queries/esg-explorer/EsgExplorerRuleDeleteCommand';
import { exportCSV } from '@utils/ExportUtils';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlobGetSasUriQuery } from '@api/queries/blob/BlobGetSasUriQuery';
import { Button } from '@components/core/Button';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { Modal } from '@components/core/Modal';
import { Table } from '@components/table/Table';
import { ITableColumn, TableSortOrder } from '@components/table/Table.types';
import { useApi } from '@hooks/useApi';
import { usePopover } from '@hooks/usePopover';
import axios from 'axios';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { transparentize } from 'polished';
import { RefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

export const getEsgExplorerScheduleMessage = (period: EsgExplorerRulePeriod, t: TFunction): string => {
  switch (period) {
    case EsgExplorerRulePeriod.Week:
      return t('ESG.RuleScheduleDescriptionWeek', { ns: 'molecules' });
    case EsgExplorerRulePeriod.Month:
      return t('ESG.RuleScheduleDescriptionMonth', { ns: 'molecules' });
    case EsgExplorerRulePeriod.Quarter:
      return t('ESG.RuleScheduleDescriptionQuarter', { ns: 'molecules' });
    default:
      return '';
  }
};

interface IRuleModal {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  toggle: () => void;
  rule: EsgExplorerRuleDto;
  refreshRules: () => void;
  siteName: string;
}

const RuleModal = ({ modalRef, isOpen, toggle, rule, refreshRules, siteName }: IRuleModal) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const { visible, toggle: togglePopover, ref } = usePopover({});

  const deleteRule = async () => {
    await execute({
      query: new EsgExplorerRuleDeleteCommand(rule.id),
      successMessage: t('RuleDeleteSuccess', { ns: 'status' }),
      errorMessage: t('RuleDeleteError', { ns: 'status' })
    });

    refreshRules();
  }

  const downloadBlob = useCallback(async (report: EsgExplorerReportDto) => {
    const fileName = `ESG Explorer_${siteName}_${format(new Date(report.fromDateTime), 'dd.MM.yyy')}-${format(new Date(report.toDateTime), 'dd.MM.yyy')}_${rule.template.name}.csv`;
    const blobUri = await execute({ query: new BlobGetSasUriQuery(report.blobName) });

    if (blobUri) {
      const response = await axios.get(blobUri.blobUri);
      exportCSV(fileName, response.data);
    }
  }, [execute, rule.template.name, siteName]);

  const tableColumns: ITableColumn<EsgExplorerReportDto>[] = useMemo(() => ([
    {
      label: t('From', { ns: 'common' }),
      key: 'fromDateTime',
      sortFormat: report => new Date(report.fromDateTime),
      displayFormat: report => format(new Date(report.fromDateTime), 'dd MMM yyyy'),
      width: 5
    },
    {
      label: t('To', { ns: 'common' }),
      key: 'toDateTime',
      sortFormat: report => new Date(report.toDateTime),
      displayFormat: report => format(new Date(report.toDateTime), 'dd MMM yyyy'),
      width: 5
    },
    {
      label: <CsvIcon icon={duotone('file-csv')} />,
      customElement: report => <DownloadIcon icon={duotone('arrow-down-to-bracket')} onClick={() => downloadBlob(report)} />,
      width: 2
    }
  ]), [downloadBlob, t]);

  const popoverContent = (
    <FlexRow style={{ width: '270px', padding: '15px', gap: '10px' }}>
      <WarningMessage>
        <WarningIcon icon={duotone('exclamation-triangle')} />
        {t('ESG.RuleDeletionWarning', { ns: 'molecules' })}
      </WarningMessage>

      <FlexRow style={{ gap: '15px', margin: '0 auto' }}>
        <Button
          small
          tertiary
          label={t('Cancel', { ns: 'common' })}
          onClick={togglePopover}
        />
        <Button
          small
          label={t('Confirm', { ns: 'common' })}
          color={theme.palette.red}
          onClick={deleteRule}
        />
      </FlexRow>
    </FlexRow>
  );

  return (
    <Modal
      ref={modalRef}
      hide={toggle}
      isOpen={isOpen}
      title={t('ESG.AutomationRule', { ns: 'molecules' })}
      width="700px"
      modalStyles={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
      dialogStyles={{ boxShadow: `0px 2px 12px 1px ${theme.palette.shadows.strong}` }}
    >
      <Column>
        <Section>
          <FlexRow style={{ padding: '7px 15px', justifyContent: 'space-between' }}>
            <Title>
              {t('ESG.Rule', { ns: 'molecules' })}
            </Title>

            <Popover
              ref={ref}
              visible={visible}
              popoverContent={popoverContent}
              placement={PopoverPlacement.Left}
              offsetX={2}
            >
              <Button
                cypress_id={'ESGExplorer_AutomationRuleModal_DeleteButton'}
                tertiary
                label={t('Delete', { ns: 'common' })}
                color={theme.palette.red}
                onClick={togglePopover}
              />
            </Popover>
          </FlexRow>

          <Divider />

          <FlexRow style={{ padding: '15px' }}>
            <div>
              <Label>
                {t('ESG.Name', { ns: 'molecules' })}
              </Label>
              <Value>
                {rule.name}
              </Value>
            </div>

            <div>
              <Label>
                {t('ESG.Schedule', { ns: 'molecules' })}
              </Label>
              <Value>
                {EsgExplorerRulePeriod.getDisplayString(rule.period)}
              </Value>
            </div>

            <div>
              <Label>
                {t('ESG.Template', { ns: 'molecules' })}
              </Label>
              <Value>
                {rule.template.name}
              </Value>
            </div>
          </FlexRow>

          <ScheduleMessage>
            <ScheduleMessageIcon icon={regular('calendar-range')} />
            {getEsgExplorerScheduleMessage(rule.period, t)}
          </ScheduleMessage>
        </Section>

        <Section>
          <FlexRow style={{ padding: '15px' }}>
            <Title>
              {t('ESG.Emails', { ns: 'molecules' })}
            </Title>
          </FlexRow>

          <Divider />

          <Column style={{ padding: '15px' }}>
            {rule.emails.sort().map((email, i) => (
              <Value key={`${i}-${uuidv4()}`}>
                {email}
              </Value>
            ))}
          </Column>
        </Section>

        <Table
          columns={tableColumns}
          records={rule.reports}
          recordKey='id'
          defaultSortColumn="fromDateTime"
          defaultSortOrder={TableSortOrder.DESC}
          emptyMessage={t('ESG.NoReports', { ns: 'molecules' })}
          height={200}
          wrapBreakpoint={300}
          compact
          cardEffect
          card={{
            cardTitle: t('ESG.Reports', { ns: 'molecules' }),
            titleWrapperStyle: { height: '42px', borderColor: theme.palette.borders.medium },
            titleStyle: { paddingLeft: '15px' },
            hideSemiCircle: true,
            style: {
              border: `1px solid ${transparentize(0.2, theme.palette.borders.medium)}`,
              boxShadow: `0 1px 3px 0px ${theme.palette.shadows.medium}`
            }
          }}
        />
      </Column>
    </Modal>
  );
};

export default RuleModal;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 40px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.forms.label.color};
  margin-bottom: 4px;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px; 
  border-radius: 5px;
  border: 1px solid ${p => transparentize(0.2, p.theme.palette.borders.medium)};
  box-shadow: 0 1px 3px 0px ${p => p.theme.palette.shadows.medium};
`;

const CsvIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  color: ${p => p.theme.palette.green};
  cursor: pointer;
`;

const DownloadIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  padding: 6px;
  border-radius: 50%;
  color: ${p => transparentize(0.1, p.theme.palette.primary)};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;

const ScheduleMessageIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 7px;
  color: ${p => transparentize(0.2, p.theme.palette.primary)};
`;

const ScheduleMessage = styled.div`
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  padding: 5px 17px 15px 17px;
`;

const WarningMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: ${p => p.theme.palette.text.medium};
  display: flex;
  gap: 12px;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 34px;
  color: ${p => p.theme.palette.orange};
  margin-top: 2px;
`;