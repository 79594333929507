import { SpaceType } from '@api/enums/SpaceType';
import { Space } from '@api/models/Space';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class SpaceUpdateCommand extends PatchQuery<Space> {
  name: string;
  spaceType: SpaceType;
  maxOccupancy?: number;
  area?: number;
  xCoordinate?: number;
  yCoordinate?: number;
  occupied: boolean;

  constructor(space: Space) {
    super(space.id);
    this.name = space.name;
    this.spaceType = space.spaceType;
    this.maxOccupancy = space.maxOccupancy;
    this.area = space.area;
    this.xCoordinate = space.xCoordinate;
    this.yCoordinate = space.yCoordinate;
    this.occupied = space.occupied;
  }

  public targetUrl(): string {
    return '/spaces';
  }
}
