import { SpaceFairUsePolicy } from '@api/models/SpaceFairUsePolicy';
import { PostQuery } from '../../common/PostQuery';

export class SpaceFairUsePolicyBulkCreateCommand extends PostQuery<SpaceFairUsePolicy> {
  policyId: number;
  exceptions: SpaceFairUsePolicy[];

  constructor(policyId: number, exceptions: SpaceFairUsePolicy[]) {
    super();
    this.policyId = policyId;
    this.exceptions = exceptions;
  }

  public targetUrl(): string {
    return '/FairUse/Exceptions/BulkCreate'
  }
}
