import { Button } from '@components/core/Button';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@components/core/Modal';
import ConsentPreferencesContent from './ConsentPreferencesContent';
import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { env } from '@src/env/env';

export const getCookieConsent = (): ConsentCookie | undefined => {
  const cookie = Cookie.get('consent');

  if (!cookie) {
    return undefined;
  } else {
    return JSON.parse(cookie);
  }
};

// update consent
export const updateConsent = (consents: Array<{ consentType: ConsentType, value: boolean }>) => {
  consents.forEach(({ consentType, value }) => {
    // remove local storage if funcational storage is denied
    if (consentType === ConsentType.functional && !value) {
      localStorage.removeItem('FLOORPLAN_HEATMAP_VIEW');
      localStorage.removeItem('UtopiConnectTheme');
    }
  });

  // update consent cookie
  const updatedCookie = consents.reduce((acc, { consentType, value }) => {
    acc[consentType] = value;
    return acc;
  }, {} as { [key in ConsentType]: boolean });

  Cookie.set('consent', JSON.stringify(updatedCookie), {
    sameSite: 'strict',
    expires: 365,
  });
};

export const DEFAULT_COOKIE_CONSENT = {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied',
  'personalization_storage': 'denied',
  'security_storage': 'granted',
  'functionality_storage': 'denied',
}

export enum ConsentType {
  functional = 'functional',
  analytical = 'analytical'
}

export type ConsentCookie = {
  functional?: boolean,
  analytical?: boolean
}

const ConsentBanner = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const [showCookieBanner, setShowCookieBanner] = useState(Cookie.get('consent') === undefined);

  useEffect(() => {
    if (showCookieBanner) {
      setShowCookieBanner(true);
    }
  }, [setShowCookieBanner, showCookieBanner]);

  const onAcceptClick = () => {
    setShowCookieBanner(false);
    updateConsent([{ consentType: ConsentType.analytical, value: true }, { consentType: ConsentType.functional, value: true }]);
  };

  const onRejectClick = () => {
    setShowCookieBanner(false);
    updateConsent([{ consentType: ConsentType.analytical, value: false }, { consentType: ConsentType.functional, value: false }]);
  };

  const [showConsentPreferencesModal, setShowConsentPreferencesModal] = useState(false);

  const handleManagePreferencesClick = () => {
    setShowConsentPreferencesModal(true);
    setShowCookieBanner(false);
  };

  return (
    <>
      {showCookieBanner && (
        <ConsentBannerWrapper>
          <IconButton
            onClick={onRejectClick}
            aria-label={t('ConsentBanner.CloseBannerAriaLabel')}
          >
            <FontAwesomeIcon icon={faXmark} color={theme.palette.systemMessage.xmark} size="lg" />
          </IconButton>
          <div>
            <Header>{t('ConsentBanner.Title')}</Header>
            <Description>
              <Trans i18nKey="molecules:ConsentBanner.Description">
                <Link
                  className='link'
                  href={env.externalLinks.privacyPolicy}
                  target="_blank"
                />
              </Trans>
            </Description>
          </div>
          <ButtonsWrapper>
            <Button
              label={t('ConsentBanner.ManagePreferences')}
              onClick={handleManagePreferencesClick}
              secondary
              small
              style={{ width: '100%' }}
            />
            <Button
              label={t('ConsentBanner.RejectAll')}
              onClick={onRejectClick}
              secondary
              small
              style={{ width: '100%' }}
            />
            <Button
              label={t('ConsentBanner.AcceptAll')}
              onClick={onAcceptClick}
              small
              style={{ width: '100%' }}
            />
          </ButtonsWrapper>
        </ConsentBannerWrapper>
      )}

      <Modal isOpen={showConsentPreferencesModal} plainModal>
        <ConsentPreferencesContent
          onUpdatePreferenceCallback={() => setShowConsentPreferencesModal(false)}
        />
      </Modal>
    </>
  );
};

export default ConsentBanner;

const ConsentBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.backgrounds.surface};
  padding: 25px 65px 25px 30px;
  box-shadow: 0px 2px 3px 0px ${(p) => p.theme.palette.shadows.light}, 0px 6px 10px 4px ${(p) => p.theme.palette.shadows.extraLight};
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10000;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 20px;
  right: 30px;
  padding: 0;
  cursor: pointer;
`;

const Header = styled.h3`
  font-weight: 600;
  font-size: 16px;
  margin: 0;
`;

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.fair};
  padding: 10px 0 0 0;
  font-weight: 500;
  line-height: 1.5;
  max-width: 1000px;
`;

const Link = styled.a`
  &.link {
    text-decoration: underline;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;