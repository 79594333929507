import { useSiteContext } from '@pages/site/SiteProvider';
import { ReactNode } from 'react';
import ResidentAppPreview from '@pages/site/resident-app/ResidentAppPreview';
import { useTranslation } from 'react-i18next';
import LoadingWidget from '@components/core/LoadingWidget';

type PageWrapperProps = {
  children: ReactNode;
}

const PageWrapper = ({children}: PageWrapperProps) => {
  const { t } = useTranslation('status');
  const { siteOperator, loadingSiteOperator } = useSiteContext();

  if (loadingSiteOperator) {
    return (
      <LoadingWidget
        label={t('Loading')}
        styles={{ marginTop: 80 }}
      />
    );
  }

  if (!siteOperator) {
    return <ResidentAppPreview />;
  }

  return (
    <>
      {children}
    </>
  )
};

export default PageWrapper;