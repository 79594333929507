import { TemperatureControlDeviceStatus } from '@api/enums/TemperatureControlDeviceStatus';
import { SpaceClimateControlDetailsDto } from '@api/models/SpaceClimateControlDetailsDto';
import { HierarchyBuilding } from '@api/models/Hierarchy';
import { Button } from '@components/core/Button';
import { ICascaderMultiSelectNodeGroup } from '@components/cascader-multi-select/CascaderMultiSelect.types';
import { SearchField } from '@components/search-field/SearchField';
import { useMediaQuery } from '@hooks/useMediaQuery';
import Filter from '@components/core/Filter';
import { LocationFilter } from '@components/core/LocationFilter';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import StepDownModeFilter from './StepDownModeFilter';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';

type TableHeaderProps = {
  tableData: SpaceClimateControlDetailsDto[];
  buildings: HierarchyBuilding[];
  hasFilters: boolean;
  stepDownFilterEnabled: boolean;
  siteClimateControl: SiteClimateControlDto;
  onSearchChange: (searchString: string) => void;
  onLocationChange: (building: HierarchyBuilding[] | undefined) => void;
  onStatusChange: (status: TemperatureControlDeviceStatus[]) => void;
  onStepDownFilterChange: (state: boolean) => void;
}

const TableHeader = ({ tableData, buildings, hasFilters, stepDownFilterEnabled, siteClimateControl, onSearchChange, onLocationChange, onStatusChange, onStepDownFilterChange }: TableHeaderProps) => {
  const { t } = useTranslation();
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<HierarchyBuilding>>();
  const [resetPulse, setResetPulse] = useState<number>(0);
  const onMobile = useMediaQuery('(max-width: 900px)');

  const temperatureControlDeviceStatus = useMemo(() => {
    return Object.values(TemperatureControlDeviceStatus).map(x => ({ label: t(x, { ns: 'enums' }), value: x }));
  }, [t]);

  useEffect(() => {
    const buildingIds = tableData.map(x => x.buildingId);
    const options: ICascaderMultiSelectNodeGroup<HierarchyBuilding> = {
      header: t('DeviceManagement.Buildings', { ns: 'settingsDevice' }),
      nodes: orderBy(buildings.filter(x => buildingIds.includes(x.id)), x => x.name.toLocaleLowerCase()).map(building => ({
        label: `${building.name}`,
        value: building,
      }))
    }
    setCascaderOptions(options);
  }, [buildings, t, tableData]);

  const resetFilters = useCallback(() => {
    onStepDownFilterChange(false);
    setResetPulse(prev => prev + 1);
  }, [onStepDownFilterChange]);

  return (
    <ActionsMenu>
      <SearchField
        placeholder={t('Search', { ns: 'common' })}
        onSearchChange={onSearchChange}
      />
      <Filters>
        {hasFilters &&
          <Button
            tertiary
            label={t('ClearFilters', { ns: 'common' })}
            onClick={resetFilters}
          />
        }

        <StepDownModeFilter
          siteClimateControl={siteClimateControl}
          checked={stepDownFilterEnabled}
          onChange={onStepDownFilterChange}
        />

        {cascaderOptions &&
          <LocationFilter
            label={t('Building', { ns: 'common' })}
            group={cascaderOptions}
            onChange={onLocationChange}
            resetPulse={resetPulse}
            position={onMobile ? 'left' : 'right'}
          />
        }

        <Filter
          label={t('Status', { ns: 'common' })}
          options={temperatureControlDeviceStatus}
          onChange={onStatusChange}
          resetPulse={resetPulse}
          rightAlign={!onMobile}
        />
      </Filters>
    </ActionsMenu>
  )
}

export default TableHeader;

const ActionsMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 375px) {
    gap: 5px;
    flex-wrap: wrap;
  }
`;