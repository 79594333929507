import { LettingStatus } from '@api/enums/LettingStatus';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { Space } from '@api/models/Space';
import { SpaceConsentDto } from '@api/models/SpaceConsentDto';
import SpaceConsentCreateOrUpdateCommand from '@api/queries/space-consent/SpaceConsentCreateOrUpdateCommand';
import { Button } from '@components/core/Button';
import { Checkbox } from '@components/checkbox/Checkbox';
import { ErrorMessage, Label } from '@components/Form';
import { LabelValuePair } from '@components/core/LabelValuePair';
import SystemMessage, { SystemMessageType } from '@components/core/SystemMessage';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { Modal } from '@components/core/Modal';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useApi } from '@hooks/useApi';
import { dateToUtcDate } from '@utils/DateUtils';
import RadioInputFormField from '@components/core/RadioInputFormField';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { transparentize } from 'polished';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type FormValues = {
  lettingStatus: LettingStatus;
  consentGranted: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  termsAccepted: boolean;
}

type PropTypes = {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  toggle: () => void;
  space: Space;
  device: DeviceWithMetrics;
  consent?: SpaceConsentDto;
  refreshConsent: () => void;
  refreshConsentHistory: () => void;
}

const ConsentModal = ({ modalRef, isOpen, toggle, space, device, consent, refreshConsent, refreshConsentHistory }: PropTypes) => {
  const methods = useForm<FormValues>();
  const theme = useTheme();
  const { tenant } = useTenantContext();
  const { localisation } = useLocalisation();
  const { t } = useTranslation();
  const { execute, loading } = useApi();
  const [hasApiError, setHasApiError] = useState(false);

  // Form values
  const lettingStatus = methods.watch('lettingStatus');
  const consentGranted = methods.watch('consentGranted');
  const startDate = methods.watch('startDate');
  const endDate = methods.watch('endDate');
  const termsAccepted = methods.watch('termsAccepted');

  // Conditionals
  const isLetWithConsent = useMemo(() => (
    lettingStatus === LettingStatus.Let && consentGranted === 'true'
  ), [lettingStatus, consentGranted]);

  const isConsentGranted = useMemo(() => (
    consentGranted === 'true'
  ), [consentGranted]);

  const previouslyAcceptedTerms = useMemo(() => (
    consent?.lettingStatus === LettingStatus.Let && consent.consentGranted
  ), [consent]);

  const mustAcceptTerms = useMemo(() => (
    isLetWithConsent && !previouslyAcceptedTerms || !termsAccepted
  ), [isLetWithConsent, previouslyAcceptedTerms, termsAccepted]);

  const startDateInThePast = !!startDate && startDate < dayjs().startOf('day') && isLetWithConsent;

  // Reset form values
  const resetForm = useCallback(() => {
    if (consent) {
      methods.setValue('lettingStatus', consent.lettingStatus);
      methods.setValue('consentGranted', consent.consentGranted ? 'true' : 'false');
      methods.setValue('startDate', consent.startDate ? dayjs(consent.startDate) : null);
      methods.setValue('endDate', consent.endDate ? dayjs(consent.endDate) : null);
      methods.setValue('termsAccepted', previouslyAcceptedTerms);
    } else {
      methods.setValue('lettingStatus', LettingStatus.Unlet);
      methods.setValue('consentGranted', 'false');
      methods.setValue('startDate', null);
      methods.setValue('endDate', null);
      methods.setValue('termsAccepted', false);
    }
  }, [methods, consent, previouslyAcceptedTerms]);

  const onSave: SubmitHandler<FormValues> = useCallback(async (data) => {
    const isLet = data.lettingStatus === LettingStatus.Let;
    const consentGranted = data.consentGranted === 'true';

    /**
     * StartDate value:
     * - if !consentGranted => null
     * - if consentGranted => data.startDate
     */
    const startDate = !consentGranted
      ? null
      : data.startDate && dateToUtcDate(data.startDate).toISOString();

    /**
     * EndDate value:
     * - if !consentGranted => null
     * - if consentGranted && !isLet => null
     * - if consentGranted && isLet => data.endDate
     */
    const endDate = !consentGranted || !isLet
      ? null
      : data.endDate && dateToUtcDate(data.endDate).toISOString();

    const response = await execute({
      query: new SpaceConsentCreateOrUpdateCommand(
        `Utopi - ${tenant.name}`,
        device.deviceIdentifier,
        space.id,
        data.lettingStatus,
        consentGranted,
        startDate,
        endDate
      ),
      displayApiError: true,
      successMessage: t('ChangesSaveSuccess', { ns: 'status' })
    });

    if (!response) {
      setHasApiError(true);
      return;
    }

    refreshConsent();
    refreshConsentHistory();
    toggle();
    setHasApiError(false);
  }, [device, space, tenant, execute, refreshConsent, refreshConsentHistory, toggle, t]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleCancel = useCallback(() => {
    toggle();
    resetForm();
    setHasApiError(false);
  }, [toggle, resetForm]);

  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      hide={toggle}
      plainModal={true}
      width={'600px'}
      dialogStyles={{ marginTop: '8vh' }}
    >
      <FormProvider {...methods}>
        <Container>
          <Title>
            {t('SmartMetering', { ns: 'common' })}
          </Title>

          {hasApiError &&
            <SystemMessage
              messages={[
                <>
                  {t('SmartMeter.MPxNNotFound', { ns: 'molecules' })}
                  <ProtectedLink link={{ path: `./../../../../../../settings/devices/${device.id}` }}>
                    <Button
                      tertiary
                      label={t('GoToDevice', { ns: 'navigation' })}
                      style={{ flexShrink: 0 }}
                    />
                  </ProtectedLink>
                </>
              ]}
              type={SystemMessageType.Error}
              hideIcon={false}
              styles={{
                borderWidth: 1,
                backgroundColor: transparentize(0.9, theme.palette.systemMessage.error)
              }}
            />
          }

          <FlexRow>
            <LabelValuePair
              label={t('Space', { ns: 'common' })}
              value={space.name}
              size="xs"
            />
            <LabelValuePair
              label={t('DataSource', { ns: 'common' })}
              value={device.manufacturer}
              size="xs"
            />
            <LabelValuePair
              label={t('SmartMeter.MPxN', { ns: 'molecules' })}
              value={device.deviceIdentifier}
              size="xs"
              tooltip={{
                content: <TooltipBody>{t('SmartMeter.MPxNDescription', { ns: 'molecules' })}</TooltipBody>,
                placement: TooltipPlacement.Bottom
              }}
            />
          </FlexRow>

          <FlexColumn style={{ gap: 12 }}>
            <Title style={{ fontSize: 16 }}>
              {t('LettingStatus', { ns: 'common' })}
            </Title>

            <RadioInputFormField
              name="lettingStatus"
              id={LettingStatus.Let}
              label={t('Let', { ns: 'common' })}
            />
            <RadioInputFormField
              name="lettingStatus"
              id={LettingStatus.Unlet}
              label={t('NotLet', { ns: 'common' })}
            />
          </FlexColumn>

          <FlexColumn style={{ gap: 12 }}>
            <Title style={{ fontSize: 16 }}>
              {t('SmartMeteringData', { ns: 'common' })}
            </Title>

            <RadioInputFormField
              name="consentGranted"
              id="true"
              label={t('SmartMeter.CollectDataForSpace', { ns: 'molecules' })}
            />
            <RadioInputFormField
              name="consentGranted"
              id="false"
              label={<Trans i18nKey="molecules:SmartMeter.DoNotCollectDataForSpace" />}
            />
          </FlexColumn>

          {isConsentGranted &&
            <FlexRow>
              <div>
                <Label>
                  {t('StartDate', { ns: 'common' })}
                </Label>

                <Controller
                  control={methods.control}
                  name={'startDate'}
                  rules={{ required: isConsentGranted && t('AStartDateIsRequired', { ns: 'validation' }) }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value}
                      minDate={device.lastMeasuredOn ? dayjs() : undefined}
                      format={localisation.dateFormats.datepickerAlternative}
                      onChange={date => {
                        onChange(date);

                        if (endDate && endDate <= date) {
                          methods.setValue('endDate', null);
                        }
                      }}
                      style={{ width: '100%' }}
                    />
                  )}
                />
                <ErrorMessage gap red>{methods.formState.errors.startDate?.message}</ErrorMessage>
              </div>

              <div>
                <Label>
                  {t('EndDateOptional', { ns: 'common' })}
                </Label>

                <Controller
                  control={methods.control}
                  name={'endDate'}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value}
                      format={localisation.dateFormats.datepickerAlternative}
                      minDate={startDate?.add(1, 'day')}
                      onChange={onChange}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </div>
            </FlexRow>
          }

          {startDateInThePast &&
            <SystemMessage
              messages={[t('SmartMeter.BackfillMessage', { ns: 'molecules' })]}
              hideIcon={false}
              styles={{
                borderWidth: 1,
                backgroundColor: transparentize(0.9, theme.palette.systemMessage.information)
              }}
            />
          }

          {isLetWithConsent &&
            <>
              <Divider />

              <div>
                <Title style={{ fontSize: 16 }}>
                  {t('SmartMeter.Terms.Title', { ns: 'molecules' })}
                </Title>

                <TermsContainer>
                  {t('SmartMeter.Terms.Intro', { ns: 'molecules' })}
                  <List>
                    <li>{t('SmartMeter.Terms.P1', { ns: 'molecules' })}</li>
                    <li>{t('SmartMeter.Terms.P2', { ns: 'molecules' })}</li>
                    <li>{t('SmartMeter.Terms.P3', { ns: 'molecules' })}</li>
                    <li>{t('SmartMeter.Terms.P4', { ns: 'molecules' })}</li>
                    <li>{t('SmartMeter.Terms.P5', { ns: 'molecules' })}</li>
                  </List>
                </TermsContainer>

                <Controller
                  control={methods.control}
                  name={'termsAccepted'}
                  rules={{ required: mustAcceptTerms && t('MustAcceptTerms', { ns: 'validation' }) }}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      checked={value}
                      onChange={checked => onChange(checked)}
                      label={t('SmartMeter.Terms.Agree', { ns: 'molecules' })}
                    />
                  )}
                />
                <ErrorMessage gap red>{methods.formState.errors.termsAccepted?.message}</ErrorMessage>
              </div>
            </>
          }

          <Footer>
            <Button
              tertiary
              label={t('Cancel', { ns: 'common' })}
              color={theme.error.main}
              onClick={handleCancel}
              disabled={loading}
            />
            <Button
              label={t('Save', { ns: 'common' })}
              onClick={methods.handleSubmit(onSave)}
              loading={loading}
            />
          </Footer>
        </Container>
      </FormProvider>
    </Modal>
  );
};

export default ConsentModal;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexColumn)`
  padding: 24px;
  gap: 24px;
  font-size: 14px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  > * {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
`;

const TooltipBody = styled.div`
  width: 260px;
  padding: 12px;
`;

const Divider = styled.div`
  border-top: 1px solid ${p => p.theme.action.divider};
`;

const TermsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
  padding: 20px 20px 5px 20px;
  margin: 10px 0;
  box-shadow: inset 0 0 7px 0px ${p => p.theme.palette.shadows.extraLight};
  border-radius: 4px;
`;

const List = styled.ol`
  margin-top: 15px;
  padding-inline-start: 24px;
  color: ${p => p.theme.text.primary};

  li {
    margin: 15px 0;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
`;