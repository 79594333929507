import PeopleCountingPhysicalDevice from '@api/models/PeopleCountingPhysicalDevice';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class PeopleCountingUpdateCommand extends PatchQuery<PeopleCountingPhysicalDevice> {
  deviceId: number;
  virtualDeviceId: number;
  direction: string;

  constructor(deviceId: number, virtualDeviceId: number, direction: string) {
    super();
    this.deviceId = deviceId;
    this.virtualDeviceId = virtualDeviceId;
    this.direction = direction;
  }

  public targetUrl(): string {
    return '/peoplecounting';
  }
}
