import styled from 'styled-components';
import utopiAppIcon from '@assets/images/utopi-app-icon.png'

type NotificationPreviewProps = {
  title: string;
  text: string;
};

const NotificationPreview = ({title, text}: NotificationPreviewProps) => {
  return (
    <NotificationPreviewWrapper>
      <Content>
        <AppIcon src={utopiAppIcon} />
        <div>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </div>
      </Content>
      <LightText>2m ago</LightText>
    </NotificationPreviewWrapper>
  )
};

export default NotificationPreview;

const NotificationPreviewWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 14px 9px 9px;
  background-color: ${(p) => p.theme.background.container};
  box-shadow: 0px 2px 12px 0px ${(p) => p.theme.palette.shadows.extraLight};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const AppIcon = styled.img`
  width: 38px;
  height: 38px;
`;

const Title = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 15px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 15px;
`;

const LightText = styled.span`
  font-size: 12px;
  color: ${(p) => p.theme.text.light};
`;