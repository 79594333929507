import React, { useContext, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { Space } from '@api/models/Space';
import { Route as RouteType } from '@src/types/Route';
import { useApiState } from '@hooks/useApiState';
import { DevicesWithLatestMetricsGetBySpaceIdQuery } from '@api/queries/devices/DevicesWithLatestMetricsGetBySpaceIdQuery';
import { ProtectedRoute } from '@src/navigation/ProtectedRoute/ProtectedRoute';
import { SpaceClimateControlGetBySpaceQuery } from '@api/queries/climate-control/Space/SpaceClimateControlGetBySpaceQuery';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { SpaceType } from '@api/enums/SpaceType';
import SpaceGetByIdQuery from '@api/queries/spaces/SpaceGetByIdQuery';
import NoData from '@components/core/NoData';
import styled from 'styled-components';
import Sidebar from '@pages/site/buildings/floor/space/components/layout/Sidebar';
import LoadingWidget from '@components/core/LoadingWidget';
import { NoteDiscriminator } from '@api/enums/NoteDiscriminator';
import { NotesGetQuery } from '@api/queries/notes/NotesGetQuery';
import { NoteTypes } from '@api/models/NoteDto';

const generateDeviceNote = (note: NoteTypes, devices?: DeviceWithMetrics[]): NoteTypes => {
  const device = devices?.find(x => x.id === note.discriminatorId);

  return {
    ...note,
    deviceFriendlyName: device?.friendlyName
  };
};

type PropTypes = {
  routes?: RouteType[]
}

interface ISpaceContext {
  space: Space;
  devices?: DeviceWithMetrics[];
  spaceClimateControl?: SpaceClimateControlDto;
  refreshSpaceClimateControl: () => void;
  showExternalAggregatedDeviceSummary?: boolean;
  spaceNotes: NoteTypes[];
  refreshSpaceNotes: () => void;
  deviceNotes: NoteTypes[];
  refreshDeviceNotes: () => void;
}

export const SpaceContext = React.createContext({} as ISpaceContext);
export const useSpaceContext = () => useContext(SpaceContext);

const SpaceProvider = ({ routes }: PropTypes) => {
  const { t } = useTranslation();
  const { spaceId } = useParams<{ spaceId: string }>();

  const { data: space, loading: loadingSpace } = useApiState({
    query: spaceId === undefined ? undefined : new SpaceGetByIdQuery(parseInt(spaceId)),
  }, [spaceId]);

  const { data: devices, loading: loadingDevices } = useApiState({
    query: space && new DevicesWithLatestMetricsGetBySpaceIdQuery(space.id),
  }, [space]);

  const { data: spaceClimateControl, execute: refreshSpaceClimateControl } = useApiState({
    query: space && new SpaceClimateControlGetBySpaceQuery(space.id)
  }, [space]);

  const { data: spaceNotes, execute: refreshSpaceNotes } = useApiState({
    query: space && new NotesGetQuery(NoteDiscriminator.Space, [space.id]),
    initialState: []
  }, [space]);

  const { transform: deviceNotes, execute: refreshDeviceNotes } = useApiState({
    query: devices && new NotesGetQuery(NoteDiscriminator.Device, devices.map(x => x.id)),
    transformFunc: notes => notes.map(x => generateDeviceNote(x, devices)),
    initialTransformState: []
  }, [devices]);

  const spaceContextProviderValue = useMemo(() => {
    // if space doesn't exist then set temporary initial space to resolve type error (but will not render as will return null if space is null)
    const initialSpace = space ?? { id: 0, name: '', spaceType: SpaceType.Other, occupied: false };

    return { space: initialSpace, devices: devices, spaceClimateControl, spaceNotes, refreshSpaceNotes, deviceNotes, refreshDeviceNotes, refreshSpaceClimateControl }
  }, [space, devices, spaceClimateControl, spaceNotes, refreshSpaceNotes, deviceNotes, refreshDeviceNotes, refreshSpaceClimateControl]);

  if (loadingSpace) {
    return (
      <LoadingWidget
        label={t('LoadingSpace', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  if (loadingDevices) {
    return (
      <LoadingWidget
        label={t('LoadingDevices', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  if (!space) {
    return (
      <NoData
        label={t('SpaceNotFound', { ns: 'status' })}
        styles={{ margin: 80 }}
      />
    );
  }

  return (
    <SpaceContext.Provider value={spaceContextProviderValue}>
      <FlexRow>
        <Sidebar space={space} devices={devices} />

        <Content>
          <Routes>
            {routes?.map(route => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    route={route}
                    customProps={{ space: space, devices: devices }}
                  />
                }
              />
            ))}
          </Routes>
        </Content>

      </FlexRow>
    </SpaceContext.Provider>
  );
};

export default SpaceProvider;

const FlexRow = styled.div`
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;