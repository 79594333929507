import { Locales } from '@api/enums/Locales';
import { UserPreference } from '@api/models/UserPreference';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class UserPreferenceUpdateCommand extends PatchQuery<UserPreference> {
  userId: number;
  locale: Locales;

  constructor(userId: number, locale: Locales) {
    super();
    this.userId = userId;
    this.locale = locale;
  }

  public targetUrl(): string {
    return '/UserPreferences';
  }
}