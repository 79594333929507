import { Card } from '@components/core/Card';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SiteOperatorInfoField from './SiteOperatorInfoField';
import { SiteOperatorDto } from '@api/models/SiteOperatorDto';

type SiteOperatorInfoProps = {
  siteOperator?: SiteOperatorDto;
}

const SiteOperatorInfo = ({ siteOperator }: SiteOperatorInfoProps) => {
  const { t } = useTranslation(['molecules', 'status']);

  return (
    <Card>
      <Info>
        <SiteOperatorInfoField
          label={t('ResidentApp.OperatorContactEmail')}
          value={siteOperator?.email}
        />
      </Info>
    </Card>
  );
};

export default SiteOperatorInfo;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;