import UtilityTabs from './UtilityTabs';
import { Title } from '@components/core/Title';
import { useTranslation } from 'react-i18next';
import LoadingWidget from '@components/core/LoadingWidget';
import { Card } from '@components/core/Card';
import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useMemo, useState } from 'react';
import { LocalisationFunction, useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import UtilitiesSummaryPreview from './UtilityWidgetPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import useSiteMetricTypes from '@hooks/useSiteMetricTypes';
import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';
import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { calculateM3ToKwh } from '@utils/EnergyUtils';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export type TUtilityTab = {
  meterType: EnergyMeterType;
  metricType: MetricType;
  unit: string;
  modifier?: (x: number) => number;
  conversion?: {
    unit: string;
    modifier: (x: number) => number;
  };
  isGasWidget?: boolean;
  icon: {
    defintion: IconDefinition;
    width: number;
  }
};

export const getUtilityTabs = (toLocale: LocalisationFunction, getUnit: (metric: MetricType | AltUnits) => string, gasCalorificValue?: number): TUtilityTab[] => {
  return [
    {
      meterType: EnergyMeterType.Electricity,
      metricType: MetricType.ElectricityKwhDelta,
      unit: 'kWh',
      conversion: {
        unit: 'MWh',
        modifier: x => x === null ? NaN : x / 1000,
      },
      icon: { defintion: solid('bolt'), width: 13 }
    },
    {
      meterType: EnergyMeterType.Heating,
      metricType: MetricType.HeatingKwhDelta,
      unit: 'kWh',
      conversion: {
        unit: 'MWh',
        modifier: x => x === null ? NaN : x / 1000,
      },
      icon: { defintion: solid('fire-flame-curved'), width: 13 }
    },
    {
      meterType: EnergyMeterType.Cooling,
      metricType: MetricType.CoolingKwhDelta,
      unit: 'kWh',
      conversion: {
        unit: 'MWh',
        modifier: x => x === null ? NaN : x / 1000,
      },
      icon: { defintion: solid('snowflake'), width: 13 }
    },
    {
      meterType: EnergyMeterType.Gas,
      metricType: MetricType.GasVolumeDelta,
      unit: getUnit(MetricType.GasVolumeDelta),
      modifier: x => toLocale(MetricType.GasVolumeDelta, x),
      conversion: gasCalorificValue ? {
        unit: 'kWh',
        modifier: x => calculateM3ToKwh(gasCalorificValue, x)
      } : undefined,
      icon: { defintion: solid('fire'), width: 15 }
    },
    {
      meterType: EnergyMeterType.Water,
      metricType: MetricType.WaterVolumeDelta,
      unit: getUnit(MetricType.WaterVolumeDelta),
      modifier: x => toLocale(MetricType.WaterVolumeDelta, x),
      icon: { defintion: regular('droplet'), width: 13 }
    }
  ];
};

const SiteUtilitiesWidget = () => {
  const { t } = useTranslation();
  const { toLocale, getUnit } = useLocalisation();
  const { site, siteMetadata } = useSiteContext();
  const [conversions, setConversions] = useState<{ [key: string]: string }>({});
  const { siteMetricTypes, loading: loadingSiteMetrics } = useSiteMetricTypes(site.id);
  const utilities = useMemo(() => getUtilityTabs(toLocale, getUnit, siteMetadata?.gasCalorificValue), [toLocale, getUnit, siteMetadata]);
  const availableUtilities = useMemo(() => siteMetricTypes ? utilities.filter(x => siteMetricTypes.includes(x.metricType)) : [], [siteMetricTypes, utilities]);

  if (!loadingSiteMetrics && availableUtilities.length === 0) {
    return <UtilitiesSummaryPreview />;
  }

  return (
    <Card noPadding style={{ padding: 32 }}>
      <FlexColumn>
        <FlexRow>
          <Title text={t('Utilities', { ns: 'common' })} />

          <InfoIconWrapper>
            <Tooltip
              placement={TooltipPlacement.Bottom}
              fixedPosition={{ x: 14, y: 20 }}
              content={
                <TooltipContent>
                  {t('SiteOverview.ConsumptionTooltip', { ns: 'molecules' })}
                </TooltipContent>
              }
            >
              <InfoIcon
                icon={regular('circle-info')}
              />
            </Tooltip>
          </InfoIconWrapper>
        </FlexRow>

        {loadingSiteMetrics &&
          <LoadingWidget
            icon={duotone('chart-column')}
            label={t('SiteOverview.LoadingUtilitySummary', { ns: 'molecules' })}
            styles={{ height: 320, justifyContent: 'center' }}
            iconStyles={{ width: 30 }}
          />
        }

        <UtilityTabs
          utilities={availableUtilities}
          conversions={conversions}
          onSetConversions={setConversions}
        />
      </FlexColumn>
    </Card>
  );
};

export default SiteUtilitiesWidget;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 530px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
`;

const InfoIconWrapper = styled.div`
  margin: -2px 5px 0 3px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const TooltipContent = styled.div`
  width: 275px;
  padding: 10px;
  font-size: 12px;
`;