import { SpaceFairUsePolicy } from '@api/models/SpaceFairUsePolicy';
import { GetQuery } from '@api/queries/common/GetQuery';

export class SpaceFairUsePoliciesGetByPolicyIdQuery extends GetQuery<SpaceFairUsePolicy[]>{
  policyId: number; 
    
  constructor(policyId: number){
    super();
    this.policyId = policyId;
  }

  public targetUrl(): string {
    return `/FairUse/Exceptions/GetByPolicyId/${this.policyId}`
  }
}