import styled, { css } from 'styled-components';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { transparentize } from 'polished';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum ConsumptionChartView {
  Periodical = 'Periodical',
  Cumulative = 'Cumulative'
}

type PropTypes = {
  selected: ConsumptionChartView;
  onChange: (value: ConsumptionChartView) => void;
};

const ChartViewToggle = ({ selected, onChange }: PropTypes) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();

  const options = useMemo(() => [
    {
      label: t('SiteOverview.CumulativeView', { ns: 'molecules' }),
      value: ConsumptionChartView.Cumulative,
      icon: solid('line-chart')
    },
    {
      label: t('SiteOverview.PeriodicalView', { ns: 'molecules' }),
      value: ConsumptionChartView.Periodical,
      icon: solid('chart-column')
    }
  ], [t]);

  const handleChange = useCallback((view: ConsumptionChartView) => {
    trackAction('view_cumulative', 'performance_utilities');
    onChange(view);
  }, [trackAction, onChange]);

  return (
    <Wrapper>
      <Label>
        {options.find(x => x.value === selected)?.label}
      </Label>

      <ToggleWrapper>
        {options.map((option) => (
          <ToggleButton
            key={option.label}
            selected={option.value === selected}
            onClick={() => handleChange(option.value)}
          >
            <Icon icon={option.icon} />
          </ToggleButton>
        ))}
      </ToggleWrapper>
    </Wrapper>
  );
};

export default React.memo(ChartViewToggle);

const Wrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ToggleWrapper = styled.div`
  height: 30px;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 2px 12px -2px ${p => p.theme.shadow.dark};
  display: flex;
`;

const ToggleButton = styled.div<{ selected: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 1px 10px 0 10px;
  
  background-color: ${p => p.theme.palette.backgrounds.surface};
  color: ${p => p.theme.palette.text.fair};

  ${({ selected }) => selected && css`
    background-color: ${p => transparentize(0.1, p.theme.palette.primary)};
    color: ${p => p.theme.palette.text.onPrimary};
  `}
`;

const Icon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 11px;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
`;