import { ReactNode, RefObject, useMemo, useState } from 'react';
import styled from 'styled-components';
import SearchBarDropdownTabs from './SearchBarDropdownTabs';
import { SpaceSearchDto } from '@api/models/SpaceSearchDto';
import { useTranslation } from 'react-i18next';
import { DeviceSearchDto } from '@api/models/DeviceSearchDto';
import NoData from '@components/core/NoData';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SearchResults, { SearchResultConfig } from './SearchResults';
import { isValidSearch, SearchBarMinCharacters } from './SearchBar';
import { useWindowSize } from '@hooks/useWindowSize';

export type TabBarItem = {
  label: ReactNode;
  count?: number;
  loading: boolean;
}

type PropTypes = {
  open: boolean;
  parentRef: RefObject<HTMLDivElement>;
  spaces: SpaceSearchDto[];
  devices: DeviceSearchDto[];
  loadingSpaces: boolean;
  loadingDevices: boolean;
  searchString: string;
  onClear: () => void
}

const SearchBarDropdown = ({ open, parentRef, spaces, devices, loadingSpaces, loadingDevices, searchString, onClear }: PropTypes) => {
  useWindowSize(); // Required so the position is recalculated when the window size changes
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const domRect = parentRef.current?.getBoundingClientRect();

  const spacesConfig: SearchResultConfig<SpaceSearchDto> = useMemo(() => ({
    emptyLabel: t('NoSpaceMatchesSearch', { ns: 'status' }),
    results: spaces,
    fields: {
      label: space => space.spaceName,
      subLabel: space => `${space.floorName}, ${space.buildingName}`,
      link: space => `./building/${space.buildingId}/floor/${space.floorId}/space/${space.spaceId}`,
      highlightLabel: true
    },
    loading: loadingSpaces
  }), [spaces, loadingSpaces, t]);

  const devicesConfig: SearchResultConfig<DeviceSearchDto> = useMemo(() => ({
    emptyLabel: t('NoDeviceMatchesSearch', { ns: 'status' }),
    results: devices,
    fields: {
      label: device => device.friendlyName,
      subLabel: device => `${device.deviceIdentifier}, ${device.spaceName}`,
      link: device => `./building/${device.buildingId}/floor/${device.floorId}/space/${device.spaceId}/device/${device.deviceId}`,
      highlightSubLabel: true
    },
    loading: loadingDevices
  }), [devices, loadingDevices, t]);

  const tabs: TabBarItem[] = useMemo(() => [
    {
      label: t('Spaces', { ns: 'common' }),
      count: spaces.length,
      loading: loadingSpaces
    },
    {
      label: t('Devices', { ns: 'common' }),
      count: devices.length,
      loading: loadingDevices
    }
  ], [spaces, devices, loadingSpaces, loadingDevices, t]);

  if (!domRect) {
    return null;
  }

  const position = { x: domRect.x, y: domRect.y + domRect.height + 6 };

  return (
    <Container open={open} position={position}>
      <SearchBarDropdownTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onChange={setSelectedTab}
      />

      {isValidSearch(searchString)
        ?
        <>
          {selectedTab === 0 && <SearchResults config={spacesConfig} searchString={searchString} onClear={onClear} />}
          {selectedTab === 1 && <SearchResults config={devicesConfig} searchString={searchString} onClear={onClear} />}
        </>
        :
        <NoData
          subLabel={t('SearchStringTooShort', { ns: 'status', numChars: SearchBarMinCharacters })}
          subLabelStyles={{ whiteSpace: 'pre-line' }}
          icon={solid('search')}
          iconStyles={{ fontSize: 18 }}
          styles={{ margin: '30px 30px' }}
        />
      }
    </Container>
  );
};

export default SearchBarDropdown;

const Container = styled.div<{ open: boolean, position: { x: number, y: number } }>`
  position: fixed;
  top: ${p => p.position.y}px;
  left: ${p => p.position.x}px;
  display: ${p => p.open ? 'block' : 'none'};

  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  width: 300px;
`;