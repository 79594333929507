import { User } from '@api/models/User';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class UserSitesUpdateCommand extends PostQuery<User> {
  userId: number;
  sitesToRemove: number[];
  sitesToAdd: number[];

  constructor(userId: number, sitesToRemove: number[], sitesToAdd: number[]) {
    super();
    this.userId = userId;
    this.sitesToRemove = sitesToRemove;
    this.sitesToAdd = sitesToAdd;
  }

  public targetUrl(): string {
    return '/userSites';
  }
}
