import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { PatchQuery } from '../../common/PatchQuery';

export default class TemperatureMonitoringDeviceUpdateCommand extends PatchQuery<SpaceClimateControlDto> {
  temperatureMonitoringDeviceId?: number;

  constructor(id: number, temperatureMonitoringDeviceId?: number) {
    super(id);
    this.temperatureMonitoringDeviceId = temperatureMonitoringDeviceId;
  }

  public targetUrl(): string {
    return '/ClimateControl/space/UpdateTemperatureMonitoringDevice';
  }
}
