import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { SiteLevelFeatureFlag } from '@api/enums/SiteLevelFeatureFlag';
import { DeviceIssueDto } from '@api/models/DeviceIssueDto';
import { SpaceClimateControlDetailsDto } from '@api/models/SpaceClimateControlDetailsDto';
import { HeatingScheduleDto } from '@api/models/HeatingScheduleDto';
import { RouteLinkAnalytics } from '@src/types/Route';
import { ReactNode } from 'react';

export enum TempControlTabType {
  Spaces = 'Spaces',
  DeviceIssues = 'DeviceIssues',
  HeatingSchedules = 'HeatingSchedules'
}

export type TableTab = {
  type: TempControlTabType;
  label: string;
  icon?: IconDefinition;
  iconColor?: string;
  loading?: boolean;
  data: SpaceClimateControlDetailsDto[] | DeviceIssueDto[] | HeatingScheduleDto[];
  analytics: RouteLinkAnalytics;
  component: ReactNode;
  siteLevelFeatureFlag?: SiteLevelFeatureFlag;
};