import { MetricType } from '@api/enums/MetricType';
import { Metric } from '@api/models/Metric';

export function GetMetricValue<T extends { metrics: Metric[] }>(obj: T, metricType: MetricType) {
  return obj.metrics.find(x => x.metricType === metricType)?.value;
}

export function GetMetricMeasuredOn<T extends { metrics: Metric[] }>(obj: T, metricType: MetricType) {
  return obj.metrics.find(x => x.metricType === metricType)?.measuredOn;
}
