import DeviceGatewayDeleteCommand from '@api/queries/gateway/DeviceGatewayDeleteCommand';
import DeviceGatewayGetByIdQuery from '@api/queries/gateway/DeviceGatewayGetByIdQuery';
import DeviceGatewayUpdateCommand from '@api/queries/gateway/DeviceGatewayUpdateCommand';
import { DeviceGateway } from '@api/models/DeviceGateway';
import { Button } from '@components/core/Button';
import { ErrorMessage, Form, Input, Label } from '@components/Form';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Card } from '@components/core/Card';
import { WarningDialog } from '@components/core/WarningDialog';
import { RouterPrompt } from '@src/navigation/RouterPrompt/RouterPrompt';
import { useApi } from '@hooks/useApi';
import { useApiState } from '@hooks/useApiState';
import { useModal } from '@hooks/useModal';
import { valuesDiffer } from '@utils/ObjectUtils';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

type FormValues = {
  friendlyName: string
}

const GatewayEdit = () => {
  const { t } = useTranslation(['settingsDevice']);
  const theme = useTheme();
  const navigate = useNavigate();
  const { execute } = useApi();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [executePostSaveAction, setExecutePostSaveAction] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValues>();
  const formValues = watch();
  const { isOpen: deleteDialogIsOpen, toggle: deleteDialogToggle, ref: deleteDialogRef } = useModal({});
  const { deviceId } = useParams<{ deviceId: string }>();
  const { data: gateway, setData: setGateway } = useApiState({
    query: deviceId === undefined ? undefined : new DeviceGatewayGetByIdQuery(parseInt(deviceId))
  }, [deviceId]);

  /**
   * Execute after saving, redirect back to the device list
   */
  useEffect(() => {
    if (executePostSaveAction && !hasUnsavedChanges) {
      navigate('../devices');
    }
  }, [executePostSaveAction, hasUnsavedChanges, navigate]);

  useEffect(() => {
    if (gateway) {
      const hasBeenModified = valuesDiffer<FormValues, DeviceGateway>(formValues, gateway);
      setHasUnsavedChanges(hasBeenModified);
    }
  }, [formValues, gateway]);

  if (!gateway) {
    return null;
  }

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const modifiedDevice = cloneDeep(gateway);
    modifiedDevice.friendlyName = data.friendlyName;

    if (!valuesDiffer(modifiedDevice, gateway)) {
      setSavingInProgress(false);
      return;
    }

    const result = await execute({
      query: new DeviceGatewayUpdateCommand(gateway.id, modifiedDevice.friendlyName),
      successMessage: t('DeviceEditing.ChangesSaved', { ns: 'settingsDevice' }),
      errorMessage: t('DeviceEditing.SavingFailed', { ns: 'settingsDevice' })
    });

    if (result === undefined) {
      setSavingInProgress(false);
      return;
    }

    setGateway(modifiedDevice);
    setSavingInProgress(false);
    setExecutePostSaveAction(true);
  }

  const onDelete = async () => {
    setHasUnsavedChanges(false); // so that the RouterPrompt doesn't trigger as well

    await execute({
      query: new DeviceGatewayDeleteCommand(gateway.id),
      successMessage: t('DeviceEditing.GatewayDeleted', { ns: 'settingsDevice' }),
      errorMessage: t('DeviceEditing.GatewayDeleteFailed', { ns: 'settingsDevice' })
    });

    navigate('../devices');
  }

  return (
    <PaddedContainer largePadding>
      <RouterPrompt when={hasUnsavedChanges} />

      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('DeviceEditing.DeleteGatewayDialogSectionOne', { ns: 'settingsDevice' })}
        confirmButton={t('DeviceEditing.Delete', { ns: 'settingsDevice' })}
        confirmationCheckbox={t('DeviceEditing.Understand', { ns: 'settingsDevice' })}
        onCancel={deleteDialogToggle}
        onConfirm={onDelete}
      />

      <Card
        cardTitle={t('DeviceEditing.Gateway', { ns: 'settingsDevice' })}
        centered
        maxWidth='400px'
      >
        <Form>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Label>{t('DeviceEditing.FriendlyName', { ns: 'settingsDevice' })}</Label>
                <Input
                  {...register('friendlyName', {
                    required: t('DeviceEditing.RequiredField', { ns: 'settingsDevice' }),
                  })}
                  defaultValue={gateway.friendlyName}
                />
                <ErrorMessage>{errors.friendlyName?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>{t('DeviceEditing.SpaceName', { ns: 'settingsDevice' })}</Label>
                <Input
                  type="deviceIdentifier"
                  defaultValue={gateway.space}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>{t('DeviceEditing.DeviceModel', { ns: 'settingsDevice' })}</Label>
                <Input
                  type="deviceModel"
                  defaultValue={gateway.deviceGatewayModel.deviceModel}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>{t('DeviceEditing.DeviceIdentifier', { ns: 'settingsDevice' })}</Label>
                <Input
                  type="deviceIdentifier"
                  defaultValue={gateway.deviceIdentifier}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>{t('DeviceEditing.AssetNumber', { ns: 'settingsDevice' })}</Label>
                <Input
                  type="assetNumber"
                  defaultValue={gateway.assetNumber}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>{t('DeviceEditing.Connectivity', { ns: 'settingsDevice' })}</Label>
                <Input
                  type="assetNumber"
                  defaultValue={gateway.connectivity}
                  disabled
                />
              </div>
            </div>
          </div>
        </Form>
      </Card>
      <Card
        centered
        maxWidth='400px'
      >
        <div style={{ display: 'flex' }}>
          <Button
            label={t('DeviceEditing.Save', { ns: 'settingsDevice' })}
            onClick={handleSubmit(onSave)}
            loading={savingInProgress}
          />

          <div style={{ marginLeft: 'auto' }} />
          <Button
            tertiary
            label={t('DeviceEditing.Delete', { ns: 'settingsDevice' })}
            color={theme.palette.red}
            onClick={deleteDialogToggle}
          />
        </div>
      </Card>
    </PaddedContainer >
  )
}

export default GatewayEdit
