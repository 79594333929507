import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@components/core/Loading';
import { usePopover } from '@hooks/usePopover';
import Dropdown from '@components/core/Dropdown';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ICascaderDropdownSelect } from './CascaderDropdownSelect.types';
import useCascaderDropdownSelect from '@hooks/useCascaderDropdownSelect';

export const CascaderDropdownSelect = <TValue,>(props: ICascaderDropdownSelect<TValue>) => {
  const { ref, visible, toggle } = usePopover({});
  const { loading, fieldStyles, placeholder, showAddButton } = props;
  const {
    transientNodeGroup,
    selectedLabels,
    labelContainerRef,
    numHiddenItems,
    handleSelect,
    clearAll
  } = useCascaderDropdownSelect(props);

  return (
    <Container ref={ref}>
      {showAddButton &&
        <AddIcon
          icon={solid('plus')}
          onClick={() => !loading && toggle()}
        />}
      {!showAddButton &&
        <SelectField
          onClick={() => !loading && toggle()}
          style={fieldStyles}
        >
          {selectedLabels.length === 0 &&
            <Placeholder>
              {placeholder}
            </Placeholder>
          }

          {loading &&
            <Loading
              size="15px"
              color="grey"
              inline
              style={{ margin: '0 auto' }}
            />
          }

          <IconWrapper>
            {numHiddenItems > 0 &&
              <Count>
                +{numHiddenItems}
              </Count>
            }

            {selectedLabels.length === 0 ?
              <ChevronIcon icon={visible ? faChevronUp : faChevronDown} /> :
              <ClearIcon icon={faXmark} onClick={(e) => { e.stopPropagation(); clearAll(); }} />
            }
          </IconWrapper>

          <LabelContainer ref={labelContainerRef}>
            {selectedLabels.map((label) => (
              <SelectedLabel key={uuidv4()}>
                {label}
              </SelectedLabel>
            ))}
          </LabelContainer>
        </SelectField>
      }
      <Dropdown
        open={visible}
        group={transientNodeGroup}
        onChange={handleSelect}
        showSearch={true}
      />
    </Container>
  );
}

const SelectField = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 100%;
  height: 38px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 5px;
  padding: 9px 54px 9px 10px;
  overflow: hidden;

  color: ${p => p.theme.text.secondary};
  background-color: ${p => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.action.outlineBorder};
  border-radius: 4px;
  box-shadow: ${p => p.theme.palette.forms.input.boxShadow};

  transition: all 150ms ease;

  &:hover {
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }

  ${p => p.disabled && css`
    background-color: ${p.theme.palette.forms.input.disabledBackground};
  `}
`;

const Container = styled.div`
  position: relative;
`;

const Placeholder = styled.div`
  font-size: 14px;
  margin-right: 8px;
  margin-top: 1px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding: 2px 9px;
  border-radius: 14px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const IconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearIcon = styled(FontAwesomeIcon)`
  width: 13px;
  height: 13px;
  color: ${p => p.theme.action.active};

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${p => p.theme.action.active};

  ${SelectField}:hover & {
    color: ${p => p.theme.primary.main};
  }
`;

const Count = styled.div`
  font-size: 12px;
  font-weight: 500;
  min-width: 18px;
  padding: 0px 5px;
  margin-right: 3px;
  border-radius: 8px;
  color: ${p => p.theme.text.contrast};
  background-color: ${p => p.theme.primary.main};
  text-align: center;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 5px;
`;

const AddIcon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${p => p.theme.palette.text.fair};
  padding: 8px;
  background: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 2px 4px 0 ${p => p.theme.palette.shadows.strong};
  border-radius: 6px;
  cursor: pointer;
`;