import EsgExplorerTemplateDto from '@api/models/EsgExplorerTemplateDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class EsgExplorerTemplatesGetQuery extends GetQuery<EsgExplorerTemplateDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/esgExplorer/templates/${this.siteId}`;
  }
}
