import { DeviceModelWithMetricTypes } from '@api/models/DeviceModelWithMetricTypes';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DeviceModelsGetByBuildingIdQuery extends GetQuery<DeviceModelWithMetricTypes[]> {
  buildingId: number;

  constructor(buildingId: number) {
    super();
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return `/devices/deviceModels/${this.buildingId}`;
  }
}
