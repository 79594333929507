import { round, sum } from 'lodash';
import { transparentize } from 'polished';
import styled, { useTheme } from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { MonthlyWeighting } from '@api/models/BuildingMetadata';
import { getAbbreviatedMonthName } from '@utils/DateUtils';
import { Input } from '@components/Form';

interface IMonthlyWeightingInput {
  weighting?: MonthlyWeighting;
  onChange: (weighting: MonthlyWeighting) => void;
  readOnly?: boolean;
}

const MonthlyWeightingInput = ({ weighting: originalWeighting, onChange, readOnly }: IMonthlyWeightingInput) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();

  const weighting: MonthlyWeighting = (originalWeighting && originalWeighting.length === 12) ? originalWeighting : Array(12).fill(8.33) as MonthlyWeighting;
  const isInvalid = weighting.some(x => x < 0);

  const handleInput = (weighting: MonthlyWeighting, stringValue: string, index: number) => {
    const newWeighting = [...weighting] as MonthlyWeighting;

    newWeighting[index] = stringValue ? parseFloat(stringValue) : 0;
    newWeighting[newWeighting.length - 1] = round(100 - sum(newWeighting.slice(0, -1)), 1);

    onChange(newWeighting);
  };

  return (
    <>
      <FlexRow readOnly={readOnly}>
        {weighting.map((value, i) => {
          const lastMonth = i === 11;

          return (
            <div key={i}>
              <MonthLabel>{getAbbreviatedMonthName(i + 1)}</MonthLabel>
              <Input
                className="numberInputHiddenArrows"
                type="number"
                min={0}
                max={100}
                value={value}
                onInput={(e) => handleInput(weighting, e.currentTarget.value, i)}
                readOnly={readOnly}
                disabled={lastMonth}
                style={lastMonth && isInvalid ? { color: theme.palette.red } : {}}
              />
            </div>
          );
        })}
      </FlexRow>

      <FlexRow>
        <Description>
          {t('Buildings.MonthlyWeightingInput.MonthlyConsumptionTarget', { ns: 'settingsAsset' })}
        </Description>

        {isInvalid &&
          <HelperMessage>
            <WarningIcon icon={solid('exclamation-triangle')} />
            {t('Buildings.MonthlyWeightingInput.MonthlyConsumptionTargetValidation', { ns: 'settingsAsset' })}
          </HelperMessage>
        }
      </FlexRow>
    </>
  );
};

export default MonthlyWeightingInput;

const FlexRow = styled.div<{ readOnly?: boolean }>`
  position: relative;
  display: flex;
  gap: ${p => p.readOnly ? '10px' : '5px'};
`;

const MonthLabel = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
  margin-left: 1px;
`;

const Description = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
  margin: 5px 0 0 2px;
`;

const HelperMessage = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 12px;
  line-height: 12px;
  color: ${p => p.theme.palette.red};
  background-color: ${p => transparentize(0.82, p.theme.palette.red)};
  box-shadow: 0px 5px 5px -3px ${p => transparentize(0, p.theme.palette.forms.input.shadow)};
  padding: 4px 6px;
  border-radius: 4px;
  width: max-content;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.palette.red};
  margin-right: 4px;
`;