import { ReactNode, RefObject, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Modal } from '@components/core/Modal';
import { Checkbox } from '@components/checkbox/Checkbox';
import { Button } from '@components/core/Button';
import { useTranslation } from 'react-i18next';

interface WarningDialogProps extends React.ComponentPropsWithoutRef<'div'> {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  sectionOne?: ReactNode;
  sectionTwo?: ReactNode;
  sectionThree?: ReactNode;
  cancelButton?: string;
  cancelButtonType?: 'secondary' | 'tertiary';
  confirmButton?: string;
  confirmButtonColor?: string;
  cancelButtonColor?: string;
  isLoading?: boolean;
  confirmationCheckbox?: string;
  onCancel: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirm: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const WarningDialog = ({ modalRef, isOpen, sectionOne, sectionTwo, sectionThree, cancelButton, cancelButtonType = 'secondary', confirmButton, confirmButtonColor, cancelButtonColor, isLoading, confirmationCheckbox, onCancel, onConfirm }: WarningDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [confirmed, setConfirmed] = useState(false);

  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      hide={onCancel}
      plainModal
      width="450px"
    >
      <Content>
        {sectionOne &&
          <SectionOne>
            {sectionOne}
          </SectionOne>
        }

        {sectionTwo &&
          <SectionTwo>
            {sectionTwo}
          </SectionTwo>
        }

        {confirmationCheckbox && (
          <Checkbox
            label={confirmationCheckbox}
            checked={confirmed}
            onChange={setConfirmed}
          />
        )}

        {sectionThree &&
          <SectionTwo>
            {sectionThree}
          </SectionTwo>
        }

        <Footer>
          <Button
            secondary={cancelButtonType === 'secondary'}
            tertiary={cancelButtonType === 'tertiary'}
            label={cancelButton ?? t('Cancel', { ns: 'common' })}
            onClick={onCancel}
            color={cancelButtonColor ?? theme.palette.primary}
          />
          <Button
            label={confirmButton ?? t('Confirm', { ns: 'confirm' })}
            onClick={onConfirm}
            color={confirmButtonColor ?? theme.palette.red}
            loading={isLoading}
            disabled={confirmationCheckbox ? !confirmed : false}
          />
        </Footer>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionOne = styled.div`
  font-size: 20px;
`;

const SectionTwo = styled.div`
  font-size: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding-top: 20px;
`;
