import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconTooltip from '@components/core/IconTooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { transparentize } from 'polished';
import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface IAlertRuleCard {
  title?: ReactNode;
  helperMessage?: ReactNode;
  open?: boolean;
  icon?: IconDefinition;
  enabled?: boolean;
  optional?: boolean;
  onOpenOptional?: () => void;
  onCloseOptional?: () => void;
  children?: ReactNode;
}

const AlertRuleCard = ({ title, helperMessage, open: openProp, icon, enabled: enabledProp, optional, onOpenOptional, onCloseOptional, children }: IAlertRuleCard) => {
  const [open, setOpen] = useState(openProp);
  const [enabled, setEnabled] = useState(enabledProp);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  useEffect(() => {
    setEnabled(enabledProp);
  }, [enabledProp]);

  if (!open) {
    return (
      <CollapsedCard enabled={enabled} onClick={() => {
        if (enabled) {
          setOpen(true);
          onOpenOptional && onOpenOptional();
        }
      }}>
        {icon &&
          <FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />
        }
        {title}
      </CollapsedCard>
    );
  }

  return (
    <Card>
      {(title || helperMessage || optional) &&
        <FlexRow>
          <Title>
            {title}
          </Title>

          {helperMessage &&
            <IconTooltip
              icon={regular('question-circle')}
              tooltip={{
                content: helperMessage,
                placement: TooltipPlacement.Right,
                hideTriangle: true
              }}
              tooltipStyles={{ width: '525px' }}
              wrapperStyles={{ marginLeft: '5px' }}
            />
          }

          {optional &&
            <CloseIcon icon={solid('xmark')} onClick={() => {
              setOpen(false);
              onCloseOptional && onCloseOptional();
            }} />
          }
        </FlexRow>
      }

      {children}
    </Card>
  );
};

export default AlertRuleCard;

const Card = styled.div`
  padding: 20px;
  width: 100%;

  background-color: ${p => p.theme.palette.backgrounds.surface};
  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 5px;
  box-shadow: 0px 3px 8px ${p => p.theme.palette.shadows.medium};

  &:hover {
    border-color: ${p => transparentize(0.7, p.theme.palette.primary)};
    box-shadow: 0px 5px 8px ${p => p.theme.palette.shadows.medium};
  }

  transition: all 400ms ease;
`;

const Title = styled.div`
  display: inline-block;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  margin-left: auto;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

export const CollapsedCard = styled.div<{ enabled?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 4px 10px;
  width: max-content;
  opacity: 0.6;
  cursor: not-allowed;

  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 5px;
  box-shadow: 0px 3px 8px ${p => p.theme.palette.shadows.medium};

  ${p => p.enabled && css`
    opacity: 1;
    cursor: pointer;
    transition: all 400ms ease;

    &:hover {
      border: 1px solid ${p => transparentize(0.4, p.theme.palette.primary)};
      box-shadow: 0px 5px 8px ${p => p.theme.palette.shadows.medium};
    }
  `}
`;