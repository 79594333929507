import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Route } from '@src/types/Route';
import TemperatureControls from '@pages/site/temperature-control/edit/TemperatureControls';
import Exceptions from '@pages/site/temperature-control/exceptions/Exceptions';
import ClimateControl from '@pages/site/temperature-control/ClimateControl';

export const ClimateControlRoutes: Route[] = [
  {
    path: 'climate-control',
    element: ClimateControl,
    analytics: { page: 'climate_control', category: 'climate_control' },
    link: {
      label: 'ClimateControl',
      path: 'climate-control',
      icon: regular('temperature-list'),
      analytics: { action: 'climate_control', category: 'feature_navigation' },
    }
  },
  {
    path: 'climate-control/edit',
    element: TemperatureControls,
    analytics: { page: 'climate_control_edit', category: 'climate_control' }
  },
  {
    path: 'climate-control/exceptions',
    element: Exceptions,
    analytics: { page: 'climate_control_exceptions', category: 'climate_control' }
  }
];