import EnergyMetersBulkUpdateCommand from '@api/queries/energy-meters/EnergyMetersBulkUpdateCommand';
import { EnergyMeterCategory } from '@api/enums/EnergyMeterCategory';
import { EnergyMeterTopologyLevel } from '@api/enums/EnergyMeterTopologyLevel';
import { EnergyMeterTopologyDto } from '@api/models/EnergyMeterTopologyDto';
import { Button } from '@components/core/Button';
import { Checkbox } from '@components/checkbox/Checkbox';
import { Select } from '@components/select/Select';
import { Tooltip } from '@components/core/Tooltip';
import { useApi } from '@hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';

interface IEnergyMeterListBulkAction {
  selectedMeters: EnergyMeterTopologyDto[];
  allMeters: EnergyMeterTopologyDto[];
  refreshMeters: () => void;
}

const EnergyMeterListBulkAction = ({ selectedMeters, allMeters, refreshMeters }: IEnergyMeterListBulkAction) => {
  const { t } = useTranslation(['settingsAsset']);
  const { execute } = useApi();
  const [selectedTopology, setSelectedTopology] = useState<EnergyMeterTopologyLevel>();
  const [selectedCategory, setSelectedCategory] = useState<EnergyMeterCategory>();
  const [isBillingMeter, setIsBillingMeter] = useState(false);
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(false);

  const categoryOptions: { label: string, value: EnergyMeterCategory }[] = Object.values(EnergyMeterCategory).map(x => ({ label: x.toString(), value: x }));
  const topologyLevelOptions: { label: string, value: EnergyMeterTopologyLevel }[] = Object.values(EnergyMeterTopologyLevel).map(x => ({ label: x.toString(), value: x }));

  useEffect(() => {
    if (selectedMeters.length !== 0 && selectedMeters !== undefined) {
      setIsBillingMeter(selectedMeters[0].isBillingMeter);

      const meterType = selectedMeters[0].meterType;

      if (hasBillingMeter(allMeters, meterType)) {
        setIsCheckBoxDisabled(!selectedMeters[0].isBillingMeter);
      } else {
        setIsCheckBoxDisabled(false);
      }
    }
  }, [selectedMeters, allMeters]);

  const hasBillingMeter = (allMeters: EnergyMeterTopologyDto[], utilityType: string): boolean => {
    if(allMeters.some(x => x.meterType === utilityType && x.isBillingMeter === true)){
      return true;
    }    
    return false;
  }  

  const handleSave = useCallback(async () => {
    const modifiedDtos = selectedMeters.map(x => ({
      ...x,
      meterTopologyLevel: selectedTopology,
      meterCategory: selectedTopology === EnergyMeterTopologyLevel.Sub ? selectedCategory : undefined,
      parentMeterId: selectedTopology === EnergyMeterTopologyLevel.Main ? undefined : x.parentMeterId,
      isBillingMeter: isBillingMeter 
    }));

    const success = await execute({
      query: new EnergyMetersBulkUpdateCommand(modifiedDtos),
      pendingMessage: t('Buildings.EnergyMeters.EnergyMeterListBulk.SavingChanges', { ns: 'settingsAsset' }),
      successMessage: t('Buildings.EnergyMeters.EnergyMeterListBulk.ChangesSaved', { ns: 'settingsAsset' }),
      errorMessage: t('Buildings.EnergyMeters.EnergyMeterListBulk.ErrorMessage', { ns: 'settingsAsset' })
    });

    if (success) {
      refreshMeters();
    } else {
      toast.error(t('Buildings.EnergyMeters.EnergyMeterListBulk.ErrorMessage', { ns: 'settingsAsset' }));
      refreshMeters();
    }
  }, [execute, selectedMeters, refreshMeters, selectedTopology, selectedCategory, t, isBillingMeter]);

  const changeBillingMeterCheckbox = () => {
    setIsBillingMeter(!isBillingMeter)
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Label>
          {t('Buildings.EnergyMeters.EnergyMeterListBulk.Topology', { ns: 'settingsAsset' })}:
        </Label>
        <div style={{ width: '150px' }}>
          <Select
            isSearchable={true}
            onChange={x => setSelectedTopology(x?.value)}
            value={topologyLevelOptions.find(x => x.value === selectedTopology)}
            options={topologyLevelOptions}
            small
          />
        </div>
      </div>

      {selectedTopology !== EnergyMeterTopologyLevel.Main && 
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Label>
            {t('Buildings.EnergyMeters.EnergyMeterListBulk.Category', { ns: 'settingsAsset' })}:
          </Label>
          <div style={{ width: '150px' }}>
            <Select
              isSearchable={true}
              options={categoryOptions}
              value={selectedTopology !== EnergyMeterTopologyLevel.Sub ? null : categoryOptions.find(x => x.value === selectedCategory)}
              placeholder={selectedTopology !== EnergyMeterTopologyLevel.Sub ? t('Buildings.EnergyMeters.EnergyMeterListBulk.N/A', { ns: 'settingsAsset' }) : t('Buildings.EnergyMeters.EnergyMeterListBulk.Select', { ns: 'settingsAsset' })}
              isDisabled={selectedTopology !== EnergyMeterTopologyLevel.Sub}
              onChange={x => setSelectedCategory(x?.value)}
              small
            />
          </div>
        </div>
      }

      {selectedTopology === EnergyMeterTopologyLevel.Main && selectedMeters.length === 1 && 
        <CheckboxWrapper>          
          <Label>{t('DeviceCreate.BillingMeter', { ns: 'settingsDevice' })}</Label>
          <Tooltip
            content={isCheckBoxDisabled ? `${selectedMeters[0].meterType}` + t('DeviceCreate.BillingMeterAlreadyPresent', { ns: 'settingsDevice' }) : ''} 
          >  
            <Checkbox
              checked={isBillingMeter}
              onChange={changeBillingMeterCheckbox}
              disabled={isCheckBoxDisabled}
            />
          </Tooltip>              
        </CheckboxWrapper>  
      }

      <Button
        label={t('Buildings.EnergyMeters.EnergyMeterListBulk.Save', { ns: 'settingsAsset' })}
        onClick={handleSave}
        disabled={!selectedTopology || (selectedTopology === EnergyMeterTopologyLevel.Sub && !selectedCategory)}
      />
    </>
  );
};

export default EnergyMeterListBulkAction;

const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.forms.label.color};
`;

const CheckboxWrapper = styled.div`
    min-width: 222.3px;
    justify-content: space-evenly;
    display: flex;
`;