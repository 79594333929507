import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import styled from 'styled-components';
import PerformanceCardWeather from './PerformanceCard_Weather';
import PerformanceCardSiteTarget from './PerformanceCard_SiteTarget';
import PerformanceCardsSiteTemperature from './PerformanceCards_SiteTemperature';

type PerformanceCardsProps = {
  siteClimateControl: SiteClimateControlDto;
}

const PerformanceCards = ({ siteClimateControl }: PerformanceCardsProps) => {
  return (
    <StyledGrid style={{ justifyContent: 'space-between', width: '100%' }}>
      <PerformanceCardWeather siteId={siteClimateControl.siteId} />
      <PerformanceCardSiteTarget targetTemp={siteClimateControl.targetTemp} />
      <PerformanceCardsSiteTemperature siteId={siteClimateControl.siteId} />
    </StyledGrid >
  )
}

export default PerformanceCards;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 1200px) {
    gap: 15px;
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 375px) {
    grid-template-columns: 1fr; 
    grid-template-rows: repeat(4, auto);
  }
`;