import { useTranslation } from 'react-i18next';
import { exportCSV } from '@utils/ExportUtils';
import { useAsync } from '@hooks/useAsync';
import { authConfig } from '@src/authentication/authConfig';

const useCsvExport = () => {
  const { execute, loading } = useAsync();
  const { t } = useTranslation(['hooks']);

  const handleCsvExport = async (query: any, fileName: string) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const result = await execute({
      asyncFunc: async () => await query.execute(authConfig),
      errorMessage: t('CSVExportFailed', { ns: 'hooks' }),
    })

    if (result) {
      exportCSV(fileName, result.csv);
    }
  }

  return { handleCsvExport, loading };
};

export default useCsvExport;