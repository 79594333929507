import { NoteAttachmentDto } from '@api/models/NoteAttachmentDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export class NoteAttachmentDownloadCommand extends GetQuery<NoteAttachmentDto> {
  attachmentId: number;

  constructor(attachmentId: number) {
    super();
    this.attachmentId = attachmentId;
  }

  public targetUrl(): string {
    return `/notes/downloadAttachment/${this.attachmentId}`;
  }
}
