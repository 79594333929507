import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { useApiState } from '@hooks/useApiState';
import { useSiteContext } from '@pages/site/SiteProvider';
import DeviceManagementHeader from '@components/device-management/DeviceManagementHeader';
import DeviceManagementTable from '@components/device-management/DeviceManagementTable';
import { DeviceDetails } from '@api/models/DeviceDetails';
import { DevicesGetWithLatestMetricsQuery } from '@api/queries/devices/DevicesGetWithLatestMetricsQuery';
import { useDeviceManagement } from '@hooks/useDeviceManagement';
import DeviceGatewaysGetBySiteIdQuery from '@api/queries/gateway/DeviceGatewaysGetBySiteIdQuery';

const DeviceList = () => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const {
    spaceFilter,
    setSpaceFilter,
    modelFilter,
    setModelFilter,
    setTypeFilter,
    typeFilter,
    searchFilter,
    locationFilter,
    deviceModelFilter,
    deviceTypeFilter,
    tableRecordCount,
    setTableRecordCount,
    setSearchString,
    searchString,
    handleExportCsv
  } = useDeviceManagement();
  const [devicesWithGateways, setDevicesWithGateways] = useState<DeviceDetails[]>([]);

  const { data: gateways } = useApiState({
    query: new DeviceGatewaysGetBySiteIdQuery(site.id),
    initialState: [],
  }, []);
  const { data: devices, loading: devicesLoading } = useApiState({
    query: new DevicesGetWithLatestMetricsQuery(site.id),
  }, []);

  useEffect(() => {
    const deviceList = devices?.devices || [];

    if (gateways) {
      gateways.forEach(gateway => {
        return deviceList.push({
          floorId: 0,
          spaceId: 0, // setting space id to 0 as it is not available in gateway
          deviceId: gateway.id,
          manufacturer: 'Kerlink',
          deviceModel: gateway.deviceGatewayModel.deviceModel,
          deviceIdentifier: gateway.deviceIdentifier,
          friendlyName: gateway.friendlyName,
          spaceName: gateway.space,
          lastMeasuredOn: undefined,
          metrics: [],
        });
      });
    }

    setDevicesWithGateways(deviceList);
  }, [gateways, t, devices]);

  return (
    <PaddedContainer largePadding>
      <DeviceManagementHeader 
        devices={devicesWithGateways || []}
        tableRecordCount={tableRecordCount}
        onExportCsv={() => handleExportCsv(devicesWithGateways)}
        modelFilter={modelFilter}
        onSetModelFilter={setModelFilter}
        typeFilter={typeFilter}
        onSetTypeFilter={setTypeFilter}
        spaceFilter={spaceFilter}
        onSetSpaceFilter={setSpaceFilter}
        onSetSearchString={setSearchString}
      />
      <DeviceManagementTable
        devices={devicesWithGateways || []}
        setTableRecordCount={setTableRecordCount}
        searchString={searchString}
        loading={devicesLoading}
        filters={[searchFilter, locationFilter, deviceModelFilter, deviceTypeFilter]}
        showActionMenu
      />
    </PaddedContainer>
  );
}

export default DeviceList;
