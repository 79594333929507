import styled from 'styled-components';
import FilterTypeConfiguration from './FilterTypeConfiguration';
import { lowerCase, upperFirst } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { EsgExplorerConfiguration, EsgExplorerFilter, EsgExplorerFilterType } from '@api/models/EsgExplorerConfiguration';
import { Select } from '@components/select/Select';
import { useTranslation } from 'react-i18next';
import { transparentize } from 'polished';
import { EsgExplorerConfigLabel } from '../ExplorerConfiguration';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';

const isValid = (value?: number | null) => {
  return value !== undefined;
}

const isFilterTypeCorrect = (filter: EsgExplorerFilter): boolean => {
  switch (filter.type) {
    case EsgExplorerFilterType.InTopTen:
    case EsgExplorerFilterType.AboveAverage:
    case EsgExplorerFilterType.BelowAverage:
      return true;
    case EsgExplorerFilterType.InsideOfRange:
    case EsgExplorerFilterType.OutsideOfRange:
      return isValid(filter.min) && isValid(filter.max);
    case EsgExplorerFilterType.EqualTo:
    case EsgExplorerFilterType.NotEqualTo:
    case EsgExplorerFilterType.GreaterThan:
    case EsgExplorerFilterType.GreaterThanOrEqualTo:
    case EsgExplorerFilterType.LessThan:
    case EsgExplorerFilterType.LessThanOrEqualTo:
      return isValid(filter.threshold);
    default:
      return false;
  }
};

export const isFilterComplete = (filter: EsgExplorerFilter) => {
  return filter.metric && filter.type && isFilterTypeCorrect(filter);
};

interface IFilter {
  filter: EsgExplorerFilter;
  config: EsgExplorerConfiguration;
  onChange: (filter: EsgExplorerFilter) => void;
  remove: () => void;
  showAndLabel: boolean;
}

const Filter = ({ filter, config, onChange, remove }: IFilter) => {
  const { t } = useTranslation();
  const { getUnit } = useLocalisation();
  const isComplete = isFilterComplete(filter);

  return (
    <Wrapper>
      <Column>

        <div style={{ flexGrow: 1 }}>
          <FlexRow>
            <EsgExplorerConfigLabel>When</EsgExplorerConfigLabel>

            <RemoveLabel onClick={remove}>
              Remove
            </RemoveLabel>
          </FlexRow>

          <Select
            value={filter.metric && { label: t(filter.metric, { ns: 'enums', areaUnit: getUnit(AltUnits.Area) }), value: filter.metric }}
            options={config.metricTypes.map(x => ({ label: t(x, { ns: 'enums', areaUnit: getUnit(AltUnits.Area) }), value: x }))}
            onChange={selected => selected && onChange({ metric: selected.value })}
            menuPlacement='top'
            menuHeight={200}
          />
        </div>

        {filter.metric &&
          <FlexRow>
            <div style={{ flexGrow: 1 }}>
              <EsgExplorerConfigLabel>is</EsgExplorerConfigLabel>
              <Select
                value={filter.type ? { label: upperFirst(lowerCase(filter.type)), value: filter.type } : null}
                options={Object.values(EsgExplorerFilterType).map(x => ({ label: upperFirst(lowerCase(x)), value: x }))}
                onChange={selected => selected && onChange({ metric: filter.metric, type: selected.value })}
                menuPlacement='top'
                menuHeight={200}
                disabledBackground={'transparent'}
              />
            </div>

            {filter.type &&
              <FilterTypeConfiguration
                filter={filter}
                onChange={onChange}
              />
            }
          </FlexRow>
        }
      </Column>

      {!isComplete &&
        <HelperMessage>
          <WarningIcon icon={solid('exclamation-triangle')} />
          {t('ESG.FilterDefinitionIncomplete', { ns: 'molecules' })}
        </HelperMessage>
      }
    </Wrapper>
  );
}

export default Filter;

const Wrapper = styled.div`
  position: relative;
  margin: 0 -15px;
  padding: 20px 15px;

  &:not(:last-child) {
    border-bottom: 1px solid ${p => transparentize(0.2, p.theme.palette.borders.medium)};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 15px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 8px;
`;

const RemoveLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => transparentize(0.4, p.theme.palette.red)};
  transition: all 150ms ease;
  cursor: pointer;
  margin: 0 4px 0 auto;

  &:hover {
    color: ${p => p.theme.palette.red};
  }
`;

const HelperMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: ${p => p.theme.palette.text.weak};
  margin: 15px 0 -12px 3px;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.palette.orange};
  margin-right: 4px;
`;