import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import { MetricType } from '@api/enums/MetricType';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class HeatmapQuery extends PostQuery<HeatmapSpaceDto[]> {
  floorIds: number[];
  deviceModel?: string;
  metricType?: MetricType;

  constructor(floorIds: number[], deviceModel?: string, metricType?: MetricType) {
    super();
    this.floorIds = floorIds;
    this.deviceModel = deviceModel;
    this.metricType = metricType;
  }

  public targetUrl(): string {
    return '/metrics/heatmap';
  }
}