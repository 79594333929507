import { OperatorPostFormDataQuery } from '@api/queries/resident-app/common/OperatorPostFormDataQuery';
import { BlobUploadDto } from '@api/models/BlobUploadDto';

export class ChallengesResidentAppUploadImageCommand extends OperatorPostFormDataQuery<BlobUploadDto> {
  constructor(challengeId: number, file: File) {
    const formData = new FormData();
    formData.append('challengeId', challengeId.toString());
    formData.append('file', file);
    super(formData);
  }

  public targetUrl(): string {
    return '/Challenges/Upload';
  }
}
