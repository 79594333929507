import { Device } from '@api/models/Device';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DevicesGetAllDeviceModels extends GetQuery<Device[]> {
  constructor() {
    super();
  }

  public targetUrl(): string {
    return '/devices/deviceModels';
  }
}