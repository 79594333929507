import styled, { css } from 'styled-components';
import EsgExplorerTemplateDto from '@api/models/EsgExplorerTemplateDto';
import EsgExplorerRuleDto from '@api/models/EsgExplorerRuleDto';
import { useTranslation } from 'react-i18next';
import TemplateListItem from './TemplateListItem';
import NoData from '@components/core/NoData';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

interface ITemplateList {
  templates: EsgExplorerTemplateDto[];
  selectedTemplate?: EsgExplorerTemplateDto;
  siteId: number;
  refreshTemplates: () => void;
  refreshRules: () => void;
  setSelectedTemplate: (template?: EsgExplorerTemplateDto) => void;
  rules: EsgExplorerRuleDto[];
}

const TemplateList = ({ templates, selectedTemplate, siteId, refreshTemplates, refreshRules, setSelectedTemplate, rules }: ITemplateList) => {
  const { t } = useTranslation();

  return (
    <List hidden={!!selectedTemplate}>
      {templates.length === 0 &&
        <NoData
          icon={regular('file')}
          label={t('ESG.NoTemplatesAddedYet', { ns: 'molecules' })}
          subLabel={t('ESG.NoTemplatesMessage', { ns: 'molecules' })}
          styles={{ padding: '20% 20px' }}
        />
      }

      {templates.map((template) => (
        <TemplateListItem
          key={template.id}
          template={template}
          siteId={siteId}
          refreshTemplates={refreshTemplates}
          refreshRules={refreshRules}
          setSelectedTemplate={setSelectedTemplate}
          rules={rules}
        />
      ))}
    </List>
  );
};

export default TemplateList;

const List = styled.div<{ hidden?: boolean }>`
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-shrink: 0;
  transition: width 300ms ease;

  display: flex;
  flex-direction: column;

  ${p => p.hidden && css`
    width: 0px;
    overflow: hidden;
  `}
`;