import { BuildingWithFloors } from '@api/models/BuildingWithFloors';
import { Select } from '@components/select/Select';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Option = {
  label: string,
  value: BuildingWithFloors
}

type PropTypes = {
  onChange: (building: BuildingWithFloors) => void;
}

const BuildingSelect = ({ onChange }: PropTypes) => {
  const { t } = useTranslation(['molecules']);
  const { buildings } = useSiteContext();
  const { trackAction } = useAnalytics();
  const [options, setOptions] = useState<Option[]>();
  const [selected, setSelected] = useState<Option>();

  useEffect(() => {
    const options = buildings.map(x => ({
      label: x.name,
      value: x
    }));

    setOptions(options);

    if (!selected && options.length > 0) {
      setSelected(options[0]);
      onChange(options[0].value)
    }
  }, [buildings, selected, onChange]);

  const handleChange = (selected: Option) => {
    setSelected(selected);
    onChange(selected.value);
    trackAction('select_building', 'heatmap');
  };

  return (
    <Select
      value={selected}
      options={options}
      onChange={option => option && handleChange(option)}
      closeMenuOnSelect={true}
      isSearchable={true}
      hideSelectedOptions={false}
      menuHeight={200}
      placeholder={t('Heatmap.SelectBuilding', { ns: 'molecules' })}
    />
  );
};

export default BuildingSelect;