import { MetricType } from '@api/enums/MetricType';
import { OperatorPrizeDto } from '@api/models/OperatorPrizeDto';
import { ChallengesResidentAppConfirmWinnersCommand } from '@api/queries/resident-app/Challenges/ChallengesResidentAppConfirmWinnersCommand';
import { PrizesResidentAppSelectIndividualWinnerCommand } from '@api/queries/resident-app/Prizes/PrizesResidentAppSelectIndividualWinnerCommand';
import { Button } from '@components/core/Button';
import { Title } from '@components/core/Title';
import { Card } from '@components/core/Card';
import { Table } from '@components/table/Table';
import { ITableColumn } from '@components/table/Table.types';
import { useApi } from '@hooks/useApi';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import IconWithText from '../common/IconWithText';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import CardWithSideBorder from '../common/CardWithSideBorder';
import { PrizeWinnersResidentAppDeleteCommand } from '@api/queries/resident-app/PrizeWinners/PrizeWinnersResidentAppDeleteCommand';

type WinnerTableItem = {
  prizeId: number;
  challengeId: number;
  prizeName?: string;
  winnerEmail: string;
  prizeWinnerId?: number;
};

type ChallengeWinnersProps = {
  prizes: OperatorPrizeDto[];
  isWinnersConfirmed: boolean;
  onRefresh: () => void;
  challengeId: number;
  metricType: MetricType;
}

const ChallengeWinners = ({ prizes, isWinnersConfirmed, onRefresh, challengeId, metricType }: ChallengeWinnersProps) => {
  const { t } = useTranslation(['molecules', 'common']);
  const { execute, loading, error } = useApi();
  const theme = useTheme();

  const handleRedraw = async (prizeId: number) => {
    await execute({
      query: new PrizesResidentAppSelectIndividualWinnerCommand(prizeId, challengeId),
      pendingMessage: t('RedrawPending', {ns: 'status'}),
      errorMessage: t('RedrawError', {ns: 'status'}),
      successMessage: t('RedrawSuccess', {ns: 'status'})
    });

    if (!error) {
      onRefresh();
    }
  };

  const handleRemove = async (prizeWinnerId?: number) => {
    if (!prizeWinnerId) {
      return;
    } 

    await execute({
      query: new PrizeWinnersResidentAppDeleteCommand(prizeWinnerId),
      pendingMessage: t('RemovingWinner', {ns: 'status'}),
      errorMessage: t('FailedToRemoveWinner', {ns: 'status'}),
      successMessage: t('WinnerRemoved', {ns: 'status'})
    });

    if (!error) {
      onRefresh();
    }
  };

  const handleConfirmWinners = async () => {
    await execute({
      query: new ChallengesResidentAppConfirmWinnersCommand(challengeId, true),
      pendingMessage: t('ConfirmWinnersPending', {ns: 'status'}),
      errorMessage: t('ConfirmWinnersError', {ns: 'status'}),
      successMessage: t('ConfirmWinnersSuccess', {ns: 'status'})
    });

    if (!error) {
      onRefresh();
    }
  };

  const tableColumns: ITableColumn<WinnerTableItem>[] = [
    // show prize name if metric type is Temperature otherwise show space name
    ...(metricType === MetricType.Temperature) ? [{
      label: t('ResidentApp.Prize'),
      key: 'prizeName',
    }] : [],
    {
      label: t('ResidentApp.Email'),
      key: 'winnerEmail',
    },
    // show redraw button if temperature
    ...(metricType === MetricType.Temperature) ? [{
      customElement: (record: { prizeId: number; }) =>
        !isWinnersConfirmed ? (
          <Button
            label={t('ResidentApp.Redraw')}
            secondary
            onClick={() => handleRedraw(record.prizeId)}
            disabled={loading}
          />
        ) : null,
      rightAlign: true,
    }] : [],

    // show remove button if Electricity
    ...(metricType === MetricType.ElectricityKwh) ? [{
      customElement: (record: { prizeWinnerId?: number; }) =>
        !isWinnersConfirmed ? (
          <Button
            label={<IconWithText text={t('Remove', { ns: 'common' })} icon={regular('trash')} color={theme.error.main} />}
            tertiary
            onClick={() => handleRemove(record.prizeWinnerId)}
            disabled={loading}
            style={{color: theme.error.main}}
          />
        ) : null,
      rightAlign: true,
    }] : [],
  ];

  const temperatureRecords = prizes.map(prize => ({
    prizeId: prize.id,
    challengeId: prize.challengeId,
    prizeName: prize.prizeName ?? '-',
    winnerEmail: prize.prizeWinners?.[0]?.user.email ?? '-', //only one winner per prize for temperature
    prizeWinnerId: prize.prizeWinners?.[0]?.id ?? 0
  }));

  const getElecRecords = (prize: OperatorPrizeDto) => {
    return prize.prizeWinners?.map(winner => ({
      prizeId: prize.id,
      challengeId: prize.challengeId,
      prizeName: prize.prizeName ?? '-',
      winnerEmail: winner.user.email ?? '-',
      prizeWinnerId: winner.id
    })) ?? [];
  }

  return (
    <WinnersCard noPadding>
      <Content>
        <Title text={t('ResidentApp.Winners')} size="lg" />
        {!isWinnersConfirmed && (
          <Text>
            {metricType === MetricType.ElectricityKwh
              ? t('ResidentApp.ElectricityWinnersDescription')
              : t('ResidentApp.TemperatureWinnersDescription')}
          </Text>
        )}
        {/* map each prize to it's own table of winners for electricity challenge */}
        {metricType === MetricType.ElectricityKwh ? (
          <ElectricityContent>
            {prizes.map((prize) => (
              <CardWithSideBorder key={prize.id}>
                <PrizeHeading>
                  <TextContent>
                    <Title text={prize.prizeName} size="lg" style={{ fontWeight: 400 }} />
                  </TextContent>

                  {!isWinnersConfirmed && (
                    <Button
                      label={
                        <IconWithText
                          text={t('Redraw', { ns: 'common' })}
                          icon={regular('refresh')}
                        />
                      }
                      secondary
                      onClick={() => handleRedraw(prize.id)}
                      disabled={loading}
                    />
                  )}
                </PrizeHeading>
                <Table
                  columns={tableColumns}
                  records={getElecRecords(prize)}
                  recordKey="prizeId"
                  disablePaging
                  cardEffect
                  minHeight="0px"
                />
              </CardWithSideBorder>
            ))}
          </ElectricityContent>
        ) : (
          <Table
            columns={tableColumns}
            records={temperatureRecords}
            recordKey="prizeId"
            disablePaging
            cardEffect
            minHeight="0px"
          />
        )}

        {!isWinnersConfirmed && (
          <Button
            label={t('Confirm', { ns: 'common' })}
            style={{ marginLeft: 'auto', marginTop: '10px' }}
            disabled={loading}
            onClick={handleConfirmWinners}
          />
        )}
      </Content>
    </WinnersCard>
  );
}

export default ChallengeWinners;

const WinnersCard = styled(Card)`
  margin-top: 30px;
  padding: 25px 20px;
`;

const Text = styled.p`
  font-size: 14px;
  margin: 0;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.text.secondary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PrizeHeading = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextContent = styled.div`
  display: grid;
  row-gap: 4px;
  margin-bottom: 16px;
`;

const ElectricityContent = styled.div`
  display: grid;
  gap: 24px;
`;