import { useTranslation } from 'react-i18next'
import { WarningDialog } from '@components/core/WarningDialog';
import { useModal } from '@hooks/useModal';
import { useEffect, useState } from 'react';
import SiteFeature from '@pages/site/settings/site/features/components/SiteFeature';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useApi } from '@hooks/useApi';
import SiteMetricTypeResidentAppCreateCommand from '@api/queries/resident-app/SiteMetricTypes/SiteMetricTypeResidentAppCreateCommand';
import { SiteMetricTypeResidentAppDeleteCommand } from '@api/queries/resident-app/SiteMetricTypes/SiteMetricTypeResidentAppDeleteCommand';
import { ResidentAppFeatureType } from '@api/enums/ResidentAppFeatureType';

type ResidentAppFeatureToggleeProps ={
  featureType: ResidentAppFeatureType;
}

const ResidentAppFeatureToggle =({ featureType }: ResidentAppFeatureToggleeProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const { siteOperator, refreshSiteOperator} = useSiteContext();
  const { isOpen: isDialogOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});
  const [isEnabled, setIsEnabled] = useState(false);

  const siteMetricTypeDto = siteOperator?.siteMetricTypes?.find((siteMetricType) => siteMetricType.metricType === featureType);

  useEffect(() => {
    setIsEnabled(!!siteMetricTypeDto);
  }, [siteMetricTypeDto]);

  const enableFeature = () => {
    setIsEnabled(true);
    enableSiteMetricType();
  };    

  const enableSiteMetricType = async () => {
    if(siteOperator){
      await execute({
        query: new SiteMetricTypeResidentAppCreateCommand(
          siteOperator.id,
          featureType),
        successMessage: t('ResidentApp.MetricEnabled', { ns: 'molecules',  metricType: t(featureType, {ns: 'enums'}) }),
        errorMessage: t('ResidentApp.MetricEnabledError', { ns: 'molecules', metricType: t(featureType, {ns: 'enums'}) }),
        pendingMessage: t('ResidentApp.MetricEnabledPending', { ns: 'molecules', metricType: t(featureType, {ns: 'enums'}) })
      })
    }

    refreshSiteOperator();
  }

  const disableSiteMetricType = async () => {
    if(siteMetricTypeDto){
      await execute({
        query: new SiteMetricTypeResidentAppDeleteCommand(
          siteMetricTypeDto.id
        ),
        successMessage: t('ResidentApp.MetricDisabled', { ns: 'molecules', metricType: t(featureType, {ns: 'enums'}) }),
        errorMessage: t('ResidentApp.MetricDisabledError', { ns: 'molecules', metricType: t(featureType, {ns: 'enums'}) }),
        pendingMessage: t('ResidentApp.MetricDisabledPending', { ns: 'molecules', metricType: t(featureType, {ns: 'enums'}) })
      })

      toggleDialog();
      refreshSiteOperator();
    }
  }

  return(
    <>
      <WarningDialog
        modalRef={dialogRef}
        isOpen={isDialogOpen}
        sectionOne={t('ResidentApp.DisableResidentMetricDialog', { ns: 'molecules', metricType: t(featureType, {ns: 'enums'})})}
        confirmButton={t('Disable', { ns: 'common' })}
        onCancel={toggleDialog}
        onConfirm={disableSiteMetricType}
      />

      <SiteFeature
        name={t(featureType, {ns: 'enums'})}
        description={t('ResidentApp.MetricFeatureDescription', { ns: 'molecules', metricType: t(featureType, {ns: 'enums'})})}
        icon={solid('mobile-screen-button')}
        isEnabled={isEnabled}
        onChange={(isFeatureEnabled: boolean) => isFeatureEnabled ? enableFeature() : toggleDialog()}
      />
    </>
  )
};

export default ResidentAppFeatureToggle;