import { GetQuery } from '@api/queries/common/GetQuery';
import { BlobUri } from '@api/models/BlobUri';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';

export class BlobGetSasUriQuery extends GetQuery<BlobUri> {
  blobName: string;
  containerType: BlobStorageContainerType;
  customContainerName?: string;

  constructor(blobName: string, containerType = BlobStorageContainerType.Tenant, customContainerName?: string) {
    super();
    this.blobName = blobName;
    this.containerType = containerType;
    this.customContainerName = customContainerName;
  }

  public targetUrl(): string {
    return `/blob/${this.containerType}/${this.blobName}/${this.customContainerName}`;
  }
}
