import { EnergyMeterBillDto } from '@api/models/EnergyMeterBillDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EnergyMeterBillGetByIdQuery extends PostQuery<EnergyMeterBillDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return '/energymeters/GetEnergyMeterBillById';
  }
}