import styled from 'styled-components';
import MetricSelect from './MetricSelect';
import { Button } from '@components/core/Button';
import { useTranslation } from 'react-i18next';
import { TOutlierConfig } from './OutlierWidget';
import TimeRangeSelect from './TimeRangeSelect';
import { MetricType } from '@api/enums/MetricType';

type PropTypes = {
  config: TOutlierConfig;
  siteMetricTypes?: MetricType[];
  onChange: (config: TOutlierConfig) => void;
  onGenerate: () => void;
}

const OutlierConfig = ({ config, siteMetricTypes, onChange, onGenerate }: PropTypes) => {
  const { t } = useTranslation();

  return (
    <FlexColumn>
      <FlexRow>
        <MetricSelect
          config={config}
          siteMetricTypes={siteMetricTypes}
          onChange={onChange}
        />

        <Button
          label={t('Generate', { ns: 'common' })}
          onClick={onGenerate}
          disabled={!config.metric}
        />
      </FlexRow>

      <TimeRangeSelect
        config={config}
        onChange={onChange}
      />
    </FlexColumn>
  );
};

export default OutlierConfig;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
`;