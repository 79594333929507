import EsgMetricType from '@api/enums/EsgMetricType';
import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import { EsgExplorerMetricsSortOrder, MetricTypesToCalculateEsgMetric, getEsgExplorerMetricOptions } from '@src/component-configuration-models/esg-explorer/EsgExplorer_MetricOptions';
import useSiteMetricTypes from '@hooks/useSiteMetricTypes';
import { MetricType } from '@api/enums/MetricType';
import { CheckboxSelect } from '@components/checkbox-select/CheckboxSelect';
import { ICheckboxSelectGroup } from '@components/checkbox-select/CheckboxSelect.types';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const metricTypeExistInBuilding = (metricTypes: MetricType[], esgMetricType: EsgMetricType) => {
  for (const metricType of MetricTypesToCalculateEsgMetric[esgMetricType]) {
    if (metricTypes.includes(metricType)) {
      return true;
    }
  }

  return false;
};

interface IMetricSelect {
  siteId: number;
  config: EsgExplorerConfiguration;
  onChange: (config: EsgExplorerConfiguration) => void;
}

const MetricSelect = ({ siteId, config, onChange }: IMetricSelect) => {
  const { t } = useTranslation(['molecules', 'enums']);
  const { getUnit, localisation } = useLocalisation();
  const [metricOptions, setMetricOptions] = useState<ICheckboxSelectGroup<EsgMetricType>[]>([]);
  const { siteMetricTypes } = useSiteMetricTypes(siteId);

  useEffect(() => {
    if (!siteMetricTypes) {
      return;
    }

    const metricOptions: ICheckboxSelectGroup<EsgMetricType>[] = [];

    getEsgExplorerMetricOptions(localisation).forEach(group => {
      const filteredOptions = group.options.filter(x => metricTypeExistInBuilding(siteMetricTypes, x.value));

      if (filteredOptions.length > 0) {
        metricOptions.push({
          ...group,
          options: group.options
            .filter(opt => metricTypeExistInBuilding(siteMetricTypes, opt.value))
            .map(opt => ({
              ...opt,
              selected: config.metricTypes.includes(opt.value),
              label: t(opt.label, { ns: 'enums', areaUnit: getUnit(AltUnits.Area) })
            }))
        })
      }
    });

    setMetricOptions(metricOptions);
  }, [config, siteMetricTypes, localisation, t, getUnit]);

  return (
    <CheckboxSelect
      options={metricOptions}
      onChange={(values) =>
        onChange({
          ...config,
          metricTypes: values.sort((a, b) => EsgExplorerMetricsSortOrder.indexOf(a) - EsgExplorerMetricsSortOrder.indexOf(b)),
          // Removes filters that use a metricType which is no longer selected
          filters: config.filters.filter(x => x.metric && values.includes(x.metric))
        })
      }
      placeholder={t('Select', { ns: 'common' })}
      dropdownMaxHeight={200}
    />
  );
};

export default MetricSelect;