import { exportCSV } from '@utils/ExportUtils';
import { SpaceType } from '@api/enums/SpaceType';
import { Cluster } from '@api/models/Cluster';
import { ClustersAndSpaces } from '@api/models/ClustersAndSpaces';
import { Space } from '@api/models/Space';

export interface CsvObject {
  [key: string]: string;
  area: string;
  buildingName: string;
  clusterName: string;
  floorNumber: string;
  occupied: string;
  unitName: string;
  unitType: string;
}

export type UnitScheduleForm = {
  data: CsvObject[]
};

export interface SpacesAndClustersByFloor {
  spacesAndClusters: ClustersAndSpaces;
  floorName: number;
}

export const regexAlphaNumeric = /^[A-Za-z0-9 _.-]+$/;
export const regexIntOnly = /^(-?[0-9\b]+)$/;

/**
 * Parses the Csv file to an array of strings
 */
export const parseCsv = (csvFile: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target) {
        const csvContent = e.target.result as string;
        const lines = csvContent.split(/\r\n|\n/);

        const nonEmptyLines = lines.filter((line) => line.trim() !== '');

        resolve(nonEmptyLines);
      }
    };

    reader.onerror = () => {
      reject(new Error('Error reading CSV file'));
    };

    reader.readAsText(csvFile);
  });
}

/**
 * Trims all trailing commas from the string array and splits it into arrays of string arrays for each line
 */
export const trimAndSplitCsvLines = (lines: string[]): string[][] => {
  const trimmedAndSplitLines: string[][] = [];
  const delimiter = ',';
  const regexForTenCommas = /,{10}$/;

  lines.forEach((line) => {
    const trimmedLine = line.replace(regexForTenCommas, '');

    if (trimmedLine !== '') {
      const splitLine = trimmedLine.split(delimiter);
      trimmedAndSplitLines.push(splitLine);
    }
  });

  if (trimmedAndSplitLines.some(value => value.length !== 7)) {
    return [[]];
  }

  return trimmedAndSplitLines;
};

/**
 * Maps arrays of string arrays to an Object
 */
export const mapStringArrayToObject = (data: string[][]): CsvObject[] => {
  const headers = data[0].map(x => x.charAt(0).toLowerCase() + x.slice(1).replace(/\s/g, '')).map(x => x === 'area(sqm)' ? 'area' : x);

  return data.slice(1).map((row) => {
    const obj: CsvObject = {} as CsvObject;
    headers.forEach((header, index) => {
      obj[header] = row[index];
    });
    const updatedObject = validateObject(obj);
    return updatedObject;
  });
};

/**
 * Validates the object's fields and swaps out values on the object as necessary
 */
export const validateObject = (obj: CsvObject): CsvObject => {
  // Cluster Name is an optional value
  for (const key in obj) {
    if (key === 'clusterName' && obj[key] === '') {
      obj[key] = '-'
    }

    if (obj[key] === '' || obj[key] === undefined) {
      obj[key] = '';
    }
  }

  // If the space is part of a cluster the spaceType can only be Bedroom or LKD
  if (obj.clusterName !== '-' && (obj.unitType !== SpaceType.Bedroom && obj.unitType !== SpaceType.LKD)) {
    obj.unitType = '';
  }

  return obj;
};

/**
 * Checks floor numbers as some floors, clusters or spaces may need to be created
 */
export const breakUpCsvObjectArrayByFloor = (csvObjectArray: CsvObject[]): SpacesAndClustersByFloor[] => {
  const newSpacesAndClustersByFloor: SpacesAndClustersByFloor[] = [];
  const uniqueFloorNumbers = Array.from(new Set(csvObjectArray.map((line) => parseInt(line.floorNumber))));

  uniqueFloorNumbers.forEach((floorId) => {
    const spaces: Space[] = [];
    const clusters: Cluster[] = [];

    csvObjectArray.forEach((line) => {
      const lineFloor = parseInt(line.floorNumber);
      if (lineFloor === floorId) {
        if (line.clusterName !== '-' && line.clusterName !== '') {
          if (!clusters.some(cluster => cluster.clusterName === line.clusterName)) {
            const newCluster: Cluster = {} as Cluster;
            newCluster.clusterName = line.clusterName;
            newCluster.spaces = [];
            clusters.push(newCluster);
          }
          if (clusters.some(cluster => cluster.clusterName === line.clusterName)) {
            const clusterToPushTo = clusters.find(cluster => cluster.clusterName === line.clusterName);
            const newSpace: Space = {} as Space;
            newSpace.name = line.unitName;
            newSpace.spaceType = line.unitType as SpaceType;
            newSpace.area = parseInt(line.area);
            newSpace.occupied = Boolean(line.occupied.toLowerCase());
            clusterToPushTo?.spaces.push(newSpace);
          }
        } else {
          const newSpace: Space = {} as Space;
          newSpace.name = line.unitName;
          newSpace.spaceType = line.unitType as SpaceType;
          newSpace.area = parseInt(line.area);
          newSpace.occupied = Boolean(line.occupied.toLowerCase());
          spaces.push(newSpace);
        }
      }
    });

    const spacesAndClustersByFloor: SpacesAndClustersByFloor = {
      floorName: floorId,
      spacesAndClusters: {
        clusters,
        spaces,
      },
    };

    newSpacesAndClustersByFloor.push(spacesAndClustersByFloor);
  });

  return newSpacesAndClustersByFloor;
};

/**
 * CSV download logic
 */
export const downloadUpdatedTableDataAsCSV = (fileName: string, formData: UnitScheduleForm) => {
  let stringData = '';

  formData.data.forEach((line) => {
    const values = Object.values(line);
    values.forEach((value) => {
      stringData += value + ','
    })
  })

  const properFormat = `Building Name,Floor Number,Unit Name,Unit Type,Cluster Name,Area (sqm),Occupied\n${stringData}`

  const finalForm = properFormat.replace(/(FALSE|TRUE),/g, '$1\n');

  exportCSV(fileName, finalForm)
};