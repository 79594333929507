import { exportCSV } from '@utils/ExportUtils';
import { Button } from '@components/core/Button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';

type PropTypes = {
  fileNameSuffix?: string;
  csv?: string;
};

const UtilityWidgetExport = ({ fileNameSuffix, csv }: PropTypes) => {
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  const { site } = useSiteContext();
  const { trackAction } = useAnalytics();

  const downloadFile = useCallback(async () => {
    if (!csv) {
      return;
    }

    const fileName = `${tenant.name}_${site.name}_${fileNameSuffix}`;

    exportCSV(fileName, csv);
    trackAction('export_excel', 'performance_utilities');
  }, [csv, site, tenant, fileNameSuffix, trackAction]);

  return (
    <div style={{ marginLeft: 'auto' }}>
      <Button
        secondary
        label={t('Export', { ns: 'common' })}
        onClick={() => downloadFile()}
        disabled={!csv}
      />
    </div>
  );
};

export default UtilityWidgetExport;