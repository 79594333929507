import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useApiState } from '@hooks/useApiState';
import { useApi } from '@hooks/useApi';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { SelectedTenantKey } from '@utils/ConnectApiUtils';
import { BlobGetSasUriQuery } from '@api/queries/blob/BlobGetSasUriQuery';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';
import { useTranslation } from 'react-i18next';
import { Select } from '@components/select/Select';
import { OptionProps, components, SingleValueProps, GroupBase } from 'react-select';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { TenantsWithSitesGetByUserQuery } from '@api/queries/tenants/TenantsWithSitesGetByUserQuery';
import { TenantWithSites } from '@api/models/TenantWithSites';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { Site } from '@api/models/Site';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { alphabeticalSort } from '@utils/StringUtils';
import { useUserContext } from '@contexts/UserContext/UserContext';

type THandleSelect = (tenantId: string, tenantName: string, siteId?: number, siteName?: string) => void;

type TOption = {
  label: string;
  value: TenantWithSites & {
    logo: string | undefined;
  };
}

const getMatchingSites = (sites: Site[], inputValue: string) => sites
  .filter(x => x.name.toLowerCase().includes(inputValue.toLowerCase()))
  .sort((a, b) => alphabeticalSort(a.name, b.name));

const Option = (props: OptionProps<TOption, false, GroupBase<TOption>> & { inputValue: string, handleSelect: THandleSelect }) => {
  const { data, inputValue, handleSelect } = props;
  const { value: tenant } = data;

  const sites = getMatchingSites(tenant.sites, inputValue);

  return (
    <components.Option {...props}>
      <>
        <TenantListItem onClick={() => handleSelect(tenant.tenantId, tenant.name)}>
          <TenantLogoWrapper>
            {tenant.logo
              ? <TenantLogoImg loading='lazy' src={tenant.logo} />
              : <TenantInitial>{tenant.name.slice(0, 1)}</TenantInitial>
            }
          </TenantLogoWrapper>
          <Label>
            {tenant.name}
          </Label>
          <TenantChevron icon={solid('chevron-right')} />
        </TenantListItem>

        {inputValue && sites.length > 0 &&
          <SiteList>
            {sites.map(site => (
              <SiteListItem
                key={site.id}
                onClick={() => handleSelect(tenant.tenantId, tenant.name, site.id, site.name)}
              >
                <Label>
                  {site.name}
                </Label>
                <Chevron icon={solid('chevron-right')} />
              </SiteListItem>
            ))}
          </SiteList>
        }
      </>
    </components.Option >
  );
};

const SingleValue = (props: SingleValueProps<TOption>) => {
  const { value: tenant } = props.data;

  return (
    <components.SingleValue  {...props} >
      <Placeholder>Tenant:</Placeholder> <TenantName>{tenant.name}</TenantName>
    </components.SingleValue >
  );
};

const TopBarTenantSelect = () => {
  const { t } = useTranslation(['commonApp']);
  const navigate = useNavigate();
  const { trackAction } = useAnalytics();
  const { tenant } = useTenantContext();
  const { execute } = useApi();
  const { claims } = useUserContext();
  const [inputValue, setInputValue] = useState('');
  const [tenantsWithLogo, setTenantsWithLogo] = useState<(TenantWithSites & { logo: string | undefined })[]>([]);
  const { data: userTenants, loading } = useApiState({
    query: claims.tenants.length < 2 ? undefined : new TenantsWithSitesGetByUserQuery(),
    initialState: [],
    errorMessage: t('TopBar.Tenants.TenantsLoadError', { ns: 'commonApp' })
  }, []);

  const options: TOption[] = tenantsWithLogo.map(x => ({ label: x.name, value: x }));

  const handleSelect: THandleSelect = useCallback((tenantId: string, tenantName: string, siteId?: number, siteName?: string) => {
    localStorage.setItem(SelectedTenantKey, tenantId);

    if (siteId) {
      trackAction('site_tenant_switch', 'tenant_navigation', { site_name: siteName, new_tenant_name: tenantName, new_tenant_id: tenantId });
      navigate(`/site/${siteId}`);
    } else {
      trackAction('tenant_switch', 'tenant_navigation', { new_tenant_name: tenantName, new_tenant_id: tenantId });
      navigate('/');
    }

    window.location.reload();
  }, [navigate, trackAction]);

  // Custom filter function to check if the tenant name or any site name matches the search string
  const customFilter = useCallback((option: FilterOptionOption<TOption>, inputValue: string) => {
    return (
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.value.sites.some(site => site.name.toLowerCase().includes(inputValue.toLowerCase()))
    );
  }, []);

  const getBlobUri = useCallback(
    async (blobName?: string) => {
      if (!blobName) {
        return undefined;
      }

      const res = await execute({
        query: new BlobGetSasUriQuery(blobName, BlobStorageContainerType.Shared)
      });

      return res?.blobUri;
    },
    [execute]
  );

  useEffect(() => {
    const populateLogo = async () => {
      const tenantsWithLogo = await Promise.all(
        userTenants.map(async (x) => ({
          ...x,
          logo: await getBlobUri(x.operatorLogoId)
        }))
      );
      setTenantsWithLogo(tenantsWithLogo.sort((a, b) => alphabeticalSort(a.name, b.name)));
    };

    populateLogo();
  }, [userTenants, getBlobUri]);

  if (claims.tenants.length < 2) {
    return null;
  }

  return (
    <Wrapper>
      <Select
        options={options}
        value={options.find(x => x.value.tenantId === tenant.tenantId)}
        components={{
          Option: props => <Option {...props} inputValue={inputValue} handleSelect={handleSelect} />,
          SingleValue
        }}
        isMulti={false}
        onMenuOpen={() => trackAction('tenant_box', 'tenant_navigation')}
        isSearchable
        onInputChange={value => setInputValue(value)}
        filterOption={customFilter}
        menuHeight={300}
        customStyles={{
          option: () => ({}),
          menuList: () => ({
            maxHeight: 300,
            padding: '10px 12px',
            overflow: 'auto'
          })
        }}
        isLoading={loading}
        placeholder={t('LoadingTenants', { ns: 'status' })}
      />
    </Wrapper>
  );
};

export default TopBarTenantSelect;

const Wrapper = styled.div`
  width: 160px;

  @media (min-width: 400px) {
    width: 200px;
  }

  @media (min-width: 700px) {
    width: 240px;
  }
`;

const SiteList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
  padding-left: 56px;
`;

const SiteListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  margin-left: -10px;
  margin-right: 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;

const Label = styled.div`
  overflow-wrap: break-word;

  width: 47px;

  @media (min-width: 400px) {
    width: 77px;
  }

  @media (min-width: 700px) {
    width: 111px;
  }
`;

const Chevron = styled(FontAwesomeIcon)`
  font-size: 8px;
`;

const TenantChevron = styled(FontAwesomeIcon)`
  font-size: 10px;
  margin: 0 4px 0 auto;
`;

const TenantListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-bottom: 20px;
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;

const Placeholder = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
`;

const TenantName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
`;

const TenantInitial = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;

const TenantLogoWrapper = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  box-shadow: 0px 2px 12px 0px ${p => p.theme.shadow.dark};
`;

const TenantLogoImg = styled.img`
  width: auto;
  height: auto;
  max-width: 20px;
  max-height: 20px;
`;