import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import IconWithText from '@pages/site/resident-app/components/common/IconWithText';
import MetricTypePill from '@pages/site/resident-app/components/common/MetricTypePill';
import { MetricType } from '@api/enums/MetricType';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from '@components/core/Button';

type ThresholdEditTitleProps = {
  metricType: MetricType;
  descriptionText: string;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
};

const ThresholdEditTitle = ({metricType, descriptionText, isEditMode, setIsEditMode}: ThresholdEditTitleProps) => {
  const { t } = useTranslation('molecules');
  
  return (
    <TitleWithEdit>
      <div>
        <MetricTypePill metricType={metricType} />
        <Text>{descriptionText}</Text>
      </div>
      {!isEditMode && (
        <Button
          label={<IconWithText icon={solid('pencil')} text={t('Edit', { ns: 'common' })} />}
          tertiary
          onClick={() => setIsEditMode(true)}
        />
      )}
    </TitleWithEdit>
  );
}

export default ThresholdEditTitle;

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

const TitleWithEdit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;