import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import DetailsPanelDevices from './DetailsPanelDevices';
import { transparentize } from 'polished';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from '@api/models/Alert';
import { useSize } from '@hooks/useSize';
import { LabelValuePair } from '@components/core/LabelValuePair';
import { TopBarHeight } from '@src/constants/designConstants';

export interface IDetailsPanelTab {
  alert: Alert;
  onMobile: boolean;
}

type DetailsPanelTab = {
  label: string,
  component: ({ alert }: IDetailsPanelTab) => JSX.Element
}

const tabs: DetailsPanelTab[] = [
  {
    label: 'Devices',
    component: DetailsPanelDevices
  }
];

interface IDetailsPanel {
  alert: Alert;
  close: () => void;
  onMobile: boolean;
}

const DetailsPanel = ({ alert, close, onMobile }: IDetailsPanel) => {
  const ref = useRef<HTMLDivElement>(null);
  const { domRect } = useSize(ref);
  const [activeTab, setActiveTab] = useState(0);
  const [heightOffset, setHeightOffset] = useState(0);
  const { t } = useTranslation(['molecules']);

  useEffect(() => {
    if (domRect) {
      setHeightOffset(domRect.height);
    }
  }, [domRect]);

  return (
    <StyledPanel $onMobile={onMobile}>
      <Content $onMobile={onMobile}>
        <SummaryColumn $onMobile={onMobile}>
          <Dividerlabel>{t('Alerting.Details', { ns: 'molecules' })}</Dividerlabel>

          <Label>
            {t('Alerting.Created', { ns: 'molecules' })}
            <Value>
              {format(new Date(alert.createdOn), 'dd. MMM yyy, hh:mmaaa')}
            </Value>
          </Label>

          <Label>
            {t('Alerting.Acknowledged', { ns: 'molecules' })}
            <Value>
              {alert.resolvedOn ? format(new Date(alert.resolvedOn), 'dd. MMM yyy, hh:mmaaa') : '--'}
            </Value>
          </Label>

          <Dividerlabel>{t('Alerting.Location', { ns: 'molecules' })}</Dividerlabel>

          <HierarchyItem to={`/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}/floor/${alert.space.floor.id}/space/${alert.spaceId}`}>
            <LabelValuePair
              label="Space"
              value={alert.space.name}
              size="xs"
              smallGap
            />
            <ArrowIcon icon={light('circle-arrow-right')} />
          </HierarchyItem>

          <HierarchyItem to={`/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}/floor/${alert.space.floor.id}`}>
            <LabelValuePair
              label={t('Alerting.Floor', { ns: 'molecules' })}
              value={alert.space.floor.name}
              size="xs"
              smallGap
            />
            <ArrowIcon icon={light('circle-arrow-right')} />
          </HierarchyItem>

          <HierarchyItem to={`/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}`}>
            <LabelValuePair
              label={t('Alerting.Building', { ns: 'molecules' })}
              value={alert.space.floor.building.name}
              size="xs"
              smallGap
            />
            <ArrowIcon icon={light('circle-arrow-right')} />
          </HierarchyItem>

          <HierarchyItem to={`/site/${alert.space.floor.building.siteId}`}>
            <LabelValuePair
              label={t('Alerting.Site', { ns: 'molecules' })}
              value={alert.space.floor.building.site.name}
              size="xs"
              smallGap
            />
            <ArrowIcon icon={light('circle-arrow-right')} />
          </HierarchyItem>
        </SummaryColumn>

        <DetailsColumn $onMobile={onMobile}>
          <div ref={ref}>
            <FlexRow>
              <div>
                <RuleName>
                  {alert.message.ruleName}
                </RuleName>
                <Description>
                  {alert.message.description}
                </Description>
              </div>

              <RightAligned>
                <CloseButton icon={solid('xmark')} onClick={close} />
              </RightAligned>
            </FlexRow>

            <TabLabels>
              {tabs.map((tab, i) => (
                <TabLabel key={i} onClick={() => setActiveTab(i)}>
                  {t(tab.label, { ns: 'molecules' })}
                </TabLabel>
              ))}
            </TabLabels>
          </div>

          {tabs.filter((_, i) => i === activeTab).map((tab, i) => (
            <TabContainer key={i} subtractHeight={heightOffset} $onMobile={onMobile}>
              {<tab.component alert={alert} onMobile={onMobile} />}
            </TabContainer>
          ))}
        </DetailsColumn>
      </Content>
    </StyledPanel>
  );
};

export default DetailsPanel;

const FlexRow = styled.div`
  display: flex;
  padding-top: 20px;
`;

const StyledPanel = styled.div<{ $onMobile: boolean }>`
  z-index: 1;
  white-space: wrap;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 4px;
  
  position: relative;
  right: 0px;
  width: 100%;
  overflow: hidden;

  max-height: ${`calc(100vh - ${TopBarHeight}px - 195px)`};
  min-height: 300px;

  ${p => p.$onMobile && css`
    max-height: max-content;
  `}
`;

const Content = styled.div<{ $onMobile: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;

  ${p => p.$onMobile && css`
    flex-direction: column;
  `}
`;

const RuleName = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  flex-wrap: wrap;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
  padding-left: 20px;
  flex-wrap: wrap;
`;

const RightAligned = styled.div`
  margin-left: auto;
  padding-right: 20px;
  display: flex;
  align-items: center;
`;

const CloseButton = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  padding: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${p => p.theme.palette.primary};
  align-self: flex-start;
  margin-top: 2px;

  &:hover {
    background-color: ${p => p.theme.palette.topbar.iconHoverBackground};
  }
`;

const SummaryColumn = styled.div<{ $onMobile: boolean }>`
  position: relative;
  width: 240px;
  border-right: 1px solid ${p => p.theme.palette.borders.medium};
  padding: 25px 30px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 20px;

  ${p => p.$onMobile && css`
    order: 2;
    width: 100%;
    border: none;
    /* height: 500px; */
    /* overflow: auto; */
  `}
`;

const DetailsColumn = styled.div<{ $onMobile: boolean }>`
  width: 100%;

  ${p => p.$onMobile && css`
    order: 1;
  `}
`;

const TabLabels = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  box-shadow: 0 4px 6px -4px ${p => p.theme.palette.shadows.medium};
`;

const TabLabel = styled.div`
  color: 'inherit';
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  cursor: pointer;

  display: flex;
  align-items: center;
`;

const TabContainer = styled.div<{ subtractHeight: number, $onMobile: boolean }>`
  height: ${p => `calc(100% - ${p.subtractHeight}px)`};
  overflow: hidden auto;

  ${p => p.$onMobile && css`
    height: 200px;
    box-shadow: 0 -4px 6px -4px ${p => p.theme.palette.shadows.medium} inset;
    border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  `}
`;

const Label = styled.div<{ inline?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const Value = styled.div<{ inline?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.medium};
`;

const Dividerlabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px dashed ${p => p.theme.palette.borders.medium};
`;

const HierarchyItem = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
  margin-top: 3px;
  color: ${p => transparentize(0.5, p.theme.palette.text.weak)};

  ${HierarchyItem}:hover & {
    color: ${p => p.theme.palette.primary};
  }
`;