import { Site } from '@api/models/Site';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SiteGetByIdQuery extends GetQuery<Site> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/sites/${this.id}`;
  }
}
