import { DeviceGateway } from '@api/models/DeviceGateway';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DeviceGatewayGetByBuildingIdQuery extends GetQuery<DeviceGateway[]> {
  buildingId: number;

  constructor(buildingId: number) {
    super();
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return `/gateway/getDeviceGatewayByBuildingId/${this.buildingId}`;
  }
}
