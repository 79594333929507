import styled from 'styled-components'
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/core/Button';
import { TextArea } from '@components/Form';

interface PropTypes {
  onCreate: (content: string) => void;
}

const NewNote = ({ onCreate }: PropTypes) => {
  const { t } = useTranslation();
  const [content, setContent] = useState<string>('');
  const isEmpty = !content;

  const handleCreate = useCallback(() => {
    onCreate(content);
    setContent('');
  }, [content, onCreate]);

  return (
    <Item>
      <StyledTextArea
        onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContent(e.currentTarget.value)}
        placeholder={t('DeviceCard.AddNote', { ns: 'molecules' })}
        value={content}
        isEmpty={isEmpty}
      />

      {!isEmpty &&
        <FlexRow>
          <Button
            label={t('Save', { ns: 'common' })}
            onClick={handleCreate}
            disabled={!content}
          />
          <Button
            tertiary
            label={t('Cancel', { ns: 'common' })}
            onClick={() => setContent('')}
          />
        </FlexRow>
      }
    </Item>
  );
}

export default NewNote;

const Item = styled.div`
  margin-bottom: 4px;
`;

const StyledTextArea = styled(TextArea) <{ isEmpty: boolean }>`
  background-color: transparent;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  height: ${p => p.isEmpty ? '48px' : '100px'};
  box-shadow: 0 1px 2px 0 ${p => p.theme.shadow.primary};

  &::placeholder {
    color: ${p => p.theme.text.light};
    font-weight: 400;
  }
`;

const FlexRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;