import { Device } from '@api/models/Device';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class DeviceUpdateCommand extends PatchQuery<Device> {
  friendlyName: string;

  constructor(id: number, friendlyName: string) {
    super(id);
    this.friendlyName = friendlyName;
  }

  public targetUrl(): string {
    return '/devices';
  }
}
