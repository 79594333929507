import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SiteFairUseSeasonalityAdjustment } from '@api/models/SiteFairUseSeasonalityAdjustment';
import { EnergyMeterType_Suffix } from '@api/enums/EnergyMeterType_Suffix';
import { EnergyMeterType_Color } from '@api/enums/EnergyMeterType_Color';
import { getAbbreviatedMonthName } from '@utils/DateUtils';
import { round } from '@utils/NumberUtils';
import SeasonalityChart, { MonthlyConsumptionDataset } from './SeasonalityChart';
import SeasonalityTable from './SeasonalityTable/SeasonailityTable';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FairUseForm } from '@api/models/FairUseForm';
import { transparentize } from 'polished';
import { MonthlyConsumptionDto, SiteFairUseEnergyConsumption } from '@api/models/SiteFairUseEnergyConsumption';

type SeasonalityProps = {
  editMode: boolean
  energyConsumption?: SiteFairUseEnergyConsumption;
}

const Seasonality = ({ editMode, energyConsumption }: SeasonalityProps) => {
  const { t } = useTranslation();
  const { control, watch, getValues } = useFormContext<FairUseForm>();
  const total = watch('total');
  const start = watch('start');
  const energyMeterType = watch('energyMeterType');
  const remainingPercentage = watch('remainingPercentage');
  const [dataSets, setDataSets] = useState<MonthlyConsumptionDataset[]>([]);
  const { fields } = useFieldArray({
    control,
    name: 'seasonalityAdjustments'
  });

  const getTargetDataSets = useCallback((): MonthlyConsumptionDataset => {
    const target = fields.filter(seasonalityAdjustment => seasonalityAdjustment.meterType === energyMeterType)
      .map(seasonalityAdjustment => round((total * getValues(`seasonalityAdjustments.${fields.indexOf(seasonalityAdjustment)}.adjustment`)) / 100, 2));

    return {
      dataset: target,
      dataUnit: EnergyMeterType_Suffix(energyMeterType),
      color: !editMode ? transparentize(0.6, EnergyMeterType_Color(energyMeterType)) : EnergyMeterType_Color(energyMeterType),
      label: t('Target', { ns: 'common' })
    };

  }, [editMode, energyMeterType, fields, t, total, getValues]);

  const getActualDataSets = useCallback((): MonthlyConsumptionDataset => {
    const actualDataSet = Array(12).fill(0);

    energyConsumption?.monthlyConsumptionValues
      .sort((currentValue, previousValue) => new Date(currentValue.start).getTime() - new Date(previousValue.start).getTime())
      .forEach((monthlyConsumptionValue: MonthlyConsumptionDto) => {
        if (new Date(monthlyConsumptionValue.start).getMonth() - new Date(start).getMonth() >= 0) {
          const monthIndex = new Date(monthlyConsumptionValue.start).getMonth() - new Date(start).getMonth();
          actualDataSet[monthIndex] = round(monthlyConsumptionValue.consumptionValue / 1000, 2);
        } else {
          const monthIndex = (12 - (new Date(start).getMonth() + 1)) + (new Date(monthlyConsumptionValue.start).getMonth() + 1)
          actualDataSet[monthIndex] = round(monthlyConsumptionValue.consumptionValue / 1000, 2);
        }
      });

    return {
      dataset: actualDataSet,
      dataUnit: EnergyMeterType_Suffix(energyMeterType),
      color: EnergyMeterType_Color(energyMeterType),
      label: t('Actual', { ns: 'common' })
    };
  }, [energyConsumption?.monthlyConsumptionValues, energyMeterType, start, t]);


  useEffect(() => {
    const dataSets = [];
    const targetDataSets = getTargetDataSets();

    if (targetDataSets) {
      dataSets.push(targetDataSets)
    }

    if (!editMode && energyConsumption) {
      const actualDataSet = getActualDataSets();

      if (actualDataSet) {
        dataSets.push(actualDataSet)
      }
    }

    setDataSets(dataSets);
  }, [editMode, energyConsumption, getActualDataSets, getTargetDataSets, remainingPercentage]);


  const getChartLabels = (): string[] => {
    const filteredSeasonalityAdjustments = fields.filter(x => x.meterType === energyMeterType)
    return filteredSeasonalityAdjustments.map((seasonalityAdjustment: SiteFairUseSeasonalityAdjustment) =>
      getAbbreviatedMonthName(new Date(seasonalityAdjustment.start).getMonth() + 1));
  }

  return (
    <>
      <FairUseTitle>
        {t('FairUsage.Seasonality', { ns: 'molecules' })}
      </FairUseTitle>

      <ChartWrapper>
        <SeasonalityChart
          datasets={dataSets}
          labels={getChartLabels()}
          startDate={start}
        />
      </ChartWrapper>

      <SeasonalityTable
        seasonalityAdjustments={fields}
        energyMeterType={energyMeterType}
        disabled={!editMode}
        remainingPercentage={remainingPercentage}
        total={total}
      />
    </>
  )
}

export default Seasonality

const FairUseTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  padding: 15px 0;
  margin: 0;
`;

const ChartWrapper = styled.div`
  margin: 15px 0;
  height: 300px;
  width: auto;
  user-select: none;
`;