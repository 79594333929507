import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@components/core/Loading';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const FloorplanLoading = () => {
  const { t } = useTranslation();

  return (
    <FlexColumn>
      <Icon
        icon={duotone('grid-horizontal')}
        beatFade
      />

      <Label>
        <Loading
          inline
          size='13px'
          style={{ marginRight: 8 }}
        />
        {t('LoadingFloorplan', { ns: 'status' })}
      </Label>
    </FlexColumn>
  );
};

export default FloorplanLoading;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 45px 32px 50px 32px;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 58px;
  height: 58px;
  color: ${p => p.theme.palette.orange};
  animation-duration: 1.8s;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${p => p.theme.palette.text.medium};
`;