import { MetricType } from '@api/enums/MetricType';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useTranslation } from 'react-i18next';
import PerformanceCard from './PerformanceCard';

type PropTypes = {
  targetTemp: number;
}

const PerformanceCardSiteTarget = ({ targetTemp }: PropTypes) => {
  const { t } = useTranslation();
  const { toLocale, getUnit } = useLocalisation();

  const unit = getUnit(MetricType.Temperature);

  return (
    <PerformanceCard
      label={t('ClimateControl.PerformanceCards.Target', { ns: 'molecules' })}
      unit={unit}
      value={toLocale(MetricType.Temperature, targetTemp, { round: 1 })}
    />
  )
}

export default PerformanceCardSiteTarget;