import { useSpaceContext } from '@pages/site/buildings/floor/space/SpaceProvider';
import { useCallback, useMemo } from 'react';
import NoteList from '../../components/note-list/NoteList';
import { useApi } from '@hooks/useApi';
import { NoteCreateCommand } from '@api/queries/notes/NoteCreateCommand';
import { NoteDiscriminator } from '@api/enums/NoteDiscriminator';
import { SortOrder } from '@src/types/SortOrder';

type PropTypes = {
  sortOrder: SortOrder;
}

const SpacePage_Notes = ({ sortOrder }: PropTypes) => {
  const { execute } = useApi();
  const { space, spaceNotes, deviceNotes, refreshSpaceNotes, refreshDeviceNotes } = useSpaceContext();
  const notes = useMemo(() => [...spaceNotes, ...deviceNotes], [spaceNotes, deviceNotes]);

  const handleCreate = useCallback(async (content: string) => {
    await execute({ query: new NoteCreateCommand(NoteDiscriminator.Space, space.id, content) });
    refreshSpaceNotes();
  }, [space, execute, refreshSpaceNotes]);

  const handleRefresh = useCallback(async () => {
    refreshSpaceNotes();
    refreshDeviceNotes();
  }, [refreshDeviceNotes, refreshSpaceNotes]);

  return (
    <NoteList
      notes={notes}
      onCreate={handleCreate}
      refreshNotes={handleRefresh}
      sortOrder={sortOrder}
    />
  );
};

export default SpacePage_Notes;