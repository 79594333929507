import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@api/enums/MetricType';
import styled, { useTheme } from 'styled-components';
import { faHeat, faBolt } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type MetricTypePillProps = {
  metricType: MetricType;
};

const MetricTypePill = ({metricType}: MetricTypePillProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getIcon = () => {
    if (metricType === MetricType.Temperature) {
      return faHeat;
    }

    return faBolt;
  }
  return (
    <MetricTypePillWrapper>
      <Icon>
        <FontAwesomeIcon icon={getIcon()} color={theme.text.contrast} style={{fontSize: 12}} />
      </Icon>
      <Text>{t(metricType, {ns: 'enums'})}</Text>
    </MetricTypePillWrapper>
  )
} 

export default MetricTypePill;

const MetricTypePillWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: ${p => p.theme.complimentary.purple.dark};
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  overflow: hidden;
`;

const Icon = styled.div`
  padding: 4px 8px;
  background-color: ${p => p.theme.complimentary.purple.main};
`;

const Text = styled.span`
  background-color: ${p => p.theme.complimentary.purple.dark};
  font-size: 12px;
  color: ${p => p.theme.text.contrast};
  padding: 4px 8px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  min-height: 100%;
`;