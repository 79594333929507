import { Tenant } from '@api/models/Tenant';
import { DeleteBlobQuery } from '@api/queries/common/DeleteBlobQuery';

export default class TenantLogoDeleteCommand extends DeleteBlobQuery<Tenant> {
  tenantId: string;

  constructor(blobName: string, tenantId: string) {
    super(blobName);
    this.tenantId = tenantId;
  }

  public targetUrl(): string {
    return '/tenants/logo';
  }
}
