import { callDelete, IAuthConfig } from '@utils/ConnectApiUtils';
import { BaseQuery } from './BaseQuery';

export abstract class DeleteBlobQuery<TDto> extends BaseQuery<TDto> {
  public blobName: string;

  constructor(blobName: string) {
    super();
    this.blobName = blobName;
  }

  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.apiBaseUrl);
    const response = await callDelete(apiUrl, this, authConfig, controller);

    return response.data;
  }
}
