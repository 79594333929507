import SiteDailyTemperaturesGetQuery from '@api/queries/metrics/SiteDailyTemperaturesGetQuery';
import { dateToUtcDateTime } from '@utils/DateUtils';
import { useSiteContext } from '@pages/site/SiteProvider';
import TemperatureChart from './chart/TemperatureChart';
import { Card } from '@components/core/Card';
import { Title } from '@components/core/Title';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { Tooltip } from '@components/core/Tooltip';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from '@hooks/useApi';
import { SiteTemperatureChartDto } from '@api/models/SiteTemperatureChartDto';
import LoadingWidget from '@components/core/LoadingWidget';
import TemperatureChartExport from './interactions/TemperatureChartExport';
import TimeRangeSelect, { DefaultTimeRanges, TimeRange, TimeRangeType } from '@components/time-range-select/TimeRangeSelect';

const SiteTemperatureChartWidget = () => {
  const { t } = useTranslation();
  const { execute, loading } = useApi();
  const { site } = useSiteContext();
  const [showPreviousYear, setShowPreviousYear] = useState(false);
  const [timeRangeType, setTimeRangeType] = useState(TimeRangeType.LastSevenDays);
  const [timeRange, setTimeRange] = useState<TimeRange>(DefaultTimeRanges[TimeRangeType.LastSevenDays]);
  const [data, setData] = useState<SiteTemperatureChartDto>();

  const fetchData = useCallback(async () => {
    const data = await execute({
      query: new SiteDailyTemperaturesGetQuery(site.id, dateToUtcDateTime(timeRange.from).toISOString(), dateToUtcDateTime(timeRange.to).toISOString())
    });

    if (data) {
      setData(data);
    }
  }, [site, timeRange, execute]);

  const handleChange = useCallback((timeRangeType: TimeRangeType, range: TimeRange, showPreviousYear: boolean) => {
    setTimeRangeType(timeRangeType);
    setTimeRange(range);
    setShowPreviousYear(showPreviousYear);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Card noPadding style={{ padding: 32 }}>
      <FlexColumn>
        <FlexRow>
          <Title text={t('Temperature', { ns: 'common' })} />

          <InfoIconWrapper>
            <Tooltip
              placement={TooltipPlacement.Bottom}
              fixedPosition={{ x: 14, y: 20 }}
              content={
                <TooltipContent>
                  {t('SiteOverview.TemperatureGraph.Tooltip', { ns: 'molecules' })}
                </TooltipContent>
              }
            >
              <InfoIcon
                icon={regular('circle-info')}
              />
            </Tooltip>
          </InfoIconWrapper>

          {data &&
            <TemperatureChartExport
              data={showPreviousYear ? [...data.previousYear, ...data.currentYear] : data.currentYear}
              timeRangeType={timeRangeType}
              timeRange={timeRange}
            />
          }
        </FlexRow>

        <TimeRangeSelect
          timeRangeType={timeRangeType}
          range={timeRange}
          showPreviousYear={showPreviousYear}
          onChange={handleChange}
        />

        {loading &&
          <LoadingWidget
            icon={solid('line-chart')}
            label={t('LoadingData', { ns: 'status' })}
            styles={{ height: 320, justifyContent: 'center' }}
            iconStyles={{ width: 30 }}
          />
        }

        {!loading && data &&
          <TemperatureChart
            data={data}
            timeRangeType={timeRangeType}
            range={timeRange}
            showPreviousYear={showPreviousYear}
          />
        }
      </FlexColumn>
    </Card>
  );
};

export default SiteTemperatureChartWidget;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const InfoIconWrapper = styled.div`
  margin: -2px 5px 0 3px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const TooltipContent = styled.div`
  width: 275px;
  padding: 10px;
  font-size: 12px;
`;