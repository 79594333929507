import { Route, Routes } from 'react-router';
import { Route as RouteType } from '@src/types/Route';
import { ProtectedRoute } from '@src/navigation/ProtectedRoute/ProtectedRoute';
import TabBar from '@components/core/TabBar';
import styled from 'styled-components';

type PropTypes = {
  routes?: RouteType[];
}

const SiteManagement = ({ routes }: PropTypes) => {

  return (
    <div>
      <TabBarWrapper>
        <TabBar routes={routes} />
      </TabBarWrapper>

      <Routes>
        {routes?.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute
                route={route}
              />
            }
          />
        ))}
      </Routes>
    </div>
  );
};

export default SiteManagement;

const TabBarWrapper = styled.div`
  padding: 0 40px;
  background-color: ${p => p.theme.background.container};
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;