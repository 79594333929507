import { User } from '@api/models/User';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class UserProfileUpdateCommand extends PatchQuery<User> {
  fullName: string;
  displayName?: string;
  phoneNumber: string;

  constructor(fullName: string, phoneNumber: string, displayName?: string) {
    super();
    this.fullName = fullName;
    this.displayName = displayName;
    this.phoneNumber = phoneNumber;
  }

  public targetUrl(): string {
    return '/userprofile';
  }
}
