import DeviceStatusWidget from './devices/DeviceStatusWidget';
import { Site } from '@api/models/Site';
import styled from 'styled-components';
import GenerationMixWidget from './generation-mix/GenerationMixWidget';
import OccupancyWidget from './occupancy/OccupancyWidget';
import TemperatureWidget from './temperature/TemperatureWidget';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { FeatureFlag } from '@api/enums/FeatureFlag';
import UnoccupiedSpacesWidget from './unoccupied-spaces/UnoccupiedSpacesWidget';
import Carousel from './Carousel';

type LiveUpdatesProps = {
  site: Site;
}

const LiveUpdates = ({ site }: LiveUpdatesProps) => {
  const { hasFeatureFlag } = useTenantContext();

  return (
    <Carousel>
      <FlexRow>
        <DeviceStatusWidget site={site} />

        <OccupancyWidget />

        <TemperatureWidget />

        {hasFeatureFlag(FeatureFlag.UnoccupiedSpacesWidget) &&
          <UnoccupiedSpacesWidget />
        }

        <GenerationMixWidget />
      </FlexRow>
    </Carousel>
  );
};

export default LiveUpdates;

const FlexRow = styled.div`
  display: flex;
  gap: 20px;
`;