import { SpaceSearchDto } from '@api/models/SpaceSearchDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SpacesGetBySiteAndNameQuery extends GetQuery<SpaceSearchDto[]> {
  siteId: number;
  searchString: string

  constructor(siteId: number, searchString: string) {
    super();
    this.siteId = siteId;
    this.searchString = searchString;
  }

  public targetUrl(): string {
    return `/spaces/SearchBySiteAndName?siteId=${this.siteId}&searchString=${this.searchString}`;
  }
}
