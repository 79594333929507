import { NoteDiscriminator } from '@api/enums/NoteDiscriminator';
import { NoteSource } from '@api/enums/NoteSource';
import { NoteVisibility } from '@api/enums/NoteVisibility';
import { NoteDto } from '@api/models/NoteDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export class NoteCreateCommand extends PostQuery<NoteDto> {
  discriminator: NoteDiscriminator;
  discriminatorId: number;
  content: string;
  source: NoteSource;
  visibility: NoteVisibility;

  constructor(discriminator: NoteDiscriminator, discriminatorId: number, content: string, source: NoteSource = NoteSource.Web, visibility: NoteVisibility = NoteVisibility.Public) {
    super();
    this.discriminator = discriminator;
    this.discriminatorId = discriminatorId;
    this.content = content;
    this.source = source;
    this.visibility = visibility;
  }

  public targetUrl(): string {
    return '/notes';
  }
}