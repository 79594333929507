import ExportCsvDto from '@api/models/ExportCsvDto';
import { MetricType } from '@api/enums/MetricType';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class CsvDevicesWithMetricsGetByBuildingAndMetricTypesQuery extends PostQuery<ExportCsvDto> {
  buildingId: number;
  metricTypes: MetricType[];
  fromDateTime: string;
  toDateTime: string;

  constructor(buildingId: number, metricTypes: MetricType[], fromDateTime: string, toDateTime: string) {
    super();
    this.buildingId = buildingId;
    this.metricTypes = metricTypes;
    this.fromDateTime = fromDateTime;
    this.toDateTime = toDateTime;
  }

  public targetUrl(): string {
    return '/exports/CsvDevicesWithMetrics';
  }
}
