import { RefObject, useEffect, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import CascadeLevel from './CascadeLevel';
import { IInternalNodeGroup } from '../CascaderMultiSelect.types';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { Modal } from '@components/core/Modal';
import { Button } from '@components/core/Button';

interface ICascaderModal<TValue> {
  modalRef: RefObject<HTMLDivElement>;
  open: boolean;
  group?: IInternalNodeGroup<TValue>;
  onChange: (group: IInternalNodeGroup<TValue>) => void;
  onCancel: () => void;
  onApply: (group: IInternalNodeGroup<TValue>) => void;
  hideChildGroupLabel?: boolean;
  confirmButton: string;
  cancelButton: string;
}

const CascaderMultiSelectModal = <TValue,>({ modalRef, open, group, onChange, onCancel, onApply, hideChildGroupLabel, confirmButton, cancelButton }: ICascaderModal<TValue>) => {
  const theme = useTheme();
  const [activeGroupId, setActiveGroupId] = useState<string>();
  const onMobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    setActiveGroupId(group?.id);
  }, [open, group])

  if (!open) {
    return null;
  }

  return (
    <Modal
      ref={modalRef}
      isOpen={open}
      width='max-content'
      plainModal
      modalStyles={{ backgroundColor: 'rgba(0,0,0,0.15)' }}
      dialogStyles={{ boxShadow: `0px 2px 12px 1px ${theme.palette.shadows.strong}` }}
    >
      <FlexColumn>
        <FlexRow $onMobile={onMobile}>
          {group &&
            <CascadeLevel
              group={group}
              depth={0}
              onChange={onChange}
              hideChildGroupLabel={hideChildGroupLabel}
              onMobile={onMobile}
              activeGroupId={activeGroupId}
              setActiveGroupId={setActiveGroupId}
            />
          }
        </FlexRow>

        <Footer $onMobile={onMobile}>
          <Button
            small
            tertiary
            label={cancelButton}
            onClick={onCancel}
          />
          <Button
            small
            label={confirmButton}
            onClick={() => group && onApply(group)}
          />
        </Footer>
      </FlexColumn>
    </Modal>
  );
};

export default CascaderMultiSelectModal;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
`;

const FlexRow = styled.div<{ $onMobile: boolean }>`
  position: relative;
  display: flex;
  overflow: auto;

  ${p => p.$onMobile && css`
    position: relative;
    overflow: unset;
    height: 300px;
  `}
`;

const Footer = styled.div<{ $onMobile: boolean }>`
  display: flex;
  justify-content: end;
  gap: 10px;

  padding: 10px 15px;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};

  ${p => p.$onMobile && css`
    position: relative;
    overflow: unset;
  `}
`;