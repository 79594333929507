import styled, { useTheme } from 'styled-components';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import PeopleCountingAssignDeviceRow from './PeopleCountingAssignDeviceRow';
import { cloneDeep, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import PeopleCountingPhysicalDevice from '@api/models/PeopleCountingPhysicalDevice';
import { DevicesGetByDeviceModelsQuery } from '@api/queries/devices/DevicesGetByDeviceModelsQuery';
import { DeviceType } from '@api/enums/DeviceType';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useApiState } from '@hooks/useApiState';
import { Button } from '@components/core/Button';

type PropTypes = {
  physicalDevices: PeopleCountingPhysicalDevice[],
  onSave: (formValues: PhysicalDeviceFormValue[]) => void,
  savingInProgress: boolean
};

export type PhysicalDeviceFormValue = {
  id?: number;
  physicalDeviceName?: string;
  physicalDeviceId?: number;
  direction?: string;
  disabled?: boolean;
};

export type PhysicalDevicesFormValues = {
  physicalDevicesFieldArray: PhysicalDeviceFormValue[];
};

export type OptionType = {
  value: string;
  label: string;
  isDisabled: boolean;
};

const PeopleCountingAssignDevicesList = ({ physicalDevices, onSave }: PropTypes) => {
  const { t } = useTranslation(['settingsPeopleCounting']);
  const theme = useTheme();
  const { getDeviceConfigsFiltered } = useDeviceConfigContext();

  const { register, handleSubmit, control, formState, reset, setValue } = useForm<PhysicalDevicesFormValues>({
    defaultValues: {
      physicalDevicesFieldArray: physicalDevices.map((x) => ({
        id: x.physicalDevice.id,
        physicalDeviceName: x.physicalDevice.friendlyName,
        physicalDeviceId: x.physicalDevice.id,
        direction: x.direction,
        disabled: true,
      })),
    },
  });

  const { transform: devicesOptionsTransformed } = useApiState({
    query: new DevicesGetByDeviceModelsQuery(getDeviceConfigsFiltered(x => x.deviceType === DeviceType.PeopleCounting).map(x => x.deviceModel)),
    initialState: [],
    initialTransformState: [],
    transformFunc: (devices) =>
      devices.map((x) => ({
        label: x.friendlyName,
        value: x.id.toString(),
        isDisabled: false,
      })) as OptionType[],
    errorMessage: t('AssignDeviceList.UnableToRetrieveDevices', { ns: 'settingsPeopleCounting' })
  }, []);

  const { isDirty, errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: 'physicalDevicesFieldArray' });
  const formValues = useWatch({ control, name: 'physicalDevicesFieldArray' });
  const [deviceOptions, setDeviceOptions] = useState<OptionType[]>([]);

  const resetValues = () => {
    reset();
    resetOptions();
  };

  const resetOptions = useCallback(() => {

    const devices = fields;
    const options = cloneDeep(devicesOptionsTransformed);

    options.forEach(option => {
      if (devices.some(device => device.physicalDeviceId === parseInt(option.value))) {
        option.isDisabled = true;
      }
    });

    setDeviceOptions(options);
  }, [devicesOptionsTransformed, fields]);

  useEffect(() => {
    resetOptions();
  }, [formValues, devicesOptionsTransformed, resetOptions]);

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <PeopleCountingAssignDeviceRow
            key={index}
            field={field}
            index={index}
            formState={formState}
            register={register}
            remove={remove}
            control={control}
            deviceOptions={deviceOptions}
            setValue={setValue}
          />
        );
      })}

      <Row>
        <Button
          label={t('AssignDeviceList.AddDevice', { ns: 'settingsPeopleCounting' })}
          onClick={() => append({ id: undefined })}
        />

        <div style={{ marginLeft: 'auto' }} />

        <Button
          tertiary
          label={t('AssignDeviceList.Discard', { ns: 'settingsPeopleCounting' })}
          onClick={resetValues}
          disabled={!isDirty}
          color={theme.palette.red}
        />
        <Button
          label={t('AssignDeviceList.Save', { ns: 'settingsPeopleCounting' })}
          onClick={handleSubmit(() => onSave(formValues))}
          disabled={!isDirty || !isEmpty(errors)}
        />
      </Row>
    </div>
  );
};

export default PeopleCountingAssignDevicesList;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid ${(p) => p.theme.palette.borders.weak};
`;
