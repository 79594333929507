import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReportSectionHeading from '../ReportSectionHeading';
import OccupancyHistogramWidget from './OccupancyHistogramWidget';
import OccupancyWidget from './OccupancyWidget';
import EsgReportDto from '@api/models/EsgReportDto';
import { TFunction } from 'i18next';
import { MAX_NUMBER_OF_OCCUPANCY_WIDGETS_ON_PAGE } from '../../../../../hooks/useEsgReportPageHeaders';

/**
 * Returns an array of grouped Occupancy widgets, adding spacing for new pages in print mode. 
 */
const renderWidgets = (report: EsgReportDto, inPrintMode: boolean, pageHeaders: JSX.Element[], histogramPageHeaders: JSX.Element[], t: TFunction): JSX.Element[] => {
  const elements: JSX.Element[] = [];

  const spaces = report.occupancyPeopleCountingMeasurements;
  const numWidgetsOnPage = inPrintMode ? MAX_NUMBER_OF_OCCUPANCY_WIDGETS_ON_PAGE : spaces.length;
  const numPages = spaces.length === 0 ? 0 : Math.ceil(spaces.length / numWidgetsOnPage);

  for (let i = 0; i < numPages; i++) {
    // Add a page header
    elements.push(pageHeaders[i]);

    // Add section heading on first page of this widget group
    if (i === 0) {
      elements.push(<ReportSectionHeading label={t('ESG.Occupancy', { ns: 'molecules' })} key='section-heading' />);
    }

    const startIndex = i * numWidgetsOnPage;

    elements.push(
      <FlexRow key={`page-${i}`}>
        {report.occupancyPeopleCountingMeasurements.slice(startIndex, startIndex + numWidgetsOnPage).map((space, j) => (
          <OccupancyWidget
            key={`${startIndex}-${j}`}
            data={space}
            inPrintMode={inPrintMode}
          />
        ))}
      </FlexRow>
    );
  }

  // Return space-occupancy widgets if there are no histogram measurements
  if (report.occupancyHistogramMeasurements.length === 0) {
    return elements;
  }

  // Only containts a header if the histogram does not fit on previous page with space-occupancy widgets
  elements.push(histogramPageHeaders[0]);

  // Add section heading if there were no space-occupancy widgets
  if (spaces.length === 0) {
    elements.push(<ReportSectionHeading label={t('ESG.Occupancy', { ns: 'molecules' })} key='section-heading' />);
  }

  const histogramMarginTop = !inPrintMode && spaces.length > 0 ? '35px' : '0';

  elements.push(
    <div key="occupancy-histogram" style={{ marginTop: histogramMarginTop }}>
      <OccupancyHistogramWidget
        data={report.occupancyHistogramMeasurements}
        inPrintMode={inPrintMode}
      />
    </div>
  )

  return elements;
}

interface IOccupancyWidgets {
  report: EsgReportDto;
  inPrintMode: boolean;
  pageHeaders: JSX.Element[];
  histogramPageHeaders: JSX.Element[];
}

const OccupancyWidgets = ({ report, inPrintMode, pageHeaders, histogramPageHeaders }: IOccupancyWidgets) => {
  const { t } = useTranslation();

  const showOccupancyWidgets = report.occupancyPeopleCountingMeasurements.length > 0;
  const showHistogramWidget = report.occupancyHistogramMeasurements.length > 0;

  if (!showOccupancyWidgets && !showHistogramWidget) {
    return null;
  }

  return (
    <div>
      {renderWidgets(report, inPrintMode, pageHeaders, histogramPageHeaders, t)}
    </div>
  );
};

export default OccupancyWidgets;

const FlexRow = styled.div`
  display: flex;
  gap: 35px;
  flex-wrap: wrap;

  @media print {
    display: block;
  }
`;
