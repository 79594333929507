import { SpaceType } from '@api/enums/SpaceType';
import { SpaceTypeSelectGroups } from './SpaceTypeSelect';

const SpaceTypeOptions: SpaceTypeSelectGroups = {
  defaultValue: SpaceType.Studio,
  groups: [
    {
      label: 'Apartment',
      options: [
        SpaceType.Studio,
        SpaceType.OneBed,
        SpaceType.TwoBed,
        SpaceType.ThreeBed,
        SpaceType.FourBed,
        SpaceType.DuplexTwoBed,
        SpaceType.DuplexThreeBed,
        SpaceType.DuplexFourBed,
        SpaceType.SharerTwoBed,
        SpaceType.SharerThreeBed,
        SpaceType.SharerFourBed,
        SpaceType.SharerFiveBed,
        SpaceType.SharerSixBed,
        SpaceType.SharerSevenBed,
        SpaceType.SharerEightBed,
        SpaceType.LKD,
        SpaceType.Bedroom,
        SpaceType.Penthouse
      ]
    },
    {
      label: 'Office',
      options: [
        SpaceType.Boardroom,
        SpaceType.MeetingRoom,
        SpaceType.OpenPlan,
        SpaceType.PrivateOffice
      ]
    },
    {
      label: 'Common',
      options: [
        SpaceType.Bathroom,
        SpaceType.BinStore,
        SpaceType.Cinema,
        SpaceType.Cupboard,
        SpaceType.CycleStore,
        SpaceType.Elevator,
        SpaceType.GamesRoom,
        SpaceType.Gym,
        SpaceType.Kitchen,
        SpaceType.LaundryRoom,
        SpaceType.Library,
        SpaceType.Lounge,
        SpaceType.MailRoom,
        SpaceType.Other,
        SpaceType.PlantRoom,
        SpaceType.PrivateDining,
        SpaceType.Reception,
        SpaceType.Study,
        SpaceType.Toilet,
        SpaceType.TvRoom
      ]
    }
  ]
};

export default Object.freeze(SpaceTypeOptions);