import { OperatorPrizeWinnerDto } from '@api/models/OperatorPrizeWinnerDto';
import { OperatorDeleteQuery } from '@api/queries/resident-app/common/OperatorDeleteQuery';

export class PrizeWinnersResidentAppDeleteCommand extends OperatorDeleteQuery<OperatorPrizeWinnerDto> {
  prizeWinnerId: number;

  constructor(prizeWinnerId: number) {
    super();
    this.prizeWinnerId = prizeWinnerId;
  }

  public targetUrl(): string {
    return '/PrizeWinners';
  }
}
