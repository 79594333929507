import styled from 'styled-components';
import AnnualAllowance from './components/annual-allowance/AnnualAllowance';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Title } from '@components/core/Title';
import { useTranslation } from 'react-i18next';
import { Card } from '@components/core/Card';
import { SiteFairUsePolicyGetByConfigurationIdQuery } from '@api/queries/fair-use/SitePolicy/SiteFairUsePolicyGetByConfigurationIdQuery';
import { useApiState } from '@hooks/useApiState';
import { useSiteContext } from '@pages/site/SiteProvider';
import { SiteFairUsePolicy } from '@api/models/SiteFairUsePolicy';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { FairUseState } from '@api/enums/FairUseState';
import { SiteFairUseGetEnergyConsumptionQuery } from '@api/queries/fair-use/EnergyConsumption/SiteFairUseGetEnergyConsumptionQuery';
import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { useApi } from '@hooks/useApi';
import { SiteFairUseEnergyConsumption } from '@api/models/SiteFairUseEnergyConsumption';
import { SpaceFairUsePoliciesGetByPolicyIdQuery } from '@api/queries/fair-use/Exceptions/SpaceFairUsePoliciesGetByPolicyIdQuery';
import { SpaceFairUsePolicy } from '@api/models/SpaceFairUsePolicy';
import LoadingWidget from '@components/core/LoadingWidget';
import NoData from '@components/core/NoData';
import FairUsePreview from './FairUsePreview';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { SiteFairUseConfigurationGetBySiteIdQuery } from '@api/queries/fair-use/Configuration/SiteFairUseConfigurationGetBySiteIdQuery';

const FairUse = () => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const { execute, loading } = useApi();
  const { trackAction } = useAnalytics();
  const [selectedPolicy, setSelectedPolicy] = useState<SiteFairUsePolicy>();
  const selectedEnergyMeterType = useMemo(() => EnergyMeterType.Electricity, []);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [energyConsumption, setEnergyConsumption] = useState<SiteFairUseEnergyConsumption>();
  const [exceptions, setExceptions] = useState<SpaceFairUsePolicy[]>();

  const { data: siteFairUseConfigurations, loading: loadingFairUse } = useApiState({
    query: site && new SiteFairUseConfigurationGetBySiteIdQuery(site.id)
  }, [site]);

  const { data: siteFairUsePolicies, execute: refresh, loading: loadingPolicies } = useApiState({
    query: siteFairUseConfigurations && new SiteFairUsePolicyGetByConfigurationIdQuery(siteFairUseConfigurations.id),
    errorMessage: t('FairUsage.FairUseLoadFailed', { ns: 'molecules' }),
    initialState: []
  }, [siteFairUseConfigurations]);

  const getExceptions = useCallback(async () => {
    if (selectedPolicy) {
      const response = await execute({
        query: new SpaceFairUsePoliciesGetByPolicyIdQuery(selectedPolicy?.id),
        errorMessage: t('FairUsage.FairUseLoadFailed', { ns: 'molecules' })
      });
      setExceptions(response);
    }
  }, [execute, selectedPolicy, t])

  const getEnergyConsumption = useCallback(async () => {
    if (selectedPolicy) {
      const response = await execute({
        query: new SiteFairUseGetEnergyConsumptionQuery(selectedPolicy?.id, site.id, selectedEnergyMeterType),
        errorMessage: t('FairUsage.FairUseLoadFailed', { ns: 'molecules' })
      });
      setEnergyConsumption(response);
    }
  }, [execute, selectedEnergyMeterType, selectedPolicy, site.id, t])

  useEffect(() => {
    if (siteFairUsePolicies.length > 0) {
      setSelectedPolicy(siteFairUsePolicies[0])
      setEditMode(siteFairUsePolicies[0].state === FairUseState.Draft)
      getExceptions()
      if (siteFairUsePolicies[0].state === FairUseState.Active) {
        getEnergyConsumption()
      }
    }
  }, [siteFairUsePolicies, getEnergyConsumption, getExceptions]);

  const handleEditModeChange = (): void => {
    setEditMode(!editMode)
    if (editMode) {
      getEnergyConsumption();
    } else {
      trackAction('edit_allowance', 'fair_use');
    }
  }

  const isLoading = (): boolean => {
    if (!editMode) {
      return loading || !energyConsumption
    }
    return loading
  }

  if (loadingFairUse || loadingPolicies) {
    return (
      <LoadingWidget
        label={t('Loading', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  if (!siteFairUseConfigurations?.enabled) {
    return <FairUsePreview />;
  }

  if (siteFairUsePolicies.length === 0 || !selectedPolicy) {
    return (
      <NoData
        label={t('NoPoliciesFound', { ns: 'status' })}
        styles={{ margin: 80 }}
      />
    );
  }

  return (
    <PaddedContainer>
      <Container>
        <Title
          size='lg'
          text={t('FairUsage.FairUse', { ns: 'molecules' })}
          wrapperStyle={{ paddingBottom: '20px' }}
          betaLabel={true}
        />

        {isLoading() &&
          <LoadingWidget
            label={t('FairUsage.Loading', { ns: 'molecules' })}
            styles={{ marginTop: 80 }}
          />
        }

        {!isLoading() &&
          <FlexRow>
            <Card>
              <AnnualAllowance
                siteFairUsePolicy={selectedPolicy}
                editMode={editMode}
                energyMeterType={selectedEnergyMeterType}
                energyConsumption={energyConsumption}
                exceptions={exceptions}
                handleEditModeChange={handleEditModeChange}
                refresh={refresh} />
            </Card>
          </FlexRow>
        }
      </Container>
    </PaddedContainer >
  )
}

export default FairUse

const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  flex-flow: column nowrap;

  @media (min-width: 1200px) {
    flex-flow: row nowrap;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1170px;
`;