import styled from 'styled-components';
import FilterConfiguration from './FilterConfiguration/FilterConfiguration';
import DetailsConfiguration from './DetailsConfiguration/DetailsConfiguration';
import TriggerConfiguration from './TriggerConfiguration/TriggerConfiguration';
import ScheduleConfiguration from './ScheduleConfiguration/ScheduleConfiguration';
import ActionConfiguration from './ActionConfiguration/ActionConfiguration';
import { AlertRule } from '@api/models/AlertRule';

interface IAlertRuleConfiguration {
  rule: AlertRule;
  onChange: (rule: AlertRule) => void;
}

const AlertRuleConfiguration = ({ rule, onChange }: IAlertRuleConfiguration) => {

  return (
    <Wrapper>
      <DetailsConfiguration
        rule={rule}
        onChange={onChange}
      />

      <Spacer />
      <FilterConfiguration
        enabled={true}
        filters={rule.configuration.filters}
        onChange={filters => onChange({ ...rule, configuration: { ...rule.configuration, filters: filters } })}
      />

      <Spacer />
      <TriggerConfiguration
        enabled={true}
        triggers={rule.configuration.triggers}
        onChange={triggers => onChange({ ...rule, configuration: { ...rule.configuration, triggers: triggers } })}
        delay={rule.delay}
        onDelayChange={delay => onChange({ ...rule, delay: delay })}
      />

      <Spacer />
      <ScheduleConfiguration
        enabled={rule.configuration.triggers.length > 0}
        schedule={rule.configuration.schedule}
        onChange={schedule => onChange({ ...rule, configuration: { ...rule.configuration, schedule: schedule } })}
      />

      <Spacer />
      <ActionConfiguration
        enabled={rule.configuration.triggers.length > 0}
        actions={rule.configuration.actions}
        onChange={actions => onChange({ ...rule, configuration: { ...rule.configuration, actions: actions } })}
      />
    </Wrapper>
  );
};

export default AlertRuleConfiguration;

const Wrapper = styled.div`
  flex-grow: 1;
  margin-left: 40px;
`;

const Spacer = () => (
  <SpacerWrapper>
    <Line />
  </SpacerWrapper>
);

const SpacerWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  width: 2px;
  background-color: ${p => p.theme.palette.borders.medium};
  height: 100%;
`;

export const AlertRuleLabel = styled.div`
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  padding-left: 4px;
`;

export const AlertRuleFlexRow = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
`;

export const AlertRuleFlexChild = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 160px;
  max-width: unset;
  flex-basis: 32%;

  @media (min-width: 600px) {
    max-width: 33%;
  }
`;

export const HelperMessage = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
  margin: 8px 0 0 6px;
`;