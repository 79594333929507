import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import { OccupancyStatus } from '@api/enums/OccupancyStatus';
import { CheckboxSelect } from '@components/checkbox-select/CheckboxSelect';
import { ICheckboxSelectGroup } from '@components/checkbox-select/CheckboxSelect.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ILettingStatusSelect {
  config: EsgExplorerConfiguration;
  onChange: (config: EsgExplorerConfiguration) => void;
}

const LettingStatusSelect = ({ config, onChange }: ILettingStatusSelect) => {
  const { t } = useTranslation(['molecules']);
  const [lettingStatusOptions, setLettingStatusOptions] = useState<ICheckboxSelectGroup<OccupancyStatus>[]>([]);

  useEffect(() => {
    setLettingStatusOptions([{
      options: Object.values(OccupancyStatus).map(status => ({
        label: t(status, { ns: 'enums' }),
        value: status,
        selected: config.lettingStatuses.includes(status)
      }))
    }]);
  }, [config, t]);

  return (
    <CheckboxSelect
      options={lettingStatusOptions}
      onChange={(values) =>
        onChange({
          ...config,
          lettingStatuses: values
        })
      }
      placeholder={t('ESG.LettingStatus', { ns: 'molecules' })}
    />
  );
};

export default LettingStatusSelect;