import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@components/core/Button';
import { Select } from '@components/select/Select';
import { useEffect, useMemo, useState } from 'react';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { localisations } from '@i18n//Localisations';
import { LocalisationType } from '@i18n//localisation';

export type Option = {
  label: string;
  value: LocalisationType;
}

const LocalisationPreferencesContent = () => {
  const { t } = useTranslation('molecules');
  const { updateLocalisation, userPreference } = useLocalisation();
  const [selected, setSelected] = useState<Option>();

  const options: Option[] = useMemo(() => {
    return localisations.map(x => ({ label: x.localeDisplayString, value: x }));
  }, [])

  const handleUpdatePreferences = () => {
    if (selected !== undefined) {
      updateLocalisation(selected.value)
    }
  }

  useEffect(() => {
    if (userPreference?.locale) {
      setSelected(options.find(x => x.value.locale === userPreference.locale))
    }
  }, [options, userPreference?.locale])

  return (
    <LocalisationPreferences>
      <Title>{t('Localisations.Title')}</Title>

      <TextBody>
        {t('Localisations.Disclaimer')}
      </TextBody>

      <Subtitle>{t('Localisations.Region')}</Subtitle>

      <FlexRow>
        <TextBodyWrapper>
          <TextBody>
            <Trans i18nKey="molecules:Localisations.RegionInfo"></Trans>
          </TextBody>

          <TextBodyItalic>
            <Trans i18nKey="molecules:Localisations.ExampleInfo"></Trans>
          </TextBodyItalic>
        </TextBodyWrapper>

        <Select
          value={options.find(x => x.label === selected?.label)}
          options={options}
          onChange={(option) => option && setSelected(option)}
          isSearchable={false}
          placeholder={t('Localisations.SelectLocalisation')}
        />
      </FlexRow>

      <Buttons>
        <Button
          label={t('ConsentPreferences.UpdatePreferences')}
          style={{ marginLeft: 'auto' }}
          onClick={handleUpdatePreferences}
        />
      </Buttons>
    </LocalisationPreferences>
  );
}

export default LocalisationPreferencesContent;

const LocalisationPreferences = styled.div`
  border-radius: 4px;
  padding: 25px 20px;
`;

const TextBody = styled.div`
  font-size: 14px;
  margin: 0;
  color: ${({ theme }) => theme.palette.forms.label.color};
`;

const TextBodyItalic = styled.div`
  font-style: italic;
  font-size: 14px;
  margin: 0;
  color: ${({ theme }) => theme.palette.text.weak};
`;

const TextBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 20px 0;
`;

const Subtitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 15px 0 15px;
`;

const Buttons = styled.div`
  margin-top: 25px;
  width: 100%;
`;