import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feature } from '@api/models/Feature';
import { Tenant } from '@api/models/Tenant';
import { TenantFeatureCreateCommand } from '@api/queries/features/TenantFeatureCreateCommand';
import { TenantFeatureDeleteCommand } from '@api/queries/features/TenantFeatureDeleteCommand';
import { Switch } from '@components/core/Switch';
import { Card } from '@components/core/Card';
import { useApi } from '@hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import TenantLogo from './TenantLogo';
import { useTranslation } from 'react-i18next';
import { WarningDialog } from '@components/core/WarningDialog';
import { useModal } from '@hooks/useModal';

export const featureIsEnabled = (tenant: Tenant, feature: Feature) => {
  return tenant.features
    ? tenant.features.map(x => x.featureFlag).includes(feature.featureFlag)
    : false;
};

interface IFeatureTenantListItem {
  feature: Feature;
  tenant: Tenant;
  refresh: () => void;
}

const FeatureTenantListItem = ({ feature, tenant, refresh }: IFeatureTenantListItem) => {
  const theme = useTheme();
  const { t } = useTranslation(['settingsFeatures']);
  const { execute, loading } = useApi();
  const [isChecked, setIsChecked] = useState<boolean>(featureIsEnabled(tenant, feature));
  const { isOpen: dialogIsOpen, toggle, ref } = useModal({});

  useEffect(() => {
    setIsChecked(featureIsEnabled(tenant, feature));
  }, [tenant, feature]);

  const addTenantFeature = useCallback(async () => {
    await execute({
      query: new TenantFeatureCreateCommand(tenant.tenantId, feature.id),
      successMessage: t('FeatureEnabled', { ns: 'settingsFeatures' })
    });
  }, [tenant, feature, execute, t]);

  const removeTenantFeature = useCallback(async () => {
    await execute({
      query: new TenantFeatureDeleteCommand(tenant.tenantId, feature.id),
      successMessage: t('FeatureDisabled', { ns: 'settingsFeatures' })
    });
  }, [tenant, feature, execute, t]);

  const handleToggle = useCallback(async (isChecked: boolean) => {
    setIsChecked(!isChecked);

    if (!isChecked && !featureIsEnabled(tenant, feature)) {
      await addTenantFeature();
    } else {
      await removeTenantFeature();
    }

    refresh();
  }, [tenant, feature, addTenantFeature, removeTenantFeature, refresh]);

  return (
    <Wrapper>
      <WarningDialog
        modalRef={ref}
        isOpen={dialogIsOpen}
        sectionOne={!isChecked ?
          t('EnableFeatureForTenant', { ns: 'settingsFeatures', feature: feature.name, tenant: tenant.name }) :
          t('DisableFeatureForTenant', { ns: 'settingsFeatures', feature: feature.name, tenant: tenant.name })
        }
        sectionTwo={t('VisibleToCustomers', { ns: 'settingsFeatures' })}
        confirmButtonColor={!isChecked ? theme.palette.primary : theme.palette.red}
        confirmButton={!isChecked ? t('Enable', { ns: 'settingsFeatures' }) : t('Disable', { ns: 'settingsFeatures' })}
        onCancel={toggle}
        onConfirm={() => {
          handleToggle(isChecked);
          toggle()
        }}
      />

      <Card noPadding noMargin>
        <FlexRow>
          <TenantLogo tenant={tenant} />

          <Name>
            {tenant.name}
          </Name>

          <div style={{ marginLeft: 'auto' }}>
            <Switch
              checked={isChecked}
              onChange={toggle}
              colorChecked='#43c3a1'
              labelChecked={<TickIcon icon={solid('check')} />}
              isLoading={loading}
            />
          </div>
        </FlexRow>
      </Card>
    </Wrapper>
  );
};

export default FeatureTenantListItem;

const Wrapper = styled.div`
  padding: 0 15px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  padding: 10px 0;
`;

const TickIcon = styled(FontAwesomeIcon)`
  margin-right: 2px;
`;