import { SalesOrderItem } from '@api/models/SalesOrderItem';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class SiteSalesOrderBulkCreateSalesOrderItemsCommand extends PostQuery<SalesOrderItem[]> {
  salesOrderId: number;
  salesOrderItems: SalesOrderItem[];

  constructor(salesOrderItems: SalesOrderItem[], salesOrderId: number) {
    super();
    this.salesOrderItems = salesOrderItems;
    this.salesOrderId = salesOrderId;
  }

  public targetUrl(): string {
    return '/salesorders/bulkcreatesalesorderitems';
  }
}