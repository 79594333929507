import { DeviceStatus } from '@api/enums/DeviceStatus';
import DeviceByStatusDto from '@api/models/DeviceByStatusDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export class DevicesGetBySiteIdAndStatusQuery extends GetQuery<DeviceByStatusDto[]>{
  siteId: number;
  deviceStatus: DeviceStatus;

  constructor(siteId: number, deviceStatus: DeviceStatus) {
    super();
    this.siteId = siteId;
    this.deviceStatus = deviceStatus;
  }

  public targetUrl(): string {
    return `/devices/DevicesGetLiveUpdateBySiteAndStatus/${this.siteId}/${this.deviceStatus}`;
  }
}