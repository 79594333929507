import { Building } from '@api/models/Building';
import { DeleteBlobQuery } from '@api/queries/common/DeleteBlobQuery';

export default class BuildingImageDeleteCommand extends DeleteBlobQuery<Building> {
  buildingId: number;

  constructor(blobName: string, buildingId: number) {
    super(blobName);
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return '/buildings/image';
  }
}
