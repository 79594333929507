import styled from 'styled-components'
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '@hooks/useApi';
import { Button } from '@components/core/Button';
import { TextArea } from '@components/Form';
import { NoteSource } from '@api/enums/NoteSource';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import dayjs from 'dayjs';
import { shouldUseModifedDate } from '@pages/site/buildings/floor/space/components/note-list/NoteList';
import { NoteUpdateCommand } from '@api/queries/notes/NoteUpdateCommand';
import { NoteDto, NoteTypes, NoteWithDeviceName } from '@api/models/NoteDto';
import NoteActions from './NoteActions';
import { TFunction } from 'i18next';
import { NoteDiscriminator } from '@api/enums/NoteDiscriminator';
import { useUserContext } from '@contexts/UserContext/UserContext';

const getDiscriminatorSpecificContent = (note: NoteDto, t: TFunction) => {
  switch (note.discriminator) {
    case NoteDiscriminator.Device: {
      const customNote = note as NoteWithDeviceName;

      return {
        link: `device/${note.discriminatorId}`,
        content: customNote.deviceFriendlyName && (
          <SmallText>
            {t('Device', { ns: 'common' })}: {customNote.deviceFriendlyName}
          </SmallText>
        )
      }
    }

    default:
      return {};
  }
};

interface PropTypes {
  note: NoteTypes;
  refreshNotes: () => void;
}

const Note = ({ note, refreshNotes }: PropTypes) => {
  const { t } = useTranslation();
  const { execute, loading } = useApi();
  const { isSuperAdmin } = useUserContext();
  const { localisation } = useLocalisation();
  const [content, setContent] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const userCanEdit = isSuperAdmin || note.createdByCurrentUser;

  const noteContent = useMemo(() => getDiscriminatorSpecificContent(note, t), [note, t]);

  useEffect(() => {
    setContent(note.content);
  }, [note]);

  const onCancel = () => {
    setContent(note.content);
    setEditMode(false);
  };

  const onSave = async () => {
    await execute({ query: new NoteUpdateCommand(note.id, content) });
    setEditMode(false);
    refreshNotes();
  };

  return (
    <Item key={note.id}>
      <Header>
        <Name>{note.createdByName ?? t('Unknown', { ns: 'common' })}</Name>
        <Separator />
        {note.source && note.source !== NoteSource.Unknown &&
          <Source>{t('via', { ns: 'common' })} {t(`NoteSource.${note.source}`, { ns: 'enums' })}</Source>
        }

        <NoteActions
          note={note}
          refreshNotes={refreshNotes}
          setEditMode={setEditMode}
          userCanEdit={userCanEdit}
        />
      </Header>

      {!editMode &&
        <Content>
          {content}
        </Content>
      }

      {editMode &&
        <StyledTextArea
          onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContent(e.currentTarget.value)}
          value={content}
        />
      }

      {editMode &&
        <FlexRow>
          <Button
            label={t('Save', { ns: 'common' })}
            onClick={onSave}
            disabled={loading || !content}
            small
          />
          <Button
            tertiary
            label={t('Cancel', { ns: 'common' })}
            onClick={onCancel}
            disabled={loading}
            small
          />
        </FlexRow>
      }

      {noteContent.content}

      <SmallText>
        {dayjs(new Date(shouldUseModifedDate(note) ? note.modifiedOn : note.createdOn)).format(localisation.dateFormats.dateAndTime)}
      </SmallText>
    </Item>
  )
}

export default Note;

const Item = styled.div`
  font-size: 14px;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.action.divider};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledTextArea = styled(TextArea)`
  background-color: transparent;
  box-shadow: none;
  font-size: 14px;
  color: ${p => p.theme.text.primary};
  height: 100px;
  box-shadow: 0 1px 2px 0 ${p => p.theme.shadow.primary};
`;

const Name = styled.div`
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;

const Source = styled.div`
  font-size: 12px;
  font-style: italic;
  color: ${p => p.theme.text.light};
`;

const Content = styled.div`
  color: ${p => p.theme.text.primary};
  max-height: 200px;
  overflow: auto;
`;

const SmallText = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const FlexRow = styled.div`
  display: flex;
  gap: 8px;
`;

const Separator = styled.div`
  width: 1.5px;
  height: 18px;
  background-color: ${p => p.theme.action.divider};
  margin: 0 6px;
`;