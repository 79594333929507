import { HierarchyBuilding } from '@api/models/Hierarchy';
import { CascaderDropdownSelect } from '@components/cascader-dropdown-select/CascaderDropdownSelect';
import { ICascaderMultiSelectNodeGroup } from '@components/cascader-multi-select/CascaderMultiSelect.types';
import { orderBy } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  buildings: HierarchyBuilding[];
  onChange: (spaceIds: number[]) => void;
  resetPulse: boolean;
  styles?: CSSProperties;
  placeHolder?: string;
}

const SpaceSelect = ({ buildings, onChange, resetPulse, styles, placeHolder }: PropTypes) => {
  const { t } = useTranslation(['molecules']);
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<number>>({ nodes: [] });

  useEffect(() => {
    const options: ICascaderMultiSelectNodeGroup<number> = {
      header: 'Buildings',
      nodes: orderBy(buildings, x => x.name.toLocaleLowerCase()).map((building) => ({
        label: `${building.name}`,
        childGroup: {
          header: 'Floors',
          nodes: orderBy(building.floors, x => x.floorNumber).map((floor) => ({
            label: `${floor.name}`,
            childGroup: {
              header: 'Spaces',
              nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map((space) => ({
                label: `${space.name}`,
                value: space.id
              }))
            }
          })),
        },
      })),
    };

    setCascaderOptions(options);
  }, [buildings])

  const handleChange = (spaceIds?: number[]) => {
    onChange(spaceIds ?? []);
  }

  return (
    <CascaderDropdownSelect
      group={cascaderOptions}
      onChange={handleChange}
      placeholder={placeHolder ?? t('Select', { ns: 'common' })}
      includeChildValues
      hideChildGroupLabel
      resetPulse={resetPulse}
      fieldStyles={styles}
    />
  );
};

export default SpaceSelect;