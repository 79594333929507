import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled, { useTheme } from 'styled-components';
import MeterDetailsModal from './MeterDetailsModal';
import { useModal } from '@hooks/useModal';
import { EnergyMeterTopologyDto } from '@api/models/EnergyMeterTopologyDto';

interface ISubMeterGroupItem {
  meter: EnergyMeterTopologyDto;
}

const SubMeterGroupItem = ({ meter }: ISubMeterGroupItem) => {
  const theme = useTheme();
  const { isOpen: detailModalIsOpen, toggle: toggleDetailModal, ref: detailModalRef } = useModal({});

  return (
    <>
      <Row onClick={toggleDetailModal}>
        <Column>
          <Name>
            {meter.meterNumber}
          </Name>
        </Column>
        <Column>
          <MeterNumber>
            {meter.meterCategory}
          </MeterNumber>
        </Column>
        <Column>
          <SpaceName>
            {meter.spaceName}
          </SpaceName>
        </Column>

        <InfoIcon icon={solid('circle-info')} />
      </Row>

      <MeterDetailsModal
        meter={meter}
        isOpen={detailModalIsOpen}
        modalRef={detailModalRef}
        toggle={toggleDetailModal}
        color={theme.palette.primary}
        titlePrefix='Sub Meter:'
      />
    </>
  );
};

export default SubMeterGroupItem;

const Row = styled.div`
  display: flex;
  height: 27px;
  padding: 7px 8px;

  &:first-child {
    border-radius: 9px 9px 0 0;
  }

  &:last-child {
    border-radius: 0 0 9px 9px;
  }

  &:nth-child(odd) {
    background-color: ${(p) => p.theme.palette.tables.evenRow};
  }

  &:nth-child(even) {
    background-color: ${(p) => p.theme.palette.tables.unevenRow};
  }
`;

export const InfoIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  cursor: pointer;
  color: ${p => transparentize(0.3, p.theme.palette.text.weak)};

  ${Row}:hover & {
    color: ${p => p.theme.palette.primary};
  }
`;

const Column = styled.div`
  width: 100px;

  div {
    width: 88px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:first-child {
    padding: 0 5px 0 0;
  }

  &:last-child {
    padding: 0 0 0 5px;
  }

  &:not(:first-child):not(:last-child) {
    padding: 0 5px;
  }
`;

const Name = styled.div`
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
`;

const MeterNumber = styled.div`
  font-size: 12px;
  line-height: 13px;
  color: ${p => p.theme.palette.text.fair};
`;

const SpaceName = styled.div`
  font-size: 12px;
  line-height: 13px;
  color: ${p => p.theme.palette.text.weak};
`;
