import { IAuthConfig } from '@utils/ConnectApiUtils';

export abstract class BaseQuery<TDto> {
  protected getApiUrl(apiBaseUrl: string): string {
    return `${apiBaseUrl}/api${this.targetUrl()}`;
  }

  abstract targetUrl(): string;

  abstract execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto>;
}
