import { IGradientStep } from '@src/types/GradientStep';
import { MetricType } from '@api/enums/MetricType';
import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { HierarchyBuilding, HierarchyFloor } from '@api/models/Hierarchy';
import SiteClimateControlUpdateCommand from '@api/queries/climate-control/Site/SiteClimateControlUpdateCommand';
import SpaceClimateControlUpdateCommand from '@api/queries/climate-control/Space/SpaceClimateControlUpdateCommand';
import { LocalisationFunction } from '@contexts/LocalisationContext/LocalisationContext';
import { TUseApiExecute } from '@hooks/useApi';
import { isNullOrUndefined } from '@utils/NumberUtils';
import { TFunction } from 'i18next';
import { EmptyObject, isEqual } from 'lodash';
import { FormState } from 'react-hook-form';
import { TemperatureRangeForm } from './components/setup/types/TemperatureRangeForm';

export enum ClimateControlConstants {
  TempRangeMin = 8,
  TempRangeMax = 30,
  HourlyThresholdMin = 1,
  HourlyThresholdMax = 72
}

export const climateControlSliderGradientSteps: IGradientStep[] = [
  {
    value: ClimateControlConstants.TempRangeMin,
    color: '#1B98FF'
  },
  {
    value: ClimateControlConstants.TempRangeMax,
    color: '#E7091B'
  },
];

export enum ClimateControlDefaults {
  Min = 10,
  Max = 22,
  Target = 20,
  StepDownOneThreshold = 4,
  StepDownOneTemp = 18,
  StepDownTwoThreshold = 24,
  StepDownTwoTemp = 12,
  OfflineSensorThreshold = 2,
  OfflineSensorTemp = 12
}

export const hasOverridenValues = (spaceClimateControl: SpaceClimateControlDto) => {
  return (
    !isNullOrUndefined(spaceClimateControl.minTemp) ||
    !isNullOrUndefined(spaceClimateControl.maxTemp) ||
    !isNullOrUndefined(spaceClimateControl.targetTemp) ||
    !isNullOrUndefined(spaceClimateControl.controlMode)
  );
};

export const submitSiteClimateControlForm = async (
  siteClimateControl: SiteClimateControlDto,
  temperatureRangeValues: TemperatureRangeForm,
  formState: FormState<TemperatureRangeForm>,
  fromLocale: LocalisationFunction,
  t: TFunction,
  execute: TUseApiExecute
) => {
  const hasBeenModified = !isEqual(temperatureRangeValues.temperatureRangeSettings, formState.defaultValues?.temperatureRangeSettings)
    || !isEqual(temperatureRangeValues.fallbackSettings, formState.defaultValues?.fallbackSettings);

  if (hasBeenModified) {
    const modified: SiteClimateControlDto = {
      ...siteClimateControl,
      ...temperatureRangeValues.fallbackSettings,
      minTemp: fromLocale(MetricType.Temperature, temperatureRangeValues.temperatureRangeSettings.minTemp),
      maxTemp: fromLocale(MetricType.Temperature, temperatureRangeValues.temperatureRangeSettings.maxTemp),
      targetTemp: fromLocale(MetricType.Temperature, temperatureRangeValues.temperatureRangeSettings.targetTemp),
      offlineSensorTemp: fromLocale(MetricType.Temperature, temperatureRangeValues.fallbackSettings.offlineSensorTemp),
      noMotionThreshold1Temp: fromLocale(MetricType.Temperature, temperatureRangeValues.fallbackSettings.noMotionThreshold1Temp),
      noMotionThreshold2Temp: fromLocale(MetricType.Temperature, temperatureRangeValues.fallbackSettings.noMotionThreshold2Temp)
    };

    await updateSiteClimateControl(modified, t, execute);
  }
};

export const updateSiteClimateControl = async (siteClimateControl: SiteClimateControlDto, t: TFunction, execute: TUseApiExecute) => {
  await execute({
    query: new SiteClimateControlUpdateCommand(siteClimateControl),
    successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
    errorMessage: t('ChangesSaveError', { ns: 'status' }),
    pendingMessage: t('ChangesSavePending', { ns: 'status' })
  });
};

export const DeleteSpaceClimateControlExceptionQuery = (spaceClimateControl: SpaceClimateControlDto) => {
  const modified = {
    ...spaceClimateControl,
    minTemp: undefined,
    maxTemp: undefined,
    targetTemp: undefined,
    controlMode: undefined,
    endDate: undefined
  }

  return new SpaceClimateControlUpdateCommand(modified);
}

const filterSpaces = (floor: HierarchyFloor, relevantSpaces: number[]): HierarchyFloor[] => {
  const spaces = floor.spaces.filter(space => relevantSpaces.includes(space.id));
  return spaces.length > 0 ? [{ ...floor, spaces: spaces }] : [];
};

const filterFloors = (building: HierarchyBuilding, relevantSpaces: number[]): HierarchyFloor[] => {
  return building.floors.flatMap(floor => filterSpaces(floor, relevantSpaces));
};

export const filterBuildingsByRelevantSpaces = (buildings: HierarchyBuilding[], relevantSpaces: number[]): HierarchyBuilding[] => {
  return buildings.reduce((filteredBuildings, building) => {
    const floors = filterFloors(building, relevantSpaces);
    if (floors.length > 0) {
      filteredBuildings.push({ ...building, floors: floors });
    }
    return filteredBuildings;
  }, [] as HierarchyBuilding[]);
};

export const temperaturesWereIncreased = (siteClimateControl: SiteClimateControlDto, form: TemperatureRangeForm | EmptyObject<TemperatureRangeForm>) => {
  if (!form.temperatureRangeSettings || !form.fallbackSettings) {
    return false;
  }

  return form.temperatureRangeSettings.minTemp > siteClimateControl.minTemp ||
    form.temperatureRangeSettings.maxTemp > siteClimateControl.maxTemp ||
    form.temperatureRangeSettings.targetTemp > siteClimateControl.targetTemp ||
    form.fallbackSettings.noMotionThreshold1Temp > siteClimateControl.noMotionThreshold1Temp ||
    form.fallbackSettings.noMotionThreshold2Temp > siteClimateControl.noMotionThreshold2Temp;
};