import { callDelete, IAuthConfig } from '@utils/ConnectApiUtils';
import { BaseQuery } from '@api/queries/common/BaseQuery';

export abstract class OperatorDeleteQuery<TDto> extends BaseQuery<TDto> {
  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.operatorApiBaseUrl);
    const response = await callDelete(apiUrl, this, authConfig, controller);

    return response.data;
  }
}