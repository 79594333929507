import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { useTheme } from 'styled-components';
import Filter from './Filter';
import { EsgExplorerConfiguration, EsgExplorerFilter } from '@api/models/EsgExplorerConfiguration';
import ConfigGroup from '../ConfigGroup';
import { Button } from '@components/core/Button';
import { useTranslation } from 'react-i18next';

interface IFilters {
  config: EsgExplorerConfiguration;
  onChange: (filters: EsgExplorerFilter[]) => void;
}

const FilterList = ({ config, onChange }: IFilters) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const AddFilterButton = (
    <Button
      style={{ padding: '3px 9px', marginTop: '-2px' }}
      onClick={() => onChange([...config.filters, {}])}
      tertiary
      label={
        <>
          <AddIcon icon={solid('plus')} />
          <AddLabel>
            Add Filter
          </AddLabel>
        </>
      }
    />
  );

  return (
    <ConfigGroup
      title={t('ESG.Filters', { ns: 'molecules' })}
      topRightElement={AddFilterButton}
      styles={{ borderTop: `1px solid ${theme.action.divider}`, paddingTop: 20 }}
      titleStyles={{ fontSize: 14, fontWeight: 500, color: theme.text.primary }}
    >
      {config.filters.length === 0 &&
        <NoFiltersLabel>
          {t('ESG.NoFiltersApplied', { ns: 'molecules' })}
        </NoFiltersLabel>
      }

      {config.filters.map((filter, i) => (
        <Filter
          key={i}
          config={config}
          filter={filter}
          onChange={filter => onChange(config.filters.map((x, j) => i === j ? filter : x))}
          remove={() => {
            const clone = [...config.filters];
            clone.splice(i, 1);
            onChange(clone)
          }}
          showAndLabel={config.filters[i + 1] !== undefined}
        />
      ))}
    </ConfigGroup>
  );
}

export default FilterList;

const AddIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.secondary};
`;

const AddLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.primary};
  cursor: pointer;
  margin-left: 8px;
`;

const NoFiltersLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.palette.secondary};
  padding-top: 20px;
`;