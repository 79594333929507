import { SiteClimateControlDto } from '@api/models/SiteClimateControlDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export class SiteClimateControlGetBySiteIdQuery extends GetQuery<SiteClimateControlDto> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/ClimateControl/site/GetBySiteId/${this.siteId}`;
  }
}
