import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from '@components/core/Button';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import InputFormField from '@pages/site/resident-app/components/common/InputFormField';
import LabelWithCharacterCount from '@pages/site/resident-app/components/common/LabelWithCharacterCount';
import { UseFormReturn } from 'react-hook-form';
import challengeTemplateImage from '@assets/images/residentApp/challengeCard.png'
import { FileDropzone } from '@components/core/FileDropzone';
import { useDropzone } from 'react-dropzone';
import RadioInputFormField from '@pages/site/resident-app/components/common/RadioInputFormField';
import ResidentAppFormErrorMessage from '@pages/site/resident-app/components/common/ResidentAppFormErrorMessage';
import { CreateChallengeForm } from '../../create-challenge/ResidentApp_CreateChallenge';
import TitleWithButton from '@pages/site/resident-app/components/common/TitleWIthButton';
import IconWithText from '@pages/site/resident-app/components/common/IconWithText';
import { Title } from '@components/core/Title';

export enum ImageType {
  UseTemplate = 'useTemplate',
  UploadImage = 'uploadImage',
}

type ImageInfoContentProps = {
  onEdit?: () => void;
  isEditDisabled?: boolean;
  methods?: UseFormReturn<CreateChallengeForm>;
  imageUrl?: string;
  onImageChange?: (files: File[]) => void;
  onImageDelete?: () => void;
  shortTitle?: string;
}

const ImageInfoContent = ({onEdit, isEditDisabled, methods, imageUrl, onImageChange, onImageDelete, shortTitle}: ImageInfoContentProps) => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: (files) => {
      if (onImageChange) {
        onImageChange(files);
      }
    },
    accept: ['image/*']
  });

  return (
    <div>
      <TitleWithButton
        title={t('ResidentApp.Image')}
        button={
          onEdit && (
            <Button
              label={<IconWithText icon={regular('pencil')} text={t('Edit', {ns: 'common'})} color={theme.palette.text.fair} />}
              tertiary
              disabled={isEditDisabled}
              onClick={onEdit}
            />
          )
        }
      />
      <Description>{t('ResidentApp.ImageInfoDescription')}</Description>
      <Content>
        {methods && (
          <div>
            <RadioInputFormField
              name="imageType"
              id="useTemplate"
              label={t('ResidentApp.UseTemplate')}
            />
            <RadioInputFormField
              name="imageType"
              id="uploadImage"
              label={t('ResidentApp.CreateMyOwn')}
            />
          </div>
        )}

        {/* show preview template if useTemplate selected */}
        {methods && methods.watch('imageType') === ImageType.UseTemplate && (
          <>
            <ShortTitleContainer>
              <LabelWithCharacterCount
                label={t('ResidentApp.ShortTitle')}
                inputName="shortTitle"
                characterLimit={50}
                currentCharacterCount={methods.watch('shortTitle')?.length ?? 0}
              />
              <InputFormField
                inputName="shortTitle"
                isRequired
                maxLength={50}
                requiredErrorMessage={t('ShortTitleRequired', {ns: 'validation'})}
                error={!!methods.formState.errors.shortTitle}
              />
              {methods.formState.errors.shortTitle && (
                <ResidentAppFormErrorMessage
                  message={methods.formState.errors.shortTitle.message}
                />
              )}
            </ShortTitleContainer>
            <div>
              <Title text={t('ResidentApp.PreviewText')} size='sm' wrapperStyle={{margin: '10px 0'}}/>
              <TemplateImageContainer>
                <TemplateText isPlaceholder={methods.watch('shortTitle')?.length === 0}>
                  {methods.watch('shortTitle')
                    ? methods.watch('shortTitle')
                    : t('ResidentApp.ShortTitleDisplayText')}
                </TemplateText>
              </TemplateImageContainer>
            </div>
          </>
        )}

        {/* show upload image field if uploadImage selected */}
        {methods && methods.watch('imageType') === ImageType.UploadImage && (
          <CustomImage>
            <FileDropzone
              fileUri={imageUrl}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              mainText={t('ResidentApp.UploadOrDragImage')}
              dimText={t('ResidentApp.ImageUploadInfo')}
              onDelete={onImageDelete}
              imageStyle={{ objectFit: 'cover', maxWidth: '716px', maxHeight: '382px' }}
            />
            {methods.formState.isDirty && !imageUrl && (
              <ResidentAppFormErrorMessage message={t('ImageRequired', {ns: 'validation'})} />
            )}
          </CustomImage>
        )}

        {onEdit && imageUrl && (
          <CustomImage>
            <Image src={imageUrl} alt={t('ResidentApp.ChallengeImage')} />
          </CustomImage>
        )}

        {onEdit && !imageUrl && shortTitle && (
          <TemplateImageContainer>
            <TemplateText isPlaceholder={false}>{shortTitle}</TemplateText>
          </TemplateImageContainer>
        )}
      </Content>
    </div>
  );
}

export default ImageInfoContent;

const Description = styled.p`
  margin: 10px 0 0 0;
  font-size: 14px;
  color: ${(p) => p.theme.palette.text.fair};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
`;

const TemplateImageContainer = styled.div`
  margin-top: 15px;
  width: 358px;
  height: 191px;
  background-image: url(${challengeTemplateImage});
  display: grid;
  place-items: center;
`;

const TemplateText = styled.span<{isPlaceholder: boolean}>`
  font-size: 32px;
  color: ${(p) => p.theme.palette.text.onPrimary};
  font-weight: 800;
  opacity: ${(p) => p.isPlaceholder ? 0.6 : 1};
  text-align: center;
`;

const CustomImage = styled.div`
  max-width: 408px;
  max-height: 341px;
`;

const Image = styled.img`
  max-width: 360;
  max-height: 190px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ShortTitleContainer = styled.div`
  max-width: 532px;
`;