import { SiteOperatorDto } from '@api/models/SiteOperatorDto';
import { OperatorPostQuery } from '@api/queries/resident-app/common/OperatorPostQuery';

export default class SiteOperatorResidentAppCreateCommand extends OperatorPostQuery<SiteOperatorDto> {
  siteId: number;

  constructor(siteId: number, ) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return '/SiteOperators/Create';
  }
}
