import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import EsgExplorerTemplateDto from '@api/models/EsgExplorerTemplateDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EsgExplorerTemplateUpdateCommand extends PostQuery<EsgExplorerTemplateDto> {
  templateId: number;
  name: string;
  private: boolean;
  configuration: EsgExplorerConfiguration;

  constructor(template: EsgExplorerTemplateDto) {
    super();
    this.templateId = template.id!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    this.name = template.name;
    this.private = template.private;
    this.configuration = template.configuration;
  }

  public targetUrl(): string {
    return '/esgExplorer/updateTemplate';
  }
}
