import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AlertRuleCard from '../AlertRuleCard';
import { AlertRule } from '@api/models/AlertRule';
import { ErrorMessage, Input, Label } from '@components/Form';
import { Switch } from '@components/core/Switch';

interface IDetailsConfiguration {
  rule: AlertRule;
  onChange: (rule: AlertRule) => void;
}

const DetailsConfiguration = ({ rule, onChange }: IDetailsConfiguration) => {
  const { t } = useTranslation(['settingsAlerting']);

  return (
    <AlertRuleCard
      open={true}
    >
      <FlexRow>
        <div style={{ flexGrow: 1, marginRight: '20px' }}>
          <Label>{t('DetailsConfiguration.Name', { ns: 'settingsAlerting' })}</Label>
          <Input
            value={rule.name ?? ''}
            onInput={(e) => onChange({ ...rule, name: e.currentTarget.value })}
          />
          <ErrorMessage></ErrorMessage>
        </div>
        <div style={{ flexGrow: 2 }}>
          <Label>{t('DetailsConfiguration.Description', { ns: 'settingsAlerting' })}</Label>
          <Input
            value={rule.description ?? ''}
            onInput={(e) => onChange({ ...rule, description: e.currentTarget.value })}
          />
          <ErrorMessage></ErrorMessage>
        </div>
        <VerticalDivider />
        <FlexRow gap={15} style={{ alignItems: 'center' }}>
          <Medium>
            {rule.enabled ? t('DetailsConfiguration.Enabled', { ns: 'settingsAlerting' }) : t('DetailsConfiguration.Disabled', { ns: 'settingsAlerting' })}
          </Medium>
          <Switch
            checked={rule.enabled}
            onChange={() => onChange({ ...rule, enabled: !rule.enabled })}
          />
        </FlexRow>
      </FlexRow>
    </AlertRuleCard>
  );
};

export default DetailsConfiguration;

const FlexRow = styled.div<{ gap?: number }>`
  display: flex;
  gap: ${p => p.gap ? `${p.gap}px` : 'unset'};
`;

const Medium = styled.span`
  color: ${p => p.theme.palette.text.fair};
  font-weight: 500;
  width: 66px;
  text-align: right;
`;

const VerticalDivider = styled.div`
  margin: -20px 30px;
  height: 102px;
  width: 1px;
  background-color: ${p => p.theme.palette.borders.medium};
`;