import { OperatorDeleteQuery } from '@api/queries/resident-app/common/OperatorDeleteQuery';
import { OperatorUserDto } from '@api/models/OperatorUserDto';

export default class UsersResidentAppUpdateCommand extends OperatorDeleteQuery<OperatorUserDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return '/Users/Delete';
  }
}
