import TenantsGetAllQuery from '@api/queries/tenants/TenantsGetAllQuery';
import FeaturesGetAllQuery from '@api/queries/features/FeaturesGetAllQuery';
import { useApiState } from '@hooks/useApiState';
import TenantList from './components/tenant-list/TenantList';
import { useState } from 'react';
import FeatureList from './components/feature-list/FeatureList';
import styled, { css, useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { alphabeticalSort } from '@utils/StringUtils';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { SearchField } from '@components/search-field/SearchField';

const FeatureManagement = () => {
  const theme = useTheme();
  const { t } = useTranslation(['settingsFeatures']);
  const [searchString, setSearchString] = useState('');
  const [inFeatureView, setInFeatureView] = useState(false);

  const { data: features, execute: refreshFeatures } = useApiState({
    query: new FeaturesGetAllQuery(),
    initialState: []
  }, []);

  const { data: tenants, execute: refreshTenants } = useApiState({
    query: new TenantsGetAllQuery(),
    initialState: []
  }, []);

  const handleRefresh = () => {
    refreshFeatures();
    refreshTenants();
  }

  return (
    <PaddedContainer>
      <FlexRow>
        <Icon icon={solid('list-timeline')} />

        <ToggleButton active={!inFeatureView} onClick={() => setInFeatureView(false)}>
          {t('Tenants', { ns: 'settingsFeatures' })}
        </ToggleButton>

        <ToggleButton active={inFeatureView} onClick={() => setInFeatureView(true)}>
          {t('Features', { ns: 'settingsFeatures' })}
        </ToggleButton>

        <SearchField
          style={{ marginLeft: 'auto', background: theme.action.filled }}
          placeholder={t('Search', { ns: 'common' })}
          onSearchChange={(str) => setSearchString(str.toLowerCase())}
        />
      </FlexRow>

      <Container visible={inFeatureView}>

        <FeatureList
          tenants={tenants}
          features={features}
          refresh={handleRefresh}
          searchString={searchString}
        />
      </Container>

      <Container visible={!inFeatureView}>
        <TenantList
          tenants={tenants.slice().sort((a, b) => alphabeticalSort(a.name, b.name))}
          features={features}
          refresh={handleRefresh}
          searchString={searchString}
        />
      </Container>
    </PaddedContainer>
  );
};

export default FeatureManagement;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  padding-bottom: 20px;
`;

const Container = styled.div<{ visible: boolean }>`
  height: ${p => p.visible ? 'auto' : '0px'};
  overflow: hidden;
`;

const ToggleButton = styled.div<{ active: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  padding: 5px 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }

  ${p => p.active && css`
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  `}
`;

const Icon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  padding: 7px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  transition: all 150ms ease;

  color: ${p => transparentize(0, p.theme.palette.primary)};
`;