import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiState } from '@hooks/useApiState';
import TenantGetByIdQuery from '@api/queries/tenants/TenantGetByIdQuery';
import { Loading } from '@components/core/Loading';
import { Tenant } from '@api/models/Tenant';
import { useApi } from '@hooks/useApi';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useFileHandler } from '@hooks/useFileHandler';
import TenantUpdateCommand from '@api/queries/tenants/TenantUpdateCommand';
import TenantLogoUploadCommand from '@api/queries/tenants/TenantLogoUploadCommand';
import TenantLogoDeleteCommand from '@api/queries/tenants/TenantLogoDeleteCommand';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Card } from '@components/core/Card';
import { ErrorMessage, Form, Input, Label } from '@components/Form';
import { BlobFileUploadArea } from '@components/blob-file-upload-area/BlobFileUploadArea';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';
import { Button } from '@components/core/Button';
import styled from 'styled-components';
import { BackButton } from '@components/core/BackButton';

//#region TenantEdit component

const TenantEdit = () => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { data: tenant, setData: setTenant, loading } = useApiState({
    query: tenantId === undefined ? undefined : new TenantGetByIdQuery(tenantId)
  }, [tenantId]);

  if (loading) {
    return <Loading />
  }

  if (!tenant) {
    return null;
  }

  return (
    <TenantEditForm
      tenant={tenant}
      onTenantUpdate={setTenant}
    />
  );
}

export default TenantEdit;

//#endregion

//#region TenantEditForm component

type FormValues = {
  name: string,
  email: string,
  operatorLogoId: string
}

type TenantEditFormProps = {
  tenant: Tenant,
  onTenantUpdate: (tenant: Tenant) => void,
}

const TenantEditForm = ({ tenant, onTenantUpdate }: TenantEditFormProps) => {
  const { t } = useTranslation(['settingsTenant']);
  const { execute } = useApi();
  const { tenant: currentTenant, setTenant } = useTenantContext();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { file, fileName, fileHasChanged, fileToBeDeleted, handleFileChange, handleFileDelete } = useFileHandler(tenant.operatorLogoId)
  const { register, handleSubmit, reset, formState: { errors, isDirty } } = useForm<FormValues>({
    defaultValues: {
      name: tenant.name,
      email: tenant.email
    }
  });

  const onSave = async (formValues: FormValues) => {
    setSavingInProgress(true);
    let isMounted = true;

    const modifiedTenant: Tenant = {
      ...tenant,
      name: formValues.name,
      email: formValues.email
    };

    if (isDirty) {
      const tenantDto = await execute({
        query: new TenantUpdateCommand(modifiedTenant),
        successMessage: t('Editing.ChangesSaved', { ns: 'settingsTenant' }),
        errorMessage: t('Editing.SavingFailed', { ns: 'settingsTenant' })
      });
      isMounted = tenantDto !== undefined;
    }

    if (fileHasChanged && file) {
      const result = await execute({
        query: new TenantLogoUploadCommand(file, tenant.tenantId),
        successMessage: t('Editing.ImageUploaded', { ns: 'settingsTenant' }),
        errorMessage: t('Editing.ImageUploadFailed', { ns: 'settingsTenant' })
      });
      modifiedTenant.operatorLogoId = result?.blobName;
      isMounted = result !== undefined;
    } else if (fileToBeDeleted && tenant.operatorLogoId) {
      const result = await execute({
        query: new TenantLogoDeleteCommand(tenant.operatorLogoId, tenant.tenantId),
        successMessage: t('Editing.ImageDeleted', { ns: 'settingsTenant' }),
        errorMessage: t('Editing.ImageDeleteFailed', { ns: 'settingsTenant' })
      });
      modifiedTenant.operatorLogoId = undefined;
      isMounted = result !== undefined;
    }

    if (!isMounted) {
      return;
    }

    setSavingInProgress(false);
    reset({ name: modifiedTenant.name, email: modifiedTenant.email });
    onTenantUpdate(modifiedTenant);

    if (currentTenant.tenantId === tenant.tenantId) {
      setTenant(modifiedTenant);
    }
  }

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('BackToTenants', { ns: 'navigation' })}
          url='./..'
        />

        <Card
          cardTitle={t('Editing.Tenant', { ns: 'settingsTenant' })}
          centered
          maxWidth='700px'
        >
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Editing.Name', { ns: 'settingsTenant' })}</Label>
                  <Input
                    {...register('name', {
                      required: t('Editing.RequiredField', { ns: 'settingsTenant' })
                    })}
                    defaultValue={tenant.name}
                  />
                  <ErrorMessage>{errors.name?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Editing.Email', { ns: 'settingsTenant' })}</Label>
                  <Input
                    {...register('email', {
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t('Editing.EmailValidation', { ns: 'settingsTenant' })
                      }
                    })}
                    defaultValue={tenant.email}
                  />
                  <ErrorMessage>{errors.email?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Label>{t('Editing.Logo', { ns: 'settingsTenant' })}</Label>
                  <BlobFileUploadArea
                    blobName={fileName}
                    mainText={t('Editing.UploadImage', { ns: 'settingsTenant' })}
                    dimText={'JPEG, PNG, SVG'}
                    acceptedTypes={['image/*']}
                    onFileChange={handleFileChange}
                    onFileDelete={handleFileDelete}
                    blobContainer={BlobStorageContainerType.Shared}
                    containerStyle={{ width: '200px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    imageStyle={{ width: 'auto', maxWidth: '200px', height: 'auto', maxHeight: '100px', padding: '10px' }}
                  />
                </div>
              </div>
            </div>
          </Form>
        </Card>
        <Card
          centered
          maxWidth='700px'
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <Button
                  label={t('Editing.Save', { ns: 'settingsTenant' })}
                  onClick={handleSubmit(onSave)}
                  disabled={!(isDirty || fileHasChanged || fileToBeDeleted)}
                  loading={savingInProgress}
                />
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </PaddedContainer>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;

//#endregion