import styled, { useTheme } from 'styled-components';
import OccupancyChart, { OccupancyDataset } from './OccupancyChart';
import { format } from 'date-fns';
import { transparentize } from 'polished';
import { TooltipItem } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { ReportOccupancyPeopleCountingDto } from '@api/models/ReportOccupancyPeopleCountingDto';
import { rgbaToRgb } from '@utils/ColorUtils';

interface IOccupancyWidget {
  data: ReportOccupancyPeopleCountingDto;
  inPrintMode: boolean;
}

const OccupancyWidget = ({ data, inPrintMode }: IOccupancyWidget) => {
  const { t } = useTranslation(['molecules']);
  const theme = useTheme();
  const color = inPrintMode ? theme.palette.printStyles.chartColor : theme.palette.esgReport.chartColor;

  const datasets: OccupancyDataset[] = [
    {
      label: t('ESG.Occupancy', { ns: 'molecules' }),
      gradientColors: [`${rgbaToRgb(transparentize(0.4, color))}`, color],
      dataset: data.dataPoints.map(x => parseInt(x.value))
    }];

  const labels = data.dataPoints.map(x => x.measuredOn);

  const title = data.buildingName ? `${data.buildingName}: ${data.spaceName}` : data.spaceName;

  return (
    <Card>
      <Title>
        {title}
      </Title>
      <Wrapper>
        <OccupancyChart
          datasets={datasets}
          labels={labels}
          inPrintMode={inPrintMode}
          printDimensions={{ width: 81, height: 25 }}
          xAxisTicksCallback={(_, index) => {
            return format(new Date(labels[index]), 'd LLL');
          }}
          tooltipCallbacks={
            {
              title: (items: TooltipItem<'bar' | 'line'>[]) => {
                const label = items[0]?.label;
                return format(new Date(label), 'd LLL, HH:mm');
              },
              label: (item: TooltipItem<'bar' | 'line'>) => {
                const suffix = item.raw === 1 ? 'Person' : 'People';
                return `${item.formattedValue} ${suffix}`;
              }
            }
          }
        />
      </Wrapper>
    </Card>
  );
};

export default OccupancyWidget;

const Card = styled.div`
  width: 100%;
  height: max-content;

  /*
   * Place two divs next to each other on larger screens.
   * Subtract 35px to account for 35px gap between the two divs.
   */
  @media (min-width: 1400px) {
    flex: 0 0 calc((100% - 35px) / 2);
    max-width: calc((100% - 35px) / 2);
  }

  padding: 20px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 10px;
  box-shadow: 0px 3px 8px ${p => p.theme.palette.esgReport.cardShadow};

  @media print {
    float: left;
    width: calc((100% - 20px) / 2);
    margin-bottom: 20px;

    &:nth-child(odd) {
      margin-right: 10px;
    }

    &:nth-child(even) {
      margin-left: 10px;
    }

    padding: 10px;
    page-break-inside: avoid;
    box-shadow: 0 0 4px 1px ${p => p.theme.palette.esgReport.cardShadow}, 0 0 1px 0px ${p => p.theme.palette.esgReport.cardShadow};
    border-radius: 5px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;

  @media print {
    font-size: 9pt;
    padding-bottom: 10px;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const Wrapper = styled.div`
  height: 150px;

  @media print {
    height: max-content;
  }
`;