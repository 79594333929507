import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import MarkerType from './Marker.types';
import { cloneDeep } from 'lodash';
import Marker from './Marker';
import { useSize } from '@hooks/useSize';

type PropTypes = {
  blobUri: string,
  markers: MarkerType[],
  showTooltips: boolean,
  onChange: (markers: MarkerType[]) => void,
}

const Floorplan = ({ blobUri, markers, showTooltips, onChange }: PropTypes) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const { domRect: imageDimensions } = useSize(imageRef);
  const [widthFactor, setWidthFactor] = useState<number>();
  const [heightFactor, setHeightFactor] = useState<number>();

  useEffect(() => {
    if (imageRef && imageDimensions) {
      const imageWidth = imageRef.current?.naturalWidth;
      const imageHeight = imageRef.current?.naturalHeight;

      if (imageWidth && imageHeight) {
        setWidthFactor(imageDimensions.width / imageWidth);
        setHeightFactor(imageDimensions.height / imageHeight);
      }
    }
  }, [imageRef, imageDimensions]);

  const onDrop = (e: React.DragEvent<HTMLImageElement>) => {
    const imagePosTop = imageRef.current?.getBoundingClientRect().top;
    const imagePosLeft = imageRef.current?.getBoundingClientRect().left;

    if (!widthFactor || !heightFactor || !imagePosTop || !imagePosLeft) {
      return;
    }

    // This is set on 'onDragStart" event.
    const index = parseInt(e.dataTransfer.getData('markerIndex'));

    if (isNaN(index)) {
      return;
    }

    const markersModified = cloneDeep(markers);

    markersModified[index].xCoordinate = (e.pageX - imagePosLeft) / widthFactor;
    markersModified[index].yCoordinate = (e.pageY - imagePosTop) / heightFactor;

    onChange(markersModified);
  }

  return (
    <ImageContainer>
      <Wrapper>
        <Image
          src={blobUri}
          ref={imageRef}
          draggable={false}
          onDragOver={(e) => e.preventDefault()}
          onDrop={onDrop}
        />
        {widthFactor && heightFactor &&
          markers.map((marker, i) => (
            <Marker
              key={i}
              index={i}
              marker={marker}
              widthFactor={widthFactor}
              heightFactor={heightFactor}
              showTooltip={showTooltips}
            />
          ))
        }
      </Wrapper>
    </ImageContainer>
  );
};

export default Floorplan;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;