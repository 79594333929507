import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { ReactComponent as CO2Svg } from '@assets/icons/esgReport/co2-svg.svg';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { SeparatorDot } from './EnergyConsumptionWidget';
import { ReportMetadataDto } from '@api/models/ReportMetadataDto';
import ReportMetricType from '@api/enums/ReportMetricType';
import { numberWithCommas } from '@utils/NumberUtils';
import { ReportEnergyConsumptionUsageDto } from '@api/models/ReportEnergyConsumptionDto';
import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { TFunction } from 'i18next';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

/**
 * Returns a metric's value if the metric is present
 */
const getMetric = (metaData: ReportMetadataDto[] | undefined, metricType: ReportMetricType): string | undefined => {
  if (metaData) {
    const metric = metaData.find(x => x.metricType === metricType);
    return metric?.value;
  }
}

const getDisplayedCarbonUsage = (carbon: number) => carbon >= 1000000 ? numberWithCommas(carbon / 1000000, 2) : numberWithCommas(carbon / 1000, 2);

const getDisplayedCarbonSuffix = (carbon: number) => carbon >= 1000000 ? 't' : 'kg';

const getCarbonData = (data: ReportEnergyConsumptionUsageDto, totalBuildingArea: string | undefined, buildingMetadata: ReportMetadataDto[] | undefined, inPrintMode: boolean, t: TFunction): { usage?: string, suffix: string, label: string, warningMessage?: string } => {
  let usage: string | undefined = undefined;
  let suffix = '';
  let label = '';
  let warningMessage: string | undefined = undefined;

  if (totalBuildingArea && data.totalCarbonUsage) {
    const carbonUsagePerSquareMeter = data.totalCarbonUsage / parseFloat(totalBuildingArea);
    usage = getDisplayedCarbonUsage(carbonUsagePerSquareMeter);
    suffix = getDisplayedCarbonSuffix(carbonUsagePerSquareMeter);
    label = t('ESG.CO2PerM2', { ns: 'molecules' });
  } else if (data.totalCarbonUsage) {
    usage = getDisplayedCarbonUsage(data.totalCarbonUsage);
    suffix = getDisplayedCarbonSuffix(data.totalCarbonUsage);
    label = inPrintMode ? t('ESG.CO2', { ns: 'molecules' }) : '';
  }

  /* 
  TODO [PP]: Potentially this warning could be added if both metadata static values plus no Electricity Carbon Intensity forecast values are found

  if (data.energyType === EnergyMeterType.Electricity) {
    const electricityCarbonIntensity = getMetric(buildingMetadata, ReportMetricType.ElectricityCarbonIntensity);
    if (!electricityCarbonIntensity) {
      warningMessage = 'No static value found for Electricity Carbon Intensity';
    }
  }
  */

  if (data.energyType === EnergyMeterType.Heating) {
    const heatingCarbonIntensity = getMetric(buildingMetadata, ReportMetricType.HeatingCarbonIntensity);
    if (!heatingCarbonIntensity) {
      warningMessage = t('ESG.NoHeatingCarbonIntensity', { ns: 'molecules' });
    }
  }

  if (data.energyType === EnergyMeterType.Water) {

    const carbonIntensities = [];

    const waterCarbonIntensity = getMetric(buildingMetadata, ReportMetricType.WaterCarbonIntensity);
    if (!waterCarbonIntensity) {
      carbonIntensities.push(t('ESG.WaterCarbonIntensity', { ns: 'molecules' }));
    }

    const wasteWaterCarbonIntensity = getMetric(buildingMetadata, ReportMetricType.WasteWaterCarbonIntensity);
    if (!wasteWaterCarbonIntensity) {
      carbonIntensities.push(t('ESG.WasteWaterCarbonIntensity', { ns: 'molecules' }));
    }

    if (carbonIntensities.length > 0) {
      const warning = t('ESG.NoMetadataFoundFor', { ns: 'molecules' });
      warningMessage = warning + carbonIntensities.join(', ');
    }
  }

  return { usage: usage, suffix: suffix, label: label, warningMessage: warningMessage }
}

interface ICarbonEmissions {
  data: ReportEnergyConsumptionUsageDto;
  inPrintMode: boolean;
  totalBuildingArea?: string;
  metadata?: ReportMetadataDto[];
}

const CarbonEmissions = ({ data, inPrintMode, totalBuildingArea, metadata }: ICarbonEmissions) => {
  const { t } = useTranslation();
  const { getUnitLabel, toLocale, getUnit } = useLocalisation();

  const carbonData = getCarbonData(data, totalBuildingArea, metadata, inPrintMode, t);
  return (
    <>
      {carbonData.usage !== undefined &&
        <SeparatorDot />
      }

      <Wrapper>
        <FlexRow>
          <Co2Icon />
          <RowCol>
            {carbonData.warningMessage &&
              <Tooltip
                content={carbonData.warningMessage}
                placement={TooltipPlacement.Top}
                fontSize='12px'
              >
                <WarningIcon icon={solid('exclamation-triangle')} />
              </Tooltip>
            }
            <Value withWarning={!!carbonData.warningMessage} hasValue={carbonData.usage !== undefined}>
              {carbonData.usage !== undefined ?
                <>
                  {toLocale(AltUnits.CO2WeightArea, parseFloat(carbonData.usage), { round: 2 })}{getUnit(AltUnits.CO2WeightArea)}
                </> :
                <span style={{ fontWeight: 400 }}>
                  --
                </span>}
            </Value>
            <Label>
              {getUnitLabel(AltUnits.CO2WeightArea)}
            </Label>
          </RowCol>
        </FlexRow>
      </Wrapper>
    </>
  );
};

export default CarbonEmissions;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const RowCol = styled.div`
  display: block;

  @media print {
    display: flex;
  }
`;

const Wrapper = styled.div`
  height: 65px;
  margin-top: 30px;
  background-color: ${p => p.theme.palette.esgReport.energyWidgetFooter};
  border-radius: 0 0 10px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media print {
    height: unset;
    margin-top: unset;
    background-color: transparent;
    border-radius: 0;
    align-items: flex-end;
    justify-content: baseline;

    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const Value = styled.div<{ withWarning?: boolean, hasValue: boolean }>`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 4px;

  ${p => p.withWarning && css`
    line-height: 0px;
    margin-bottom: 10px;
  `}

  @media print {
    display: ${p => p.hasValue ? 'block' : 'none'};
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;

  @media print {
    font-size: 8px;
    line-height: 8px;
    margin: 0.75mm 0 0 4px;
  }
`;

const WarningIcon = styled(FontAwesomeIcon)`
  margin-bottom: 5px;
  margin-left: 3px;
  font-size: 11px;
  color: ${p => transparentize(0.2, p.theme.palette.orange)};

  @media print {
    display: none;
  }
`;

const Co2Icon = styled(CO2Svg)`
  width: 30px;
  height: 30px;
  margin-right: 9px;

  fill:  ${p => p.theme.palette.text.medium};

  @media print {
    display: none;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: ${p => p.theme.palette.printStyles.text.medium};
  }
`;