import { LocalisationType } from '@i18n//localisation';
import { ukLocalisation } from '@i18n//en-GB';
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { localisations } from '@i18n//Localisations';
import { useUserContext } from '@contexts/UserContext/UserContext';
import UserPreferenceUpdateCommand from '@api/queries/users/UserPreferenceUpdateCommand';
import UserPreferenceGetByUserIdQuery from '@api/queries/users/UserPreferenceGetByUserIdQuery';
import { useApiState } from '@hooks/useApiState';
import { useApi } from '@hooks/useApi';
import { useTranslation } from 'react-i18next';
import { MetricType } from '@api/enums/MetricType';
import { round } from 'lodash';
import { MetricType_Suffix } from '@api/enums/MetricType_Suffix';
import { MetricType_ValueDisplayString } from '@api/enums/MetricType_ValueDisplayString';
import { AltUnits } from './AltUnits';
import { UserPreference } from '@api/models/UserPreference';
import { getChartDataUnit } from '@utils/ChartUtils';

/**
 * Metric types that should be localised
 */
export const LocalisedMetricTypes = Object.keys(ukLocalisation.metrics);

export type LocalisationFunction = (metric: MetricType | AltUnits, value: number, options?: ConversionOptions) => number;

export type ConversionOptions = {
  round?: number
}

interface ILocalisationContext {
  localisation: LocalisationType;
  toLocale: LocalisationFunction;
  fromLocale: LocalisationFunction;
  displayString: (metric: MetricType | AltUnits, value: string) => string;
  getUnit: (metric: MetricType | AltUnits) => string;
  getUnitLabel: (metric: MetricType | AltUnits) => string | undefined;
  updateLocalisation: (locale: LocalisationType) => void;
  userPreference?: UserPreference;
}

export const LocalisationContext = React.createContext({} as ILocalisationContext);
export const useLocalisation = () => useContext(LocalisationContext);

export const LocalisationProvider = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation('molecules');
  const [localisation, setLocalisation] = useState<LocalisationType>(ukLocalisation);
  const { user } = useUserContext();
  const { execute } = useApi();

  const { data: userPreference, execute: refresh } = useApiState({
    query: new UserPreferenceGetByUserIdQuery(user.id)
  }, [user])

  const updateLocalisation = useCallback(async (locale: LocalisationType) => {
    await execute({
      query: new UserPreferenceUpdateCommand(user.id, locale.locale),
      successMessage: t('Localisations.UpdateSuccess'),
      errorMessage: t('Localisations.UpdateFailed')
    });

    setLocalisation(locale)
    refresh()
  }, [execute, user, t, refresh]);

  // Convert to the current locale
  const toLocale: LocalisationFunction = useCallback((metric: MetricType | AltUnits, value: number, options?: ConversionOptions) => {
    const conversion = localisation.metrics[metric];
    const localised = conversion?.toLocale ? conversion.toLocale(value) : value;
    return options?.round !== undefined ? round(localised, options.round) : localised;
  }, [localisation]);

  // Convert from the current locale
  const fromLocale: LocalisationFunction = useCallback((metric: MetricType | AltUnits, value: number, options?: ConversionOptions) => {
    const conversion = localisation.metrics[metric];
    const localised = conversion?.fromLocale ? conversion.fromLocale(value) : value;
    return options?.round !== undefined ? round(localised, options.round) : localised;
  }, [localisation]);

  // Return the unit's suffix
  const getUnit = useCallback((metric: MetricType | AltUnits) => {
    const conversion = localisation.metrics[metric];

    if (metric in MetricType) {
      return conversion?.unit ?? MetricType_Suffix(metric as MetricType);
    } else {
      return conversion?.unit ?? '';
    }
  }, [localisation]);

  // Return the unit's label
  const getUnitLabel = useCallback((metric: MetricType | AltUnits) => {
    const conversion = localisation.metrics[metric];

    if (metric in MetricType) {
      return conversion?.unitLabel ?? getChartDataUnit(metric as MetricType).label;
    } else {
      return conversion?.unitLabel ?? '';
    }
  }, [localisation]);

  // Return the display string with the unit in the current locale
  const displayString = useCallback((metric: MetricType | AltUnits, value: string) => {
    const conversion = localisation.metrics[metric];

    if (metric in MetricType) {
      return conversion?.displayString ? conversion.displayString(value) : MetricType_ValueDisplayString({ metricType: metric as MetricType, value: value, t: t });
    } else {
      return conversion?.displayString ? conversion.displayString(value) : '';
    }
  }, [localisation, t]);

  const localisationContextValue = useMemo(() => (
    {
      localisation,
      updateLocalisation,
      userPreference,
      toLocale,
      fromLocale,
      displayString,
      getUnit,
      getUnitLabel
    }
  ), [localisation, updateLocalisation, userPreference, toLocale, fromLocale, displayString, getUnit, getUnitLabel]);

  useEffect(() => {
    const currentLocale = localisations.find(x => x.locale === userPreference?.locale)

    setLocalisation(currentLocale ?? ukLocalisation)
  }, [userPreference?.locale]);

  return (
    <LocalisationContext.Provider
      value={localisationContextValue}
    >
      {children}
    </LocalisationContext.Provider>
  );
};