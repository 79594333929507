import { stringToFloat } from '@utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: Noise
 */
const HeatmapConfigNoise: IHeatmapConfig = {
  accessor: (x: string) => {
    return stringToFloat(x, 0);
  },

  scale: {
    points: [
      {
        threshold: 55,
        label: '55db',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 65,
        label: '65',
        color: DefaultScaleColors.LIGHTGREEN,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 70,
        label: '70',
        color: DefaultScaleColors.YELLOW,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 75,
        label: '75',
        color: DefaultScaleColors.ORANGE,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 80,
        label: '80',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      }
    ]
  }
};

export default Object.freeze(HeatmapConfigNoise);