import { stringToFloat } from '@utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';
import { LocalisationFunction } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

/**
 * Heatmap config for metric: Temperature
 */
const HeatmapConfigTemperature = (toLocale: LocalisationFunction, getUnit: (metric: MetricType | AltUnits) => string): IHeatmapConfig => {
  return {
    accessor: (x: string) => {
      return stringToFloat(x, 0);
    },
    scale: {
      points: [
        {
          threshold: toLocale(MetricType.Temperature, 15, {round: 0}),
          label: `${toLocale(MetricType.Temperature, 15, {round: 0}).toString()} ${getUnit(MetricType.Temperature)}`,
          color: '#7a7fff',
          textColor: DefaultScaleColors.LIGHTTEXT,
        },
        {
          threshold: toLocale(MetricType.Temperature, 18, {round: 0}),
          label: toLocale(MetricType.Temperature, 18, {round: 0}).toString(),
          color: '#65C2FF',
          textColor: DefaultScaleColors.LIGHTTEXT,
        },
        {
          threshold: toLocale(MetricType.Temperature, 21, {round: 0}),
          label: toLocale(MetricType.Temperature, 21, {round: 0}).toString(),
          color: '#62FB6D',
          textColor: DefaultScaleColors.DARKTEXT,
        },
        {
          threshold: toLocale(MetricType.Temperature, 25, {round: 0}),
          label: toLocale(MetricType.Temperature, 25, {round: 0}).toString(),
          color: DefaultScaleColors.YELLOW,
          textColor: DefaultScaleColors.DARKTEXT,
        },
        {
          threshold: toLocale(MetricType.Temperature, 27, {round: 0}),
          label: toLocale(MetricType.Temperature, 27, {round: 0}).toString(),
          color: '#FFAE00',
          textColor: DefaultScaleColors.LIGHTTEXT,
          hideFromScale: true
        },
        {
          threshold: toLocale(MetricType.Temperature, 28, {round: 0}),
          label: toLocale(MetricType.Temperature, 28, {round: 0}).toString(),
          color: '#FF0000',
          textColor: DefaultScaleColors.LIGHTTEXT
        },
      ]
    }
  }
};

export default Object.freeze(HeatmapConfigTemperature);