import styled from 'styled-components';
import { Card } from './Card';

export type ActionItem = {
  id: string;
  label: string;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
}

type PopoverActionMenuProps = {
  actionItems: ActionItem[];
}

const PopoverActionMenu = ({actionItems}: PopoverActionMenuProps) => {
  return (
    <PopoverContent noPadding>
      {actionItems.map((item) => (
        <PopoverButton
          key={item.id}
          onClick={item.onClick}
          disabled={item.disabled}
        >
          <ActionLabel color={item.color}>{item.label}</ActionLabel>
        </PopoverButton>
      ))}
    </PopoverContent>
  );
};

export default PopoverActionMenu;

const PopoverContent = styled(Card)`

`;

const PopoverButton = styled.button`
  padding: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 8px 12px;
  text-align: left;
  font-family: inherit;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover{
    background-color: ${p => p.theme.primary.hover};

    span {
      color: ${p => p.theme.primary.main};
    }
  }
`;

const ActionLabel = styled.span<{color?: string}>`
  color: ${({ color, theme }) => color ?? theme.text.primary};
  font-size: 14px;
  text-align: left;
  transition: color 0.2s ease;
`;