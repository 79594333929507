import { SpaceTypeThresholdDto } from '@api/models/SpaceTypeThresholdDto';
import { OperatorPatchQuery } from '@api/queries/resident-app/common/OperatorPatchQuery';

export default class SpaceTypeThresholdsResidentAppUpdateCommand extends OperatorPatchQuery<SpaceTypeThresholdDto[]> {
  siteId: number;
  spaceTypeThresholds?: SpaceTypeThresholdDto[];

  constructor(siteId: number, spaceTypeThresholds?: SpaceTypeThresholdDto[]) {
    super();
    this.siteId = siteId;
    this.spaceTypeThresholds = spaceTypeThresholds;
  }

  public targetUrl(): string {
    return '/SpaceTypeThresholds/Update';
  }
}
