import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@components/core/Loading';
import { usePopover } from '@hooks/usePopover';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Dropdown from './Dropdown/Dropdown';
import { CascaderDropdownSelectNodeGroup } from './SearchableDropdownSelect.types';
import useSearchableDropdownSelect from '@hooks/useSearchableDropdownSelect';

type SearchableDropdownSelectProps<TValue> = {
  /**
     * Group of nodes with N number of child nodes
     */
  group: CascaderDropdownSelectNodeGroup<TValue>;
  /**
   * Array of values returned when selection changes.
   * Returns an empty array if a selection is made but no values are directly selected or present as children of the selected nodes.
   * Returns undefined when no selection is made, this is to distinguish between a selection without values and no selection at all.
   */
  onChange: (values?: TValue[]) => void;
  /**
   * Include values of selected descendants in returned array of values
   */
  includeChildValues?: boolean;
  /**
   * Placeholder content
   */
  placeholder?: ReactNode,
  /**
   * Display loading spinner if true
   */
  loading?: boolean;
  /**
   * Triggering this pulse will reset the options to their original state
   */
  resetPulse?: boolean;
}

const SearchableDropdownSelect = <TValue,>({ group, onChange, includeChildValues, placeholder, loading, resetPulse }: SearchableDropdownSelectProps<TValue>) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string>('');
  const { ref, visible, toggle } = usePopover({});
  const {
    transientNodeGroup,
    selectedLabels,
    labelContainerRef,
    numHiddenItems,
    allNodes,
    handleSelect,
    handleRemoveSpace
  } = useSearchableDropdownSelect({ group, onChange, includeChildValues, resetPulse });

  useEffect(() => {
    setSearchString('');
  }, [visible]);

  const handleClick = useCallback(() => {
    if (!visible && !loading) {
      toggle();
    }
  }, [visible, loading, toggle]);

  return (
    <Container ref={ref}>
      <SelectField onClick={handleClick}>
        {visible &&
          <StyledInput
            autoFocus
            value={searchString}
            placeholder={t('Select', { ns: 'common' })}
            onChange={(e) => setSearchString(e.target.value)}
          />
        }

        {loading &&
          <Loading
            size="15px"
            color="grey"
            inline
            style={{ margin: '0 auto' }}
          />
        }

        <IconWrapper>
          {(!visible && selectedLabels.length > 0) && numHiddenItems > 0 &&
            <Count>
              +{numHiddenItems}
            </Count>
          }


          {searchString.length === 0 ?
            <ChevronIcon onClick={toggle} icon={visible ? faChevronUp : faChevronDown} /> :
            <ClearIcon icon={faXmark} onClick={(e) => { e.stopPropagation(); setSearchString(''); }} />
          }
        </IconWrapper>

        <Placeholder visible={!visible && selectedLabels.length === 0}>
          {placeholder}
        </Placeholder>

        <LabelContainer ref={labelContainerRef} visible={!visible && selectedLabels.length > 0}>
          {selectedLabels.map((label) => (
            <SelectedLabel key={uuidv4()}>
              {label}
            </SelectedLabel>
          ))}
        </LabelContainer>
      </SelectField>
      <Dropdown
        open={visible}
        selectedLabels={selectedLabels}
        searchString={searchString}
        group={transientNodeGroup}
        handleRemoveSpace={handleRemoveSpace}
        allNodes={allNodes}
        handleSelect={handleSelect}
      />
    </Container>
  );
}

export default SearchableDropdownSelect

const SelectField = styled.div`
  position: relative;
  width: 100%;
  height: 38px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 5px;
  padding: 9px 54px 9px 10px;
  overflow: hidden;

  color: ${p => p.theme.text.secondary};
  background-color: ${p => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.action.outlineBorder};
  border-radius: 4px;
  box-shadow: ${p => p.theme.palette.forms.input.boxShadow};

  transition: all 150ms ease;

  &:hover {
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }
`;

const Container = styled.div`
  position: relative;
`;

const Placeholder = styled.div<{ visible: boolean }>`
  font-size: 14px;
  margin-right: 8px;
  margin-top: 1px;

  display: ${p => p.visible ? 'block' : 'none'};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding: 2px 9px;
  border-radius: 14px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 11px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearIcon = styled(FontAwesomeIcon)`
  width: 13px;
  height: 13px;
  color: ${p => p.theme.action.active};

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${p => p.theme.action.active};

  ${SelectField}:hover & {
    color: ${p => p.theme.primary.main};
  }
`;

const Count = styled.div`
  font-size: 12px;
  font-weight: 500;
  min-width: 18px;
  padding: 0px 5px;
  margin-right: 3px;
  border-radius: 8px;
  color: ${p => p.theme.text.contrast};
  background-color: ${p => p.theme.primary.main};
  text-align: center;
`;

const LabelContainer = styled.div<{ visible: boolean }>`
  width: 100%;
  display: ${p => p.visible ? 'flex' : 'none'};
  flex-wrap: wrap;
  gap: 20px 5px;
`;

const StyledInput = styled.input`
  font-size: 14px;
  font-family: 'DM Sans';
  letter-spacing: 0.4px;
  width: 100%;
  border-radius: 4px;
  color: ${p => p.theme.text.primary};
  background-color: ${p => p.theme.palette.forms.input.background};
  outline: none;
  border: none;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0 10px;

  &:focus {
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }
`;