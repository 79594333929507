import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LabelValuePair } from '@components/core/LabelValuePair';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';

interface IDeviceCardPulseAssignments {
  device: DeviceWithMetrics;
  inSidebar?: boolean;
}

const DeviceCardPulseAssignments = ({ device, inSidebar }: IDeviceCardPulseAssignments) => {
  const { getDeviceConfig } = useDeviceConfigContext();
  const deviceConfig = getDeviceConfig(device.deviceModel);
  const { t } = useTranslation(['molecules']);

  if (!device.pulseAssignments || !deviceConfig?.isPulseCounter) {
    return null;
  }

  return (
    <Wrapper inSidebar={inSidebar}>
      <Title>
        {t('DeviceCard.PulseAssignments', { ns: 'molecules' })}
      </Title>

      <PulseAssignmentContainer>
        {device.pulseAssignments.map((pulseAssignment, index) => (
          <PulseAssignment key={index}>
            <LabelValuePair
              label={t('DeviceCard.Channel', { ns: 'molecules' })}
              value={pulseAssignment.channel && pulseAssignment.channel.split(' ')[1]}
              size="xs"
              valueStyle={{ fontWeight: 500 }}
            />

            <LabelValuePair
              label={t('DeviceCard.PulsesPerUnit', { ns: 'molecules' })}
              value={pulseAssignment.pulsesPerUnit && pulseAssignment.pulsesPerUnit}
              size="xs"
              valueStyle={{ fontWeight: 500 }}
            />

            <LabelValuePair
              label={t('DeviceCard.InitialValue', { ns: 'molecules' })}
              value={pulseAssignment.initialValue && pulseAssignment.initialValue}
              size="xs"
              valueStyle={{ fontWeight: 500 }}
            />
          </PulseAssignment>
        ))}
      </PulseAssignmentContainer>
    </Wrapper>
  );
};

export default DeviceCardPulseAssignments;

const Wrapper = styled.div<{ inSidebar?: boolean }>`
  font-size: 14px;
  padding: ${p => p.inSidebar ? '12px 0' : '16px 24px'};
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Title = styled.div`
  color: ${p => p.theme.palette.text.weak};
  margin-bottom: 20px;
`;

const PulseAssignmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PulseAssignment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;