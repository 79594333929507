import { SiteMetadata } from '@api/models/SiteMetadata';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SiteMetadataGetBySiteIdQuery extends GetQuery<SiteMetadata> {
  id: string;

  constructor(id: string) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/SiteMetadata/${this.id}`;
  }
}
