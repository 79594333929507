import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { GenericObject } from '@utils/ObjectUtils';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';

export type ToggleOption<TValue> = {
  label: string;
  value: TValue;
  analytics?: { button: string, category: string, props?: GenericObject };
};

type ToggleProps<TValue> = {
  selected: TValue;
  options: ToggleOption<TValue>[];
  onChange: (value: TValue) => void;
};

const ToggleV2 = <TValue,>({ selected, options, onChange }: ToggleProps<TValue>) => {
  const { trackAction } = useAnalytics();

  const handleClick = useCallback((option: ToggleOption<TValue>) => {
    onChange(option.value);

    if (option.analytics) {
      trackAction(option.analytics.button, option.analytics.category, option.analytics.props);
    }
  }, [onChange, trackAction]);

  if (options.length === 0) {
    return null;
  }

  return (
    <ToggleWrapper>
      {options.map((option, i) => (
        <ToggleButton
          key={`${option.label}-${i}`}
          selected={option.value === selected}
          onClick={() => handleClick(option)}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleWrapper>
  );
};

export default ToggleV2;

const ToggleWrapper = styled.div`
  height: 30px;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0px 2px 12px 0px ${p => p.theme.shadow.dark};
  background-color: ${p => p.theme.background.container};
  display: flex;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  width: max-content;
  padding: 2.5px 3px;
`;

const ToggleButton = styled.div<{ selected: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 30px;

  font-size: 12px;
  color: ${p => p.theme.text.secondary};
  
  ${({ selected }) => selected && css`
    color: ${p => p.theme.primary.contrast};
    background-color: ${p => p.theme.primary.main};
    transition: all 100ms ease;
  `}
`;