import { ReactNode } from 'react';

export enum TooltipPlacement {
  Top = 'Top',
  TopRight = 'TopRight',
  TopLeft = 'TopLeft',

  Bottom = 'Bottom',
  BottomRight = 'BottomRight',
  BottomLeft = 'BottomLeft',

  Left = 'Left',
  Right = 'Right'
}

export interface ITooltip {
  /**
   * Child components
   */
  children?: ReactNode;
  /**
   * Tooltip content
   */
  content: ReactNode;
  /**
   * Position of popover
   */
  placement?: TooltipPlacement;
  /**
   * Distance of tooltip
   */
  distance?: number;
  /**
    * Fixed position
    */
  fixedPosition?: {
    x: number;
    y: number;
  };
  /**
   * Align the popover with the target div (the triangle will not be centered)
   */
  alignWithTarget?: boolean;
  /**
   * Control visibility from parent component (true = visible, false/undefined = visible on hover)
   */
  forceVisible?: boolean;
  /**
   * Tooltip background color
   */
  tooltipColor?: string;
  /**
   * Font size
   */
  fontSize?: string;
  /**
   * Text color
   */
  fontColor?: string;
  /**
   * Hide triangle
   */
  hideTriangle?: boolean;
  /**
   * Apply secondary background color
   */
  secondary?: boolean;

  /**
   * Styles applied to content
   */
  styles?: React.CSSProperties;

  /**
   * Styles applied to container
   */
  containerStyles?: React.CSSProperties;

  /**
   * Is fixed position
   */
  isFixedPosition?: boolean;
}
