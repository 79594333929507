import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@components/core/Loading';
import LoadingWidget from '@components/core/LoadingWidget';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type WidgetCardProps = {
  icon: IconDefinition;
  title: string;
  titleElement?: ReactNode;
  children: ReactNode;
  loading?: {
    state: boolean;
    icon?: IconDefinition;
    iconColor?: string;
    label?: string;
  };
  styles?: React.CSSProperties;
};

const WidgetCard = ({ icon, title, titleElement, children, loading, styles }: WidgetCardProps) => {
  const { t } = useTranslation();

  const loadingElement = loading?.icon && loading.label
    ? <LoadingWidget icon={loading.icon} iconColor={loading.iconColor} label={loading.label} iconStyles={{ width: 30, height: 30 }} styles={{ padding: '30px 40px 10px' }} />
    : <Loading message={t('Loading', { ns: 'status' })} style={{ padding: '0px 120px' }} />;

  return (
    <StyledCard style={styles}>
      <FlexRow>
        <Icon icon={icon} />

        <Title>
          {title}
        </Title>

        {titleElement}
      </FlexRow>

      {loading?.state &&
        loadingElement
      }

      {!loading?.state &&
        children
      }
    </StyledCard>
  );
};

export default WidgetCard;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${p => p.theme.action.divider};
  background-color: ${(p) => p.theme.background.container};
  box-shadow: 0px 3px 8px ${(p) => p.theme.palette.shadows.medium};
  padding: 16px 20px;
  width: max-content;
  user-select: none;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 17px;
  height: 17px;
  color: ${p => p.theme.palette.primary};
`;

const FlexRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding-bottom: 15px;
`;

const Title = styled.div`
  font-size: 16px;
  color: ${p => p.theme.palette.text.fair};
`;