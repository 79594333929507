import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import EsgExplorerSpaceTypeOptions from '@src/component-configuration-models/esg-explorer/EsgExplorer_SpaceTypeOptions';
import useSiteSpaceTypes from '@hooks/useSiteSpaceTypes';
import { SpaceType } from '@api/enums/SpaceType';
import { CheckboxSelect } from '@components/checkbox-select/CheckboxSelect';
import { ICheckboxSelectGroup } from '@components/checkbox-select/CheckboxSelect.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ISpaceTypeSelect {
  siteId: number;
  config: EsgExplorerConfiguration;
  onChange: (config: EsgExplorerConfiguration) => void;
}

const SpaceTypeSelect = ({ siteId, config, onChange }: ISpaceTypeSelect) => {
  const { t } = useTranslation(['molecules', 'enums']);
  const [spaceTypeOptions, setSpaceTypeOptions] = useState<ICheckboxSelectGroup<SpaceType>[]>([]);
  const { siteSpaceTypes } = useSiteSpaceTypes(siteId);

  useEffect(() => {
    if (!siteSpaceTypes) {
      return;
    }

    const spaceTypeOptions: ICheckboxSelectGroup<SpaceType>[] = [];

    EsgExplorerSpaceTypeOptions.forEach(group => {
      const filteredOptions = group.options.filter(x => siteSpaceTypes.includes(x.value));

      if (filteredOptions.length > 0) {
        spaceTypeOptions.push({
          ...group,
          options: group.options
            .filter(opt => siteSpaceTypes.includes(opt.value))
            .map(opt => ({
              ...opt,
              selected: config.spaceTypes.includes(opt.value),
              label: t(opt.label, { ns: 'enums' })
            }))
        })
      }
    });

    setSpaceTypeOptions(spaceTypeOptions);
  }, [config, siteSpaceTypes, t]);

  return (
    <CheckboxSelect
      options={spaceTypeOptions}
      onChange={(values) =>
        onChange({
          ...config,
          spaceTypes: values
        })
      }
      placeholder={t('ESG.SpaceTypes', { ns: 'molecules' })}
      dropdownMaxHeight={200}
    />
  );
};

export default SpaceTypeSelect;