import { SiteOperatorDto } from '@api/models/SiteOperatorDto';
import { OperatorGetQuery } from '@api/queries/resident-app/common/OperatorGetQuery';

export class SiteOperatorsResidentAppGetBySiteIdQuery extends OperatorGetQuery<SiteOperatorDto> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/SiteOperators/GetBySiteId/${this.siteId}`;
  }
}
