import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { AlertingRuleSeverity } from '@api/enums/AlertingRuleSeverity';
import { Alert } from '@api/models/Alert';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertBannerItem from './AlertBannerItem';
import { darken, transparentize } from 'polished';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type PropTypes = {
  alerts: Alert[],
  severity: AlertingRuleSeverity,
  icon: IconDefinition,
  refreshAlerts: () => void
}

const AlertBanner = ({ alerts, severity, icon, refreshAlerts }: PropTypes) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const theme = useTheme();

  const color = severity === AlertingRuleSeverity.Urgent ? theme.palette.siteOverview.alerts.urgent : theme.palette.siteOverview.alerts.important;

  const handleCollapse = () => {
    setIsCollapsed(prevState => !prevState);
  }

  if (alerts.length === 0) {
    return null;
  }

  if (alerts.length === 1) {
    return (
      <WidgetBanner color={color}>
        <FlexRow>
          <Icon icon={icon} color={color} />
          <Title color={color}>{t(`AlertsWidget.${severity.toString()}Alert`, { ns: 'molecules' })}:</Title>
          <AlertBannerItem alert={alerts[0]} refreshAlerts={refreshAlerts} />
        </FlexRow>
      </WidgetBanner>
    )
  }

  return (
    <WidgetBanner color={color}>
      <FlexRow onClick={handleCollapse} style={{ cursor: 'pointer' }}>
        <Icon icon={icon} color={color} />
        <Title color={color}>{alerts.length} {t(`AlertsWidget.${severity.toString()}Alerts`, { ns: 'molecules' })}</Title>
        <CollapseIcon icon={isCollapsed ? solid('chevron-down') : solid('chevron-up')} />
      </FlexRow>

      <AlertsContainer isShown={!isCollapsed} numAlerts={alerts.length}>
        <AlertList>
          {alerts.map(alert => (
            <AlertListItem key={alert.id}>
              <AlertBannerItem alert={alert} refreshAlerts={refreshAlerts} />
            </AlertListItem>
          ))}
        </AlertList>
      </AlertsContainer>
    </WidgetBanner>
  )
}

export default AlertBanner;

const WidgetBanner = styled.div<{ color: string }>`
  border: 1px solid ${p => p.color};
  background-color: ${p => transparentize(0.89, p.color)};
  border-radius: 5px;
`;

const Title = styled.div<{ color: string }>`
  font-size: 14px;
  font-weight: 500;
  color: ${p => darken(0.1, p.color)};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px 16px;
`;

const CollapseIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.text.fair};
  margin-left: auto;
  margin-top: -4px;
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon) <{ color: string }>`
  font-size: 14px;
  margin-top: -1px;
  color: ${p => p.color};
`;

const AlertsContainer = styled.div<{ isShown: boolean, numAlerts: number }>`
  height: ${p => p.isShown ? `calc(${p.numAlerts * 27}px)` : 0};
  max-height: 138px;
  opacity: ${p => p.isShown ? 1 : 0};
  transition: height 300ms ease;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin: ${p => p.isShown ? '0 16px 12px 24px' : 0};
`;

const AlertList = styled.ul`
  margin: 0;
  padding-inline-start: 17.5px;
  list-style-position: outside;
`;

const AlertListItem = styled.li`
  display: list-item;

  &::marker{
    color: ${p => p.theme.palette.text.fair};
  }

  & + li{
    margin-top: 8px;
  }
`;