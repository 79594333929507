import { Button } from '@components/core/Button';
import { Modal } from '@components/core/Modal';
import { ReactNode, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type DeleteDialogProps = {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  header: ReactNode;
  body: ReactNode;
  sectionThree?: ReactNode;
  isLoading?: boolean;
  onCancel: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirm: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const DeleteDialog = ({ modalRef, isOpen, header, body, sectionThree, isLoading, onCancel, onConfirm }: DeleteDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      hide={onCancel}
      plainModal
      width="470px"
    >
      <Content>
        <SectionOne>
          {header}
        </SectionOne>

        <SectionTwo>
          {body}
        </SectionTwo>

        {sectionThree &&
          <SectionTwo>
            {sectionThree}
          </SectionTwo>
        }

        <Footer>
          <Button
            label={t('Cancel', { ns: 'common' })}
            tertiary
            color={theme.palette.red}
            onClick={onCancel}
          />
          <Button
            label={t('Delete', { ns: 'common' })}
            onClick={onConfirm}
            loading={isLoading}
          />
        </Footer>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionOne = styled.div`
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.2px;
text-align: left;
`;

const SectionTwo = styled.div`
font-size: 14px;
font-weight: 400;
line-height: 20.02px;
letter-spacing: 0.2px;
text-align: left;
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  padding-top: 20px;
`;
