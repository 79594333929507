import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import Marker from './Marker';
import { useSize } from '@hooks/useSize';
import { FloorplanData } from './FloorplanContainer';

type PropTypes = {
  data: FloorplanData;
  blobUri: string;
}

const Floorplan = ({ data, blobUri }: PropTypes) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const { domRect: imageDimensions } = useSize(imageRef);
  const [widthFactor, setWidthFactor] = useState<number>();
  const [heightFactor, setHeightFactor] = useState<number>();

  useEffect(() => {
    if (imageRef && imageDimensions) {
      const imageWidth = imageRef.current?.naturalWidth;
      const imageHeight = imageRef.current?.naturalHeight;

      if (imageWidth && imageHeight) {
        setWidthFactor(imageDimensions.width / imageWidth);
        setHeightFactor(imageDimensions.height / imageHeight);
      }
    }
  }, [imageRef, imageDimensions]);

  return (
    <Wrapper>
      <Image
        src={blobUri}
        ref={imageRef}
      />

      {widthFactor && heightFactor &&
        data.spaces.map(space => (
          <Marker
            key={space.spaceId}
            space={space}
            widthFactor={widthFactor}
            heightFactor={heightFactor}
            config={data.config}
          />
        ))
      }
    </Wrapper>
  );
};

export default Floorplan;

const Wrapper = styled.div`
  position: relative;
  padding: 10px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;