import DeviceStatusCountsDto from '@api/models/DevicesStatusCountsDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DeviceStatusCountsGetBySiteIdQuery extends GetQuery<DeviceStatusCountsDto>{
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/devices/DevicesGetLiveUpdateCountBySite/${this.siteId}`;
  }
}