import SiteTemperatureDto from '@api/models/SiteTemperaturesDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SiteTemperaturesGetQuery extends GetQuery<SiteTemperatureDto> {
  siteId: number;
  minTemperature: number;
  maxTemperature: number;

  constructor(siteId: number, minTemperature: number, maxTemperature: number) {
    super();
    this.siteId = siteId;
    this.minTemperature = minTemperature;
    this.maxTemperature = maxTemperature;
  }

  public targetUrl(): string {

    return `/metrics/SiteTemperature?siteId=${this.siteId}&minTemperature=${this.minTemperature}&maxTemperature=${this.maxTemperature}`;
  }
} 