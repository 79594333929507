import styled from 'styled-components'
import { Input, Label } from '@components/Form'
import AnnualAllowanceInput from './AnnualAllowanceInput'
import { useTranslation } from 'react-i18next'
import { EnergyMeterType_Suffix } from '@api/enums/EnergyMeterType_Suffix'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { getTotalAllowance } from '@pages/site/fair-use/FairUseUtils'
import { FairUseForm } from '@api/models/FairUseForm'
import { SpaceTypeFairUsePolicy } from '@api/models/SpaceTypeFairUsePolicy'
import { round } from '@utils/NumberUtils'

const AnnualAllowanceForm = () => {
  const { t } = useTranslation();
  const { control, register, setValue, getValues, watch } = useFormContext<FairUseForm>();
  const energyMeterType = watch('energyMeterType');
  const { fields: spaceTypePoliciesFields } = useFieldArray({
    control,
    name: 'spaceTypePolicies'
  });

  const handleSpaceTypePolicyChange = (spaceTypeFairUsePolicyIndex: number, updatedValue: number): void => {
    setValue(`spaceTypePolicies.${spaceTypeFairUsePolicyIndex}.allowance`, updatedValue);

    const spaceTypePolicies = getValues('spaceTypePolicies');
    const total = getTotalAllowance(spaceTypePolicies, energyMeterType);
    setValue('total', total);
  }

  return (
    <>
      <AnnualAllowanceWrapper>
        {spaceTypePoliciesFields.filter((spaceTypePoliciesField: SpaceTypeFairUsePolicy) => spaceTypePoliciesField.meterType === energyMeterType && spaceTypePoliciesField.occurrences > 0)
          .map((spaceTypePoliciesField) => {
            const index = spaceTypePoliciesFields.indexOf(spaceTypePoliciesField);
            return (
              <AnnualAllowanceInput
                key={spaceTypePoliciesField.id}
                spaceTypeFairUsePolicy={watch(`spaceTypePolicies.${index}`)}
                energyMeterTypeSuffix={EnergyMeterType_Suffix(energyMeterType)}
                index={index}
                onValueChange={(updatedValue: number) => handleSpaceTypePolicyChange(index, updatedValue)}
              />
            )
          })}
      </AnnualAllowanceWrapper>

      <AnnualAllowanceTotalWrapper>
        <AnnualAllowanceTotalLabel>
          {t('Total', { ns: 'common' })}:
        </AnnualAllowanceTotalLabel>
        <AnnualAllowanceTotalInput disabled={true} value={getValues('total') ? round(getValues('total') * 1000, 2) : 0} />
        <MetricTypeLabel>
          {EnergyMeterType_Suffix(energyMeterType)} =
        </MetricTypeLabel>

        <AnnualAllowanceTotalInput
          {...register('total')}
          type='number'
          disabled={true}
        />

        <TotalMetricTypeLabel>
          MWh
        </TotalMetricTypeLabel>
      </AnnualAllowanceTotalWrapper>
    </>
  )
}

export default AnnualAllowanceForm

const AnnualAllowanceWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10%;
  padding: 15px 20px 0;
`;

const MetricTypeLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
  padding-left: 5px;
`;

const AnnualAllowanceTotalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px 0;
  gap: 10px;
  flex: 1 1 50%;
  margin-left: auto;
  color: ${(p) => p.theme.palette.forms.label.color};
`;

const AnnualAllowanceTotalLabel = styled(Label)`
  font-weight: 500;
  margin: 0;
`;

const AnnualAllowanceTotalInput = styled(Input)`
  height: 30px;
  width: 90px;
  text-align: center;
`;

const TotalMetricTypeLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.palette.forms.label.color};
  margin-bottom: 4px;
`;