import { EnergyMeterBillSiteDto } from '@api/models/EnergyMeterBillSiteDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EnergyMeterBillHelperDataGetQuery extends PostQuery<EnergyMeterBillSiteDto> {
  meterNumber: string;

  constructor(meterNumber: string) {
    super();
    this.meterNumber = meterNumber;
  }

  public targetUrl(): string {
    return '/energyMeters/EnergyMeterBillHelperDataGetByMeterNumber';
  }
}
