import { Route, Routes } from 'react-router-dom';
import { Route as RouteType } from '@src/types/Route';
import { ProtectedRoute } from '@src/navigation/ProtectedRoute/ProtectedRoute';

type PropTypes = {
  routes?: RouteType[],
  customProps?: object
}

const NestedRouteProvider = ({ routes, customProps }: PropTypes) => {

  return (
    <Routes>
      {routes?.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <ProtectedRoute
              route={route}
              customProps={customProps}
            />
          }
        />
      ))}
    </Routes>
  );
};

export default NestedRouteProvider;
