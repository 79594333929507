import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import NoData from '@components/core/NoData';
import { Paging } from '@components/table/Paging';
import { sub } from 'date-fns';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NewNote from './note/NewNote';
import { NoteDto } from '@api/models/NoteDto';
import Note from './note/Note';
import { SortOrder } from '@src/types/SortOrder';

export const shouldUseModifedDate = (note: NoteDto) => note && new Date(note.modifiedOn) > sub(new Date(), { years: 100 });

type PropTypes = {
  notes: (NoteDto)[];
  sortOrder?: SortOrder;
  onCreate: (content: string) => void;
  refreshNotes: () => void;
}

const NoteList = ({ notes, sortOrder = 'desc', onCreate, refreshNotes }: PropTypes) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  /**
   * Sort by the modifiedOn date if that date is newer than "0001-01-01 00:00:00.000000" which is the default value
   * in the database if it has never been updated, otherwise sort by the createdOn date.
   */
  const sortedNotes = useMemo(() => orderBy(notes, x => shouldUseModifedDate(x) ? x.modifiedOn : x.createdOn, sortOrder), [notes, sortOrder]);

  const pagedNotes = useMemo(() => {
    const startIndex = page * pageSize;
    return sortedNotes.slice(startIndex, startIndex + pageSize);
  }, [sortedNotes, page, pageSize]);

  return (
    <>
      <List>
        <NewNote onCreate={onCreate} />

        {pagedNotes.map(note =>
          <Note
            key={note.id}
            note={note as NoteDto}
            refreshNotes={refreshNotes}
          />
        )}
      </List>

      {notes.length === 0 &&
        <NoData
          label={t('NoNotes', { ns: 'status' })}
          icon={duotone('notes')}
          styles={{ margin: '80px auto' }}
        />
      }

      {notes.length > 10 &&
        <PagingWrapper>
          <Paging
            numItems={notes.length}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
          />
        </PagingWrapper>
      }
    </>
  );
};

export default NoteList;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const PagingWrapper = styled.div`
  margin: 20px 0 0 auto;
`;