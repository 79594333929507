import { MetricType } from '@api/enums/MetricType';
import { SpaceWithFloor } from '@api/models/SpaceWithFloor';
import { AlertRule, AlertRuleActionType } from '@api/models/AlertRule';

export enum AlertStatus {
  New = 'New',
  Acknowledged = 'Acknowledged'
}

export type AlertDeviceMessage = {
  deviceId: number;
  friendlyName: string;
  value: string;
  measuredOn: string;
  metricType: MetricType;
};

export type AlertMessage = {
  ruleName: string;
  description: string;
  devices: AlertDeviceMessage[];
};

export type Alert = {
  id: number;
  message: AlertMessage;
  createdOn: string;
  resolvedOn: string | null;
  status: AlertStatus;
  actions: AlertRuleActionType[];
  alertingRule: AlertRule;
  space: SpaceWithFloor;
  spaceId: number;
};