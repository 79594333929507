import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import { MetricType } from '@api/enums/MetricType';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class HeatmapRefetchQuery extends PostQuery<HeatmapSpaceDto[]> {
  spaces: HeatmapSpaceDto[];
  deviceModel?: string;
  metricType?: MetricType;

  constructor(spaces: HeatmapSpaceDto[], deviceModel?: string, metricType?: MetricType) {
    super();
    this.spaces = spaces;
    this.deviceModel = deviceModel;
    this.metricType = metricType;
  }

  public targetUrl(): string {
    return '/metrics/heatmapRefetch';
  }
}