import { PostQuery } from '@api/queries/common/PostQuery';

export class ReportUIBugCommand extends PostQuery<string> {
  pageUrl: string;
  userEmail?: string;
  errorMessage?: string;
  errorStack?: string;
  uiVersion?: string;
  apiVersion?: string;

  constructor(
    pageUrl: string,
    userEmail?: string,
    errorMessage?: string,
    errorStack?: string,
    uiVersion?: string,
    apiVersion?: string
  ) {
    super();
    this.pageUrl = pageUrl;
    this.userEmail = userEmail;
    this.errorMessage = errorMessage;
    this.errorStack = errorStack;
    this.uiVersion = uiVersion;
    this.apiVersion = apiVersion;
  }

  public targetUrl(): string {
    return '/feedback/reportUiBug';
  }
}
