import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@api/enums/MetricType';
import { Permission } from '@api/enums/Permission';
import { IModalSelectGroup } from '@components/core/ModalSelect.types';

const DynamicReportMetricOptions: IModalSelectGroup<MetricType>[] = [
  {
    label: 'Environmental',
    icon: <FontAwesomeIcon icon={solid('leaf')} color='#44A901' />,
    subGroups: [
      {
        options: [
          { label: MetricType.CO2, value: MetricType.CO2 },
          { label: MetricType.Humidity, value: MetricType.Humidity },
          { label: MetricType.IAQ, value: MetricType.IAQ },
          { label: MetricType.Light, value: MetricType.Light },
          { label: MetricType.NO, value: MetricType.NO },
          { label: MetricType.NO2, value: MetricType.NO2 },
          { label: MetricType.Noise, value: MetricType.Noise },
          { label: MetricType.O2, value: MetricType.O2 },
          { label: MetricType.PIR, value: MetricType.PIR },
          { label: MetricType.Particulates2_5, value: MetricType.Particulates2_5 },
          { label: MetricType.Particulates10, value: MetricType.Particulates10 },
          { label: MetricType.Pressure, value: MetricType.Pressure },
          { label: MetricType.Temperature, value: MetricType.Temperature },
          { label: MetricType.VOC, value: MetricType.VOC },
        ]
      }
    ],
  },
  {
    label: 'Metering',
    icon: <FontAwesomeIcon icon={solid('meter-bolt')} color='#FFB500' />,
    subGroups: [
      {
        options: [
          { label: MetricType.ElectricityKwh, value: MetricType.ElectricityKwh },
          { label: MetricType.ElectricityKwhDelta, value: MetricType.ElectricityKwhDelta },
          { label: MetricType.HeatingKwh, value: MetricType.HeatingKwh },
          { label: MetricType.HeatingKwhDelta, value: MetricType.HeatingKwhDelta },
          { label: MetricType.CoolingKwh, value: MetricType.CoolingKwh },
          { label: MetricType.CoolingKwhDelta, value: MetricType.CoolingKwhDelta },
          { label: MetricType.WaterVolume, value: MetricType.WaterVolume },
          { label: MetricType.WaterVolumeDelta, value: MetricType.WaterVolumeDelta }
        ]
      }
    ],
  },
  {
    label: 'Gas',
    icon: <FontAwesomeIcon icon={solid('fire')} color='#00C851' />,
    subGroups: [
      {
        label: 'm³',
        options: [
          { label: MetricType.GasVolume, value: MetricType.GasVolume },
          { label: MetricType.GasVolumeDelta, value: MetricType.GasVolumeDelta }
        ]
      },
      {
        label: 'kWh',
        options: [
          { label: MetricType.GasKwh, value: MetricType.GasKwh },
          { label: MetricType.GasKwhDelta, value: MetricType.GasKwhDelta }
        ]
      }
    ]
  },
  {
    label: 'Occupancy',
    icon: <FontAwesomeIcon icon={solid('people-group')} color='#1B98FF' />,
    subGroups: [
      {
        options: [
          { label: MetricType.CalculatedOccupancy, value: MetricType.CalculatedOccupancy }
        ]
      }
    ],
  },
  {
    label: 'Relay',
    icon: <FontAwesomeIcon icon={solid('sensor')} color='#FF821B' />,
    subGroups: [
      {
        options: [
          { label: MetricType.RelayOutputStatus, value: MetricType.RelayOutputStatus }
        ]
      }
    ],
  },
  {
    label: 'Switch',
    icon: <FontAwesomeIcon icon={solid('light-switch-off')} color='#FF4B5A' />,
    subGroups: [
      {
        options: [
          { label: MetricType.ContactSwitch, value: MetricType.ContactSwitch }
        ]
      }
    ],
  },
  {
    label: 'Device',
    icon: <FontAwesomeIcon icon={solid('sensor-on')} color='#44A901' />,
    requirePermission: Permission.ViewSettings,
    subGroups: [
      {
        options: [
          { label: MetricType.BatteryVoltage, value: MetricType.BatteryVoltage },
          { label: MetricType.SignalStrength, value: MetricType.SignalStrength },
        ]
      }
    ],
  },
];

export default Object.freeze(DynamicReportMetricOptions);
