import EsgExplorerRulePeriod from '@api/enums/EsgExplorerRulePeriod';
import EsgExplorerRuleDto from '@api/models/EsgExplorerRuleDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EsgExplorerRuleCreateCommand extends PostQuery<EsgExplorerRuleDto> {
  siteId: number;
  templateId: number;
  name: string;
  period: EsgExplorerRulePeriod;
  emails: string[];
  enabled: boolean;

  constructor(siteId: number, templateId: number, name: string, period: EsgExplorerRulePeriod, emails: string[], enabled: boolean) {
    super();
    this.siteId = siteId;
    this.templateId = templateId;
    this.name = name;
    this.period = period;
    this.emails = emails;
    this.enabled = enabled;
  }

  public targetUrl(): string {
    return '/esgExplorer/createRule';
  }
}
