import { ErrorMessage, Input, Label } from '@components/Form';
import { Card } from '@components/core/Card';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { stringToNumber } from '@utils/NumberUtils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../SiteMetadataEdit';

type EmissionsEditProps = {
  editMode: boolean;
}

const EmissionsEdit = ({ editMode }: EmissionsEditProps) => {
  const { t } = useTranslation(['settingsAsset', 'currencies']);
  const { register, formState: { errors } } = useFormContext<FormValues>();
  const { getUnit } = useLocalisation();

  return (
    <Card cardTitle={t('Sites.SiteMetadataEditing.Emissions', { ns: 'settingsAsset' })}>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.BaselineCarbonEmission', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}</Label>
          <Input
            {...register('baselineCarbon', {
              pattern: {
                value: /[0-9]/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.BaselineCarbonEmissionPlaceholder', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.baselineCarbon?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.TargetCarbonEmission', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}</Label>
          <Input
            {...register('targetCarbon', {
              pattern: {
                value: /[0-9]/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.TargetCarbonEmissionPlaceholder', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightArea) })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.targetCarbon?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.ElectricityCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightIntensity) })}</Label>
          <Input
            {...register('electricityCarbonIntensity', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.ElectricityCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.electricityCarbonIntensity?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.HeatingCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightIntensity) })}</Label>
          <Input
            {...register('heatingCarbonIntensity', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.HeatingCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.heatingCarbonIntensity?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.WaterCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
          <Input
            {...register('waterCarbonIntensity', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.WaterCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.waterCarbonIntensity?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.WasteWaterCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
          <Input
            {...register('wasteWaterCarbonIntensity', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.WasteWaterCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.wasteWaterCarbonIntensity?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.GasCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2SubWeightArea) })}</Label>
          <Input
            {...register('gasCarbonIntensity', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.GasCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.gasCarbonIntensity?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.CoolingCarbonIntensity', { ns: 'settingsAsset', unit: getUnit(AltUnits.CO2WeightIntensity) })}</Label>
          <Input
            {...register('coolingCarbonIntensity', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.CoolingCarbonIntensityPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.coolingCarbonIntensity?.message}</ErrorMessage>
        </div>
      </div>
    </Card>
  )
}

export default EmissionsEdit