import { useDropzone } from 'react-dropzone';
import { useFileFromBlobStorage } from '@hooks/useFileFromBlobStorage';
import { Loading } from '@components/core/Loading';
import { FileDropzone } from '@components/core/FileDropzone';
import { IBlobFileUploadArea } from './BlobFileUploadArea.types';

export const BlobFileUploadArea = ({ acceptedTypes, blobName, blobContainer, mainText, dimText, isCircle, onFileChange, onFileDelete, containerStyle, imageStyle, readOnly }: IBlobFileUploadArea) => {
  const { blobUri, setBlobUri, loading } = useFileFromBlobStorage(blobName, blobContainer);

  const handleFiles = (files: File[]) => {
    if (files[0]) {
      const url = URL.createObjectURL(files[0]);
      setBlobUri(url);
      onFileChange(files[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: handleFiles,
    accept: acceptedTypes
  });

  const handleOnDelete = () => {
    setBlobUri(undefined);
    onFileDelete();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <FileDropzone
      fileUri={blobUri}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      mainText={mainText}
      dimText={dimText}
      onDelete={handleOnDelete}
      isCircle={isCircle}
      containerStyle={containerStyle}
      imageStyle={imageStyle}
      readOnly={readOnly}
    />
  );
};
