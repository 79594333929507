import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpaceClimateControlDto } from '@api/models/SpaceClimateControlDto';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { usePopover } from '@hooks/usePopover';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type ExceptionsActionsProps = {
  exception: SpaceClimateControlDto;
  onEditException: (exception: SpaceClimateControlDto) => void;
  onDeleteException: (exceptionToDelete: SpaceClimateControlDto) => void;
}

const ExceptionsActions = ({ exception, onEditException, onDeleteException }: ExceptionsActionsProps) => {
  const { t } = useTranslation();
  const { visible, toggle, ref } = usePopover({});
  const theme = useTheme();

  const renderActionsMenu = useCallback(() => {
    return (
      <ActionMenu>
        <ActionLabel onClick={() => onEditException(exception)}>
          {t('Edit', { ns: 'common' })}
        </ActionLabel>
        <ActionLabel onClick={() => onDeleteException(exception)}>
          {t('Delete', { ns: 'common' })}
        </ActionLabel>
      </ActionMenu>
    );
  }, [exception, onDeleteException, onEditException, t])

  return (
    <Popover
      ref={ref}
      visible={visible}
      popoverContent={renderActionsMenu()}
      placement={PopoverPlacement.BottomLeft}
      hideTriangle={true}
      offsetY={-10}
      offsetX={-150}
      containerStyles={{
        boxShadow: `0px 1px 3px 1px ${theme.palette.shadows.extraLight}`,
      }}
    >
      <MenuIcon
        open={visible}
        icon={solid('ellipsis-vertical')}
        onClick={() => toggle()}
      />
    </Popover >
  )
}

export default ExceptionsActions

const ActionMenu = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
`;

const MenuIcon = styled(FontAwesomeIcon) <{ open: boolean }>`
  margin-top: 5px;
  color: ${p => p.open ? p.theme.primary.main : p.theme.action.active};
  cursor: pointer;
  padding: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;

const ActionLabel = styled.div`
  width: 100%;
  padding: 8px 12px;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;