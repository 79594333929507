import SpaceOccupancyDto from '@api/models/SpaceOccupancyDto';
import DoughnutChart from '@components/charts/DoughnutChart';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

type PropTypes = {
  space: SpaceOccupancyDto;
  chartSize?: string;
}

const SpaceOccupancy = ({ space, chartSize }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const difference = space.maxOccupancy - space.currentValue;
  const maxExceeded = difference < 0;

  const dataset = {
    data: [space.currentValue, Math.max(difference, 0)],
    colors: [theme.widget.green, theme.palette.siteOverview.charts.secondary],
    labels: [t('People', { ns: 'common' }), t('Capacity', { ns: 'common' })]
  };

  return (
    <DoughnutChart
      dataset={dataset}
      size={chartSize ?? '120px'}
      cutout="83%"
      arcBorderWidth={2}
      legend={{
        title: (
          <>
            <Link to={`./../building/${space.buildingId}/floor/${space.floorId}/space/${space.spaceId}`}>
              <SpaceName>
                {space.spaceName}
              </SpaceName>
            </Link>

            <BuildingName>
              {space.buildingName}
            </BuildingName>
          </>
        )
      }}
      innerValue={space.currentValue}
      innerSubLabel={`/${space.maxOccupancy}`}
      innerValueStyles={maxExceeded ? { color: theme.palette.red } : {}}
    />
  );
};
export default SpaceOccupancy;

const SpaceName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  width: 116px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BuildingName = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
  padding-top: 2px;
  width: 116px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;