import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { useSpaceContext } from '@pages/site/buildings/floor/space/SpaceProvider';
import { useCallback, useMemo } from 'react';
import NoteList from '../../components/note-list/NoteList';
import { useApi } from '@hooks/useApi';
import { NoteCreateCommand } from '@api/queries/notes/NoteCreateCommand';
import { NoteDiscriminator } from '@api/enums/NoteDiscriminator';
import { Card } from '@components/core/Card';

type PropTypes = {
  device: DeviceWithMetrics;
};

const DevicePage_Notes = ({ device }: PropTypes) => {
  const { execute } = useApi();
  const { deviceNotes, refreshDeviceNotes } = useSpaceContext();
  const notes = useMemo(() => deviceNotes.filter(x => x.discriminatorId === device.id), [deviceNotes, device]);

  const handleCreate = useCallback(async (content: string) => {
    await execute({ query: new NoteCreateCommand(NoteDiscriminator.Device, device.id, content) });
    refreshDeviceNotes();
  }, [device, execute, refreshDeviceNotes]);

  return (
    <Card>
      <NoteList
        notes={notes}
        onCreate={handleCreate}
        refreshNotes={refreshDeviceNotes}
      />
    </Card>
  );
};

export default DevicePage_Notes;