import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlobUri } from '@api/models/BlobUri';
import { NoteAttachmentDto } from '@api/models/NoteAttachmentDto';
import { NoteAttachmentDeleteCommand } from '@api/queries/notes/NoteAttachmentDeleteCommand';
import { NoteAttachmentDownloadCommand } from '@api/queries/notes/NoteAttachmentDownloadCommand';
import { Button } from '@components/core/Button';
import { useApi } from '@hooks/useApi';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

const downloadAttachment = (encodedFileString: BlobUri, fileName: string) => {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = `data:application/octet-stream;charset=utf-16le;base64,${encodedFileString}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

type PropTypes = {
  attachment: NoteAttachmentDto;
  userCanEdit: boolean;
  refreshNotes: () => void;
}

const NoteAttachment = ({ attachment, userCanEdit, refreshNotes }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();

  const handleDelete = useCallback(async () => {
    await execute({
      query: new NoteAttachmentDeleteCommand(attachment.id),
      successMessage: t('DeviceCard.AttachmentDeleted', { ns: 'molecules' }),
      errorMessage: t('DeviceCard.AttachmentDeleteFailed', { ns: 'molecules' }),
    });

    refreshNotes();
  }, [attachment, execute, refreshNotes, t]);

  const handleDownload = useCallback(async () => {
    const response = await execute({
      query: new NoteAttachmentDownloadCommand(attachment.id)
    });

    if (!response) {
      return;
    }

    downloadAttachment(response.encodedFileString, response.fileName);
  }, [attachment, execute]);

  return (
    <AttachmentContainer>
      <FontAwesomeIcon icon={regular('file')} style={{ fontSize: '18px' }} color={theme.palette.primary} />

      <FileName>
        {attachment.fileName}
      </FileName>

      <ActionsContainer>
        <Button
          tertiary
          circle
          label={<FontAwesomeIcon icon={solid('file-arrow-down')} style={{ width: '17px', height: '17px' }} />}
          onClick={handleDownload}
          style={{ padding: '7px' }}
        />

        {userCanEdit &&
          <Button
            tertiary
            circle
            label={<FontAwesomeIcon icon={solid('trash-xmark')} style={{ width: '17px', height: '17px' }} />}
            onClick={handleDelete}
            color={theme.palette.red}
            style={{ padding: '7px' }}
          />
        }
      </ActionsContainer>
    </AttachmentContainer>
  );
}

export default NoteAttachment;

const AttachmentContainer = styled.div`
	display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px 5px 15px;
  box-shadow: 0 1px 0px 0px ${p => p.theme.palette.shadows.medium};
	border: 1px solid ${p => p.theme.palette.borders.weak};
  border-radius: 10px;
`;

const ActionsContainer = styled.div`
	display: flex;
	margin-left: auto;
`;

const FileName = styled.div`
	font-size: 14px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;