import { SiteTemperatureSummaryDto } from '@api/models/SiteTemperatureSummaryDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export class SiteTemperatureSummaryGetQuery extends GetQuery<SiteTemperatureSummaryDto> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/ClimateControl/SiteTemperatureSummary?siteId=${this.siteId}`;
  }
}
