import { useCallback, useEffect, useRef } from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { Chart, Filler, LineController, LinearScale, BarController, BarElement, PointElement, LineElement, ChartTypeRegistry, ChartData, ChartOptions, TooltipItem, CategoryScale } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { AbbreviatedToFullMonthName } from '@utils/DateUtils';
import { numberWithCommas } from '@utils/NumberUtils';

Chart.register(LineController, BarElement, BarController, LinearScale, PointElement, LineElement, CategoryScale);

export type MonthlyConsumptionDataset = {
  label: string,
  dataset: number[],
  dataUnit: string,
  color: string,
};

export interface IMonthlyConsumptionChart {
  /**
   * Chart datasets
   */
  datasets: MonthlyConsumptionDataset[];
  /**
   * Array of labels
   */
  labels?: string[];
  /**
   * Is true when the page is about to open the print dialog, rerender graphs in printable dimensions.
   */
  inPrintMode: boolean;
}

const MonthlyConsumptionChart = ({ datasets, labels, inPrintMode }: IMonthlyConsumptionChart) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, number[], string> => {
    return {
      labels: labels,
      datasets: datasets.map(x => (
        {
          label: x.label,
          data: x.dataset,
          dataUnit: x.dataUnit,
          backgroundColor: x.color,
          borderColor: x.color,
          categoryPercentage: 0.6,
          barPercentage: 0.75,
        }
      )),
    }
  }, [datasets, labels]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const chart = new Chart(context, {
        type: 'bar',
        data: constructData(),
        options: constructOptions(theme, inPrintMode),
        plugins: [Filler],
      });

      return () => chart.destroy();
    }
  }, [theme, inPrintMode, constructData]);

  return (
    <Wrapper inPrintMode={inPrintMode}>
      <canvas id="myChart" ref={canvasRef} />
    </Wrapper>
  );
};

export default MonthlyConsumptionChart;

const Wrapper = styled.div<{ inPrintMode: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;

  ${p => p.inPrintMode && css`
    width: 168mm;
    height: 21mm;
  `}
`;

const constructOptions = (theme: DefaultTheme, inPrintMode: boolean): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: transparentize(0.5, theme.palette.charts.gridLineColor),
          borderWidth: 1,
        },
        ticks: {
          display: !inPrintMode,
          maxRotation: 0,
          padding: 0,
          color: inPrintMode ? theme.palette.printStyles.text.fair : theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: inPrintMode ? 8 : 10,
            weight: '400',
          },
        },
      },
      y: {
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.7, theme.palette.charts.gridLineColor),
        },
        ticks: {
          padding: 5,
          color: inPrintMode ? theme.palette.printStyles.text.fair : theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: inPrintMode ? 8 : 10,
            weight: '400',
          },
          callback: (value: string | number) => {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(Number(value));
          }
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
      bar: {
        borderWidth: 0,
        borderRadius: 5,
      },
      point: {
        radius: 2,
        hoverRadius: 2,
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        displayColors: true,
        usePointStyle: true,
        boxHeight: 10,
        caretPadding: 12,
        caretSize: 7,
        backgroundColor: theme.palette.backgrounds.surface,
        cornerRadius: 3,
        padding: 6,
        borderColor: theme.palette.borders.medium,
        borderWidth: 1,
        titleMarginBottom: 3,
        titleColor: theme.palette.text.fair,
        titleFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        bodyColor: theme.palette.text.medium,
        bodyFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        callbacks: {
          title: (items: TooltipItem<'bar' | 'line'>[]) => {
            const label = items[0]?.label;
            return `${AbbreviatedToFullMonthName(label)}`;
          },
          label: (item: TooltipItem<'bar' | 'line'>) => {
            if (isNaN(item.raw as number)) {
              return ' --'
            }

            const dataset = (item.dataset as unknown as MonthlyConsumptionDataset);
            const unit = dataset.dataUnit;

            return ` ${numberWithCommas(item.raw as number, 0)} ${unit}`;
          }
        },
      },
      legend: {
        display: false,
      },
    }
  };
}