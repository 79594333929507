import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@components/Form';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import React, { HTMLInputTypeAttribute } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

type InputFormFieldProps = {
  label?: string;
  inputId?: string;
  inputName: string;
  inputType?: HTMLInputTypeAttribute;
  value?: string | number;
  pattern?: RegExp;
  patternErrorMessage?: string;
  placeholder?: string;
  tooltip?: string;
  readOnly?: boolean;
  isRequired?: boolean;
  requiredErrorMessage?: string;
  min?: string | number;
  max?: number;
  maxLength?: number;
  onValidate?: (value: string) => boolean | string;
  error?: boolean;
  style?: React.CSSProperties;
};

const InputFormField = ({
  label,
  inputId,
  inputName,
  value,
  pattern,
  patternErrorMessage,
  placeholder,
  tooltip,
  inputType = 'text',
  readOnly,
  isRequired,
  requiredErrorMessage,
  min,
  max,
  maxLength,
  onValidate,
  error,
  style
}: InputFormFieldProps) => {
  const theme = useTheme();
  const { register } = useFormContext();
  const { t } = useTranslation('molecules');
  
  return (
    <div>
      {label && (
        <Label htmlFor={inputName}>
          {label}
          {tooltip && (
            <InfoIconWrapper>
              <Tooltip
                content={tooltip}
                placement={TooltipPlacement.Bottom}
                fontSize="14px"
                tooltipColor={theme.palette.tooltip.background.primary}
                styles={{
                  padding: 10,
                  maxWidth: 215,
                }}
              >
                <FontAwesomeIcon
                  icon={regular('circle-info')}
                  color={theme.palette.text.fair}
                  size="sm"
                />
              </Tooltip>
            </InfoIconWrapper>
          )}
        </Label>
      )}

      <StyledInput
        style={style}
        error={error}
        id={inputId ?? inputName}
        placeholder={placeholder}
        type={inputType}
        readOnly={readOnly}
        {...register(inputName, {
          value: value,
          required: isRequired && {
            value: true,
            message: requiredErrorMessage ?? t('RequiredField', { ns: 'validation' }),
          },
          pattern: pattern && {
            value: pattern,
            message: patternErrorMessage ?? t('InvalidInput', { ns: 'validation' }),
          },
          min: min && {
            value: min,
            message: t('InvalidMinNumber', { ns: 'validation', min: min }),
          },
          max: max && {
            value: max,
            message: t('InvalidMaxNumber', { ns: 'validation', max: max }),
          },
          maxLength: maxLength && {
            value: maxLength,
            message: t('InvalidLength', { ns: 'validation', maxLength: maxLength }),
          },
          validate: onValidate
        })}
      />
    </div>
  );
};

export default InputFormField;

const Label = styled.label`
  font-size: 14px;
  color: ${(p) => p.theme.palette.forms.label.color};
  display: block;
  margin-bottom: 8px;
`;

const StyledInput = styled(Input)<{ readOnly?: boolean, error?: boolean }>`
  border: 1px solid ${(p) => p.error ? p.theme.error.main : p.theme.palette.forms.input.border};

  ${(p) => p.readOnly && css`
    background-color: ${p.theme.palette.forms.input.disabledDarkBackground};
    cursor: not-allowed;
    padding-left: 10px;
  `}
`;

const InfoIconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  padding-left: 5px;
`;