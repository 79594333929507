import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Route as RouteType } from '@src/types/Route';
import { ProtectedRoute } from '@src/navigation/ProtectedRoute/ProtectedRoute';
import Header from './components/Header';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useEffect, useState } from 'react';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { BuildingWithFloors } from '@api/models/BuildingWithFloors';

type PropTypes = {
  routes?: RouteType[];
}

const BuildingManagement = ({ routes }: PropTypes) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { site, buildings } = useSiteContext();
  const { buildingId } = useParams<{ buildingId: string }>();
  const [building, setBuilding] = useState<BuildingWithFloors>();

  // Handle change of buildingId param in URL
  useEffect(() => {
    if (buildingId) {
      const building = buildings.find(x => x.id === parseInt(buildingId));
      setBuilding(building);
    }
  }, [buildingId, buildings]);

  // Navigate to first child route
  useEffect(() => {
    const path = routes?.[0].link?.path;
    if (location.pathname.split('/').pop() === 'settings' && path) {
      navigate(path, { replace: true });
    }
  }, [location, routes, navigate]);

  if (!building) {
    return null;
  }

  return (
    <div>
      <Header routes={routes} />

      <PaddedContainer>
        <Routes>
          {routes?.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute
                  route={route}
                  customProps={{ site, building }}
                />
              }
            />
          ))}
        </Routes>
      </PaddedContainer>
    </div>
  );
};

export default BuildingManagement;