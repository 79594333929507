import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled, { css, useTheme } from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FilterOptionGroup } from './Filter';
import { SearchField } from '@components/search-field/SearchField';
import { useCallback, useState } from 'react';
import { includesCI } from '@utils/StringUtils';
import NoData from '@components/core/NoData';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/core/Button';
import { v4 as uuidv4 } from 'uuid';

const hasNoOptions = <TValue,>(options: FilterOptionGroup<TValue>[], searchFilter: (x: string) => boolean) => {
  let filteredGroups = options.map(g => ({ ...g, options: g.options.filter(x => searchFilter(x.label)) }));
  filteredGroups = filteredGroups.filter(g => g.options.length > 0);
  return filteredGroups.length === 0;
}

type PropTypes<TValue> = {
  options: FilterOptionGroup<TValue>[];
  onSelect: (groupIndex: number, index: number) => void;
  selectAll: () => void;
  clear: () => void;
}

export const FilterDropdown = <TValue,>({ options, onSelect, selectAll, clear }: PropTypes<TValue>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchString, setSearchString] = useState<string>('');

  const searchFilter = useCallback((x: string) => {
    return includesCI(x, searchString);
  }, [searchString]);

  const hasSelected = options.flatMap(x => x.options).some(x => x.selected);

  return (
    <FlexColumn>
      <HeaderRow>
        <SearchField
          placeholder={t('Search', { ns: 'common' })}
          onSearchChange={setSearchString}
          style={{ flexGrow: 1 }}
        />

        <ButtonRow>
          {hasSelected &&
            <Button
              tertiary
              label={t('Clear', { ns: 'common' })}
              onClick={clear}
              color={theme.palette.red}
              style={{ flexShrink: 0, padding: 8, fontSize: 14 }}
            />
          }

          <Button
            tertiary
            label={t('SelectAll', { ns: 'common' })}
            onClick={selectAll}
            style={{ flexShrink: 0, padding: 8, fontSize: 14 }}
          />
        </ButtonRow>
      </HeaderRow>

      <OptionsWrapper>
        {hasNoOptions(options, searchFilter) &&
          <NoData
            label={t('NoOptions', { ns: 'status' })}
            styles={{ margin: '20px 0' }}
          />
        }

        {options.map((group, i) => {
          if (!group.options.some(x => searchFilter(x.label))) {
            return null;
          }

          return (
            <div key={uuidv4()}>
              {group.label &&
                <GroupLabel>
                  {group.icon &&
                    <GroupIcon>
                      {group.icon}
                    </GroupIcon>
                  }

                  {group.label}
                </GroupLabel>
              }

              {group.options.map((option, j) => searchFilter(option.label) && (
                <Option key={uuidv4()} selected={option.selected} onClick={() => onSelect(i, j)}>
                  <Checkbox selected={option.selected}>
                    {option.selected &&
                      <CheckIcon icon={solid('check')} />
                    }
                  </Checkbox>

                  <FlexRow>
                    <Label>
                      {option.displayElement ?? option.label}
                    </Label>
                  </FlexRow>
                </Option>
              ))}
            </div>
          );
        })}

      </OptionsWrapper>
    </FlexColumn>
  );
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OptionsWrapper = styled.div`
  overflow: hidden auto;
  height: auto;
  max-height: 240px;
  padding: 4px 0;
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 16px 10px 16px;
  border-bottom: 1px solid ${p => transparentize(0.5, p.theme.action.divider)};
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Label = styled.div`
  width: calc(100% - 15px);
  font-size: 14px;
  line-height: 18px;
  color: ${p => p.theme.palette.text.medium};
  padding-right: 5px;
  cursor: pointer;
`;

const Option = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  border-left: 3px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.select.hover};
    border-left-color: ${p => p.theme.primary.main};
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 11px;
  color: ${p => p.theme.palette.text.onPrimary};
`;

const Checkbox = styled.div<{ selected?: boolean }>`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border: 1px solid ${p => p.theme.palette.cascader.checkboxBorder};
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  ${p => !p.selected && css`
    &:hover {
      border-color: ${p => transparentize(0.3, p.theme.palette.primary)};
    }
  `}

  transition: border-color 150ms ease;

  ${p => p.selected && css`
    border-color: ${p.theme.palette.primary};
    background-color: ${p.theme.palette.primary};
  `}
`;

const GroupLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  padding: 8px 19px;
`;

const GroupIcon = styled.div`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  text-align: center;
`;