import { BackButton } from '@components/core/BackButton';
import { Title } from '@components/core/Title';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type TitleWithButtonProps = {
  title: string;
  titleSize?: 'xs' | 'sm' | 'md' | 'lg';
  button?: ReactNode;
  backButtonUrl?: string;
  style?: React.CSSProperties;
}

const TitleWithButton = ({title, titleSize='lg', button, backButtonUrl, style}: TitleWithButtonProps) => {
  const { t } = useTranslation('common');
  return (
    <>
      {backButtonUrl && <BackButton label={t('Back')} url={backButtonUrl} />}
      <Header style={style}>
        <Title text={title} size={titleSize} />
        {button && <>{button}</>}
      </Header>
    </>
  ) 
}

export default TitleWithButton;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;