import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@hooks/useMediaQuery';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

export type Steps = {
  step: number;
  title: string;
}

type StepperProps = {
  /**
   * Steps to display in the stepper
   */
  steps: Steps[];
  /**
   * Step number the stepper is on
   */
  activeStep: number;
  /**
   * Width of the stepper
   */
  stepperWidth?: string;
  /**
   * Style of the stepper
   */
  style?: React.CSSProperties;
};

const Stepper = ({ steps, activeStep, stepperWidth, style }: StepperProps) => {
  const numChildren = steps.length;
  const smallScreen = useMediaQuery('(max-width: 900px)');
  const mobileScreen = useMediaQuery('(max-width: 600px)');

  const renderStepTitle = (step: Steps, index: number) => {
    if (mobileScreen) {
      if (activeStep === index + 1) {
        return (
          <StepTitle
            $active={activeStep === index + 1}
            $completed={activeStep > index + 1}
          >
            {step.title}
          </StepTitle>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <StepTitle
          $active={activeStep === index + 1}
          $completed={activeStep > index + 1}
        >
          {step.title}
        </StepTitle>
      );
    }
  };

  return (
    <StepperWrapper $width={stepperWidth} style={style}>
      {numChildren > 1 && (
        <Steps>
          {steps.map((step, index) =>
            <StepWrapper key={uuidv4()}>
              <Step
                $active={activeStep === index + 1}
                $completed={activeStep > index + 1}
              >
                {(activeStep > index + 1) ?
                  <Tick icon={faCheck} /> :
                  <span>{index + 1}</span>
                }
              </Step>
              {renderStepTitle(step, index)}
              {smallScreen || index + 1 !== numChildren && <HorizontalDivider />}
            </StepWrapper>
          )}
        </Steps>
      )}
    </StepperWrapper>
  );
};

export default Stepper;

const StepperWrapper = styled.div<{ $width?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: ${(props) => props.$width ? `${props.$width}` : '100%'};
`;

const StepWrapper = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center; 
  justify-content: space-between;
`;

const Steps = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: fit-content;

  ${StepWrapper}:first-child{
    padding: 0 5px 0 0;
  }

  ${StepWrapper}:last-child{
    padding: 0 0 0 5px;
  }
`;

const Step = styled.div<{ $active: boolean, $completed: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.palette.backgrounds.background};
  border: 2px solid ${(p) => p.theme.palette.borders.strong};
  color: ${(p) => p.theme.text.secondary};
  font-size: 12px;

  ${p => p.$active && css`
    background-color: ${p.theme.palette.primary};
    border: 2px solid ${p.theme.palette.primary};
    color: ${p.theme.palette.borders.weak};
  `} 

  ${p => p.$completed && css`
  background-color: ${p.theme.palette.primary};
    border: 2px solid ${p.theme.palette.primary};
    color: ${p.theme.palette.borders.weak};
  `}
`;

const Tick = styled(FontAwesomeIcon)`
    width: 60%;
    height: 60%;
    color: ${(p) => p.theme.palette.text.onPrimary};
`;

const StepTitle = styled.div<{ $active: boolean, $completed: boolean }>`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${(p) => p.$active || p.$completed ? p.theme.text.primary : p.theme.text.secondary};
  padding: 0 10px;
`;

const HorizontalDivider = styled.div`
  height: 0;
  width: 50px;
  border-bottom: 1px solid ${(p) => p.theme.palette.borders.strong};
`;
