import EsgExplorerRuleDto from '@api/models/EsgExplorerRuleDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EsgExplorerRuleUpdateCommand extends PostQuery<EsgExplorerRuleDto> {
  ruleId: number;
  enabled: boolean;

  constructor(ruleId: number, enabled: boolean) {
    super();
    this.ruleId = ruleId;
    this.enabled = enabled;
  }

  public targetUrl(): string {
    return '/esgExplorer/updateRule';
  }
}
