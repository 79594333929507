import { Alert } from '@api/models/Alert';
import { faAlarmExclamation, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowRight } from '@fortawesome/pro-light-svg-icons';
import { LabelValuePair } from '@components/core/LabelValuePair';
import AlertingRuleActionIcon from './AlertRuleActionIcon';
import { transparentize } from 'polished';
import { format } from 'date-fns';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ILocationLink {
  label: string;
  value: string;
  url: string;
}

const createLocationLinks = (alert: Alert, t: TFunction): ILocationLink[] => {
  return [
    {
      label: t('TopBar.Alerts.Space', { ns: 'commonApp' }),
      value: alert.space.name,
      url: `/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}/floor/${alert.space.floor.id}/space/${alert.spaceId}`
    },
    {
      label: t('TopBar.Alerts.Floor', { ns: 'commonApp' }),
      value: alert.space.floor.name,
      url: `/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}/floor/${alert.space.floor.id}`
    },
    {
      label: t('TopBar.Alerts.Building', { ns: 'commonApp' }),
      value: alert.space.floor.building.name,
      url: `/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}`
    },
    {
      label: t('TopBar.Alerts.Site', { ns: 'commonApp' }),
      value: alert.space.floor.building.site.name,
      url: `/site/${alert.space.floor.building.siteId}`
    }
  ];
};

type AlertModalSidebarProps = {
  alert: Alert;
  onMobile: boolean;
  closeModal: () => void;
}

const AlertModalSidebar = ({ alert, onMobile, closeModal }: AlertModalSidebarProps) => {
  const { t } = useTranslation(['commonApp']);
  const locationLinks = createLocationLinks(alert, t);

  return (
    <Column $onMobile={onMobile}>
      <Dividerlabel>{t('TopBar.Alerts.Details', { ns: 'commonApp' })}</Dividerlabel>

      <Label>
        {t('TopBar.Alerts.Created', { ns: 'commonApp' })}<CreatedOnIcon icon={faAlarmExclamation} />
        <Value>
          {format(new Date(alert.createdOn), 'dd. MMM yyy, hh:mmaaa')}
        </Value>
      </Label>

      <Label>
        {t('TopBar.Alerts.Acknowledged', { ns: 'commonApp' })}{alert.resolvedOn && <AcknowledgedOnIcon icon={faCircleCheck} />}
        <Value>
          {alert.resolvedOn ? format(new Date(alert.resolvedOn), 'dd. MMM yyy, hh:mmaaa') : '--'}
        </Value>
      </Label>

      <Label>
        {t('TopBar.Alerts.Actions', { ns: 'commonApp' })}
        <div style={{ display: 'flex', gap: '7px', marginTop: '2px' }}>
          {alert.actions.map((action, i) => (
            <AlertingRuleActionIcon action={action} key={i} />
          ))}
        </div>
      </Label>

      <Dividerlabel>{t('TopBar.Alerts.Location', { ns: 'commonApp' })}</Dividerlabel>

      {locationLinks.map((locationLink, i) => (
        <Link
          key={i}
          to={locationLink.url}
          onClick={closeModal}
        >
          <LocationLink>
            <LabelValuePair
              label={locationLink.label}
              value={locationLink.value}
              size="xs"
              smallGap
            />
            <ArrowIcon icon={faCircleArrowRight} />
          </LocationLink>
        </Link>
      ))}
    </Column>
  );
};

export default AlertModalSidebar;

const Column = styled.div<{ $onMobile: boolean }>`
  position: relative;
  width: 240px;
  border-right: 1px solid ${p => p.theme.palette.borders.medium};
  padding: 25px 30px;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 20px;

  ${p => p.$onMobile && css`
    order: 2;
    width: 100%;
    border: none;
  `}
`;

const Label = styled.div<{ inline?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const Value = styled.div<{ inline?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.medium};
`;

const Dividerlabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px dashed ${p => p.theme.palette.borders.medium};
`;

const LocationLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;


const CreatedOnIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => transparentize(0.1, p.theme.palette.red)};
  margin-left: 5px;
  margin-bottom: 1px;
`;

const AcknowledgedOnIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => transparentize(0, p.theme.palette.primary)};
  margin-left: 5px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
  margin-top: 3px;
  color: ${p => transparentize(0.5, p.theme.palette.text.weak)};

  ${LocationLink}:hover & {
    color: ${p => p.theme.palette.primary};
  }
`;