import { Feature } from '@api/models/Feature';
import { Tenant } from '@api/models/Tenant';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Card } from '@components/core/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { transparentize } from 'polished';
import FeatureTenantList from './FeatureTenantList';
import { useApi } from '@hooks/useApi';
import { featureIsEnabled } from './FeatureTenantListItem';
import { TenantFeature } from '@api/models/TenantFeature';
import { TenantFeatureBulkDeleteCommand } from '@api/queries/features/TenantFeatureBulkDeleteCommand';
import { TenantFeatureBulkCreateCommand } from '@api/queries/features/TenantFeatureBulkCreateCommand';
import { useTranslation } from 'react-i18next';
import { alphabeticalSort } from '@utils/StringUtils';
import { WarningDialog } from '@components/core/WarningDialog';
import { useModal } from '@hooks/useModal';
import TextHighlighter from '@components/table/TextHighlighter';

interface IFeatureListItem {
  tenants: Tenant[];
  feature: Feature;
  refresh: () => void;
  searchString: string;
}
const FeatureListItem = ({ tenants, feature, refresh, searchString }: IFeatureListItem) => {
  const { t } = useTranslation(['settingsFeatures']);
  const { execute, loading } = useApi();
  const [isOpen, setIsOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const { isOpen: dialogIsOpen, toggle: toggle, ref } = useModal({});
  const isVisible = feature.name.toLowerCase().includes(searchString) || feature.description.toLowerCase().includes(searchString);

  const handleEnableForAll = useCallback(async () => {
    const tenantFeatures: TenantFeature[] = tenants
      .filter(tenant => !featureIsEnabled(tenant, feature))
      .map(tenant => ({ tenantId: tenant.tenantId, featureId: feature.id }));

    if (tenantFeatures.length === 0) {
      return;
    }

    await execute({
      query: new TenantFeatureBulkCreateCommand(tenantFeatures),
      successMessage: t('FeatureEnabledForAll', { ns: 'settingsFeatures' })
    });

    refresh();
  }, [tenants, feature, execute, refresh, t]);

  const handleDisableForAll = useCallback(async () => {
    const tenantFeatures: TenantFeature[] = tenants
      .filter(tenant => featureIsEnabled(tenant, feature))
      .map(tenant => ({ tenantId: tenant.tenantId, featureId: feature.id }));

    if (tenantFeatures.length === 0) {
      return;
    }

    await execute({
      query: new TenantFeatureBulkDeleteCommand(tenantFeatures),
      successMessage: t('FeatureDisabledForAll', { ns: 'settingsFeatures' })
    });

    refresh();
  }, [tenants, feature, execute, refresh, t]);

  return (
    <Container visible={isVisible}>
      <WarningDialog
        modalRef={ref}
        isOpen={dialogIsOpen}
        sectionOne={isEnabled ? t('EnableAllTenantsDialog', { ns: 'settingsFeatures', feature: feature.name }) : t('DisableAllTenantsDialog', { ns: 'settingsFeatures', feature: feature.name })}
        confirmButton={t('Confirm', { ns: 'common' })}
        onCancel={toggle}
        onConfirm={() => {
          isEnabled ? handleEnableForAll() : handleDisableForAll();
          toggle();
        }}
      />

      <Card noPadding>
        <Header onClick={() => setIsOpen(prevState => !prevState)}>
          <FlagIcon icon={solid('flag')} />

          <Name>
            <TextHighlighter
              text={feature.name}
              highlight={searchString}
            />
          </Name>

          <Description>
            <TextHighlighter
              text={feature.description}
              highlight={searchString}
            />
          </Description>

          <FlexRow>
            <ToggleButton onClick={e => {
              e.stopPropagation();

              if (!loading) {
                setIsEnabled(true)
                toggle();
              }
            }}>
              {t('Enable', { ns: 'settingsFeatures' })}
            </ToggleButton>
            /
            <ToggleButton onClick={e => {
              e.stopPropagation();

              if (!loading) {
                setIsEnabled(false)
                toggle();
              }
            }}>
              {t('Disable', { ns: 'settingsFeatures' })}
            </ToggleButton>
          </FlexRow>
        </Header>
      </Card>

      <Collapsible isOpen={isOpen}>
        <FeatureTenantList
          feature={feature}
          tenants={tenants.sort((a, b) => alphabeticalSort(a.name, b.name))}
          refresh={refresh}
        />
      </Collapsible>
    </Container>
  );
};

export default FeatureListItem;

const Container = styled.div<{ visible: boolean }>`
  height: ${p => p.visible ? 'auto' : '0px'};
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  border-left: 5px solid ${p => p.theme.palette.primary};
  border-radius: 5px 0 0 5px;
`;

const Collapsible = styled.div<{ isOpen: boolean }>`
  display: ${p => p.isOpen ? 'block' : 'none'};
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};

  @media (min-width: 600px) {
    flex-shrink: 0;
    width: 150px;
  }

  @media (min-width: 900px) {
    width: 250px;
  }
`;

const Description = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.fair};
`;

const FlagIcon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  padding: 7px;
  margin-right: 5px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  transition: all 150ms ease;

  color: ${p => transparentize(0.3, p.theme.palette.primary)};
  background-color: ${p => transparentize(0.3, p.theme.palette.buttons.hoverBackground)};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  font-size: 12px;
  font-weight: 500;
  color: ${p => transparentize(0.4, p.theme.palette.text.fair)};
`;

const ToggleButton = styled.div`
  padding: 2px 7px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;