import Diagnostics from './Diagnostics';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';

type PropTypes = {
  device: DeviceWithMetrics;
};

const DevicePage_Diagnostics = ({ device }: PropTypes) => {
  return (
    <Diagnostics device={device} />
  );
};

export default DevicePage_Diagnostics;
