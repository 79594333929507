import React, { ReactNode, useEffect, useContext, useState, useMemo, useCallback } from 'react';
import { darkTheme, lightTheme, ThemeType } from '@theme/theme';
import { getCookieConsent } from '@components/consent-banner/ConsentBanner';

const THEME_STORAGE_KEY = 'UtopiConnectTheme';

interface IThemeContext {
  theme: ThemeType;
  toggleTheme: () => void;
  isDarkTheme: boolean;
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  setSidebarOpen: (state: boolean) => void;
}

export const ThemeContext = React.createContext({} as IThemeContext);
export const useThemeContext = () => useContext(ThemeContext);

export const CustomThemeProvider = ({ children }: { children?: ReactNode }) => {
  const [theme, setTheme] = useState<ThemeType>(lightTheme);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

  const toggleTheme = useCallback(() => {
    if (theme === lightTheme) {
      // only set local storage if user has consented
      if (getCookieConsent()?.functional) {
        window.localStorage.setItem(THEME_STORAGE_KEY, darkTheme.name);
      }

      setTheme(darkTheme);
    } else {
      // only set local storage if user has consented
      if (getCookieConsent()?.functional) {
        window.localStorage.setItem(THEME_STORAGE_KEY, lightTheme.name);
      }

      setTheme(lightTheme);
    }
  }, [theme]);

  const themeContextValue = useMemo(() => (
    {
      theme,
      toggleTheme: toggleTheme,
      isDarkTheme: theme.name === 'Dark',
      sidebarOpen,
      toggleSidebar: () => setSidebarOpen(!sidebarOpen),
      setSidebarOpen: (state: boolean) => setSidebarOpen(state)
    }
  ), [sidebarOpen, theme, toggleTheme]);

  useEffect(() => {
    const themeName = window.localStorage.getItem(THEME_STORAGE_KEY);

    if (themeName === darkTheme.name) {
      setTheme(darkTheme);
    } else {
      setTheme(lightTheme);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={themeContextValue}
    >
      {children}
    </ThemeContext.Provider>
  );
};
