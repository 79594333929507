import { round } from '@utils/NumberUtils';
import styled from 'styled-components';

type PropTypes = {
  height: number
}

const BackgroundPattern = ({ height }: PropTypes) => {
  const x = 6;
  const y = round(height / 14);
  const rows = Array.from(Array(y).keys());
  const columns = Array.from(Array(x).keys());

  return (
    <Column>
      {rows.map((_, y) => (
        <FlexRow key={y}>
          {columns.map((_, x) => (
            <Square key={`${y}_${x}`} x={x} y={y} />
          ))}
        </FlexRow>
      ))}
    </Column>
  );
};

export default BackgroundPattern;

const Column = styled.div`
  width: 150px;
  position: absolute;
  right: 0;
  top: 0;
  margin: -30px 5px 0 0;

  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 1;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Square = styled.div.attrs<{ x: number, y: number }>(p => ({
  style: {
    opacity: `${p.x * 0.2}`,
    width: `${2 + (p.y * 0.5)}px`,
    height: `${2 + (p.y * 0.5)}px`,
    // borderRadius: `${3 - (p.y * 0.5)}px`
  }
})) <{ x: number, y: number }>`
  background-color: ${p => p.theme.primary.main};
`;