import { ChartOptions, ChartTypeRegistry, TimeUnit } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';
import { LocalisationType } from '@i18n//localisation';
import { TimeRangeType } from '@components/time-range-select/TimeRangeSelect';
import { externalTooltipHandler, TooltipContext } from './externalTooltipHandler';

const getXAxisTimeUnit = (timeRangeType: TimeRangeType, localisation: LocalisationType): {
  unit: false | TimeUnit,
  displayFormats: _DeepPartialObject<{ [key: string]: string; }>;
} => {
  switch (timeRangeType) {
    case TimeRangeType.Year:
      return {
        unit: 'month',
        displayFormats: {
          month: 'MMM',
        }
      };
    case TimeRangeType.Month:
      return {
        unit: 'day',
        displayFormats: {
          day: 'd',
        }
      };
    default:
      return {
        unit: 'day',
        displayFormats: localisation.timeDisplayFormats()
      };
  }
}

export const constructChartOptions = (theme: DefaultTheme, localisation: LocalisationType, timeRangeType: TimeRangeType, smallRange: boolean, xAxisMin?: string, xAxisMax?: string): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500
    },
    hover: {
      mode: 'nearest',
      intersect: false
    },
    scales: {
      x: {
        type: 'time',
        time: getXAxisTimeUnit(timeRangeType, localisation),
        min: xAxisMin,
        max: xAxisMax,
        grid: {
          borderColor: theme.action.divider,
          borderWidth: 1,
          tickLength: 5,
          drawOnChartArea: false
        },
        ticks: {
          display: true,
          autoSkipPadding: smallRange ? 50 : undefined,
          maxRotation: 0,
          padding: 8,
          color: theme.text.secondary,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '400',
          },
        },
      },
      y: {
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.5, theme.action.divider),
        },
        ticks: {
          padding: 10,
          maxTicksLimit: 10,
          color: theme.text.secondary,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '400',
          }
        },
      },
    },
    elements: {
      line: {
        borderWidth: 1,
        tension: 0.3
      },
      bar: {
        borderWidth: 0,
        borderRadius: 5,
      },
      point: {
        radius: 0,
        hoverRadius: 3,
        hoverBorderWidth: 7
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        mode: 'nearest',
        intersect: false,
        external: (context: TooltipContext) => externalTooltipHandler(context, theme, localisation)
      },
      legend: {
        display: false
      }
    }
  };
}