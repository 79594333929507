import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MetricType } from '@api/enums/MetricType';
import HeatmapQuery from '@api/queries/metrics/HeatmapQuery';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { Title } from '@components/core/Title';
import { Card } from '@components/core/Card';
import HeatmapScale from '@pages/site/heatmap/components/HeatmapScale';
import Heatmap from '@pages/site/heatmap/Heatmap';
import { useApi } from '@hooks/useApi';
import HeatmapMetricSelect from '@pages/site/heatmap/components/selects/MetricSelect';
import DeviceModelSelect from '@pages/site/heatmap/components/selects/DeviceModelSelect';
import { DeviceModelWithMetricTypes } from '@api/models/DeviceModelWithMetricTypes';
import HeatmapLegend from '@pages/site/heatmap/components/HeatmapLegend';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { IHeatmapConfig, getHeatmapMetricConfig } from '@src/component-configuration-models/heatmap/HeatmapConfig';
import { HeatmapDefaultDeviceModel, HeatmapDefaultMetricType } from '@src/component-configuration-models/heatmap/HeatmapDefaultSettings';
import { HorizontalNavbarHeight, TopBarHeight } from '@src/constants/designConstants';
import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import HeatmapRefetchQuery from '@api/queries/metrics/HeatmapRefetchQuery';
import { unionBy } from 'lodash';
import BuildingSelect from '@pages/site/heatmap/components/selects/BuildingSelect';
import { BuildingWithFloors } from '@api/models/BuildingWithFloors';
import { Label } from '@components/Form';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { Button } from '@components/core/Button';
import { convertToCSV, exportCSV } from '@utils/ExportUtils';
import { format } from 'date-fns';
import dayjs from 'dayjs';

export type HeatmapConfig = {
  building?: BuildingWithFloors;
  model?: DeviceModelWithMetricTypes;
  metricType?: MetricType;
};

export type HeatmapData = {
  spaces: HeatmapSpaceDto[];
  config: HeatmapConfig;
};

const HeatmapPage = () => {
  const { t } = useTranslation();
  const { execute, loading } = useApi();
  const { execute: refetch, loading: loadingRefetch } = useApi();
  const { toLocale, getUnit, localisation, displayString } = useLocalisation();
  const [loadingModels, setLoadingModels] = useState(false);
  const [config, setConfig] = useState<HeatmapConfig>({});
  const [data, setData] = useState<HeatmapData>();
  const [heatmapMetricConfig, setHeatmapMetricConfig] = useState<IHeatmapConfig>();

  useEffect(() => {
    if (config?.metricType) {
      setHeatmapMetricConfig(getHeatmapMetricConfig(config.metricType, toLocale, getUnit));
    }
  }, [config, getUnit, toLocale]);

  const refetchData = useCallback(async (spaces: HeatmapSpaceDto[], config: HeatmapConfig) => {
    if (!config.model || !config.metricType) {
      return;
    }

    const spacesToRefetch = spaces.filter(x => x.fetchOlderMeasurement);

    if (spacesToRefetch.length === 0) {
      return;
    }

    const refetchedSpaces = await refetch({
      query: new HeatmapRefetchQuery(spacesToRefetch, config.model.deviceModel, config.metricType)
    });

    if (refetchedSpaces) {
      const merged = unionBy(refetchedSpaces, spaces, 'spaceId');
      setData({ spaces: merged, config });
    }
  }, [refetch]);

  const exportDataToCsv = useCallback((data?: HeatmapSpaceDto[]) => {
    // Example file name: UtopiOffice_Utopi Multisensor_Temperature_Heatmap_28 Jun 2024.csv 
    const fileName = `${config.building?.name}_${config.model?.deviceModelDisplayName}_${config.metricType ? t(config.metricType, { ns: 'enums' }) : ''}_${t('Building.Heatmap', { ns: 'organisms' })}_${(dayjs(new Date()).format(localisation.dateFormats.date))}`;
    
    if (!data) {
      return;
    }

    const csv = convertToCSV(
      [
        {
          key: 'floorName',
          header: t('Heatmap.Csv.FloorName', { ns: 'molecules' }),
        },
        {
          key: 'spaceName',
          header: t('Heatmap.Csv.SpaceName', { ns: 'molecules' }),
        },
        {
          key: 'spaceType',
          header: t('Heatmap.Csv.SpaceType', { ns: 'molecules' }),
        },
        {
          key: 'deviceFriendlyName',
          header: t('Heatmap.Csv.DeviceName', { ns: 'molecules' }),
          modifier: (device) => device.deviceFriendlyName || '-',
        },
        {
          key: 'deviceIdentifier',
          header: t('Heatmap.Csv.DeviceIdentifier', { ns: 'molecules' }),
          modifier: (device) => device.deviceIdentifier || '-',
        },
        {
          key: 'deviceLastMeasuredOn',
          header: t('Heatmap.Csv.LastMeasuredOn', { ns: 'molecules' }),
          modifier: (device) =>
            device.deviceLastMeasuredOn
              ? format(new Date(device.deviceLastMeasuredOn), localisation.dateFormats.default)
              : '-',
        },
        {
          key: 'measurementValue',
          header: config.metricType ? t(config.metricType, { ns: 'enums' }) : '',
          modifier: (device) =>
            config.metricType && device.measurementValue
              ? displayString(config.metricType, device.measurementValue)
              : '-',
        },
      ],
      data
    );

    exportCSV(fileName, csv);
  }, [config, localisation, t, displayString]);

  // Fetch data once a Building, DeviceModel and MetricType are selected
  useEffect(() => {
    const fetchData = async () => {
      if (!config.building || !config.model || !config.metricType) {
        return;
      }

      const spaces = await execute({
        query: new HeatmapQuery(config.building.floors.map(x => x.id), config.model.deviceModel, config.metricType)
      });

      if (spaces) {
        setData({ spaces, config });
        refetchData(spaces, config);
      } else {
        setData(undefined);
      }
    };

    fetchData();
  }, [config, execute, refetchData]);

  const handleBuildingChange = useCallback((building: BuildingWithFloors) => {
    setData(undefined);
    setConfig({ building });
  }, []);

  const handleDeviceModelChange = useCallback((model: DeviceModelWithMetricTypes) => {
    setConfig(prev => ({ building: prev.building, model }));
  }, []);

  const handleMetricChange = useCallback((metricType: MetricType) => {
    setConfig(prev => ({ ...prev, metricType }))
  }, []);

  return (
    <ContentWrapper>
      <PaddedContainer>
        <FlexRowSpaceBetween>
          <Title
            text={t('Building.Heatmap', { ns: 'organisms' })}
            size='lg'
          />

          <Button 
            onClick={() => exportDataToCsv(data?.spaces)}
            label={t('Export', { ns: 'common' })}
            disabled={!data}
          />
        </FlexRowSpaceBetween>


        <Card noPadding style={{ minWidth: 'max-content' }}>
          <HeaderRow>
            <FlexRow>
              <SelectContainer>
                <Label>{t('Building', { ns: 'common' })}</Label>
                <BuildingSelect
                  onChange={handleBuildingChange}
                />
              </SelectContainer>

              {config.building &&
                <SelectContainer>
                  <Label>{t('DeviceModel', { ns: 'common' })}</Label>
                  <DeviceModelSelect
                    building={config.building}
                    onChange={handleDeviceModelChange}
                    onLoading={setLoadingModels}
                    defaultModel={HeatmapDefaultDeviceModel}
                  />
                </SelectContainer>
              }

              {config.building && config.model &&
                <SelectContainer>
                  <Label>{t('Metric', { ns: 'common' })}</Label>
                  <HeatmapMetricSelect
                    building={config.building}
                    deviceModel={config.model}
                    onChange={handleMetricChange}
                    defaultMetricType={HeatmapDefaultMetricType}
                  />
                </SelectContainer>
              }
            </FlexRow>

            <FlexRow>
              <HeatmapLegend
                data={data}
                placement={heatmapMetricConfig ? TooltipPlacement.Bottom : TooltipPlacement.BottomRight}
              />

              <HeatmapScale
                config={heatmapMetricConfig}
                width={320}
                styles={{ padding: '27px 0 27px 16px' }}
              />
            </FlexRow>
          </HeaderRow>

          <Heatmap
            data={data}
            loading={loading || loadingModels}
            loadingRefetch={loadingRefetch}
          />
        </Card>
      </PaddedContainer>
    </ContentWrapper>
  );
};

export default HeatmapPage;

const ContentWrapper = styled.div`
  flex-grow: 1;
  height: ${`calc(100vh - ${TopBarHeight}px - ${HorizontalNavbarHeight}px)`};
  overflow: auto;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FlexRowSpaceBetween = styled(FlexRow)`
  justify-content: space-between;
  padding-bottom: 20px;
`;

const HeaderRow = styled(FlexRow)`
  display: flex;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  justify-content: space-between;
  padding: 0 16px;
`;

const SelectContainer = styled.div`
  width: 200px;
  margin: 16px 0;
`;