import { AlertRuleActionType } from '@api/models/AlertRule';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBrowser, faEnvelope, faLinkHorizontal } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IAlertRuleActionIcon {
  action: AlertRuleActionType
}

const AlertRuleActionIcon = ({ action }: IAlertRuleActionIcon) => {
  const { t } = useTranslation(['commonApp']);
  const theme = useTheme();

  const getIcon = (action: AlertRuleActionType): IconDefinition => {
    switch (action) {
      case AlertRuleActionType.Email:
        return faEnvelope;
      case AlertRuleActionType.EmailDigest:
        return faEnvelope;
      case AlertRuleActionType.Webhook:
        return faLinkHorizontal;
      case AlertRuleActionType.SignalR:
        return faBrowser;
      default:
        return faEnvelope;
    }
  };

  const getLabel = (action: AlertRuleActionType): AlertRuleActionType | string => 
    action === AlertRuleActionType.SignalR ? t('TopBar.Alerts.InAppNotification', { ns: 'commonApp' }) : action;


  return (
    <Tooltip
      content={getLabel(action)}
      placement={TooltipPlacement.Top}
      distance={0}
      fontSize='12px'
      hideTriangle={true}
      tooltipColor={transparentize(0.15, theme.palette.tooltip.background.primary)}
    >
      <ActionIcon icon={getIcon(action)} />
    </Tooltip>
  );
};

export default AlertRuleActionIcon;

const ActionIcon = styled(FontAwesomeIcon)`
  font-size: 17px;
  color: ${p => transparentize(0.2, p.theme.palette.text.fair)};
`;