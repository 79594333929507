import { Site } from '@api/models/Site';
import { useApiState } from '@hooks/useApiState';
import { useEffect, useState } from 'react';
import { AlertsGetBySiteIdAndAlertingRuleSeverityQuery } from '@api/queries/alerts/AlertsGetBySiteIdAndAlertingRuleSeverityQuery';
import { AlertingRuleSeverity } from '@api/enums/AlertingRuleSeverity';
import { Alert } from '@api/models/Alert';
import AlertBanner from './AlertBanner';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

type PropTypes = {
  site: Site
}

const AlertsWidget = ({ site }: PropTypes) => {
  const [urgentAlerts, setUrgentAlerts] = useState<Alert[]>([]);
  const [importantAlerts, setImportantAlerts] = useState<Alert[]>([]);

  const { data: alerts, execute: refreshAlerts } = useApiState({
    query: new AlertsGetBySiteIdAndAlertingRuleSeverityQuery(site.id, [AlertingRuleSeverity.Urgent, AlertingRuleSeverity.Important])
  }, [site]);

  useEffect(() => {
    if (alerts) {
      setUrgentAlerts(alerts.filter(alert => alert.alertingRule.severity === AlertingRuleSeverity.Urgent))
      setImportantAlerts(alerts.filter(alert => alert.alertingRule.severity === AlertingRuleSeverity.Important))
    }
  }, [alerts]);

  if (urgentAlerts.length === 0 && importantAlerts.length === 0) {
    return null;
  }

  return (
    <FlexColumn>
      <AlertBanner
        severity={AlertingRuleSeverity.Urgent}
        alerts={urgentAlerts}
        icon={solid('hexagon-exclamation')}
        refreshAlerts={refreshAlerts}
      />

      <AlertBanner
        severity={AlertingRuleSeverity.Important}
        alerts={importantAlerts}
        icon={solid('triangle-exclamation')}
        refreshAlerts={refreshAlerts}
      />
    </FlexColumn>
  )
}

export default AlertsWidget;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;