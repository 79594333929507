import { regexIntOnly } from '@pages/site/buildings/settings/building/components/CsvImport/CsvUtils';
import { MetricType } from '@api/enums/MetricType';
import { SiteMetadata } from '@api/models/SiteMetadata';
import { ErrorMessage, Input, Label } from '@components/Form';
import { Card } from '@components/core/Card';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { currencyDecimalName } from '@utils/CurrencyUtils';
import { stringToNumber } from '@utils/NumberUtils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../SiteMetadataEdit';

type UtilitiesEditProps = {
  siteMetadata: SiteMetadata | undefined;
  editMode: boolean;
}

const UtilitiesEdit = ({ siteMetadata, editMode }: UtilitiesEditProps) => {
  const { t } = useTranslation(['settingsAsset', 'currencies']);
  const { register, formState: { errors } } = useFormContext<FormValues>();
  const { getUnit } = useLocalisation();
  const decimalName = siteMetadata?.currency != null ? currencyDecimalName(siteMetadata?.currency, t) : t('GBPDecimal', { ns: 'common' });

  return (
    <Card cardTitle={t('Sites.SiteMetadataEditing.Utilities', { ns: 'settingsAsset' })}>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.ElectricityPrice', { decimalName: decimalName })}</Label>
          <Input
            {...register('electricityPricePerKwh', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.ElectricityPriceValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.EnergyPricePlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.electricityPricePerKwh?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.HeatingPrice', { decimalName: decimalName })}</Label>
          <Input
            {...register('heatingPricePerKwh', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.HeatingPriceValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.EnergyPricePlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.heatingPricePerKwh?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.WaterPrice', { decimalName: decimalName, unit: getUnit(MetricType.WaterVolume) })}</Label>
          <Input
            {...register('waterPricePerM3', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.WaterPriceValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.WaterPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.waterPricePerM3?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.WasteWaterPrice', { decimalName: decimalName, unit: getUnit(MetricType.WaterVolume) })}</Label>
          <Input
            {...register('wasteWaterPricePerM3', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.WasteWaterPriceValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.WaterPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.wasteWaterPricePerM3?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.WasteWaterProportion', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('wasteWaterProportion', {
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.WasteWaterProportionMin', { ns: 'settingsAsset' })
              },
              max: {
                value: 99,
                message: t('Sites.SiteMetadataEditing.WasteWaterProportionMax', { ns: 'settingsAsset' })
              },
              required: t('Sites.SiteMetadataEditing.FieldRequired', { ns: 'settingsAsset' }),
              validate: value => value ? regexIntOnly.test(value.toString()) || t('Sites.SiteMetadataEditing.WasteWaterProportionValidation', { ns: 'settingsAsset' }) : true,
              valueAsNumber: true
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.WasteWaterProportionPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.wasteWaterProportion?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.GasPrice', { decimalName: decimalName, unit: getUnit(MetricType.GasVolume) })}</Label>
          <Input
            {...register('gasPricePerM3', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.GasPriceValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.PriceMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.GasPricePlaceholder', { ns: 'settingsAsset', unit: getUnit(MetricType.GasVolume) })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.gasPricePerM3?.message}</ErrorMessage>
        </div>
      </div>
      <div className='row'>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.GasCalorificValue')}</Label>
          <Input
            {...register('gasCalorificValue', {
              validate: value => value ? regexIntOnly.test(value.toString()) || t('Sites.SiteMetadataEditing.GasCalorificValidation', { ns: 'settingsAsset' }) : true,
              valueAsNumber: true,
              required: t('Sites.SiteMetadataEditing.FieldRequired', { ns: 'settingsAsset' }),
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
            })}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.GasCalorificPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
            step="1"
          />
          <ErrorMessage>{errors.gasCalorificValue?.message}</ErrorMessage>
        </div>
      </div>
    </Card>
  )
}

export default UtilitiesEdit