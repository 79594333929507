import { Locales } from '@api/enums/Locales';
import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';
import { round } from '@utils/NumberUtils';
import { LocalisationType, MetricConversion } from './localisation';

const temperatureConversion: MetricConversion = {
  toLocale: x => (x * 1.8) + 32,
  fromLocale: x => (x - 32) / 1.8,
  displayString: x => `${round(((parseFloat(x) * 1.8) + 32), 1)} °F`,
  unit: '°F',
  unitLabel: 'Temperature (°F)'
};

const gasVolumeConversion: MetricConversion = {
  toLocale: x => x * 35.315,
  fromLocale: x => x / 35.315,
  displayString: x => `${round((parseFloat(x) * 35.315), 2)} ft³`,
  unit: 'ft³',
  unitLabel: 'Volume (ft³)'
};

const waterVolumeConversion: MetricConversion = {
  toLocale: x => x * 264.172,
  fromLocale: x => x / 264.172,
  displayString: x => `${round((parseFloat(x) * 264.172), 2)} gal`,
  unit: 'gal',
  unitLabel: 'Volume (gal)'
}

const particlesConversion: MetricConversion = {
  toLocale: x => x * 35.315,
  fromLocale: x => x / 35.315,
  displayString: x => `${round((parseFloat(x) * 35.315), 2)} µg/ft³`,
  unit: 'µg/ft³',
  unitLabel: 'Weight/Volume (µg/ft³)'
};

export const usLocalisation: LocalisationType = {
  id: 1,
  displayString: 'USA Imperial system',
  locale: Locales.enUS,
  localeDisplayString: 'en-US',
  showPriceLine: false,
  currency: '$',
  localPostcodeName: 'Zip Code',
  dateFormats: {
    default: 'MM/dd/yyyy HH:mm',
    weekdayName: 'EE. MMMM do',
    weekdayNameAndTime: 'EE. MMMM do, HH:mm',
    date: 'MMM DD YYYY',
    datepickerField: 'MM/DD/YYYY',
    datepickerAlternative: 'MMMM Do YYYY',
    slashFormatDisplay: 'MM/dd/yyyy',
    dateAndTime: 'MMM DD YYYY, HH:mm',
    monthAndYear: 'MMM YYYY'
  },
  timeDisplayFormats() {
    return {
      millisecond: 'HH:mm:ss:SSS',
      second: 'HH:mm:ss',
      minute: 'HH:mm',
      hour: 'MMM dd, HH:mm',
      day: 'MMM dd',
      week: 'MMM dd yyy',
      month: 'MMM yyyy',
    };
  },
  metrics: {
    [AltUnits.Area]: {
      toLocale: x => x * 10.764,
      fromLocale: x => x / 10.764,
      displayString: x => `${round((parseFloat(x) * 10.764), 1)} ft²`,
      unit: 'ft²',
      unitLabel: 'Area (ft²)'
    },
    [AltUnits.CO2WeightArea]: {
      toLocale: x => x * 0.2048,
      fromLocale: x => x / 0.2048,
      displayString: x => `${round((parseFloat(x) * 0.2048), 4)} lb`,
      unit: 'lbCO₂',
      unitLabel: ' per square foot'
    },
    [AltUnits.CO2WeightIntensity]: {
      toLocale: x => x / 28.34949254,
      fromLocale: x => x * 28.34949254,
      displayString: x => `${round((parseFloat(x) / 28.34949254), 4)} oz`,
      unit: 'ozCO₂/kWh',
      unitLabel: 'ounces CO₂ per kWh'
    },
    [AltUnits.CO2SubWeightArea]: {
      toLocale: x => x / 28.34949254,
      fromLocale: x => x * 28.34949254,
      displayString: x => `${round((parseFloat(x)), 4)} ft³`,
      unit: 'ozCO₂/ft³',
      unitLabel: 'per square foot'
    },
    [AltUnits.Weight]: {
      toLocale: x => x * 2.204,
      fromLocale: x => x / 2.204,
      displayString: x => `${round((parseFloat(x) * 2.204), 4)} lb`,
      unit: 'lb',
      unitLabel: 'Weight (lb)'
    },
    [MetricType.Emissions]: {
      toLocale: x => x * 2.204,
      fromLocale: x => x / 2.204,
      displayString: x => `${round((parseFloat(x) * 2.204), 4)} lb`,
      unit: 'lb',
      unitLabel: 'lbCO₂'
    },
    [AltUnits.EnergySqmDailyAverage]: {
      toLocale: x => x * 10.764,
      fromLocale: x => x / 10.764,
      displayString: x => `${round((parseFloat(x) * 10.764), 4)} kWh`,
      unit: 'kWh',
      unitLabel: 'Avg/day/ft² (kWh)'
    },
    [AltUnits.GasSqmDailyAverageVolume]: {
      toLocale: x => (x * 35.315) / 10.764,
      fromLocale: x => (x / 35.315) * 10.764,
      displayString: x => `${round(((parseFloat(x) * 35.315) * 10.764), 4)} ft³`,
      unit: 'ft³',
      unitLabel: 'Avg/day/ft² (ft³)'
    },
    [AltUnits.WaterSqmDailyAverage]: {
      toLocale: x => (x * 264.172) / 10.764,
      fromLocale: x => (x / 264.172) * 10.764,
      displayString: x => `${round(((parseFloat(x) * 264.172) / 10.764), 4)} gal`,
      unit: 'gal',
      unitLabel: 'Avg/day/ft² (gal)'
    },
    [AltUnits.VariancePercentage]: {
      displayString: x => `${round((parseFloat(x)), 4)} %`,
      unit: '%',
      unitLabel: 'Percent (%)'
    },
    [AltUnits.Default]: {
      unit: '',
      unitLabel: ''
    },
    [MetricType.WindSpeedAverage]: {
      toLocale: x => x * 2.23694,
      fromLocale: x => x / 2.23694,
      displayString: x => `${round((parseFloat(x) * 2.2369), 2)} mph`,
      unit: 'mph',
      unitLabel: 'Average Windspeed (mph)'
    },
    [MetricType.Pressure]: {
      toLocale: x => x * 0.0145038,
      fromLocale: x => x / 0.0145038,
      displayString: x => `${round((parseFloat(x) * 0.0145038), 2)} psi`,
      unit: 'psi',
      unitLabel: 'Pressure (psi)'
    },
    [AltUnits.CarbonIntensityForecast]: {
      toLocale: x => x * 0.453,
      fromLocale: x => x / 0.453,
      displayString: x => `${round((parseFloat(x) * 0.435), 2)} lbCO₂/kWh`,
      unit: 'lbCO₂/kWh',
      unitLabel: 'Weight/Consumption (lbCO₂/kWh)'
    },
    [AltUnits.CarbonIntensityVolume]: {
      toLocale: x => x * 0.06242796,
      fromLocale: x => x / 0.06242796,
      displayString: x => `${round((parseFloat(x) * 0.06242796), 2)} lbCO₂/ft³`,
      unit: 'lbCO₂/ft³',
      unitLabel: 'Weight/Consumption (lbCO₂/ft³)'
    },
    [MetricType.Temperature]: temperatureConversion,
    [MetricType.OutdoorTemperature]: temperatureConversion,
    [MetricType.Sensor1Temp]: temperatureConversion,
    [MetricType.Sensor1MinTemp]: temperatureConversion,
    [MetricType.Sensor1MaxTemp]: temperatureConversion,
    [MetricType.Sensor2Temp]: temperatureConversion,
    [MetricType.Sensor2MinTemp]: temperatureConversion,
    [MetricType.Sensor2MaxTemp]: temperatureConversion,
    [MetricType.Sensor3Temp]: temperatureConversion,
    [MetricType.Sensor3MinTemp]: temperatureConversion,
    [MetricType.Sensor3MaxTemp]: temperatureConversion,
    [MetricType.SensorScaldTemp]: temperatureConversion,
    [MetricType.SensorFreezeTemp]: temperatureConversion,
    [MetricType.TemperatureTarget]: temperatureConversion,
    [MetricType.MinTemperature]: temperatureConversion,
    [MetricType.MaxTemperature]: temperatureConversion,
    [MetricType.HeatingTemperature]: temperatureConversion,
    [MetricType.CoolingTemperature]: temperatureConversion,
    [MetricType.ManualTargetTemperatureUpdate]: temperatureConversion,
    [MetricType.GasVolume]: gasVolumeConversion,
    [MetricType.GasVolumeDelta]: gasVolumeConversion,
    [MetricType.HeatingVolume]: gasVolumeConversion,
    [MetricType.WaterVolume]: waterVolumeConversion,
    [MetricType.WaterVolumeDelta]: waterVolumeConversion,
    [MetricType.Particulates2_5]: particlesConversion,
    [MetricType.Particulates10]: particlesConversion,
    [MetricType.NO2]: particlesConversion,
    [MetricType.HCHO]: particlesConversion,
    [MetricType.VOC]: particlesConversion,
  }
}