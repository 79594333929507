import { PostQuery } from '@api/queries/common/PostQuery';
import { DeviceModel } from '@api/enums/DeviceModel';
import { EnergyMeterCategory } from '@api/enums/EnergyMeterCategory';
import { EnergyMeterTopologyLevel } from '@api/enums/EnergyMeterTopologyLevel';
import { Device } from '@api/models/Device';
import { MetricType } from '@api/enums/MetricType';

export default class DeviceCreateCommand extends PostQuery<Device> {
  spaceId?: number;
  deviceModel?: DeviceModel;
  deviceIdentifier?: string;
  friendlyName?: string;

  meterTopologyLevel?: EnergyMeterTopologyLevel;
  meterCategory?: EnergyMeterCategory;
  parentMeterId?: number;
  isBillingMeter: boolean;

  pulseAssignments?: {
    metricType: MetricType;
    pulsesPerUnit: number;
    initialValue?: number;
    channel?: string
  }[]

  metadata?: string;

  public targetUrl(): string {
    return '/devices';
  }
}
