import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import EsgExplorerTemplateDto from '@api/models/EsgExplorerTemplateDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EsgExplorerTemplateCreateCommand extends PostQuery<EsgExplorerTemplateDto> {
  siteId: number;
  name: string;
  configuration: EsgExplorerConfiguration;
  private: boolean;

  constructor(siteId: number, name: string, configuration: EsgExplorerConfiguration, visibilityPrivate: boolean) {
    super();
    this.siteId = siteId;
    this.name = name;
    this.configuration = configuration;
    this.private = visibilityPrivate;
  }

  public targetUrl(): string {
    return '/esgExplorer/createTemplate';
  }
}
