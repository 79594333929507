import Devices_ClimateControl from '@pages/site/buildings/settings/devices/temperature-control/Devices_ClimateControl';
import Devices_Environmental from '@pages/site/buildings/settings/devices/environmental/Devices_Environmental';
import Devices_Gateway from '@pages/site/buildings/settings/devices/gateway/Devices_Gateway';
import Devices_Metering from '@pages/site/buildings/settings/devices/metering/Devices_Metering';
import Devices_Occupancy from '@pages/site/buildings/settings/devices/occupancy/Devices_Occupancy';
import Devices_Status from '@pages/site/buildings/settings/devices/status/Devices_Status';
import BuildingPage_Devices from '@pages/site/buildings/settings/devices/BuildingPage_Devices';
import BuildingDeviceList from '@pages/site/buildings/settings/devices/all/BuildingDeviceList';
import BuildingEdit from '@pages/site/buildings/settings/building/BuildingEdit';
import BuildingEnergyMeters from '@pages/site/buildings/settings/energy-meters/BuildingEnergyMeters';
import EnergyMeterList from '@pages/site/buildings/settings/energy-meters/list/EnergyMeterList';
import EnergyMeterTopology from '@pages/site/buildings/settings/energy-meters/topology/EnergyMeterTopology';
import BuildingFloorList from '@pages/site/buildings/settings/floors/BuildingFloorList';
import BuildingSpaces from '@pages/site/buildings/settings/spaces/BuildingSpaces';
import BuildingSpaceList from '@pages/site/buildings/settings/spaces/list/BuildingSpaceList';
import SpaceLocations from '@pages/site/buildings/settings/spaces/locations/SpaceLocations';
import { Route } from '@src/types/Route';
import BuildingManagement from '@pages/site/buildings/settings/BuildingManagement';

export const BuildingManagementRoutes: Route[] = [
  {
    path: 'building/:buildingId/settings/*',
    element: BuildingManagement,
    analytics: { page: 'manage_building', category: 'buildings' },
    children: [
      {
        path: 'building',
        element: BuildingEdit,
        link: {
          label: 'Building',
          path: 'building'
        }
      },
      {
        path: 'floors/*',
        element: BuildingFloorList,
        link: {
          label: 'Floors',
          path: 'floors'
        }
      },
      {
        path: 'spaces/*',
        element: BuildingSpaces,
        link: {
          label: 'Spaces',
          path: 'spaces',
          showChildrenInDropdown: true
        },
        children: [
          {
            path: '',
            element: BuildingSpaceList,
            link: {
              label: 'List',
              path: 'spaces',
              end: true
            }
          },
          {
            path: 'locations',
            element: SpaceLocations,
            link: {
              label: 'Locations',
              path: 'spaces/locations'
            }
          },
        ]
      },
      {
        path: 'devices',
        element: BuildingPage_Devices,
        link: {
          label: 'Devices',
          path: 'devices'
        }
      },
      {
        path: 'devices/all',
        element: BuildingDeviceList,
      },
      {
        path: 'devices/environmental',
        element: Devices_Environmental,
      },
      {
        path: 'devices/metering',
        element: Devices_Metering,
      },
      {
        path: 'devices/occupancy',
        element: Devices_Occupancy,
      },
      {
        path: 'devices/status',
        element: Devices_Status,
      },
      {
        path: 'devices/climate-control',
        element: Devices_ClimateControl,
      },
      {
        path: 'devices/gateway',
        element: Devices_Gateway,
      },
      {
        path: '/energy-meters/topology',
        element: EnergyMeterTopology,
      },
      {
        path: 'energy-meters/*',
        element: BuildingEnergyMeters,
        link: {
          label: 'EnergyMeters',
          path: 'energy-meters',
          showChildrenInDropdown: true
        },
        children: [
          {
            path: '',
            element: EnergyMeterList,
            link: {
              label: 'List',
              path: 'energy-meters',
              end: true
            }
          },
          {
            path: 'topology',
            element: EnergyMeterTopology,
            link: {
              label: 'Topology',
              path: 'energy-meters/topology'
            }
          }
        ]
      }
    ]
  }
];