import { Chart, ChartTypeRegistry, TooltipModel } from 'chart.js';
import { DefaultTheme } from 'styled-components';
import { numberWithCommas } from '@utils/NumberUtils';
import { transparentize } from 'polished';
import { groupBy, sum } from 'lodash';
import { LineBarChartType } from '@components/charts/Chart.types';
import dayjs from 'dayjs';
import { LocalisationType } from '@i18n/localisation';
import { TFunction } from 'i18next';
import { ConsumptionChartDataset, CustomUtilityCategory, TXYDateChartDataPoint } from './utilityChartUtils';
import { ConsumptionChartView } from './ChartViewToggle';

export type TooltipContext = { chart: Chart; tooltip: TooltipModel<keyof ChartTypeRegistry> };

// Render rounded rectangle or line icon where appropriate
const getIconElement = (chartType?: LineBarChartType, color?: string): HTMLSpanElement | undefined => {
  const span = document.createElement('span');
  span.style.height = '14px';
  span.style.width = '14px';
  span.style.marginRight = '8px';
  span.style.background = color ?? 'transparent';
  span.style.borderRadius = '4px';
  span.style.display = 'inline-block';

  if (chartType === LineBarChartType.Line) {
    span.style.height = '3px';
  }

  return span;
};

const createRow = (theme: DefaultTheme, unit: string, label: string, value: number | null, isBold?: boolean, chartType?: LineBarChartType, color?: string) => {
  const tr = document.createElement('tr');
  const td = document.createElement('td');
  td.style.paddingBottom = '2px';
  td.style.display = 'flex';
  td.style.alignItems = 'center';

  // Render rounded rectangle or line icon where appropriate
  const iconElement = getIconElement(chartType, color);
  if (iconElement) {
    td.appendChild(iconElement);
  }

  // Render label (category) and value
  const displyValue = value === null ? '--' : `${numberWithCommas(value, 2)}${unit}`;
  const labelSpan = document.createElement('span');
  labelSpan.innerHTML = `${label}: ${displyValue}`;
  labelSpan.style.fontWeight = isBold ? '500' : '400';
  labelSpan.style.color = isBold ? theme.text.primary : 'inherit';
  labelSpan.style.paddingRight = '4px';
  td.appendChild(labelSpan);

  // Add cell element to row=
  tr.appendChild(td);

  return tr;
};

const getOrCreateTooltip = (chart: Chart, theme: DefaultTheme) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.backgroundColor = theme.background.container;
    tooltipEl.style.border = `1px solid ${theme.action.divider}`;
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.boxShadow = `0 2px 12px -2px ${theme.shadow.dark}`;
    tooltipEl.style.color = transparentize(0.15, theme.text.primary);
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.padding = '4px 10px 8px 10px';
    tooltipEl.style.height = 'max-content';
    tooltipEl.style.width = 'max-content';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transition = 'all 300ms ease';
    tooltipEl.style.zIndex = '1';
    tooltipEl.style.fontSize = '14px';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: TooltipContext, theme: DefaultTheme, localisation: LocalisationType, aggregateData: boolean, view: ConsumptionChartView, t: TFunction) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, theme);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  const tableRoot = tooltipEl.querySelector('table');

  if (!tableRoot) {
    return;
  }

  // Set Text
  if (tooltip.body) {
    // Create table body (one line per dataset)
    const tableBody = document.createElement('tbody');

    // Group data points by date to separate last years from current year
    const groupedByDate = groupBy(tooltip.dataPoints, x => (x.raw as TXYDateChartDataPoint).date);

    Object.entries(groupedByDate).forEach((group) => {
      // Render date label as a group sub heading
      const date = group[0];
      const tr = document.createElement('tr');
      const td = document.createElement('td');
      td.style.paddingTop = '6px';
      td.style.paddingBottom = '4px';
      td.style.fontWeight = '500';
      td.style.color = theme.text.primary;

      td.appendChild(document.createTextNode(dayjs(date).format(aggregateData ? localisation.dateFormats.monthAndYear : localisation.dateFormats.date)));
      tr.appendChild(td);
      tableBody.appendChild(tr);

      // Render a label for each dataset
      group[1].forEach((dataPoint) => {
        const dataset = dataPoint.dataset as unknown as ConsumptionChartDataset;
        // Create and add row to table
        tableBody.appendChild(createRow(theme, dataset.dataUnit, dataset.label, dataPoint.parsed.y, false, dataset.type, dataset.legend.color));
      });

      // Create and add row for daily totals (don't show this row on the Cumulative view)
      if (view === ConsumptionChartView.Periodical) {
        const firstDataset = group[1][0]?.dataset as unknown as (ConsumptionChartDataset | undefined);
        const unit = firstDataset?.dataUnit ?? '';
        const dailyTotal = sum(group[1].filter(x => (x.dataset as unknown as ConsumptionChartDataset).category !== CustomUtilityCategory.Target).map(x => x.parsed.y ?? 0));
        tableBody.appendChild(createRow(theme, unit, t('Total', { ns: 'common' }), dailyTotal, true));
      }
    });

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY, offsetWidth } = chart.canvas;
  const canvasCenter = offsetWidth / 2;

  // Display the tooltip
  tooltipEl.style.opacity = '1';

  // Position the tooltip on the left or right side of the datapoint depending on its position on the chart
  if (tooltip.caretX > canvasCenter) {
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.left = positionX + tooltip.caretX - 20 + 'px';
    tooltipEl.style.transform = 'translate(-100%, -50%)';
  } else {
    tooltipEl.style.left = positionX + tooltip.caretX + 20 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.transform = 'translateY(-50%)';
  }
};