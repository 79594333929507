import { MetricType } from '@api/enums/MetricType';
import { TimeGranularity } from '@api/enums/TimeGranularity';
import { Metric } from '@api/models/Metric';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class DeviceMetricsQuery extends PostQuery<Metric[]> {
  deviceId: number;
  metricTypes?: MetricType[];
  fromDateTime?: string;
  toDateTime?: string;
  timeGranularity?: TimeGranularity;

  constructor(deviceId: number, metricTypes?: MetricType[], fromDateTime?: string, toDateTime?: string, timeGranularity?: TimeGranularity) {
    super();
    this.deviceId = deviceId;
    this.metricTypes = metricTypes;
    this.fromDateTime = fromDateTime;
    this.toDateTime = toDateTime;
    this.timeGranularity = timeGranularity;
  }

  public targetUrl(): string {
    return '/metrics/deviceMetrics';
  }
}
