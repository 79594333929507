import styled, { useTheme } from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { Select } from '@components/select/Select';
import { CSSObjectWithLabel } from 'react-select';
import { TUtilityTab } from '../SiteUtilitiesWidget';

type PropTypes = {
  utility: TUtilityTab;
  conversions: { [key: string]: string };
  onChange: (conversions: { [key: string]: string }) => void;
};

const UnitToggle = ({ utility, conversions, onChange }: PropTypes) => {
  const theme = useTheme();
  const [selected, setSelected] = useState<boolean>();
  const { localisation } = useLocalisation();
  const options = useMemo(() => [
    {
      label: utility.unit,
      value: false
    },
    {
      label: utility.conversion?.unit ?? '',
      value: true
    }
  ], [utility]);

  useEffect(() => {
    setSelected(!!conversions[utility.metricType]);
  }, [utility, conversions, localisation])

  const handleChange = useCallback((convert: boolean) => {
    setSelected(convert);

    const copy = { ...conversions };

    if (convert) {
      copy[utility.metricType] = utility.metricType;
    } else {
      delete copy[utility.metricType];
    }

    onChange(copy);
  }, [utility, conversions, onChange]);

  const customStyles = {
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      paddingLeft: 0,
      marginLeft: -4,
      color: theme.action.active,

      'svg': {
        width: 16,
        height: 16
      }
    } as CSSObjectWithLabel)
  };

  if (!utility.conversion) {
    return <YAxisLabel>{utility.unit}</YAxisLabel>;
  }

  return (
    <Wrapper>
      <Select
        value={options.find(x => x.value === selected)}
        options={options}
        onChange={selected => selected && handleChange(selected.value)}
        borderColor='transparent'
        boxShadow='none'
        customStyles={customStyles}
        isSearchable={false}
        fontSize='12px'
      />
    </Wrapper>
  );
};

export default UnitToggle;

const Wrapper = styled.div`
  width: fit-content;
  margin-left: -10px;
`;

const YAxisLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
  margin: 11.5px 0 10.5px 1px;
`;