import RadarChart from '@components/charts/RadarChart';
import { MetricType_ValueDisplayString } from '@api/enums/MetricType_ValueDisplayString';
import { Metric } from '@api/models/Metric';
import { IRadarChartWidgetConfig } from '@contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IRadarChartWidget {
  metrics: Metric[];
  config?: IRadarChartWidgetConfig;
  spaceColor: string;
}

const RadarChartWidget = ({ metrics, config, spaceColor }: IRadarChartWidget) => {
  const { t } = useTranslation(['deviceConfigs', 'enums']);
  const { displayString } = useLocalisation();

  if (!config) {
    return null;
  }

  // Cretae chart labels array, use the MetricType display string if a label is not defined in the config
  const chartLabels = config.metrics.map(x => x.label ? t(x.label, { ns: 'deviceConfigs' }) : t(x.metricType, { ns: 'enums' }));

  const chartDataset: number[] = [];
  const tooltipLabels: string[] = [];
  const tooltipAfterLabels: string[] = [];

  config.metrics.forEach(x => {
    // Look for the metric defined in the config
    const metric = metrics.find(m => m.metricType === x.metricType);

    if (!metric) {
      return;
    }

    // Add the metric value to the dataset
    chartDataset.push(parseFloat(metric.value));

    // Add the tooltip label to the tooltips array
    const tooltipValuePrefix = x.tooltipValue?.prefix ? t(x.tooltipValue.prefix, { ns: 'deviceConfigs' }) : '';
    const tooltipValueSuffix = x.tooltipValue?.suffix ? t(x.tooltipValue.suffix, { ns: 'deviceConfigs' }) : '';
    tooltipLabels.push(`${tooltipValuePrefix}${MetricType_ValueDisplayString(metric)}${tooltipValueSuffix}`);

    if (!x.secondTooltipValue) {
      return;
    }

    // If a second value for the tooltip is defined, look for that metric and add it to the second tooltips array
    const secondTooltipMetric = metrics.find(m => m.metricType === x.secondTooltipValue?.metricType);
    if (secondTooltipMetric) {
      const secondTooltipValuePrefix = x.secondTooltipValue?.prefix ? t(x.secondTooltipValue.prefix, { ns: 'deviceConfigs' }) : '';
      tooltipAfterLabels.push(`${secondTooltipValuePrefix}${displayString(secondTooltipMetric.metricType, secondTooltipMetric.value)}`);
    }
  });

  return (
    <Wrapper>
      <RadarChart
        labels={chartLabels}
        dataset={chartDataset}
        tooltipEnabled
        tooltipLabels={tooltipLabels}
        tooltipAfterLabels={tooltipAfterLabels}
        color={transparentize(0.3, spaceColor)}
      />
    </Wrapper>
  );
};

export default RadarChartWidget;

const Wrapper = styled.div`
  height: 200px;
  width: 100%;
`;