import { DeviceSearchDto } from '@api/models/DeviceSearchDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DevicesGetBySiteAndIdentifierQuery extends GetQuery<DeviceSearchDto[]> {
  siteId: number;
  searchString: string

  constructor(siteId: number, searchString: string) {
    super();
    this.siteId = siteId;
    this.searchString = searchString;
  }

  public targetUrl(): string {
    return `/devices/SearchBySiteAndIdentifier?siteId=${this.siteId}&searchString=${this.searchString}`;
  }
}
