import { DatePicker } from 'antd';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { CSSProperties } from 'react';
import styled, { useTheme } from 'styled-components';
import PreviousNextButtons from './PreviousNextButtons';
import { TimeRange, TimeRangeType } from '../TimeRangeSelect';

type PropTypes = {
  range: TimeRange;
  onChange: (range: TimeRange) => void;
}

const MonthSelect = ({ range, onChange }: PropTypes) => {
  const theme = useTheme();
  const { localisation } = useLocalisation();

  const customStyles: CSSProperties = {
    height: '38px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.action.outlineBorder}`,
    borderRadius: '4px',
    boxShadow: theme.palette.forms.input.boxShadow,
    color: theme.text.secondary
  };

  return (
    <FlexRow>

      <DatePicker
        picker="month"
        value={range.from}
        onChange={date => onChange({ from: date.startOf('month'), to: date.endOf('month') })}
        format={date => date ? date.format(localisation.dateFormats.monthAndYear) : ''}
        allowClear={false}
        style={customStyles}
      />

      <PreviousNextButtons
        timeRangeType={TimeRangeType.Month}
        onPrevious={() => onChange({ from: range.from.add(-1, 'month'), to: range.to.add(-1, 'month').endOf('month') })}
        onNext={() => onChange({ from: range.from.add(1, 'month'), to: range.to.add(1, 'month').endOf('month') })}
      />
    </FlexRow>
  );
};

export default MonthSelect;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;