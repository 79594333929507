import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { ClustersAndSpaces } from '@api/models/ClustersAndSpaces';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class ResidentialSpacesWithMeterTypesGetBySiteIdQuery extends PostQuery<ClustersAndSpaces> {
  siteId: number;
  meterType: EnergyMeterType;

  constructor(siteId: number, meterType: EnergyMeterType) {
    super();
    this.siteId = siteId;
    this.meterType = meterType;
  }

  public targetUrl(): string {
    return '/Spaces/ResidentialSpacesWithMeterTypesBySite';
  }
}