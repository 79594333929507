import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import { HeatmapConfig } from '@pages/site/heatmap/HeatmapPage';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LabelValuePair } from '@components/core/LabelValuePair';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  space: HeatmapSpaceDto;
  config: HeatmapConfig;
}

const HeatmapTooltipContent = ({ space, config }: PropTypes) => {
  const { t } = useTranslation();
  const { displayString } = useLocalisation();
  const lastMeasuredOn = space.deviceLastMeasuredOn ? new Date(space.deviceLastMeasuredOn) : undefined;

  if (!space.deviceId) {
    return (
      <NoDeviceLabel>
        {t('Heatmap.NoDeviceInstalled', { ns: 'molecules', deviceModel: config.model?.deviceModelDisplayName })}
      </NoDeviceLabel>
    );
  }

  if (!lastMeasuredOn) {
    return (
      <NoDeviceLabel>
        {t('Heatmap.SensorOffline', { ns: 'molecules' })}
      </NoDeviceLabel>
    );
  }

  return (
    <>
      {lastMeasuredOn &&
        <>
          <LabelValuePair
            label={config.metricType ? t(config.metricType, { ns: 'enums' }) : ''}
            value={space.metricType && space.measurementValue ? displayString(space.metricType, space.measurementValue) : '--'}
            size="sm"
            smallGap
          />

          <Label>
            {t('Heatmap.LastReading', { ns: 'molecules' })}
          </Label>

          <Value>
            {space.deviceIsOffline &&
              <WarningIcon icon={solid('triangle-exclamation')} />
            }
            <span>
              {formatDistance(lastMeasuredOn, new Date(), { addSuffix: true })}
            </span>
          </Value>
        </>
      }
    </>
  );
};

export default HeatmapTooltipContent;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.palette.red};
  margin-right: 4px;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
  margin-top: 15px;
  margin-bottom: 3px;
`;

const Value = styled.div`
  font-size: 14px;
  color: ${p => p.theme.palette.text.fair};
`;

const NoDeviceLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;