import { callPatch, IAuthConfig } from '@utils/ConnectApiUtils';
import { BaseQuery } from '@api/queries/common/BaseQuery';

export abstract class OperatorPatchQuery<TDto> extends BaseQuery<TDto> {
  public id?: number;

  constructor(id?: number) {
    super();
    this.id = id;
  }

  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.operatorApiBaseUrl);
    const response = await callPatch(apiUrl, this, authConfig, controller);

    return response.data;
  }
}
