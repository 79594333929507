import { PaddedContainer } from '@components/core/PaddedContainer';
import { BackButton } from '@components/core/BackButton';
import { Title } from '@components/core/Title';
import { useTranslation } from 'react-i18next';
import { Table } from '@components/table/Table';
import { ITableColumn, TableSortOrder } from '@components/table/Table.types';
import { useCallback, useMemo, useState } from 'react';
import { addDays, format, formatDistance } from 'date-fns';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { HorizontalNavbarHeight, TopBarHeight } from '@src/constants/designConstants';
import styled from 'styled-components';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { ThemeColored } from '@components/core/ThemeColoredSpan';
import { useSiteContext } from '@pages/site/SiteProvider';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';
import { useApiState } from '@hooks/useApiState';
import LoadingWidget from '@components/core/LoadingWidget';
import { Button } from '@components/core/Button';
import Filter from '@components/core/Filter';
import { convertToCSV, exportCSV } from '@utils/ExportUtils';
import { useParams } from 'react-router-dom';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { SpaceType } from '@api/enums/SpaceType';
import { TFunction } from 'i18next';
import SpaceTypeFilter from '../components/live-updates/temperature/SpaceTypeFilter';
import UnoccupiedSpaceDto from '@api/models/UnoccupiedSpaceDto';
import { stringToFloat } from '@utils/NumberUtils';
import UnoccupiedSpacesWithMetricsGetQuery from '@api/queries/metrics/UnoccupiedSpacesWithMetricsGetQuery';

const getPageDetails = (t: TFunction, path?: string, data?: UnoccupiedSpaceDto[]) => {
  if (!path || !data) {
    return {
      spaces: [],
      label: '',
      sortOrder: TableSortOrder.DESC,
      analytics: ''
    }
  }

  const now = new Date();
  const onlineDevices = data.filter(x => x.deviceIsOnline);
  const offlineDevices = data.filter(x => !x.deviceIsOnline);

  switch (path) {
    case 'offline-devices':
      return {
        spaces: offlineDevices,
        title: t('SiteOverview.UnoccupiedWidget.ListTitleOfflineDevices', { ns: 'molecules' }),
        sortOrder: TableSortOrder.DESC,
        analytics: 'view_offline_device_space'
      };
    case 'seven-days':
      return {
        spaces: onlineDevices.filter(x => !x.motionMeasuredOn || new Date(x.motionMeasuredOn) <= addDays(now, -7)),
        title: t('SiteOverview.UnoccupiedWidget.ListTitleSevenDays', { ns: 'molecules', days: 7 }),
        sortOrder: TableSortOrder.DESC,
        analytics: 'view_over7days_space'
      };
    case 'one-day':
      return {
        spaces: onlineDevices.filter(x => x.motionMeasuredOn && new Date(x.motionMeasuredOn) <= addDays(now, -1) && new Date(x.motionMeasuredOn) > addDays(now, -7)),
        title: t('SiteOverview.UnoccupiedWidget.ListTitleOneDay', { ns: 'molecules', hours: 24 }),
        sortOrder: TableSortOrder.DESC,
        analytics: 'view_over1day_space'
      };
    case 'occupied':
    default:
      return {
        spaces: onlineDevices.filter(x => x.motionMeasuredOn && new Date(x.motionMeasuredOn) > addDays(now, -1)),
        title: t('SiteOverview.UnoccupiedWidget.ListTitleOccupied', { ns: 'molecules', hours: 24 }),
        sortOrder: TableSortOrder.DESC,
        analytics: 'view_under1day_space'
      };
  }
};

const UnoccupiedSpacesList = () => {
  const { t } = useTranslation();
  const { status } = useParams<{ status: string }>();
  const { toLocale, getUnit, localisation } = useLocalisation();
  const { trackAction } = useAnalytics();
  const navigate = useNavigate();
  const { tenant } = useTenantContext();
  const { site, buildings } = useSiteContext();
  const [buildingFilter, setBuildingFilter] = useState<number[]>([]);
  const [spaceTypeFilter, setSpaceTypeFilter] = useState<SpaceType[]>([]);

  const { data, loading } = useApiState({
    query: new UnoccupiedSpacesWithMetricsGetQuery(site.id, [MetricType.Temperature, MetricType.ElectricityKwhDelta, MetricType.WaterVolumeDelta])
  }, [site]);

  const pageDetails = useMemo(() => getPageDetails(t, status, data), [t, status, data]);
  const spaceTypes = useMemo(() => pageDetails.spaces.map(x => x.spaceType), [pageDetails]);
  const buildingFilterOptions = useMemo(() => buildings.map(x => ({ label: x.name, value: x.id })), [buildings]);

  const tableRows = useMemo(() => {
    if (!data) {
      return [];
    }

    let spaces = [...pageDetails.spaces];

    if (buildingFilter.length > 0) {
      spaces = spaces.filter(x => buildingFilter.includes(x.buildingId));
    }

    if (spaceTypeFilter.length > 0) {
      spaces = spaces.filter(x => spaceTypeFilter.includes(x.spaceType));
    }

    return spaces;
  }, [data, pageDetails, buildingFilter, spaceTypeFilter]);

  const handleRowClick = useCallback((row: UnoccupiedSpaceDto) => {
    trackAction(pageDetails.analytics, 'overview_live_updates');
    navigate(`../building/${row.buildingId}/floor/${row.floorId}/space/${row.spaceId}`);
  }, [pageDetails, trackAction, navigate]);

  const tableColumns: ITableColumn<UnoccupiedSpaceDto>[] = useMemo(() => ([
    {
      label: t('Space', { ns: 'common' }),
      key: 'name',
    },
    {
      label: t('Floor', { ns: 'common' }),
      key: 'floorName',
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName',
    },
    {
      label: `${t('Temperature', { ns: 'enums' })} (${getUnit(MetricType.Temperature)})`,
      key: 'temperature',
      sortFormat: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.Temperature);
        return metric && toLocale(MetricType.Temperature, parseFloat(metric.value), { round: 1 })
      },
      displayFormat: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.Temperature);
        return metric ? toLocale(MetricType.Temperature, parseFloat(metric.value), { round: 1 }).toString() : t('NA', { ns: 'common' })
      },
      displaySuffix: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.Temperature);
        return metric ? `(${dayjs(metric.measuredOn).format(localisation.dateFormats.date)})` : '';
      }
    },
    {
      label: `${t('Electricity', { ns: 'enums' })} ${t('Delta', { ns: 'common' })} (kWh)`,
      key: 'electricity',
      sortFormat: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.ElectricityKwhDelta);
        return metric && stringToFloat(metric.value);
      },
      displayFormat: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.ElectricityKwhDelta);
        return metric ? stringToFloat(metric.value, 3).toString() : t('NA', { ns: 'common' })
      },
      displaySuffix: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.ElectricityKwhDelta);
        return metric ? `(${dayjs(metric.measuredOn).format(localisation.dateFormats.date)})` : '';
      }
    },
    {
      label: `${t('Water', { ns: 'enums' })} ${t('Delta', { ns: 'common' })} (${getUnit(MetricType.WaterVolumeDelta)})`,
      key: 'water',
      sortFormat: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.WaterVolumeDelta);
        return metric && stringToFloat(metric.value);
      },
      displayFormat: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.WaterVolumeDelta);
        return metric ? toLocale(MetricType.WaterVolumeDelta, parseFloat(metric.value), { round: 3 }).toString() : t('NA', { ns: 'common' })
      },
      displaySuffix: space => {
        const metric = space.metrics.find(x => x.metricType === MetricType.WaterVolumeDelta);
        return metric ? `(${dayjs(metric.measuredOn).format(localisation.dateFormats.date)})` : '';
      }
    },
    {
      label: t('LastMotionDetected', { ns: 'common' }),
      key: 'motionMeasuredOn',
      sortFormat: space => space.motionMeasuredOn && new Date(space.motionMeasuredOn),
      displayFormat: space => space.motionMeasuredOn ? formatDistance(new Date(space.motionMeasuredOn), new Date(), { addSuffix: true }) : t('OverNDaysAgo', { ns: 'common', days: 7 })
    }
  ]), [t, toLocale, getUnit, localisation]);

  if (loading) {
    return (
      <LoadingWidget
        label={t('Loading', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    );
  }

  if (!data) {
    return null;
  }

  const handleExport = () => {
    // Example file name: Utopi_Bothwell Bridge_28 Jun 2024_Spaces with no motion over 7 days.csv 
    const fileName = `${tenant.name}_${site.name}_${(dayjs(new Date()).format(localisation.dateFormats.date))}_${pageDetails.title}`;

    const csv = convertToCSV([
      {
        key: 'name',
        header: t('Space', { ns: 'common' })
      },
      {
        key: 'spaceType',
        header: t('SpaceType', { ns: 'common' }),
        modifier: space => t(space.spaceType, { ns: 'enums' })
      },
      {
        key: 'floorName',
        header: t('Floor', { ns: 'common' })
      },
      {
        key: 'buildingName',
        header: t('Building', { ns: 'common' })
      },
      {
        key: 'metrics',
        header: `${t('Temperature', { ns: 'enums' })} (${getUnit(MetricType.Temperature)})`,
        modifier: space => {
          const metric = space.metrics.find(x => x.metricType === MetricType.Temperature);
          return metric ? toLocale(MetricType.Temperature, parseFloat(metric.value), { round: 1 }).toString() : t('NA', { ns: 'common' })
        }
      },
      {
        key: 'metrics',
        header: `${t('Electricity', { ns: 'enums' })} ${t('Delta', { ns: 'common' })} (kWh)`,
        modifier: space => {
          const metric = space.metrics.find(x => x.metricType === MetricType.ElectricityKwhDelta);
          return metric ? stringToFloat(metric.value, 3).toString() : t('NA', { ns: 'common' })
        }
      },
      {
        key: 'metrics',
        header: `${t('Water', { ns: 'enums' })} ${t('Delta', { ns: 'common' })} (${getUnit(MetricType.WaterVolumeDelta)})`,
        modifier: space => {
          const metric = space.metrics.find(x => x.metricType === MetricType.WaterVolumeDelta);
          return metric ? toLocale(MetricType.WaterVolumeDelta, parseFloat(metric.value), { round: 3 }).toString() : t('NA', { ns: 'common' })
        }
      },
      {
        key: 'motionMeasuredOn',
        header: t('LastMotionDetected', { ns: 'common' }),
        modifier: space => space.motionMeasuredOn ? format(new Date(space.motionMeasuredOn), localisation.dateFormats.default) : t('OverNDaysAgo', { ns: 'common', days: 7 })
      },
    ], tableRows);

    exportCSV(fileName, csv);
    trackAction('export_excel', 'overview_live_updates');
  };

  return (
    <PaddedContainer>
      <BackButton
        label={t('BackToOverview', { ns: 'navigation' })}
        url='./../..'
      />

      <FlexRow>
        <Title text={<>{pageDetails.title} <ThemeColored>({tableRows.length})</ThemeColored></>} />

        <div style={{ marginLeft: 'auto' }} />

        <SpaceTypeFilter
          spaceTypes={spaceTypes}
          onChange={setSpaceTypeFilter}
        />

        <Filter
          label={t('Building', { ns: 'common' })}
          options={buildingFilterOptions}
          onChange={setBuildingFilter}
          rightAlign
        />

        <Button
          secondary
          label={t('Export', { ns: 'common' })}
          onClick={handleExport}
          disabled={loading}
        />
      </FlexRow>

      <Table
        columns={tableColumns}
        records={tableRows}
        recordKey="spaceId"
        emptyMessage={t('NoSpacesFound', { ns: 'status' })}
        defaultSortColumn="temperature"
        defaultSortOrder={pageDetails.sortOrder}
        onRowClick={handleRowClick}
        loading={loading}
        cardEffect
        fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 230}
      />
    </PaddedContainer>
  );
};

export default UnoccupiedSpacesList;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
`;