import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Route as RouteType } from '@src/types/Route';
import { Floor } from '@api/models/Floor';
import { useApiState } from '@hooks/useApiState';
import ServiceIntegrationExistsQuery from '@api/queries/service-integration/ServiceIntegrationExistsQuery';
import { ProtectedRoute } from '@src/navigation/ProtectedRoute/ProtectedRoute';
import styled from 'styled-components';
import Sidebar from '@pages/site/buildings/components/layout/Sidebar';
import { useTranslation } from 'react-i18next';
import NoData from '@components/core/NoData';
import { BuildingWithFloors } from '@api/models/BuildingWithFloors';
import { useSiteContext } from '../SiteProvider';

export enum BuildingHierarchyLevel {
  Floor = 'floor',
  Space = 'space',
  Device = 'device',
}

type PropTypes = {
  routes?: RouteType[],
}

interface IBuildingContext {
  building: BuildingWithFloors;
  floor?: Floor;
  floors: Floor[];
  hasServiceIntegration: boolean;
  hierarchyLevel: BuildingHierarchyLevel;
}

export const BuildingContext = React.createContext({} as IBuildingContext);
export const useBuildingContext = () => useContext(BuildingContext);

const BuildingProvider = ({ routes }: PropTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { site, buildings } = useSiteContext();
  const { buildingId, floorId } = useParams<{ buildingId?: string, floorId?: string }>();
  const [building, setBuilding] = useState<BuildingWithFloors>();
  const [hierarchyLevel, setHierarchyLevel] = useState<BuildingHierarchyLevel>(BuildingHierarchyLevel.Floor);
  const [floor, setFloor] = useState<Floor>();

  // Set the appropriate hierarchy level to know which sidebar to render
  useEffect(() => {
    if (pathname.includes('/device/')) {
      setHierarchyLevel(BuildingHierarchyLevel.Device);
    } else if (pathname.includes('/space/')) {
      setHierarchyLevel(BuildingHierarchyLevel.Space);
    } else {
      setHierarchyLevel(BuildingHierarchyLevel.Floor);
    }
  }, [pathname]);

  const { data: serviceIntegrationAvailability } = useApiState({
    query: buildingId === undefined ? undefined : new ServiceIntegrationExistsQuery(parseInt(buildingId)),
    initialState: ({ hasServiceIntegration: false })
  }, [buildingId]);

  // When buildingId param in URL is undefined, navigate to the first building (and floor if it exists)
  useEffect(() => {
    if (!buildingId && buildings.length > 0) {
      const basePath = `${buildings[0].id}`;
      const floors = buildings[0]?.floors;
      const path = floors.length > 0 ? `${basePath}/floor/${floors[0].id}` : basePath;
      navigate(path, { replace: true });
    }
  }, [buildingId, buildings, navigate]);

  // When floorId param in URL is undefined, navigate to the first floor if it exists
  useEffect(() => {
    if (buildingId && building && !floorId && building.floors.length > 0) {
      navigate(`floor/${building.floors[0].id}`, { replace: true });
    }
  }, [buildingId, building, floorId, buildings, navigate]);

  // Handle change of buildingId param in URL
  useEffect(() => {
    if (buildingId) {
      setBuilding(buildings.find(x => x.id === parseInt(buildingId)));
    }
  }, [buildingId, buildings]);

  // Handle change of floorId param in URL
  useEffect(() => {
    if (!floorId) {
      setFloor(undefined);
    }

    if (building && floorId) {
      setFloor(building.floors.find(x => x.id === parseInt(floorId)));
    }
  }, [floorId, building]);

  const buildingContextProviderValue = useMemo(() => {
    // if building doesn't exist then set temporary initial building to resolve type error (but will not render as will return null if building is null)
    const initialBuilding = building ?? { id: 0, name: '', useSiteAddress: false, address: { addressLine1: '', city: '', country: '', postCode: '' }, floors: [] };
    return { building: initialBuilding, floor, floors: building?.floors ?? [], hasServiceIntegration: serviceIntegrationAvailability.hasServiceIntegration, hierarchyLevel }
  }, [building, floor, serviceIntegrationAvailability.hasServiceIntegration, hierarchyLevel]);

  if (!building) {
    return (
      <NoData
        label={t('BuildingNotFound', { ns: 'status' })}
        styles={{ margin: 80 }}
      />
    );
  }

  return (
    <BuildingContext.Provider value={buildingContextProviderValue}>
      <FlexRow>
        <Sidebar />

        <Content>
          <Routes>
            {routes?.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    route={route}
                    customProps={{ site, building }}
                  />
                }
              />
            ))}
          </Routes>
        </Content>
      </FlexRow>
    </BuildingContext.Provider>
  );
};

export default BuildingProvider;

const FlexRow = styled.div`
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;