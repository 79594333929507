import { SiteFairUsePolicy } from '@api/models/SiteFairUsePolicy';
import { GetQuery } from '@api/queries/common/GetQuery';

export class SiteFairUsePolicyGetByIdQuery extends GetQuery<SiteFairUsePolicy> {
  policyId: number;

  constructor(policyId: number) {
    super();
    this.policyId = policyId;
  }

  public targetUrl(): string {
    return `/FairUse/Policies/${this.policyId}`;
  }
}