import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { WarningDialog } from '@components/core/WarningDialog';
import { useApi } from '@hooks/useApi';
import { useModal } from '@hooks/useModal';
import { usePopover } from '@hooks/usePopover';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import DeviceNoteAttachmentModal from './NoteAttachmentModal';
import { NoteDto } from '@api/models/NoteDto';
import { NoteDeleteCommand } from '@api/queries/notes/NoteDeleteCommand';
import { Button } from '@components/core/Button';

type PropTypes = {
  note: NoteDto;
  userCanEdit: boolean;
  refreshNotes: () => void;
  setEditMode: (state: boolean) => void;
}

const NoteActions = ({ note, userCanEdit, refreshNotes, setEditMode }: PropTypes) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const { visible, toggle, ref } = usePopover({});
  const theme = useTheme();
  const { isOpen: attachmentDialogIsOpen, toggle: toggleAttatchmentDialog, ref: attachmentDialogRef } = useModal({});
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});

  const handleEdit = async () => {
    setEditMode(true);
    toggle();
  };

  const handleDelete = async () => {
    await execute({ query: new NoteDeleteCommand(note.id) });
    refreshNotes();
  };

  const PopoverContent = (
    <ActionMenu>
      <ActionLabel onClick={handleEdit}>
        {t('Edit', { ns: 'common' })}
      </ActionLabel>

      <ActionLabel onClick={toggleDeleteDialog}>
        {t('Delete', { ns: 'common' })}
      </ActionLabel>
    </ActionMenu>
  );

  return (
    <>
      <FlexRow>
        <Button
          small
          tertiary
          label={
            <>
              {t('Attachments', { ns: 'common' })} ({note.attachments.length})
              <AttachmentIcon icon={solid('paperclip')} />
            </>
          }
          style={{ color: theme.text.secondary, fontWeight: 400, padding: '4px 6px' }}
          onClick={() => toggleAttatchmentDialog()}
        />

        {userCanEdit &&
          <Popover
            ref={ref}
            visible={visible}
            popoverContent={PopoverContent}
            placement={PopoverPlacement.BottomRight}
            hideTriangle={true}
            offsetY={-8}
            offsetX={-58}
            containerStyles={{
              boxShadow: `0px 1px 3px 1px ${theme.palette.shadows.extraLight}`,
            }}
          >
            <Icon
              open={visible}
              icon={solid('ellipsis-vertical')}
              onClick={() => toggle()}
            />
          </Popover >
        }
      </FlexRow>

      <DeviceNoteAttachmentModal
        modalRef={attachmentDialogRef}
        open={attachmentDialogIsOpen}
        hide={toggleAttatchmentDialog}
        note={note}
        refreshNotes={refreshNotes}
        userCanEdit={userCanEdit}
      />

      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('DeviceCard.DeleteNoteConfirm', { ns: 'molecules' })}
        confirmButton={t('DeviceCard.Delete', { ns: 'molecules' })}
        onCancel={toggleDeleteDialog}
        onConfirm={handleDelete}
      />
    </>
  )
}

export default NoteActions;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const ActionMenu = styled.div`
  width: 150px;
`;

const ActionLabel = styled.div`
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;

const AttachmentIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  margin-left: 5px;
`;

const Icon = styled(FontAwesomeIcon) <{ open?: boolean }>`
  color: ${p => p.open ? p.theme.primary.main : p.theme.action.active};
  cursor: pointer;
  padding: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;