import styled, { css } from 'styled-components';
import { ReactComponent as SquigglyLineSvg } from '@assets/icons/squiggly-line.svg';
import { DeviceMetricsChartType, IDeviceMetricsChart } from '@components/charts/DeviceMetricsChart.types';
import { LineBarChartType } from '@components/charts/Chart.types';
import { MetricType_ChartColor } from '@api/enums/MetricType_ChartColor';
import { getCategoryChartColor } from '@utils/ChartUtils';

type PropTypes = {
  charts: IDeviceMetricsChart[];
  selected: number,
  onChange: (index: number) => void,
  selectedChartType: LineBarChartType
}

const ChartSelectTabs = ({ charts, selected, onChange, selectedChartType }: PropTypes) => {

  const getChartType = (chart: IDeviceMetricsChart, chartType: LineBarChartType) => 
    chart.options?.chartType ? chart.options.chartType === chartType : selectedChartType === chartType;

  return (
    <Wrapper>
      {charts.map((chart, i) => (
        <ChartSelectTab
          key={`chart-select-${i}`}
          selected={i === selected}
          onClick={() => onChange(i)}
          noData={chart.datasets.length === 0}
        >
          <Flex column={chart.options?.stacked ?? getChartType(chart, LineBarChartType.Line)}>
            {chart.metricTypes.map((metricType, j) => {
              // Render maximum 3 symbols
              if (j >= 3) {
                return null;
              }

              const color = chart.colorOverride ? chart.colorOverride[j] : MetricType_ChartColor(metricType);

              // Render symbol for category chart 
              if (chart.type === DeviceMetricsChartType.Category) {
                return (
                  <div key={j}>
                    <CategoryRectangle color={getCategoryChartColor(0)} style={{ width: '10px', marginLeft: '10px', marginBottom: '1px' }} />
                    <CategoryRectangle color={getCategoryChartColor(1)} style={{ width: '10px' }} />
                  </div>
                )
              }

              // Render symbols for line chart
              if (getChartType(chart, LineBarChartType.Line)) {
                return <SquigglyLine color={color} key={j} />;
              }

              // Render symbols for bar chart
              if (getChartType(chart, LineBarChartType.Bar)) {
                return <ColoredSquare color={color} key={j} />;
              }

              return null;
            })}
          </Flex>
          <div>
            {chart.label}
          </div>
        </ChartSelectTab>
      ))}
    </Wrapper>
  );
};

export default ChartSelectTabs;

const Wrapper = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Flex = styled.div<{ column?: boolean }>`
  display: flex;
  flex-direction: ${p => p.column ? 'column' : 'row'};
  gap: 2px;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
`;

const ChartSelectTab = styled.div<{ selected: boolean, noData: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 15px 20px;
  font-size: 16px;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;

  ${p => p.selected && css`
    background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
    border-radius: 5px;
  `}

  div {
    opacity: ${p => p.noData ? 0.6 : 1};
  }
`;

const SquigglyLine = styled(SquigglyLineSvg) <{ color: string }>`
  width: 16px;

  path {
    stroke: ${p => p.color}
  }

  &:not(:first-child) {
    margin-top: -2px;
  }
`;

const ColoredSquare = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background-color: ${p => p.color};
`;

const CategoryRectangle = styled.div<{ color: string }>`
  height: 6px;
  border-radius: 0px;
  background-color: ${p => p.color};
`;