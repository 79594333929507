import ExportCsvDto from '@api/models/ExportCsvDto';
import { DeviceType } from '@api/enums/DeviceType';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class CsvDevicesGetQuery extends GetQuery<ExportCsvDto> {
  buildingId: number;
  deviceType: DeviceType;

  constructor(buildingId: number, deviceType: DeviceType) {
    super();
    this.buildingId = buildingId;
    this.deviceType = deviceType;
  }

  public targetUrl(): string {
    return `/exports/CsvDevices/${this.buildingId}/${this.deviceType}`;
  }
}
