import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ReactNode, useCallback } from 'react';
import ReportSectionHeading from './ReportSectionHeading';
import { useTranslation } from 'react-i18next';
import { ReportMetadataDto } from '@api/models/ReportMetadataDto';
import ReportMetricType from '@api/enums/ReportMetricType';
import { numberOr0, numberWithCommas, round } from '@utils/NumberUtils';
import { ReportEnergyConsumptionDto } from '@api/models/ReportEnergyConsumptionDto';
import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { ReportAggregateCategory } from '@api/enums/ReportAggregateCategory';
import EsgReportDto from '@api/models/EsgReportDto';
import { Site } from '@api/models/Site';
import { useSiteContext } from '@pages/site/SiteProvider';
import { currencyDecimalName } from '@utils/CurrencyUtils';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

const fitwelGetNumStars = (fitwel?: string): number | undefined => {
  if (fitwel === undefined) {
    return undefined;
  }

  const fitwelNumeric = parseFloat(fitwel);

  if (fitwelNumeric >= 90 && fitwelNumeric <= 104) {
    return 1;
  }

  if (fitwelNumeric >= 105 && fitwelNumeric <= 124) {
    return 2;
  }

  if (fitwelNumeric >= 125 && fitwelNumeric <= 144) {
    return 3;
  }

  return undefined;
}

/**
 * Returns a metric's value if the metric is present
 */
const getMetric = (metaData: ReportMetadataDto[], metricType: ReportMetricType): string | undefined => {
  const metric = metaData.find(x => x.metricType === metricType);
  return metric?.value;
}

/**
 * Returns an array of report metadata dto for given metric types
 */
const getMetrics = (metaData: ReportMetadataDto[], metricTypes: ReportMetricType[]): ReportMetadataDto[] => {
  const metrics = metaData.filter((data) => metricTypes.includes(data.metricType));
  return metrics;
}

/**
 * Returns a metric value for a given building in a site if the metric is present
 */
const getBuildingMetric = (metricTypes: LocalMetricType[], desiredType: ReportMetricType): string | undefined => {
  const metric = metricTypes.find((metric) => metric.type === desiredType);
  return metric?.value;
}

/**
 * Returns true if at least one of the metrics is present
 */
const hasSomeMetrics = (metaData: ReportMetadataDto[], metricTypes: ReportMetricType[]): boolean => {
  return metaData.some(x => metricTypes.includes(x.metricType));
}

/**
 * The value is in kilograms
 */
const formatCarbonValue = (value: string | undefined): { value: number, suffix: string } | undefined => {
  if (value === undefined) {
    return undefined;
  }

  const numericValue = parseFloat(value);
  const unit = 'kgCO₂';
  const displayValue = round(numericValue, 2);

  return { value: displayValue, suffix: unit }
}

/**
 * The value is in grams
 */
const formatEmissionValue = (value: number | undefined): { value: number, suffix: string } | undefined => {
  if (value === undefined) {
    return undefined;
  }

  const unit = 'kgCO₂';
  const displayValue = round(value / 1000, 2);

  return { value: displayValue, suffix: unit }
}

const getTotalEmissions = (energyConsumptionMeasurements: ReportEnergyConsumptionDto[], category: ReportAggregateCategory): number | undefined => {
  const data = energyConsumptionMeasurements.find(x => x.aggregationCategory === category);
  return data?.energyConsumptionUsageData.flatMap(x => x.totalCarbonUsage).reduce((a, b) => numberOr0(a) + numberOr0(b), undefined);
}

const getTotalCost = (report: EsgReportDto, meterType: EnergyMeterType, category: ReportAggregateCategory): { usage: number, cost: number } | undefined => {
  const buildingOrSiteData = report.energyConsumptionMeasurements.find(x => x.aggregationCategory === category);
  const data = buildingOrSiteData?.energyConsumptionUsageData.find(x => x.energyType === meterType);
  const usage = data?.totalUsage;
  const cost = data?.totalCost;

  if (usage === undefined || cost === undefined) {
    return undefined;
  }

  return { usage: usage, cost: cost / 100 };
}

const getUniqueBuildingsInSite = (metaData: ReportMetadataDto[]) => {
  const uniqueBuildings: BuildingInSite[] = [];

  metaData.forEach((data) => {
    const existingBuilding = uniqueBuildings.find((building) => building.name === data.key);

    if (!existingBuilding) {
      uniqueBuildings.push({
        name: data.key,
        metrics: [{ type: data.metricType, value: data.value }]
      });
    }
    const existingMetricType = existingBuilding?.metrics.find((metric) => metric.type === data.metricType);
    if (!existingMetricType) {
      existingBuilding?.metrics.push({
        type: data.metricType,
        value: data.value
      });
    }
  });

  return uniqueBuildings;
}

interface IReportOverviewPage {
  report: EsgReportDto;
  pageHeaders: JSX.Element[];
  site?: Site;
}

type BuildingOverviewSectionProps = {
  sectionTitle?: string,
  floors?: string,
  spaces?: string,
  assetArea?: string
}

type LocalMetricType = {
  type: string,
  value: string
}

type BuildingInSite = {
  name: string,
  metrics: LocalMetricType[]
}

const ReportOverviewPage = ({ report, pageHeaders, site }: IReportOverviewPage) => {
  const { t } = useTranslation(['molecules']);
  const { siteCurrencyFormat } = useSiteContext();
  const { siteMetadata } = useSiteContext();
  const { displayString, toLocale, getUnit, localisation } = useLocalisation();
  const isSiteLevel = !!site;
  const aggregationCategory = isSiteLevel ? ReportAggregateCategory.Site : ReportAggregateCategory.Building;
  const metaData = report.metadataMeasurements;
  const numFitwelStars = fitwelGetNumStars(getMetric(metaData, ReportMetricType.Fitwel));
  const targetCarbon = formatCarbonValue(getMetric(metaData, ReportMetricType.TargetCarbon));
  const baselineCarbon = formatCarbonValue(getMetric(metaData, ReportMetricType.BaselineCarbon));
  const totalEmissions = formatEmissionValue(getTotalEmissions(report.energyConsumptionMeasurements, aggregationCategory));
  const electricityData = getTotalCost(report, EnergyMeterType.Electricity, aggregationCategory);
  const heatingData = getTotalCost(report, EnergyMeterType.Heating, aggregationCategory);
  const waterData = getTotalCost(report, EnergyMeterType.Water, aggregationCategory);
  const buildingMetricTypes = [ReportMetricType.ChildTotalFloors, ReportMetricType.ChildTotalAreaM3, ReportMetricType.ChildTotalSpaces];
  const hasBuildingMetrics = isSiteLevel
    ? hasSomeMetrics(metaData, buildingMetricTypes)
    : hasSomeMetrics(metaData, [ReportMetricType.TotalFloors, ReportMetricType.TotalSpaces, ReportMetricType.TotalAreaM3])

  const buildingsInSite = isSiteLevel ? getUniqueBuildingsInSite(getMetrics(metaData, buildingMetricTypes)) : undefined;

  const decimalName = siteMetadata?.currency != null ? currencyDecimalName(siteMetadata?.currency, t) : t('GBPDecimal', { ns: 'common' });

  const BuildingOverviewSection = ({ sectionTitle, floors, spaces, assetArea }: BuildingOverviewSectionProps) => (
    <>
      {
        sectionTitle &&
        <SectionTitle>{sectionTitle}</SectionTitle>
      }
      <FlexRow>
        <ValueLabelPair
          value={floors}
          label={t('ESG.Floors', { ns: 'molecules' })}
        />
        <ValueLabelPair
          value={spaces}
          label={t('ESG.Spaces', { ns: 'molecules' })}
        />
        <ValueLabelPair
          value={assetArea && displayString(AltUnits.Area, assetArea)}
          label={t('ESG.AssetArea', { ns: 'molecules' })}
        />
      </FlexRow>
    </>
  )

  const localisedReportMetric = useCallback((reportMetricType: ReportMetricType): string => {
    const localisedReportMetric = getMetric(metaData, reportMetricType);
    if (localisedReportMetric) {
      return localisedReportMetric;
    }
    return '';
  }, [metaData]);

  return (
    <OverviewPageWrapper>
      {pageHeaders[0]}
      <ReportSectionHeading
        label={isSiteLevel
          ? `${t('ESG.SiteOverview', { ns: 'molecules' })} - ${site.name}`
          : t('ESG.BuildingOverview', { ns: 'molecules' })}
        style={{ marginBottom: '3mm' }} />

      {/* site overview */}
      {isSiteLevel &&
        <OverviewPageSection>
          <SectionTitle>{t('ESG.Site', { ns: 'molecules' })}</SectionTitle>
          <FlexRow>
            <ValueLabelPair
              value={displayString(AltUnits.Area, localisedReportMetric(ReportMetricType.TotalAreaM3))}
              label={t('ESG.TotalAssetArea', { ns: 'molecules' })}
            />
          </FlexRow>
        </OverviewPageSection>
      }

      {/* buildings overview */}
      {hasBuildingMetrics &&
        <OverviewPageSection>
          <SectionTitle>
            {isSiteLevel ?
              t('ESG.Buildings', { ns: 'molecules' }) :
              t('ESG.Building', { ns: 'molecules' })}
          </SectionTitle>
          {isSiteLevel && buildingsInSite ?
            buildingsInSite.map((building) =>
              <BuildingWrapper key={building.name}>
                <BuildingOverviewSection
                  sectionTitle={building.name}
                  floors={getBuildingMetric(building.metrics, ReportMetricType.ChildTotalFloors)}
                  spaces={getBuildingMetric(building.metrics, ReportMetricType.ChildTotalSpaces)}
                  assetArea={getBuildingMetric(building.metrics, ReportMetricType.ChildTotalAreaM3)}
                />
              </BuildingWrapper>
            ) :
            <BuildingOverviewSection
              floors={getMetric(metaData, ReportMetricType.TotalFloors)}
              spaces={getMetric(metaData, ReportMetricType.TotalSpaces)}
              assetArea={getMetric(metaData, ReportMetricType.TotalAreaM3)}
            />
          }
        </OverviewPageSection>
      }

      {hasSomeMetrics(metaData, [
        ReportMetricType.ElectricityPricePerKwh,
        ReportMetricType.HeatingPricePerKwh,
        ReportMetricType.WaterPricePerM3,
        ReportMetricType.WasteWaterPricePerM3,
        ReportMetricType.GasPricePerM3
      ]) && localisation.showPriceLine &&
        <OverviewPageSection>
          <SectionTitle>
            {t('ESG.UtilityPricing', { ns: 'molecules' })}
          </SectionTitle>

          <FlexRow>
            <ValueLabelPair
              label={t('ESG.Electricity', { ns: 'molecules' })}
              value={getMetric(metaData, ReportMetricType.ElectricityPricePerKwh)}
              suffix={` ${decimalName}/kWh`}
              allowEmpty
            />
            <ValueLabelPair
              label={t('ESG.Heating', { ns: 'molecules' })}
              value={getMetric(metaData, ReportMetricType.HeatingPricePerKwh)}
              suffix={` ${decimalName}/kWh`}
              allowEmpty
            />
            <ValueLabelPair
              label={t('ESG.Water', { ns: 'molecules' })}
              value={getMetric(metaData, ReportMetricType.WaterPricePerM3)}
              suffix={` ${decimalName}/m³`}
              allowEmpty
            />
            <ValueLabelPair
              label={t('ESG.WasteWater', { ns: 'molecules' })}
              value={getMetric(metaData, ReportMetricType.WasteWaterPricePerM3)}
              suffix={` ${decimalName}/m³`}
              allowEmpty
            />
            <ValueLabelPair
              label={t('ESG.Gas', { ns: 'molecules' })}
              value={getMetric(metaData, ReportMetricType.GasPricePerM3)}
              suffix={` ${decimalName}/m³`}
              allowEmpty
            />
          </FlexRow>
        </OverviewPageSection>
      }

      {(electricityData || heatingData || waterData) && localisation.showPriceLine &&
        <OverviewPageSection>
          <SectionTitle>
            {t('ESG.Costs', { ns: 'molecules' })}<sup>1</sup>
          </SectionTitle>

          <FlexRow>
            {electricityData &&
              <ValueLabelPair
                label={<>{t('ESG.Electricity', { ns: 'molecules' })}<br /><span>({numberWithCommas(electricityData.usage, 0)}kWh)</span></>}
                value={siteCurrencyFormat(electricityData.cost)}
              />
            }
            {heatingData &&
              <ValueLabelPair
                label={<>{t('ESG.Heating', { ns: 'molecules' })}<br /><span>({numberWithCommas(heatingData.usage, 0)}kWh)</span></>}
                value={siteCurrencyFormat(heatingData.cost)}
              />
            }
            {waterData &&
              <ValueLabelPair
                label={<>{t('ESG.Water', { ns: 'molecules' })}<br /><span>({numberWithCommas(waterData.usage, 0)}m³)</span></>}
                value={siteCurrencyFormat(waterData.cost)}
              />
            }
          </FlexRow>

          <FlexRow>
            <Footnote>
              <sup>1</sup>{t('ESG.CostsText', { ns: 'molecules' })}
            </Footnote>
          </FlexRow>
        </OverviewPageSection>
      }
      <OverviewPageSection>
        <SectionTitle>
          {t('ESG.Emissions', { ns: 'molecules' })}
        </SectionTitle>

        <FlexRow style={{ marginBottom: '15px' }}>
          <ValueLabelPair
            label={t('ESG.ElectricityCarbonIntensity', { ns: 'molecules' })}
            value={<>{toLocale(AltUnits.CarbonIntensityForecast, parseFloat(localisedReportMetric(ReportMetricType.ElectricityCarbonIntensity)) / 1000, { round: 3 })}{'\u00A0'}</>}
            suffix={getUnit(AltUnits.CarbonIntensityForecast)}
            allowEmpty
          />
          <ValueLabelPair
            label={t('ESG.HeatingCarbonIntensity', { ns: 'molecules' })}
            value={<>{toLocale(AltUnits.CarbonIntensityForecast, parseFloat(localisedReportMetric(ReportMetricType.HeatingCarbonIntensity)) / 1000, { round: 3 })}{'\u00A0'}</>}
            suffix={getUnit(AltUnits.CarbonIntensityForecast)}
            allowEmpty
          />
          <ValueLabelPair
            label={t('ESG.WaterCarbonIntensity', { ns: 'molecules' })}
            value={<>{toLocale(AltUnits.CarbonIntensityVolume, parseFloat(localisedReportMetric(ReportMetricType.WaterCarbonIntensity)) / 1000, { round: 3 })}{'\u00A0'}</>}
            suffix={getUnit(AltUnits.CarbonIntensityVolume)}
            allowEmpty
          />
          <ValueLabelPair
            label={t('ESG.WasteWaterCarbonIntensity', { ns: 'molecules' })}
            value={<>{toLocale(AltUnits.CarbonIntensityVolume, parseFloat(localisedReportMetric(ReportMetricType.WasteWaterCarbonIntensity)) / 1000, { round: 3 })}{'\u00A0'}</>}
            suffix={getUnit(AltUnits.CarbonIntensityVolume)}
            allowEmpty
          />
          <ValueLabelPair
            label={t('ESG.GasCarbonIntensity', { ns: 'molecules' })}
            value={<>{toLocale(AltUnits.CarbonIntensityVolume, parseFloat(localisedReportMetric(ReportMetricType.GasCarbonIntensity)) / 1000, { round: 3 })}{'\u00A0'}</>}
            suffix={getUnit(AltUnits.CarbonIntensityVolume)}
            allowEmpty
          />
        </FlexRow>

        {(baselineCarbon || targetCarbon || totalEmissions) &&
          <FlexRow>
            <ValueLabelPair
              label={t('ESG.MonthCO2Emissions', { ns: 'molecules' })}
              value={<>{totalEmissions?.value && toLocale(AltUnits.Weight, totalEmissions?.value, { round: 2 })}{'\u00A0'}</>}
              suffix={<>{getUnit(AltUnits.CO2WeightArea)}</>}
            />
            <ValueLabelPair
              label={t('ESG.BaselineCO2', { ns: 'molecules' })}
              value={<>{baselineCarbon?.value && toLocale(AltUnits.Weight, baselineCarbon?.value, { round: 2 })}{'\u00A0'}</>}
              suffix={<>{getUnit(AltUnits.CO2WeightArea)}</>}
            />
            <ValueLabelPair
              label={t('ESG.TargetCO2', { ns: 'molecules' })}
              value={<>{targetCarbon?.value && toLocale(AltUnits.Weight, targetCarbon?.value, { round: 2 })}{'\u00A0'}</>}
              suffix={<>{getUnit(AltUnits.CO2WeightArea)}</>}
            />
          </FlexRow>
        }

      </OverviewPageSection>


      {hasSomeMetrics(metaData, [
        ReportMetricType.Gresb,
        ReportMetricType.Breeam,
        ReportMetricType.Epc,
        ReportMetricType.Fitwel
      ]) &&
        <OverviewPageSection>
          <SectionTitle>
            {t('ESG.Certifications', { ns: 'molecules' })}
          </SectionTitle>

          <FlexRow>
            <ValueLabelPair
              value={getMetric(metaData, ReportMetricType.Gresb)}
              suffix='%'
              label='GRESB'
            />

            <ValueLabelPair
              value={getMetric(metaData, ReportMetricType.Breeam)}
              suffix='%'
              label='BREEAM'
            />

            <ValueLabelPair
              value={getMetric(metaData, ReportMetricType.Epc)}
              label='EPC'
            />

            <ValueLabelPair
              value={numFitwelStars &&
                [...Array(numFitwelStars)].map((_, i) => (
                  <Star key={i} icon={solid('star')} />
                ))
              }
              label='Fitwel'
            />
          </FlexRow>
        </OverviewPageSection>
      }
    </OverviewPageWrapper>
  );
}

export default ReportOverviewPage;

const OverviewPageWrapper = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

const OverviewPageSection = styled.div<{ halfWidth?: boolean }>`
  float: left;
  width: ${p => p.halfWidth ? '50%' : '100%'};
  padding-bottom: 5mm;
  margin-bottom: 4mm;
  &:not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  }

  page-break-inside: avoid;
`;

const SectionTitle = styled.div`
  font-size: 10pt;
  font-weight: 500;
  color: ${p => p.theme.palette.printStyles.text.medium};

  margin-bottom: 2mm;
`;

const BuildingWrapper = styled.div`
  & + * {
    margin-top: 15px;
  }
`

const FlexRow = styled.div`
  display: flex;
  gap: 10mm;
`;

const Star = styled(FontAwesomeIcon)`
  font-size: 9pt;
  color: #FFAA37;
`;

const Footnote = styled.span`
  font-size: 8pt;
  line-height: 8pt;
  color: ${p => p.theme.palette.printStyles.text.medium};
  margin-top: 5mm;
`;

//#endregion

//#region ValueLabelPair

interface IValueLabelPair {
  heading?: ReactNode;
  value?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  label?: ReactNode;
  allowEmpty?: boolean;
}

const ValueLabelPair = ({ heading, value, prefix, suffix, label, allowEmpty }: IValueLabelPair) => {
  if (value === undefined && !allowEmpty) {
    return null;
  }

  return (
    <div>
      {heading &&
        <Heading>{heading}</Heading>
      }
      <PrefixSuffix>{value !== undefined && prefix}</PrefixSuffix>
      <Value hasValue={value !== undefined}>{value ?? '--'}</Value>
      <PrefixSuffix>{value !== undefined && suffix}</PrefixSuffix>
      <Label>{label}</Label>
    </div>
  )
}

const Heading = styled.div`
  font-size: 9pt;
  color: ${p => p.theme.palette.printStyles.text.fair};
  margin-bottom: 2mm;
`;

const Value = styled.div<{ hasValue: boolean }>`
  display: inline-block;
  font-size: 10pt;
  font-weight: ${p => p.hasValue ? 500 : 400};
  color: #4d5664;
`;

const PrefixSuffix = styled.div`
  display: inline-block;
  font-size: 8pt;
  font-weight: 500;
  color: #4d5664;
`;

const Label = styled.div`
  font-size: 8pt;
  line-height: 12pt;
  color: ${p => p.theme.palette.printStyles.text.fair};
`;

//#endregion
