import { MetricType } from '@api/enums/MetricType';
import { GetQuery } from '@api/queries/common/GetQuery';

export class DevicesMetricTypesGetBySiteIdQuery extends GetQuery<MetricType[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/devices/DevicesMetricTypesBySiteId/${this.siteId}`;
  }
}
