import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@components/core/Button';
import { Modal } from '@components/core/Modal';
import { isNullOrUndefined } from '@utils/NumberUtils';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Label from './Label';

type LabelWithCharacterCountProps = {
  label: string;
  inputName: string;
  modalContent?: ReactNode;
  characterLimit?: number;
  currentCharacterCount?: number;
};

const LabelWithCharacterCount = ({label, inputName,  modalContent, characterLimit, currentCharacterCount}: LabelWithCharacterCountProps) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(['molecules', 'common']);

  return (
    <>
      <LabelContainer>
        <LabelTextContainer>
          <Label inputName={inputName} text={label} />
          {modalContent && (
            <IconButton onClick={() => setShowModal(true)} type="button">
              <FontAwesomeIcon icon={regular('circle-question')} />
            </IconButton>
          )}
        </LabelTextContainer>
        {!isNullOrUndefined(characterLimit) && !isNullOrUndefined(currentCharacterCount) && (
          <CharacterCount>
            {t('ResidentApp.CharacterCount', {
              count: currentCharacterCount,
              limit: characterLimit,
            })}
          </CharacterCount>
        )}
      </LabelContainer>

      {modalContent && (
        <Modal isOpen={showModal} plainModal width="600px">
          <ModalContent>
            {modalContent}
            <Button
              onClick={() => setShowModal(false)}
              label={t('Ok', { ns: 'common' })}
              style={{ marginLeft: 'auto' }}
            />
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default LabelWithCharacterCount;

const LabelTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const LabelContainer = styled(LabelTextContainer)`
  margin-bottom: 8px;
  justify-content: space-between;
`;

const CharacterCount = styled.span`
  font-size: 12px;
  color: ${(p) => p.theme.palette.forms.label.color};
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: ${(p) => p.theme.palette.text.fair};
  font-size: 12px;
`;

const ModalContent = styled.div`
  padding: 25px 20px;
`;