import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LineBarChartType } from '@components/charts/Chart.types';
import { Button } from '@components/core/Button';

type PropTypes = {
  hasBeenZoomed: boolean,
  handleResetZoom: () => void,
  selectedChartType: LineBarChartType,
  setSelectedChartType: (value: LineBarChartType) => void,
  toggleModal?: () => void
  hideChartTypeToggle?: boolean,
}

const ChartActionButtons = ({ hasBeenZoomed, handleResetZoom, selectedChartType, setSelectedChartType, toggleModal, hideChartTypeToggle }: PropTypes) => {
  return (
    <FlexRow>
      {hasBeenZoomed &&
        <Button
          tertiary
          label={<><ZoomIcon icon={regular('magnifying-glass-minus')} />Reset</>}
          onClick={handleResetZoom}
        />
      }

      {!hideChartTypeToggle &&
        <ChartTypeToggle>
          <ChartTypeToggleOption
            selected={selectedChartType === LineBarChartType.Line}
            onClick={() => setSelectedChartType(LineBarChartType.Line)}
          >
            <FontAwesomeIcon icon={solid('chart-line')} />
          </ChartTypeToggleOption>
          <ChartTypeToggleOption
            selected={selectedChartType === LineBarChartType.Bar}
            onClick={() => setSelectedChartType(LineBarChartType.Bar)}
          >
            <FontAwesomeIcon icon={solid('chart-column')} />
          </ChartTypeToggleOption>
        </ChartTypeToggle>
      }

      {toggleModal &&
        <ModalToggleIcon onClick={toggleModal}>
          <FontAwesomeIcon icon={regular('question-circle')} />
        </ModalToggleIcon>
      }
    </FlexRow>
  );
};

export default ChartActionButtons;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ChartModalToggleIcon = styled.div`
  font-size: 16px;
  font-weight: 500;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 3px;

  svg > path {
    fill: ${p => p.theme.palette.text.weak};  
  }
`;

export const ChartTypeToggle = styled.div`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
`;

export const ChartTypeToggleOption = styled.div<{ selected?: boolean }>`
  font-size: 16px;
  border: 1px solid ${p => p.theme.palette.borders.medium};

  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  :first-child {
    padding: 0 9px 0 8px;
    border-width: 1px 0 1px 1px;
    border-radius: 3px 0 0 3px;
  }
  :nth-child(2) {
    padding: 0 8px 0 9px;
    border-width: 1px 1px 1px 0;
    border-radius: 0 3px 3px 0;
  }

  ${p => p.selected && css`
    color: ${p.theme.palette.text.onPrimary};
    background-color: ${p.theme.palette.primary};
    border-color: ${p.theme.palette.primary};
  `}

  svg > path {
    fill: ${p => p.selected ? p.theme.palette.text.onPrimary : p.theme.palette.text.weak};  
  }
`;

export const ZoomResetButton = styled.div`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 2px 10px;
  white-space: nowrap;

  color: ${p => p.theme.palette.primary};
  border: 1px solid ${p => p.theme.palette.primary};
  border-radius: 3px;
  
  &:hover {
    color: ${p => p.theme.palette.text.onPrimary};
    background-color: ${p => p.theme.palette.primary};
    border-color: ${p => p.theme.palette.primary};
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

const ZoomIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 6px;
`;

const ModalToggleIcon = styled.div`
  font-size: 16px;
  font-weight: 500;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 3px;

  svg > path {
    fill: ${p => p.theme.palette.text.weak};  
  }
`;