import { Feature } from '@api/models/Feature';
import { Tenant } from '@api/models/Tenant';
import styled from 'styled-components';
import FeatureTenantListItem from './FeatureTenantListItem';

interface IFeatureTenantList {
  feature: Feature;
  tenants: Tenant[];
  refresh: () => void;
}

const FeatureTenantList = ({ feature, tenants, refresh }: IFeatureTenantList) => {
  return (
    <Wrapper>
      {tenants.map((tenant, i) => (
        <FeatureTenantListItem
          key={i}
          feature={feature}
          tenant={tenant}
          refresh={refresh}
        />
      ))}
    </Wrapper>
  );
};

export default FeatureTenantList;

const Wrapper = styled.div`
  padding: 10px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;