import { DeviceGateway } from '@api/models/DeviceGateway';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DeviceGatewaysGetBySiteIdQuery extends GetQuery<DeviceGateway[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/gateway/getDeviceGatewaysBySiteId/${this.siteId}`;
  }
}
