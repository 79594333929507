import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { format, formatDistance } from 'date-fns';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { ITableColumn } from '@components/table/Table.types';
import { useApiState } from '@hooks/useApiState';
import { DevicesGetAllInactiveQuery } from '@api/queries/devices/DevicesGetAllInactiveQuery';
import { BreadcrumbHeight, TopBarHeight } from '@src/constants/LayoutConstants';
import { Table } from '@components/table/Table';

type InactiveDevicesRowItem = {
  deviceId: number,
  friendlyName: string,
  deviceIdentifier: string,
  floorId: number,
  spaceId: number,
  floorNumber: number,
  spaceName: string,
  lastMeasuredOn?: string
}

const tableColumns: ITableColumn<InactiveDevicesRowItem>[] = [
  {
    label: 'Device',
    key: 'friendlyName'
  },
  {
    label: 'Device Identifier',
    key: 'deviceIdentifier'
  },
  {
    label: 'Floor',
    key: 'floorNumber'
  },
  {
    label: 'Location',
    key: 'spaceName'
  },
  {
    label: 'Latest Reading',
    key: 'lastMeasuredOn',
    sortFormat: device => device.lastMeasuredOn && new Date(device.lastMeasuredOn),
    displayFormat: device => device.lastMeasuredOn ? format(new Date(device.lastMeasuredOn), 'dd MMM yyyy') : 'Never',
    displaySuffix: device => device.lastMeasuredOn ? `(${formatDistance(new Date(device.lastMeasuredOn), new Date(), { addSuffix: true })})` : '',
  }
];

type PropTypes = {
  buildingId: number
}

const InactiveDevicesWidget = ({ buildingId }: PropTypes) => {
  const { t } = useTranslation(['molecules']);
  const navigate = useNavigate();
  const [inactiveDevices, setInactiveDevices] = useState<InactiveDevicesRowItem[]>([]);
  const { data: devices, loading } = useApiState({
    query: new DevicesGetAllInactiveQuery(buildingId),
    initialState: []
  }, [buildingId]);

  /**
   * Function is executed once the devices are fetched from the API.
   */
  useEffect(() => {
    if (!devices) {
      return;
    }

    const inactiveDeviceItems: InactiveDevicesRowItem[] = devices.map(x => {
      return {
        deviceId: x.deviceId,
        friendlyName: x.friendlyName,
        deviceIdentifier: x.deviceIdentifier,
        floorId: x.floorId,
        spaceId: x.spaceId,
        floorNumber: x.floorNumber,
        spaceName: x.spaceName,
        lastMeasuredOn: x.lastMeasuredOn,
      };
    });

    setInactiveDevices(inactiveDeviceItems);
  }, [devices]);

  return (
    <>
      <FlexRow>
        <Table
          columns={tableColumns}
          records={inactiveDevices}
          recordKey="deviceId"
          emptyMessage={t('NoInactiveDevices', { ns: 'molecules' })}
          defaultSortColumn="lastMeasuredOn"
          loading={loading}
          fullHeightSubtractor={TopBarHeight + BreadcrumbHeight + 440}
          onRowClick={(row: InactiveDevicesRowItem) => { navigate(`./../../../floor/${row.floorId}/space/${row.spaceId}/device/${row.deviceId}`) }}
          card={{
            cardTitle: t('InactiveDevices', { ns: 'molecules' }),
            modalTitle: t('InactiveDevices', { ns: 'molecules' }),
            modalContent: (
              <>
                <Trans i18nKey='molecules:InactiveDeviceText'>
                  <p></p>
                  <p></p>
                </Trans>
              </>
            )
          }}
        />

      </FlexRow>
    </>
  );
}

export default InactiveDevicesWidget;

// Temporarily make this widget span the whole screen
// until we have any other widgets to share the page with
const FlexRow = styled.div`
  width: 100%;
`;