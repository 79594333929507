import { Device } from '@api/models/Device';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class DevicesOfSameModelInSpaceByGetDeviceIdQuery extends GetQuery<Device[]> {
  deviceId: number;

  constructor(deviceId: number) {
    super();
    this.deviceId = deviceId;
  }

  public targetUrl(): string {
    return `/devices/DevicesOfSameModelInSpaceByDeviceId/${this.deviceId}`
  }
}