import { OperatorPatchQuery } from '@api/queries/resident-app/common/OperatorPatchQuery';
import { OperatorUserDto } from '@api/models/OperatorUserDto';

export default class UsersResidentAppUpdateCommand extends OperatorPatchQuery<OperatorUserDto> {
  id: number;
  firstName: string;
  lastName: string;
  spaceId: number;
  siteId: number;
  moveInDate: string;
  moveOutDate: string;

  constructor(id: number, firstName: string, lastName: string, spaceId: number, siteId: number, moveInDate: string, moveOutDate: string) {
    super();
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.spaceId = spaceId;
    this.siteId = siteId;
    this.moveInDate = moveInDate;
    this.moveOutDate = moveOutDate;
  }

  public targetUrl(): string {
    return '/Users/Update';
  }
}
