import { MetricType } from '@api/enums/MetricType';
import { TimeGranularity } from '@api/enums/TimeGranularity';
import { LineBarChartType } from '@components/charts/Chart.types';
import { stringToFloat, stringToPercentage } from '@utils/NumberUtils';
import { Chart, ChartTypeRegistry, ScriptableContext } from 'chart.js';
import { TFunction } from 'i18next';
import { darken, lighten, transparentize } from 'polished';

/**
 * Colors to be used for charts with a 'category' y-axis.
 */
const CategoryChartColors = [
  '#44A901',
  '#516EFF',
  '#FF4B5A',
  '#FFAA37',
  '#00AEED',
  '#7020FF'
];

/**
 * Given an index, returns the corresponding category chart color.
 */
export const getCategoryChartColor = (i: number) => {
  return CategoryChartColors[i % CategoryChartColors.length];
}

/**
 * Colors to be used for charts with a 'category' y-axis.
 */
const ReportChartColors = [
  '#FE6148',
  '#FE9A3E',
  '#FED234',
  '#69C02F',
  '#3DA5D9',
  '#7F4AD9',
  '#FF2C3D',
  '#3A9000',
  '#2364AA',
  '#355679'
];

/**
 * Given an index, returns the corresponsing category chart color.
 */
export const getReportChartColor = (i: number) => {
  return ReportChartColors[i % ReportChartColors.length];
}

/**
 * Definition for a unit type of a chart dataset point.
 */
export type ChartDataUnit = {
  /**
   * Label for that unit
   */
  label?: string,
  /**
   * Prefix for that unit
   */
  prefix?: string,
  /**
   * Suffix for that unit
   */
  suffix?: string,
  /**
   * Function to be applied to each value individually
   */
  modifier?: (x: string, t?: TFunction) => string | number
}

/**
 * Maps a MetricType to a unit type.
 */
const MetricTypeToUnitMapping: { [key: string]: ChartDataUnit } = {

  /** Common */
  [MetricType.BatteryPercentage]: { label: 'Percentage (%)', suffix: '%' },
  [MetricType.BatteryVoltage]: { label: 'Voltage (V)', suffix: 'V' },
  [MetricType.SignalStrength]: { label: 'Percentage (%)', suffix: '%' },

  /** Enviromental */
  [MetricType.OutdoorTemperature]: { label: 'Celsius (°C)', suffix: '°C', modifier: (x) => (stringToFloat(x, 1)) },
  [MetricType.Temperature]: { label: 'Celsius (°C)', suffix: '°C', modifier: (x) => (stringToFloat(x, 1)) },
  [MetricType.TemperatureTarget]: { label: 'Celsius (°C)', suffix: '°C', modifier: (x) => (stringToFloat(x, 1)) },
  [MetricType.Light]: { label: 'Lux', suffix: 'lux', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.LightLevel]: { label: 'Light Level', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.CO2]: { label: 'Part/Million (ppm)', suffix: 'ppm', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.Noise]: { label: 'Decibel (db)', suffix: 'db', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.OutdoorHumidity]: { label: 'Percentage (%)', suffix: '%', modifier: (x) => (stringToPercentage(x, 0)) },
  [MetricType.Humidity]: { label: 'Percentage (%)', suffix: '%', modifier: (x) => (stringToPercentage(x, 0)) },
  [MetricType.ElectricityKwh]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.GasVolume]: { label: 'Volume (m³)', suffix: 'm³', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.GasVolumeDelta]: { label: 'Volume (m³)', suffix: 'm³', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.GasKwh]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.GasKwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.HeatingKwh]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.WaterVolume]: { label: 'Volume (m³)', suffix: 'm³', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityKwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityExportKwh]: { label: 'Export (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityExportKwhDelta]: { label: 'Export (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.HeatingKwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.WaterVolumeDelta]: { label: 'Volume (m³)', suffix: 'm³', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.RelayOutputStatus]: { modifier: (x) => (x === 'true' ? 'ON' : 'OFF') },
  [MetricType.ContactSwitch]: { modifier: (x) => (x === '1' ? 'Open' : 'Closed') },
  [MetricType.WaterMeterAlarm]: { modifier: (x, t) => t ? t(`WaterMeterAlarmCodes.${x}`, { ns: 'enums' }) : x },
  [MetricType.VOC]: { label: 'Weight/Volume (µg/m³)', suffix: 'µg/m³', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.Pressure]: { label: 'Pressure (mbar)', suffix: 'mbar', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.CO]: { label: 'Part/Million (ppm)', suffix: 'ppm', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.O2]: { label: 'Percentage (%)', suffix: '%', modifier: (x) => (stringToPercentage(x, 0)) },
  [MetricType.H2S]: { label: 'Part/Million (ppm)', suffix: 'ppm', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.Pollutants]: { label: 'kOhm', suffix: 'kOhm', modifier: (x) => (stringToFloat(x, 1)) },
  [MetricType.Particulates2_5]: { label: 'Weight/Volume (µg/m³)', suffix: 'µg/m³', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.Particulates10]: { label: 'Weight/Volume (µg/m³)', suffix: 'µg/m³', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.NO]: { label: 'Part/Million (ppm)', suffix: 'ppm', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.NO2]: { label: 'Weight/Volume (µg/m³)', suffix: 'µg/m³', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.IAQ]: { label: 'AirQuality', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.PIR]: { modifier: x => stringToFloat(x, 0) },

  /** Renewable Meter Metrics */
  [MetricType.ElectricityConsumedL1KwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityConsumedL2KwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityConsumedL3KwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityGeneratedL1KwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityGeneratedL2KwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityGeneratedL3KwhDelta]: { label: 'Consumption (kWh)', suffix: 'kWh', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityL1ActivePower]: { label: 'Consumption (kW)', suffix: 'kW', modifier: (x) => (stringToFloat(x, 3) / 1000) },
  [MetricType.ElectricityL2ActivePower]: { label: 'Consumption (kW)', suffix: 'kW', modifier: (x) => (stringToFloat(x, 3) / 1000) },
  [MetricType.ElectricityL3ActivePower]: { label: 'Consumption (kW)', suffix: 'kW', modifier: (x) => (stringToFloat(x, 3) / 1000) },
  [MetricType.ElectricityL1Voltage]: { label: 'Voltage (V)', suffix: 'V', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityL2Voltage]: { label: 'Voltage (V)', suffix: 'V', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityL3Voltage]: { label: 'Voltage (V)', suffix: 'V', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityL1Current]: { label: 'Current (A)', suffix: 'A', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityL2Current]: { label: 'Current (A)', suffix: 'A', modifier: (x) => (stringToFloat(x, 2)) },
  [MetricType.ElectricityL3Current]: { label: 'Current (A)', suffix: 'A', modifier: (x) => (stringToFloat(x, 2)) },

  /** IoTSens Sound Monitor */
  [MetricType.Percentile_P1]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.Percentile_P10]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.Percentile_P50]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.Percentile_P90]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.Percentile_P99]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.DBA_Min]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.LAEQ]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },
  [MetricType.DBA_Max]: { label: 'Decibel (dBA)', suffix: 'dBA', modifier: (x) => (stringToFloat(x, 0)) },

  /** External Metrics */
  [MetricType.CarbonIntensityForecast]: { label: 'Weight/Consumption', suffix: ' gCO₂/kWh', modifier: (x) => (stringToFloat(x, 2)) },

  /** People Count */
  [MetricType.IsolatedPeopleCount]: { modifier: (x) => (parseInt(x) < 0 ? 0 : x) },

  /** Utopi Panel Heater */
  [MetricType.OperationalMode]: { modifier: (x, t) => t ? t(`PanelHeaterOperationalModes.${x}`, { ns: 'enums' }) : x }
};

/**
 * Given a MetricType, returns the corresponsing unit type.
 */
export const getChartDataUnit = (metricType: MetricType): ChartDataUnit => {
  if (MetricTypeToUnitMapping[metricType]) {
    return MetricTypeToUnitMapping[metricType];
  }

  return {};
};

/**
 * ScaleRange type
 */
export type ScaleRange = {
  min: number,
  max: number
}

/**
 * ScaleRange type including time unit
 */
export type TimeScaleRange = {
  scaleRange: ScaleRange,
  xScaleTimeUnit: false | 'hour' | 'millisecond' | 'second' | 'minute' | 'day' | 'week' | 'month' | 'quarter' | 'year' | undefined,
};

/**
 * Option type to select a predefined time range
 */
export type TimeRangeOption = {
  label: string
  hours: number,
  xScaleTimeUnit: false | 'hour' | 'millisecond' | 'second' | 'minute' | 'day' | 'week' | 'month' | 'quarter' | 'year' | undefined,
  default?: boolean,
  timeGranularities?: TimeGranularity[]
};

/**
 * Default time range options for historic data charts
 */
export const HistoricDataTimeRangeOptions: TimeRangeOption[] = [
  {
    label: 'Last 24h',
    hours: 1 * 24,
    xScaleTimeUnit: 'hour'
  },
  {
    label: 'Last week',
    hours: 7 * 24,
    xScaleTimeUnit: 'day',
    default: true,
    timeGranularities: [TimeGranularity.Hourly, TimeGranularity.Daily]
  },
  {
    label: 'Last month',
    hours: 31 * 24,
    xScaleTimeUnit: 'day',
    timeGranularities: [TimeGranularity.Daily, TimeGranularity.Weekly]
  },
  {
    label: 'Last 180 days',
    hours: 180 * 24,
    xScaleTimeUnit: 'day',
    timeGranularities: [TimeGranularity.Daily, TimeGranularity.Weekly]
  },
];

/**
 * Create a gradient for a bar chart
 */
export const createChartGradient = (scriptableContext: ScriptableContext<keyof ChartTypeRegistry>, color: string, chartType = LineBarChartType.Bar): (CanvasGradient | string) => {
  const chart: Chart = scriptableContext.chart;
  const { ctx, chartArea } = chart;

  if (!ctx || !chartArea) {
    return color;
  }

  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, chartType === LineBarChartType.Line ? transparentize(0.9, color) : lighten(0.1, color));
    gradient.addColorStop(1, darken(0.15, color));
  }

  return gradient ?? color;
}