import { stringToFloat } from '@utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: CO2
 */
const HeatmapConfigCO2: IHeatmapConfig = {
  accessor: (x: string) => {
    return stringToFloat(x, 0);
  },

  scale: {
    points: [
      {
        threshold: 400,
        label: '400ppm',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 750,
        label: '750',
        color: DefaultScaleColors.MIDGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 1000,
        label: '1000',
        color: DefaultScaleColors.YELLOW,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 1500,
        label: '1500',
        color: DefaultScaleColors.ORANGE,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 2000,
        label: '2000',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigCO2);