import styled, { useTheme } from 'styled-components';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { v4 as uuidv4 } from 'uuid';
import NoData from '@components/core/NoData';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TextHighlighter from '@components/table/TextHighlighter';
import { transparentize } from 'polished';
import SkeletonLoader from './SkeletonLoader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SearchResultFields<TData> = {
  link: (record: TData) => string;
  label: (record: TData) => string;
  subLabel: (record: TData) => string;
  highlightLabel?: boolean;
  highlightSubLabel?: boolean;
}

export type SearchResultConfig<TData> = {
  fields: SearchResultFields<TData>;
  results: TData[];
  emptyLabel: string;
  loading: boolean;
}

export type PropTypes<TData> = {
  config: SearchResultConfig<TData>;
  searchString: string;
  onClear: () => void;
}

const SearchResult = <TData,>({ config, searchString, onClear }: PropTypes<TData>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);

  if (config.loading) {
    return (
      <SkeletonLoader />
    );
  }

  if (config.results.length === 0) {
    return (
      <NoData
        subLabel={config.emptyLabel}
        subLabelStyles={{ whiteSpace: 'pre-line' }}
        icon={solid('search')}
        iconStyles={{ fontSize: 18 }}
        styles={{ margin: '30px 30px' }}
      />
    );
  }

  return (
    <List>
      {config.results.slice(0, pageSize).map((result, i) => (
        <ProtectedLink
          key={uuidv4()}
          link={{ path: config.fields.link(result) }}
          onClick={onClear}
        >
          {i !== 0 &&
            <Divider />
          }

          <ListItem>
            <Label>
              {config.fields.highlightLabel
                ?
                <TextHighlighter
                  text={config.fields.label(result)}
                  highlight={searchString}
                  highlightStyle={{ backgroundColor: theme.primary.selected }}
                />
                :
                config.fields.label(result)
              }
            </Label>
            <Sublabel>
              {config.fields.highlightSubLabel
                ?
                <TextHighlighter
                  text={config.fields.subLabel(result)}
                  highlight={searchString}
                  highlightStyle={{ backgroundColor: theme.primary.selected }}
                />
                :
                config.fields.subLabel(result)
              }
            </Sublabel>
          </ListItem>
        </ProtectedLink>
      ))}

      {config.results.length > pageSize &&
        <ShowMore onClick={() => setPageSize(prev => prev + 10)}>
          {t('ShowMore', { ns: 'common' })}
        </ShowMore>
      }
    </List>
  );
};

export default SearchResult;

const List = styled.div`
  width: 100%;
  max-height: 250px;
  padding: 5px 0;
  overflow: auto;
`;

const ListItem = styled.div`
  position: relative;
  margin: 0 10px;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.primary.hover};
    
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -0.5px;
      width: 2px;
      height: 25px;
      border-radius: 8px;
      background-color: rgb(39, 110, 234);
    } 
  }
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-bottom: 3px;
`;

const Sublabel = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const Divider = styled.div`
  margin: 0 20px;
  border-bottom: 1px solid ${p => transparentize(0.6, p.theme.action.divider)};
`;

const ShowMore = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
  padding: 10px;
  margin: 8px 10px 10px 10px;
  border-radius: 8px;
  text-align: center;
  background-color: ${p => p.theme.action.selected};
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.primary.hover};
  }
`;