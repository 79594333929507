import { SpaceConsentDto } from '@api/models/SpaceConsentDto';
import { Modal } from '@components/core/Modal';
import { Table } from '@components/table/Table';
import { ITableColumn, TableSortOrder } from '@components/table/Table.types';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  toggle: () => void;
  consentHistory: SpaceConsentDto[];
}

const ConsentHistoryModal = ({ modalRef, isOpen, toggle, consentHistory }: PropTypes) => {
  const { localisation } = useLocalisation();
  const { t } = useTranslation();

  const tableColumns: ITableColumn<SpaceConsentDto>[] = useMemo(() => ([
    {
      label: t('Date/Time', { ns: 'common' }),
      key: 'changedOn',
      displayFormat: space => format(new Date(space.modifiedOn), localisation.dateFormats.default),
      sortFormat: space => new Date(space.modifiedOn)
    },
    {
      label: t('SmartMeter.CollectData', { ns: 'molecules' }),
      key: 'consentGranted',
      displayFormat: space => space.consentGranted ? t('Yes', { ns: 'common' }) : t('No', { ns: 'common' }),
      disableSort: true
    },
    {
      label: t('StartDate', { ns: 'common' }),
      key: 'startDate',
      displayFormat: space => space.startDate ? dayjs(space.startDate).format(localisation.dateFormats.date) : '-',
      disableSort: true
    },
    {
      label: t('EndDate', { ns: 'common' }),
      key: 'endDate',
      displayFormat: space => space.endDate ? dayjs(space.endDate).format(localisation.dateFormats.date) : '-',
      disableSort: true
    },
    {
      label: t('UpdatedBy', { ns: 'common' }),
      key: 'modifiedByName',
      disableSort: true
    }
  ]), [localisation, t]);

  const pageSizeOptions = useMemo(() => [
    { label: 'Show 5', value: 5 },
    { label: 'Show 10', value: 10 },
    { label: 'Show 20', value: 20 },
    { label: 'Show 50', value: 50 },
    { label: 'Show All', value: consentHistory.length }
  ], [consentHistory]);

  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      hide={toggle}
      headerStyles={{ padding: 24, border: 'none' }}
      bodyStyles={{ padding: '0 24px 24px 24px' }}
      width={'750px'}
      title={t('SmartMeteringHistory', { ns: 'common' })}
    >
      <Table
        columns={tableColumns}
        records={consentHistory}
        recordKey='id'
        defaultSortColumn={'modifiedOn'}
        defaultSortOrder={TableSortOrder.DESC}
        cardEffect
        removeDefaultStyling={true}
        recordBorder={true}
        pageSizeOptions={pageSizeOptions}
      />
    </Modal>
  );
};

export default ConsentHistoryModal;