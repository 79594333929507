import { MetricType_Suffix } from '@api/enums/MetricType_Suffix';
import { AlertRuleTrigger, AlertRuleTriggerType } from '@api/models/AlertRule';
import { AlertRuleLabel } from '../AlertRuleConfiguration';
import { Input } from '@components/Form';

const expectsSingleValue = (t: AlertRuleTriggerType) => {
  return (t === AlertRuleTriggerType.GreaterThan || t === AlertRuleTriggerType.LessThan);
}

const expectsRange = (t: AlertRuleTriggerType) => {
  return (t === AlertRuleTriggerType.WithinRange || t === AlertRuleTriggerType.OutsideRange);
}

interface ITriggerTypeConfiguration {
  trigger: AlertRuleTrigger;
  onChange: (trigger: AlertRuleTrigger) => void;
}

const TriggerTypeConfig = ({ trigger, onChange }: ITriggerTypeConfiguration) => {
  if (!trigger.metric || !trigger.type) {
    return null;
  }

  const suffix = MetricType_Suffix(trigger.metric);

  if (expectsSingleValue(trigger.type)) {
    return (
      <div style={{ minWidth: '150px', position: 'relative' }}>
        <AlertRuleLabel>{`${suffix}`}</AlertRuleLabel>
        <Input
          style={{ position: 'absolute', bottom: 0, width: '100%' }}
          type="number"
          value={trigger.threshold ?? NaN.toString()}
          onInput={(e) => {
            onChange({
              metric: trigger.metric,
              type: trigger.type,
              threshold: e.currentTarget.value === '' ? undefined : parseFloat(e.currentTarget.value),
              min: null,
              max: null,
              state: null
            });
          }} />
      </div>
    )
  }

  if (expectsRange(trigger.type)) {
    return (
      <>
        <div style={{ minWidth: '150px', position: 'relative' }}>
          <AlertRuleLabel>{suffix && `lower (${suffix})`}</AlertRuleLabel>
          <Input
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
            type="number"
            value={trigger.min ?? NaN.toString()}
            onInput={(e) => {
              onChange({
                metric: trigger.metric,
                type: trigger.type,
                min: e.currentTarget.value === '' ? undefined : parseFloat(e.currentTarget.value),
                max: trigger.max,
                threshold: null,
                state: null
              });
            }}
          />
        </div>
        <div style={{ minWidth: '150px', position: 'relative' }}>
          <AlertRuleLabel>{suffix && `upper (${suffix})`}</AlertRuleLabel>
          <Input
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
            type="number"
            value={trigger.max ?? NaN.toString()}
            onInput={(e) => {
              onChange({
                metric: trigger.metric,
                type: trigger.type,
                min: trigger.min,
                max: e.currentTarget.value === '' ? undefined : parseFloat(e.currentTarget.value),
                threshold: null,
                state: null
              });
            }} />
        </div>
      </>
    )
  }

  return null;
};

export default TriggerTypeConfig;