import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopBarTenantSelect from './TopBarTenantSelect';
import TopBarMenu from './TopBarMenu';
import { TopBarTenantLogo } from './TopBarTenantLogo';
import { TopBarHeight } from '@src/constants/designConstants';
import Onboarding from './Onboarding';

export const TopBar = () => {
  return (
    <NavBar>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <TopBarTenantLogo />
        <FlexRow style={{ gap: '20px' }}>
          <Onboarding />
          <TopBarTenantSelect />
          <TopBarMenu />
        </FlexRow>
      </FlexRow>
    </NavBar>
  );
};

const NavBar = styled.div`
  z-index: 12;
  height: ${TopBarHeight}px;
  padding: 0 20px 0 20px;
  flex-shrink: 0;

  background: ${(p) => p.theme.palette.backgrounds.surface};
  border-bottom: 1px solid ${p => p.theme.action.divider};

  @media (min-width: 600px) {
    padding: 0 30px 0 30px;
  }

  @media print {
    display: none;
  }
`;

const FlexRow = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TopBarIcon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  color: ${(p) => p.theme.palette.text.fair};
  cursor: pointer;
  padding: 8px 7px 7px 7px;
  border-radius: 50%;

  &:hover {
    background-color: ${(p) => p.theme.palette.topbar.iconHoverBackground};
  }
`;
