import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DeviceIssueDto } from '@api/models/DeviceIssueDto';
import { DeviceIssueType } from '@api/models/DeviceIssueType';
import { HierarchyBuilding } from '@api/models/Hierarchy';
import { Button } from '@components/core/Button';
import { ICascaderMultiSelectNodeGroup } from '@components/cascader-multi-select/CascaderMultiSelect.types';
import NoData from '@components/core/NoData';
import { SearchField } from '@components/search-field/SearchField';
import { Table } from '@components/table/Table';
import { ITableColumn } from '@components/table/Table.types';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { includesCI } from '@utils/StringUtils';
import DeviceModelFilter from '@components/core/DeviceModelFilter';
import Filter from '@components/core/Filter';
import { LocationFilter } from '@components/core/LocationFilter';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


type DeviceIssuesTableProps = {
  tableData: DeviceIssueDto[];
  buildings: HierarchyBuilding[];
  emptyListLabel?: string;
}

const DeviceIssuesTable = ({ tableData, buildings, emptyListLabel }: DeviceIssuesTableProps) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string>('');
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<HierarchyBuilding>>();
  const [spaceFilter, setSpaceFilter] = useState<HierarchyBuilding[] | undefined>([]);
  const [deviceModels, setDeviceModels] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<DeviceIssueType[]>([]);
  const [resetPulse, setResetPulse] = useState<number>(0);
  const onMobile = useMediaQuery('(max-width: 900px)');
  const navigate = useNavigate();

  const hasFilters = useMemo(() => (
    spaceFilter || statusFilter.length > 0 || deviceModels.length > 0
  ), [spaceFilter, statusFilter, deviceModels]);

  const deviceIssueTypes = useMemo(() => {
    return Object.values(DeviceIssueType).map(x => ({ label: t(`ClimateControl.DeviceIssues.IssueTypes.${DeviceIssueType[x]}`, { ns: 'molecules' }), value: x }));
  }, [t]);

  const deviceModelOptions = useMemo(() => (
    tableData.map(x => x.deviceModel)
  ), [tableData]);

  useEffect(() => {
    const buildingIds = tableData.map(x => x.buildingId);
    const options: ICascaderMultiSelectNodeGroup<HierarchyBuilding> = {
      header: t('DeviceManagement.Buildings', { ns: 'settingsDevice' }),
      nodes: orderBy(buildings.filter(x => buildingIds.includes(x.id)), x => x.name.toLocaleLowerCase()).map(building => ({
        label: `${building.name}`,
        value: building,
      }))
    }
    setCascaderOptions(options);
  }, [buildings, t, tableData]);

  const searchFilter = useCallback((x: DeviceIssueDto) => {
    return includesCI(x.friendlyName, searchString)
      || includesCI(x.deviceModel, searchString)
      || includesCI(x.spaceName, searchString)
      || includesCI(x.floorName?.toString() ?? '', searchString)
      || includesCI(x.buildingName?.toString() ?? '', searchString)
      || includesCI(t(`ClimateControl.DeviceIssues.IssueTypes.${x.issueType}`, { ns: 'molecules' }) ?? '', searchString)
      || includesCI(t(`ClimateControl.DeviceIssues.IssueRules.${x.issue}`, { ns: 'molecules' }) ?? '', searchString)
  }, [searchString, t]);

  const locationFilter = useCallback((x: DeviceIssueDto) => {
    return (spaceFilter && x.buildingId) ? spaceFilter.map(x => x.id).includes(x.buildingId) : true;
  }, [spaceFilter]);

  const deviceModelFilter = useCallback((x: DeviceIssueDto) => {
    return deviceModels.length > 0 ? deviceModels.includes(x.deviceModel) : true;
  }, [deviceModels]);

  const statusFilters = useCallback((x: DeviceIssueDto) => {
    return statusFilter.length > 0 ? statusFilter.includes(x.issueType) : true;
  }, [statusFilter]);

  const tableColumns: ITableColumn<DeviceIssueDto>[] = useMemo(() => ([
    {
      label: t('Device', { ns: 'common' }),
      key: 'friendlyName',
      width: 2
    },
    {
      label: t('ClimateControl.Model', { ns: 'molecules' }),
      key: 'deviceModel',
      displayFormat: device => t(`${device.deviceModel}.DisplayString`, { ns: 'deviceConfigs' })
    },
    {
      label: t('Space', { ns: 'common' }),
      key: 'spaceName',
    },
    {
      label: t('Floor', { ns: 'common' }),
      key: 'floorName'
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName',
    },
    {
      label: t('ClimateControl.DeviceIssues.IssueType', { ns: 'molecules' }),
      key: 'issueType',
      displayFormat: (e) => t(`ClimateControl.DeviceIssues.IssueTypes.${e.issueType}`, { ns: 'molecules' }),
      width: 2
    },
    {
      label: t('ClimateControl.DeviceIssues.IssueRule', { ns: 'molecules' }),
      key: 'issue',
      displayFormat: (e) => t(`ClimateControl.DeviceIssues.IssueRules.${e.issue}`, { ns: 'molecules' }),
      width: 3
    }
  ]), [t]);

  if (tableData.length === 0) {
    return (
      <NoData
        icon={duotone('party-horn')}
        label={t('LookingGood', { ns: 'status' })}
        subLabel={emptyListLabel}
        large
        styles={{ margin: '100px 0' }}
      />
    )
  }

  return (
    <>
      <ActionsMenu>
        <SearchField
          placeholder={t('Search', { ns: 'common' })}
          onSearchChange={setSearchString}
        />
        <Filters>
          {hasFilters &&
            <Button
              tertiary
              label={t('ClearFilters', { ns: 'common' })}
              onClick={() => setResetPulse(prev => prev + 1)}
            />
          }
          {cascaderOptions &&
            <LocationFilter
              label={t('Building', { ns: 'common' })}
              group={cascaderOptions}
              onChange={setSpaceFilter}
              resetPulse={resetPulse}
              position={onMobile ? 'left' : 'right'}
            />
          }
          <DeviceModelFilter
            deviceModels={deviceModelOptions}
            onChange={setDeviceModels}
            resetPulse={resetPulse}
            rightAlign={!onMobile}
          />
          <Filter
            label={t('ClimateControl.DeviceIssues.IssueType', { ns: 'molecules' })}
            options={deviceIssueTypes}
            onChange={setStatusFilter}
            resetPulse={resetPulse}
            rightAlign={!onMobile}
          />
        </Filters>
      </ActionsMenu>

      <Table
        columns={tableColumns}
        records={tableData}
        recordKey='id'
        defaultSortColumn={'friendlyName'}
        cardEffect
        disablePaging={onMobile}
        highlightString={searchString}
        removeDefaultStyling={true}
        minHeight={'100%'}
        recordBorder={true}
        filters={[searchFilter, locationFilter, statusFilters, deviceModelFilter]}
        onRowClick={(record) => navigate(`./../building/${record.buildingId}/floor/${record.floorId}/space/${record.spaceId}/device/${record.id}`)}
      />
    </>

  )
}

export default DeviceIssuesTable


const ActionsMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Filters = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 375px) {
    gap: 5px;
    flex-wrap: wrap;
  }
`;