import styled, { useTheme } from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Xarrow, { useXarrow } from 'react-xarrows';
import { useCallback, useEffect, useState } from 'react';
import SubMeterGroup from './SubMeterGroup';
import MeterMultiSelectModal from './MeterMultiSelectModal';
import { TopologyCard, TopologyCardContent, TopologyCardHeader, TopologyCardHeaderIcon, TopologyCardMenu, TopologyCardMenuItem, TopologyCardRelativeWrapper } from './shared';
import MeterDetailsModal from './MeterDetailsModal';
import { useTranslation } from 'react-i18next';
import { EnergyMeterTopologyLevel } from '@api/enums/EnergyMeterTopologyLevel';
import { EnergyMeterTopologyDto } from '@api/models/EnergyMeterTopologyDto';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useModal } from '@hooks/useModal';

interface IDistributionMeter {
  meter: EnergyMeterTopologyDto;
  meters: EnergyMeterTopologyDto[];
  addDistributionMeter: (meterId: number, parentMeter: EnergyMeterTopologyDto) => void;
  updateMeters: (meters: EnergyMeterTopologyDto[], parentMeter: EnergyMeterTopologyDto, level: EnergyMeterTopologyLevel) => void;
  parentArrowRefId?: string;
}

const DistributionMeter = ({ meter, meters, addDistributionMeter, updateMeters, parentArrowRefId }: IDistributionMeter) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const updateXarrow = useXarrow();
  const { getDisplayString } = useDeviceConfigContext();
  const { isOpen, toggle, ref } = useModal({});
  const { isOpen: detailModalIsOpen, toggle: toggleDetailModal, ref: detailModalRef } = useModal({});
  const [subMeters, setSubMeters] = useState<EnergyMeterTopologyDto[] | undefined>();
  const [distributionMeters, setDistributionMeters] = useState<EnergyMeterTopologyDto[]>([]);
  const arrowRefId = `${parentArrowRefId}-distr-${meter.id}`;

  useEffect(() => {
    const childMeters = meters.filter(x => x.parentMeterId === meter.id);
    const subMeters = childMeters.filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Sub);
    const distributionMeters = childMeters.filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Distribution);

    setDistributionMeters(distributionMeters);
    setSubMeters(subMeters.length > 0 ? subMeters : undefined);
  }, [meters, meter]);

  const addSubMeterGroup = useCallback(() => {
    setSubMeters([]);
    updateXarrow();
  }, [updateXarrow]);

  const removeParentMeter = useCallback((meter: EnergyMeterTopologyDto, meters: EnergyMeterTopologyDto[]): EnergyMeterTopologyDto[] => {
    if (meter.parentMeter?.meterTopologyLevel === EnergyMeterTopologyLevel.Distribution) {
      meters.splice(meters.findIndex(x => x.id === meter.parentMeter?.id), 1);
      return removeParentMeter(meter.parentMeter, meters);
    }

    return meters;
  }, []);

  const getDistributionMeterOptions = useCallback(() => {
    const distributionMeters = meters.filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Distribution);
    distributionMeters.splice(distributionMeters.findIndex(x => x.id === meter.id), 1);
    return removeParentMeter(meter, distributionMeters);
  }, [meters, meter, removeParentMeter]);

  return (
    <>
      <FlexColumn>
        <Wrapper>
          <TopologyCardRelativeWrapper>
            <TopologyCard id={arrowRefId}>
              <TopologyCardHeader color={theme.palette.orange} onClick={toggleDetailModal}>
                <span>{t('Buildings.EnergyMeters.DistributionMeter.DistributionMeter', { ns: 'settingsAsset' })}</span>
                <TopologyCardHeaderIcon icon={solid('circle-info')} />
              </TopologyCardHeader>

              <TopologyCardContent>
                <Label style={{ fontWeight: 500 }}>
                  {meter.meterNumber}
                </Label>
                <Label style={{ color: theme.palette.text.fair }}>
                  {meter.deviceFriendlyName}
                </Label>
                <Label style={{ color: theme.palette.text.fair }}>
                  {getDisplayString(meter.deviceModel)}
                </Label>
                <Label style={{ color: theme.palette.text.weak }}>
                  {meter.spaceName}
                </Label>
              </TopologyCardContent>
            </TopologyCard>

            <TopologyCardMenu>
              <TopologyCardMenuItem onClick={toggle}>
                <FontAwesomeIcon icon={solid('pen-to-square')} color={theme.palette.primary} />
                {t('Buildings.EnergyMeters.DistributionMeter.DistributionMeters', { ns: 'settingsAsset' })}
              </TopologyCardMenuItem>
              {subMeters === undefined &&
                <TopologyCardMenuItem onClick={addSubMeterGroup}>
                  <FontAwesomeIcon icon={solid('plus')} color={theme.palette.primary} />
                  {t('Buildings.EnergyMeters.DistributionMeter.AddSubMeters', { ns: 'settingsAsset' })}
                </TopologyCardMenuItem>
              }
            </TopologyCardMenu>
          </TopologyCardRelativeWrapper>
        </Wrapper>

        <MeterMultiSelectModal
          ref={ref}
          isOpen={isOpen}
          toggle={toggle}
          selected={distributionMeters}
          options={getDistributionMeterOptions()}
          parentMeter={meter}
          onSelectChange={(meters: EnergyMeterTopologyDto[]) => updateMeters(meters, meter, EnergyMeterTopologyLevel.Distribution)}
        />

        {parentArrowRefId && arrowRefId &&
          <Xarrow
            start={parentArrowRefId}
            end={arrowRefId}
            color={theme.palette.borders.strong}
            strokeWidth={2}
            startAnchor='bottom'
            endAnchor='top'
            path="straight"
          />
        }

        <FlexRow>
          {subMeters &&
            <SubMeterGroup
              meters={meters}
              subMeters={subMeters}
              parentMeter={meter}
              onChange={(meters: EnergyMeterTopologyDto[]) => updateMeters(meters, meter, EnergyMeterTopologyLevel.Sub)}
              arrowRefId={`${arrowRefId}-sub`}
              parentArrowRefId={arrowRefId}
            />
          }

          {distributionMeters.map((meter, i) => (
            <DistributionMeter
              key={i}
              meter={meter}
              meters={meters}
              addDistributionMeter={addDistributionMeter}
              parentArrowRefId={arrowRefId}
              updateMeters={updateMeters}
            />
          ))}
        </FlexRow>
      </FlexColumn>

      <MeterDetailsModal
        meter={meter}
        isOpen={detailModalIsOpen}
        modalRef={detailModalRef}
        toggle={toggleDetailModal}
        color={theme.palette.orange}
        titlePrefix={`${t('Buildings.EnergyMeters.DistributionMeter.DistributionMeter', { ns: 'settingsAsset' })}:`}
      />
    </>
  );
};

export default DistributionMeter;

const FlexRow = styled.div`
  display: flex;
  gap: 50px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.medium};
`;