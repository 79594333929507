import { TopBarHeight } from '@src/constants/designConstants';
import { BreadcrumbHeight } from '@src/constants/LayoutConstants';
import styled from 'styled-components';
import Sidebar from './components/layout/Sidebar';
import { Route as RouteType } from '@src/types/Route';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ProtectedRoute } from '@src/navigation/ProtectedRoute/ProtectedRoute';
import { useEffect, useState } from 'react';
import { BackButton } from '@components/core/BackButton';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  routes?: RouteType[],
};

const SettingsPage = ({ routes }: PropTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [origin, setOrigin] = useState<string>();

  // Navigate to first child route
  useEffect(() => {
    const path = routes?.[0].link?.path;
    if (pathname.split('/').pop() === 'settings' && path) {
      navigate(path, { replace: true });
    }
  }, [pathname, routes, navigate]);

  // Set origin as passed through the location state from the TopBarMenu
  useEffect(() => {
    if (state?.origin) {
      setOrigin(state.origin);
    }
  }, [state]);

  return (
    <>
      <Breadcrumb>
        <BackButton
          label={t('Back', { ns: 'navigation' })}
          url={origin ?? '/'}
          styles={{ margin: 0 }}
        />
      </Breadcrumb>

      <FlexRow>
        <Sidebar routes={routes} />

        <Content>
          <Routes>
            {routes?.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    route={route}
                  />
                }
              />
            ))}
          </Routes>
        </Content>
      </FlexRow>
    </>
  );
};

export default SettingsPage;

const FlexRow = styled.div`
  display: flex;
  height: ${`calc(100% - ${TopBarHeight}px - ${BreadcrumbHeight}px)`};
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Breadcrumb = styled.div`
  width: 100%;
  height: ${BreadcrumbHeight}px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: ${p => p.theme.background.container};
  border-bottom: 1px solid ${p => p.theme.action.divider};
  z-index: 2;
`;