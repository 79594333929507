import { SiteFairUseConfiguration } from '@api/models/SiteFairUseConfiguration';
import { PostQuery } from '../../common/PostQuery';

export class SiteFairUseConfigurationCreateCommand extends PostQuery<SiteFairUseConfiguration> {
  siteId: number;
  date: Date

  constructor(siteId: number, date: Date) {
    super();
    this.siteId = siteId;
    this.date = date;
  }

  public targetUrl(): string {
    return '/FairUse/configuration';
  }
}