import DeviceCard from '@components/device-cards/DeviceCard';
import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { BackButton } from '@components/core/BackButton';
import { useTranslation } from 'react-i18next';
import { CommonSidebar } from '@components/core/CommonSidebar';
import { useLayoutContext } from '@contexts/LayoutContext';
import { BuildingHierarchyLevel, useBuildingContext } from '@pages/site/buildings/BuildingProvider';

type PropTypes = {
  device: DeviceWithMetrics,
}

const Sidebar = ({ device }: PropTypes) => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();
  const { hierarchyLevel } = useBuildingContext();

  return (
    <CommonSidebar isHidden={isFullScreen || hierarchyLevel !== BuildingHierarchyLevel.Device}>
      <BackButton
        label={t('BackToSpace', { ns: 'navigation' })}
        url='..'
      />

      <DeviceCard
        device={device}
        inSidebar
      />
    </CommonSidebar>
  );
}

export default Sidebar;