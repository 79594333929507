import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { Radio, RadioChangeEvent, Space } from 'antd'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

type AnnualAllowanceUtilityProps = {
  energyMeterType: EnergyMeterType;
  onValueChange: (value: EnergyMeterType) => void;
}

const AnnualAllowanceUtility = ({ energyMeterType, onValueChange }: AnnualAllowanceUtilityProps) => {
  const { t } = useTranslation();

  return (
    <UtilityTypeWrapper>

      <UtilityTypeTitle>
        {t('FairUsage.Utility', { ns: 'molecules' })}:
      </UtilityTypeTitle>

      <Radio.Group
        onChange={(e: RadioChangeEvent) => onValueChange(e.target.value)}
        value={energyMeterType}
      >
        <Space direction="vertical">
          <Radio
            key={EnergyMeterType.Electricity}
            value={EnergyMeterType.Electricity}
            disabled={false}
          >
            {EnergyMeterType.Electricity}
          </Radio>
          <Radio
            key={EnergyMeterType.Heating}
            value={EnergyMeterType.Heating}
            disabled={true}
          >
            {EnergyMeterType.Heating}
          </Radio>
          <Radio
            key={EnergyMeterType.Water}
            value={EnergyMeterType.Water}
            disabled={true}
          >
            {EnergyMeterType.Water}
          </Radio>
        </Space>
      </Radio.Group>
    </UtilityTypeWrapper>
  )
};

export default AnnualAllowanceUtility;

const UtilityTypeWrapper = styled.header`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
`;

const UtilityTypeTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.palette.forms.label.color};
  align-self: start;
`;