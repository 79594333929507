import styled, { useTheme } from 'styled-components';
import UtopiLogoU from '@assets/images/utopi-logo-u.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopBarIcon } from '@components/top-bar/TopBar';
import { useMsal } from '@azure/msal-react';
import { useUserContext } from '@contexts/UserContext/UserContext';
import { useThemeContext } from '@contexts/ThemeContext/ThemeContext';
import { usePopover } from '@hooks/usePopover';
import { useApiState } from '@hooks/useApiState';
import { MetadataVersionGetQuery } from '@api/queries/metadata/MetadataVersionGetQuery';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { Permission } from '@api/enums/Permission';
import { useLocation } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { env } from '@src/env/env';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FeedbackModal from '@components/FeedbackModal';
import { useModal } from '@hooks/useModal';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { RouteLink } from '@src/types/Route';

const createMenuItems = (t: TFunction): RouteLink[] => {
  return [
    {
      label: t('TopBar.Menu.MyProfile', { ns: 'commonApp' }),
      path: '/profile',
      analytics: { action: 'my_profile', category: 'account_navigation' },
      automationId: 'myProfileOption'
    },
    {
      label: t('UserManagement', { ns: 'routes' }),
      path: '/user-management',
      permission: Permission.UserWrite,
      analytics: { action: 'user_management', category: 'account_navigation' },
      automationId: 'userManagementOption'
    },
    {
      label: t('SuperAdminSettings', { ns: 'routes' }),
      path: '/settings',
      permission: Permission.SuperAdministrator,
      analytics: { action: 'global_settings', category: 'account_navigation' },
      automationId: 'superAdminSettingsOption'
    }
  ];
};

const TopBarMenu = () => {
  const { t } = useTranslation(['commonApp']);
  const theme = useTheme();
  const { trackAction } = useAnalytics();
  const { pathname } = useLocation();
  const { instance } = useMsal();
  const { user } = useUserContext();
  const { toggleTheme, isDarkTheme } = useThemeContext();
  const { visible, toggle, ref } = usePopover({});
  const { isOpen: isModalOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const { data: apiVersion } = useApiState({
    query: new MetadataVersionGetQuery()
  }, []);

  const handlePopoverToggle = () => {
    trackAction('account_icon', 'account_navigation');
    toggle();
  }

  const handleThemeToggle = () => {
    trackAction(isDarkTheme ? 'light_mode_on' : 'dark_mode_on', 'account_navigation');
    toggleTheme();
  }

  const handleFeedbackToggle = () => {
    trackAction('account_icon', 'account_navigation');
    toggleModal();
  }

  const handleLogout = () => {
    trackAction('sign_out', 'account_navigation');
    instance.setActiveAccount(null);
    instance.logout().catch((e) => {
      console.error(e);
    });
  };

  const popoverContent = (
    <FlexContainer>
      <Header>
        <Name data-cy={'userName'}>
          {user.displayName}
        </Name>
        <Email data-cy={'userEmail'}>
          {user.email}
        </Email>
      </Header>

      <DropdownItem onClick={handleThemeToggle}>
        <ThemeIcon icon={isDarkTheme ? solid('moon') : solid('sun-bright')} />
        {isDarkTheme ? t('DarkMode', { ns: 'common' }) : t('LightMode', { ns: 'common' })}
      </DropdownItem>

      {createMenuItems(t).map((menuItem) => (
        <ProtectedLink link={menuItem} state={{ origin: pathname }} key={menuItem.label}>
          <DropdownItem onClick={toggle} data-cy={menuItem.automationId}>
            {menuItem.label}
          </DropdownItem>
        </ProtectedLink>
      ))}

      <DropdownItem onClick={handleFeedbackToggle} data-cy={'giveFeedbackOption'}>
        {t('TopBar.Menu.GiveFeedback', { ns: 'commonApp' })}
      </DropdownItem>

      <DropdownItem onClick={handleLogout} data-cy={'signOutOption'}>
        {t('TopBar.Menu.SignOut', { ns: 'commonApp' })}
      </DropdownItem>

      <Footer>
        <FlexRow>
          <Logo src={UtopiLogoU} />
          <CompanyName>Utopi</CompanyName>
        </FlexRow>

        <Versioning>
          <span data-cy={'uiVersion'}>v.{env.version} UI</span>
          <>
            {apiVersion && (
              <span data-cy={'apiVersion'}>
                v.{apiVersion} API
              </span>
            )}
          </>
        </Versioning>
      </Footer>
    </FlexContainer>
  );

  return (
    <>
      <FeedbackModal
        modalRef={modalRef}
        isOpen={isModalOpen}
        toggle={toggleModal}
      />

      <Popover
        ref={ref}
        visible={visible}
        popoverContent={popoverContent}
        placement={PopoverPlacement.BottomRight}
        offsetX={10}
        alignWithTarget
        hideTriangle
        containerStyles={{
          borderRadius: 8,
          border: `1px solid ${theme.action.divider}`,
          boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.08)'
        }}
      >
        <TopBarIcon
          icon={solid('user')}
          onClick={handlePopoverToggle}
          data-cy={'TopBar_MenuIcon'}
        />
      </Popover>
    </>
  );
};

export default TopBarMenu;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 248px;
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.palette.text.fair};
  white-space: wrap;
  overflow: auto;
`;

export const DropdownItem = styled.div`
  padding: 9px 22px;
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: ${(p) => p.theme.text.primary};
  transition: all 150ms ease-out;

  &:hover {
    background-color: ${(p) => p.theme.primary.hover};
  }
`;

const ThemeIcon = styled(FontAwesomeIcon)`
  width: 16px;
  font-size: 16px;
  color: ${p => p.theme.text.secondary};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  padding: 22px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${(p) => p.theme.action.divider};
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-bottom: 5px;
`;

const Email = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 22px;
  margin-top: 8px;
  border-top: 1px solid ${(p) => p.theme.action.divider};
`;

const Logo = styled.img`
  width: 12px;
  margin-right: 5px;
`;

const CompanyName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.text.medium};
`;

const Versioning = styled.div`
  font-size: 12px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
  color: ${(p) => p.theme.palette.text.weak};
`;