import styled from 'styled-components';
import profileImagePlaceholder from '@assets/images/profileImagePlaceholder.png';
import { useFileFromBlobStorage } from '@hooks/useFileFromBlobStorage';
import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';

interface IUserListProfileImage {
  blobName?: string;
}

const UserListProfileImage = ({ blobName }: IUserListProfileImage) => {
  const { blobUri } = useFileFromBlobStorage(blobName, BlobStorageContainerType.Shared);

  return (
    <UserImage src={blobUri ?? profileImagePlaceholder} />
  );
};

export default UserListProfileImage;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;
