import { OperatorPrizeDto } from '@api/models/OperatorPrizeDto';
import { OperatorDeleteQuery } from '@api/queries/resident-app/common/OperatorDeleteQuery';

export class PrizesResidentAppDeleteCommand extends OperatorDeleteQuery<OperatorPrizeDto> {
  prizeId: number;
  challengeId: number;

  constructor(prizeId: number, challengeId: number) {
    super();
    this.prizeId = prizeId;
    this.challengeId = challengeId;
  }

  public targetUrl(): string {
    return '/Prizes';
  }
}
