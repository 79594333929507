import { OptionType, PhysicalDevicesFormValues, } from './PeopleCountingAssignDevicesList';
import { Control, Controller, FieldArrayWithId, FormState, UseFormRegister, UseFormSetValue, useWatch, } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@components/Form';
import { Select } from '@components/select/Select';
import { Button } from '@components/core/Button';

type PropTypes = {
  field: FieldArrayWithId<PhysicalDevicesFormValues, 'physicalDevicesFieldArray'>;
  index: number;
  formState: FormState<PhysicalDevicesFormValues>;
  register: UseFormRegister<PhysicalDevicesFormValues>;
  remove: (index?: number | number[] | undefined) => void;
  control: Control<PhysicalDevicesFormValues>;
  deviceOptions: OptionType[];
  setValue: UseFormSetValue<PhysicalDevicesFormValues>;
};

const PeopleCountingAssignDeviceRow = ({ index, formState, remove, control, deviceOptions, setValue }: PropTypes) => {
  const { t } = useTranslation(['settingsPeopleCounting']);
  const { errors } = formState;

  const AlphabeticalSort = (a: OptionType, b: OptionType): number => {
    switch (true) {
      case a.label > b.label:
        return 1;
      case a.label < b.label:
        return -1;
      default:
        return 0;
    }
  };

  const theme = useTheme();
  const formValues = useWatch({ control, name: `physicalDevicesFieldArray.${index}`, });

  const directionOptions: OptionType[] = [
    { value: 'Inbound', label: t('AssignDeviceRow.Inbound', { ns: 'settingsPeopleCounting' }), isDisabled: false },
    { value: 'Outbound', label: t('AssignDeviceRow.Outbound', { ns: 'settingsPeopleCounting' }), isDisabled: false },
  ];

  return (
    <div className="container">
      <div className="row" style={{ marginBottom: '10px', paddingTop: '20px' }}>
        <div className="col-md-6">
          <Controller
            control={control}
            name={`physicalDevicesFieldArray.${index}.physicalDeviceId`}
            rules={{
              required: {
                value: true,
                message: t('AssignDeviceRow.RequiredField', { ns: 'settingsPeopleCounting' }),
              },
            }}
            render={({ field: { onChange, ref } }) => (
              <Select
                isDisabled={formValues?.disabled}
                onChange={(e) => {
                  if (e) {
                    onChange(parseInt(e.value));
                    setValue(`physicalDevicesFieldArray.${index}.physicalDeviceName`, e.label)
                  }
                }}
                innerRef={ref}
                isSearchable={false}
                options={deviceOptions.sort(AlphabeticalSort)}
                value={deviceOptions?.find(
                  (x) =>
                    x.value === formValues?.physicalDeviceId?.toString()
                )}
                isOptionDisabled={(option) => option.isDisabled}
              />
            )}
          />
          <ErrorMessage>
            {errors?.physicalDevicesFieldArray?.[index]?.physicalDeviceId?.message}
          </ErrorMessage>
        </div>
        <div className="col">
          <Controller
            control={control}
            name={`physicalDevicesFieldArray.${index}.direction`}
            rules={{
              required: {
                value: true,
                message: 'This field is required',
              },
            }}
            render={({ field: { onChange, ref } }) => (
              <Select
                onChange={(e) => e && onChange(e.value)}
                innerRef={ref}
                isSearchable={false}
                options={directionOptions}
                value={directionOptions.find(
                  (x) => x.value === formValues?.direction
                )}
              />
            )}
          />
          <ErrorMessage>
            {errors?.physicalDevicesFieldArray?.[index]?.direction?.message}
          </ErrorMessage>
        </div>

        <div className="col-auto">
          <Button
            tertiary
            circle
            onClick={() => remove(index)}
            label={<FontAwesomeIcon icon={solid('trash-xmark')} style={{ width: '18px', height: '18px' }} />}
            color={theme.palette.red}
          />
        </div>
      </div>
    </div>
  );
};

export default PeopleCountingAssignDeviceRow;
