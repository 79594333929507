import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@components/core/Tooltip';
import { ITooltip } from '@components/core/Tooltip.types';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

type IconTooltipProps = {
  icon: IconDefinition;
  tooltip: ITooltip;
  iconStyles?: React.CSSProperties;
  tooltipStyles?: React.CSSProperties;
  wrapperStyles?: React.CSSProperties;
}

const IconTooltip = ({ icon, tooltip, iconStyles, tooltipStyles, wrapperStyles }: IconTooltipProps) => {

  return (
    <Wrapper style={wrapperStyles}>

      <Tooltip
        {...tooltip}
        content={
          <TooltipContent style={tooltipStyles}>
            {tooltip.content}
          </TooltipContent>
        }
      >
        <InfoIcon icon={icon} style={iconStyles} />
      </Tooltip>
    </Wrapper>
  );
};

export default IconTooltip;

const Wrapper = styled.div`
  display: flex;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const TooltipContent = styled.div`
  padding: 10px;
  font-size: 12px;
`;