import { Title } from '@components/core/Title';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SiteTemperatureChartWidget from './components/temperature/SiteTemperatureChartWidget';
import SiteUtilitiesWidget from './components/utilities/SiteUtilitiesWidget';

const SitePerformance = () => {
  const { t } = useTranslation();

  return (
    <PaddedContainer>
      <Title
        text={t('SitePerformance', { ns: 'common' })}
        subtitle={{ text: t('SitePeformanceDescription', { ns: 'common' }), style: { paddingTop: 12 } }}
        size='lg'
        wrapperStyle={{ paddingBottom: '32px' }}
      />

      <FlexColumn>
        <SiteTemperatureChartWidget />
        <SiteUtilitiesWidget />
      </FlexColumn>
    </PaddedContainer>
  );
};

export default SitePerformance;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;