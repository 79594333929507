import { IGradientStep } from '@src/types/GradientStep';
import thumbDark from '@assets/icons/thumb-dark.svg';
import thumb from '@assets/icons/thumb.svg';
import { useThemeContext } from '@contexts/ThemeContext/ThemeContext';
import { transparentize } from 'polished';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

type SliderProps = {
  minRange: number;
  maxRange: number;
  gradientSteps: IGradientStep[];
  formId: string;
  value: number;
  showTicks?: boolean;
  minValue?: number;
  maxValue?: number;
  onChange?: (thumbs: number) => void;
}

const Slider = ({ minRange, maxRange, gradientSteps, formId, value, showTicks = false, minValue = minRange, maxValue = maxRange, onChange }: SliderProps) => {
  const { register, watch, setValue } = useFormContext();
  const [labelPosition, setLabelPosition] = useState<string>('');
  const { isDarkTheme } = useThemeContext();

  useEffect(() => {
    watch(formId);
    getLabelPosition(value, minRange, maxRange);
  }, [formId, gradientSteps, maxRange, minRange, value, watch]);

  const getLabelPosition = (value: number, min: number, max: number) => {
    const newVal = Number(((value - min) * 100) / (max - min));
    setLabelPosition(`calc(${newVal}% + (${8 - newVal * 0.16}px))`);
  }

  return (
    <SliderWrapper>
      <Label
        showTicks={showTicks}
        style={{ left: `${labelPosition}` }}
      >
        {value}
      </Label>
      <FacadeSlider
        gradient={gradientSteps.map(x => x.color)}
      />
      <StyledInput
        isDarkTheme={isDarkTheme}
        showTicks={showTicks}
        type="range"
        step={1}
        min={minRange}
        max={maxRange}
        gradient={gradientSteps.map(x => x.color)}
        {...register(formId, {
          valueAsNumber: true,
          max: maxValue,
          min: minValue,
          onChange: (e) => {
            if (parseInt(e.target.value) > maxValue) {
              setValue(formId, maxValue)
              getLabelPosition(maxValue, minRange, maxRange)
            } else if (parseInt(e.target.value) < minValue) {
              setValue(formId, minValue)
              getLabelPosition(minValue, minRange, maxRange)
            } else {
              setValue(formId, e.target.value)
              getLabelPosition(parseInt(e.target.value), minRange, maxRange)
            }
            if (onChange) {
              onChange(parseInt(e.target.value))
            }
          }
        })}
      />

      {showTicks &&
        <StyledTicks>
          <span>{minRange}</span>
          {Array.from({ length: (maxRange - minRange) - 1 }, () => (
            <span key={uuidv4()}></span>
          ))}
          <span>{maxRange}</span>
        </StyledTicks>
      }
    </SliderWrapper>
  )
}

export default Slider

const baseThumb = ({ showTicks, isDarkTheme }: { showTicks: boolean, isDarkTheme: boolean }) => css`
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  pointer-events: all;
  position: relative;
  z-index: 1;
  width: 16px;
  height: 22px;
  background-image: ${() => isDarkTheme ? `url(${thumbDark})` : `url(${thumb})`};
  background-color: transparent;
  transform: ${() => showTicks ? 'rotate(180deg)' : 'rotate(0deg)'};
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: none;
  cursor: pointer;
`;

const StyledInput = styled.input<{ gradient: string[], showTicks: boolean, isDarkTheme: boolean }>`
  -webkit-appearance: none; 
  appearance: none;
  height: 7px;
  width: 100%;
  margin: 0px;
  border-radius: 5px;
  background: ${p => `linear-gradient(to right, ${p.gradient})`};
  pointer-events: none;
  z-index: 1;

  @-moz-document url-prefix(){
    position: absolute;
    background: transparent;
  }

  &::-webkit-slider-thumb {
    ${p => baseThumb({ showTicks: p.showTicks, isDarkTheme: p.isDarkTheme })};
  }

  &::-moz-range-thumb {
    ${p => baseThumb({ showTicks: p.showTicks, isDarkTheme: p.isDarkTheme })};
    border: 0;
  }
`;

const Label = styled.output<{ showTicks: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 50%;
  bottom: ${p => p.showTicks ? '-150%' : '100%'};
  width: 21px;
  height: 24px;
  transform: translateX(-50%);

  font-size: 12px;
  font-weight: 400;
  line-height: 19.92px;
  letter-spacing: 0.4000000059604645px;

  background-color: ${p => transparentize(0.8, p.theme.palette.primary)};
  color: ${p => p.theme.palette.primary};;
  border-radius: 6px;
  
  @-moz-document url-prefix(){
    bottom: ${p => p.showTicks ? '-110%' : '150%'};
  }

`;

const SliderWrapper = styled.div`
  position: absolute;
  width: 400px;
  height: 100%;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const StyledTicks = styled.div`
  position: absolute;
  top: 90%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 7px;
  z-index: 0;

  @-moz-document url-prefix(){
    top: 45%;
  }

  & span {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 1px;
    height: 5px;
    line-height: 40px;
    background: ${p => p.theme.palette.text.weak};

    font-size: 10px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-align: center;
    color: ${p => p.theme.palette.text.weak}
  }
`;

const FacadeSlider = styled.div<{ gradient: string[] }>`
  @-moz-document url-prefix(){
    position: absolute;
    width: 100%;
    height: 7px;
    background: ${p => `linear-gradient(to right, ${p.gradient})`};
    border-radius: 5px;
    z-index: 0;
  }
`;