import DeviceListTenantLevel from '@pages/admin/devices/DeviceListTenantLevel';
import FeatureManagement from '@pages/admin/features/FeatureManagement';
import TenantCreate from '@pages/admin/tenants/create/TenantCreate';
import TenantEdit from '@pages/admin/tenants/edit/TenantEdit';
import TenantList from '@pages/admin/tenants/TenantList';
import TenantUserList from '@pages/admin/users/TenantUserList';
import { Route } from '@src/types/Route';

export const SettingsRoutes: Route[] = [
  {
    path: 'devices',
    element: DeviceListTenantLevel,
    link: {
      label: 'Devices',
      path: 'devices',
      category: 'TenantLevel'
    }
  },
  {
    path: 'tenants',
    element: TenantList,
    link: {
      label: 'Tenants',
      path: 'tenants',
      category: 'Global'
    }
  },
  {
    path: 'tenants/create',
    element: TenantCreate,
  },
  {
    path: 'tenants/:tenantId',
    element: TenantEdit,
  },
  {
    path: 'tenant-access',
    element: TenantUserList,
    link: {
      label: 'UserTenantAccess',
      path: 'tenant-access',
      category: 'Global'
    }
  },
  {
    path: 'features',
    element: FeatureManagement,
    link: {
      label: 'Features',
      path: 'features',
      category: 'Global'
    },
  }
]