import styled, { useTheme } from 'styled-components';
import { Button } from '@components/core/Button';
import { Modal } from '@components/core/Modal';
import { ReactNode, Ref } from 'react';
import { IButton } from '@components/core/Button.types';

type InteractiveDialogProps = {
  modalRef: Ref<HTMLDivElement>;
  isOpen: boolean;
  hide: () => void;
  title?: string;
  content: ReactNode;
  confirmButton: IButton;
  cancelButton: IButton;
  width?: string;
}

export const InteractiveDialog = ({ modalRef, isOpen, hide, title, content, confirmButton, cancelButton, width }: InteractiveDialogProps) => {
  const theme = useTheme();

  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      title={title}
      hide={hide}
      width={width ? width : '500px'}
      footer={
        <FlexRow>
          <Button
            tertiary
            color={theme.palette.red}
            {...cancelButton}
          />
          <Button {...confirmButton} />
        </FlexRow>
      }
    >
      {content}
    </Modal>
  );
};

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;