import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { LineBarChartType } from '@components/charts/Chart.types';
import { v4 as uuidv4 } from 'uuid';
import { groupBy, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import DashedLine from '@components/charts/legend/DashedLine';
import { ConsumptionChartDataset } from './utilityChartUtils';

type PropTypes = {
  datasets?: ConsumptionChartDataset[];
  hiddenDatasets: { [key: string]: string; };
  toggleDataset: (id: string) => void;
  isGasWidget?: boolean;
}

const ChartLegend = ({ datasets, hiddenDatasets, toggleDataset, isGasWidget }: PropTypes) => {
  const { t } = useTranslation();
  let groups = Object.entries(groupBy(datasets, x => x.legend.group));
  groups = orderBy(groups, x => x[0]);

  return (
    <Wrapper>
      {groups.map(([group, datasets]) => (
        <Category key={group} showBorder={!isGasWidget}>
          {!isGasWidget &&
            <CategoryLabel>
              {t(`SiteOverview.${group}`, { ns: 'molecules' })}
            </CategoryLabel>
          }

          <Items>
            {orderBy(datasets, x => x.legend.order).map(({ label, type, id, legend }) => {
              const isHidden = !!hiddenDatasets[id];

              return (
                <Item
                  key={uuidv4()}
                  onClick={() => toggleDataset(id)}
                  inactive={isHidden}
                >
                  {type === LineBarChartType.Bar ? (
                    <Square color={legend.color} />
                  ) : (
                    <DashedLine color={legend.color} />
                  )
                  }

                  {isHidden &&
                    <LineThrough />
                  }

                  <Label inactive={isHidden}>
                    {label}
                  </Label>
                </Item>
              );
            })}
          </Items>
        </Category>
      ))}
    </Wrapper>
  );
};

export default ChartLegend;

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const Category = styled.div<{ showBorder: boolean }>`
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;
  
  ${p => p.showBorder && css`
    padding: 10px 13px 7px 13px;
    border-color: ${p => p.theme.palette.borders.medium};
  `}
`;

const CategoryLabel = styled.div`
  position: absolute;
  top: -9px;
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
  width: max-content;
  padding: 0 7px 0 6px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
`;

const Item = styled.div<{ inactive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Label = styled.div<{ inactive: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.inactive ? p.theme.palette.text.weak : p.theme.palette.text.fair};
  user-select: none;
`;

const Square = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  flex-shrink: 0;
  background-color: ${p => p.color};
`;

const LineThrough = styled.div`
  position: absolute;
  left: -4px;
  width: calc(100% + 8px);
  height: 2px;
  border-radius: 2px;
  background-color: ${p => transparentize(0.5, p.theme.palette.text.fair)};
`;