import { DeviceWithMetrics } from '@api/models/DeviceWithMetrics';
import { LabelValuePair } from '@components/core/LabelValuePair';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DeviceCardConnectionStatus from './DeviceCardConnectionStatus';
import { TooltipPlacement } from '@components/core/Tooltip.types';

type PropTypes = {
  device: DeviceWithMetrics;
  inSidebar?: boolean;
}

const DeviceCardCommon = ({ device, inSidebar }: PropTypes) => {
  const { getDisplayString, getDeviceConfig } = useDeviceConfigContext();
  const deviceConfig = getDeviceConfig(device.deviceModel);
  const { t } = useTranslation(['molecules']);
  const { localisation } = useLocalisation();

  return (
    <Wrapper inSidebar={inSidebar}>
      <FlexRow>
        <LabelValuePair
          label={t('DeviceCard.Model', { ns: 'molecules' })}
          value={getDisplayString(device.deviceModel)}
          size="xs"
        />
        <LabelValuePair
          label={deviceConfig?.devEUIType ?? t('DeviceCard.DeviceId', { ns: 'molecules' })}
          value={device.deviceIdentifier}
          size="xs"
          tooltip={
            deviceConfig?.tooltip ? {
              content: <TooltipBody>{t(deviceConfig.tooltip, { ns: 'molecules' })}</TooltipBody>,
              placement: TooltipPlacement.BottomLeft,
              isFixedPosition: inSidebar
            } : undefined
          }
        />
      </FlexRow>

      {deviceConfig?.isPanelHeater &&
        <DeviceCardConnectionStatus
          deviceId={device.id}
        />
      }

      {device.lastMeasuredOn &&
        <FlexRow>
          <LabelValuePair
            label={t('DeviceCard.LastRead', { ns: 'molecules' })}
            value={format(new Date(device.lastMeasuredOn), `${localisation.dateFormats.default}`)}
            size="xs"
          />
        </FlexRow>
      }
    </Wrapper>
  );
};

export default DeviceCardCommon;

const Wrapper = styled.div<{ inSidebar?: boolean }>`
  padding: ${p => p.inSidebar ? '12px 0' : '16px 24px'};
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 18px;
`;

const TooltipBody = styled.div`
  width: 260px;
  padding: 12px;
`;