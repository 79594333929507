import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import MetricPerformanceMeterLegend from './MetricPerformanceMeterLegend';
import { reduceArray } from '@utils/ArrayUtils';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { Tooltip } from '@components/core/Tooltip';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

interface IMetricPerformanceMeter {
  mean: number;
  min: number;
  max: number;
  scaleMin: number;
  scaleMax: number;
  scaleLabels: number[];
  scaleLabelSuffix?: string;
  inPrintMode: boolean;
  noData?: boolean;
  metricType: MetricType | AltUnits;
}

const getPositionAsPercentage = (value: number, scaleMin: number, scaleMax: number): number => {
  const scaleRange = scaleMax - scaleMin;
  let valueOnScale = value - scaleMin;

  if (valueOnScale < 0) {
    valueOnScale = 0;
  }

  if (valueOnScale > scaleRange) {
    valueOnScale = scaleRange;
  }

  return valueOnScale / scaleRange * 100;
}

const getGradient = (minPosition: number, meanPosition: number, maxPosition: number, color: string): string => {
  const barRange = maxPosition - minPosition;
  const meanPositionOnBar = (meanPosition - minPosition) / barRange * 100;

  return `linear-gradient(90deg, ${transparentize(0.2, color)} 0%, ${transparentize(0.6, color)} ${meanPositionOnBar}%, ${transparentize(0.2, color)} 100%);`;
}

const reduceLabels = (labels: number[]): number[] => {
  if (labels.length % 2 === 0) {
    return reduceArray(labels, 4);
  } else {
    return reduceArray(labels, 3);
  }
}

const MetricPerformanceMeter = ({ mean, min, max, scaleMin, scaleMax, scaleLabels, scaleLabelSuffix = '', inPrintMode, noData, metricType }: IMetricPerformanceMeter) => {
  const { t } = useTranslation(['molecules']);
  const theme = useTheme();
  const { toLocale } = useLocalisation();
  const onSmallScreen = useMediaQuery('(max-width: 1000px)');
  const meanPosition = getPositionAsPercentage(mean, scaleMin, scaleMax);
  const minPosition = getPositionAsPercentage(min, scaleMin, scaleMax);
  const maxPosition = getPositionAsPercentage(max, scaleMin, scaleMax);
  const color = inPrintMode ? theme.palette.printStyles.chartColor : theme.palette.esgReport.chartColor;

  if (inPrintMode) {
    scaleLabels = reduceLabels(scaleLabels);
  }

  if (!inPrintMode && onSmallScreen) {
    scaleLabels = reduceArray(scaleLabels, 2);
  }

  const tooltipContent = (
    <div style={{ fontWeight: 500, fontSize: '12px', display: 'flex', flexDirection: 'column', padding: '4px' }}>
      <span>
        {`${t('ESG.Average', { ns: 'molecules' })} ${toLocale(metricType, mean, { round: 0 }) + scaleLabelSuffix}`}
      </span>
      <span>
        {`${t('ESG.Minimum', { ns: 'molecules' })} ${toLocale(metricType, min, { round: 0 }) + scaleLabelSuffix}`}
      </span>
      <span>
        {`${t('ESG.Maximum', { ns: 'molecules' })} ${toLocale(metricType, max, { round: 0 }) + scaleLabelSuffix}`}
      </span>
    </div>
  );

  return (
    <Wrapper>
      <Tooltip content={tooltipContent} placement={TooltipPlacement.TopRight} alignWithTarget={true}>
        <ScaleLabels faint={noData}>
          {scaleLabels.map((scalePoint, i) => (
            <ScaleLabel
              key={i}
              rightShift={getPositionAsPercentage(scalePoint, scaleMin, scaleMax)}
              first={i === 0}
              last={scaleLabels.length - 1 === i}
            >
              {toLocale(metricType, scalePoint, { round: 0 })}{i === 0 ? scaleLabelSuffix : ''}
            </ScaleLabel>
          ))}
        </ScaleLabels>

        <Bar faint={noData}>
          <MeanMarker hide={noData} rightShift={meanPosition} color={color} />
          <MinMaxBar hide={noData} rightShift={minPosition} width={maxPosition - minPosition} gradient={getGradient(minPosition, meanPosition, maxPosition, color)} />
        </Bar>
      </Tooltip>

      <MetricPerformanceMeterLegend
        mean={mean}
        min={min}
        max={max}
        metricType={metricType}
      />
    </Wrapper>
  );
};


export default MetricPerformanceMeter;

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;

  @media print {
    padding-bottom: 1px;
  }
`;

const ScaleLabels = styled.div<{ faint?: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  color: ${p => p.faint ? p.theme.palette.text.weak : p.theme.palette.text.fair};
  position: relative;
  height: 20px;

  @media print {
    font-size: 10px;
    height: 15px;
    color: ${p => p.theme.palette.printStyles.text.fair};
  }
`;

const ScaleLabel = styled.div<{ rightShift: number, first: boolean, last: boolean }>`
  position: absolute;
  top: 0;
  left: ${p => `${p.rightShift}%`};
  transform: ${p =>
    (p.last && 'translate(-100%,0)') ||
    (!p.first && 'translate(-50%,0)') ||
    'none'};
`;

const Bar = styled.div<{ faint?: boolean }>`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: ${p => transparentize(p.faint ? 0.5 : 0, p.theme.palette.esgReport.metricMeterBackground)};
  border-radius: 16px;

  @media print {
    height: 4px;
  }
`;

const MinMaxBar = styled.div<{ rightShift: number, width: number, gradient: string, hide?: boolean }>`
  display: ${p => p.hide ? 'none' : 'unset'};

  position: absolute;
  top: 0px;
  left: ${p => `calc(${p.rightShift}%)`};;
  width: ${p => `${p.width}%`};
  height: 6px;
  background: ${p => p.gradient};
  border-radius: 4px;
  z-index: 1;

  @media print {
    height: 4px;
  }
`;

const MeanMarker = styled.div<{ rightShift: number, color: string, hide?: boolean }>`
  display: ${p => p.hide ? 'none' : 'unset'};

  position: absolute;
  top: -4px;
  left: ${p => `${p.rightShift}%`};
  transform: ${p => p.rightShift === 100
    ? 'translate(-100%,0)'
    : 'translate(-50%,0)'};

  width: 14px;
  height: 14px;
  background: ${p => p.color};
  border: 2px solid ${p => p.theme.palette.backgrounds.surface};
  border-radius: 16px;
  z-index: 2;

  @media print {
    top: -3px;
    width: 10px;
    height: 10px;
    border-width: 1px;
  }
`;