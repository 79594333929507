import { ErrorMessage, Label } from '@components/Form';
import { Select } from '@components/select/Select';
import { Card } from '@components/core/Card';
import { getCurrencies } from '@utils/CurrencyUtils';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues } from '../SiteMetadataEdit';

type GeneralEditProps = {
  editMode: boolean;
}

const GeneralEdit = ({ editMode }: GeneralEditProps) => {
  const { t } = useTranslation(['settingsAsset', 'currencies']);
  const { control, formState: { errors } } = useFormContext<FormValues>();

  const currencyOptions = getCurrencies().map(({ code }) => ({
    value: code,
    label: `${t(`Currencies.${code}`, { ns: 'currencies' })} - ${code}`
  }))

  return (
    <Card cardTitle={t('Sites.SiteMetadataEditing.General', { ns: 'settingsAsset' })}>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.Currency', { ns: 'settingsAsset' })}</Label>
          <Controller
            control={control}
            name="currency"
            render={({ field: { onChange, value } }) => (
              <Select
                value={currencyOptions.find(currency => currency.value === value)}
                isSearchable={true}
                onChange={selected => selected && onChange(selected.value)}
                options={currencyOptions}
                readOnly={!editMode}
              />
            )}
          />
          <ErrorMessage>{errors.currency?.message}</ErrorMessage>
        </div>
      </div>
    </Card>
  )
}

export default GeneralEdit