import { OperatorChallengeDto } from '@api/models/OperatorChallengeDto';
import { OperatorGetQuery } from '@api/queries/resident-app/common/OperatorGetQuery';

export class ChallengesResidentAppGetAllBySiteQuery extends OperatorGetQuery<OperatorChallengeDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/Challenges/GetAllBySite/${this.siteId}`;
  }
}
