import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from '@components/checkbox/Checkbox';
import styled, { useTheme } from 'styled-components';

type ConfirmationBoxProps = {
  warningTitle: string;
  warningDescription?: string;
  checkboxLabel: string;
  isChecked: boolean;
  onCheckboxChange: (isChecked: boolean) => void;
};

const ConfirmationBox = ({
  warningTitle,
  warningDescription,
  checkboxLabel,
  isChecked,
  onCheckboxChange,
}: ConfirmationBoxProps) => {
  const theme = useTheme();

  return (
    <ConfirmationBoxWrapper>
      <Title>
        <FontAwesomeIcon icon={solid('warning')} color={theme.error.main} />
        <TitleText>{warningTitle}</TitleText>
      </Title>
      {warningDescription && <Description>{warningDescription}</Description>}
      <Checkbox 
        checked={isChecked}
        onChange={(state: boolean) => onCheckboxChange(state)}
        label={checkboxLabel}
        labelStyle={{fontSize: '14px'}}
        wrapperStyle={{marginTop: '16px'}}
      />
    </ConfirmationBoxWrapper>
  );
};

export default ConfirmationBox;

const ConfirmationBoxWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.action.divider};
  padding: 16px;
  border-radius: 4px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TitleText = styled.p`
  margin: 0;
  color: ${(p) => p.theme.error.main};
  font-size: 14px;
`;

const Description = styled.p`
  margin: 5px 0 0 0;
  font-size: 14px;
  font-weight: 500;
`;