//#region Enum

import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

enum EsgMetricType {
  Emissions = 'Emissions',

  ElectricityTotalKwh = 'ElectricityTotalKwh',
  ElectricityDailyAverage = 'ElectricityDailyAverage',
  ElectricitySqmDailyAverage = 'ElectricitySqmDailyAverage',
  ElectricityVarianceFromAverage = 'ElectricityVarianceFromAverage',
  ElectricityVarianceFromMinimum = 'ElectricityVarianceFromMinimum',

  HeatingTotalKwh = 'HeatingTotalKwh',
  HeatingDailyAverage = 'HeatingDailyAverage',
  HeatingSqmDailyAverage = 'HeatingSqmDailyAverage',
  HeatingVarianceFromAverage = 'HeatingVarianceFromAverage',
  HeatingVarianceFromMinimum = 'HeatingVarianceFromMinimum',

  CoolingTotalKwh = 'CoolingTotalKwh',
  CoolingDailyAverage = 'CoolingDailyAverage',
  CoolingSqmDailyAverage = 'CoolingSqmDailyAverage',
  CoolingVarianceFromAverage = 'CoolingVarianceFromAverage',
  CoolingVarianceFromMinimum = 'CoolingVarianceFromMinimum',

  WaterTotalVolume = 'WaterTotalVolume',
  WaterDailyAverage = 'WaterDailyAverage',
  WaterSqmDailyAverage = 'WaterSqmDailyAverage',
  WaterVarianceFromAverage = 'WaterVarianceFromAverage',
  WaterVarianceFromMinimum = 'WaterVarianceFromMinimum',

  GasTotalVolume = 'GasTotalVolume',
  GasDailyAverage = 'GasDailyAverage',
  GasSqmDailyAverage = 'GasSqmDailyAverage',

  GasTotalKwh = 'GasTotalKwh',
  GasDailyAverageKwh = 'GasDailyAverageKwh',
  GasSqmDailyAverageKwh = 'GasSqmDailyAverageKwh',

  TemperatureMin = 'TemperatureMin',
  TemperatureMax = 'TemperatureMax',
  TemperatureAvg = 'TemperatureAvg',

  HumidityMin = 'HumidityMin',
  HumidityMax = 'HumidityMax',
  HumidityAvg = 'HumidityAvg',

  CO2Min = 'CO2Min',
  CO2Max = 'CO2Max',
  CO2Avg = 'CO2Avg',

  NoiseMax = 'NoiseMax',
}

export default EsgMetricType;

//#endregion

//#region Mapping accessor functions

namespace EsgMetricType { // eslint-disable-line
  /**
   * Get suffix
   */
  export const getSuffix = (metric: EsgMetricType): string => {
    return metricToSuffix[metric] ?? metric;
  }
  /**
   * Convert EsgMetricTypeToMetricType
   */
  export const toMetricType = (metric: EsgMetricType): MetricType | AltUnits => {
    return esgMetricTypeToMetricTypeMapping[metric];
  }
  /**
   * Check if a metric is a variance metric type
   */
  export const isVarianceMetric = (metric: EsgMetricType): boolean => {
    return VarianceMetrics.includes(metric);
  }
}

//#endregion

//#region Mappings

const metricToSuffix: { [key in EsgMetricType]: string; } = Object.freeze({
  [EsgMetricType.ElectricityTotalKwh]: 'kWh',
  [EsgMetricType.ElectricityDailyAverage]: 'kWh',
  [EsgMetricType.ElectricitySqmDailyAverage]: 'kWh',
  [EsgMetricType.ElectricityVarianceFromAverage]: '%',
  [EsgMetricType.ElectricityVarianceFromMinimum]: '%',

  [EsgMetricType.HeatingTotalKwh]: 'kWh',
  [EsgMetricType.HeatingDailyAverage]: 'kWh',
  [EsgMetricType.HeatingSqmDailyAverage]: 'kWh',
  [EsgMetricType.HeatingVarianceFromAverage]: '%',
  [EsgMetricType.HeatingVarianceFromMinimum]: '%',

  [EsgMetricType.CoolingTotalKwh]: 'kWh',
  [EsgMetricType.CoolingDailyAverage]: 'kWh',
  [EsgMetricType.CoolingSqmDailyAverage]: 'kWh',
  [EsgMetricType.CoolingVarianceFromAverage]: '%',
  [EsgMetricType.CoolingVarianceFromMinimum]: '%',

  [EsgMetricType.WaterTotalVolume]: 'm³',
  [EsgMetricType.WaterDailyAverage]: 'm³',
  [EsgMetricType.WaterSqmDailyAverage]: 'm³',
  [EsgMetricType.WaterVarianceFromAverage]: '%',
  [EsgMetricType.WaterVarianceFromMinimum]: '%',

  [EsgMetricType.GasTotalVolume]: 'm³',
  [EsgMetricType.GasDailyAverage]: 'm³',
  [EsgMetricType.GasSqmDailyAverage]: 'm³',

  [EsgMetricType.GasTotalKwh]: 'kWh',
  [EsgMetricType.GasDailyAverageKwh]: 'kWh',
  [EsgMetricType.GasSqmDailyAverageKwh]: 'kWh',

  [EsgMetricType.TemperatureMin]: '°C',
  [EsgMetricType.TemperatureMax]: '°C',
  [EsgMetricType.TemperatureAvg]: '°C',

  [EsgMetricType.HumidityMin]: '%',
  [EsgMetricType.HumidityMax]: '%',
  [EsgMetricType.HumidityAvg]: '%',

  [EsgMetricType.CO2Min]: 'ppm',
  [EsgMetricType.CO2Max]: 'ppm',
  [EsgMetricType.CO2Avg]: 'ppm',

  [EsgMetricType.NoiseMax]: 'db',

  [EsgMetricType.Emissions]: 'kgCO₂',
});

const esgMetricTypeToMetricTypeMapping: { [key in EsgMetricType]: MetricType | AltUnits } = Object.freeze({
  [EsgMetricType.ElectricityTotalKwh]: MetricType.ElectricityKwh,
  [EsgMetricType.ElectricityDailyAverage]: MetricType.ElectricityKwh,
  [EsgMetricType.ElectricitySqmDailyAverage]: AltUnits.EnergySqmDailyAverage,
  [EsgMetricType.ElectricityVarianceFromAverage]: MetricType.ElectricityKwh,
  [EsgMetricType.ElectricityVarianceFromMinimum]: MetricType.ElectricityKwh,
  [EsgMetricType.HeatingTotalKwh]: MetricType.HeatingKwh,
  [EsgMetricType.HeatingDailyAverage]: MetricType.HeatingKwh,
  [EsgMetricType.HeatingSqmDailyAverage]: AltUnits.EnergySqmDailyAverage,
  [EsgMetricType.HeatingVarianceFromAverage]: MetricType.HeatingKwh,
  [EsgMetricType.HeatingVarianceFromMinimum]: MetricType.HeatingKwh,
  [EsgMetricType.CoolingTotalKwh]: MetricType.CoolingKwh,
  [EsgMetricType.CoolingDailyAverage]: MetricType.CoolingKwh,
  [EsgMetricType.CoolingSqmDailyAverage]: AltUnits.EnergySqmDailyAverage,
  [EsgMetricType.CoolingVarianceFromAverage]: MetricType.CoolingKwh,
  [EsgMetricType.CoolingVarianceFromMinimum]: MetricType.CoolingKwh,
  [EsgMetricType.WaterTotalVolume]: MetricType.WaterVolume,
  [EsgMetricType.WaterDailyAverage]: MetricType.WaterVolume,
  [EsgMetricType.WaterSqmDailyAverage]: AltUnits.WaterSqmDailyAverage,
  [EsgMetricType.WaterVarianceFromAverage]: AltUnits.VariancePercentage,
  [EsgMetricType.WaterVarianceFromMinimum]: AltUnits.VariancePercentage,
  [EsgMetricType.GasTotalVolume]: MetricType.GasVolume,
  [EsgMetricType.GasDailyAverage]: MetricType.GasVolume,
  [EsgMetricType.GasSqmDailyAverage]: AltUnits.GasSqmDailyAverageVolume,
  [EsgMetricType.GasTotalKwh]: MetricType.GasKwh,
  [EsgMetricType.GasDailyAverageKwh]: MetricType.GasKwh,
  [EsgMetricType.GasSqmDailyAverageKwh]: AltUnits.EnergySqmDailyAverage,
  [EsgMetricType.TemperatureMin]: MetricType.Temperature,
  [EsgMetricType.TemperatureMax]: MetricType.Temperature,
  [EsgMetricType.TemperatureAvg]: MetricType.Temperature,
  [EsgMetricType.HumidityMin]: MetricType.Humidity,
  [EsgMetricType.HumidityMax]: MetricType.Humidity,
  [EsgMetricType.HumidityAvg]: MetricType.Humidity,
  [EsgMetricType.CO2Min]: MetricType.CO2,
  [EsgMetricType.CO2Max]: MetricType.CO2,
  [EsgMetricType.CO2Avg]: MetricType.CO2,
  [EsgMetricType.NoiseMax]: MetricType.Noise,
  [EsgMetricType.Emissions]: MetricType.Emissions
});

const VarianceMetrics = Object.freeze([
  EsgMetricType.ElectricityVarianceFromAverage,
  EsgMetricType.ElectricityVarianceFromMinimum,
  EsgMetricType.HeatingVarianceFromAverage,
  EsgMetricType.HeatingVarianceFromMinimum,
  EsgMetricType.WaterVarianceFromAverage,
  EsgMetricType.WaterVarianceFromMinimum
]);

export type ScalePoint = {
  /**
   * The lower limit threshold, value will be compared with greater than or equal operator (i.e. '>=')
   */
  threshold: number,
  /**
   * Color of indicator
   */
  color: string,
}

export const getEsgMetricColorScales = (metricType: EsgMetricType): ScalePoint[] => {
  switch (metricType) {
    case EsgMetricType.ElectricityTotalKwh:
    case EsgMetricType.ElectricityDailyAverage:
    case EsgMetricType.ElectricitySqmDailyAverage:
    case EsgMetricType.ElectricityVarianceFromAverage:
    case EsgMetricType.ElectricityVarianceFromMinimum:
      return [{ threshold: 0, color: '#FFB500' }];
    case EsgMetricType.HeatingTotalKwh:
    case EsgMetricType.HeatingDailyAverage:
    case EsgMetricType.HeatingSqmDailyAverage:
    case EsgMetricType.HeatingVarianceFromAverage:
    case EsgMetricType.HeatingVarianceFromMinimum:
      return [{ threshold: 0, color: '#FF821B' }];
    case EsgMetricType.CoolingTotalKwh:
    case EsgMetricType.CoolingDailyAverage:
    case EsgMetricType.CoolingSqmDailyAverage:
    case EsgMetricType.CoolingVarianceFromAverage:
    case EsgMetricType.CoolingVarianceFromMinimum:
      return [{ threshold: 0, color: '#00c3ff' }];
    case EsgMetricType.WaterTotalVolume:
    case EsgMetricType.WaterDailyAverage:
    case EsgMetricType.WaterSqmDailyAverage:
    case EsgMetricType.WaterVarianceFromAverage:
    case EsgMetricType.WaterVarianceFromMinimum:
      return [{ threshold: 0, color: '#1B98FF' }];
    case EsgMetricType.GasTotalVolume:
    case EsgMetricType.GasDailyAverage:
    case EsgMetricType.GasSqmDailyAverage:
    case EsgMetricType.GasTotalKwh:
    case EsgMetricType.GasDailyAverageKwh:
    case EsgMetricType.GasSqmDailyAverageKwh:
      return [{ threshold: 0, color: '#00C851' }];
    case EsgMetricType.TemperatureMin:
    case EsgMetricType.TemperatureMax:
    case EsgMetricType.TemperatureAvg:
      return [
        { threshold: 0, color: '#1b22ff' },
        { threshold: 17, color: '#7A7FFF' },
        { threshold: 19, color: '#4fe94a' },
        { threshold: 22, color: '#FFCC00' },
        { threshold: 24, color: '#fe7b58' },
        { threshold: 26, color: '#fe6767' },
      ];
    case EsgMetricType.CO2Min:
    case EsgMetricType.CO2Max:
    case EsgMetricType.CO2Avg:
      return [
        { threshold: 0, color: '#0cc320' },
        { threshold: 400, color: '#4fe94a' },
        { threshold: 750, color: '#b8e94a' },
        { threshold: 1000, color: '#FFCC00' },
        { threshold: 1500, color: '#fe7b58' },
        { threshold: 2000, color: '#fe6767' },
      ];
    case EsgMetricType.HumidityMin:
    case EsgMetricType.HumidityMax:
    case EsgMetricType.HumidityAvg:
      return [
        { threshold: 0, color: '#0cc320' },
        { threshold: 50, color: '#FFCC00' },
        { threshold: 75, color: '#fe7b58' },
        { threshold: 85, color: '#fe6767' },
      ];
    case EsgMetricType.NoiseMax:
      return [
        { threshold: 0, color: '#0cc320' },
        { threshold: 55, color: '#b8e94a' },
        { threshold: 65, color: '#FFCC00' },
        { threshold: 75, color: '#fe7b58' },
        { threshold: 80, color: '#fe6767' },
      ];
    case EsgMetricType.Emissions:
      return [
        { threshold: 0, color: '#f67045' },
      ];
    default:
      return [{ threshold: 0, color: '#007CF1' }];
  }
};

//#endregion
