import { PostQuery } from '@api/queries/common/PostQuery';

export class EmailSendCommand extends PostQuery<unknown> {
  email: string;
  subject: string;
  preHeader?: string;
  bannerHeader?: string;
  title: string;
  paragraphs: string[];

  constructor(email: string, subject: string, title: string, paragraphs: string[], preHeader?: string, bannerHeader?: string) {
    super();
    this.email = email;
    this.subject = subject;
    this.preHeader = preHeader;
    this.bannerHeader = bannerHeader;
    this.title = title;
    this.paragraphs = paragraphs;
  }

  public targetUrl(): string {
    return '/email';
  }
}
