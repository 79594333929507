import { BlobName } from '@api/models/BlobName';
import { PostFormDataQuery } from '@api/queries/common/PostFormDataQuery';

export default class UserProfileProfileImageUploadCommand extends PostFormDataQuery<BlobName> {

  constructor(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    super(formData);
  }

  public targetUrl(): string {
    return '/userprofile/profileImage';
  }
}
