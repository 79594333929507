import SitesGetAllQuery from '@api/queries/sites/SitesGetAllQuery';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Site } from '@api/models/Site';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { useApiState } from '@hooks/useApiState';
import { usePopover } from '@hooks/usePopover';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

type PropTypes = {
  site: Site;
}

const SiteSwitcher = ({ site }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { trackAction } = useAnalytics();
  const { visible, toggle, ref } = usePopover({});
  const { data: sites } = useApiState({
    query: new SitesGetAllQuery(),
    initialState: []
  }, []);

  const handleToggle = useCallback(() => {
    trackAction('sites_link', 'site_navigation');
    toggle();
  }, [trackAction, toggle]);

  const popoverContent = (
    <PopoverContent>
      <SwitchLabel>
        {t('SwitchSites', { ns: 'common' })}
      </SwitchLabel>

      <List>
        {sites.map(x => (
          <ListItem
            key={x.name}
            link={{ path: `./../${x.id}`, analytics: { action: 'sites_link', category: 'site_navigation' } }}
            className={x.id === site.id ? 'active' : ''}
          >
            <SiteName>
              {x.name}
            </SiteName>
            <SiteAddress>
              {x.address.addressLine1}, {x.address.city}
            </SiteAddress>
          </ListItem>
        ))}
      </List>

      <Divider />

      <BackButton to='..'>
        <Arrow icon={solid('arrow-left')} />
        {t('BackToAllSites', { ns: 'navigation' })}
      </BackButton>
    </PopoverContent>
  );

  return (
    <Popover
      ref={ref}
      visible={visible}
      popoverContent={popoverContent}
      placement={PopoverPlacement.BottomLeft}
      fixed
      offsetY={-3}
      offsetX={-48}
      hideTriangle
      containerStyles={{
        borderRadius: 8,
        border: `1px solid ${theme.action.divider}`,
        boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.08)'
      }}
    >

      <SitesLabel onClick={handleToggle}>
        {t('Sites', { ns: 'assets' })}
      </SitesLabel>
    </Popover>
  );
};

export default SiteSwitcher;

const SitesLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.primary.main};
  cursor: pointer;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 220px;
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
  white-space: wrap;
  overflow: auto;
`;

const SwitchLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding: 16px 16px 8px 16px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow: auto;
`;

const ListItem = styled(ProtectedLink)`
  position: relative;
  margin: 3px 16px 0 16px;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  border-left: 3px solid transparent;

  &:hover {
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};
  }
  
  &.active {
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};
  }

  &.active,
  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -4px;
      width: 4px;
      height: 25px;
      border-radius: 8px;
      background-color: ${p => p.theme.primary.main};
    }
  }
`;

const Divider = styled.div`
  margin: 4px 16px 12px 16px;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const SiteName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-bottom: 4px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SiteAddress = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Arrow = styled(FontAwesomeIcon)`
  color: ${p => p.theme.text.secondary};
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  color: ${p => p.theme.text.primary} !important;

  margin: 0 16px 12px;
  padding: 8px 0;
  border-radius: 4px;
  transition: all 300ms ease;

  &:hover {
    color: ${p => p.theme.primary.main} !important;
    background-color: ${p => p.theme.primary.hover};
  }
`;