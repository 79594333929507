import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Xarrow from 'react-xarrows';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MeterMultiSelectModal from './MeterMultiSelectModal';
import { TopologyCard, TopologyCardHeader, TopologyCardHeaderSlidingText } from './shared';
import SubMeterGroupItem from './SubMeterGroupItem';
import { EnergyMeterTopologyLevel } from '@api/enums/EnergyMeterTopologyLevel';
import { EnergyMeterTopologyDto } from '@api/models/EnergyMeterTopologyDto';
import { useModal } from '@hooks/useModal';

interface ISubMeterGroup {
  meters: EnergyMeterTopologyDto[];
  subMeters: EnergyMeterTopologyDto[];
  parentMeter: EnergyMeterTopologyDto;
  onChange: (meters: EnergyMeterTopologyDto[]) => void;
  arrowRefId?: string;
  parentArrowRefId?: string;
}

const SubMeterGroup = ({ meters, subMeters, parentMeter, onChange, arrowRefId, parentArrowRefId }: ISubMeterGroup) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const { isOpen, toggle, ref } = useModal({});
  const allSubMeters = meters.filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Sub);

  return (
    <>
      <TopologyCard id={arrowRefId}>
        <TopologyCardHeader color={theme.palette.primary} onClick={toggle}>
          <div>
            {t('Buildings.EnergyMeters.SubMeterGroup.SubMeters', { ns: 'settingsAsset' })}
          </div>

          <div style={{ display: 'flex', gap: '5px' }}>
            <TopologyCardHeaderSlidingText>Edit</TopologyCardHeaderSlidingText>
            <EditIcon icon={solid('pen-to-square')} />
          </div>
        </TopologyCardHeader>

        {subMeters.length === 0 &&
          <EmptyGroup onClick={toggle}>
            <FontAwesomeIcon icon={solid('plus')} />
          </EmptyGroup>
        }

        {subMeters.length > 0 &&
          <Group>
            <ScrollWrapper>
              {subMeters.map((meter, i) => (
                <SubMeterGroupItem
                  key={i}
                  meter={meter}
                />
              ))}
            </ScrollWrapper>
          </Group>
        }
      </TopologyCard>

      <MeterMultiSelectModal
        ref={ref}
        isOpen={isOpen}
        toggle={toggle}
        selected={subMeters}
        options={allSubMeters}
        parentMeter={parentMeter}
        onSelectChange={onChange}
        showFilters
      />

      {parentArrowRefId && arrowRefId &&
        <Xarrow
          start={parentArrowRefId}
          end={arrowRefId}
          color={theme.palette.borders.strong}
          strokeWidth={2}
          startAnchor='bottom'
          endAnchor='top'
          path="straight"
        />
      }
    </>
  );
};

export default SubMeterGroup;

export const EditIcon = styled(FontAwesomeIcon)`
  color: #ffffffb5;

  ${TopologyCardHeader}:hover & {
    color: #fff
  }
`;

const Group = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 3;
  cursor: pointer;

  width: 320px;
  height: max-content;
  min-height: 80px;
  max-height: 108px;

  color: ${p => p.theme.palette.text.weak};
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 0 0 9px 9px;
`;

const ScrollWrapper = styled.div`
  overflow: auto;
  flex-shrink: 1;
`;

const EmptyGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 320px;
  height: 80px;
  border-radius: 0 0 9px 9px;

  color: ${p => p.theme.palette.text.weak};
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 3px 8px ${p => p.theme.palette.shadows.medium};

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;
