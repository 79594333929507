import { FlexColumn } from '@components/core/FlexColumn';
import FlexRow from '@components/core/FlexRow';
import { TRVControlMode } from '@api/enums/TRVControlMode';
import { Input } from '@components/Form';
import { Switch } from '@components/core/Switch';
import { Title } from '@components/core/Title';
import { Card } from '@components/core/Card';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TemperatureRangeForm } from '../types/TemperatureRangeForm';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { ClimateControlConstants } from '@pages/site/temperature-control/ClimateControlUtils';

type ExternalSensorProps = {
  rangeMinTemp: number;
  rangeMaxTemp: number;
  unit: string;
}

const ExternalSensor = ({ rangeMinTemp, rangeMaxTemp, unit }: ExternalSensorProps) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { register, watch, setValue } = useFormContext<TemperatureRangeForm>();
  const offlineSensorThreshold = watch('fallbackSettings.offlineSensorThreshold');
  const controlMode = watch('fallbackSettings.controlMode');
  const enabled = controlMode === TRVControlMode.External;
  const hoursLabel = offlineSensorThreshold === 1
    ? t('ClimateControl.Hour', { ns: 'molecules' })
    : t('ClimateControl.Hours', { ns: 'molecules' });

  const handleToggleChange = (checked: boolean) => {
    setValue('fallbackSettings.controlMode', checked ? TRVControlMode.External : TRVControlMode.TRV)
    trackAction(checked ? 'enable_external_sensor_mode' : 'disable_external_sensor_mode', 'climate_control');
  };

  return (
    <Card>
      <StyledFlexColumn>
        <StyledFlexRow>
          <Title
            text={t('ClimateControl.ExternalTemperatureSensor', { ns: 'molecules' })}
          />
          <Switch
            checked={enabled}
            onChange={handleToggleChange}
          />
        </StyledFlexRow>
        <StyledFlexRow>
          <StyledLabel>
            {t('ClimateControl.ExternalSensorDescription', { ns: 'molecules' })}
          </StyledLabel>
        </StyledFlexRow>
        <StyledFlexColumn style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}>
          <StyledFlexRow disabled={!enabled}>
            <ContentWrapper>
              {t('ClimateControl.WhenSensorIsOfflineFor', { ns: 'molecules' })}
              <StyledNumberInput
                disabled={!enabled}
                {...register('fallbackSettings.offlineSensorThreshold', {
                  valueAsNumber: true,
                  required: true,
                  onChange: (e) => {
                    if (e.target.value > ClimateControlConstants.HourlyThresholdMax) {
                      setValue('fallbackSettings.offlineSensorThreshold', ClimateControlConstants.HourlyThresholdMax)
                    } else if (e.target.value < ClimateControlConstants.HourlyThresholdMin) {
                      setValue('fallbackSettings.offlineSensorThreshold', ClimateControlConstants.HourlyThresholdMin)
                    } else {
                      setValue('fallbackSettings.offlineSensorThreshold', e.target.value)
                    }
                  }
                })}
                type='number'
              />
            </ContentWrapper>
            <ContentWrapper>
              {hoursLabel}, {t('ClimateControl.SetTemperatureToExternalSesnor', { ns: 'molecules' })}
              <HeaderRow>
                <StyledNumberInput
                  disabled={!enabled}
                  {...register('fallbackSettings.offlineSensorTemp', {
                    valueAsNumber: true,
                    required: true,
                    onChange: (e) => {
                      if (e.target.value > rangeMaxTemp) {
                        setValue('fallbackSettings.offlineSensorTemp', rangeMaxTemp)
                      } else if (e.target.value < rangeMinTemp) {
                        setValue('fallbackSettings.offlineSensorTemp', rangeMinTemp)
                      } else {
                        setValue('fallbackSettings.offlineSensorTemp', e.target.value)
                      }
                    }
                  })}
                  type='number'
                />
                <Unit>{unit}</Unit>
              </HeaderRow>
            </ContentWrapper>
          </StyledFlexRow>
        </StyledFlexColumn>

      </StyledFlexColumn>
    </Card>
  )
}

export default ExternalSensor;

const StyledFlexColumn = styled(FlexColumn)`
  gap: 20px;
`;

const HeaderRow = styled(FlexRow)`
  align-items: center;
  gap: 10px;
`;

const StyledFlexRow = styled(FlexRow) <{ disabled?: boolean }>`
  align-items: center;
  gap: 10px;
  opacity: ${p => p.disabled ? 0.5 : 1};
  transition: opacity .1s;

  @media (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 375px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const StyledNumberInput = styled(Input)`
  width: 60px;
  height: 32px;
  border-radius: 4px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
  text-align: left;
  padding: 10px 0 10px 10px;
`;

const Unit = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
`;

const StyledLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${p => p.theme.text.secondary};
`;