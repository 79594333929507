import SitesGetAllQuery from '@api/queries/sites/SitesGetAllQuery';
import { Loading } from '@components/core/Loading';
import { useApiState } from '@hooks/useApiState';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssetMap from '@components/maps/AssetMap';
import styled from 'styled-components';
import SiteList from './components/SiteList';

const LandingPage = () => {
  const { t } = useTranslation(['main']);
  const [hoveredAssetId, setHoveredAssetId] = useState<number | null>(null);

  const { data: sites, loading } = useApiState({
    query: new SitesGetAllQuery(),
    errorMessage: t('AssetListMapPage.SiteLoadError', { ns: 'main' })
  }, []);

  const handleHover = (assetId: number) => {
    setHoveredAssetId(assetId);
  };

  const handleHoverEnd = () => {
    setHoveredAssetId(null);
  };

  if (loading) {
    return <Loading fullWidthCentered message={t('AssetListMapPage.SiteLoading', { ns: 'main' })} />
  }

  if (!sites) {
    return null;
  }

  return (
    <FlexRow>
      <Sidebar>
        <SiteList
          sites={sites}
          onHover={handleHover}
          onHoverEnd={handleHoverEnd}
        />
      </Sidebar>

      <Content>
        <AssetMap
          sites={sites}
          hoveredAssetId={hoveredAssetId}
          mapOptions={{
            interactive: true,
            gestureHandling: 'cooperative'
          }}
        />
      </Content>
    </FlexRow>
  );
};

export default LandingPage;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Sidebar = styled.div`
  width: 100%;
  background-color: ${p => p.theme.background.container};
  flex-shrink: 0;
  
  @media (min-width: 600px) {
    width: 250px;
  }
`;

const Content = styled.div`
  width: 0px;

  @media (min-width: 600px) {
    width: 100%;
  }
`;