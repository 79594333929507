import { useEffect, useState } from 'react';
import { EnergyConsumptionWidgetConfig, createEnergyConsumptionWidgetConfig } from '../pages/site/esg/components/energy-consumption-widget/helpers/ModifierHelpers';
import { ReportEnergyConsumptionUsageDto } from '@api/models/ReportEnergyConsumptionDto';
import { ReportMetadataDto } from '@api/models/ReportMetadataDto';
import { mod } from '@utils/NumberUtils';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';

const checkUpwardsTrend = (config: EnergyConsumptionWidgetConfig, reportDate: Date) => {
  // Date.getMonth() returns a month index between 0-11, this equals the previous month in our historicSeriesData dto as this indexes months from 1-12.
  const previousMonthUsage = config.data.historicSeriesData[mod(reportDate.getMonth() - 1, config.data.historicSeriesData.length)]?.currentYearTotal;

  // Return undefined if there is no previousMonthUsage or the current month's usage is the same as the previous month's usage
  if (previousMonthUsage === null || config.data.totalUsage === previousMonthUsage) {
    return;
  }

  return config.data.totalUsage > previousMonthUsage;
};

type PropTypes = {
  reportDate: Date;
  dto: ReportEnergyConsumptionUsageDto;
  metadata: ReportMetadataDto[];
}

export const useEnergyConsumptionWidget = ({ reportDate, dto, metadata }: PropTypes) => {
  const [useTransform, setUseTransform] = useState(false);
  const [defaultConfig, setDefaultConfig] = useState<EnergyConsumptionWidgetConfig>();
  const [transformConfig, setTransformConfig] = useState<EnergyConsumptionWidgetConfig>();
  const [hasUpwardsTrend, setHasUpwardsTrend] = useState<boolean>();
  const { getUnit, fromLocale } = useLocalisation();

  useEffect(() => {
    const { config, transform } = createEnergyConsumptionWidgetConfig(dto, metadata, getUnit, fromLocale);

    setDefaultConfig(config);
    setTransformConfig(transform);
    setHasUpwardsTrend(checkUpwardsTrend(config, reportDate));
  }, [reportDate, dto, metadata, getUnit, fromLocale]);

  // Use the default config or the transform if toggled on
  const config = useTransform && transformConfig ? transformConfig : defaultConfig;

  return {
    config,
    hasUpwardsTrend,
    setUseTransform
  };
};

export default useEnergyConsumptionWidget;