import { SiteFeature } from '@api/models/SiteFeature';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SiteFeaturesGetBySiteQuery extends GetQuery<SiteFeature[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/sites/GetSiteFeatures?siteId=${this.siteId}`;
  }
}
