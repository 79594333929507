import { OperatorPrizeDto } from '@api/models/OperatorPrizeDto';
import { OperatorPostQuery } from '@api/queries/resident-app/common/OperatorPostQuery';

export class PrizesResidentAppBulkCreateCommand extends OperatorPostQuery<OperatorPrizeDto[]> {
  challengeId: number;
  prizes: OperatorPrizeDto[];

  constructor(challengeId: number, prizes: OperatorPrizeDto[]) {
    super();
    this.challengeId = challengeId;
    this.prizes = prizes;
  }

  public targetUrl(): string {
    return '/Prizes/BulkCreate';
  }
}
