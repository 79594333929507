import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Metric } from '@api/models/Metric';
import { IMeterWidgetConfig } from '@contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import Meter from '@components/core/Meter';
import { stringToFloat } from '@utils/NumberUtils';

interface IMeterWidget {
  metrics: Metric[];
  config?: IMeterWidgetConfig;
  spaceColor: string;
  maxOccupancy?: number;
}

const MeterWidget = ({ metrics, config, spaceColor, maxOccupancy }: IMeterWidget) => {
  const { t } = useTranslation(['deviceConfigs', 'enums']);

  if (!config) {
    return null;
  }

  const metric = metrics.find(x => x.metricType === config.metricType);

  if (!metric) {
    return null;
  }

  return (
    <Wrapper>
      <Meter
        label={config.label ? t(config.label, { ns: 'deviceConfigs' }) : t(config.metricType, { ns: 'enums' })}
        max={config.maxValue ?? maxOccupancy}
        value={stringToFloat(metric.value, config.decimalPlaces ?? 0)}
        color={spaceColor}
        height='8px'
        showMaxLabel
        labelBarGap='5px'
      />
    </Wrapper>
  );
};

export default MeterWidget;

const Wrapper = styled.div`
  width: 100%;
`;