import { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { groupBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import AssetListItem from '@components/core/AssetListItem';
import { Asset } from '@api/models/Asset';
import { Loading } from '@components/core/Loading';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { SearchField } from '@components/search-field/SearchField';
import { includesCI } from '@utils/StringUtils';
import { Button } from '@components/core/Button';
import { Title } from '@components/core/Title';

type PropTypes = {
  items: Asset[],
  loading: boolean,
  assetTypeName: string,
  createlinkPath: (assetId: number) => string,
  showCreateButton?: boolean,
  isBuildingList?: boolean
}

const AssetList = ({ items: itemsProp, loading, assetTypeName, createlinkPath, showCreateButton, isBuildingList }: PropTypes) => {
  const theme = useTheme();
  const [items, setItems] = useState<Asset[]>([]);
  const [itemsFiltered, setItemsFiltered] = useState<Asset[]>([]);

  useEffect(() => {
    setItems(itemsProp);
    setItemsFiltered(itemsProp);
  }, [itemsProp]);

  const filterPredicate = (x: Asset, searchValue: string) => {
    return includesCI(x.name, searchValue)
      || includesCI(x.address.addressLine1, searchValue)
      || (x.address.addressLine2 ? includesCI(x.address.addressLine2, searchValue) : false)
      || includesCI(x.address.city, searchValue)
      || (x.address.region ? includesCI(x.address.region, searchValue) : false)
      || includesCI(x.address.postCode, searchValue)
  };

  const handleSearchChange = useCallback((str: string) => {
    const filtered = items.filter(x => filterPredicate(x, str));
    setItemsFiltered(filtered);
  }, [items]);

  const groupedBySite = groupBy(itemsFiltered, x => x.siteName);

  return (
    <PaddedContainer >
      <FlexContainer>
        <Title text={<>{assetTypeName} <ThemeColored>({itemsFiltered.length})</ThemeColored></>} />

        <RightAligned>
          <SearchField
            placeholder={`Search ${assetTypeName.toLowerCase()}`}
            onSearchChange={handleSearchChange}
            style={{ background: theme.action.filled }}
          />
          {showCreateButton &&
            <Link to={'create'}>
              <Button
                tertiary
                circle
                label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
              />
            </Link>
          }
        </RightAligned>
      </FlexContainer>

      {loading &&
        <Loading />
      }

      {!loading && Object.entries(groupedBySite).map((group, i) => (
        <div key={i}>
          {isBuildingList &&
            <SiteName>{group[1][0].siteName}</SiteName>
          }

          {group[1].map((asset) => (
            <AssetListItem
              key={asset.id}
              asset={asset}
              createlinkPath={createlinkPath}
            />
          ))}
        </div>
      ))}
    </PaddedContainer>
  );
}

export default AssetList;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;

const RightAligned = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: auto;
`;

const SiteName = styled.div`
  color: ${p => p.theme.palette.text.medium};
  font-size: 16px;
  font-weight: 500;
  padding: 15px 10px 10px 10px;
`;