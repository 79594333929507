import DoughnutChart from '@components/charts/DoughnutChart';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import WidgetCard from '../../WidgetCard';
import { DefaultTheme, useTheme } from 'styled-components';
import { useSiteContext } from '@pages/site/SiteProvider';
import NoData from '@components/core/NoData';
import { TDoughnutChartLegend } from '@components/charts/DoughnutChartLegend';
import { TFunction } from 'i18next';
import UnoccupiedSpacesGetQuery from '@api/queries/metrics/UnoccupiedSpacesGetQuery';
import { useApiState } from '@hooks/useApiState';
import { addDays } from 'date-fns';
import { useMemo } from 'react';
import { createTooltipOptions } from '../temperature/ChartUtils';

const createDataset = (data: WidgetData, t: TFunction, theme: DefaultTheme) => {
  const datasets = {
    data: [data.occupied, data.unoccupiedOverOneDay, data.unoccupiedOverSevenDays],
    colors: [theme.widget.green, theme.widget.blue, theme.widget.red],
    labels: [
      t('SiteOverview.UnoccupiedWidget.Tooltip.LabelOccupied', { ns: 'molecules', hours: 24 }),
      t('SiteOverview.UnoccupiedWidget.Tooltip.LabelOverOneDay', { ns: 'molecules', hours: 24 }),
      t('SiteOverview.UnoccupiedWidget.Tooltip.LabelOverSevenDays', { ns: 'molecules', days: 7 })
    ],
  }

  if (data.offlineDevices) {
    datasets.data.push(data.offlineDevices);
    datasets.colors.push(theme.palette.siteOverview.charts.secondary);
    datasets.labels.push(t('SiteOverview.UnoccupiedWidget.Tooltip.LabelOfflineDevices', { ns: 'molecules' }));
  }

  return datasets;
}

const createLegend = (data: WidgetData, t: TFunction, theme: DefaultTheme): TDoughnutChartLegend => {
  const legend: TDoughnutChartLegend = {
    title: t('LastMotionDetected', { ns: 'common' }),
    items: [
      {
        label: t('SiteOverview.UnoccupiedWidget.LabelOccupied', { ns: 'molecules', count: data.occupied, hours: 24 }),
        color: theme.widget.green,
        link: { path: 'unoccupied-spaces/occupied', analytics: { action: 'unoccupied_widget_under1day', category: 'overview_live_updates' } }
      },
      {
        label: t('SiteOverview.UnoccupiedWidget.LabelOverOneDay', { ns: 'molecules', count: data.unoccupiedOverOneDay, hours: 24 }),
        color: theme.widget.blue,
        link: { path: 'unoccupied-spaces/one-day', analytics: { action: 'unoccupied_widget_over1day', category: 'overview_live_updates' } }
      },
      {
        label: t('SiteOverview.UnoccupiedWidget.LabelOverSevenDays', { ns: 'molecules', count: data.unoccupiedOverSevenDays, days: 7 }),
        color: theme.widget.red,
        link: { path: 'unoccupied-spaces/seven-days', analytics: { action: 'unoccupied_widget_over7days', category: 'overview_live_updates' } }
      }
    ]
  };

  if (data.offlineDevices) {
    legend.items?.push({
      label: data.offlineDevices === 1 ? t('SiteOverview.UnoccupiedWidget.LabelOfflineDevice', { ns: 'molecules', count: data.offlineDevices }) : t('SiteOverview.UnoccupiedWidget.LabelOfflineDevices', { ns: 'molecules', count: data.offlineDevices }),
      color: theme.palette.siteOverview.charts.secondary,
      link: { path: 'unoccupied-spaces/offline-devices', analytics: { action: 'unoccupied_widget_offline_devices', category: 'overview_live_updates' } }
    });
  }

  return legend;
}

type WidgetData = {
  occupied: number;
  unoccupiedOverOneDay: number;
  unoccupiedOverSevenDays: number;
  unoccupiedTotal: number;
  offlineDevices: number;
}

const UnoccupiedSpacesWidget = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { site } = useSiteContext();

  const { data, loading } = useApiState({
    query: new UnoccupiedSpacesGetQuery(site.id)
  }, [site]);

  const widgetData = useMemo(() => {
    if (!data) {
      return;
    }

    const now = new Date();

    const onlineDevices = data.filter(x => x.deviceIsOnline);
    const offlineDevices = data.filter(x => !x.deviceIsOnline);

    const occupied = onlineDevices.filter(x => x.motionMeasuredOn && new Date(x.motionMeasuredOn) > addDays(now, -1)).length;
    const unoccupiedOverOneDay = onlineDevices.filter(x => x.motionMeasuredOn && new Date(x.motionMeasuredOn) <= addDays(now, -1) && new Date(x.motionMeasuredOn) > addDays(now, -7)).length;
    const unoccupiedOverSevenDays = onlineDevices.filter(x => !x.motionMeasuredOn || new Date(x.motionMeasuredOn) <= addDays(now, -7)).length;

    return {
      occupied: occupied,
      unoccupiedOverOneDay: unoccupiedOverOneDay,
      unoccupiedOverSevenDays: unoccupiedOverSevenDays,
      unoccupiedTotal: unoccupiedOverOneDay + unoccupiedOverSevenDays,
      offlineDevices: offlineDevices.length
    };
  }, [data]);

  // Hide the widget if no spaces were returned (this is the case when a site has no multisensors installed)
  if (data && data.length === 0) {
    return null;
  }

  return (
    <WidgetCard
      icon={regular('house-window')}
      title={t('UnoccupiedSpaces', { ns: 'common' })}
      loading={{
        state: loading,
        icon: duotone('house-window'),
        label: t('Loading', { ns: 'status' })
      }}
    >

      <>
        {!data &&
          <NoData
            subLabel={t('DataUnavailable', { ns: 'status' })}
            styles={{ paddingTop: 40 }}
            iconColor={theme.primary.light}
          />
        }

        {widgetData &&
          <DoughnutChart
            dataset={createDataset(widgetData, t, theme)}
            size="120px"
            cutout="83%"
            arcBorderWidth={2}
            innerValue={widgetData.unoccupiedTotal}
            innerValueStyles={{ fontSize: '24px' }}
            innerSubLabel={t('Unoccupied', { ns: 'common' })}
            legend={createLegend(widgetData, t, theme)}
            tooltipOptions={createTooltipOptions(theme)}
          />
        }
      </>
    </WidgetCard>
  )
}

export default UnoccupiedSpacesWidget;