import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@components/core/Button';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TemperatureRangeForm } from '../types/TemperatureRangeForm';
import { MetricType } from '@api/enums/MetricType';
import { ClimateControlDefaults } from '@pages/site/temperature-control/ClimateControlUtils';

const ResetRecommended = () => {
  const { t } = useTranslation();
  const { toLocale } = useLocalisation();
  const { setValue } = useFormContext<TemperatureRangeForm>();

  const resetRecommended = () => {
    // Reset range and target
    setValue('temperatureRangeSettings.targetTemp', toLocale(MetricType.Temperature, ClimateControlDefaults.Target, { round: 0 }));
    setValue('temperatureRangeSettings.minTemp', toLocale(MetricType.Temperature, ClimateControlDefaults.Min, { round: 0 }));
    setValue('temperatureRangeSettings.maxTemp', toLocale(MetricType.Temperature, ClimateControlDefaults.Max, { round: 0 }));

    // Reset step-down settings
    setValue('fallbackSettings.noMotionThreshold1', ClimateControlDefaults.StepDownOneThreshold);
    setValue('fallbackSettings.noMotionThreshold1Temp', toLocale(MetricType.Temperature, ClimateControlDefaults.StepDownOneTemp, { round: 0 }));
    setValue('fallbackSettings.noMotionThreshold2', ClimateControlDefaults.StepDownTwoThreshold);
    setValue('fallbackSettings.noMotionThreshold2Temp', toLocale(MetricType.Temperature, ClimateControlDefaults.StepDownTwoTemp, { round: 0 }));

    // Reset offline external sensor settings
    setValue('fallbackSettings.offlineSensorThreshold', ClimateControlDefaults.OfflineSensorThreshold);
    setValue('fallbackSettings.offlineSensorTemp', toLocale(MetricType.Temperature, ClimateControlDefaults.OfflineSensorTemp, { round: 0 }));

  }

  return (
    <RecommendedContainer>
      <RecommendedContent style={{ fontWeight: 500 }}>
        {t('ClimateControl.RecommendedHeader', { ns: 'molecules' })}
      </RecommendedContent>
      <RecommendedContent>
        {t('ClimateControl.RecommendedContent', { ns: 'molecules' })}
      </RecommendedContent>
      <Button
        tertiary
        onClick={resetRecommended}
        label={
          <>
            <ActionButtonIcon
              icon={solid('arrows-rotate')}
            />
            {t('ClimateControl.RecommendedButton', { ns: 'molecules' })}
          </>
        }
        analytics={{ action: 'reset_temperature_controls_to_recommended', category: 'climate_control' }}
      />
    </RecommendedContainer>
  )
}

export default ResetRecommended;

const RecommendedContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  background-color: ${p => p.theme.palette.backgrounds.info};
  padding: 15px;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const RecommendedContent = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${p => p.theme.text.secondary};
`;

const ActionButtonIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;
`;
