import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmailSendCommand } from '@api/queries/email/EmailSendCommand';
import { Button } from '@components/core/Button';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useUserContext } from '@contexts/UserContext/UserContext';
import { env } from '@src/env/env';
import { useApi } from '@hooks/useApi';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useSiteContext } from '@pages/site/SiteProvider';
import Cookie from 'js-cookie';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import BackgroundPattern from './BackgroundPattern';
import { useSize } from '@hooks/useSize';

export const DemoRequestedCookie = 'demo-requested';

type PropTypes = {
  feature: string;
  heading: ReactNode;
  bulletPoints: ReactNode[];
  image?: string;
  imageStyles?: React.CSSProperties;
}

const PreviewBanner = ({ feature, heading, bulletPoints, image, imageStyles }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { height } = useSize(containerRef);
  const { execute, loading } = useApi();
  const { user } = useUserContext();
  const { tenant } = useTenantContext();
  const { site } = useSiteContext();
  const [demoRequested, setDemoRequested] = useState(false);
  const cookieName = `${DemoRequestedCookie}-${feature}`;
  const demoPreviouslyRequested = !!Cookie.get(cookieName);
  const mediumScreen = useMediaQuery('(max-width: 900px)');

  const handleDemoRequest = () => {
    const paragraph1 = `${user.fullName} has requested a demo of the ${feature} feature on Utopi Connect.`;
    const paragraph2 = `They were looking at the ${site.name} site in the ${tenant.name} tenant when requesting the demo.`;
    const paragraph3 = `Please contact ${user.fullName} via their email ${user.email} to arrange a demo.`;

    execute({
      query: new EmailSendCommand(env.emails.contact, 'Demo Request', `${feature} - Demo Request`, [paragraph1, paragraph2, paragraph3], feature, 'Demo Request')
    })

    Cookie.set(cookieName, 'true', {
      sameSite: 'strict',
      expires: 7,
    });

    setDemoRequested(true);
  }

  return (
    <Container ref={containerRef}>
      <div style={{ zIndex: 2 }}>
        <Heading>
          {heading}
        </Heading>

        <Points>
          {bulletPoints.map(x => (
            <FlexRow key={uuidv4()}>
              <Icon icon={solid('check')} />
              <Label>{x}</Label>
            </FlexRow>
          ))}
        </Points>


        <Footer>
          {demoRequested &&
            <Confirmation>
              {t('DemoRequestConfirmation', { ns: 'status' })}
            </Confirmation>
          }

          {!demoRequested &&
            <FlexRow>
              <Button
                label={t('RequestADemo', { ns: 'status' })}
                onClick={handleDemoRequest}
                loading={loading}
                disabled={demoPreviouslyRequested}
                style={demoPreviouslyRequested ? { backgroundColor: theme.previewBanner.disabledButtonBackground, color: theme.previewBanner.disabledButtonText } : {}}
              />

              {demoPreviouslyRequested &&
                <ConfirmationLabel>
                  {t('RecentDemoRequestMessage', { ns: 'status' })}
                </ConfirmationLabel>
              }
            </FlexRow>
          }
        </Footer>
      </div>

      {!mediumScreen && <Image src={image} style={imageStyles} />}

      {height &&
        <BackgroundPattern height={height} />
      }
    </Container>
  );
};

export default PreviewBanner;

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 32px;
  border: 1px solid ${p => p.theme.action.divider};
  border-radius: 4px;
  background-color: ${p => p.theme.previewBanner.background};
  box-shadow: 0px 4px 8px 0px rgba(34, 37, 51, 0.07);
  overflow: hidden;
`;

const Heading = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-bottom: 20px;
`;

const Points = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => p.theme.primary.main};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
`;

const Footer = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
  padding-top: 20px;
`;

const Image = styled.img`
  max-height: 240px;
  max-width: 100%;
  margin-bottom: -33px;
  margin-right: 10px;
  z-index: 2;
`;

const Confirmation = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;

const ConfirmationLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
`;