import { DeviceWidgetType, IWidgetConfig } from '@contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import ListWidget from './widgets/ListWidget';
import MeterWidget from './widgets/MeterWidget';
import RadarChartWidget from './widgets/RadarChartWidget';
import ScoreWidget from './widgets/ScoreWidget';
import { Metric } from '@api/models/Metric';
import MultisensorWidget from './widgets/MultisensorWidget';

interface IDeviceWidget {
  config: IWidgetConfig;
  metrics: Metric[];
  maxOccupancy?: number;
  spaceColor: string;
}

const DeviceWidget = ({ config, metrics, maxOccupancy, spaceColor }: IDeviceWidget) => {
  const renderWidget = (widgetConfig: IWidgetConfig, metrics: Metric[]) => {
    switch (widgetConfig.type) {
      case DeviceWidgetType.List:
        return (
          <ListWidget
            metrics={metrics}
            config={widgetConfig.listWidget}
          />
        );

      case DeviceWidgetType.Meter:
        return (
          <MeterWidget
            metrics={metrics}
            config={widgetConfig.meterWidget}
            spaceColor={spaceColor}
            maxOccupancy={maxOccupancy}
          />
        );

      case DeviceWidgetType.Score:
        return (
          <ScoreWidget
            metrics={metrics}
            config={widgetConfig.scoreWidget}
          />
        );

      case DeviceWidgetType.RadarChart:
        return (
          <RadarChartWidget
            metrics={metrics}
            config={widgetConfig.radarChartWidget}
            spaceColor={spaceColor}
          />
        );

      case DeviceWidgetType.Multisensor:
        return (
          <MultisensorWidget
            metrics={metrics}
            config={widgetConfig.multisensorWidget}
            spaceColor={spaceColor}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {renderWidget(config, metrics)}
    </>
  );
};

export default DeviceWidget;