import { BlobStorageContainerType } from '@api/enums/BlobStorageContainerType';
import { Tenant } from '@api/models/Tenant';
import { useFileFromBlobStorage } from '@hooks/useFileFromBlobStorage';
import styled from 'styled-components';

type PropTypes = {
  tenant: Tenant
};

const TenantLogo = ({ tenant }: PropTypes) => {
  const { blobUri } = useFileFromBlobStorage(tenant.operatorLogoId, BlobStorageContainerType.Shared);

  return (
    <LogoWrapper>
      {blobUri &&
        <Logo src={blobUri} />
      }
    </LogoWrapper>
  );
}

export default TenantLogo;

const LogoWrapper = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Logo = styled.img`
  width: auto;
  height: auto;
  max-width: 60px;
  max-height: 15px;
`;