import { EsgExplorerFilter, EsgExplorerFilterType } from '@api/models/EsgExplorerConfiguration';
import EsgMetricType from '@api/enums/EsgMetricType';
import { Input } from '@components/Form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LocalisationFunction, useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@api/enums/MetricType';
import { AltUnits } from '@contexts/LocalisationContext/AltUnits';

const expectsSingleValue = (c: EsgExplorerFilterType) => {
  return (c === EsgExplorerFilterType.EqualTo || c === EsgExplorerFilterType.NotEqualTo || c === EsgExplorerFilterType.GreaterThan
    || c === EsgExplorerFilterType.GreaterThanOrEqualTo || c === EsgExplorerFilterType.LessThan || c === EsgExplorerFilterType.LessThanOrEqualTo);
}

const expectsRange = (c: EsgExplorerFilterType) => {
  return (c === EsgExplorerFilterType.InsideOfRange || c === EsgExplorerFilterType.OutsideOfRange);
}

const localise = (conversion: LocalisationFunction, metricType: MetricType | AltUnits, value?: number | null, round?: number) => {
  if (value === null || value === undefined) {
    return undefined;
  }

  return conversion(metricType, value, { round: round ?? undefined });
};

type PropTypes = {
  filter: EsgExplorerFilter;
  onChange: (filter: EsgExplorerFilter) => void;
}

const FilterTypeConfig = ({ filter, onChange }: PropTypes) => {
  const { t } = useTranslation();
  const { toLocale, fromLocale, getUnit } = useLocalisation();

  if (!filter.metric || !filter.type) {
    return null;
  }

  const metricType = EsgMetricType.toMetricType(filter.metric);
  const unit = EsgMetricType.isVarianceMetric(filter.metric) ? '%' : getUnit(metricType);

  if (expectsSingleValue(filter.type)) {
    return (
      <FlexRow>
        <div style={{ width: 57, overflow: 'visible', whiteSpace: 'nowrap' }}>
          <Input
            type="number"
            value={localise(toLocale, metricType, filter.threshold, 2) ?? ''}
            onInput={(e) => {
              onChange({
                metric: filter.metric,
                type: filter.type,
                threshold: e.currentTarget.value === '' ? undefined : localise(fromLocale, metricType, Math.round(parseFloat(e.currentTarget.value))),
                min: null,
                max: null
              });
            }}
          />
        </div>
        <InlineLabel>{unit}</InlineLabel>
      </FlexRow>
    )
  }

  if (expectsRange(filter.type)) {
    return (
      <FlexRow>
        <div style={{ width: 57, overflow: 'visible', whiteSpace: 'nowrap' }}>
          <Input
            type="number"
            value={localise(toLocale, metricType, filter.min, 2) ?? ''}
            placeholder={t('Min', { ns: 'common' })}
            onInput={(e) => {
              onChange({
                metric: filter.metric,
                type: filter.type,
                threshold: null,
                min: e.currentTarget.value === '' ? undefined : localise(fromLocale, metricType, Math.round(parseFloat(e.currentTarget.value))),
                max: filter.max
              });
            }}
          />
        </div>
        -
        <div style={{ width: 57, overflow: 'visible', whiteSpace: 'nowrap' }}>
          <Input
            type="number"
            value={localise(toLocale, metricType, filter.max, 2) ?? ''}
            placeholder={t('Max', { ns: 'common' })}
            onInput={(e) => {
              onChange({
                metric: filter.metric,
                type: filter.type,
                threshold: null,
                min: filter.min,
                max: e.currentTarget.value === '' ? undefined : localise(fromLocale, metricType, Math.round(parseFloat(e.currentTarget.value))),
              });
            }}
          />
        </div>
        <InlineLabel>{unit}</InlineLabel>
      </FlexRow>
    )
  }

  return (
    <>
    </>
  );
};

export default FilterTypeConfig;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const InlineLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;