import { Cluster } from '@api/models/Cluster';
import { ClustersAndSpaces } from '@api/models/ClustersAndSpaces';
import { Space } from '@api/models/Space';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class SpaceBulkUpdateCommand extends PatchQuery<ClustersAndSpaces> {
  spaces: Space[];
  clusters?: Cluster[];

  constructor(space: Space[], clusters?: Cluster[]) {
    super();
    this.spaces = space;
    this.clusters = clusters;
  }

  public targetUrl(): string {
    return '/spaces/bulkUpdateClustersAndSpaces';
  }
}
