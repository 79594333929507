import { IInternalNode, IInternalNodeGroup, INodeGroup } from '../components/cascader-single-select/CascaderSingleSelect.types';

/**
 * Assigns a unique ID to each node
 */
export const createNodeTreeWithIds = <TValue,>(parentId: string, group?: INodeGroup<TValue>): IInternalNodeGroup<TValue> | undefined => {
  if (group === undefined) {
    return undefined;
  }

  const groupWithIds: IInternalNodeGroup<TValue> = {
    ...group,
    id: parentId,
    nodes: group.nodes.map((item, i) => ({
      ...item,
      id: `${parentId}_${i}`,
      childGroup: createNodeTreeWithIds(`${parentId}_${i}`, item.childGroup)
    }))
  }

  return groupWithIds;
};

/**
 * Check if a node is selected by default and return it
 */
export const getDefaultSelected = <TValue>(hierarchy: IInternalNodeGroup<TValue> | undefined): IInternalNode<TValue> | undefined => {
  if (!hierarchy) {
    return;
  }

  const selectedNode = hierarchy.nodes.find(x => x.selectedByDefault);

  if (selectedNode) {
    return selectedNode;
  } else {
    return hierarchy.nodes.map(x => getDefaultSelected(x.childGroup)).find(x => x);
  }
};