import styled, { css } from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@components/core/Title';
import FloorplanContainer from './components/FloorplanContainer';
import FloorplanSidepanel from './components/FloorplanSidepanel';
import { TopBarHeight } from '@src/constants/designConstants';
import DeviceModelSelect from '@pages/site/heatmap/components/selects/DeviceModelSelect';
import { HeatmapConfig } from '@pages/site/heatmap/HeatmapPage';
import { useApi } from '@hooks/useApi';
import HeatmapMetricSelect from '@pages/site/heatmap/components/selects/MetricSelect';
import { HeatmapSpaceDto } from '@api/models/HeatmapSpaceDto';
import HeatmapLegend from '@pages/site/heatmap/components/HeatmapLegend';
import HeatmapScale from '@pages/site/heatmap/components/HeatmapScale';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { IHeatmapConfig, getHeatmapMetricConfig } from '@src/component-configuration-models/heatmap/HeatmapConfig';
import { Card } from '@components/core/Card';
import { FloorplanDefaultDeviceModel, FloorplanDefaultMetricType } from '@src/component-configuration-models/floorplan/FloorplanDefaultSettings';
import Toggle, { ToggleOption } from '@components/core/Toggle';
import { useUserContext } from '@contexts/UserContext/UserContext';
import HeatmapQuery from '@api/queries/metrics/HeatmapQuery';
import { getCookieConsent } from '@components/consent-banner/ConsentBanner';
import { useBuildingContext } from '@pages/site/buildings/BuildingProvider';
import FullScreenToggle from '@components/core/FullScreenToggle';
import { BackButton } from '@components/core/BackButton';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { BreadcrumbHeight } from '@src/constants/LayoutConstants';
import { useLayoutContext } from '@contexts/LayoutContext';
import { DeviceModelWithMetricTypes } from '@api/models/DeviceModelWithMetricTypes';
import { MetricType } from '@api/enums/MetricType';
import { Label } from '@components/Form';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';

enum View {
  SpaceView = 'SpaceView',
  HeatmapView = 'HeatmapView'
}

const FLOORPLAN_HEATMAP_VIEW = 'FloorplanHeatmapView';
const FLOORPLAN_DEVICE_MODEL = 'FloorplanDeviceModel';
const FLOORPLAN_METRIC_TYPE = 'FloorplanMetricType';

export type FloorplanData = {
  spaces: HeatmapSpaceDto[];
  config: HeatmapConfig;
};

const Floorplan = () => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();
  const { isSuperAdmin } = useUserContext();
  const { toLocale, getUnit } = useLocalisation();
  const { execute, loading } = useApi();
  const { building, floor } = useBuildingContext();
  const [heatmapView, setHeatmapView] = useState(false);
  const [config, setConfig] = useState<HeatmapConfig>({});
  const [data, setData] = useState<FloorplanData>();
  const [heatmapMetricConfig, setHeatmapMetricConfig] = useState<IHeatmapConfig>();

  // Check the toggle state in local storage to keep state on floor change 
  useEffect(() => {
    const view = localStorage.getItem(FLOORPLAN_HEATMAP_VIEW);
    const newState = view === View.HeatmapView;
    setHeatmapView(prevState => prevState === newState ? prevState : newState);
  }, []);

  // Get Heatmap metric config and store in state as it is used by multiple child components
  useEffect(() => {
    if (config?.metricType) {
      setHeatmapMetricConfig(getHeatmapMetricConfig(config.metricType, toLocale, getUnit));
    }
  }, [config, getUnit, toLocale]);

  // Fetch data to get space details only or with a metric if a DeviceModel and a MetricType are selected
  useEffect(() => {
    const fetchData = async () => {
      if (!floor) {
        return;
      }

      if (!heatmapView) {
        const spaces = await execute({
          query: new HeatmapQuery([floor.id])
        });

        if (spaces) {
          setData({ spaces, config: {} });
        } else {
          setData(undefined);
        }
      }

      if (heatmapView && config.model && config.metricType) {
        const spaces = await execute({
          query: new HeatmapQuery([floor.id], config.model.deviceModel, config.metricType)
        });

        if (spaces) {
          setData({ spaces, config });
        } else {
          setData(undefined);
        }
      }
    };

    fetchData();
  }, [floor, heatmapView, config, execute]);

  const viewOptions: ToggleOption<View>[] = useMemo(() => [
    {
      label: t('Floorplan.SpaceView', { ns: 'molecules' }),
      value: View.SpaceView
    },
    {
      label: t('Floorplan.HeatmapView', { ns: 'molecules' }),
      value: View.HeatmapView
    }
  ], [t]);

  const handleHeatmapViewToggle = (view: View) => {
    // only use local storage if user has consented
    if (getCookieConsent()?.functional) {
      localStorage.setItem(FLOORPLAN_HEATMAP_VIEW, view);
    }
    setHeatmapView(view === View.HeatmapView);
  };

  const handleDeviceModelChange = useCallback((model: DeviceModelWithMetricTypes) => {
    setConfig({ model });
  }, []);

  const handleMetricChange = useCallback((metricType: MetricType) => {
    setConfig(prev => ({ ...prev, metricType }))
  }, []);

  return (
    <Container>
      <ContentWrapper isFullScreen={isFullScreen}>
        <BackButton
          label={t('BackToFloor', { ns: 'navigation' })}
          url='..'
        />

        <FlexRow style={{ paddingBottom: 20, minWidth: 270 }}>
          <Title
            text={t('FloorPlan', { ns: 'common' })}

          />

          <div style={{ marginLeft: 'auto' }} />

          <FullScreenToggle styles={{ marginRight: 10 }} />

          {isSuperAdmin &&
            <Toggle
              defaultSelected={heatmapView ? viewOptions[1] : viewOptions[0]}
              options={viewOptions}
              onChange={handleHeatmapViewToggle}
              buttonStyle={{
                padding: '4px 15px 3px 15px',
                width: 'auto'
              }}
            />
          }
        </FlexRow>

        <Card noPadding style={{ minWidth: 270 }}>
          <HeaderRow show={heatmapView}>
            <FlexRow style={{ gap: '10px 16px' }}>
              <SelectContainer>
                <Label>{t('DeviceModel', { ns: 'common' })}</Label>
                <DeviceModelSelect
                  building={building}
                  onChange={handleDeviceModelChange}
                  defaultModel={FloorplanDefaultDeviceModel}
                  localStorageKey={FLOORPLAN_DEVICE_MODEL}
                />
              </SelectContainer>

              {config.model &&
                <SelectContainer>
                  <Label>{t('Metric', { ns: 'common' })}</Label>
                  <HeatmapMetricSelect
                    building={building}
                    deviceModel={config.model}
                    onChange={handleMetricChange}
                    defaultMetricType={FloorplanDefaultMetricType}
                    localStorageKey={FLOORPLAN_METRIC_TYPE}
                  />
                </SelectContainer>
              }
            </FlexRow>

            <FlexRow style={{ gap: '10px 26px' }}>
              <HeatmapLegend
                placement={heatmapMetricConfig ? TooltipPlacement.Bottom : TooltipPlacement.BottomRight}
              />

              <HeatmapScale
                config={heatmapMetricConfig}
                width={230}
                styles={{ border: 'none' }}
              />
            </FlexRow>
          </HeaderRow>

          <FloorplanContainer
            floor={floor}
            data={data}
            loading={loading}
          />
        </Card>
      </ContentWrapper>

      <FloorplanSidepanel
        spaces={data?.spaces}
      />
    </Container>
  );
};

export default Floorplan;

const Container = styled.div`
  display: flex;
`;

const ContentWrapper = styled(PaddedContainer) <{ isFullScreen: boolean }>`
  flex-grow: 1;
  height: ${p => `calc(100vh - ${TopBarHeight}px - ${p.isFullScreen ? 0 : BreadcrumbHeight}px)`};
  overflow: auto;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const HeaderRow = styled(FlexRow) <{ show: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: none;
  height: 0;
  overflow: hidden;
  padding: 0;

  ${p => p.show && css`
    border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
    height: auto;
    overflow: visible;
    padding: 16px 16px;
  `}
`;

const SelectContainer = styled.div`
  width: 230px;
`;