/* eslint-disable  @typescript-eslint/no-explicit-any */

import { MetricType } from '@api/enums/MetricType';
import { Metric } from '@api/models/Metric';

/**
 * Generic object type
 */
export type GenericObject = { [key: string]: any };

/**
 * Only compares properties present in the FormValues object (e.g. ignores 'id' property in origin).
 */
export function valuesDiffer<TValues extends GenericObject, TOrigin extends GenericObject>(values: TValues, origin: TOrigin): boolean {
  let doDiffer = false;

  for (const [key, value] of Object.entries(values)) {

    // Checks for nested objects and compares values within in the same fashion.
    if (typeof value === 'object' && value !== null && Object.prototype.hasOwnProperty.call(origin, key)) {
      doDiffer = valuesDiffer(value, origin[key as keyof GenericObject]);
    } else if (Object.prototype.hasOwnProperty.call(origin, key) && value !== origin[key as keyof GenericObject]){
      doDiffer = true;
    }

    if (doDiffer) return true;
  }

  return false;
}

/**
 * Create an object and copy properties in 'data' to the new object
 */
export function createDataObject<T extends object>(type: { new(): T }, data: any) {
  const returnObject = new type();
  Object.assign(returnObject, data);

  return returnObject;
}

/**
 * Creates and returns an object of type T and tries to find a value in the metrics list for each property in T.
 *
 * @param {T} type The type of the object to be created and returned.
 * @param {Metric[]} metrics A list of all the metrics returned from the API.
 * @return {T | null} Returns the object or null if not all metrics are present to create the object.
 */
export const createMetricObject = <T extends object>(type: { new(): T }, metrics: Metric[]): (T | null) => {
  const returnObject: any = new type();

  const requestedMetrics = Object.keys(returnObject).map(x => x as MetricType);

  for (const metricType of requestedMetrics) {
    const metric = metrics.find(m => m.metricType === metricType);
    if (metric) {
      returnObject[metricType.toString()] = metric;
    } else {
      return null;
    }
  }

  return returnObject;
};

/**
 * Creates and returns an object of type T and tries to find a value in the metrics list for each property in T,
 * with the property set to null if no corresponding metric exists in the list of metrics.
 *
 * @param {T} type The type of the object to be created and returned.
 * @param {Metric[]} metrics A list of all the metrics returned from the API.
 */
export const createOptionalMetricsObject = <T extends object>(type: { new(): T }, metrics: Metric[]): (T | null) => {
  if (metrics.length === 0) {
    return null;
  }

  const returnObject: any = new type();

  const requestedMetrics = Object.keys(returnObject).map(x => x as MetricType);

  for (const metricType of requestedMetrics) {
    const metric = metrics.find(m => m.metricType === metricType);
    if (metric) {
      returnObject[metricType.toString()] = metric;
    } else {
      returnObject[metricType.toString()] = null;
    }
  }

  return returnObject;
};