import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICascaderMultiSelectNodeGroup } from '@components/cascader-multi-select/CascaderMultiSelect.types';
import { usePopover } from '@hooks/usePopover';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Dropdown from '@components/core/Dropdown';
import useLocationFilter from '@hooks/useLocationFilter';

type PropTypes<TValue> = {
  label: string;
  group: ICascaderMultiSelectNodeGroup<TValue>;
  onChange: (selected?: TValue[]) => void;
  resetPulse?: number;
  position?: 'left' | 'right';
}

export const LocationFilter = <TValue,>({ label, group, onChange, resetPulse, position = 'left' }: PropTypes<TValue>) => {
  const { nodeGroup, selected, handleSelect } = useLocationFilter({ group, resetPulse });
  const { ref, visible, toggle } = usePopover({});

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  return (
    <Container ref={ref}>
      <FlexRow isActive={visible} onClick={toggle}>
        <Label isActive={visible}>
          {label}
        </Label>
        {selected &&
          <Count>
            {selected.length}
          </Count>
        }

        <ChevronIcon
          $isActive={visible}
          icon={solid('chevron-down')}
        />
      </FlexRow>

      <Dropdown
        open={visible}
        group={nodeGroup}
        onChange={handleSelect}
        width={240}
        position={position}
      />
    </Container>
  );
}


const Container = styled.div`
  position: relative;
`;

const FlexRow = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 4px;

  &:hover {
    background-color: ${p => p.theme.primary.hover};
  }

  ${p => p.isActive && css`
    background-color: ${p => p.theme.primary.hover};
    color: ${p => p.theme.primary.main} !important;
  `}
`;

const Label = styled.div<{ isActive: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};

  ${p => p.isActive && css`
    color: ${p => p.theme.primary.main} !important;
  `}
`;

const Count = styled.div`
  font-size: 12px;
  font-weight: 500;
  min-width: 18px;
  padding: 0px 5px;
  border-radius: 8px;
  color: ${p => p.theme.text.contrast};
  background-color: ${p => p.theme.primary.main};
  text-align: center;
`;

const ChevronIcon = styled(FontAwesomeIcon) <{ $isActive: boolean }>`
  font-size: 12px;
  margin-left: 5px;
  color: ${p => p.theme.text.secondary};

  ${p => p.$isActive && css`
    color: ${p => p.theme.primary.main} !important;
  `}
`;