import { AlertRule } from '@api/models/AlertRule';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class AlertRuleUpdateCommand extends PatchQuery<AlertRule> {
  rule: AlertRule;

  constructor(id: number, rule: AlertRule) {
    super(id);
    this.rule = rule;
  }

  public targetUrl(): string {
    return '/alerting/UpdateRule';
  }
}
