import styled, { css } from 'styled-components';

type PaddedContainerProps = {
  /**
   * Increase the padding of the container to make the content more centered
   */
  largePadding?: boolean;
  /**
   * Center the content
   */
  centered?: boolean;
}

export const PaddedContainer = styled.div<PaddedContainerProps>`
  padding: 30px 35px;

  ${p => p.centered && css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  @media (max-width: 600px) {
    min-width: 600px;
  }

  @media print {
    padding: 0;
  }
`;
