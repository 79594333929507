import { OperatorPostFormDataQuery } from '@api/queries/resident-app/common/OperatorPostFormDataQuery';
import { UsersBulkCreateDto } from '@api/models/UsersBulkCreateDto';

export default class UsersResidentAppBulkCreateCommand extends OperatorPostFormDataQuery<UsersBulkCreateDto> {
  file: File;
  siteId: number;

  constructor(file: File, siteId: number) {
    const formData = new FormData();
    formData.append('siteId', siteId.toString());
    formData.append('file', file);
    super(formData);
  }

  public targetUrl(): string {
    return '/Users/BulkCreate';
  }
}
