import { User } from '@api/models/User';
import { PatchQuery } from '@api/queries/common/PatchQuery';

export default class UserProfilePasswordUpdateCommand extends PatchQuery<User> {
  password: string;

  constructor(id: number, password: string) {
    super(id);
    this.password = password;
  }

  public targetUrl(): string {
    return '/userprofile/updatePassword';
  }
}
