import ExportCsvDto from '@api/models/ExportCsvDto';
import { MetricType } from '@api/enums/MetricType';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class ExportDynamicReportGroupedByMetricTypeGetQuery extends PostQuery<ExportCsvDto> {
  spaceIds: number[];
  metricTypes: MetricType[];
  fromDateTime: string;
  toDateTime: string;

  constructor(spaceIds: number[], metricTypes: MetricType[], fromDateTime: string, toDateTime: string) {
    super();
    this.spaceIds = spaceIds;
    this.metricTypes = metricTypes;
    this.fromDateTime = fromDateTime;
    this.toDateTime = toDateTime;
  }

  public targetUrl(): string {
    return '/exports/CsvDynamicReportGroupedByMetricType';
  }
}
