import { useState } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useApi } from '@hooks/useApi';
import { EsgExplorerConfiguration } from '@api/models/EsgExplorerConfiguration';
import EsgExplorerDataGetQuery from '@api/queries/esg-explorer/EsgExplorerDataGetQuery';
import { round } from '@utils/NumberUtils';
import { Loading } from '@components/core/Loading';
import EsgExplorerDataTable from '@pages/site/esg-explorer/components/DataTable/EsgExplorerDataTable';
import EsgExplorerMenu from '@pages/site/esg-explorer/components/EsgExplorerMenu';
import { useSiteContext } from '@pages/site/SiteProvider';
import { EsgExplorerMetricsDto } from '@api/models/EsgExplorerMetricsDto';
import EsgExplorerTemplateDto from '@api/models/EsgExplorerTemplateDto';
import NoData from '@components/core/NoData';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FallbackGasCalorificValue } from '@src/constants/FallbackGasCalorificValue';

const SitePage_EsgExplorer = () => {
  const { t } = useTranslation(['organisms']);
  const { tenant } = useTenantContext();
  const { site, buildings, siteMetadata } = useSiteContext();
  const { execute, loading, controller } = useApi();
  const [data, setData] = useState<EsgExplorerMetricsDto>();
  const [queryStartTime, setQueryStartTime] = useState<Date>();
  const [currentTime, setCurrentTime] = useState<Date>();
  const [fileName, setFileName] = useState<string>('');
  const [currentConfig, setCurrentConfig] = useState<EsgExplorerConfiguration>();

  const generateData = async (template: EsgExplorerTemplateDto) => {
    setQueryStartTime(new Date());
    const interval = setInterval(() => setCurrentTime(new Date()), 1000);

    const data = await execute({
      query: new EsgExplorerDataGetQuery(tenant.tenantId, template.configuration, siteMetadata?.gasCalorificValue ?? FallbackGasCalorificValue)
    });

    if (data) {
      const fileNamePrefix = !template.name ? `ESG Explorer_${site.name}` : template.name;

      setData(data);
      setFileName(template.configuration.timeRange ? `${fileNamePrefix}_${format(new Date(template.configuration.timeRange.from), 'dd.MM.yyy')}-${format(new Date(template.configuration.timeRange.to), 'dd.MM.yyy')}.csv` : '');
      setCurrentConfig(template.configuration);
    } else {
      setData(undefined);
      setFileName('');
      setCurrentConfig(undefined);
    }

    clearInterval(interval);
  };

  const renderLoadingTime = (currentTime?: Date, queryStartTime?: Date) => {
    if (!currentTime || !queryStartTime) {
      return null;
    }

    const seconds = round(((currentTime).getTime() - (queryStartTime).getTime()) / 1000);

    return (
      <LoadingTextSmall show={seconds > 10}>
        <Trans i18nKey='organisms:Building.ESGExplorerHelpText' values={{ seconds: seconds }}>
          <br />
          <CancelText onClick={() => { controller.abort() }}></CancelText><br />
        </Trans>
      </LoadingTextSmall>
    );
  };

  return (
    <Container>
      <EsgExplorerMenu
        site={site}
        buildings={buildings}
        onGenerate={generateData}
        loading={loading}
      />

      {loading &&
        <LoadingOverlay>
          <Loading />
          <LoadingText>
            {t('Building.LoadingData', { ns: 'organisms' })}
          </LoadingText>
          {renderLoadingTime(currentTime, queryStartTime)}
        </LoadingOverlay>
      }

      {data && data.spaces.length > 0 &&
        <EsgExplorerDataTable
          data={data}
          config={currentConfig}
          fileName={fileName}
        />
      }

      {!data &&
        <NoData
          large
          label={t('NoReportGenerated', { ns: 'status' })}
          subLabel={t('NoReportGeneratedHelpText', { ns: 'status' })}
          styles={{ margin: '100px auto' }}
          icon={solid('chart-simple')}
        />
      }

      {data?.spaces.length === 0 &&
        <NoData
          large
          label={t('NoDataFound', { ns: 'status' })}
          subLabel={t('AdjustConfigAndRerun', { ns: 'status' })}
          styles={{ margin: '100px auto' }}
          icon={regular('table')}
        />
      }
    </Container>
  )
}

export default SitePage_EsgExplorer;

const Container = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  display: flex;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => transparentize(0.3, p.theme.palette.backgrounds.surface)};
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled.div`
  color: ${p => p.theme.palette.text.medium};
  margin-top: -20px;
  text-align: center;
`;

const LoadingTextSmall = styled.div<{ show: boolean }>`
  opacity: ${p => p.show ? 1 : 0};
  transition: opacity 300ms ease;
  background-color: ${p => transparentize(0, p.theme.palette.backgrounds.surface)};
  box-shadow: 0px 1px 8px ${p => p.theme.palette.shadows.medium};
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 15px;

  font-size: 14px;
  color: ${p => p.theme.palette.text.fair};
  text-align: center;
`;

const CancelText = styled.span`
  color: ${p => p.theme.palette.primary};
  font-weight: 500;
  cursor: pointer;
`;