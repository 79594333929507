import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Input } from '@components/Form';
import IconTooltip from '@components/core/IconTooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import { env } from '@src/env/env';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AlertRuleLabel, HelperMessage } from '../AlertRuleConfiguration';

export enum Timing {
  Immediately = 'Immediately',
  AfterADelay = 'AfterADelay',
}

interface IDelayConfiguration {
  delay: number,
  onChange: (delay: number) => void;
}

const DelayConfiguration = ({ delay, onChange }: IDelayConfiguration) => {
  const { t } = useTranslation(['settingsAlerting']);
  const [helperMessage, setHelperMessage] = useState<string>();

  const handleDelayChange = (key: string, value: string, maxValue: number, forumla: (value: number) => number) => {
    let numericValue = value !== '' ? parseFloat(value) : 0;

    // Show message if the value is greater than the max value
    if (numericValue > maxValue) {
      setHelperMessage(`${t('DelayConfiguration.Maximum', { ns: 'settingsAlerting' })} ${maxValue} ${key}.`);
    } else {
      setHelperMessage('');
    }

    // Set to zero if negative, set to maxValue if greater than maxValue
    if (numericValue < 0) {
      numericValue = 0;
    }
    else {
      numericValue = Math.min(numericValue, maxValue);
    }

    // Calcualte the new delay in seconds
    const newDelay = forumla(numericValue);

    onChange(newDelay);
  }

  const renderTimeFields = (delay: number) => {
    const dayInSeconds = 86400;
    const hourInSeconds = 3600;
    const minuteInSeconds = 60;

    let totalSeconds = delay;

    const days = Math.floor(totalSeconds / dayInSeconds);
    totalSeconds %= dayInSeconds;
    const hours = Math.floor(totalSeconds / hourInSeconds);
    totalSeconds %= hourInSeconds;
    const minutes = Math.floor(totalSeconds / minuteInSeconds);


    return (
      <FlexRow>
        <div style={{ minWidth: '80px' }}>
          <AlertRuleLabel>{t('DelayConfiguration.Days', { ns: 'settingsAlerting' })}</AlertRuleLabel>
          <Input type="number" min={0} max={env.alertDelay.maxDelayInDays}
            value={days}
            onInput={(e) => handleDelayChange('days', e.currentTarget.value, parseInt(env.alertDelay.maxDelayInDays), (value: number) => delay - days * dayInSeconds + value * dayInSeconds)}
          />
        </div>
        <div style={{ minWidth: '80px' }}>
          <AlertRuleLabel>{t('DelayConfiguration.Hours', { ns: 'settingsAlerting' })}</AlertRuleLabel>
          <Input type="number" min={0} max={23}
            value={hours}
            onInput={(e) => handleDelayChange('hours', e.currentTarget.value, 23, (value: number) => delay - hours * hourInSeconds + value * hourInSeconds)}
          />
        </div>
        <div style={{ minWidth: '80px' }}>
          <AlertRuleLabel>{t('DelayConfiguration.Minutes', { ns: 'settingsAlerting' })}</AlertRuleLabel>
          <Input type="number" min={0} max={59}
            value={minutes}
            onInput={(e) => handleDelayChange('minutes', e.currentTarget.value, 59, (value: number) => delay - minutes * minuteInSeconds + value * minuteInSeconds)}
          />
        </div>
      </FlexRow>
    )
  };

  return (
    <Wrapper>
      <div style={{ display: 'flex' }}>
        <Title>
          {t('DelayConfiguration.Duration', { ns: 'settingsAlerting' })}
        </Title>
        <IconTooltip
          icon={regular('question-circle')}
          tooltip={{
            content: <span>{t('DelayConfiguration.Example', { ns: 'settingsAlerting' })}</span>,
            placement: TooltipPlacement.Right,
            hideTriangle: true
          }}
          tooltipStyles={{ width: '525px' }}
          wrapperStyles={{ marginLeft: '5px' }}
        />
      </div>

      {renderTimeFields(delay)}

      <HelperMessage>
        {helperMessage}
      </HelperMessage>

      <HelperMessage>
        {t('DelayConfiguration.TriggerHelperMessage', { ns: 'settingsAlerting' })}
      </HelperMessage>
    </Wrapper>
  );
};

export default DelayConfiguration;

const Wrapper = styled.div`
  margin-top: 24px;
  padding-top: 12px;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  > div {
    flex-grow: 1;
    min-width: 160px;
    max-width: 33%;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  color: ${p => p.theme.palette.text.fair};
`;