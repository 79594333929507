import styled from 'styled-components';
import MetricPerformance from './MetricPerformance';
import { SpaceType_DisplayString } from '@api/enums/SpaceType_DisplayString';
import { ReportSpaceTypePerformanceDto, ReportSpaceTypePerformanceMetricDto } from '@api/models/ReportSpaceTypePerformanceDto';
import ReportMetricType from '@api/enums/ReportMetricType';

interface ISpaceTypePerformanceWidget {
  data: ReportSpaceTypePerformanceDto;
  inPrintMode: boolean;
  style?: React.CSSProperties;
  isSiteLevel?: boolean;
}

const SpacePerformance_Display = ( performanceMetrics: ReportSpaceTypePerformanceMetricDto[], inPrintMode: boolean, isSiteLevel?: boolean ) => {
  return (
    <>
      {performanceMetrics.map((metric, i) => 
        // Hide light metric on site level reports, will also hide the comfort score (or other data not in the enum)
        (((isSiteLevel && metric.metricType !== ReportMetricType.Light && Object.values(ReportMetricType).includes(metric.metricType as ReportMetricType)) || 
        (!isSiteLevel && Object.values(ReportMetricType).includes(metric.metricType as ReportMetricType)))
          && <MetricPerformance
            key={i}
            metric={metric}
            inPrintMode={inPrintMode}
          />
        )
      )}
    </>
  )
};

const SpaceTypePerformanceWidget = ({ data, inPrintMode, style, isSiteLevel }: ISpaceTypePerformanceWidget) => {
  return (
    <Card style={style}>
      <Title>
        {SpaceType_DisplayString(data.spaceType)} ({data.numberOfSpaces})
      </Title>
      <Wrapper>
        {SpacePerformance_Display(data.performanceMetrics, inPrintMode, isSiteLevel)}
      </Wrapper>
    </Card>
  );
};

export default SpaceTypePerformanceWidget;

const Card = styled.div`
  width: 100%;
  height: max-content;
  
  /*
  * Place two divs next to each other on larger screens.
  * Subtract 35px to account for 35px gap between the two divs.
  */
  @media (min-width: 1400px) {
    flex: 0 0 calc((100% - 35px) / 2);
    max-width: calc((100% - 35px) / 2);
  }
  
  padding: 20px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 10px;
  box-shadow: 0px 3px 8px ${p => p.theme.palette.esgReport.cardShadow};
  display: flex;
  flex-direction: column;
  
  @media print {
    float: left;
    width: calc((100% - 20px) / 2);
    margin-bottom: 20px;
    
    &:nth-child(odd) {
      margin-right: 10px;
    }
    
    &:nth-child(even) {
      margin-left: 10px;
    }
    
    padding: 10px;
    page-break-inside: avoid;
    box-shadow: 0 0 4px 1px ${p => p.theme.palette.esgReport.cardShadow}, 0 0 1px 0px ${p => p.theme.palette.esgReport.cardShadow};
    border-radius: 5px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;

  @media print {
    font-size: 9pt;
    padding-bottom: 10px;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media print {
    gap: 5px;
  }
`;