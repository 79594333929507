import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import ConsumptionWidget from './UtilityWidget';
import { ConsumptionChartView } from './chart/ChartViewToggle';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { Select } from '@components/select/Select';
import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import CSVExport from './UtilityWidgetExport';
import { TUtilityTab } from './SiteUtilitiesWidget';

type UtilityTabsProps = {
  utilities: TUtilityTab[];
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const UtilityTabs = ({ utilities, conversions, onSetConversions }: UtilityTabsProps) => {
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const [exportData, setExportData] = useState<{ csv: string, fileName: string }>();
  const [selected, setSelected] = useState<number>(0);
  const mediumScreen = useMediaQuery('(max-width: 900px)');
  const [view, setView] = useState<ConsumptionChartView>(ConsumptionChartView.Periodical);

  const handleCsvGenerated = useCallback((csv: string, fileName: string) => {
    setExportData({ csv, fileName });
  }, []);

  const handleTabChange = useCallback((index: number, meterType: EnergyMeterType) => {
    trackAction(`${meterType.toLowerCase()}_summary`, 'performance_utilities');
    setSelected(index);
  }, [trackAction]);

  if (utilities.length === 0) {
    return null;
  }

  const tabSelectOptions = utilities.map((utility, i) => ({
    meterType: utility.meterType,
    label: t(`SiteOverview.${utility.metricType}`, { ns: 'molecules' }),
    value: i
  }));

  return (
    <>
      <Tabs>
        {mediumScreen
          ?
          <StyledSelect>
            <Select
              options={tabSelectOptions}
              value={tabSelectOptions.find(x => x.value === selected)}
              onChange={selected => selected && handleTabChange(selected.value, selected.meterType)}
            />
          </StyledSelect>
          :
          <>
            {utilities.map((utility, i) => (
              <Tab
                key={utility.metricType}
                ref={element => tabRefs.current[i] = element}
                selected={selected === i}
                onClick={() => handleTabChange(i, utility.meterType)}
              >
                {utility &&
                  <Icon icon={utility.icon.defintion} $width={utility.icon.width} />
                }
                <Label>{t(`SiteOverview.${utility.metricType}`, { ns: 'molecules' })}</Label>
              </Tab>
            ))}
          </>
        }

        <CSVExport
          fileNameSuffix={exportData?.fileName}
          csv={exportData?.csv} />
      </Tabs>

      {utilities[selected] &&
        <ConsumptionWidget
          key={utilities[selected].metricType}
          utility={utilities[selected]}
          view={view}
          onViewChange={setView}
          conversions={conversions}
          onSetConversions={onSetConversions}
          onCsvGenerated={handleCsvGenerated}
        />
      }
    </>
  );
};

export default UtilityTabs;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

const Icon = styled(FontAwesomeIcon) <{ $width?: number }>`
  width: ${p => p.$width ?? 17}px;
  height: 17px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  user-select: none;
`;

const Tab = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 11px;
  transition: all 150ms ease;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${p => p.theme.primary.background};

    ${Label} {
      color: ${p => p.theme.primary.main};
    }

    ${Icon} {
      color: ${p => p.theme.primary.main};
    }
  }

  ${p => p.selected && css`
    background-color: ${p => p.theme.primary.background};

    ${Label} {
      color: ${p => p.theme.primary.main};
    }

    ${Icon} {
      color: ${p => p.theme.primary.main};
    }
  `}
`;

const StyledSelect = styled.div`
  width: 150px;
`;