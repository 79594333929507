import { TenantFeature } from '@api/models/TenantFeature';
import { PostQuery } from '@api/queries/common/PostQuery';

export class TenantFeatureCreateCommand extends PostQuery<TenantFeature> {
  tenantId: string;
  featureId: number;

  constructor(tenantId: string, featureId: number) {
    super();
    this.tenantId = tenantId;
    this.featureId = featureId;
  }

  public targetUrl(): string {
    return '/features/CreateTenantFeature';
  }
}
