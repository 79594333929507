import EsgMetricType, { ScalePoint, getEsgMetricColorScales } from '@api/enums/EsgMetricType';
import MonoSpace from '@components/core/MonoSpace';
import styled from 'styled-components';
import { findLast } from 'lodash';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';

interface IRowValue {
  metricType: EsgMetricType;
  value: number | null;
  maxValues?: { metricType: EsgMetricType, maxValue: number, minValue: number }[];
  showConditionalFormatting: boolean;
}

const RowValue = ({ metricType, value, maxValues, showConditionalFormatting }: IRowValue) => {
  const { toLocale } = useLocalisation();
  const normalMetricType = EsgMetricType.toMetricType(metricType);

  if (value === null) {
    return null;
  }

  const maxValue = maxValues?.find(x => x.metricType === metricType)?.maxValue;
  const width = maxValue ? value / maxValue * 100 : 0;
  const smallWidth = Math.abs(width) > 100 ? 100 : Math.abs(width);

  let color = '#fff';
  const scalePoints = getEsgMetricColorScales(metricType);

  if (scalePoints && scalePoints.length > 0) {
    const initialColor = scalePoints[0].color;

    color = findLast(scalePoints, (scalepoint: ScalePoint) => value >= scalepoint.threshold)?.color ?? initialColor;
  }

  return (
    <Field style={{ textAlign: 'right' }}>
      <MonoSpace>
        {toLocale(normalMetricType, value, { round: 2 })}
      </MonoSpace>

      {width > 0 &&
        <Bar hide={!showConditionalFormatting}>
          <ColorIndicator
            width={showConditionalFormatting ? width : 0}
            color={color}
          />
        </Bar>
      }

      {width < 0 &&
        <Bar hide={!showConditionalFormatting}>
          <ColorIndicator
            width={showConditionalFormatting ? smallWidth : 0}
            color={color}
          />
          <ZeroLine
            leftPos={showConditionalFormatting ? smallWidth : 0}
          />
        </Bar>
      }
    </Field>
  );
}

export default RowValue;

const Bar = styled.div<{ hide: boolean }>`
  width: ${p => p.hide ? '0px' : '100%'};
  height: 3px;
  background-color: ${p => p.theme.palette.borders.medium};
  border-radius: 5px;
  position: absolute;
  bottom: -4px;
  transition: width 300ms ease;
`;

const ColorIndicator = styled.div.attrs<{ width: number, color: string }>(p => ({
  style: {
    width: `${p.width}%`,
    backgroundColor: p.color,
  }
})) <{ width: number, color: string }>`
  height: 3px;
  border-radius: 5px;
  transition: width 300ms ease;
`;

const ZeroLine = styled.div.attrs<{ leftPos: number }>(p => ({
  style: {
    left: `${p.leftPos}%`
  }
})) <{ leftPos: number }>`
  position: absolute;
  bottom: -2px;
  height: 8px;
  border-left: 2px solid ${p => p.theme.palette.text.fair};
`;

const Field = styled.div`
  position: relative;
  margin: 12px 10px;
  font-weight: 500;
`;