import { IHeatmapConfig } from '@src/component-configuration-models/heatmap/HeatmapConfig';
import styled from 'styled-components';

type PropTypes = {
  config?: IHeatmapConfig;
  width: number;
  styles?: React.CSSProperties;
}

const HeatmapScale = ({ config, width, styles }: PropTypes) => {
  if (!config) {
    return null;
  }

  const scaleGradientColors: string[] = [];
  const scaleLabels: string[] = [];

  const gradientStep = 100 / config.scale.points.length;
  config.scale.points.filter(p => !p.hideFromScale).forEach((point, i) => {
    scaleLabels.push(point.label);

    // When the scale is a category scale, render a gradient without smooth transitions
    if (config.scale.categoryScale) {
      const previousColor = scaleGradientColors[i - 1];

      if (previousColor) {
        // Add gradient point ending previous color and one to start next color
        scaleGradientColors.push(`${previousColor.split(' ')[0]} ${i * gradientStep}%, ${point.color} ${gradientStep}%`);
      } else {
        // Add first (0%) gradient point
        scaleGradientColors.push(`${point.color} 0%`);
      }

      // Add last (100%) gradient point
      if (config.scale.points.length === i + 1) {
        scaleGradientColors.push(`${point.color} 100%`);
      }

    } else {
      scaleGradientColors.push(point.color);
    }
  });

  return (
    <Wrapper style={styles} width={width}>
      <ScaleLabels categoryScale={config.scale.categoryScale}>
        {scaleLabels.map((label) => (
          <div key={label}>
            {label}
          </div>
        ))}
      </ScaleLabels>
      <Scale gradientColors={scaleGradientColors.join()} />
    </Wrapper>
  );
};

export default HeatmapScale;

const Wrapper = styled.div<{ width: number }>`
  width: ${p => p.width}px;
  border-left: 1px solid ${p => p.theme.palette.borders.weak};
`;

const ScaleLabels = styled.div<{ categoryScale?: boolean }>`
  display: flex;
  justify-content: ${p => p.categoryScale ? 'space-around' : 'space-between'};

  font-size: 12px;
  font-weight: 500;
`;

const Scale = styled.div<{ gradientColors: string }>`
  height: 20px;
  margin-top: 2px;

  border-radius: 3px;
  background: ${p => `linear-gradient(90deg, ${p.gradientColors})`};
`;