import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { darken } from 'polished';
import { useTranslation } from 'react-i18next';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnergyMeterCategory } from '@api/enums/EnergyMeterCategory';
import { EnergyMeterTopologyLevel } from '@api/enums/EnergyMeterTopologyLevel';
import { SpaceType_DisplayString } from '@api/enums/SpaceType_DisplayString';
import { EnergyMeterTopologyDto } from '@api/models/EnergyMeterTopologyDto';
import { useDeviceConfigContext } from '@contexts/DeviceConfigContext/DeviceConfigContext';
import { Button } from '@components/core/Button';
import { isNullOrUndefined } from '@utils/NumberUtils';
import { Label } from '@components/Form';
import { Select } from '@components/select/Select';
import { Modal } from '@components/core/Modal';

interface IMeterMultiSelectModal extends React.ComponentPropsWithoutRef<'div'> {
  isOpen: boolean;
  toggle: () => void;
  selected: EnergyMeterTopologyDto[];
  options: EnergyMeterTopologyDto[];
  parentMeter: EnergyMeterTopologyDto;
  onSelectChange: (selected: EnergyMeterTopologyDto[]) => void;
  showFilters?: boolean;
}

const MeterMultiSelectModal = React.forwardRef<HTMLDivElement, IMeterMultiSelectModal>(({ selected: selectedProp, options, parentMeter, onSelectChange, isOpen, toggle, showFilters }, ref) => {
  const { t } = useTranslation(['settingsAssset']);
  const theme = useTheme();
  const { getDisplayString } = useDeviceConfigContext();
  const [selected, setSelected] = useState<EnergyMeterTopologyDto[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<EnergyMeterTopologyDto[]>([]);
  const unselected = filteredOptions.filter(x => !selected.some(a => a.id === x.id));

  let categoryOptions: { label: string, value: EnergyMeterCategory | undefined }[] = Object.values(EnergyMeterCategory).map(x => ({ label: x.toString(), value: x }));
  categoryOptions = [{ label: t('Buildings.EnergyMeters.MeterMultiSelect.All', { ns: 'settingsAsset' }), value: undefined }, ...categoryOptions];

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    setSelected(selectedProp);
  }, [selectedProp, isOpen]);

  const onSelect = (meter: EnergyMeterTopologyDto) => {
    const clone = [...selected];
    const index = clone.findIndex(x => x.id === meter.id);

    if (index !== -1) {
      clone.splice(index, 1);
    } else {
      clone.push(meter);
    }

    setSelected(clone);
  };

  const handleConfirm = () => {
    onSelectChange(selected);
    toggle();
  };

  const handleFilterChange = (category?: EnergyMeterCategory) => {
    setFilteredOptions(options.filter(x => category ? x.meterCategory === category : true));
  };

  const setAll = () => {
    // If there are any unselected ones set all to selected, otherwise remove all from the selected array.
    if (unselected.length > 0) {
      setSelected([...selected, ...unselected]);
    } else {
      setSelected(selected.filter(x => !filteredOptions.some(a => a.id === x.id)));
    }
  };

  return (
    <Modal
      ref={ref}
      isOpen={isOpen}
      plainModal
      width="600px"
      modalStyles={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
      dialogStyles={{ boxShadow: `0px 2px 12px 1px ${theme.palette.shadows.strong}` }}
    >
      <FlexColumn>
        {showFilters &&
          <FilterRow>
            <div style={{ width: '150px' }}>
              <FilterLabel>
                {t('Buildings.EnergyMeters.MeterMultiSelect.Filters', { ns: 'settingsAsset' })}
              </FilterLabel>

              <Label>
                {t('Buildings.EnergyMeters.MeterMultiSelect.MeterType', { ns: 'settingsAsset' })}
              </Label>
              <Select
                isSearchable={true}
                onChange={x => x && handleFilterChange(x.value)}
                defaultValue={categoryOptions.find(x => !x.value)}
                options={categoryOptions}
              />
            </div>
          </FilterRow>
        }

        <TitleRow>
          <StatusIconWrapper>
            <StatusIcon
              icon={(unselected.length > 0 || filteredOptions.length === 0) ? light('circle') : solid('circle-check')}
              onClick={setAll}
            />
          </StatusIconWrapper>
          <TitleColumn>
            {t('Buildings.EnergyMeters.MeterMultiSelect.Meter', { ns: 'settingsAsset' })}
          </TitleColumn>
          <TitleColumn>
            {t('Buildings.EnergyMeters.MeterMultiSelect.Device', { ns: 'settingsAsset' })}
          </TitleColumn>
          <TitleColumn>
            {t('Buildings.EnergyMeters.MeterMultiSelect.Location', { ns: 'settingsAsset' })}
          </TitleColumn>
          <TitleColumn style={{ width: '120px' }}>
            {t('Buildings.EnergyMeters.MeterMultiSelect.AssignedTo', { ns: 'settingsAsset' })}
          </TitleColumn>
        </TitleRow>

        <ScrollWrapper>
          {filteredOptions.length === 0 &&
            <NoMetersLabel>
              {t('Buildings.EnergyMeters.MeterMultiSelect.NoMeters', { ns: 'settingsAsset' })}
            </NoMetersLabel>
          }

          {filteredOptions.map((meter, i) => {
            const isSelected = selected.some(x => x.id === meter.id);
            const isAssignedToAnotherMeter = !isNullOrUndefined(meter.parentMeterId) && meter.parentMeterId !== parentMeter.id;
            const isSelectedIcon = isSelected ? solid('circle-check') : light('circle');
            const isAssignedToAnotherMeterIcon = isAssignedToAnotherMeter ? regular('circle-check') : isSelectedIcon;

            return (
              <Row
                key={i}
                onClick={() => onSelect(meter)}
              >
                <StatusIconWrapper>
                  <StatusIcon
                    icon={isSelected && isAssignedToAnotherMeter
                      ? solid('arrows-rotate')
                      : isAssignedToAnotherMeterIcon
                    }
                  />
                </StatusIconWrapper>
                <Column>
                  <div>
                    {meter.meterNumber}
                  </div>
                  <div>
                    {meter.meterCategory}
                  </div>
                </Column>
                <Column>
                  <div>
                    {meter.deviceFriendlyName}
                  </div>
                  <div>
                    {getDisplayString(meter.deviceModel)}                  </div>
                </Column>
                <Column>
                  <div>
                    {meter.spaceName}
                  </div>
                  <div>
                    {SpaceType_DisplayString(meter.spaceType)}
                  </div>
                </Column>

                {meter.parentMeter &&
                  <AssignedToCard>
                    <AssignedToCardHeader color={meter.parentMeter.meterTopologyLevel === EnergyMeterTopologyLevel.Main ? theme.palette.red : theme.palette.orange} />
                    <AssignedToCardLabelWrapper>
                      <AssignedToCardLabel style={{ fontWeight: 500 }}>
                        {meter.parentMeter.meterNumber}
                      </AssignedToCardLabel>
                      <AssignedToCardLabel>
                        {meter.parentMeter.deviceFriendlyName}
                      </AssignedToCardLabel>
                      <AssignedToCardLabel color={theme.palette.text.weak}>
                        {meter.parentMeter.spaceName}
                      </AssignedToCardLabel>
                    </AssignedToCardLabelWrapper>
                  </AssignedToCard>
                }
              </Row>
            );
          })}
        </ScrollWrapper>
      </FlexColumn>

      <Footer>
        <FlexColumn style={{ gap: '6px' }}>
          <FlexRow>
            <StatusIcon icon={solid('circle-check')} $small $normalCursor />
            {t('Buildings.EnergyMeters.MeterMultiSelect.AssignedToThis', { ns: 'settingsAsset' })}
          </FlexRow>
          <FlexRow>
            <StatusIcon icon={regular('circle-check')} $small $normalCursor />
            {t('Buildings.EnergyMeters.MeterMultiSelect.AssignedToAnother', { ns: 'settingsAsset' })}
          </FlexRow>
          <FlexRow>
            <StatusIcon icon={light('circle')} $small $normalCursor />
            {t('Buildings.EnergyMeters.MeterMultiSelect.Unassigned', { ns: 'settingsAsset' })}
          </FlexRow>
        </FlexColumn>

        <div style={{ marginLeft: 'auto' }} />
        <Button
          tertiary
          label={t('Cancel', { ns: 'common' })}
          color={theme.palette.red}
          onClick={toggle}
        />
        <Button
          label={t('Buildings.EnergyMeters.MeterMultiSelect.Confirm', { ns: 'settingsAsset' })}
          onClick={handleConfirm}
        />
      </Footer>
    </Modal>
  );
});

export default MeterMultiSelectModal;

const Footer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 15px;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};
  box-shadow: 0px -5px 4px -4px ${p => p.theme.palette.shadows.medium};
  z-index: 2;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  line-height: 12px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
`;

const FilterLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  box-shadow: 0px 5px 4px -4px ${p => p.theme.palette.shadows.medium};
  z-index: 2;
`;

const ScrollWrapper = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const NoMetersLabel = styled.div`
  width: 160px;
  font-size: 14px;
  line-height: 13px;
  color: ${p => p.theme.palette.text.weak};
  margin: 50px auto;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 15px;
  cursor: pointer;

  &:nth-child(odd) {
    background-color: ${(p) => p.theme.palette.tables.evenRow};
  }

  &:nth-child(even) {
    background-color: ${(p) => p.theme.palette.tables.unevenRow};
  }

  &:hover {
    background-color: ${p => darken(0.02, p.theme.palette.tables.backgroundHighlight)};
  }
`;

const Column = styled.div`
  width: 135px;

  font-size: 14px;
  line-height: 14px;

  padding-right: 10px;

  > div {
    width: 115px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div:first-child {
    font-size: 14px;
    line-height: 18px;
    color: ${p => p.theme.palette.text.fair};
  }

  > div:nth-child(2) {
    font-size: 14px;
    line-height: 18px;
    color: ${p => p.theme.palette.text.weak};
  }
`;

const TitleColumn = styled.div`
  width: 135px;
  padding-right: 10px;

  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
`;

const StatusIconWrapper = styled.div`
  width: 35px;
  display: flex;
  align-items: center;
`;

const StatusIcon = styled(FontAwesomeIcon) <{ $small?: boolean, $normalCursor?: boolean }>`
  color: ${p => p.theme.palette.primary};
  font-size: ${p => p.$small ? '12px' : '18px'};
  cursor: ${p => p.$normalCursor ? 'unset' : 'pointer'};
`;

const AssignedToCard = styled.div`
  display: flex;

  width: 115px;
  border-radius: 3px;
  font-weight: 400;

  color: ${p => p.theme.palette.text.weak};
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 0 8px 1px ${p => p.theme.palette.shadows.medium};
`;

const AssignedToCardHeader = styled.div<{ color: string }>`
  width: 6px;
  height: 50px;
  background-color: ${p => p.color};
  border-radius: 3px 0 0 3px;
`;

const AssignedToCardLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px 6px 3px 6px;
`;

const AssignedToCardLabel = styled.div<{ color?: string }>`
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  color: ${p => p.color ?? p.theme.palette.text.medium};

  width: 95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;