import { SiteThresholdDto } from '@api/models/SiteThresholdDto';
import { OperatorPatchQuery } from '@api/queries/resident-app/common/OperatorPatchQuery';

export default class SiteThresholdsResidentAppUpdateCommand extends OperatorPatchQuery<SiteThresholdDto[]> {
  siteId: number;
  siteThresholds?: SiteThresholdDto[];

  constructor(siteId: number, siteThresholds?: SiteThresholdDto[]) {
    super();
    this.siteId = siteId;
    this.siteThresholds = siteThresholds;
  }

  public targetUrl(): string {
    return '/SiteThresholds/Update';
  }
}
