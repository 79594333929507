import styled, { css } from 'styled-components';
import { Select } from '@components/select/Select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@components/core/Title';
import { Link } from 'react-router-dom';
import { OptionProps, components } from 'react-select';
import { useSiteContext } from '@pages/site/SiteProvider';
import { BuildingWithFloors } from '@api/models/BuildingWithFloors';
import NoData from '@components/core/NoData';
import { BuildingHierarchyLevel, useBuildingContext } from '@pages/site/buildings/BuildingProvider';
import { useLayoutContext } from '@contexts/LayoutContext';
import { CommonSidebar } from '@components/core/CommonSidebar';
import { Button } from '@components/core/Button';

type BuildingOption = {
  label: string;
  value: BuildingWithFloors;
  siteId: number;
};

const Option = (props: OptionProps<BuildingOption>) => {
  const { value: building, siteId } = props.data;
  const basePath = `/site/${siteId}/building/${building.id}`;
  const path = building.floors.length > 0 ? `${basePath}/floor/${building.floors[0].id}` : basePath;

  return (
    <Link to={path}>
      <components.Option {...props} >
        {building.name}
      </components.Option>
    </Link>
  );
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();
  const { site, buildings } = useSiteContext();
  const { building, floor, hierarchyLevel } = useBuildingContext();
  const buildingOptions = useMemo(() => buildings.map(x => ({ label: x.name, value: x, siteId: site.id })), [buildings, site]);

  return (
    <CommonSidebar isHidden={isFullScreen || hierarchyLevel !== BuildingHierarchyLevel.Floor}>
      <Title
        text={t('Buildings', { ns: 'assets' })}
        size='sm'
      />

      <Label>
        {t('Building', { ns: 'assets' })}
      </Label>

      <Select
        options={buildingOptions}
        value={buildingOptions.find(x => x.value.id === building.id)}
        components={{ Option }}
      />

      <Link to={`/site/${site.id}/building/${building.id}/settings`} style={{ marginTop: 12 }}>
        <Button
          secondary
          label={t('ViewBuildingInfo', { ns: 'common' })}
          style={{ width: '100%', padding: '6px 10px' }}
        />
      </Link>

      <Label>
        {t('Floor', { ns: 'assets' })}
      </Label>

      {building.floors.length === 0 &&
        <NoData
          label={t('NoFloorsFound', { ns: 'status' })}
          styles={{ marginTop: 40 }}
        />
      }

      <FloorList>
        {building.floors.map(x => (
          <FloorLink
            key={x.id}
            to={`/site/${site.id}/building/${building.id}/floor/${x.id}`}
            $active={x.id === floor?.id}
          >
            {x.name}
          </FloorLink>
        ))}
      </FloorList>
    </CommonSidebar>
  );
};

export default Sidebar;

const Label = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
  padding: 20px 0 8px 0;
`;

const FloorList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: auto;
  margin: 0 -16px -16px;
  padding: 0 16px;
`;

const FloorLink = styled(Link) <{ $active: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  border-radius: 8px;
  padding: 9px 12px;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};
  }

  ${p => p.$active && css`
    font-weight: 500;
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};
  `}
`;