import { FeedbackCreateCommand } from '@api/queries/feedback/FeedbackCreateCommand';
import { Button } from '@components/core/Button';
import { ErrorMessage, TextArea } from '@components/Form';
import { useApi } from '@hooks/useApi';
import { isEmpty } from 'lodash';
import { RefObject, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Modal } from './core/Modal';
import { useUserContext } from '@contexts/UserContext/UserContext';
import { Title } from '@components/core/Title';

type FormValues = {
  feedbackComments: string;
};

type PropTypes = {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  toggle: () => void;
}

const FeedbackModal = ({ modalRef, isOpen, toggle }: PropTypes) => {
  const { t } = useTranslation(['commonApp']);
  const theme = useTheme();
  const { user } = useUserContext();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm<FormValues>();
  const { execute } = useApi();

  const onClose = () => {
    toggle();
    reset();
  };

  const submitFeedback: SubmitHandler<FormValues> = async (data) => {
    setSavingInProgress(true);
    await execute({
      query: new FeedbackCreateCommand({ email: user.email, feedbackComments: data.feedbackComments }),
      successMessage: t('TopBar.Feedback.FeebackReceivedMessage', { ns: 'commonApp' })
    });
    onClose();
    setSavingInProgress(false);
  };

  return (
    <Modal
      ref={modalRef}
      hide={toggle}
      isOpen={isOpen}
      plainModal
      width="max-content"
    >
      <Wrapper>
        <Title
          text={t('TopBar.Feedback.HelpUsImprove', { ns: 'commonApp' })}
          size='sm'
          style={{ paddingBottom: 18 }}
        />

        <TextArea
          {...register('feedbackComments', {
            required: t('TopBar.Feedback.FieldRequired', { ns: 'commonApp' })
          })}
          placeholder={t('TopBar.Feedback.FeedbackPlaceholder', { ns: 'commonApp' })}
          style={{ height: '200px' }}
          autoFocus
        />
        <ErrorMessage style={{ fontSize: '12px' }}>
          {errors.feedbackComments?.message}
        </ErrorMessage>

        <FlexRow>
          <Button
            tertiary
            label={t('Discard', { ns: 'common' })}
            onClick={onClose}
            color={theme.palette.text.weak}
          />
          <Button
            label={t('TopBar.Feedback.Send', { ns: 'commonApp' })}
            onClick={handleSubmit(submitFeedback)}
            disabled={!isEmpty(errors)}
            loading={savingInProgress}
          />
        </FlexRow>
      </Wrapper>
    </Modal>
  );
};

export default FeedbackModal;

const Wrapper = styled.div`
  width: 400px;
  padding: 24px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 22px 0px 0px 0px;
`;
