import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@components/core/Tooltip';
import { TooltipPlacement } from '@components/core/Tooltip.types';
import styled, { useTheme } from 'styled-components';
import NotSetMessage from '../common/NotSetMessage';

type SiteOperatorInfoFieldProps = {
  label: string;
  value?: string;
  tooltip?: string;
};

const SiteOperatorInfoField = ({ label, value, tooltip }: SiteOperatorInfoFieldProps) => {
  const theme = useTheme();

  return (
    <div>
      <LabelContainer>
        <Label>{label}</Label>
        {tooltip && (
          <InfoIconWrapper>
            <Tooltip
              content={tooltip}
              placement={TooltipPlacement.Bottom}
              fontSize="14px"
              tooltipColor={theme.palette.tooltip.background.primary}
              styles={{
                padding: 10,
                maxWidth: 215
              }}
            >
              <FontAwesomeIcon icon={regular('circle-info')} color={theme.palette.primary} size='sm' />
            </Tooltip>
          </InfoIconWrapper>
        )}
      </LabelContainer>
      {value ? (
        <Paragraph>{value}</Paragraph>
      ) : (
        <NotSetMessage />
      )}
    </div>
  );
};

export default SiteOperatorInfoField;

const Paragraph = styled.p`
  font-size: 14px;
  font-family: ${p => p.theme.fontFamily};
  margin: 0;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
`;

const InfoIconWrapper = styled.div`
  position: relative;
  margin-top: 3px;
`;

const Label = styled(Paragraph)`
  color: ${p => p.theme.palette.text.fair};
`;