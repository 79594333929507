import { ChartOptions, ChartType, GridLineOptions, TickOptions, TooltipItem, TooltipOptions } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { ZoomPluginOptions } from 'chartjs-plugin-zoom/types/options';
import { format } from 'date-fns';
import { DefaultTheme } from 'styled-components';
import { transparentize } from 'polished';
import { ChartDataset } from '@components/charts/Chart.types';
import { LocalisationType } from '@i18n//localisation';
import { ukLocalisation } from '@i18n//en-GB';

interface AdditionlTickOptions { source: 'labels' | 'auto' | 'data', align: 'start' | 'end' | 'center'; autoSkip: boolean; autoSkipPadding: number; crossAlign: 'center' | 'near' | 'far'; includeBounds: boolean; labelOffset: number; maxRotation: number; minRotation: number; mirror: boolean; padding: number; sampleSize: number }
export type xAxisTimeUnit = false | 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year' | undefined;

export type LineBarChartOverrides = {
  /**
   * Line tension
   */
  tension?: number,
  /**
   * Do not fill line chart with color
   */
  fillLineDisabled?: boolean
  /**
   * y-axis step size
   */
  yAxisStepSize?: number
  /**
   * stacked chart
   */
  stacked?: boolean
  /**
   * number of decimal places to round tooltip label to
  */
  tooltipLabelDecimalPlaces?: number;
}

export type ChartAxisPosition = 'center' | 'top' | 'left' | 'right' | 'bottom' | _DeepPartialObject<_DeepPartialObject<{ [scale: string]: number; }>> | undefined;

export default class ChartOptionsFactory {
  theme: DefaultTheme;
  yAxisLabel?: string;
  yAxisLabelPosition?: ChartAxisPosition;
  y1AxisLabel?: string;
  xAxisTimeUnit?: xAxisTimeUnit;
  chartType?: ChartType;
  onZoomPanComplete?: (min: number, max: number) => void;
  isMultiAxisChart?: boolean;
  localisation?: LocalisationType;

  constructor(
    theme: DefaultTheme,
    yAxisLabel?: string,
    yAxisLabelPosition?: ChartAxisPosition,
    y1AxisLabel?: string,
    xAxisTimeUnit?: xAxisTimeUnit,
    chartType?: ChartType,
    onZoomPanComplete?: (min: number, max: number) => void,
    isMultiAxisChart?: boolean,
    localisation?: LocalisationType
  ) {
    this.theme = theme;
    this.yAxisLabel = yAxisLabel;
    this.yAxisLabelPosition = yAxisLabelPosition
    this.y1AxisLabel = y1AxisLabel;
    this.xAxisTimeUnit = xAxisTimeUnit;
    this.chartType = chartType;
    this.onZoomPanComplete = onZoomPanComplete;
    this.isMultiAxisChart = isMultiAxisChart;
    this.localisation = localisation;
  }

  public TimeAxisDisplayFormats(): _DeepPartialObject<{ [key: string]: string; }> {
    if (this.localisation) {
      return this.localisation?.timeDisplayFormats();
    }
    return ukLocalisation.timeDisplayFormats();
  }

  public XAxisTicks(): _DeepPartialObject<TickOptions & AdditionlTickOptions> {
    return {
      source: 'auto',
      crossAlign: 'near',
      autoSkip: true,
      autoSkipPadding: 30,
      maxRotation: 0,
      color: this.theme.palette.text.weak,
      padding: 5,
      font: {
        family: 'DM Sans',
        size: 14,
        weight: '400',
      },
    };
  }

  public XAxisGrid(): _DeepPartialObject<GridLineOptions> {
    return {
      display: true,
      lineWidth: 0,
      drawTicks: true,
      tickWidth: 2,
      tickColor: this.theme.palette.charts.gridLineColor,
      borderColor: this.theme.palette.charts.gridLineColor,
      borderWidth: 2,
    };
  }

  public YAxisTitle(label?: string) {
    return {
      display: !!label,
      text: label,
      color: this.theme.palette.text.weak,
      font: {
        family: 'DM Sans',
        size: 16,
        weight: '400',
      }
    };
  }

  public YAxisTicks(): _DeepPartialObject<TickOptions> {
    return {
      color: this.theme.palette.text.weak,
      padding: 15,
      font: {
        family: 'DM Sans',
        size: 14,
        weight: '400',
      }
    };
  }

  public TooltipPlugin(roundLabelDecimalPlaces?: number, isCategoryChart?: boolean): _DeepPartialObject<TooltipOptions<'bar' | 'line'>> {
    return {
      enabled: true,
      mode: 'nearest',
      intersect: false,
      displayColors: false,
      caretPadding: 12,
      caretSize: 7,
      backgroundColor: this.theme.palette.backgrounds.surfaceStrong,
      cornerRadius: 3,
      padding: 12,
      borderColor: this.theme.palette.borders.weak,
      borderWidth: 1,
      titleColor: this.theme.palette.text.medium,
      titleFont: {
        family: this.theme.fontFamily,
        weight: '500',
        size: 16,
      },
      bodyColor: this.theme.palette.text.medium,
      bodyFont: {
        family: this.theme.fontFamily,
        weight: '500',
        size: 18,
      },
      footerColor: this.theme.palette.text.weak,
      footerFont: {
        family: this.theme.fontFamily,
        weight: '400',
        size: 14,
      },
      callbacks: {
        title: (items: TooltipItem<'bar' | 'line'>[]) => {
          const dataset = items[0]?.dataset as any; // eslint-disable-line @typescript-eslint/no-explicit-any
          const label = dataset?.displayLabel as string;
          return `${label}`;
        },
        label: (item: TooltipItem<'bar' | 'line'>) => {
          if (isCategoryChart) {
            return item.formattedValue;
          }
          const dataset = (item.dataset as unknown as ChartDataset);
          const formatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: roundLabelDecimalPlaces });
          const labelValue = formatter.format(item.parsed.y);
          return `${dataset?.dataUnit?.prefix ?? ''}${labelValue}${dataset?.dataUnit?.suffix ?? ''}`;
        },
        footer: (items: TooltipItem<'bar' | 'line'>[]) => {
          const raw: any = items[0].raw; // eslint-disable-line @typescript-eslint/no-explicit-any
          return format(new Date(raw.x), `${this.localisation?.dateFormats.weekdayNameAndTime}`) ?? '';
        },
      },
    };
  }

  public ZoomPlugin(): _DeepPartialObject<ZoomPluginOptions> {
    return {
      pan: {
        enabled: true,
        mode: 'x',
        modifierKey: 'ctrl',
        onPanComplete: ({ chart }) => {
          const ticks = chart.scales.x.ticks;
          if (this.onZoomPanComplete && ticks.length > 0) {
            this.onZoomPanComplete(ticks[0].value, ticks[ticks.length - 1].value);
          }
        },
      },
      zoom: {
        drag: {
          enabled: true,
          backgroundColor: '#abb1cb63',
        },
        wheel: {
          enabled: true,
          modifierKey: 'ctrl',
        },
        pinch: {
          enabled: true,
        },
        mode: 'x',
        onZoomComplete: ({ chart }) => {
          const ticks = chart.scales.x.ticks;
          if (this.onZoomPanComplete && ticks.length > 0) {
            this.onZoomPanComplete(ticks[0].value, ticks[ticks.length - 1].value);
          }

          if (chart) {
            const unit = (chart.options.scales as any).x.time.unit; // eslint-disable-line @typescript-eslint/no-explicit-any
            if (unit !== undefined) {
              (chart.options.scales as any).x.time.unit = undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
              chart.update('none');
            }
          }
        }
      }
    };
  }

  public LineBarChartOptions(options?: LineBarChartOverrides): _DeepPartialObject<ChartOptions<'line' | 'bar'>> {
    return {
      responsive: true,
      maintainAspectRatio: false,
      ...(this.isMultiAxisChart && {
        interaction: {
          mode: 'index',
          intersect: false
        }
      }),
      layout: {
        padding: {
          top: 20,
          left: 10,
          right: 5,
        },
      },
      scales: {
        x: {
          stacked: options?.stacked,
          type: 'time',
          time: {
            unit: this.xAxisTimeUnit,
            displayFormats: this.TimeAxisDisplayFormats(),
          },
          grid: this.XAxisGrid(),
          ticks: this.XAxisTicks(),
        },
        y: {
          stacked: options?.stacked,
          title: this.YAxisTitle(this.yAxisLabel),
          position: this.yAxisLabelPosition ?? 'right',
          suggestedMin: 0,
          grid: {
            drawBorder: false,
            color: this.theme.palette.charts.gridLineColor,
            drawTicks: false,
          },
          ticks: {
            stepSize: options?.yAxisStepSize,
            ...this.YAxisTicks()
          },
        },
        y1: {
          display: this.y1AxisLabel !== undefined,
          stacked: options?.stacked,
          title: this.YAxisTitle(this.y1AxisLabel),
          position: 'left',
          suggestedMin: 0,
          grid: {
            drawBorder: false,
            color: this.theme.palette.charts.gridLineColor,
            drawTicks: false,
          },
          ticks: {
            stepSize: options?.yAxisStepSize,
            ...this.YAxisTicks()
          },
        }
      },
      elements: {
        line: {
          borderWidth: 2,
          fill: !options?.fillLineDisabled,
          tension: options?.tension ?? 0.4,
        },
        bar: {
          borderWidth: 0,
          borderRadius: 4,
        },
        point: {
          radius: 0,
          backgroundColor: '#fff',
          borderWidth: 2,
          hoverRadius: 6,
          hoverBackgroundColor: '#fff',
          hoverBorderWidth: 4,
          hitRadius: this.chartType === 'line' ? 20 : 1,
        },
      },
      plugins: {
        tooltip: this.TooltipPlugin(options?.tooltipLabelDecimalPlaces),
        zoom: this.ZoomPlugin(),
        legend: {
          display: false
        }
      }
    };
  }

  public CategoryChartOptions(): _DeepPartialObject<ChartOptions<'line'>> {
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      layout: {
        padding: {
          left: 20,
          right: 5,
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            displayFormats: this.TimeAxisDisplayFormats(),
          },
          grid: this.XAxisGrid(),
          ticks: this.XAxisTicks(),
        },
        y: {
          type: 'category',
          labels: [],
          offset: true,
          title: this.YAxisTitle(),
          position: 'right',
          grid: {
            drawBorder: false,
            drawTicks: false,
            color: transparentize(0.4, this.theme.palette.charts.gridLineColor),
            lineWidth: 16,
          },
          ticks: this.YAxisTicks(),
        },
      },
      elements: {
        line: {
          borderWidth: 16,
        },
        point: {
          radius: 0,
        },
      },
      plugins: {
        tooltip: this.TooltipPlugin(undefined, true),
        zoom: this.ZoomPlugin(),
        legend: {
          display: false,
        }
      }
    };
  }
}