import { SalesOrder } from '@api/models/SalesOrder';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class SiteSalesOrderCreateCommand extends PostQuery<SalesOrder> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return '/salesorders';
  }
}