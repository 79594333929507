import { MetricTypeWithSpaces, SpaceWithMetricTypes } from '@api/models/DynamicReportDataDto';
import { BuildingHierarchiesGetBySiteId } from '@api/queries/buildings/BuildingHierarchiesGetBySiteId';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Site } from '@api/models/Site';
import { Loading } from '@components/core/Loading';
import NoData from '@components/core/NoData';
import { Title } from '@components/core/Title';
import { useApiState } from '@hooks/useApiState';
import { TopBarHeight } from '@src/constants/designConstants';
import { CommonSidebar } from '@components/core/CommonSidebar';
import { BreadcrumbHeight } from '@src/constants/LayoutConstants';
import { useLayoutContext } from '@contexts/LayoutContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReportConfiguration, { IDynamicReportConfiguration } from './components/config-panel/configuration/ReportConfiguration';
import ReportSection from './components/content/ReportSection';

export interface IDynamicReportData {
  config: IDynamicReportConfiguration;
  spaces: SpaceWithMetricTypes[];
  metrics: MetricTypeWithSpaces[];
}

type PropTypes = {
  site: Site
}

const SitePage_Reporting = ({ site }: PropTypes) => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();
  const [reportData, setReportData] = useState<IDynamicReportData>();
  const [loading, setLoading] = useState(false);
  const { data: buildings } = useApiState({
    query: new BuildingHierarchiesGetBySiteId(site.id),
    initialState: []
  }, [site]);

  return (
    <Wrapper isFullScreen={isFullScreen}>
      <CommonSidebar isHidden={isFullScreen}>
        <Title
          text={t('Reporting', { ns: 'common' })}
          style={{ paddingBottom: 20 }}
        />

        <ReportConfiguration
          siteId={site.id}
          buildings={buildings}
          setReportData={setReportData}
          onLoadingReportChange={setLoading}
        />
      </CommonSidebar>

      <ReportWrapper>
        {(!reportData && !loading) &&
          <NoData
            large
            label={t('NoReportGenerated', { ns: 'status' })}
            subLabel={t('NoReportGeneratedHelpText', { ns: 'status' })}
            styles={{ margin: '100px auto' }}
            icon={solid('chart-simple')}
          />
        }
        {loading &&
          <Loading message={t('LoadingReportData', { ns: 'status' })} style={{ margin: '100px auto' }} />
        }

        {(reportData && !loading) &&
          <ReportSection
            data={reportData}
            site={site}
          />
        }
      </ReportWrapper>
    </Wrapper>
  );
};

export default SitePage_Reporting;

const Wrapper = styled.div<{ isFullScreen: boolean }>`
  position: relative;
  display: flex;
  height: ${p => `calc(100vh - ${TopBarHeight}px - ${p.isFullScreen ? 0 : BreadcrumbHeight}px)`};
`;

const ReportWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
`;
