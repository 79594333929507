import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TenantLogo from './components/TenantLogo';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useApiState } from '@hooks/useApiState';
import TenantsGetByUserQuery from '@api/queries/tenants/TenantsGetByUserQuery';
import { ITableColumn } from '@components/table/Table.types';
import { Tenant } from '@api/models/Tenant';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { Button } from '@components/core/Button';
import { Table } from '@components/table/Table';
import { TopBarHeight } from '@src/constants/designConstants';

const TenantList = () => {
  const { t } = useTranslation(['settingsTenant']);
  const navigate = useNavigate();
  const { data: tenants, loading } = useApiState({
    query: new TenantsGetByUserQuery(),
    initialState: []
  }, []);
  const [tableColumns, setTableColumns] = useState<ITableColumn<Tenant>[]>([]);

  useEffect(() => {
    const tableColumns: ITableColumn<Tenant>[] = [
      {
        fixedWidth: '220px',
        customElement: (x) => <TenantLogo tenant={x} />,
        key: 'logo',
      },
      {
        width: 4,
        label: t('List.Name', { ns: 'settingsTenant' }),
        key: 'name',
      },
      {
        label: t('List.Email', { ns: 'settingsTenant' }),
        key: 'email',
      }
    ];
    setTableColumns(tableColumns);
  }, [t])

  return (
    <PaddedContainer>
      <FlexContainer>
        <Heading>
          {t('List.Tenants', { ns: 'settingsTenant' })} <ThemeColored>({tenants.length})</ThemeColored>
        </Heading>
        <RightAligned>

          <ProtectedLink link={{ path: 'create' }}>
            <Button
              tertiary
              circle
              label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
            />
          </ProtectedLink>
        </RightAligned>
      </FlexContainer>

      <Table
        columns={tableColumns}
        records={tenants}
        recordKey='tenantId'
        defaultSortColumn="name"
        emptyMessage={t('List.NoTenants', { ns: 'settingsTenant' })}
        cardEffect
        fullHeightSubtractor={TopBarHeight + 250}
        loading={loading}
        onRowClick={x => navigate(x.tenantId)}
      />
    </PaddedContainer>
  );
}

export default TenantList;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;

const RightAligned = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: auto;
`;