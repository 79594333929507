import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { HierarchySite } from '@api/models/Hierarchy';
import { ICascaderMultiSelectNodeGroup } from '@components/cascader-multi-select/CascaderMultiSelect.types';
import { CascaderMultiSelect } from '@components/cascader-multi-select/CascaderMultiSelect';
import { useTheme } from 'styled-components';

type PropTypes = {
  sites: HierarchySite[];
  onChange: (buildingNames: string[]) => void;
  resetPulse: boolean;
}

const BuildingSelect = ({ sites, onChange, resetPulse }: PropTypes) => {
  const { t } = useTranslation(['molecules']);
  const theme = useTheme();
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<string>>({ nodes: [] });

  useEffect(() => {
    const options: ICascaderMultiSelectNodeGroup<string> = {
      header: 'Sites',
      nodes: orderBy(sites, x => x.name.toLocaleLowerCase()).map((site) => ({
        label: `${site.name}`,
        childGroup: {
          header: 'Buildings',
          nodes: orderBy(site.buildings, x => x.name.toLocaleLowerCase()).map((building) => ({
            label: `${building.name}`,
            value: building.name
          }))
        }
      }
      ))
    };


    setCascaderOptions(options);
  }, [sites])

  const handleChange = (buildingNames?: string[]) => {
    onChange(buildingNames ?? []);
  }

  return (
    <CascaderMultiSelect
      group={cascaderOptions}
      onChange={handleChange}
      placeholder={t('SelectBuildings', { ns: 'common' })}
      confirmButton={t('Apply', { ns: 'common' })}
      cancelButton={t('Cancel', { ns: 'common' })}
      includeChildValues
      hideChildGroupLabel
      resetPulse={resetPulse}
      fieldStyles={{ background: theme.action.filled }}
    />
  );
};

export default BuildingSelect;