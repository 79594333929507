import PeopleCountingZone from '@api/models/PeopleCountingZone';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class PeopleCountingZoneGetByIdQuery extends GetQuery<PeopleCountingZone> {
  virtualDeviceId: number;

  constructor(virtualDeviceId: number) {
    super();
    this.virtualDeviceId = virtualDeviceId;
  }

  public targetUrl(): string {
    return `/peoplecounting/${this.virtualDeviceId}`;
  }
}
