import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { SiteUtilityDailyConsumptionDto } from '@api/models/SiteUtilityDailyConsumptionDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export default class SiteUtilityDailyConsumptionGetQuery extends GetQuery<SiteUtilityDailyConsumptionDto> {
  siteId: number;
  meterType: EnergyMeterType;
  fromDate: string;
  toDate: string;
  includePreviousYear: boolean;

  constructor(siteId: number, meterType: EnergyMeterType, fromDate: string, toDate: string, includePreviousYear?: boolean) {
    super();
    this.siteId = siteId;
    this.meterType = meterType;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.includePreviousYear = !!includePreviousYear;
  }

  public targetUrl(): string {
    return `/sites/GetDailyUtilityConsumption?siteId=${this.siteId}&meterType=${this.meterType}&fromDate=${this.fromDate}&toDate=${this.toDate}&includePreviousYear=${this.includePreviousYear}`;
  }
}