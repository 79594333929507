import { Building } from '@api/models/Building';
import { GetQuery } from '@api/queries/common/GetQuery';

export class BuildingsGetBySiteIdQuery extends GetQuery<Building[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/buildings/site/${this.siteId}`;
  }
}
