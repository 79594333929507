import styled, { css, useTheme } from 'styled-components';
import { useEffect, useState } from 'react';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import { AlertRule, AlertRuleTrigger, DeviceFilterType } from '@api/models/AlertRule';
import { RouterPrompt } from '@src/navigation/RouterPrompt/RouterPrompt';
import { useApi } from '@hooks/useApi';
import { Button } from '@components/core/Button';
import AlertRuleCreateCommand from '@api/queries/alert-rules/AlertRuleCreateCommand';
import AlertRuleUpdateCommand from '@api/queries/alert-rules/AlertRuleUpdateCommand';
import { isTriggerComplete } from './AlertRuleConfiguration/TriggerConfiguration/Trigger';
import { BackButton } from '@components/core/BackButton';
import { PaddedContainer } from '@components/core/PaddedContainer';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import AlertRuleConfigurationV2 from './AlertRuleConfiguration/AlertRuleConfigurationV2';

const newRule: AlertRule = {
  enabled: true,
  delay: 0,
  configuration: {
    triggers: [],
    filters: {
      hierarchy: [],
      devices: {
        filterType: DeviceFilterType.All,
        models: [],
        identifiers: [],
      },
      lettingStatuses: [],
    },
    actions: []
  }
};

const hasIncompleteTriggers = (triggers: AlertRuleTrigger[]) => {
  return triggers.some(trigger => !isTriggerComplete(trigger));
};

const ruleIsValid = (rule?: AlertRule) => {
  return rule?.name !== undefined
    && !isEmpty(rule.configuration.triggers)
    && !hasIncompleteTriggers(rule.configuration.triggers)
    && !isEmpty(rule.configuration.actions)
    && !isEmpty(rule.configuration.filters.hierarchy)
    && !isEmpty(rule.configuration.filters.lettingStatuses)
};

const rulesDiffer = (original?: AlertRule, current?: AlertRule) => {
  return !isEqual(original, current);
}

interface IRuleManagement {
  rules: AlertRule[];
  refreshRules: () => void;
}

export const AlertRuleManagementV2 = ({ rules, refreshRules }: IRuleManagement) => {
  const { t } = useTranslation(['settingsAlerting']);
  const theme = useTheme();
  const { site } = useSiteContext();
  const { trackAction } = useAnalytics();
  const { execute, loading: operationInProgress } = useApi();
  const { alertRuleId } = useParams<{ alertRuleId: string }>();
  const [activeRule, setActiveRule] = useState<AlertRule>();
  const [modifiedRule, setModifiedRule] = useState<AlertRule>();
  const modified = rulesDiffer(activeRule, modifiedRule);

  useEffect(() => {
    const ruleFromRoute = rules.find(x => alertRuleId && x.id === parseInt(alertRuleId));

    if (ruleFromRoute) {
      setActiveRule(ruleFromRoute);
      setModifiedRule(ruleFromRoute);
    } else {
      setActiveRule(newRule);
      setModifiedRule(newRule);
    }
  }, [rules, alertRuleId]);

  const handleSave = async () => {
    if (!modifiedRule || !ruleIsValid(modifiedRule)) {
      return;
    }

    // Update rule if it has an id property, meaning it already exists in the database.
    // Create rule if it has no id property.
    if (modifiedRule.id !== undefined) {
      trackAction('update_alert_rule', 'alerts');
      await execute({ query: new AlertRuleUpdateCommand(modifiedRule.id, modifiedRule) });
    } else {
      trackAction('create_alert_rule', 'alerts');
      await execute({ query: new AlertRuleCreateCommand(site.id, modifiedRule) });
    }

    refreshRules();
  };

  if (!modifiedRule) {
    return null;
  }

  return (
    <PaddedContainer>
      <RouterPrompt when={modified} />

      <BackButton
        label={t('BackToAlertRules', { ns: 'navigation' })}
        url={`/site/${site.id}/alerts/ruleslist`}
        styles={{ marginBottom: 20 }}
      />

      <AlertRuleConfigurationV2
        rule={modifiedRule}
        onChange={setModifiedRule}
      />

      <ControlWrapper>
        <Button
          label={activeRule?.id ? t('AlertingPage.UpdateAlert', { ns: 'settingsAlerting' }) : t('AlertingPage.CreateAlert', { ns: 'settingsAlerting' })}
          onClick={handleSave}
          disabled={!modified || !ruleIsValid(modifiedRule)}
          loading={operationInProgress}
        />
        <VerticalDivider />
        <CancelLabel enabled={modified} onClick={() => modified && setModifiedRule(activeRule)}>
          <FontAwesomeIcon icon={solid('circle-xmark')} color={theme.palette.primary} style={{ marginRight: '5px' }} />
          {activeRule?.id ? t('AlertingPage.DiscardChanges', { ns: 'settingsAlerting' }) : t('AlertingPage.Discard', { ns: 'settingsAlerting' })}
        </CancelLabel>
      </ControlWrapper>
    </PaddedContainer>
  )
};

export default AlertRuleManagementV2;

const ControlWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 24px;
  z-index: 5;
  padding: 0px 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  background-color: ${p => transparentize(0.4, p.theme.palette.backgrounds.surface)};
  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 5px;
  box-shadow: 0 0 8px 0px ${p => p.theme.palette.shadows.medium};
`;

const CancelLabel = styled.div<{ enabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;

  ${p => p.enabled && css`
    opacity: 1;
    cursor: pointer;
  `}
`;

const VerticalDivider = styled.div`
  height: 60px;
  width: 1px;
  background-color: ${p => p.theme.palette.borders.medium};
`;