import React, { ReactNode, useMemo, useContext, useCallback } from 'react';
import { Tenant } from '@api/models/Tenant';
import TenantGetByIdQuery from '@api/queries/tenants/TenantGetByIdQuery';
import { currentSelectedTenant } from '@utils/ConnectApiUtils';
import { useApiState } from '@hooks/useApiState';
import { LoadingFullPage } from '@components/core/LoadingFullPage';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { FeatureFlag } from '@api/enums/FeatureFlag';

interface ITenantContext {
  tenant: Tenant;
  setTenant: (tenant: Tenant) => void;
  tenantFeatureFlags: FeatureFlag[];
  hasFeatureFlag: (feature?: FeatureFlag | FeatureFlag[]) => boolean;
}

export const TenantContext = React.createContext({} as ITenantContext);
export const useTenantContext = () => useContext(TenantContext);

export const TenantProvider = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation();
  const { instance: msalInstance } = useMsal();
  const currentTenant = currentSelectedTenant(msalInstance);

  const { data: tenant, loading, setData } = useApiState({
    query: new TenantGetByIdQuery(currentTenant),
    errorMessage: t('TenantLoadError', { ns: 'commonApp' })
  }, []);

  const tenantFeatureFlags = useMemo(() => (
    tenant?.features?.map(x => x.featureFlag) ?? []
  ), [tenant?.features]);

  const hasFeatureFlag = useCallback((featureFlag?: FeatureFlag | FeatureFlag[]): boolean => {
    return !featureFlag || tenantFeatureFlags.some(tenantFeatureFlag => tenantFeatureFlag === featureFlag);
  }, [tenantFeatureFlags]);

  const tenantContextProviderValue = useMemo(() => {
    // if tenant doesn't exist then set temporary initial tenant to resolve type error (but will not render as will return null if tenant is null)
    const initialTenant = tenant ?? { tenantId: '', name: '', email: '' };

    return {
      tenant: initialTenant,
      setTenant: (tenant: Tenant) => setData(tenant),
      tenantFeatureFlags: tenantFeatureFlags,
      hasFeatureFlag
    }
  }, [tenant, hasFeatureFlag, setData, tenantFeatureFlags]);

  if (loading) {
    return <LoadingFullPage message={t('Launching', { ns: 'commonApp' })} icon={faRocketLaunch} iconPosTop='8px' />;
  }

  if (!tenant) {
    return null;
  }

  return (
    <TenantContext.Provider
      value={tenantContextProviderValue}
    >
      {children}
    </TenantContext.Provider>
  );
};
