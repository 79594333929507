import { IAuthConfig, callPatch } from '@utils/ConnectApiUtils';
import { BaseQuery } from './BaseQuery';

export abstract class GenericPatchQuery<TDto> extends BaseQuery<TDto> { 
  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.apiBaseUrl);
    const response = await callPatch(apiUrl, this, authConfig, controller);

    return response.data;
  }
}
