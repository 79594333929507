import { Button } from '@components/core/Button';
import { Modal } from '@components/core/Modal';
import { IButton } from '@components/core/Button.types';
import { ReactNode, Ref } from 'react';

type InformationDialogProps = {
  modalRef: Ref<HTMLDivElement>;
  isOpen: boolean;
  hide: () => void;
  title?: string;
  content: ReactNode;
  button: IButton;
}

export const InformationDialog = ({ modalRef, isOpen, hide, title, content, button }: InformationDialogProps) => {
  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      title={title}
      hide={hide}
      width="400px"
      footer={<Button tertiary onClick={hide} {...button} />}
    >
      {content}
    </Modal>
  );
};
