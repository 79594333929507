import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title } from '@components/core/Title';
import { NoResultsErrorMessage } from '@src/types/errors/NoResultsErrorMessage';
import styled, { useTheme } from 'styled-components';

type NoResultsErrorProps = {
  noResultsError: NoResultsErrorMessage;
  customIconHeight?: number;
  customContainerHeight?: number;
}

const NoResultsError = ({ noResultsError, customIconHeight, customContainerHeight }: NoResultsErrorProps) => {
  const theme = useTheme();

  return (
    <NoResultsErrorContainer
      customContainerHeight={customContainerHeight}
    >
      <NoResultsErrorIcon
        icon={noResultsError.errorIcon}
        customiconheight={customIconHeight}
      />
      <Title
        text={noResultsError.errorTitle}
        wrapperStyle={{ paddingTop: '15px', paddingBottom: '5px', fontWeight: '400' }}
        style={{ color: theme.palette.text.weak }}
      />
      {noResultsError.errorSubtitle &&
        <Title
          text={noResultsError.errorSubtitle}
          size='xs'
          style={{ color: theme.palette.text.weak, fontWeight: '400' }}
        />
      }
    </NoResultsErrorContainer>
  )
}

export default NoResultsError

const NoResultsErrorContainer = styled.div<{ customContainerHeight?: number }>`
  height: ${(p) => (p.customContainerHeight !== undefined ? `${p.customContainerHeight}px` : '400px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoResultsErrorIcon = styled(FontAwesomeIcon) <{ customiconheight?: number }> `
  width: 100px;
  height: ${(p) => (p.customiconheight !== undefined ? `${p.customiconheight}px` : '100px')};
  color: ${p => p.theme.palette.text.weak};
`;