import { MetricType } from '@api/enums/MetricType';
import { HeatingScheduleDto } from '@api/models/HeatingScheduleDto';
import HeatingSchedulesGetBySpaceIdQuery from '@api/queries/heating-schedules/HeatingSchedulesGetBySpaceIdQuery';
import { Button } from '@components/core/Button';
import { Table } from '@components/table/Table';
import { ITableColumn } from '@components/table/Table.types';
import { ProtectedLink } from '@src/navigation/ProtectedLink/ProtectedLink';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useApiState } from '@hooks/useApiState';
import { useSpaceContext } from '@pages/site/buildings/floor/space/SpaceProvider';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const SpacePage_HeatingSchedules = () => {
  const { t } = useTranslation();
  const { localisation, toLocale, getUnit } = useLocalisation();
  const { space } = useSpaceContext();
  const { trackAction } = useAnalytics();
  const [selected, setSelected] = useState<number>(0);
  const { data: schedules, loading: loading } = useApiState({
    query: new HeatingSchedulesGetBySpaceIdQuery(space.id),
    initialState: []
  }, [space]);

  const tabs = useMemo(() => [
    {
      id: 0,
      label: t('Upcoming', { ns: 'common' }),
      data: schedules.filter(x => !x.endDateTime || new Date(x.endDateTime) >= new Date()),
      analytics: { action: 'upcoming', category: 'space_heating_schedules' }
    },
    {
      id: 1,
      label: t('History', { ns: 'common' }),
      data: schedules.filter(x => x.endDateTime && new Date(x.endDateTime) < new Date()),
      analytics: { action: 'history', category: 'space_heating_schedules' }
    }
  ], [schedules, t]);

  const tableColumns: ITableColumn<HeatingScheduleDto>[] = useMemo(() => ([
    {
      label: t('StartDate', { ns: 'common' }),
      key: 'startDateTime',
      displayFormat: x => format(new Date(x.startDateTime), localisation.dateFormats.default),
      sortFormat: e => new Date(e.startDateTime)
    },
    {
      label: t('EndDate', { ns: 'common' }),
      key: 'endDateTime',
      displayFormat: x => x.endDateTime ? format(new Date(x.endDateTime), localisation.dateFormats.default) : '--',
      sortFormat: x => x.endDateTime && new Date(x.endDateTime)
    },
    {
      label: t('Target', { ns: 'common' }),
      key: 'targetTemp',
      displayFormat: x => `${toLocale(MetricType.Temperature, x.targetTemp, { round: 1 })}${getUnit(MetricType.Temperature)}`
    },
    {
      label: t('ScheduleStatus', { ns: 'common' }),
      key: 'status',
      displayFormat: x => t(`ScheduleStatus.${x.status}`, { ns: 'enums' }),
      sortFormat: x => t(`ScheduleStatus.${x.status}`, { ns: 'enums' })
    },
  ]), [t, toLocale, getUnit, localisation]);

  return (
    <>
      <Tabs>
        {tabs?.map((tab, i) => (
          <Tab
            key={uuidv4()}
            selected={selected === i}
            onClick={() => {
              setSelected(i);
              trackAction(tab.analytics.action, tab.analytics.category);
            }}
          >
            <Label>{tab.label}</Label>
          </Tab>
        ))}

        <ProtectedLink
          link={{ path: './../../../../../../climate-control' }}
          style={{ marginLeft: 'auto' }}
        >
          <Button
            tertiary
            label={t('GoToAllSpaceSchedules', { ns: 'navigation' })}
          />
        </ProtectedLink>
      </Tabs>

      {tabs?.map((tab, i) => (
        <Content
          key={uuidv4()}
          selected={selected === i}
        >
          <Table
            columns={tableColumns}
            records={tab.data}
            recordKey='id'
            defaultSortColumn={'startDateTime'}
            cardEffect
            removeDefaultStyling={true}
            recordBorder={true}
            wrapBreakpoint={450}
            minHeight='none'
            loading={loading}
          />
        </Content>
      ))}
    </>
  );
};

export default SpacePage_HeatingSchedules;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
`;

const Tabs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 20px;
`;

const Tab = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 8px;
  transition: all 150ms ease;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  border-radius: 4px;

  ${p => p.selected && css`
    background-color: ${p => p.theme.primary.background};

    ${Label} {
      color: ${p => p.theme.primary.main};
    }
  `}
`;

const Content = styled.div<{ selected: boolean }>`
  display: none;
  
  ${p => p.selected && css`
    display: inline;
  `}
`;