import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@components/core/Button';
import { Popover } from '@components/core/Popover';
import { PopoverPlacement } from '@components/core/Popover.types';
import { Card } from '@components/core/Card';
import { WarningDialog } from '@components/core/WarningDialog';
import { useModal } from '@hooks/useModal';
import { usePopover } from '@hooks/usePopover';
import styled, { useTheme } from 'styled-components';

type TableActionButtonProps = {
  primaryLabel: string;
  primaryAction: () => void;
  isPrimaryDisabled?: boolean;
  secondaryLabel?: string;
  secondaryAction?: () => void;
  isSecondaryDisabled?: boolean;
  showWarningDialog?: boolean;
  warningDialogTitle?: string;
  warningDialogMessage?: string;
  warningDialogConfirmButtonLabel?: string;
}

const TableActionButton = ({
  primaryLabel,
  primaryAction,
  isPrimaryDisabled,
  secondaryLabel,
  secondaryAction,
  isSecondaryDisabled,
  showWarningDialog,
  warningDialogTitle,
  warningDialogMessage,
  warningDialogConfirmButtonLabel
}: TableActionButtonProps) => {
  const theme = useTheme();
  const { visible, toggle, ref } = usePopover({ disableCloseOnClickOutside: false });
  const { isOpen: dialogIsOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});

  return (
    <>
      {showWarningDialog && secondaryAction && (
        <WarningDialog
          modalRef={dialogRef}
          isOpen={dialogIsOpen}
          sectionOne={warningDialogTitle}
          sectionTwo={warningDialogMessage}
          confirmButton={warningDialogConfirmButtonLabel}
          onCancel={toggleDialog}
          onConfirm={secondaryAction}
          cancelButtonType='tertiary'
        />
      )}
      <Popover
        hideTriangle
        ref={ref}
        visible={visible}
        placement={PopoverPlacement.BottomRight}
        popoverContent={
          <PopoverContent noPadding>
            <PopoverButton
              label={primaryLabel}
              tertiary
              onClick={primaryAction}
              color={theme.palette.text.medium}
              disabled={isPrimaryDisabled}
              style={{ width: '100%', justifyContent: 'flex-start'}}
            />
            <PopoverButton
              label={secondaryLabel}
              tertiary
              onClick={showWarningDialog ? toggleDialog : secondaryAction}
              disabled={isSecondaryDisabled}
              color={theme.palette.systemMessage.error}
              style={{opacity: isSecondaryDisabled ? 0.6 : 1}}
            />
          </PopoverContent>
        }
      >
        <Button
          label={
            <FontAwesomeIcon icon={solid('ellipsis')} color={theme.palette.text.medium} size="lg" />
          }
          tertiary
          onClick={toggle}
        />
      </Popover>
    </>
  );
};

export default TableActionButton;

const PopoverContent = styled(Card)`
  padding: 5px 0;
`;

const PopoverButton = styled(Button)<{color: string}>`
  color: ${({ color }) => color};
  padding: 0;
`;
