import { OperatorPatchQuery } from '@api/queries/resident-app/common/OperatorPatchQuery';
import { OperatorPrizeDto } from '@api/models/OperatorPrizeDto';

export class PrizesResidentAppSelectIndividualWinnerCommand extends OperatorPatchQuery<OperatorPrizeDto[]> {
  prizeId: number;
  challengeId: number;

  constructor(prizeId: number, challengeId: number) {
    super();
    this.prizeId = prizeId;
    this.challengeId = challengeId;
  }

  public targetUrl(): string {
    return '/Prizes/SelectIndividualWinner';
  }
}
