import { DailyTemperaturesDto } from '@api/models/DailyTemperaturesDto';
import { convertToCSV, exportCSV } from '@utils/ExportUtils';
import { MetricType } from '@api/enums/MetricType';
import { Button } from '@components/core/Button';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { useLocalisation } from '@contexts/LocalisationContext/LocalisationContext';
import { useTenantContext } from '@contexts/TenantContext/TenantContext';
import { useSiteContext } from '@pages/site/SiteProvider';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TimeRange, TimeRangeType } from '@components/time-range-select/TimeRangeSelect';

type PropTypes = {
  data: DailyTemperaturesDto[];
  timeRangeType: TimeRangeType;
  timeRange: TimeRange;
}

const TemperatureChartExport = ({ data, timeRangeType, timeRange }: PropTypes) => {
  const { t } = useTranslation();
  const { localisation, getUnit, toLocale } = useLocalisation();
  const { trackAction } = useAnalytics();
  const { tenant } = useTenantContext();
  const { site } = useSiteContext();
  const unit = getUnit(MetricType.Temperature);

  const handleExport = () => {
    const fileName = `${tenant.name}_${site.name}_${t('SiteTemperature', { ns: 'common' })}_${timeRange.from.format(localisation.dateFormats.date)}-${timeRange.to.format(localisation.dateFormats.date)}`;

    const csv = convertToCSV([
      {
        key: 'day',
        header: t('Date', { ns: 'common' }),
        modifier: dto => dayjs(new Date(dto.day)).format(localisation.dateFormats.date)
      },
      {
        key: 'minimum',
        header: `${t('SiteOverview.TemperatureGraph.WarmestSpace', { ns: 'molecules' })} (${unit})`,
        modifier: dto => dto.maximum !== null ? toLocale(MetricType.Temperature, dto.maximum, { round: 1 }).toString() : ''
      },
      {
        key: 'average',
        header: `${t('SiteOverview.TemperatureGraph.SiteAverage', { ns: 'molecules' })} (${unit})`,
        modifier: dto => dto.average !== null ? toLocale(MetricType.Temperature, dto.average, { round: 1 }).toString() : ''
      },
      {
        key: 'ninetiethPercentileAverage',
        header: `${t('SiteOverview.TemperatureGraph.AvgTempInTopTenSpaces', { ns: 'molecules' })} (${unit})`,
        modifier: dto => dto.ninetiethPercentileAverage !== null ? toLocale(MetricType.Temperature, dto.ninetiethPercentileAverage, { round: 1 }).toString() : ''
      },
      {
        key: 'outdoorAverage',
        header: `${t('SiteOverview.TemperatureGraph.ExternalTemp', { ns: 'molecules' })} (${unit})`,
        modifier: dto => dto.outdoorAverage !== null ? toLocale(MetricType.Temperature, dto.outdoorAverage, { round: 1 }).toString() : ''
      },
    ], data);

    exportCSV(fileName, csv);
    trackAction('export_excel', 'performance_temperature', { time_type: timeRangeType });
  };

  return (
    <Button
      secondary
      label={t('Export', { ns: 'common' })}
      onClick={handleExport}
      style={{ marginLeft: 'auto' }}
    />
  );
};

export default TemperatureChartExport;