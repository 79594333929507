import styled from 'styled-components';
import sectionArrowImage from '@assets/images/report-section-arrow.png'

interface IReportSectionHeading {
  label: string;
  printSubLabel?: string;
  style?: React.CSSProperties;
}

const ReportSectionHeading = ({ label, printSubLabel, style }: IReportSectionHeading) => {

  return (
    <Wrapper style={style}>
      <Arrow src={sectionArrowImage} />
      <Heading>
        {label} <SubHeading>{printSubLabel}</SubHeading>
      </Heading>
    </Wrapper>
  );
}

export default ReportSectionHeading;

const Wrapper = styled.div`
  margin-bottom: 10px;

  @media print{
    position: relative;
    margin-bottom: 0px;
  }
`;

const Arrow = styled.img`
  display: none;

  @media print {
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
    margin-left: -15mm;
    width: 27px;
    height: 22px;
  }
`;

const Heading = styled.div`
  color: ${p => p.theme.palette.text.medium};
  font-size: 24px;
  font-weight: 500;
  padding: 30px 0 10px;

  @media print {
    font-size: 12pt;
    padding: 0 0 4mm;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const SubHeading = styled.span`
  display: none;

  @media print {
    display: inline-block;
  }
`;