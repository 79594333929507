import { AlertingRuleSeverity } from '@api/enums/AlertingRuleSeverity';
import { DeviceModel } from '@api/enums/DeviceModel';
import { HierarchyLocationType } from '@api/enums/HierarchyLocationType';
import { OccupancyStatus } from '@api/enums/OccupancyStatus';
import { MetricType } from '@api/enums/MetricType';

export type AlertRule = {
  id?: number,
  name?: string,
  description?: string,
  enabled: boolean,
  delay: number,
  occurrences?: number,
  severity?: AlertingRuleSeverity,
  createdByName?: string,
  modifiedByName?: string,
  createdOn?: string,
  modifiedOn?: string,
  configuration: {
    triggers: AlertRuleTrigger[],
    filters: AlertRuleFilter,
    schedule?: AlertRuleSchedule,
    actions: AlertRuleAction[]
  }
}

export type AlertRuleListItem = {
  id?: number,
  name?: string,
  description?: string,
  location?: string[],
  device?: string,
  lastUpdated?: string,
  updatedBy?: string,
  status: string,
  actions: AlertRuleActionType[]
}

// Trigger

export type AlertRuleTrigger = {
  metric?: MetricType,
  type?: AlertRuleTriggerType,
  threshold?: number | null,
  min?: number | null,
  max?: number | null,
  state?: string | null
}

export enum AlertRuleTriggerType {
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  WithinRange = 'WithinRange',
  OutsideRange = 'OutsideRange',
  State = 'State'
}

// Schedule

export enum AlertRuleScheduleType {
  EveryDay = 'EveryDay',
  Weekdays = 'Weekdays',
  Weekends = 'Weekends',
  Custom = 'Custom'
}

export type AlertRuleScheduleDay = {
  day: number, // Index from 1-7 for each day of the week
  from: AlerRuleScheduleTimeOfDay,
  to: AlerRuleScheduleTimeOfDay,
  enabled: boolean
}

export type AlertRuleSchedule = {
  type?: AlertRuleScheduleType,
  days: AlertRuleScheduleDay[],
}

export type AlerRuleScheduleTimeOfDay = {
  hour: number,
  minute: number
}

// Action

export enum AlertRuleActionType {
  Email = 'Email',
  Webhook = 'Webhook',
  SignalR = 'SignalR',
  EmailDigest = 'EmailDigest'
}

export type AlertRuleAction = {
  type?: AlertRuleActionType | null,
  target?: string | null
  schedule?: AlertRuleActionSchedule | null
}

export type AlertRuleActionSchedule = {
  cronExpression?: string | null;
  timeZoneIdentifier?: string | null;
}

// We use this default until users can select their own timezone across the platform
export const DefaultActionRuleSchedule: AlertRuleActionSchedule = {
  cronExpression: '0 7 * * *', // 7am every day
  timeZoneIdentifier: 'Europe/London' // With DST
}

// Filter

export enum DeviceFilterType {
  All = 'All',
  ByModels = 'ByModels',
  CustomList = 'CustomList'
}

export type AlertRuleFilter = {
  hierarchy: {
    type: HierarchyLocationType,
    id: number
  }[],
  devices: {
    filterType: DeviceFilterType,
    models?: DeviceModel[],
    identifiers?: string[],
  },
  lettingStatuses: OccupancyStatus[],
}