import EsgReportDto from '@api/models/EsgReportDto';
import { TFunction } from 'i18next';
import ReportPageHeader from '@pages/site/esg/components/ReportPageHeader';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Building } from '@api/models/Building';
import { Site } from '@api/models/Site';
import { useTranslation } from 'react-i18next';

export const MAX_NUMBER_OF_ENERGY_CONSUMPTION_WIDGETS_ON_PAGE = 3;
export const MAX_NUMBER_OF_SPACE_TYPE_PERFORMANCE_WIDGETS_ON_PAGE = 4;
export const MAX_NUMBER_OF_OCCUPANCY_WIDGETS_ON_PAGE = 12;
export const MAX_NUMBER_OF_OCCUPANCY_WIDGETS_TO_FIT_HISTOGRAM_ON_SAME_PAGE = 8;

const getNumberOfPagesForEnergyWidgets = (report: EsgReportDto) => {
  let numPages = 0;

  for (const category of report.energyConsumptionMeasurements) {
    const numWidgets = category.energyConsumptionUsageData.length;
    numPages += Math.ceil(numWidgets / MAX_NUMBER_OF_ENERGY_CONSUMPTION_WIDGETS_ON_PAGE);
  }

  return numPages;
}

const getNumberOfPagesForSpaceTypePerformanceWidgets = (report: EsgReportDto) => {
  return Math.ceil(report.spaceTypesPerformanceMetrics.length / MAX_NUMBER_OF_SPACE_TYPE_PERFORMANCE_WIDGETS_ON_PAGE);
}

const getNumberOfPagesForOccupancyWidgets = (report: EsgReportDto) => {
  return Math.ceil(report.occupancyPeopleCountingMeasurements.length / MAX_NUMBER_OF_OCCUPANCY_WIDGETS_ON_PAGE);
}

const getNumberOfPagesForOccupancyHistogramWidget = (report: EsgReportDto, numPagesForOccupancyWidgets: number) => {
  const numOccupancyWidgetsOnLastPage = report.occupancyPeopleCountingMeasurements.length - (MAX_NUMBER_OF_OCCUPANCY_WIDGETS_ON_PAGE * (numPagesForOccupancyWidgets - 1));
  if (report.occupancyHistogramMeasurements.length > 0 && numOccupancyWidgetsOnLastPage > MAX_NUMBER_OF_OCCUPANCY_WIDGETS_TO_FIT_HISTOGRAM_ON_SAME_PAGE) {
    return 1;
  }
  return 0;
}

const createPageHeaderElements = (title: string, reportDate: string, numPages: number, t: TFunction): JSX.Element[] => {
  const elements: JSX.Element[] = [];

  for (let i = 0; i < numPages; i++) {
    elements.push(
      <ReportPageHeader
        key={`page-header-${i}`}
        pageNumber={i + 1}
        numPages={numPages}
        title={<>{t('ESG.EnvironmentalSocialGovernance', { ns: 'molecules' })}</>}
        subTitle={<>{title}<SeparatorDot />{format(new Date(reportDate), 'MMMM yyy')}</>}
        noPageBreak={i === 0}
      />
    );
  }

  return elements;
}

export const useEsgReportPageHeaders = (report: EsgReportDto, site: Site, building?: Building) => {
  const { t } = useTranslation();

  let numPages = 0;
  const indexRanges = [];

  // Number of pages for overview page
  const numPagesForOverviewPage = 1;
  indexRanges.push({ start: numPages, end: numPages + numPagesForOverviewPage });
  numPages += numPagesForOverviewPage;

  // Number of pages for energy widgets
  const numPagesForEnergyWidgets = getNumberOfPagesForEnergyWidgets(report);
  indexRanges.push({ start: numPages, end: numPages + numPagesForEnergyWidgets });
  numPages += numPagesForEnergyWidgets;

  // Number of pages for space type performanc widgets
  const numPagesForSpaceTypePerformanceWidgets = getNumberOfPagesForSpaceTypePerformanceWidgets(report);
  indexRanges.push({ start: numPages, end: numPages + numPagesForSpaceTypePerformanceWidgets });
  numPages += numPagesForSpaceTypePerformanceWidgets;

  // Number of pages for occupancy widgets
  const numPagesForOccupancyWidgets = getNumberOfPagesForOccupancyWidgets(report);
  indexRanges.push({ start: numPages, end: numPages + numPagesForOccupancyWidgets });
  numPages += numPagesForOccupancyWidgets;

  // Number of pages for occupancy histogram widget
  const numPagesForOccupancyHistogramWidget = getNumberOfPagesForOccupancyHistogramWidget(report, numPagesForOccupancyWidgets);
  indexRanges.push({ start: numPages, end: numPages + numPagesForOccupancyHistogramWidget });
  numPages += numPagesForOccupancyHistogramWidget;

  // Number of pages for glossary
  const numPagesForGlossary = 2;
  indexRanges.push({ start: numPages, end: numPages + numPagesForGlossary });
  numPages += numPagesForGlossary;

  const pageHeaders = createPageHeaderElements(building ? building.name : site.name, report.reportDate, numPages, t);

  return {
    pageHeaders,
    indexRanges
  };
};

const SeparatorDot = styled.span`
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #919cad;
  margin: 0 7px 2px 7px;
`;