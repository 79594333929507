import { Protected } from '@src/navigation/Protected/Protected';
import { useAnalytics } from '@contexts/AnalyticsContext/AnalyticsContext';
import { Route } from '@src/types/Route';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export interface IProtectedRoute {
  route: Route;
  customProps?: object;
}

export const ProtectedRoute = ({ route, customProps }: IProtectedRoute) => {
  const { trackPage } = useAnalytics();
  const isHidden = route.hideWhen?.(customProps);

  useEffect(() => {
    if (route.analytics) {
      const { page, category, ...props } = route.analytics;
      trackPage(page, category, props);
    }
  }, [route.analytics, trackPage]);

  if (isHidden) {
    return null;
  }

  if (!route.element) {
    return null;
  }

  const childRoutes = route.children?.map(x => ({ ...x, customProps }));

  return (
    <Protected
      requirePermission={route.permission}
      requireFeatureFlag={route.featureFlag}
      onUnauthorized={<Navigate to={'/'} replace />}
    >
      <route.element routes={childRoutes} {...customProps} customProps={customProps} />
    </Protected>
  );
};
