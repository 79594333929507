import { MetricType } from '@api/enums/MetricType';
import { ChartDataset, LineBarChartType } from '@components/charts/Chart.types';

export enum DeviceMetricsChartType {
  /**
   * Line/Bar chart (renders the LineBarChart.tsx component)
   */
  Default = 'Default',
  /**
   * Category chart (renders the CategoryChart.tsx component)
   */
  Category = 'Category'
}

type ChartConfigOptions = {
  /**
   * If this property is set, the chart type (line/bar) will not change when the chart type is changed via the toggle
   */
  chartType?: LineBarChartType,
  /**
   * Stack the datasets on the chart
   */
  stacked?: boolean
}

export interface IDeviceMetricsChart {
  /**
   * Label displayed on the chart selector (tabs)
   */
  label: string,
  /**
   * MetricTypes that should be shown on the chart
   */
  metricTypes: MetricType[],
  /**
   * Datasets present on the chart (Determined by the defined MetricTypes in the config)
   */
  datasets: ChartDataset[],
  /**
   * The chart is by default a Line/Bar chart, can be changed to a category chart with this property
   */
  type?: DeviceMetricsChartType,
  /**
   * The label of the y-axis of the chart. If this property is not set, the label is determined by
   * the first dataset on the chart (which might not make sense if datasets have different unit types)
   */
  yAxisLabel?: string,
  /**
   * Chart options to to overwrite the chart type or show a stacked chart
   */
  options?: ChartConfigOptions,
  /**
   * Override the colors for the graphs. There may be a case where you need different colors set instead
   * of the metric type color.
   */
  colorOverride?: string[];
}

export interface IDeviceMetricsChartConfig {
  /**
   * Label displayed on the chart selector (tabs), defaults to the DisplayString of the first MetricType on the chart if not defined.
   */
  label?: string;
  /**
   * MetricTypes that should be shown on the chart, used for the API call to get metrics.
   */
  metricTypes: MetricType[];
  /**
   * If the metric types returned from the API are different from the ones used in the query.
   */
  metricTypesFromApi?: MetricType[];
  /**
   * The chart is by default a Line/Bar chart, can be changed to a category chart with this property
   */
  type?: DeviceMetricsChartType;
  /**
   * The label of the y-axis of the chart. If this property is not set, the label is determined by
   * the first dataset on the chart (which might not make sense if datasets have different unit types)
   */
  yAxisLabel?: string;
  /**
   * Chart options to to overwrite the chart type or show a stacked chart
   */
  options?: ChartConfigOptions;
  /**
   * Override the colors for the graphs. There may be a case where you need different colors set instead
   * of the metric type color.
   */
  colorOverride?: string[];
  /**
   * Colors for categories on category chart
   */
  categoryColors?: { [key: string]: string }
}