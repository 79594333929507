import { TenantFeature } from '@api/models/TenantFeature';
import { GenericDeleteQuery } from '../common/GenericDeleteQuery';

export class TenantFeatureBulkDeleteCommand extends GenericDeleteQuery<unknown> {
  tenantFeatures: TenantFeature[];

  constructor(tenantFeatures: TenantFeature[]) {
    super();
    this.tenantFeatures = tenantFeatures;
  }

  public targetUrl(): string {
    return '/features/TenantFeatureBulkDelete';
  }
}
