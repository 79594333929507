import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumber } from '@utils/NumberUtils';
import { useCallback } from 'react';
import styled, { CSSProperties, css } from 'styled-components';

type NumberInputProps = {
  value: number
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  unit?: string;
  disabled?: boolean;
  inputStyle?: CSSProperties;
  arrowStyle?: CSSProperties;
  unitStyle?: CSSProperties;
}

const NumberInput = ({ value, onChange, min, max, unit, disabled, inputStyle, arrowStyle, unitStyle }: NumberInputProps) => {

  const addOne = useCallback(() => {
    onChange(value + 1);
  }, [value, onChange]);

  const subtractOne = useCallback(() => {
    onChange(value - 1);
  }, [value, onChange]);

  const handleChange = (stringValue: string) => {
    const numericValue = !isNumber(stringValue) ? 0 : parseInt(stringValue);
    onChange(numericValue);
  };

  return (
    <Wrapper>
      <StyledInput
        type='number'
        min={min}
        max={max}
        value={value}
        onChange={e => handleChange(e.currentTarget.value)}
        disabled={disabled}
        style={inputStyle}
      />

      {!disabled &&
        <Arrows style={arrowStyle}>
          <Arrow
            icon={solid('chevron-up')}
            onClick={() => !disabled && addOne()}
            disabled={disabled}
          />
          <Arrow
            icon={solid('chevron-down')}
            onClick={() => !disabled && subtractOne()}
            disabled={disabled}
          />
        </Arrows>
      }

      {unit &&
        <Unit style={unitStyle}>
          {unit}
        </Unit>
      }
    </Wrapper>
  );
};

export default NumberInput;

const Wrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  text-align: right;
  color: ${p => p.theme.text.secondary};

  min-width: 57px;
  height: 28px;
  padding-right: 22px;
  padding-left: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: none;

  ${Wrapper}:hover & {
    border-color: ${p => p.theme.action.divider};
    box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
    transition: border-color 150ms ease, box-shadow 150ms ease;    
  }
  
  &:focus {
    outline: none;
    border-color: ${p => p.theme.action.divider};
    box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
    transition: border-color 150ms ease, box-shadow 150ms ease;
  }

  /* Hide native up/down arrows in Firefox */
  @-moz-document url-prefix() { 
    appearance: textfield;
  }

  /* Hide native up/down arrows in Chrome/Edge */
  &::-webkit-inner-spin-button {
    display: none;
  }
`;

const Arrows = styled.div`
  position: absolute;
  top: 0px;
  right: 1px;
  padding: 1px 0;

  height: 28px;
  display: none;
  flex-direction: column;

  ${Wrapper}:hover & {
    display: flex;
  }

  ${StyledInput}:focus ~ & {
    display: flex;
  }
`;

const Arrow = styled(FontAwesomeIcon) <{ disabled?: boolean }>`
  font-size: 10px;
  color: ${p => p.theme.palette.text.weak};

  ${p => !p.disabled && css`
    &:hover {
      color: ${p => p.theme.palette.text.onPrimary};
      background-color: ${p => p.theme.palette.primary};
    }
  `}

  &:first-child {
    padding: 3px 4px 0px 4px;
    border-radius: 0 4px 0 0;
  }

  &:last-child {
    padding: 0px 4px 3px 4px;
    border-radius: 0 0 4px 0;
  }
`;

const Unit = styled.div`
  position: absolute;
  top: 5px;
  right: 7px;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};

  ${Wrapper}:hover & {
    display: none;
  }

  ${StyledInput}:focus ~ & {
    display: none;
  }
`;