import styled from 'styled-components';
import MarkerType from './Marker.types';
import { Tooltip } from '@components/core/Tooltip';
import { Icon } from '@components/core/Icon';
import { SpaceType_Color } from '@api/enums/SpaceType_Color';
import { SpaceType_Icon } from '@api/enums/SpaceType_Icon';

type PropTypes = {
  index: number
  marker: MarkerType,
  widthFactor: number,
  heightFactor: number,
  showTooltip: boolean,
}

const iconSize = 30;
const iconSizeMin = 10;
const iconSquareSize = 42;
const iconSquareSizeMin = 15;

const Marker = ({ index, marker, widthFactor, heightFactor, showTooltip }: PropTypes) => {
  const markerWidth = Math.max(iconSquareSizeMin, iconSquareSize * widthFactor);

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, i: number) => {
    e.dataTransfer.setData('markerIndex', i.toString());
    e.currentTarget.style.transform = 'translateX(-9999px)';
    e.currentTarget.style.cursor = 'grabbing';
  };

  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.transform = 'none';
    e.currentTarget.style.cursor = 'grab';
  };

  if (!marker.xCoordinate || !marker.yCoordinate) {
    return null;
  }

  return (
    <>
      <MarkerContainer
        x={marker.xCoordinate}
        y={marker.yCoordinate}
        width={markerWidth}
        widthFactor={widthFactor}
        heightFactor={heightFactor}
        onDragStart={(e) => onDragStart(e, index)}
        onDragEnd={(e) => onDragEnd(e)}
        draggable
      >
        <Tooltip
          content={marker.name}
          forceVisible={showTooltip}
        >
          <Icon
            icon={SpaceType_Icon(marker.spaceType)}
            iconSize={`${Math.max(iconSizeMin, iconSize * widthFactor)}px`}
            squareSize={`${markerWidth}px`}
            squareColor={SpaceType_Color(marker.spaceType)}
            iconColor='#fff'
            shadowBottom
          />
        </Tooltip>
      </MarkerContainer>
    </>
  );
};

export default Marker;

const MarkerContainer = styled.div<{ x: number, y: number, width: number, widthFactor: number, heightFactor: number }>`
  position: absolute;
  top: ${p => p.y * p.widthFactor - (p.width / 2)}px;
  left: ${p => p.x * p.heightFactor - (p.width / 2)}px;
  
  border-radius: 3px;
  cursor: grab;

  transition: transform 0.01s;
`;