import { NoteDiscriminator } from '@api/enums/NoteDiscriminator';
import { NoteDto } from '@api/models/NoteDto';
import { GetQuery } from '@api/queries/common/GetQuery';

export class NotesGetQuery extends GetQuery<NoteDto[]> {
  discriminator: NoteDiscriminator;
  discriminatorIds: number[];

  constructor(discriminator: NoteDiscriminator, discriminatorIds: number[]) {
    super();
    this.discriminator = discriminator;
    this.discriminatorIds = discriminatorIds;
  }

  public targetUrl(): string {
    const queryParams = new URLSearchParams();

    queryParams.append('discriminator', this.discriminator);

    this.discriminatorIds.forEach(id => {
      queryParams.append('discriminatorIds', id.toString());
    });

    return `/notes?${queryParams}`;
  }
}
