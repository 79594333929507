import { EnergyMeterType } from '@api/enums/EnergyMeterType';
import { EnergyMeterBill } from '@api/models/EnergyMeterBill';
import { EnergyMeterBillDto } from '@api/models/EnergyMeterBillDto';
import { PostQuery } from '@api/queries/common/PostQuery';

export default class EnergyMeterBillCreateCommand extends PostQuery<EnergyMeterBillDto> {
  siteId: number;
  buildingId: number | undefined;
  siteName: string;
  buildingName: string | undefined;
  meterNumber: string;
  meterId: number;
  meterReading?: string;
  measuredOn?: Date;
  consumption?: string;
  periodStartDate?: Date;
  periodEndDate?: Date;
  meterType: EnergyMeterType;

  constructor(bill: EnergyMeterBill) {
    super();
    this.siteId = bill.siteId;
    this.buildingId = bill.buildingId;
    this.siteName = bill.siteName;
    this.buildingName = bill.buildingName;
    this.meterNumber = bill.meterNumber;
    this.meterId = bill.meterId;
    this.meterReading = bill.meterReading;
    this.measuredOn = bill.measuredOn;
    this.consumption = bill.consumption;
    this.periodStartDate = bill.periodStartDate;
    this.periodEndDate = bill.periodEndDate;
    this.meterType = bill.meterType;
  }

  public targetUrl(): string {
    return '/energymeters/createenergymeterbill';
  }
}
